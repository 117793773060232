/* eslint-disable @typescript-eslint/no-explicit-any */
export type StationId = number;
export type LineId = number;

class MetroMapModel {
    private onChange: (data: any) => void;
    private stationsIdsByLineId: Map<LineId, Array<StationId>>;

    private allStationsIds: Set<StationId> = new Set<StationId>();
    private selectedStationsIds = new Set<StationId>();

    constructor({ selected, onChange, stationsIdsByLineId }:
                {
                    selected: Set<StationId>;
                    onChange: (data: any) => void;
                    stationsIdsByLineId: Map<LineId, Array<StationId>>;
                }) {
        this.onChange = onChange;
        this.stationsIdsByLineId = stationsIdsByLineId;

        stationsIdsByLineId.forEach(stationsIds => {
            stationsIds.forEach(stationId => this.allStationsIds.add(stationId));
        });

        selected.forEach(stationId => {
            if (this.allStationsIds.has(stationId)) {
                this.selectedStationsIds.add(stationId);
            }
        });
    }

    private _selectStations(stationIds: Array<StationId>, isNeedSelect: boolean) {
        let isChanged = false;

        const selected = new Set();
        const deselected = new Set();

        stationIds.forEach(stationId => {
            if (this.allStationsIds.has(stationId)) {
                const isStationSelected = this.selectedStationsIds.has(stationId);

                if (
                    (isNeedSelect && ! isStationSelected) ||
                    (! isNeedSelect && isStationSelected)
                ) {
                    isChanged = true;
                    if (isNeedSelect) {
                        this.selectedStationsIds.add(stationId);
                        selected.add(stationId);
                    } else {
                        this.selectedStationsIds.delete(stationId);
                        deselected.add(stationId);
                    }
                }
            }
        });

        if (isChanged) {
            this.onChange({
                diff: {
                    selected,
                    deselected
                }
            });
        }
    }

    isStationSelected(stationId: StationId) {
        return this.selectedStationsIds.has(stationId);
    }

    isStationsSelected(stationsIds: Array<StationId>) {
        return Array.isArray(stationsIds) && stationsIds.every(this.isStationSelected, this);
    }

    setStations(stationIds?: Set<StationId>) {
        if (stationIds) {
            let isChanged = false;

            const selected = new Set();
            const deselected = new Set();

            this.selectedStationsIds.forEach(stationId => {
                if (! stationIds.has(stationId)) {
                    isChanged = true;
                    this.selectedStationsIds.delete(stationId);
                    deselected.add(stationId);
                }
            });

            stationIds.forEach(stationId => {
                if (
                    this.allStationsIds.has(stationId) &&
                    ! this.selectedStationsIds.has(stationId)
                ) {
                    isChanged = true;
                    this.selectedStationsIds.add(stationId);
                    selected.add(stationId);
                }
            });

            if (isChanged) {
                this.onChange({
                    diff: {
                        selected,
                        deselected
                    }
                });
            }
        }
    }

    selectStations(stationIds?: Array<StationId>) {
        if (Array.isArray(stationIds)) {
            this._selectStations(stationIds, true);
        }
    }

    deselectStations(stationIds?: Array<StationId>) {
        if (Array.isArray(stationIds)) {
            this._selectStations(stationIds, false);
        }
    }

    selectStation(stationId: StationId) {
        if (stationId) {
            this.selectStations([ stationId ]);
        }
    }

    deselectStation(stationId: StationId) {
        if (stationId) {
            this.deselectStations([ stationId ]);
        }
    }

    toggleStation(stationId: StationId) {
        if (stationId) {
            if (this.isStationSelected(stationId)) {
                this.deselectStation(stationId);
            } else {
                this.selectStation(stationId);
            }
        }
    }

    selectLine(lineId: LineId) {
        if (lineId) {
            const stationIds = this.stationsIdsByLineId.get(lineId);

            if (stationIds) {
                this.selectStations(stationIds);
            }
        }
    }

    deselectLine(lineId: LineId) {
        if (lineId) {
            const stationIds = this.stationsIdsByLineId.get(lineId);

            if (stationIds) {
                this.deselectStations(stationIds);
            }
        }
    }

    toggleLine(lineId: LineId) {
        if (lineId) {
            const stationIds = this.stationsIdsByLineId.get(lineId);

            if (stationIds?.every(stationId => this.isStationSelected(stationId))) {
                this.deselectLine(lineId);
            } else {
                this.selectLine(lineId);
            }
        }
    }

    deselectAll() {
        this.deselectStations(Array.from(this.selectedStationsIds.values()));
    }

    getSelectedStations() {
        return new Set(this.selectedStationsIds);
    }

    getSelected() {
        const selectedLinesIds = new Set();
        const selectedStationsIds = new Set();
        const processedStationsIds = new Set();

        this.stationsIdsByLineId.forEach((stationIds, lineId) => {
            if (
                stationIds.every(stationId => this.isStationSelected(stationId))
            ) {
                selectedLinesIds.add(lineId);
                stationIds.forEach(stationId => processedStationsIds.add(stationId));
            }
        });

        this.selectedStationsIds.forEach(stationId => {
            if (! processedStationsIds.has(stationId)) {
                selectedStationsIds.add(stationId);
            }
        });

        return {
            linesIds: selectedLinesIds,
            stationsIds: selectedStationsIds
        };
    }
}

export default MetroMapModel;
