import React, { useState } from 'react';

interface IChatStateProviderContextContext {
    wasOpened: boolean;
    setWasOpened: (value: boolean) => void;
    initialClientMessage?: string;
}

const ChatStateProviderContext = React.createContext<IChatStateProviderContextContext>({
    wasOpened: false,
    setWasOpened: () => {}
});

export const ChatStateProvider = ({
    initialClientMessage,
    children
}: {
    initialClientMessage?: string;
    children?: React.ReactNode;
}) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ wasOpened, setWasOpened ] = useState<boolean>(false);

    return (
        <ChatStateProviderContext.Provider value={{
            wasOpened,
            setWasOpened,
            initialClientMessage
        }}>
            {children}
        </ChatStateProviderContext.Provider>
    );
};

export const useChatStateContext = () => {
    return React.useContext(ChatStateProviderContext);
};
