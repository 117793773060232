import { MouseEvent, useCallback, useState, useEffect } from 'react';

export const produceNextIndex = (lastIndex: number, nextIndex: number) => {
    if (nextIndex > lastIndex) {
        nextIndex = 0;
    } else if (nextIndex < 0) {
        nextIndex = lastIndex;
    }

    return nextIndex;
};

type UseControl = (params: {
    /**
     * Индекс открытой фотографии
     */
    index: number;
    /**
     * Индекс последней фотографии
     */
    lastIndex: number;

    /**
     * Использовать навигацию по клавишам
     */
    isKeyNav: boolean;
}) => {
    handlePrevClick: (e?: MouseEvent) => void;
    handleNextClick: (e?: MouseEvent) => void;
    handleClickOnPreview: (index: number) => void;
    currentIndex: number;
};

export const useControl: UseControl = ({
    isKeyNav,
    index = 0,
    lastIndex
}) => {
    const [ galleryControlState, setControl ] = useState<{ index: number }>({ index });

    const handlePrevClick = useCallback((e?: MouseEvent) => {
        e?.stopPropagation();

        setControl(prevState => ({
            ...prevState, index: produceNextIndex(lastIndex, prevState.index - 1)
        }));
    }, [ lastIndex ]);

    const handleNextClick = useCallback((e?: MouseEvent) => {
        e?.stopPropagation();

        setControl(prevState => ({
            ...prevState, index: produceNextIndex(lastIndex, prevState.index + 1)
        }));
    }, [ lastIndex ]);

    const handleClickOnPreview = useCallback((idx: number) => {
        setControl(prevState => ({ ...prevState, index: idx }));
    }, []);

    const handleWindowKeyDown = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'Escape':

                break;
            case 'ArrowLeft':
                setControl(prevState => ({
                    ...prevState, index: produceNextIndex(lastIndex, prevState.index - 1)
                }));

                break;
            case 'ArrowRight':
                setControl(prevState => ({
                    ...prevState, index: produceNextIndex(lastIndex, prevState.index + 1)
                }));

                break;
            default:
                break;
        }
    }, [ lastIndex ]);

    useEffect(() => {
        if (isKeyNav) {
            window.addEventListener('keydown', handleWindowKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleWindowKeyDown);
        };
    }, [ isKeyNav, handleWindowKeyDown ]);

    return {
        handlePrevClick,
        handleNextClick,
        handleClickOnPreview,
        currentIndex: galleryControlState.index
    };
};
