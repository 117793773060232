import React, { useCallback, useEffect, useState } from 'react';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { useFavoritesModal } from '@search/vtbeco-frontend-core/view/common/components/FavoritesButton/FavoritesModal';

import { useUpdateFavoriteNewBuilding } from './useUpdateFavoriteNewBuilding';
import { useUpdateFavoriteNewBuildingMutation$variables as useUpdateFavoriteNewBuildingMutationVariables } from './__generated__/useUpdateFavoriteNewBuildingMutation.graphql';

export const useFavoriteNewBuildingButton = ({
    isFavorite,
    newBuildingId,
    onSuccess
}: useUpdateFavoriteNewBuildingMutationVariables & {
    onSuccess?: () => void;
}) => {
    const favoritesMutation = useUpdateFavoriteNewBuilding();
    const { setError } = useFavoritesModal();

    const [ isFavoriteManual, setIsFavoriteManual ] = useState<boolean>(isFavorite);

    useEffect(() => {
        setIsFavoriteManual(isFavorite);
    }, [ isFavorite ]);

    const updateFavorite = useCallback((next: Pick<useUpdateFavoriteNewBuildingMutationVariables, 'isFavorite'>) => {
        setError(undefined);

        if (next.isFavorite) {
            dataLayerPush({
                event: NewBuildingAnalytics.NewBuildingAddToFavorites,
                'nb-id': newBuildingId
            });
        }

        favoritesMutation({
            isFavorite: next.isFavorite,
            newBuildingId
        })
            .then(({ data }) => {
                const error = data?.editFavoriteNewBuildings.firstError;

                setError(error);

                if (! error) {
                    setIsFavoriteManual(next.isFavorite);
                    onSuccess && onSuccess();
                }
            })
            .catch(e => {
                setError(e);
            });
    }, [
        newBuildingId,
        onSuccess,
        favoritesMutation,
        setError
    ]);

    const sendFavoritesRequest = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();

        updateFavorite({
            isFavorite: ! isFavoriteManual
        });
    }, [ updateFavorite, isFavoriteManual ]);

    return {
        isFavorite: isFavoriteManual,
        onClick: sendFavoritesRequest
    };
};
