import React from 'react';

import { useYMapPane } from '../Pane';

export function YMapLayer({
    tileUrl
}: {
    tileUrl: string;
}) {
    const { map, api: { Layer } } = useYMapPane();
    const imgUrl = `${tileUrl}${! tileUrl.includes('?') ? '?' : '&'}%c`;

    const attached = React.useRef(false);

    const imgLayer = React.useMemo(() => {
        // @ts-ignore
        return new Layer(imgUrl, {
            tileTransparent: true
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ Layer ]);

    React.useEffect(() => {
        // @ts-ignore
        imgLayer.setTileUrlTemplate(imgUrl);
        if (attached.current) {
            // @ts-ignore
            imgLayer.update();
        }
    }, [ imgLayer, imgUrl ]);

    React.useEffect(() => {
        map.layers.add(imgLayer);
        attached.current = true;

        return () => {
            attached.current = false;
            map.layers.remove(imgLayer);
        };
    }, [ imgLayer, map ]);

    return null;
}
