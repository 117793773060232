/* global window */
import { useState, useEffect, KeyboardEvent } from 'react';

export function useKeyPress(targetKey: KeyboardEvent['key']) {
    const [ isKeyPressed, setKeyPressed ] = useState(false);

    function downHandler({ key }: KeyboardEvent) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    const upHandler = ({ key }: KeyboardEvent) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('keydown', downHandler);
        // @ts-ignore
        window.addEventListener('keyup', upHandler);
        return () => {
            // @ts-ignore
            window.removeEventListener('keydown', downHandler);
            // @ts-ignore
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return isKeyPressed;
}
