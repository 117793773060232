export enum TimeLimit {
    UNKNOWN = 0,
    MAX_5_MINUTES = 1,
    MAX_10_MINUTES = 2,
    MAX_15_MINUTES = 3,
    MAX_20_MINUTES = 4,
    MAX_25_MINUTES = 5,
    MAX_30_MINUTES = 6,
    MAX_40_MINUTES = 7,
    MAX_50_MINUTES = 8,
    MAX_60_MINUTES = 9,
}
