import React from 'react';

import { Link, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import type { NewBuildingMortgageParams } from '@search/graphql-typings';

import useMortgageDemandLink from '../../../../common/hooks/useMortgageDemandLink';

import './styles.css';

const cn = classname.bind(null, 'M2ProSnippetMortgage');

const M2ProSnippetMortgage = ({
    regionId,
    propertyCost,
    purposeValue,
    claimType,
    newBuildingName,
    ownAmount
}: Pick<NewBuildingMortgageParams, 'regionId' | 'propertyCost' | 'purposeValue' | 'claimType' | 'ownAmount'> & {
    newBuildingName: string;
}) => {
    const mortgageDemandLink = useMortgageDemandLink({
        regionId,
        propertyCost,
        purpose: purposeValue,
        claimType,
        newBuildingName,
        ownAmount
    });

    return (
        <div className={cn()}>
            <Link
                target='blank'
                href={mortgageDemandLink}
            >
                <Typography
                    bold
                    variant='primary-alone'
                    className={cn('link')}
                >
                    Оформить ипотеку
                </Typography>
            </Link>
            <Typography
                variant='secondary-alone'
                className={cn('promo')}
            >
                Универсальная заявка в&nbsp;24&nbsp;банка‑партнёра
            </Typography>
        </div>
    );
};

export default M2ProSnippetMortgage;
