/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useCallback, useEffect } from 'react';
import upperFirst from 'lodash/upperFirst';

import { Rooms as RoomsEnum } from '@search/filter-enums/enums';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { PriceFilter, RegionFilter, RoomFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import { Checkbox } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';

import { cnNewBuildingQuiz } from '../../index';
import type { UpdateData, UpdateEvent } from '../types';

type Region = RegionIdEnum.MSK_AND_MSK_OBL | RegionIdEnum.SPB_AND_LEN_OBL;
type Rooms = RoomsEnum.STUDIO | RoomsEnum.ROOM_1 | RoomsEnum.ROOM_2 | RoomsEnum.ROOM_3 | RoomsEnum.ROOM_4;
type PricesByRooms = Record<Rooms, number[]>;

const PRICES: Record<Region, PricesByRooms> = {
    [RegionIdEnum.MSK_AND_MSK_OBL]: {
        [RoomsEnum.STUDIO]: [ 4, 7, 10 ],
        [RoomsEnum.ROOM_1]: [ 6, 9, 13, 17 ],
        [RoomsEnum.ROOM_2]: [ 8, 11, 15, 20 ],
        [RoomsEnum.ROOM_3]: [ 10, 13, 16, 20 ],
        [RoomsEnum.ROOM_4]: [ 15, 20, 30 ]
    },
    [RegionIdEnum.SPB_AND_LEN_OBL]: {
        [RoomsEnum.STUDIO]: [ 4, 6, 10 ],
        [RoomsEnum.ROOM_1]: [ 5, 8, 12, 15 ],
        [RoomsEnum.ROOM_2]: [ 8, 10, 14, 18 ],
        [RoomsEnum.ROOM_3]: [ 10, 13, 16, 20 ],
        [RoomsEnum.ROOM_4]: [ 15, 20, 30 ]
    }
};

const makePrices = (roomsProp: RoomsEnum[], pricesByRooms: PricesByRooms): number[] => {
    // @ts-ignore
    const rooms: Rooms[] = roomsProp.filter(roomsValue => Boolean(pricesByRooms[roomsValue]));

    const pricesArrays = rooms.length === 0 ?
        Object.entries(pricesByRooms).map(([ , priceArray ]) => priceArray) :
        rooms.map(roomsValue => pricesByRooms[roomsValue]);
    const prices = ([] as number[]).concat(...pricesArrays);

    const priceMin = Math.min(...prices);
    const priceMax = Math.max(...prices);
    const priceDiff = priceMax - priceMin;

    return [
        priceMin,
        Math.round(priceMin + priceDiff / 3),
        Math.round(priceMin + 2 * priceDiff / 3),
        priceMax
    ];
};

const DEFAULT_PRICES: Record<Region, number[]> = {
    [RegionIdEnum.MSK_AND_MSK_OBL]: makePrices([], PRICES[RegionIdEnum.MSK_AND_MSK_OBL]),
    [RegionIdEnum.SPB_AND_LEN_OBL]: makePrices([], PRICES[RegionIdEnum.SPB_AND_LEN_OBL])
};

const getPrices = (rooms: RoomsEnum[], regionProp: RegionIdEnum): number[] => {
    const region: Region = regionProp === RegionIdEnum.MSK_AND_MSK_OBL ?
        RegionIdEnum.MSK_AND_MSK_OBL :
        RegionIdEnum.SPB_AND_LEN_OBL;

    if (rooms.length === 0) {
        return DEFAULT_PRICES[region];
    }

    if (rooms.length === 1) {
        // @ts-ignore
        return PRICES[region][rooms[0]] ?? DEFAULT_PRICES[region];
    }

    return makePrices(rooms, PRICES[region]);
};

type PriceData = {
    from: number | null;
    to: number | null;
    label: string;
};

const getPriceData = (rooms: RoomsEnum[], region: RegionIdEnum): PriceData[] => {
    const prices = [ ...getPrices(rooms, region) ].reverse();

    return prices.reduce((res, to, i) => {
        const from = i === prices.length - 1 ? null : prices[i + 1];

        res.unshift({
            from: from && from * 1e6,
            to: to * 1e6,
            label: upperFirst(`${from ? `от ${from} ` : ''}до ${to} млн рублей`)
        });

        return res;
    }, [ {
        from: null,
        to: null,
        label: 'Пока не знаю'
    } ] as PriceData[]);
};

export const PriceView = (({
    priceFilter,
    regionFilter,
    roomsFilter,
    updateData,
    updateEvent,
    isMobile
}: {
    priceFilter: PriceFilter;
    regionFilter: RegionFilter;
    roomsFilter: RoomFilter;
    updateData: UpdateData;
    updateEvent: UpdateEvent;
    isMobile: boolean;
}) => {
    const update = useCallback(
        (from: number | null, to: number | null, label: string) => updateData([
            new PriceFilter(undefined, { from, to })
        ], {
            price: label
        }),
        [ updateData ]
    );

    useEffect(() => {
        const { from, to } = priceFilter.value;

        updateEvent({
            event_category: 'price_range',
            event_action: [ from && from / 1e6, to && to / 1e6 ]
        });
    }, [ priceFilter, updateEvent ]);

    const priceCheckboxes = getPriceData([ ...roomsFilter.rooms ], regionFilter.region)
        .map(({ from, to, label }) => (
            <Checkbox
                key={label}
                radio
                value={priceFilter.value.from === from && priceFilter.value.to === to}
                onChange={() => update(from, to, label)}
            >
                {label}
            </Checkbox>
        ));

    return (
        <div className={cnNewBuildingQuiz('filter', { price: true })}>
            {isMobile ? (
                priceCheckboxes.map((priceCheckbox, i) => (
                    <Fragment key={i}>
                        {priceCheckbox}
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid cols={12}>
                        {priceCheckboxes.slice(0, 3).map((priceCheckbox, i) => (
                            <Grid.Cell
                                key={i}
                                m={4}
                            >
                                {priceCheckbox}
                            </Grid.Cell>
                        ))}
                    </Grid>
                    <Grid cols={12}>
                        {priceCheckboxes.slice(3, 6).map((priceCheckbox, i) => (
                            <Grid.Cell
                                key={i}
                                m={4}
                            >
                                {priceCheckbox}
                            </Grid.Cell>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
}) as React.FC;
