import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import type ymaps from '../ApiProvider/yandex-maps';

import { useYMapPane } from '../Pane';

export type PolygonCoordinate = {
    latitude: number;
    longitude: number;
}

type Event = object | ymaps.IEvent;

type HandleEvent = (e: Event) => void;

export interface YMapPolygonProps {
    polygons: PolygonCoordinate[][];
    fillColor?: string;
    fillOpacity?: number;
    strokeColor?: string;
    strokeWidth?: number;
    fillImageHref?: string;
    fillMethod?: 'stretch' | 'tile';
    cursor?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hintLayout?: any;
    onClick?: (e: Event, properties: object | undefined) => void;
    properties?: object;
    shouldBeRemovedOnUnmount?: boolean;
}

export function YMapPolygon(props: YMapPolygonProps) {
    const {
        polygons: coordinates,
        properties,
        fillColor = 'rgba(241, 71, 26, 0.1)',
        fillOpacity = 1,
        strokeColor = 'rgba(241, 71, 26, 0.1)',
        strokeWidth = 1,
        fillImageHref,
        fillMethod = 'tile',
        cursor,
        hintLayout,
        onClick,
        shouldBeRemovedOnUnmount
    } = props;
    const {
        api,
        map: { geoObjects }
    } = useYMapPane();

    const [ state, setState ] = useState<{
        props?: YMapPolygonProps;
        polygons?: ymaps.Polygon[];
        handleClick?: HandleEvent;
    }>({});

    React.useEffect(() => {
        const {
            props: prevProps,
            polygons: prevPolygons,
            handleClick: prevHandleClick
        } = state;

        if (prevProps && isEqual(
            omit(prevProps, 'onClick'),
            omit(props, 'onClick')
        )) {
            if (
                prevPolygons &&
                prevHandleClick &&
                onClick !== prevProps.onClick
            ) {
                const nextHandleClick = onClick && ((e: Event) => onClick(e, properties));

                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                prevPolygons.forEach((polygon, _i) => {
                    prevHandleClick && polygon.events.remove([ 'click' ], prevHandleClick);
                    nextHandleClick && polygon.events.add([ 'click' ], nextHandleClick);
                });

                setState(prevState => ({
                    ...prevState,
                    handleClick: nextHandleClick
                }));
            }

            return;
        }

        const handleClick = onClick && ((e: Event) => onClick(e, properties));

        const polygons = coordinates.map(coords => {
            const points = coords.map(point => [
                point.latitude,
                point.longitude
            ]);

            const polygon = new api.Polygon([ points ], properties, {
                fillColor, fillOpacity,
                strokeColor, strokeWidth,
                fillImageHref, fillMethod,
                cursor, hintLayout
            });

            handleClick && polygon.events.add([ 'click' ], handleClick);

            return polygon;
        });

        if (prevPolygons) {
            for (const polygon of prevPolygons) {
                geoObjects.remove(polygon);
            }
        }

        for (const polygon of polygons) {
            geoObjects.add(polygon);
        }

        setState({
            props,
            polygons,
            handleClick
        });

        return () => {
            shouldBeRemovedOnUnmount && polygons.forEach(polygon => geoObjects.remove(polygon));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        coordinates, properties,
        fillColor, fillOpacity,
        strokeColor, strokeWidth,
        fillImageHref, fillMethod,
        cursor, hintLayout, onClick
    ]);

    return null;
}
