import React, { FC, useCallback } from 'react';

import { Button, ButtonProps } from '@vtblife/uikit';
import { M2ProModalOrderCreation } from '@vtblife/m2-pro';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import type { M2ProEvent } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/m2ProEvent';

type M2ProOrderButtonProps = {
    newBuildingId: string;
    showM2ProModal?: (show: boolean) => void;
    eventData?: Pick<M2ProEvent, 'page_type' | 'position' | 'modal_type'>;
} & ButtonProps;

export const M2ProOrderButton: FC<M2ProOrderButtonProps> = ({
    newBuildingId,
    showM2ProModal = () => {},
    eventData,
    ...props
}) => {
    const handleClick = useCallback(() => {
        showM2ProModal(true);

        dataLayerPush({
            event: 'M2Pro_rieltors',
            name: 'fix',
            ...eventData
        } as M2ProEvent);
    }, [ showM2ProModal, eventData ]);

    return (
        <M2ProModalOrderCreation.Action
            onClose={() => showM2ProModal(false)}
            selectedApartments={[ newBuildingId ]}
        >
            <Button {...props} onClick={handleClick}>
                Зафиксировать
            </Button>
        </M2ProModalOrderCreation.Action>
    );
};
