import { useMemo } from 'react';

import { NewBuildingSnippet, RouteMetro } from '@search/graphql-typings';

export const useNewBuildingSnippet = (newBuilding: NewBuildingSnippet) => {
    return useMemo(() => {
        const isFrozen = Boolean(newBuilding.buildingStatus?.some(status => status.isFrozen));

        const { originPath, galleryThumbnail } = newBuilding.gallery?.main ?? {};
        const description = newBuilding.title?.nominative ?? '';

        const hero = {
            id: 'mainPhoto',
            src: galleryThumbnail,
            originPath,
            description
        };

        const thumbnails = newBuilding.gallery?.images?.map((photo, index) => ({
            id: index,
            src: photo.galleryThumbnail,
            originPath: photo.originPath,
            description
        })) ?? [];

        const pictures = [ hero, ...thumbnails ];

        const routes = newBuilding.routes?.filter((route: RouteMetro) => route?.station);

        const developer = newBuilding.developerCards && newBuilding.developerCards[0]?.developer;

        return {
            hero,
            routes,
            thumbnails,
            isFrozen,
            developer,
            pictures
        };
    }, [ newBuilding ]);
};
