import { Geo } from '../../Icon';
import { YMapControlButtonTemplate } from '../Button';
import { YMapControl, YMapControlProps } from '../Control';
// @ts-ignore
import { ymaps } from '../../ApiProvider';

type YMapControlGeoLocationProps = YMapControlProps;

const baseClass = 'YMapControlGeoLocation';

export function YMapControlGeoLocation({
    className,
    index,
    dataTest = 'ymap-geolocation',
    title
}: YMapControlGeoLocationProps) {
    YMapControlGeoLocationY.use({
        index,
        dataTest,
        className,
        title
    } as YMapControlGeoLocationProps);

    return null;
}

class YMapControlGeoLocationY extends YMapControl<YMapControlGeoLocationProps, ymaps.control.IGeolocationControl> {
    protected create() {
        return new this.ymaps.control.GeolocationControl({
            options: {
                layout: this.layoutClass()
            }
        }) as unknown as ymaps.control.IGeolocationControl;
    }

    protected template() {
        const { props } = this;

        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            dataTest: props.dataTest,
            className: `${baseClass} ${props.className || ''}`,
            children: `${Geo.str({})}`,
            title: props.title
        });
    }
}
