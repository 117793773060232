import { useMemo } from 'react';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';

export const useIsMskMOSPbLO = (regionId?: number) => useMemo(() => regionId ? [
    RegionIdEnum.MSK,
    RegionIdEnum.MSK_OBL,
    RegionIdEnum.MSK_AND_MSK_OBL,
    RegionIdEnum.SPB,
    RegionIdEnum.LEN_OBL,
    RegionIdEnum.SPB_AND_LEN_OBL
].includes(regionId) : false, [ regionId ]);
