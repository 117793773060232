import { useCallback } from 'react';

import { GqlClientOptions, graphql, useGqlContext } from '@search/gql-client/src';

import {
    NewBuildingQuizRootRegionsQuery$variables,
    NewBuildingQuizRootRegionsQuery$data
} from './__generated__/NewBuildingQuizRootRegionsQuery.graphql';

const rootRegionsQuery = graphql`
query NewBuildingQuizRootRegionsQuery(
    $whitelabel: NewBuildingWhitelabelEnum
    $skipQuery: Boolean!
) {
    newBuildingRootRegions(whitelabel: $whitelabel) @skip(if: $skipQuery) {
        rootRegion {
            id
            translit
            locative
            displayName
            mainName
            bounds {
                min {
                    x
                    y
                }
                max {
                    x
                    y
                }
            }
        }
        regions {
            id
            narrowRegion {
                id
            }
            translit
            locative
            displayName
            mainName
            bounds {
                min {
                    x
                    y
                }
                max {
                    x
                    y
                }
            }
        }
    }
}
`;

export function useRootRegionsFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: NewBuildingQuizRootRegionsQuery$variables, opts?: GqlClientOptions) => gql.client<
            NewBuildingQuizRootRegionsQuery$variables,
            NewBuildingQuizRootRegionsQuery$data
        >(rootRegionsQuery, vars, opts).then(),
        [ gql ]
    );
}
