/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ExecutionResultDataDefault {
    [key: string]: any;
}

export type ExecutionSpan = {
    column: number;
    line: number;
}

export type ExecutionError = {
    message?: string;
    code?: string;
    path?: readonly (string | number)[];
    locations?: readonly ExecutionSpan[];
};

export interface ExecutionResult<TData = ExecutionResultDataDefault> {
    errors?: readonly ExecutionError[];
    data?: TData | null;
    loading?: Promise<void>;
}

export type GqlClientOptions = {
    signal?: AbortSignal;
    tracingTag?: string;
    pathUniqSuffix?: string;
    extraHeaders?: {
        [key: string]: string;
    };
}

export type GqlClientType = <Variables, Response>(query: string, variables: Variables, options?: GqlClientOptions) =>
    Promise<ExecutionResult<Response>>;

export type Cache = Record<string, { error?: null | object | undefined; data?: any }>;

const buildCacheKey = <Variables>(query: string, variables: Variables): string =>
    String(query).replace(/([\r\n])|(\s)/g, '') + JSON.stringify(variables);

export class GqlClient {
    readonly client: GqlClientType;
    readonly cache: Cache = {};
    readonly buildCacheKey = buildCacheKey;

    constructor({ client, cache }: { client: GqlClientType; cache: Cache }) {
        this.client = client;
        this.cache = cache;
    }

    execute<Variables, Response>(query: string, variables: Variables, options?: GqlClientOptions) {
        return this.client<Variables, Response>(query, variables, options)
            .then(result => {
                this.setCache(this.buildCacheKey<Variables>(query, variables), result);

                if (! result.data && result.errors) {
                    throw result;
                }

                return result;
            });
    }

    getCache<V>(key?: string): ExecutionResult<V> | undefined {
        if (! key) return this.cache;

        const part = this.cache[key];

        return part;
    }

    setCache<V>(key: string, data: ExecutionResult<V>) {
        this.cache[key] = data;
    }

    deleteCache(key: string) {
        delete this.cache[key];
    }
}
