import React, { useCallback, useEffect, useState } from 'react';

import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { GqlClientOptions, graphql, useGqlContext } from '@search/gql-client/src';

import { useFavoriteOffersQuery$data as useFavoriteOffersQueryResponse, useFavoriteOffersQuery$variables as useFavoriteOffersQueryVariables } from './__generated__/useFavoriteOffersQuery.graphql';

const favoriteOffersQuery = graphql`
    query useFavoriteOffersQuery($skipFavoriteOffers: Boolean!) {
        favoritePersonal @skip(if: $skipFavoriteOffers) {
            offerId
            added
        }
    }
`;

export type FavoriteOfferIds = Record<string, true>;

type UseFavoriteOffersResponse = {
    favoriteOfferIds: FavoriteOfferIds;
    clearCache: () => void;
    isLoading: boolean;
    addFavoriteOfferId: (offerId: string) => void;
    deleteFavoriteOfferId: (offerId: string) => void;
}

function useFavoriteOffersFetcher() {
    const gql = useGqlContext();

    return React.useCallback(
        (vars: useFavoriteOffersQueryVariables, opts?: GqlClientOptions) => gql.client<
            useFavoriteOffersQueryVariables,
            useFavoriteOffersQueryResponse
        >(favoriteOffersQuery, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export const useFavoriteOffers = (vars: useFavoriteOffersQueryVariables): UseFavoriteOffersResponse => {
    const favoriteOffersLoader = useGql2Loader(useFavoriteOffersFetcher(), {
        cachePrefix: 'useFavoriteOffers',
        authTrack: true
    });
    const response = favoriteOffersLoader(vars);

    useEffect(() => {
        const { favoritePersonal = [] } = response.data ?? {};
        const favoriteOffersData: Record<string, true> = favoritePersonal.reduce((res, item) => {
            res[item.offerId] = true;

            return res;
        }, {} as FavoriteOfferIds);

        setFavoriteOfferIds(favoriteOffersData);
    }, [ response.data ]);

    const [ favoriteOfferIds, setFavoriteOfferIds ] = useState<FavoriteOfferIds>({});

    const addFavoriteOfferId = useCallback((offerId: string) => {
        setFavoriteOfferIds(prev => ({
            ...prev,
            [offerId]: true
        }));
    }, []);

    const deleteFavoriteOfferId = useCallback((offerId: string) => {
        const favoriteOffersCopy = { ...favoriteOfferIds };

        delete favoriteOffersCopy[offerId];

        setFavoriteOfferIds(favoriteOffersCopy);
    }, [ favoriteOfferIds ]);

    return {
        favoriteOfferIds,
        clearCache: response.clearCache,
        isLoading: response.loading,
        addFavoriteOfferId,
        deleteFavoriteOfferId
    };
};
