import React from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import { NewBuildingCardQueryResponse } from '../../../../common/components/NewBuildingCard';

import NewBuildingCardInspectionStatus from '../NewBuildingCardInspectionStatus';

import './styles.css';

interface IProps {
    newBuildingTitle: NewBuildingCardQueryResponse['newBuilding']['title']['nominative'];
    inspectionStatus?: NewBuildingCardQueryResponse['newBuilding']['inspectionStatus'];
}

const cn = classname.bind(null, 'NewBuildingCardHeading');

const NewBuildingCardHeading = ({
    newBuildingTitle,
    inspectionStatus
}: IProps) => (
    <div className={cn()}>
        {inspectionStatus && (
            <div className={cn('status')}>
                <NewBuildingCardInspectionStatus inspectionStatus={inspectionStatus} />
            </div>
        )}
        <Typography
            tag='h1'
            variant='h1'
        >
            {newBuildingTitle}
        </Typography>
    </div>
);

export default React.memo(NewBuildingCardHeading);
