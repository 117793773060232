export type BackofficeRegionOption = {
    label: string;
    value: string;
    geoId: number | undefined;
}

// по хорошему нужно брать регионы из
// https://gitlab.m2.ru/vtblife/backend/mortgage/registratura/-/blob/master/shared/src/main/resources/reference.conf
// но из-за сложности деклоративной формы из DECL, приходится добавлять и там и тут.
const backOfficeRegions: BackofficeRegionOption[] = [
    { label: 'Москва', value: '3', geoId: 3 as undefined | number },
    { label: 'Московская область', value: '4', geoId: 108 },
    { label: 'Санкт-Петербург', value: '1', geoId: 444 },
    { label: 'Ленинградская область', value: '2', geoId: 886 },
    { label: 'Краснодарский край', value: '5', geoId: 741 },
    { label: 'Адыгея', value: '6', geoId: 3355 },
    { label: 'Ростовская область', value: '7', geoId: 1247 },
    { label: 'Республика Татарстан', value: '8', geoId: 1042 },
    { label: 'Свердловская область', value: '9', geoId: 991 },
    { label: 'Тюменская область', value: '10', geoId: 1096 },
    { label: 'Новосибирская область', value: '11', geoId: 1308 },
    { label: 'Воронежская область', value: '12', geoId: 585 },
    { label: 'Калининградская область', value: '13', geoId: 12064 },
    { label: 'Нижегородская область', value: '14', geoId: 1959 },
    { label: 'Красноярский край', value: '15', geoId: 2025 },
    { label: 'Чувашия', value: '16', geoId: undefined },
    { label: 'Рязанская область', value: '17', geoId: 1182 },
    { label: 'Ярославская область', value: '18', geoId: 677 },
    { label: 'Волгоградская область', value: '19', geoId: 755 },
    { label: 'Республика Башкортостан', value: '20', geoId: 3492 },
    { label: 'Челябинская область', value: '21', geoId: 2796 },
    { label: 'Вологодская область', value: '22', geoId: 12394 },
    { label: 'Оренбургская область', value: '23', geoId: 4598 },
    { label: 'Самарская область', value: '24', geoId: 3337 },
    { label: 'Алтай Республика', value: '25', geoId: 59352 },
    { label: 'Алтайский край', value: '26', geoId: 23503 },
    { label: 'Амурская область', value: '27', geoId: 40764 },
    { label: 'Архангельская область', value: '28', geoId: 6565 },
    { label: 'Астраханская область', value: '29', geoId: 649 },
    { label: 'Белгородская область', value: '30', geoId: 3810 },
    { label: 'Брянская область', value: '31', geoId: 1355 },
    { label: 'Бурятия', value: '32', geoId: undefined },
    { label: 'Владимирская область', value: '33', geoId: 3409 },
    { label: 'Дагестан', value: '34', geoId: 37866 },
    { label: 'Еврейская автономная область', value: '35', geoId: undefined },
    { label: 'Забайкальский край', value: '36', geoId: 171367 },
    { label: 'Ивановская область', value: '37', geoId: 494 },
    { label: 'Иркутская область', value: '39', geoId: 958 },
    {
        label: 'Кабардино-Балкарская Республика',
        value: '40',
        geoId: 181292
    },
    { label: 'Калмыкия', value: '41', geoId: 161693 },
    { label: 'Калужская область', value: '42', geoId: 792 },
    { label: 'Камчатский край', value: '43', geoId: undefined },
    { label: 'Карачаево-Черкесская Республика', value: '44', geoId: 171951 },
    { label: 'Карелия', value: '45', geoId: undefined },
    { label: 'Кемеровская область', value: '46', geoId: 2089 },
    { label: 'Кировская область', value: '47', geoId: 3273 },
    { label: 'Коми', value: '48', geoId: undefined },
    { label: 'Костромская область', value: '49', geoId: 1201 },
    { label: 'Крым', value: '50', geoId: undefined },
    { label: 'Курганская область', value: '51', geoId: 2296 },
    { label: 'Курская область', value: '52', geoId: 1302 },
    { label: 'Липецкая область', value: '53', geoId: 1332 },
    { label: 'Магаданская область', value: '54', geoId: undefined },
    { label: 'Марий Эл', value: '55', geoId: undefined },
    { label: 'Мордовия', value: '56', geoId: 30400 },
    { label: 'Мурманская область', value: '57', geoId: undefined },
    { label: 'Новгородская область', value: '58', geoId: 5258 },
    { label: 'Омская область', value: '59', geoId: 5145 },
    { label: 'Орловская область', value: '60', geoId: 46369 },
    { label: 'Пензенская область', value: '61', geoId: 5403 },
    { label: 'Пермский край', value: '62', geoId: 763 },
    { label: 'Приморский край', value: '63', geoId: 3522 },
    { label: 'Псковская область', value: '64', geoId: 4771 },
    { label: 'Саратовская область', value: '65', geoId: 1970 },
    { label: 'Саха', value: '66', geoId: undefined },
    { label: 'Сахалинская область', value: '67', geoId: 27698 },
    { label: 'Севастополь', value: '68', geoId: undefined },
    { label: 'Северная Осетия — Алания', value: '69', geoId: 169866 },
    { label: 'Смоленская область', value: '70', geoId: 3895 },
    { label: 'Ставропольский край', value: '71', geoId: 1999 },
    { label: 'Тамбовская область', value: '72', geoId: 1362 },
    { label: 'Тверская область', value: '73', geoId: 706 },
    { label: 'Томская область', value: '74', geoId: 40173 },
    { label: 'Тульская область', value: '75', geoId: 869 },
    { label: 'Тыва', value: '76', geoId: 170627 },
    { label: 'Удмуртская Республика', value: '77', geoId: 4705 },
    { label: 'Ульяновская область', value: '78', geoId: 2288 },
    { label: 'Хабаровский край', value: '79', geoId: 3304 },
    { label: 'Хакасия', value: '80', geoId: undefined },
    { label: 'Чеченская Республика', value: '81', geoId: 22045 },
    { label: 'Чукотский автономный округ', value: '82', geoId: undefined },
    {
        label: 'Ханты-Мансийский автономный округ',
        value: '83',
        geoId: 2147
    },
    { label: 'Ненецкий автономный округ', value: '84', geoId: 41177 },
    { label: 'Ямало-Ненецкий автономный округ', value: '85', geoId: 12864 },
    { label: 'Республика Ингушетия', value: '86', geoId: 310588 },
    { label: 'Байконур', value: '87', geoId: 159 },
    { label: 'Донецкая Народная респ', value: '88', geoId: 267133 },
    { label: 'Луганская Народная респ', value: '89', geoId: 222 }

];

class BackofficeRegionRepository {
    constructor(
        protected regions = backOfficeRegions,
        protected regionMap = new Map(regions.map(item => [ item.value, item ])),
        protected regionGeoMap = new Map(regions.map(item => [ item.geoId, item ]))
    ) {
        this.regions = regions.sort(
            (a, b) => Number(a.value) <= 4 || Number(b.value) <= 4 ? 0 : a.label.localeCompare(b.label)
        );
        this.regionMap = regionMap;
        this.regionGeoMap = regionGeoMap;

        const spb = regions.find(item => item.value === '1');

        if (spb) regionGeoMap.set(9514, spb); // spb + obl

        const msk = regions.find(item => item.value === '3');

        if (msk) regionGeoMap.set(9513, msk); // msk + obl
    }

    byBackOfficeId(id: string | number) {
        return this.regionMap.get(String(id));
    }

    byGeoId(id: number) {
        return this.regionGeoMap.get(id);
    }

    filterByLabel(labelPart: string) {
        const searchQuery = labelPart.toLocaleLowerCase();

        return this.regions.filter(({ label }) => label.toLocaleLowerCase().includes(searchQuery));
    }

    get labels() {
        return this.regions.map(item => item.label);
    }

    get values() {
        return this.regions.map(item => item.value);
    }
}

export const backofficeRegionRepository = new BackofficeRegionRepository();

export const DEFAULT_RESULTS_ON_PAGE = 100;
export const MAX_RESULTS_ON_PAGE = 1000;

export const KEYBOARD_EVENT_KEYS = {
    Enter: 'Enter'
};
