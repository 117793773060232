const legacySlugs = [
    [
        'primorskii-krai',
        'primorskiy-kray'
    ],
    [
        'komi',
        'respublika-komi'
    ],
    [
        'krasnodarskii-krai',
        'krasnodarskiy-kray'
    ],
    [
        'bashkortostan',
        'respublika-bashkortostan'
    ],
    [
        'tatarstan',
        'respublika-tatarstan'
    ],
    [
        'permskii-krai',
        'permskiy-kray'
    ],
    [
        'krasnoyarskii-krai',
        'krasnoyarskiy-kray'
    ],
    [
        'respublika-krim',
        'respublika-krym'
    ],
    [
        'kareliya',
        'respublika-kareliya'
    ],
    [
        'khabarovskii-krai',
        'khabarovskiy-kray'
    ],
    [
        'dagestan',
        'respublika-dagestan'
    ],
    [
        'stavropolskii-krai',
        'stavropolskiy-kray'
    ],
    [
        'severnaya-osetiya-alaniya',
        'respublika-severnaya-osetiya-alaniya'
    ],
    [
        'sakha',
        'respublika-sakha-yakutiya'
    ],
    [
        'khakasiya',
        'respublika-khakasiya'
    ],
    [
        'kabardino-balkariya',
        'kabardino-balkarskaya-respublika'
    ],
    [
        'buryatiya',
        'respublika-buryatiya'
    ],
    [
        'marii-el',
        'respublika-mariy-el'
    ],
    [
        'zabaikalskii-krai',
        'zabaykalskiy-kray'
    ],
    [
        'khanti-mansiiskii-ao',
        'khanty-mansiyskiy-avtonomnyy-okrug'
    ],
    [
        'chuvashiya',
        'chuvashskaya-respublika'
    ],
    [
        'yamalo-nenetskii-ao',
        'yamalo-nenetskiy-avtonomnyy-okrug'
    ],
    [
        'udmurtiya',
        'udmurtskaya-respublika'
    ],
    [
        'adigeya',
        'respublika-adygeya'
    ],
    [
        'altaiskii-krai',
        'altayskiy-kray'
    ],
    [
        'mordoviya',
        'respublika-mordoviya'
    ],
    [
        'nizhnii-novgorod',
        'nizhniy-novgorod'
    ]
] as [string, string][];

const legacySlugToNewMap = new Map(legacySlugs);

export function legacySlugToNew(slug: string) {
    return legacySlugToNewMap.get(slug);
}

const newSlugToLegacyMap = new Map(legacySlugs.map(rec => [ rec[1], rec[0] ]));

export function newSlugToLegacy(slug: string) {
    return newSlugToLegacyMap.get(slug);
}
