/* global ymaps */
import { FullscreenOn, Close } from '../../Icon';
import { YMapControlButtonTemplate } from '../Button';
import { YMapControlCheckY, YMapControlCheckProps, YMapControlCheckContent } from '../Check';

const baseClass = 'YMapControlFullscreen';

const CONTENT: YMapControlCheckContent = [
    FullscreenOn.str({}),
    Close.str({})
];

type YMapControlFullscreenProps = YMapControlCheckProps;

export function YMapControlFullscreen({
    className,
    index,
    title,
    setFullscreen,
    dataTest = 'ymap-fullscreen'
}: YMapControlFullscreenProps) {
    YMapControlFullscreenY.use({
        index,
        dataTest,
        className,
        content: CONTENT,
        hasInactiveView: true,
        title,
        setFullscreen
    } as YMapControlFullscreenProps);

    return null;
}

class YMapControlFullscreenY extends YMapControlCheckY<
    YMapControlFullscreenProps,
    ymaps.control.IFullscreenControl
> {
    protected create() {
        const options = {
            layout: this.layoutClass()
        } as unknown as ymaps.control.IFullscreenControlParameters['options'];

        return (
            new this.ymaps.control.FullscreenControl({
                state: this.state(),
                options
            }) as unknown
        ) as ymaps.control.IFullscreenControl;
    }

    protected template() {
        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            dataTest: this.props.dataTest,
            className: `${baseClass} ${this.checkClass}`,
            children: CONTENT[0],
            title: this.props.title,
            setFullscreen: this.props.setFullscreen
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        const setFullscreen = this.props?.setFullscreen;

        return class YMapControlFullscreenLayout extends Layout {
            protected toggle(isFull: boolean) {
                const container = this.map.container;

                if (isFull) {
                    container.exitFullscreen();
                    setFullscreen?.(true);
                } else {
                    container.enterFullscreen();
                    setFullscreen?.(false);
                }
            }
        };
    }
}
