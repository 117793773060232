/* global window */
import useIsSsr from './useIsSsr';

type Params = {
    /**
     * Регион покупки недвижимости
     */
    regionId?: number;
    /**
     * Вид кредита
     */
    purpose?: number;
    /**
     * Стоимость недвижимости (квартиры/минимальная стоимость квартиры в ЖК)
     */
    propertyCost?: number;
    /**
     * Объект (название ЖК)
     */
    newBuildingName?: string;
    /**
     * Вид недвижимости (квартира/апартаменты)
     */
    claimType?: number;
    /**
     * Первоначальный взнос
     */
    ownAmount?: number;
}

const MORTGAGE_URL = '/ipoteka/demand/';

/**
 * Формирование ссылки на ипотечную заявку вида https://m2.ru/ipoteka/demand/{query}
 */
const useMortgageDemandLink = ({
    regionId,
    purpose,
    propertyCost,
    newBuildingName,
    claimType,
    ownAmount
}: Params) => {
    const isSsr = useIsSsr();

    if (isSsr) {
        return undefined;
    }

    const base = window.location.origin;

    const url = new URL(MORTGAGE_URL, base);

    const urlParams = new URLSearchParams({
        regionId: regionId?.toString() ?? '',
        purpose: purpose?.toString() ?? '',
        propertyCost: propertyCost?.toString() ?? '',
        propertyName: newBuildingName ?? '',
        propertyType: claimType?.toString() ?? '',
        ownAmount: ownAmount?.toString() ?? ''
    });

    url.search = urlParams.toString();

    return url.toString();
};

export default useMortgageDemandLink;
