import React from 'react';

import type { DeveloperCard } from '@search/graphql-typings';

import classname from '@search/classname/src';

import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { QRCodePhoneButton } from '@search/vtbeco-frontend-core/view/common/components/QRCodePhoneButton';

import { usePhoneButtonContext } from '../../../../../../client/common/components/NewBuildingCard/PhoneButtonStateProvider';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardDeveloper');

export interface NewBuildingCardDeveloperProps {
    title: NonNullable<DeveloperCard['developer']>['title'];
    imageUrl: string;
}

const NewBuildingCardDeveloper: React.FunctionComponent<NewBuildingCardDeveloperProps> = props => {
    const { buttonsData, firstDeveloperId, handleClick } = usePhoneButtonContext();

    return (
        <div className={cn()}>
            <div className={cn('developer')}>
                <div className={cn('info')}>
                    <CustomTypography>
                        Застройщик
                    </CustomTypography>
                    <CustomTypography level='h4'>
                        {props.title}
                    </CustomTypography>
                </div>
                {
                    props.imageUrl ? (
                        <div className={cn('logo')}>
                            <Image
                                width={52}
                                height={52}
                                type='contain'
                                url={props.imageUrl}
                            />
                        </div>
                    ) : null
                }
            </div>
            {firstDeveloperId && (
                <QRCodePhoneButton
                    variant='secondary'
                    href={buttonsData[firstDeveloperId].href}
                    onClick={() => handleClick(firstDeveloperId)}
                    qrCodeValue={buttonsData[firstDeveloperId].qrCodeValue}
                    loading={buttonsData[firstDeveloperId].isLoading}
                >
                    {buttonsData[firstDeveloperId].text}
                </QRCodePhoneButton>
            )}
        </div>
    );
};

export default React.memo(NewBuildingCardDeveloper);
