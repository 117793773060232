import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';

import Grid from '@vtblife/uikit-grid';

import {
    getInitialOfferFilters as getInitialExceptForBuildingsFilters
} from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewBuildingFilterInitial';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';

import {
    Filters,
    IFilter,
    CommissioningListFilter,
    PriceFilter,
    BuildingsFilter
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import {
    sellFlatPricesPerAllSquareMsk,
    sellFlatPricesPerAllSquareSpb,
    sellFlatPricesPerMeterMskMo,
    sellFlatPricesPerMeterSpbLo
} from '@search/vtbeco-frontend-core/view/filters/helpers/priceHelper';
import type { PredefinedPrice } from '@search/vtbeco-frontend-core/view/filters/types/PredefinedPrice';
import { DesktopRoomsTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/rooms/DesktopRoomsTypeFilterView';
import { DesktopPriceFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/price/DesktopPriceFilterView';
import { DesktopBuildingsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/buildings/DesktopBuildingsFilterView';
import { DesktopAreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/area/DesktopAreaFilterView';
import { DesktopFloorFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/floor/DesktopFloorFilterView';
import { DesktopFinishingFlatTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/finishing/DesktopFinishingFlatTypeFilterView';
import { DesktopCommissioningListFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/commissioning_list/DesktopCommissioningListFilterView';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { OffersSearchTips } from '@search/vtbeco-frontend-core/view/tips/OffersSearchTips';
import type { QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { AreaUnit } from '@search/filter-enums/enums';
import classname from '@search/classname/src';
import type { SelectFinishingFlatOption } from '@search/graphql-typings/src';

import './styles.css';

const OffersSearchTipsWitchErrorCatcher = errorCatcherWrap(OffersSearchTips);

const cn = classname.bind(null, 'NewBuildingCardOfferSearchFilters');

const NewBuildingCardOfferSearchFilters = ({
    filters,
    updateFilter: updateFilterProp,
    updateMultipleFilters: updateMultipleFiltersProp,
    removeFilter: removeFilterProp,
    resetFilters: resetFiltersProp,
    handleChange,
    showBuildingPicker,
    commissioningOptions,
    queryDisplayValues,
    isMskMO,
    isSPbLO,
    noBuildingPicker,
    noOffers,
    hasCustomOfferFilterSize,
    finishingOptions
}: {
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateMultipleFilters: (multipleFilters: IFilter[]) => void;
    removeFilter: (filter: IFilter) => void;
    resetFilters: () => void;
    handleChange: () => void;
    showBuildingPicker: () => void;
    commissioningOptions: { value: string; label: string }[];
    isMskMO: boolean;
    isSPbLO: boolean;
    noBuildingPicker: boolean;
    noOffers: boolean;
    hasCustomOfferFilterSize: boolean;
    queryDisplayValues?: QueryDisplayValues;
    finishingOptions: SelectFinishingFlatOption[];
}) => {
    const updateFilter = useCallback(debounce((filter: IFilter) => { // eslint-disable-line
        updateFilterProp(filter);
        handleChange();
    }, 300), [ updateFilterProp, handleChange ]);
    const updateCommissioningListFilter = useCallback((filter: CommissioningListFilter) => {
        updateFilterProp(filter);
        updateFilterProp(new BuildingsFilter([]));
        handleChange();
    }, [ updateFilterProp, handleChange ]);
    const updateMultipleFilters = useCallback(debounce((multipleFilters: IFilter[]) => { // eslint-disable-line
        updateMultipleFiltersProp(multipleFilters);
        handleChange();
    }, 300), [ updateMultipleFiltersProp, handleChange ]);
    const removeFilter = useCallback((filter: IFilter) => {
        removeFilterProp(filter);
        handleChange();
    }, [ removeFilterProp, handleChange ]);
    const resetFilters = useCallback(() => {
        resetFiltersProp();
        handleChange();
    }, [ resetFiltersProp, handleChange ]);

    const [ hasTips, setHasTips ] = useState<boolean>(false);

    const handleTipsLengthUpdate = useCallback((length: number) => {
        setHasTips(length > 0);
    }, []);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ areTipsExpanded, expandTips ] = useState<boolean>(false);

    if (noOffers) {
        return noBuildingPicker ? null : (
            <div className={cn()}>
                <DesktopBuildingsFilterView
                    standalone
                    showBuildingPicker={showBuildingPicker}
                />
            </div>
        );
    }

    const priceFilter = filters.get<PriceFilter>(Filters.price);
    const isPricePerMeter = priceFilter.priceType === AreaUnit.METER;
    let pricePresets: PredefinedPrice[] = [];

    if (isMskMO) {
        pricePresets = isPricePerMeter ?
            sellFlatPricesPerMeterMskMo :
            sellFlatPricesPerAllSquareMsk;
    } else if (isSPbLO) {
        pricePresets = isPricePerMeter ?
            sellFlatPricesPerMeterSpbLo :
            sellFlatPricesPerAllSquareSpb;
    } else {
        pricePresets = [];
    }

    return (
        <div className={cn(null, { view: 'full' })}>
            <div className={cn('content')}>
                <div className={cn('topPanel')}>
                    {
                        commissioningOptions.length >= 1 ? (
                            <div className={cn('topPanelItem')}>
                                <DesktopCommissioningListFilterView
                                    filter={filters.get(Filters.commissioningList)}
                                    onChange={updateCommissioningListFilter}
                                    options={commissioningOptions}
                                />
                            </div>
                        ) : null
                    }
                    {finishingOptions.length > 0 && (<div className={cn('topPanelItem')}>
                        <DesktopFinishingFlatTypeFilterView
                            filter={filters.get(Filters.finishingFlat)}
                            onChange={updateFilter}
                            options={finishingOptions}
                        />
                    </div>)}
                    <div className={cn('topPanelItem')}>
                        <DesktopFloorFilterView
                            filter={filters.get(Filters.floor)}
                            onChange={updateFilter}
                        />
                    </div>
                </div>
                <div className={cn('bottomPanel')}>
                    <Grid
                        cols={24}
                        gap={false}
                    >
                        <Grid.Cell m={noBuildingPicker ? 6 : 5}>
                            <div className={cn('bottomPanelItem', { rooms: true })}>
                                <DesktopRoomsTypeFilterView
                                    filter={filters.get(Filters.rooms)}
                                    onChange={updateFilter}
                                />
                            </div>
                        </Grid.Cell>
                        <Grid.Cell
                            {...(hasCustomOfferFilterSize ? {
                                m: noBuildingPicker ? 9 : 8
                            } : {
                                m: noBuildingPicker ? 10 : 9
                            })}
                            xl={noBuildingPicker ? 10 : 9}
                        >
                            <div className={cn('bottomPanelItem', { withBorder: true })}>
                                <DesktopPriceFilterView
                                    filter={priceFilter}
                                    pricePreset={pricePresets}
                                    priceTypes={[ AreaUnit.UNKNOWN, AreaUnit.METER ]}
                                    onChange={updateFilter}
                                    isLarge
                                />
                            </div>
                        </Grid.Cell>
                        <Grid.Cell m={noBuildingPicker ? 8 : 5}>
                            <div className={cn('bottomPanelItem', { withBorder: true })}>
                                <DesktopAreaFilterView
                                    totalAreaFilter={filters.get(Filters.totalArea)}
                                    livingAreaFilter={filters.get(Filters.livingArea)}
                                    kitchenAreaFilter={filters.get(Filters.kitchenArea)}
                                    onChange={updateMultipleFilters}
                                />
                            </div>
                        </Grid.Cell>
                        {
                            noBuildingPicker ? null : (
                                <Grid.Cell
                                    m={hasCustomOfferFilterSize ? 6 : 5}
                                    xl={5}
                                >
                                    <div className={cn('bottomPanelItem', { withBorder: true })}>
                                        <DesktopBuildingsFilterView showBuildingPicker={showBuildingPicker} />
                                    </div>
                                </Grid.Cell>
                            )
                        }
                    </Grid>
                </div>
            </div>
            <div className={cn('tipsContainer', { expanded: hasTips })}>
                <div className={cn('tipsPopup', { expanded: areTipsExpanded })}>
                    <div className={cn('tipsLimiter')}>
                        <OffersSearchTipsWitchErrorCatcher {...{
                            filters: getInitialExceptForBuildingsFilters(filters),
                            updateFilter,
                            removeFilter,
                            queryDisplayValues,
                            expandFilter: expandTips,
                            reset: resetFilters,
                            onUpdateLength: handleTipsLengthUpdate,
                            onWhiteBackground: false
                        }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewBuildingCardOfferSearchFilters;
