/* global window */
import Cookies from 'universal-cookie';

const USER_REGION_ID_SELECTED_COOKIE = 'user_region_id_selected';
const USER_REGION_ID_DETECTED_COOKIE = 'user_region_id_detected';
const USER_REGION_ID_SELECTED_COOKIE_SEPARATOR = '-';

export class GeoUserRegion {
    protected cookies: Cookies;

    constructor(p: { rawCookie?: string } = {}) {
        this.cookies = new Cookies(p.rawCookie);
    }

    values(name = USER_REGION_ID_SELECTED_COOKIE): readonly (number | undefined)[] {
        const v: string | undefined = this.cookies.get(name);

        return v
            ?.split(USER_REGION_ID_SELECTED_COOKIE_SEPARATOR)
            .map(Number).filter(num => ! Number.isNaN(num)) ?? [];
    }

    get userRegionId() {
        const [ regionSelected ] = this.values();
        const [ regionDetected ] = this.values(USER_REGION_ID_DETECTED_COOKIE);

        return regionSelected ?? regionDetected;
    }

    setUserRegionId(userRegionId: number) {
        this.cookies.set(USER_REGION_ID_SELECTED_COOKIE, String(userRegionId), {
            expires: SELECTED_COOKIE_MAX_AGE,
            domain: getCookieDomain(typeof window === 'undefined' ? 'localhost' : window.location.hostname),
            path: '/'
        });
    }
}

const extractSecondLevelDomain = (hostname: string): string => {
    const subdomains = hostname.split('.');

    if (subdomains.length === 1) {
        return hostname;
    }
    return subdomains.slice(-2).join('.');
};

function getCookieDomain(hostname: string) {
    if (hostname === 'localhost') return;
    if (hostname.includes('ingress')) return `.${hostname.substring(hostname.indexOf('ingress'))}`;
    return `.${extractSecondLevelDomain(hostname)}`;
}

const expires = new Date();

expires.setFullYear(expires.getFullYear() + 1);

const SELECTED_COOKIE_MAX_AGE = expires;
