export enum AuthorTypeOptions {
    UNKNOWN = 0,
    OWNER = 1,
    AGENCY = 2,
    PARTNER = 3,
    DEVELOPER = 4,
    PIT = 5, // TODO HOT Это не норма!!!
}

export enum AuthorTypeOptionsWithRealtor {
    UNKNOWN = 0,
    OWNER = 1,
    AGENCY = 2,
    PARTNER = 3,
    DEVELOPER = 4,
    REALTOR = 5,
    PIT = 6, // TODO HOT Это не норма!!!
}
