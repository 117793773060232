import React, { useState, useCallback } from 'react';
import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'Tab');

const Tab: React.FunctionComponent<{
    open?: boolean;
    onOpen?: () => void;
    renderTitle: (isOpen: boolean) => React.ReactNode;
    className?: string;
}> = props => {
    const [ open, toggleOpen ] = useState<boolean>(Boolean(props.open));

    const handleToggleTab = useCallback(() => {
        toggleOpen((prevState: boolean) => {
            if (props.onOpen && ! prevState) {
                props.onOpen();
            }

            return ! prevState;
        });
    }, [ props.onOpen ]); // eslint-disable-line

    return (
        <div className={cn(null, { open })}>
            <div
                className={cn('title', undefined, props.className)}
                onClick={handleToggleTab}
            >
                {props.renderTitle(open)}
            </div>
            <div className={cn('inner', { open })}>
                {props.children}
            </div>
        </div>
    );
};

export default Tab;
