import React, { useState } from 'react';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { SnippetProviderContext } from '@search/vtbeco-frontend-core/domain/newbuilding/components/desktop/NewBuildingBaseSnippet/snippetContext';
import { getDeveloperBannerGtmData } from '@search/vtbeco-frontend-core/domain/newbuilding/developer-promo/analytics';
import { errorCatcherWrap } from '@search/error/src/catcher';
import NewBuildingPromoBanner from '@search/vtbeco-frontend-core/view/desktop/components/NewBuildingPromoBanner';
import { NewBuildingSnippet } from '@search/graphql-typings';

import { notEmpty } from '../../../../types';
import type { Shared } from '../../../../types/shared';

import {
    newBuildingSnippets,
    newBuildingSearchSnippet,
    NewBuildingSearchQueryResponse
} from '../../../common/components/NewBuildingSearch';
import MockSnippet from '../../../desktop/components/MockSnippet';

import NewBuildingSerpSnippet from '../NewBuildingSerpSnippet';
import NewBuildingPromoSnippet from '../NewBuildingPromoSnippet';
import SamoletPlusSerpBanner from '../SamoletPlusSerpBanner';
import MortgagePromoBanner from '../../../common/components/MortgagePromoBanner';
import QuickSelectionSerpBanner from '../QuickSelection/QuickSelectionSerpBanner';

import { NewBuildingEmptySearch } from '../NewBuildingSearchPage/NewBuildingEmptySearch';
import './styles.css';

const NewBuildingSerpSnippetWithCatcher = errorCatcherWrap(NewBuildingSerpSnippet);
const NewBuildingPromoBannerWithCatcher = errorCatcherWrap(NewBuildingPromoBanner);
const NewBuildingPromoSnippetWithCatcher = errorCatcherWrap(NewBuildingPromoSnippet);

const SnippetList: React.FunctionComponent<{
    snippets: newBuildingSnippets;
    pageParams: Shared.ISearchParams;
    resetFilters: (resetGeo?: boolean) => void;
    clearSerpCache?: (refresh?: boolean) => void;
    openQuizModal: () => void;
}> = ({
    snippets,
    pageParams,
    resetFilters,
    clearSerpCache,
    openQuizModal
}) => {
    const [ itemWithOpenedMap, setItemWithOpenedMap ] = useState<string | undefined>(undefined);

    if (! snippets.length) {
        return <NewBuildingEmptySearch resetFilters={resetFilters} />;
    }

    const items = snippets
        .filter(notEmpty)
        .map((item: newBuildingSearchSnippet, idx) => {
            switch (item.__typename) {
                case 'NewBuildingSnippet': {
                    return (
                        <li
                            key={`${idx}-${item.id}`}
                            className='NewBuildingSearch__list-item'
                        >
                            <SnippetProviderContext>
                                <NewBuildingSerpSnippetWithCatcher
                                    newBuilding={item as NewBuildingSnippet}
                                    pageParams={pageParams}
                                    clearSerpCache={clearSerpCache}
                                    withLazyLoad={idx !== 0}
                                    itemWithOpenedMap={itemWithOpenedMap}
                                    setItemWithOpenedMap={setItemWithOpenedMap}
                                />
                            </SnippetProviderContext>
                        </li>
                    );
                }

                case 'NewBuildingSearchPromoBanner': {
                    return item.isActive ? (
                        <NewBuildingPromoBannerWithCatcher
                            key={`${idx}-${item.id}`}
                            view='nb'
                            id={Number(item.id)}
                            disclaimerList={item.disclaimerList}
                            developerName={item.developerName}
                            developerLogo={item.logoColor}
                            dataGTM={{ click: DATA_GTM.PROMO_BANNER }}
                            gtmClickData={getDeveloperBannerGtmData(item.developerName, {
                                action: 'click',
                                section: 'promo_block',
                                pageType: 'serp'
                            })}
                            gtmShowData={getDeveloperBannerGtmData(item.developerName, {
                                action: 'view',
                                section: 'promo_block',
                                pageType: 'serp'
                            })}
                            erid={item.erid}
                        />
                    ) : null;
                }

                case 'NewBuildingPromoSnippet': {
                    return item.isActive ? (
                        <NewBuildingPromoSnippetWithCatcher
                            key={`${idx}-${item.id}`}
                            id={Number(item.id)}
                            disclaimer={item.disclaimer}
                            developerName={item.developerName}
                            developerLogo={item.logoWhite}
                            dataGTM={{
                                click: DATA_GTM.PROMO_SNIPPET,
                                phoneClick: DATA_GTM.PROMO_SNIPPET_PHONE
                            }}
                            gtmClickData={getDeveloperBannerGtmData(item.developerName, {
                                action: 'click',
                                section: 'spec_snippet',
                                pageType: 'serp'
                            })}
                            gtmShowData={getDeveloperBannerGtmData(item.developerName, {
                                action: 'view',
                                section: 'spec_snippet',
                                pageType: 'serp'
                            })}
                            erid={item.erid}
                        />
                    ) : null;
                }

                case 'NewBuildingSearchSamoletPlusBanner': {
                    return item.isActive ? (
                        <SamoletPlusSerpBanner
                            key={`${idx}-samoletPlus`}
                            bannerData={{
                                title: item.caption,
                                subtitle: item.subtitle,
                                buttonText: item.buttonText,
                                url: item.url,
                                image: item.imageDesktop
                            }}
                            developerLogo={item.logoWhite}
                        />
                    ) : null;
                }

                case 'NewBuildingSearchMortgagePromoBanner': {
                    return item.isActive ? (
                        <MortgagePromoBanner
                            key={`${idx}-mortgagePromo`}
                            title={item.caption}
                            subtitle={item.subtitle}
                            buttonText={item.buttonText}
                            imageUrl={item.imageDesktop}
                            badgeText={item.badgeText}
                            regionId={pageParams.region}
                        />
                    ) : null;
                }

                case 'NewBuildingSearchQuickSelectionBanner': {
                    return item.isActive && (
                        <QuickSelectionSerpBanner
                            key={`${idx}-quickSelectionSerpBanner`}
                            openQuizModal={openQuizModal}
                        />
                    );
                }

                default:
                    return null;
            }
        });

    return (
        <ul
            className='NewBuildingSearch__list'
            data-test='nb-snippets'
        >
            {items}
        </ul>
    );
};

const NewBuildingSearch: React.FunctionComponent<{
    loading: boolean;
    searchNewBuildings: NewBuildingSearchQueryResponse['searchNewBuildings'];
    pageParams: Shared.ISearchParams;
    resetFilters: (resetGeo?: boolean) => void;
    openQuizModal: () => void;
    clearCache?: (refresh?: boolean) => void;
}> = ({
    loading,
    searchNewBuildings,
    pageParams,
    clearCache,
    resetFilters,
    openQuizModal
}) => {
    let content = null;

    if (loading && ! searchNewBuildings) {
        content = Array.from({ length: 10 }).map(() => <MockSnippet key={Math.random()} />);
    } else if (searchNewBuildings && searchNewBuildings.items) {
        content = (
            <SnippetList
                snippets={searchNewBuildings.items}
                pageParams={pageParams}
                resetFilters={resetFilters}
                clearSerpCache={clearCache}
                openQuizModal={openQuizModal}
            />
        );
    }

    return (
        <div className='NewBuildingSearch'>
            {content}
        </div>
    );
};

export default React.memo(NewBuildingSearch);
