import { graphql, GqlClient, GqlClientOptions } from '@search/gql-client/src';

import type { clGeoSlugsQuery$variables as clGeoSlugsQueryVariables, clGeoSlugsQuery$data as clGeoSlugsQueryResponse } from './__generated__/clGeoSlugsQuery.graphql';

export const clGeoSlugsQuery = graphql`query clGeoSlugsQuery(
    $geos: [GeoSlugInput!]!
) {
    geo2(geos: $geos) {
        items {
            __typename
            ... on BaseLoaderItemError {
                itemId
            }
            ... on GeoObject {
                id
                kind
                translit
                title
                locative
            }
        }
    }
}
`;

//const cachePrefix = 'ClGeoSlugs';

export function createClGeoSlugsFetcher({ gql }: {
    gql: GqlClient;
}) {
    return (
        vars: clGeoSlugsQueryVariables,
        opts?: GqlClientOptions
    ) => {
        return gql.client<clGeoSlugsQueryVariables, clGeoSlugsQueryResponse>(clGeoSlugsQuery, vars, opts);
    };
}
