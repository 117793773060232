import React, { FC } from 'react';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'YMapPlacemarkSubwayPin');

export const YMapPlacemarkSubwayPinIcon: FC<{
    iconName: string;
    iconBgColor: string;
    iconColors: Array<string>;
}> = ({
    iconName,
    iconBgColor,
    iconColors
}) => (
    <div
        className={cn('iconCircle')}
        style={{ backgroundColor: iconBgColor }}
    >
        <div className={cn('iconWrapper')}>
            {
                iconColors.map((color, idx) => (
                    <svg
                        key={idx}
                        height={24}
                        width={24}
                        color={color}
                        fill={iconBgColor}
                        className={cn('icon')}
                    >
                        <use xlinkHref={`#${iconName}`} />
                    </svg>
                ))
            }
        </div>
    </div>
);
