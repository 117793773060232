import React from 'react';
import { Tabs } from '@vtblife/uikit';

export const SerpTabs: React.FC<{
    size?: 's' | 'm';
    isSerp: boolean;
    toggleSerp: (isSerp: boolean) => void;
    hasApartmentsOnly: boolean;
}> = ({
    size = 's',
    isSerp,
    toggleSerp,
    hasApartmentsOnly
}) => (
    <Tabs
        size={size}
        variant='text'
        onChange={tabIndex => toggleSerp(tabIndex === 0)}
    >
        <Tabs.Option value={0} active={isSerp}>{hasApartmentsOnly ? 'Апартаменты' : 'Квартиры'}</Tabs.Option>
        <Tabs.Option value={1} active={! isSerp}>Корпуса</Tabs.Option>
    </Tabs>
);
