import React from 'react';
import { GeoRootRegionRepository } from './GeoRootRegionRepository';

const GeoRootRegionContext = React.createContext(GeoRootRegionRepository.instance);

export function GeoRootRegionProvider(p: { children?: React.ReactNode; value?: GeoRootRegionRepository }) {
    return (<GeoRootRegionContext.Provider value={p.value ?? GeoRootRegionRepository.instance}>
        {p.children}
    </GeoRootRegionContext.Provider>);
}

export function useGeoRootRegion() {
    return React.useContext(GeoRootRegionContext);
}
