import React, { ReactNode, useContext, useEffect } from 'react';

import classname from '@search/classname/src';
import { ApplicationIdEnum, NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { CustomerFeedback } from '@search/vtbeco-frontend-core/view/common/components/CustomerFeedback';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import { useChat } from '../../hooks/useChat';

import { GlobalContext } from '../GlobalContext';
import ShellProvider from '../ShellProvider';

import './styles.css';

const cn = classname.bind(null, 'Page');

interface IPage {
    children: ReactNode | ReactNode[];
    wideHeader?: boolean;
    hasHeader?: boolean;
    hasFooter?: boolean;
    regionId?: number;
    className?: string;
    contentClassName?: string;
    isMobile?: boolean;
    isFullWidth?: boolean;
    withCustomerFeedback?: boolean;
}

const Page: React.FunctionComponent<IPage> = ({
    children,
    wideHeader = false,
    hasFooter = true,
    hasHeader = true,
    regionId,
    className,
    contentClassName,
    isMobile,
    isFullWidth,
    withCustomerFeedback
}) => {
    const { components, instanceConfig: { hasChat, searchIndex, hasCustomerFeedback } } = useContext(GlobalContext);
    const { Header, Footer } = components;
    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);

    useChat(hasChat);

    useEffect(() => {
        setTimeout(() => dataLayerPush({
            event: 'ads_loads'
        }), 5000);
    }, []);

    const content = (
        <div className={cn(null, undefined, className)}>
            {
                hasHeader ? (
                    <Header
                        isFullWidth={wideHeader}
                        regionId={regionId}
                    />
                ) : null
            }
            <div className={cn('content', {
                desktop: ! isMobile,
                mobile: isMobile,
                fullWidth: isFullWidth,
                vtb: isVtb
            }, contentClassName)}>
                {
                    isFullWidth || isMobile ? children : (
                        <div className={cn('container', { vtb: isVtb })}>
                            {children}
                        </div>
                    )
                }
                {
                    hasCustomerFeedback && withCustomerFeedback && ! hasM2Pro ? (
                        <CustomerFeedback
                            applicationId={isMobile ? ApplicationIdEnum.NewBuildingsTouch : ApplicationIdEnum.NewBuildingsDesktop}
                            modalOpenEvent={NewBuildingAnalytics.CustomerFeedbackClick}
                        />
                    ) : null
                }
            </div>
            {
                hasFooter ? (
                    <Footer regionId={regionId} />
                ) : null
            }
        </div>
    );

    return isVtb ? content : (
        <ShellProvider regionId={regionId}>
            {content}
        </ShellProvider>
    );
};

export default Page;
