/* global document */
import { useState, useCallback, MouseEvent, FocusEvent, FocusEventHandler, MouseEventHandler, useEffect } from 'react';

export interface IUseControlState {
    pressed?: boolean;
    focused?: boolean;
    disabled?: boolean;
    hovered?: boolean;
}

export interface IUseControlHandlers<T = Element> {
    onBlur?: FocusEventHandler<T>;
    onFocus?: FocusEventHandler<T>;
    onMouseDown?: MouseEventHandler<T>;
    onMouseUp?: MouseEventHandler<T>;
}

export interface IUseControlReturnedHandlers<T = Element> {
    handleBlur: FocusEventHandler<T>;
    handleFocus: FocusEventHandler<T>;
    handleMouseUp: MouseEventHandler<T>;
    handleMouseDown: MouseEventHandler<T>;
    handleMouseEnter: MouseEventHandler<T>;
    handleMouseLeave: MouseEventHandler<T>;
}

export interface IUseControlDeps {
    focused?: boolean;
    disabled?: boolean;
}

const useControl = (
    initialState: IUseControlState,
    handlers: IUseControlHandlers,
    deps: IUseControlDeps
): [ IUseControlState, IUseControlReturnedHandlers ] => {
    const [ state, setState ] = useState<IUseControlState>(Object.assign({
        pressed: false,
        focused: false,
        disabled: false,
        hovered: false
    }, initialState));

    const docOnMouseUp = () => {
        setState(prevState => ({ ...prevState, pressed: false }));

        document.removeEventListener('mouseup', docOnMouseUp);
        document.removeEventListener('dragend', docOnMouseUp);
    };
    const docOnMouseDown = () => {
        // необходимо слушать mouseup вне блока, иначе
        // при отпущенной вовне кнопке мыши блок остается pressed
        document.addEventListener('mouseup', docOnMouseUp);
        document.addEventListener('dragend', docOnMouseUp);
    };

    const handleMouseUp = useCallback((event: MouseEvent) => {
        setState(prevState => ({ ...prevState, pressed: false }));

        handlers.onMouseUp && handlers.onMouseUp(event);
    }, [ handlers.onMouseDown ]);
    const handleMouseDown = useCallback((event: MouseEvent) => {
        // по нажатию правой кнопки мыши
        // не нужно выставлять pressed
        if (event.nativeEvent.which === 3) {
            return;
        }

        setState(prevState => ({ ...prevState, pressed: true }));

        docOnMouseDown();

        handlers.onMouseDown && handlers.onMouseDown(event);
    }, [ handlers.onMouseDown ]);
    const handleFocus = useCallback((event: FocusEvent) => {
        setState(prevState => ({ ...prevState, focused: true }));

        handlers.onFocus && handlers.onFocus(event);
    }, [ handlers.onFocus ]);

    const handleBlur = useCallback((event: FocusEvent) => {
        setState(prevState => ({ ...prevState, focused: false }));

        handlers.onBlur && handlers.onBlur(event);
    }, [ handlers.onBlur ]);
    const handleMouseEnter = useCallback(() => {
        setState(prevState => ({ ...prevState, hovered: true }));
    }, []);
    const handleMouseLeave = useCallback(() => {
        setState(prevState => ({ ...prevState, hovered: false }));
    }, []);

    useEffect(() => {
        const newState = { ...state };

        if (initialState.disabled !== state.disabled) {
            newState.disabled = initialState.disabled;
        }

        if (initialState.focused !== state.focused) {
            newState.focused = initialState.focused;
        }

        if (initialState.pressed !== state.pressed) {
            newState.pressed = initialState.pressed;
        }

        if (initialState.disabled) {
            newState.focused = false;
            newState.pressed = false;
        }

        setState(newState);

        return () => {
            document.removeEventListener('mouseup', docOnMouseUp);
            document.removeEventListener('dragend', docOnMouseUp);
        };
    }, [ deps.focused, deps.disabled ]);

    useEffect(() => {
        if (deps.focused && deps.focused !== state.focused) {
            setState(prevState => ({ ...prevState, focused: deps.focused }));
        }
    }, [ state.focused ]);

    return [
        state,
        {
            handleBlur,
            handleFocus,
            handleMouseUp,
            handleMouseDown,
            handleMouseEnter,
            handleMouseLeave
        }
    ];
};

export default useControl;
