import React, { useCallback, useMemo } from 'react';

import { Link, Typography } from '@vtblife/uikit';

import { CALLTOUCH_ID } from '@search/classified-common/src/scripts/useCalltouch';
import { NewBuildingSpecialEventTypeEnum, RouteMetro } from '@search/graphql-typings';
import { Rooms } from '@search/filter-enums/enums';
import { formatRange } from '@search/helpers/src/formatNumber';
import { useToggleValue } from '@search/hooks/src/useToggleValue';

import type { ArrayElement } from '@search/vtbeco-frontend-core/types';
import SnippetPhone from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/SnippetPhone';
import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';
import MetroRoute from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import { GalleryMobile2 } from '@search/vtbeco-frontend-core/view/common/components/gallery/GalleryMobile2/GalleryMobile2';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { Badge } from '@search/vtbeco-frontend-core/view/common/components/Badge';
import { RoomFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import type { NewBuildingQuizQuery$data } from '../../gql/__generated__/NewBuildingQuizQuery.graphql';
import { cnNewBuildingQuiz } from '../../index';
import type { RoomQuizFilterType } from './rooms';

type QuizSnippetListType = NonNullable<NewBuildingQuizQuery$data['searchNewBuildings']>['items'];
type QuizSnippetType = NonNullable<ArrayElement<QuizSnippetListType>>;
type BuildCardUrlType = (params: QuizSnippetType['routeParams']) => string;

const SpecialEventBadgeColor: Record<NewBuildingSpecialEventTypeEnum, string> = {
    [NewBuildingSpecialEventTypeEnum.ImplNotSet]: '#77738C',
    [NewBuildingSpecialEventTypeEnum.Promo]: '#28B9BE',
    [NewBuildingSpecialEventTypeEnum.Discount]: '#E91F0C',
    [NewBuildingSpecialEventTypeEnum.InstallmentPlan]: '#9173FA',
    [NewBuildingSpecialEventTypeEnum.SalesStart]: '#3A86F7',
    [NewBuildingSpecialEventTypeEnum.Gift]: '#FF71CF',
    [NewBuildingSpecialEventTypeEnum.TradeIn]: '#77738C',
    [NewBuildingSpecialEventTypeEnum.Arbitrary]: '#28B9BE'
};

const RoomsMapStr: Record<RoomQuizFilterType, string> = {
    [Rooms.STUDIO]: 'STUDIO',
    [Rooms.ROOM_1]: '1',
    [Rooms.ROOM_2]: '2',
    [Rooms.ROOM_3]: '3',
    [Rooms.ROOM_4]: '4',
    [Rooms.ROOM_5_AND_MORE]: '5'
};

const getRoomsAndPriceInfo = ({
    roomsFilter: { rooms },
    newBuilding
}: {
    roomsFilter: RoomFilter;
    newBuilding: QuizSnippetType;
}) => {
    const housingType = newBuilding.housingTypeQuiz;

    const newBuildingPriceMinInfo = housingType && newBuilding.priceMin ?
        `${housingType} ${formatRange(newBuilding.priceMin)} ₽` :
        null;

    if (! rooms.length) {
        return newBuildingPriceMinInfo;
    }

    const roomValue = rooms.length > 1 ? Math.min(...rooms) : rooms[0];
    const roomStr = RoomsMapStr[roomValue as RoomQuizFilterType];
    const { labelFull, priceMin } = newBuilding.flats?.find(flat => flat?.rooms === roomStr) ?? {};

    return labelFull && priceMin ? `${labelFull} ${formatRange(priceMin)} ₽` : newBuildingPriceMinInfo;
};

export const MiniSerpView = (({
    newBuildings,
    isMobile,
    buildCardUrl,
    quizId,
    roomsFilter
}: {
    newBuildings: QuizSnippetListType;
    isMobile: boolean;
    buildCardUrl: BuildCardUrlType;
    quizId: string;
    roomsFilter: RoomFilter;
}) => {
    return (
        <div className={cnNewBuildingQuiz('miniSerp', { mobile: isMobile })}>
            {newBuildings.map((nb, idx) => nb && (
                <QuizSnippet
                    key={idx}
                    newBuilding={nb}
                    buildCardUrl={buildCardUrl}
                    quizId={quizId}
                    roomsFilter={roomsFilter}
                    isMobile={isMobile}
                />
            ))}
        </div>
    );
}) as React.FC;

const QuizSnippet: React.FC<{
    newBuilding: QuizSnippetType;
    buildCardUrl: BuildCardUrlType;
    quizId: string;
    roomsFilter: RoomFilter;
    isMobile: boolean;
}> = ({ newBuilding, buildCardUrl, quizId, roomsFilter, isMobile }) => {
    const hoverToggle = useToggleValue(false);

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: newBuilding.gallery?.baseUrl });

    const developer = newBuilding.developerCards && newBuilding.developerCards[0].developer;
    const routes = newBuilding.routes?.filter(route => route?.station);

    const handlePhoneEventPush = useCallback(() => dataLayerPush({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        quiz_id: quizId,
        event: 'nb_quiz_phone_show'
    }), [ quizId ]);

    const roomsAndPriceInfo = useMemo(
        () => getRoomsAndPriceInfo({ roomsFilter, newBuilding }),
        [ roomsFilter, newBuilding ]
    );

    return (
        <div
            className={cnNewBuildingQuiz('snippet', { mobile: isMobile })}
            onMouseOver={hoverToggle.handleSetTrueTogVal}
            onMouseLeave={hoverToggle.handleSetFalseTogVal}
        >
            <ImageLazySrcSetProvide getUrl={getUrl}>
                <div className={cnNewBuildingQuiz('snippetGallery')}>
                    <GalleryMobile2
                        relativeHeight
                        pictures={[ {
                            originPath: newBuilding.gallery?.main?.originPath ?? '',
                            description: ''
                        } ]}
                        renderSpecialEvents={
                            newBuilding.specialEvents?.length ? (
                                <Badge
                                    size='xs'
                                    backgroundColor={SpecialEventBadgeColor[newBuilding.specialEvents[0].type]}
                                    className={cnNewBuildingQuiz('snippetBadge')}
                                    textClassName={cnNewBuildingQuiz('snippetBadgeText')}
                                >
                                    {newBuilding.specialEvents[0].titleRaw}
                                </Badge>
                            ) : undefined
                        }
                    />
                </div>
            </ImageLazySrcSetProvide>
            <div className={cnNewBuildingQuiz('snippetMain')}>
                <Link
                    href={buildCardUrl(newBuilding.routeParams)}
                    target='blank'
                >
                    <Typography
                        variant='primary-alone'
                        bold
                    >
                        {newBuilding.title?.nominative}
                    </Typography>
                </Link>
                {
                    roomsAndPriceInfo && (
                        <div className={cnNewBuildingQuiz('snippetRoomsAndPriceInfo')}>
                            <Typography variant='secondary-alone'>{roomsAndPriceInfo}</Typography>
                        </div>
                    )
                }
                {
                    newBuilding.buildingStatusQuiz && (
                        <div className={cnNewBuildingQuiz('snippetBuildingStatus')}>
                            <Typography variant='secondary-alone'>{newBuilding.buildingStatusQuiz}</Typography>
                        </div>
                    )
                }
                <div className={cnNewBuildingQuiz('snippetAddress')}>
                    {
                        routes?.length ? (
                            <MetroRoute
                                view='mobile'
                                metro={routes[0] as RouteMetro}
                                withoutTransport
                            />
                        ) : (
                            <Typography
                                variant='secondary-alone'
                                color='secondary'
                            >
                                {newBuilding.address}
                            </Typography>
                        )
                    }
                </div>
                {
                    developer && (
                        <div className={cnNewBuildingQuiz('snippetPhoneButton')}>
                            <SnippetPhone
                                phoneParams={{
                                    phoneNum: 0,
                                    developerId: developer.id,
                                    newBuildingId: Number(newBuilding.id)
                                }}
                                calltouchId={CALLTOUCH_ID}
                                onPhoneEventPush={handlePhoneEventPush}
                                variant={hoverToggle.isToggleValue ? 'primary' : 'secondary'}
                                fullWidth
                                isMobile={isMobile}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};
