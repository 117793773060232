import './styles.css';

import React, { useCallback } from 'react';
import Grid from '@vtblife/uikit-grid';
import { Button, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings/src';

import { useGlobalContext } from '../../../../common/components/GlobalContext';

const cn = classname.bind(null, 'QuickSelectionSerpBanner');

const QuickSelectionSerpBanner = ({ openQuizModal }: { openQuizModal: () => void }) => {
    const globalContext = useGlobalContext();
    const { searchIndex } = globalContext.instanceConfig;
    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const handleBannerClick = useCallback(() => {
        openQuizModal();

        dataLayerPush({
            event: 'nb_banner_click_quiz'
        });
    }, [ openQuizModal ]);

    return (
        <div
            onClick={handleBannerClick}
            className={cn()}
        >
            <Grid cols={24}>
                <Grid.Cell m={8}>
                    <div className={cn('imageWrapper')}>
                        <div className={cn('image', { isVtb })} />
                    </div>
                </Grid.Cell>
                <Grid.Cell m={10}>
                    <div className={cn('textWrapper')}>
                        <Typography
                            variant='h3'
                            tag='span'
                        >
                            {isVtb ? (<>
                                Устали искать идеальную квартиру?<br />
                                <Typography variant='h3' className={cn('text-vtb-color')}>Выберем подходящие варианты</Typography>
                                из&nbsp;полной базы новостроек
                            </>) : (<>
                                Ответьте на&nbsp;четыре вопроса и&nbsp;получите персональную <Typography
                                    variant='h3'
                                    tag='span'
                                    color='purple-500'
                                >
                                    подборку новостроек и&nbsp;скидку на&nbsp;ремонт новой квартиры
                                </Typography>
                            </>)}
                        </Typography>
                    </div>
                </Grid.Cell>
                <Grid.Cell m={6}>
                    <div className={cn('buttonWrapper', { isVtb })}>
                        <Button
                            fullWidth
                            size='l'
                            variant='brand-purple'
                        >
                            Подобрать {isVtb ? 'квартиру' : 'новостройку'}
                        </Button>
                        <Typography
                            color='secondary'
                            variant='secondary-alone'
                            className={cn('postscript')}
                        >
                            один звонок, без&nbsp;рекламы и&nbsp;спама
                        </Typography>
                    </div>
                </Grid.Cell>
            </Grid>
        </div>
    );
};

export default QuickSelectionSerpBanner;
