import { ErrorNotFound } from '@search/error/src/BadRequest';
import {
    LocationLike
} from './RouterInterfaces';
import { toPromise } from './toPromise';
import { SchemaVariantError, SchemaTypeError } from './schema';

export interface IRouter<Context> {
    readonly context: Context;
    location?: Readonly<LocationLike>;
    update?(nextUrl: string, options?: RoutePushOptions): void;
}

export type RouteConstructor<Output, Context> =
    new (router: IRouter<Context>) => Output;

export type PartialDefaults<Params, Defaults> = Omit<Params, keyof Defaults>
    & Partial<Pick<Params, keyof Params & keyof Defaults>>;

export type RoutePushOptions = {
    delay?: number;
    replace?: boolean;
    pageState?: Object;
    noRefresh?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class Route<Output = any, Context = any, Defaults extends Partial<Output> = any> {
    displayName: string | undefined;

    protected router: IRouter<Context>;

    constructor(
        router: IRouter<Context>
    ) {
        this.router = router;
    }

    protected get context(): Context {
        return this.router.context;
    }

    isDefaults(params: Output): boolean {
        if (! this.defaults) return false;
        const defaults = this.defaults();

        for (const key in params) {
            if (params[key] as Partial<Output> !== defaults[key]) return false;
        }
        return true;
    }

    abstract defaults(): Defaults;

    /**
     * Построить url из параметров роута
     *
     * @throws Error если параметры не соотвествуют типу в схеме
     * @throws Promise если требуется загрузка данных для построения урла, внешний вызов должен быть обернут тогда в toPromise
     */
    abstract url(params: PartialDefaults<Output, Defaults>): string;

    /**
     * @throws Error если параметры не соотвествуют текущему урлу
     * @throws Promise если требуется загрузка данных для построения урла, внешний вызов должен быть обернут тогда в toPromise
     */
    abstract params(location?: LocationLike, pageState?: Object): Output;

    paramsSafe() {
        try {
            return this.params();
        } catch (error) {
            // @ts-expect-error
            if (SchemaVariantError.is(error)) return undefined;
            // @ts-expect-error
            if (SchemaTypeError.is(error)) return undefined;

            if (error instanceof ErrorNotFound) return undefined;

            throw error;
        }
    }

    protected update(url: string, options?: RoutePushOptions) {
        if (this.router.update) {
            this.router.update(url, options);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected handler: any = null

    protected paramsTmp: PartialDefaults<Output, Defaults> | undefined = undefined;

    urlPush(params: PartialDefaults<Output, Defaults>): string {
        const url = this.url(params);

        return url;
    }

    push(params: PartialDefaults<Output, Defaults>, options?: RoutePushOptions) {
        // this.paramsTmp = params;
        // Обработать ситуацию, если будут еще вызовы push до резолва промиса
        const cb = () => toPromise(() => this.urlPush(params)).then(url => {
            // console.log({ params, stack, url });
            this.update(url, options);
            this.paramsTmp = undefined;
        });

        if (this.handler) clearTimeout(this.handler);

        this.handler = null;

        if (options && options.delay) {
            this.handler = setTimeout(cb, options.delay);
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        cb();
    }

    replace(params: PartialDefaults<Output, Defaults>, options?: RoutePushOptions) {
        return this.push(params, { ...options, replace: true });
    }
}
