import React from 'react';

import { Mortgage2StarterModal } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/mortgage2/starter/Mortgage2StarterModal';
import { useMinimalBanksMortgageOffersSliced } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';

import { ApplicationIdEnum, MortgagePurpose } from '@search/graphql-typings';
import type { NewBuildingMortgageParams } from '@search/graphql-typings';

type TMortgage2StarterModalWrapper = {
    onClose: () => void;
    mortgageParams: NewBuildingMortgageParams;
    isSerp?: boolean;
    isSerpMap?: boolean;
}

const NewBuildingCardMortgageModal = ({
    mortgageParams,
    onClose,
    isSerp,
    isSerpMap
}: TMortgage2StarterModalWrapper) => {
    const {
        term,
        propertyCost,
        ownAmount,
        purpose,
        regionId
    } = mortgageParams;

    const {
        offersFull
    } = useMinimalBanksMortgageOffersSliced({
        term,
        propertyCost,
        ownAmount,
        purpose,
        regionId,
        defaultVisibleOffers: 4
    });

    return (
        <Mortgage2StarterModal
            alwaysFromWord={isSerp}
            mortgageOffers={offersFull}
            applicationId={ApplicationIdEnum.NewBuildingsTouch}
            locationPlacement='плавающая кнопка'
            term={term}
            propertyCost={propertyCost}
            ownAmount={ownAmount}
            regionId={regionId}
            realtyType='ProfOfferFlat'
            isMobile
            withoutAuth
            gtmSendData={{
                event: 'nb_ib_form',
                action: 'send',
                // eslint-disable-next-line no-nested-ternary
                page_type: isSerp ? 'serp' : isSerpMap ? 'map ' : 'card',
            }}
            gtmShowData={{
                event: 'nb_ib_form',
                action: 'open',
                // eslint-disable-next-line no-nested-ternary
                page_type: isSerp ? 'serp' : isSerpMap ? 'map ' : 'card',
            }}
            withoutShowContainer
            onClose={onClose}
            purpose={MortgagePurpose.Primary}
        />
    );
};

export default NewBuildingCardMortgageModal;
