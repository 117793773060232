import React from 'react';

import { Button } from '@vtblife/uikit';

import { formatNumber } from '@search/helpers/src/formatNumber';

interface IProps {
    submitFilters: () => void;
    count: number;
    isLoading?: boolean;
}

export const NewNewBuildingSearchFiltersSubmitButton: React.FC<IProps> = ({
    submitFilters,
    count,
    isLoading
}) => (
    <Button
        rounded='none'
        fullWidth
        onClick={submitFilters}
        loading={isLoading}
        dataTest='show-button'
    >
        {
            count > 0 ?
                `Показать ${formatNumber(count)}` :
                'Нет результатов'
        }
    </Button>
);
