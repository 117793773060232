import { formatRange } from '@search/helpers/src/formatNumber';
import { FlatsSpecialEventString, IsApartmentsOptionsString, ParkingTypeString } from '@search/filter-enums/enums';

const commissioningDict: Record<string, string> = {
    COMMISSIONED: 'сданные',
    IN_PROGRESS: 'строящиеся'
};

const commissioningQuarterDict: Record<string, string> = {
    FIRST: '1',
    SECOND: '2',
    THIRD: '3',
    FOURTH: '4'
};

const roomsDict: Record<string, string> = {
    STUDIO: 'студии',
    ROOM_1: '1-комнатные',
    ROOM_2: '2-комнатные',
    ROOM_3: '3-комнатные',
    ROOM_4: '4-комнатные',
    ROOM_5_AND_MORE: '5\u00A0и\u00A0более комнат',
    OPEN_PLAN: 'свободная планировка'
};

const euroPlanningDict: Record<string, string> = {
    ROOM_1: 'евродвушки',
    ROOM_2: 'евротрёшки'
};

const finishingDict: Record<string, string> = {
    AVAILABLE: 'с\u00A0отделкой',
    NOT_AVAILABLE: 'без\u00A0отделки'
};

const finishingTypeDict: Record<string, string> = {
    FINE: 'с\u00A0чистовой отделкой',
    WITH_FURNITURE: 'с\u00A0мебелью',
    TURN_KEY: 'под\u00A0ключ'
};

const buildingClassDict: Record<string, string> = {
    ECONOMY: 'эконом-класс',
    COMFORT: 'комфорт-класс',
    COMFORT_PLUS: 'класс комфорт+',
    BUSINESS: 'бизнес-класс',
    ELITE: 'элитные'
};

const wallsTypeDict: Record<string, string> = {
    MONOLITH: 'монолитные',
    BRICK_MONOLITH: 'кирпично-монолитные',
    BRICK: 'кирпичные',
    PANEL: 'панельные',
    BLOCK: 'блочные'
};

const floorLastDict: Record<string, string> = {
    ONLY: 'последний этаж',
    EXCLUDE: 'не\u00A0последний этаж'
};

const apartmentsDict: Record<string, string> = {
    APARTMENTS_ONLY: 'апартаменты'
};

const parkingDict: Record<string, string> = {
    UNDERGROUND: 'с подземной парковкой',
    AVAILABLE: 'с парковкой'
};

const flatsSpecialEventsDict: Record<FlatsSpecialEventString.FLAT_DISCOUNT | FlatsSpecialEventString.FLAT_SALES_START, string> = {
    [FlatsSpecialEventString.FLAT_DISCOUNT]: 'по\u00A0скидке',
    [FlatsSpecialEventString.FLAT_SALES_START]: 'на\u00A0старте продаж'
};

type ParamName = 'law214' | 'escrow' | 'armyMortgage' | 'installment' | 'cheap' | 'closeToMetro' |
    'closedArea' | 'concierge' | 'bigKitchen' | 'ceilingHeight' | 'balcony' | 'motherCapital';

const paramDict: Record<ParamName, string> = {
    law214: 'со\u00A0сделкой по\u00A0214-ФЗ',
    escrow: 'со\u00A0сделкой по\u00A0эскроу-счёту',
    armyMortgage: 'военная ипотека',
    installment: 'рассрочка',
    cheap: 'дешёвые',
    closeToMetro: 'рядом с\u00A0метро',
    closedArea: 'с\u00A0закрытой территорией',
    concierge: 'с\u00A0консьержем',
    bigKitchen: 'с\u00A0большой кухней',
    ceilingHeight: 'с\u00A0высокими потолками',
    balcony: 'с\u00A0балконом',
    motherCapital: 'на\u00A0материнский капитал',
};

export default {
    makeCommissioning: (commissioning: string): string => {
        const commissioningText = commissioningDict[commissioning];

        if (commissioningText) {
            return commissioningText;
        }

        const quarterYear = commissioning.split('_');

        if (quarterYear.length === 2) {
            const [ quarter, year ] = quarterYear;
            const quarterText = commissioningQuarterDict[quarter];

            if (quarterText && /^\d+$/.test(year)) {
                return `до\u00A0${quarterText}\u00A0квартала ${year}`;
            }
        }

        return '';
    },
    makeRooms: (rooms: string): string => roomsDict[rooms] ?? '',
    makeEuroPlanning: (rooms: string): string => euroPlanningDict[rooms] ?? '',
    makeFinishing: (finishing: string): string => finishingDict[finishing] ?? '',
    makeFinishingType: (finishingType: string): string => finishingTypeDict[finishingType] ?? '',
    makeBuildingClass: (buildingClass: string): string => buildingClassDict[buildingClass] ?? '',
    makeWallsType: (wallsType: string): string => wallsTypeDict[wallsType] ?? '',
    makeFloorLast: (floorLast: string): string => floorLastDict[floorLast] ?? '',
    makeApartments: (apartments: IsApartmentsOptionsString): string => {
        return apartmentsDict[apartments] ?? '';
    },
    makeParking: (parking: Array<ParkingTypeString>): string => {
        if (parking.length === 3) {
            return parkingDict[ParkingTypeString.AVAILABLE];
        }

        if (parking.length === 1 && parking[0] === ParkingTypeString.UNDERGROUND) {
            return parkingDict[ParkingTypeString.UNDERGROUND];
        }

        return '';
    },
    makeFlatsSpecialEvents: (flatsSpecialEvents: Array<FlatsSpecialEventString.FLAT_DISCOUNT | FlatsSpecialEventString.FLAT_SALES_START>) => {
        return flatsSpecialEventsDict[flatsSpecialEvents[0]];
    },
    makePriceMax: (to: number): string => `${formatRange(null, to, {
        billionsAsString: true
    })}\u00A0₽`,
    makePricePerMeterMax: (to: number): string => `${formatRange(null, to, {
        billionsAsString: true
    })}\u00A0₽ за\u00A0квадратный\u00A0метр`,
    makeParam: (param: ParamName) => paramDict[param] ?? '',
    makeMetro: (metro: string): string => `у\u00A0метро ${metro}`
};

export const emojiDict = {
    NEW_BUILDING: 0x1F3E2,
    DEVELOPER: 0x1F3D7,
};
