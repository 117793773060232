/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Model, { StationId, LineId } from './MetroMapModel';

type SublineId = string;
type NodeId = number;

type NodeInfo = {
    stationId?: StationId;
    lineId?: LineId;
    sublineId?: SublineId;
}

const nodeListForEach = (
    nodes: NodeList | Array<Element>,
    callback: (node: Element) => void
) => {
    Array.prototype.forEach.call(nodes, callback);
};

const findClosestNode = (
    node: Element,
    allowedNodes: Set<Element>,
    endedNode: Element
): Element | null => {
    while (node && node !== endedNode) {
        if (allowedNodes.has(node)) {
            return node;
        }

        node = node.parentNode as Element;
    }

    return null;
};

const setClassName = (
    nodes: Array<Element>,
    className: string,
    isSet: boolean
) => {
    nodeListForEach(nodes, node => {
        node.classList[isSet ? 'add' : 'remove'](className);
    });
};

class MetroMapView {
    model: Model;

    private attrPrefix: string = 'MetroMap_';
    private onChange: (data: any) => void;

    private node: Element;

    private lastNodeId: NodeId = 0;
    private nodeInfoByNodeId = new Map<NodeId, NodeInfo>();

    private stationsNodes = new Set<Element>();
    private stationsNodesByStationId = new Map<StationId, Array<Element>>();
    private stationsNodesByLineId = new Map<LineId, Array<Element>>();
    private stationsNodesBySublineId = new Map<SublineId, Array<Element>>();

    private sublinesNodes = new Set<Element>();
    private stationsIdsBySublineId = new Map<SublineId, Array<StationId>>();
    private sublinesNodesByLineId = new Map<LineId, Array<Element>>();
    private sublineNodeBySublineId = new Map<SublineId, Element>();

    private linesLabelsNodes = new Set<Element>();
    private linesLabelsNodesByLineId = new Map<LineId, Array<Element>>();
    private sublinesLabelsNodes = new Set<Element>();

    private hoveredNodes: Array<Element> | null = null;

    constructor({ node, onChange, selected }: {
        node: Element;
        onChange: (data: any) => void;
        selected: Set<StationId>;
    }) {
        this.onChange = onChange;
        this.node = node;

        const attrPrefix = this.attrPrefix;

        const stationsIdsByLineId = new Map<LineId, Array<StationId>>();
        const stationsGroupsByLineNodes = node.querySelectorAll(`#${attrPrefix}stations .${attrPrefix}stations`);

        nodeListForEach(stationsGroupsByLineNodes, stationsGroupNode => {
            const lineId = parseInt(stationsGroupNode.getAttribute('data-id')!, 10);
            let sublineNodes = Array.from(stationsGroupNode.querySelectorAll(`.${attrPrefix}subline`));
            const lineStationsIds: Array<StationId> = [];
            let isSublinesExist = true;

            if (! sublineNodes.length) {
                isSublinesExist = false;
                sublineNodes = [ stationsGroupNode ];
            }

            stationsIdsByLineId.set(lineId, lineStationsIds);

            nodeListForEach(sublineNodes, sublineNode => {
                const sublineId = `${lineId}_${isSublinesExist ? sublineNode.getAttribute('data-id') : ''}`;
                const stationsNodes = sublineNode.querySelectorAll(`.${attrPrefix}station`);
                const sublineStationsIds: Array<StationId> = [];

                this.stationsIdsBySublineId.set(sublineId, sublineStationsIds);

                nodeListForEach(stationsNodes, stationNode => {
                    const stationId = parseInt(stationNode.getAttribute('data-id')!, 10);

                    if (! this.stationsNodesByStationId.has(stationId)) {
                        this.stationsNodesByStationId.set(stationId, []);
                    }

                    this.stationsNodesByStationId.get(stationId)?.push(stationNode);
                    this.stationsNodes.add(stationNode);

                    lineStationsIds.push(stationId);
                    sublineStationsIds.push(stationId);
                    this.setNodeInfo(stationNode as HTMLElement, { stationId });
                });
            });
        });

        const linesWrappersNodes = node.querySelectorAll(`#${attrPrefix}lines .${attrPrefix}ln`);

        nodeListForEach(linesWrappersNodes, fullLineNode => {
            const lineId = parseInt(fullLineNode.getAttribute('data-id')!, 10);
            const sublinesNodes = fullLineNode.querySelectorAll(`.${attrPrefix}line`);
            const labelsNodes = fullLineNode.querySelectorAll(`.${attrPrefix}label`);

            this.sublinesNodesByLineId.set(lineId, []);
            this.linesLabelsNodesByLineId.set(lineId, []);

            // eslint-disable-next-line @typescript-eslint/no-shadow
            nodeListForEach(sublinesNodes, node => {
                let sublineNode = node;

                if (sublineNode.parentElement?.classList.contains(`${attrPrefix}subline`)) {
                    sublineNode = sublineNode.parentElement;
                }

                const sublineId = `${lineId}_${sublineNode.getAttribute('data-id') || ''}`;

                this.setNodeInfo(sublineNode as HTMLElement, { sublineId });
                this.sublinesNodes.add(sublineNode);
                this.sublineNodeBySublineId.set(sublineId, sublineNode);
                this.sublinesNodesByLineId.get(lineId)?.push(sublineNode);
            });

            nodeListForEach(labelsNodes, labelNode => {
                if (labelNode.parentElement?.classList.contains(`${attrPrefix}subline`)) {
                    const { sublineId } = this.getNodeInfo(labelNode.parentElement) ?? {};

                    this.setNodeInfo(labelNode as HTMLElement, { sublineId });
                    this.sublinesLabelsNodes.add(labelNode);
                } else {
                    this.setNodeInfo(labelNode as HTMLElement, { lineId });
                    this.linesLabelsNodes.add(labelNode);
                    this.linesLabelsNodesByLineId.get(lineId)?.push(labelNode);
                }
            });
        });

        this.stationsIdsBySublineId.forEach((stationsIds, sublineId) => {
            if (! this.stationsNodesBySublineId.has(sublineId)) {
                this.stationsNodesBySublineId.set(sublineId, []);
            }

            stationsIds.forEach(stationId => {
                this.stationsNodesBySublineId.get(sublineId)?.push(
                    ...this.stationsNodesByStationId.get(stationId) ?? []
                );
            });
        });

        stationsIdsByLineId.forEach((stationsIds, lineId) => {
            if (! this.stationsNodesByLineId.has(lineId)) {
                this.stationsNodesByLineId.set(lineId, []);
            }

            stationsIds.forEach(stationId => {
                this.stationsNodesByLineId.get(lineId)?.push(
                    ...this.stationsNodesByStationId.get(stationId) ?? []
                );
            });
        });

        selected.forEach(stationId => {
            setClassName(this.stationsNodesByStationId.get(stationId) || [], `${attrPrefix}selected`, true);
        });

        this.model = new Model({
            selected,
            stationsIdsByLineId,
            onChange: data => {
                data.diff.selected.forEach((stationId: StationId) => {
                    setClassName(this.stationsNodesByStationId.get(stationId) || [], `${attrPrefix}selected`, true);
                });

                data.diff.deselected.forEach((stationId: StationId) => {
                    setClassName(this.stationsNodesByStationId.get(stationId) || [], `${attrPrefix}selected`, false);
                });

                this.onChange(data);
            }
        });

        this.handleClick = this.handleClick.bind(this);
        this.handleMouseover = this.handleMouseover.bind(this);
        this.handleMouseout = this.handleMouseout.bind(this);

        node.addEventListener('click', this.handleClick, false);
        node.addEventListener('mouseover', this.handleMouseover, false);
        node.addEventListener('mouseout', this.handleMouseout, false);
    }

    destroy() {
        const node = this.node;

        node.removeEventListener('click', this.handleClick);
        node.removeEventListener('mouseover', this.handleMouseover);
        node.removeEventListener('mouseout', this.handleMouseout);
    }

    setOnChange(onChange: (data: any) => void) {
        this.onChange = onChange;
    }

    private setNodeInfo(node: HTMLElement, nodeInfo: NodeInfo) {
        const nodeId = ++this.lastNodeId;

        node.dataset.metroMapNodeId = String(nodeId);

        this.nodeInfoByNodeId.set(nodeId, nodeInfo);
    }

    private getNodeInfo(node: HTMLElement): NodeInfo | undefined {
        const { metroMapNodeId } = node.dataset;

        if (metroMapNodeId) {
            const nodeId = Number(metroMapNodeId);

            return this.nodeInfoByNodeId.get(nodeId);
        }
    }

    private handleClick(e: Event) {
        const find = findClosestNode.bind(null, e.target as Element);
        let foundNode = find(this.stationsNodes, this.node);

        if (foundNode) {
            const { stationId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            stationId && this.model.toggleStation(stationId);

            return;
        }

        foundNode = find(this.linesLabelsNodes, this.node);

        if (foundNode) {
            const { lineId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            lineId && this.model.toggleLine(lineId);

            return;
        }

        foundNode = find(this.sublinesNodes, this.node) || find(this.sublinesLabelsNodes, this.node);

        if (foundNode) {
            const { sublineId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            const stationsIds = sublineId ? this.stationsIdsBySublineId.get(sublineId) ?? [] : [];

            if (this.model.isStationsSelected(stationsIds)) {
                this.model.deselectStations(stationsIds);
            } else {
                this.model.selectStations(stationsIds);
            }
        }
    }

    private handleMouseover(e: Event) {
        const find = findClosestNode.bind(null, e.target as Element);
        let foundNode = find(this.stationsNodes, this.node);

        if (foundNode) {
            const { stationId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            stationId && this.setHovered(this.stationsNodesByStationId.get(stationId) ?? []);

            return;
        }

        foundNode = find(this.linesLabelsNodes, this.node);

        if (foundNode) {
            const { lineId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            this.setHovered([
                ...(lineId ? this.linesLabelsNodesByLineId.get(lineId) ?? [] : []),
                ...(lineId ? this.sublinesNodesByLineId.get(lineId) ?? [] : []),
                ...(lineId ? this.stationsNodesByLineId.get(lineId) ?? [] : [])
            ]);

            return;
        }

        foundNode = find(this.sublinesNodes, this.node);

        if (foundNode) {
            const { sublineId } = this.getNodeInfo(foundNode as HTMLElement) ?? {};

            this.setHovered([
                foundNode,
                ...(sublineId ? this.stationsNodesBySublineId.get(sublineId) ?? [] : [])
            ]);

            return;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private handleMouseout(e: Event) {
        this.unsetHovered();
    }

    private setHovered(nodes: Array<Element>) {
        this.unsetHovered();
        setClassName(nodes, `${this.attrPrefix}hovered`, true);
        this.hoveredNodes = nodes;
    }

    private unsetHovered() {
        if (this.hoveredNodes) {
            setClassName(this.hoveredNodes, `${this.attrPrefix}hovered`, false);
        }

        this.hoveredNodes = null;
    }
}

export default MetroMapView;
