/* eslint-disable complexity */
import * as React from 'react';
import { useContext } from 'react';

import type { NewBuildingCard } from '@search/graphql-typings';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import { Button, Link, Typography } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';

import classname from '@search/classname/src';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import { QRCodePhoneButton } from '@search/vtbeco-frontend-core/view/common/components/QRCodePhoneButton';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import {
    GlobalContext,
    IGlobalContext,
    IOnChangeGlobalContext,
    OnChangeGlobalContext
} from '../../../../common/components/GlobalContext';
import { SvgSpriteIcon } from '../../../../common/components/SvgSpriteIcon';
import SummaryItem from '../../../../common/components/NewBuildingCard/NewBuildingCardDevelopers';
import { defineCallbackEmailSender } from '../../../../common/libs/defineEmailSender';
import { usePhoneButtonContext } from '../../../../common/components/NewBuildingCard/PhoneButtonStateProvider';
import ChatButton from '../../../../common/components/ChatButton';
import { M2ProOrderButton } from '../../../../common/components/M2ProOrderButton';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardDevelopers');

const NewBuildingCardDevelopers: React.FunctionComponent<{
    developerCards: NewBuildingCard['developerCards'];
    searchIndex: NewBuildingWhitelabelEnum;
    newBuildingName: string;
    newBuildingAddress: string;
    newBuildingId: NewBuildingCard['id'];
    developerNames: string;
    hasCustomDeveloperBtnSize?: boolean;
    isMskMO?: boolean;
    isSPbLO?: boolean;
    isSpecProjectRegion?: boolean;
    hasChat?: boolean;
    m2Pro?: NewBuildingCard['m2Pro'];
}> = props => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const { instanceConfig: { hasDeveloperCallBack, calltouchUnitId } } = useContext<IGlobalContext>(GlobalContext);

    const { handleClick, buttonsData, handleStatEventPush } = usePhoneButtonContext();

    const {
        developerCards,
        searchIndex,
        hasCustomDeveloperBtnSize,
        isMskMO,
        isSPbLO,
        isSpecProjectRegion,
        newBuildingId
        // m2Pro
    } = props;

    const isPhoneRegion = isMskMO || isSPbLO || isSpecProjectRegion;
    const hasCallback = hasDeveloperCallBack && (isMskMO || isSPbLO);

    const { auth } = useM2AuthContext();
    const rewardAmount = null;
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType) && Boolean(rewardAmount); // m2Pro?.rewardAmount

    const renderAllObjectsButton = (developerPageLink?: string) => (
        <Link
            text={false}
            href={developerPageLink}
        >
            <Button
                fullWidth
                variant='transparent'
            >
                Все объекты
            </Button>
        </Link>
    );

    /*TODO Убрать searchIndex === NewBuildingWhitelabelEnum.Default, когда пофиксят статистику для витрины на бэке*/
    return (
        <ul className={cn()}>
            {
                developerCards.map(({ developer, commissionedCount, inProgressCount }) => {
                    if (developer) {
                        const developerPageLink = onChangeGlobalContext.linkBuilder.developer?.({
                            id: developer.id,
                            name: developer.translitTitle
                        });

                        return (
                            <li
                                key={developer.id}
                                className={cn('item')}
                            >
                                <Grid cols={12}>
                                    <Grid.Cell m={4}>
                                        <div className={cn('info')}>
                                            <Link
                                                target='blank'
                                                href={developerPageLink}
                                                variant='text'
                                            >
                                                {
                                                    developer.icon?.origin ? (
                                                        <Image
                                                            type='contain'
                                                            width={40}
                                                            height={40}
                                                            url={developer.icon.origin}
                                                            className={cn('logo')}
                                                        />
                                                    ) : (
                                                        <SvgSpriteIcon
                                                            width={40}
                                                            height={40}
                                                            name='CustomDeveloper'
                                                            className={cn('logo')}
                                                        />
                                                    )
                                                }
                                                <Typography>{developer.title}</Typography>
                                            </Link>
                                        </div>
                                    </Grid.Cell>
                                    {searchIndex === NewBuildingWhitelabelEnum.Default && commissionedCount && (
                                        <Grid.Cell m={4}>
                                            <SummaryItem
                                                iconName='Commissioned'
                                                label='Сдано'
                                                value={
                                                    `${commissionedCount.value}\u00A0${commissionedCount.shortLabel}`
                                                }
                                            />
                                        </Grid.Cell>
                                    )}
                                    {searchIndex === NewBuildingWhitelabelEnum.Default && inProgressCount && (
                                        <Grid.Cell m={4}>
                                            <SummaryItem
                                                iconName='InProgress'
                                                label='Строится'
                                                value={`${inProgressCount.value}\u00A0${inProgressCount.shortLabel}`}
                                            />
                                        </Grid.Cell>
                                    )}
                                </Grid>
                                { hasM2Pro ? (
                                    <div className={cn('buttons-container')}>
                                        <Grid cols={3}>
                                            <Grid.Cell>
                                                <M2ProOrderButton
                                                    newBuildingId={String(newBuildingId)}
                                                    variant='primary'
                                                    size='m'
                                                    fullWidth
                                                    eventData={{ page_type: 'card' }}
                                                />
                                            </Grid.Cell>
                                            <Grid.Cell>
                                                <ChatButton
                                                    variant='secondary'
                                                    size='m'
                                                    hasText
                                                    hasIcon={false}
                                                    fullWidth
                                                />
                                            </Grid.Cell>
                                            <Grid.Cell>
                                                {renderAllObjectsButton(developerPageLink)}
                                            </Grid.Cell>

                                        </Grid>
                                    </div>
                                ) : (
                                    <div className={cn('buttons-container')}>
                                        <Grid cols={12}>
                                            <Grid.Cell
                                                m={props.hasChat ? 4 : 5}
                                                xl={hasCustomDeveloperBtnSize ? 5 : 4}
                                            >
                                                <QRCodePhoneButton
                                                    href={buttonsData[developer.id].href}
                                                    onClick={() => handleClick(developer.id)}
                                                    qrCodeValue={buttonsData[developer.id].qrCodeValue}
                                                    gtm={DATA_GTM.CARD_PHONE_3}
                                                    loading={buttonsData[developer.id].isLoading}
                                                    buttonVariant={isPhoneRegion ? 'primary' : 'secondary'}
                                                >
                                                    {buttonsData[developer.id].text}
                                                </QRCodePhoneButton>
                                            </Grid.Cell>
                                            {hasCallback || props.hasChat ? (
                                                <Grid.Cell
                                                    m={props.hasChat ? 5 : 4}
                                                    xl={4}
                                                >
                                                    <div className={cn('feedback')}>
                                                        {hasCallback ? (
                                                            <CallbackModal
                                                                newBuildingName={props.newBuildingName}
                                                                address={props.newBuildingAddress}
                                                                emailSender={defineCallbackEmailSender(isMskMO, isSPbLO)}
                                                                developerName={props.developerNames}
                                                                gtmSendClick={DATA_GTM.CARD_CALL_BACK_SEND}
                                                                onSendEventPush={handleStatEventPush}
                                                                gtmShowModal={DATA_GTM.CARD_CALL_BACK}
                                                                btnWrapperClass={cn('callBack')}
                                                                callbackUnitId={calltouchUnitId[props.isSPbLO ? 'Spb' : 'Msk']}
                                                            />
                                                        ) : null}
                                                        {props.hasChat ? (
                                                            <ChatButton
                                                                className={cn('openChat')}
                                                                size='m'
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Grid.Cell>
                                            ) : null}
                                            <Grid.Cell
                                                m={3}
                                                xl={hasCustomDeveloperBtnSize ? 3 : 4}
                                            >
                                                {renderAllObjectsButton(developerPageLink)}
                                            </Grid.Cell>
                                        </Grid>
                                    </div>
                                )}
                                <div className={cn('disclaimer-container')}>
                                    {developer.webSite && (
                                        <Typography
                                            tag='span'
                                            variant='tiny'
                                            color='minor'
                                        >
                                            С проектной декларацией можно ознакомиться на сайте застройщика.&nbsp;
                                        </Typography>
                                    )}
                                    {(commissionedCount || inProgressCount) && (
                                        <Typography
                                            tag='span'
                                            variant='tiny'
                                            color='minor'
                                        >
                                            Объекты застройки включают все регионы.
                                        </Typography>
                                    )}
                                </div>
                            </li>
                        );
                    }

                    return null;
                })
            }
        </ul>
    );
};

export default React.memo(NewBuildingCardDevelopers);
