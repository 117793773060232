/* global ymaps */
import { Ruler } from '../../Icon';
import { YMapControlButtonTemplate } from '../Button';
import { YMapControlCheckY, YMapControlCheckProps } from '../Check';

const baseClass = 'YMapControlRuler';

type YMapControlRulerProps = YMapControlCheckProps;

export function YMapControlRuler({
    className,
    dataTest = 'ruler',
    index,
    title
}: YMapControlRulerProps) {
    YMapControlRulerY.use({
        index,
        dataTest,
        className,
        title
    } as YMapControlRulerProps);

    return null;
}

class YMapControlRulerY extends YMapControlCheckY<
    YMapControlRulerProps,
    ymaps.control.IRulerControl
> {
    protected create() {
        const options = {
            layout: this.layoutClass()
        } as ymaps.control.IRulerControlParameters['options'];

        return (new this.ymaps.control.RulerControl({
            state: this.state(),
            options
        }) as unknown) as ymaps.control.IRulerControl;
    }

    protected template() {
        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            dataTest: this.props.dataTest,
            className: `${baseClass} ${this.checkClass}`,
            children: `${Ruler.str({})}`,
            title: this.props.title
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        return class YMapControlRulerLayout extends Layout {
            protected toggle(next: boolean) {
                const behaviors = this.map.behaviors;

                if (next) behaviors.enable('ruler');
                else behaviors.disable('ruler');
            }
        };
    }
}
