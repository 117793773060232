import React, { useCallback, useContext } from 'react';
import upperFirst from 'lodash/upperFirst';

import type { NewBuildingCard } from '@search/graphql-typings';

import classname from '@search/classname/src';

import { Typography } from '@vtblife/uikit';
//import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { ImageLazySeo } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySeo';

import type { GalleryImage } from '../Gallery';
import { GalleryContext } from '../Gallery/GalleryContext';

import './styles.css';

export type Image = GalleryImage & {
    quarter: number;
    year: number;
    date: string;
    address: string;
};

export type ConstructionProps = {
    items: NewBuildingCard['constructionProgress'];
    title: Pick<NewBuildingCard['title'], 'nominative' | 'genitive'>;
    titleSeoTail: string;
}

export const groupImages = ({ items, title, titleSeoTail }: ConstructionProps): Image[][][] => [ ...items ]
    .map(({ image, quarter, year, address }) => {
        const date = `${quarter} кв. ${year} г.`;

        return ({
            ...image,
            year,
            quarter,
            date,
            address,
            title: `${title.nominative}${titleSeoTail}${address ? `, ${address}` : ''} — ${date}`
        });
    })
    .sort(dateSorter)
    .reduce((groups, image) => {
        if (groups.length === 0 || groups[groups.length - 1][0].date !== image.date) {
            groups.push([]);
        }

        groups[groups.length - 1].push(image);

        return groups;
    }, [] as Image[][])
    .map(
        group => group
            .sort(addressSorter)
            .reduce((groups, image) => {
                if (groups.length === 0 || groups[groups.length - 1][0].address !== image.address) {
                    groups.push([]);
                }

                groups[groups.length - 1].push(image);

                return groups;
            }, [] as Image[][])
    );

export const useConstructionGallery = ({ title }: { title: string }) => {
    const galleryContext = useContext(GalleryContext);

    return {
        handleGallery: useCallback((
            imageGroup: Image[],
            index: number
        ) => galleryContext?.open({
            elements: imageGroup.map(({ date, address, ...other }) => ({
                additionalTitle: `${date}${address ? `, ${address}` : ''}`,
                ...other
            })),
            index,
            title
        }), [ galleryContext, title ])
    };
};

const cn = classname.bind(null, 'ConstructionProgressItem');

export const ConstructionProgressItem = ({
    url,
    title,
    date,
    isDateHidden = false,
    address,
    isMobile = false,
    onImageClick,
    newBuildingName,
    originPath
}: {
    url?: string;
    title: string;
    date: string;
    isDateHidden?: boolean;
    address: string;
    isMobile?: boolean;
    onImageClick?: () => void;
    newBuildingName?: string;
    originPath?: string;
}) => {
    const mainTitle = isDateHidden ? upperFirst(address) : date;
    const subTitle = isDateHidden ? undefined : address;

    return (
        <div className={cn(null, { mobile: isMobile })}>
            <div
                className={cn('content')}
                onClick={onImageClick}
            >
                <div className={cn('imageWrapper')}>
                    <ImageLazySeo
                        alt={title}
                        title={title}
                        metaName={`${newBuildingName} — ${date} ход строительства`}
                        noLocalItemScope
                        loadingLazy='native'
                        originPath={url ?? originPath}
                        srcWidth={560}
                        srcHeight={420}
                    />
                </div>
                {mainTitle && (
                    <Typography
                        variant='primary-alone'
                        className={cn('title')}
                    >
                        {mainTitle}
                    </Typography>
                )}
                {subTitle && (
                    <Typography
                        variant='secondary'
                        color='secondary'
                    >
                        {subTitle}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export const dateSorter = <T extends {
    year: number;
    quarter: number;
}> (a: T, b: T): number => b.year - a.year || b.quarter - a.quarter;

export const addressSorter = <T extends {
    address: string;
// eslint-disable-next-line no-nested-ternary
}> (a: T, b: T): number => a.address > b.address ? 1 : a.address < b.address ? -1 : 0;
