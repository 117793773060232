export enum FloorConstraints {
    UNKNOWN = 0,
    ONLY = 1,
    EXCLUDE = 2,
}

export enum FloorConstraintsString {
    ONLY = 'ONLY',
    EXCLUDE = 'EXCLUDE',
}
