import React from 'react';
import { useGqlContext } from '@search/gql-client/src';
import { graphql } from '@search/gql-client/src/useGql';
import { useGql2Loader } from '@search/gql-client/src/useGql2';

import { UseAuthError } from '../useAuth';
import {
    userDetailsQuery$data as userDetailsQueryResponse,
    userDetailsQuery$variables as userDetailsQueryVariables
} from './__generated__/userDetailsQuery.graphql';

const userDetailsQuery = graphql`
    query userDetailsQuery {
        userDetails {
            phone
            username
            firstname
            surname
            patronymic
            email
        }
        userProfile {
            ... on UserProfile {
                person {
                    primaryPhone {
                        number
                        formattedNumber
                        extraNumber
                    }
                    name
                    surname
                    patronymic
                    email
                }
            }
            ... on UnauthenticatedError {
                message
            }
        }
    }
`;

export function useUserDetailsFetcher() {
    const gql = useGqlContext();

    return React.useCallback(
        (vars: userDetailsQueryVariables = {}, opts?: { signal?: AbortSignal }) => gql.client<
            userDetailsQueryVariables,
            userDetailsQueryResponse
        >(userDetailsQuery, vars, opts)
            .then(response => {
                if (! response.data?.userProfile || response.data?.userProfile.message) throw new UseAuthError();
                return response;
            }),
        [ gql ]
    );
}

export function useUserDetailsLoader() {
    return useGql2Loader(useUserDetailsFetcher(), { cachePrefix: 'userDetails' });
}
