import React, { FunctionComponent } from 'react';

import { Badge, LinkButton, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';

import './styles.css';

const cn = classname.bind(null, 'SamoletPlusSerpBanner');

const SamoletPlusSerpBanner: FunctionComponent<{
    bannerData: {
        title: string;
        subtitle: string;
        buttonText: string;
        url: string;
        image: string;
    };
    developerLogo?: string;
}> = ({
    developerLogo,
    bannerData: {
        title,
        subtitle,
        buttonText,
        url,
        image
    }
}) => {
    return (
        <div
            className={cn()}
            style={{
                backgroundImage: `linear-gradient(180deg, rgba(15, 16, 30, 0.15) 0%, rgba(31, 31, 46, 0.8) 100%), url(${
                    image
                }`
            }}
        >
            <a
                href={url}
                target='_blank'
                className={cn('link')}
            />
            <div>
                <Image url={developerLogo} />
            </div>
            <div>
                <Badge color='white'>Реклама</Badge>
                <Typography
                    bold
                    variant='promo-h1'
                    color='white-500'
                    className={cn('title')}
                >
                    {title}
                </Typography>
                <Typography
                    variant='h4'
                    color='white-500'
                    className={cn('subtitle')}
                >
                    {subtitle}
                </Typography>
                <div className={cn('button')}>
                    <LinkButton
                        width={230}
                        variant='primary-alt-bg'
                        href={url}
                        target='blank'
                    >
                        {buttonText}
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default SamoletPlusSerpBanner;
