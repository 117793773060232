import React from 'react';

import { Link, Typography } from '@vtblife/uikit';

import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardNavbar');

export interface INewBuildingCardNavbarProps {
    navLinks: Record<string, {
        title: string;
        titleApartments?: string;
        id: string;
    }>;
    hasFlats: boolean;
    hasSpecialEvents: boolean;
    hasConstructionProgress: boolean;
    hasApartmentsOnly: boolean;
}

const NewBuildingCardNavbar = ({
    navLinks,
    hasFlats,
    hasSpecialEvents,
    hasConstructionProgress,
    hasApartmentsOnly
}: INewBuildingCardNavbarProps) => {
    const links = { ...navLinks };

    if (! hasFlats) {
        delete links.flats;
    }

    if (! hasSpecialEvents) {
        delete links.specialEvents;
    }

    if (! hasConstructionProgress) {
        delete links.constructionProgress;
    }

    return (
        <List className={cn()}>
            {
                Object.values(links).map(item => (
                    <ListItem
                        key={item.id}
                        className={cn('item')}
                    >
                        <Link href={`#${item.id}`}>
                            <Typography>
                                {hasApartmentsOnly && item.titleApartments || item.title}
                            </Typography>
                        </Link>
                    </ListItem>
                ))
            }
        </List>
    );
};

export default React.memo(NewBuildingCardNavbar);
