/* eslint complexity: 0 */

import React, {
    Fragment,
    MutableRefObject,
    ReactElement,
    Suspense,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from 'react';

import { LinkButton, Link } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';
import type {
    DeveloperCard,
    NewBuildingConstructionProgress,
    NewBuildingInfo as GQLNewBuildingInfo,
    NewBuildingOffersInput,
    RouteMetro,
    RouteParams
} from '@search/graphql-typings';
import {
    ApplicationIdEnum,
    NewBuildingOffersSortEnum,
    NewBuildingPositionEnum,
    NewBuildingWhitelabelEnum
} from '@search/graphql-typings';
import { errorCatcherWrap } from '@search/error/src/catcher';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import { getDeveloperBannerGtmData } from '@search/vtbeco-frontend-core/domain/newbuilding/developer-promo/analytics';
import { useGql } from '@search/gql-client/src/useGql';
import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { INewBuildingCardType } from '@search/nb-routes/src/NewBuildingCardRoute';

import {
    BuildingsFilter,
    CommissioningListFilter,
    IFilter
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import ImageBanner from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/ImageBanner';
import { CallbackDataType, CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import { NewBuildingNews } from '@search/vtbeco-frontend-core/view/desktop/components/NewBuildingNews';
import { useMinimalBanksMortgageOffersSliced } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import { Mortgage2StarterModal } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/mortgage2/starter/Mortgage2StarterModal';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';
import type { DataLayerObject } from '@search/vtbeco-frontend-core/domain/google/DataLayerObject';

import classname from '@search/classname/src';
import Breadcrumb from '@search/vtbeco-ui/src/components/Breadcrumb';
import type { UseBankMortgageFormProps } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/MinimalBanksMortgageFields/useBankMortgageForm';
import type { NewsTinyItem } from '@search/vtbeco-frontend-core/view/common/components/NewsTinyCard';
import type { INewBuildingCardRightBlockProps } from '../../../../instances/m2/components/desktop/NewBuildingCard/NewBuildingCardRightBlock';
import { INewBuildingCardTopStickyProps } from '../../../../instances/vtb/components/desktop/NewBuildingCard/NewBuildingCardTopSticky';
import type { Required } from '../../../../types';
import type { Shared } from '../../../../types/shared';

import {
    Building,
    buildingsQuery,
    DocumentGroup,
    makeOffersUpdateDatePostfix,
    NewBuildingCardBuildingsQueryResponse,
    NewBuildingCardBuildingsQueryVariables,
    NewBuildingCardQueryResponse,
    NewBuildingClassEnum,
    useBuildings,
    useDeveloperNames
} from '../../../common/components/NewBuildingCard';
import { NewBuildingInfo } from '../../../common/components/NewBuildingInfo';
import { GlobalContext, IOnChangeGlobalContext, OnChangeGlobalContext } from '../../../common/components/GlobalContext';
import Ellipsis from '../../../common/components/Ellipsis';
import { usePseudoLoading } from '../../../common/hooks/usePseudoLoading';
import { DEFAULT_MAP_ZOOM, STANDALONE_MAP_ZOOM } from '../../../common/constants/map';
import { defineCallbackEmailSender } from '../../../common/libs/defineEmailSender';
import { PhoneButtonStateProvider } from '../../../common/components/NewBuildingCard/PhoneButtonStateProvider';
import { ChatStateProvider } from '../../../common/components/NewBuildingCard/ChatStateProvider';
import { useBreadcrumbs } from '../../../common/components/NewBuildingCardPage/useBreadcrumbs';
import { usePhoneButton } from '../../../common/hooks/usePhoneButton';
import { NewBuildingDescription } from '../../../common/components/NewBuildingDescription';
import ChatButton from '../../../common/components/ChatButton';
import NewBuildingStats from '../../../common/components/NewBuildingStats';
import { Document, DocumentType } from '../../../common/components/Document';
import useMortgageDemandLink from '../../../common/hooks/useMortgageDemandLink';

import { DocumentsModal } from '../DocumentsModal';
import QuickSelectionCardBanner from '../QuickSelection/QuickSelectionCardBanner';
import NewBuildingCardDevelopers from './NewBuildingCardDevelopers';
import NewBuildingCardGallery from './NewBuildingCardGallery';
import { StickyNavbar } from './NewBuildingCardNavbar/StickyNavbar';
import NewBuildingCardSpecialEvents from './NewBuildingCardSpecialEvents';
import NewBuildingCardBuildingsMap from './NewBuildingCardBuildingsMap';
import { NewBuildingCardOfferSearchWrapper } from './NewBuildingCardOfferSearch';
import NewBuildingCardOfferSearchFilters from './NewBuildingCardOfferSearchFilters';
import NewBuildingCardSimilar from './NewBuildingCardSimilar';
import NewBuildingCardOthersByDeveloper from './NewBuildingCardOthersByDeveloper';
import NewBuildingCardConstructionProgress from './NewBuildingCardConstructionProgress';
import NewBuildingCardHeading from './NewBuildingCardHeading';
import NewBuildingCardLocation from './NewBuildingCardLocation';
import NewBuildingCardMap from './NewBuildingCardMap';
import NewBuildingCardSectionHeading from './NewBuildingCardSectionHeading';

import './styles.css';

interface INewBuildingCardProps {
    newBuilding: NewBuildingCardQueryResponse['newBuilding'];
    isCardLoading: boolean;
    pageParams: Shared.INewBuildingCardParams;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateMultipleFilters: (multipleFilters: IFilter[]) => void;
    removeFilter: (filter: IFilter) => void;
    resetFilters: () => void;
    handlePageChange: (pageNumber: number) => void;
    handleSortChange: (sort: string | string[]) => void;
}

const cn = classname.bind(null, 'NewBuildingCard');

const NAV_LINKS: Record<string, {
    title: string;
    titleApartments?: string;
    id: string;
}> = {
    specialEvents: {
        title: 'Акции\u00A0и\u00A0скидки',
        id: 'special-events'
    },
    flats: {
        title: 'Квартиры\u00A0от\u00A0застройщика',
        titleApartments: 'Апартаменты\u00A0от\u00A0застройщика',
        id: 'flats'
    },
    description: {
        title: 'Описание',
        id: 'description'
    },
    map: {
        title: 'Расположение',
        id: 'location'
    },
    constructionProgress: {
        title: 'Ход\u00A0строительства',
        id: 'construction-progress'
    }
};

const OfferSearchSection: React.FunctionComponent<INewBuildingCardProps & {
    buildingsData: Building[];
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string
    ) => ReactElement | null;
    developerLink: string;
    developer: NonNullable<NewBuildingCardQueryResponse['newBuilding']['developerCards'][0]['developer']>;
    callbackData: CallbackDataType;
}> = ({
    pageParams,
    filters,
    updateFilter,
    updateMultipleFilters,
    removeFilter,
    resetFilters,
    handlePageChange,
    handleSortChange,
    newBuilding,
    buildingsData,
    renderCallBack,
    developerLink,
    developer,
    callbackData
}) => {
    const {
        instanceConfig: {
            accreditedKey,
            searchIndex,
            hasCustomOfferFilterSize
        }
    } = useContext(GlobalContext);
    const {
        buildingGroups,
        notAccreditedBuildingsTotal,
        hasFrozenBuildings,
        buildings,
        buildingById,
        getBuildingIds,
        commissioningOptions,
        queryDisplayValues
    } = useBuildings(buildingsData, accreditedKey);

    const finishingFlat = pageParams.finishingFlat && Array.isArray(pageParams.finishingFlat) ?
        pageParams.finishingFlat :
        [ pageParams.finishingFlat ];

    const offersFilters = {
        newBuildingId: pageParams.id,

        finishingTypeList: pageParams.finishingFlat ? finishingFlat : [],

        floorMin: pageParams.floorMin,
        floorMax: pageParams.floorMax,
        floorFirst: pageParams.floorFirst,
        floorLast: pageParams.floorLast,

        rooms: pageParams.rooms,

        priceMin: pageParams.priceMin,
        priceMax: pageParams.priceMax,
        priceType: pageParams.priceType,

        totalAreaRangeMin: pageParams.totalAreaRangeMin,
        totalAreaRangeMax: pageParams.totalAreaRangeMax,
        livingAreaRangeMin: pageParams.livingAreaRangeMin,
        livingAreaRangeMax: pageParams.livingAreaRangeMax,
        kitchenAreaRangeMin: pageParams.kitchenAreaRangeMin,

        buildingId: pageParams.commissioningDate ?
            getBuildingIds(pageParams.commissioningDate as string | string[]) :
            pageParams.buildingId
    } as Required<NewBuildingOffersInput>;

    const {
        data: buildingSerpData
    } = useGql<
        NewBuildingCardBuildingsQueryVariables,
        NewBuildingCardBuildingsQueryResponse
    >(
        buildingsQuery,
        {
            filters: offersFilters,
            whiteLabel: searchIndex
        }
    );

    const flatsRef = useRef<HTMLDivElement>(null);

    const { isPseudoLoading, executePseudoLoading } = usePseudoLoading();

    const handleChange = useCallback((skipPseudoLoading: boolean = false) => {
        executePseudoLoading(skipPseudoLoading);

        const element = document.getElementById(NAV_LINKS.flats.id);

        if (element && element.getBoundingClientRect().top < 0) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [ executePseudoLoading ]);

    const [ isBuildingPickerVisible, toggleBuildingPicker ] = useState<boolean>(false);

    const showBuildingPicker = useCallback(() => {
        toggleBuildingPicker(true);
    }, []);

    const hideBuildingPicker = useCallback(() => {
        executePseudoLoading();

        toggleBuildingPicker(false);
    }, [ executePseudoLoading ]);

    const { buildingId } = pageParams;

    const selectedBuildings: Building[] = useMemo(
        () => buildingId && ([] as number[])
            .concat(buildingId)
            .map(id => buildingById[id]) || [],
        [ buildingId, buildingById ]
    );

    const filteredBuildings = useMemo(() => {
        const filteredBuildingIds = buildingSerpData?.getBuildings?.ids ?? [];

        const unfilteredBuildings = selectedBuildings.length ? selectedBuildings : buildings;

        return unfilteredBuildings
            .filter(building => filteredBuildingIds.includes(building.id!))
            .sort((b0, b1) => filteredBuildingIds.indexOf(b0.id!) - filteredBuildingIds.indexOf(b1.id!));
    }, [ buildingSerpData?.getBuildings?.ids, selectedBuildings, buildings ]);

    const handleBuildingsSelect = useCallback((current: Building[]) => {
        updateFilter(new CommissioningListFilter());
        updateFilter(new BuildingsFilter(current.map(({ id }) => id!)));
    }, [ updateFilter ]);

    const { hasApartmentsOnly } = newBuilding;

    const offersUpdateDatePostfix = makeOffersUpdateDatePostfix(newBuilding.offersUpdateDate);

    const title = hasApartmentsOnly ? NAV_LINKS.flats.titleApartments : NAV_LINKS.flats.title;

    return (
        <>
            <div
                id={NAV_LINKS.flats.id}
                className={cn('row', { anchor: true })}
                ref={flatsRef}
            >
                {title && (
                    <NewBuildingCardSectionHeading
                        title={title}
                        postfix={offersUpdateDatePostfix !== '' ? `(${offersUpdateDatePostfix})` : ''}
                        subtitle={newBuilding.title.locative}
                        seoTitle={newBuilding.seo.seoTitle}
                    />
                )}
                <NewBuildingCardOfferSearchFilters {...{
                    filters,
                    updateFilter,
                    updateMultipleFilters,
                    removeFilter,
                    resetFilters,
                    handleChange,
                    showBuildingPicker,
                    commissioningOptions,
                    queryDisplayValues,
                    isMskMO: newBuilding.isMskMO,
                    isSPbLO: newBuilding.isSPbLO,
                    noBuildingPicker: buildings.length < 2,
                    noOffers: newBuilding.noOffers,
                    hasCustomOfferFilterSize: hasCustomOfferFilterSize ?? false,
                    finishingOptions: newBuilding.offersSummaryInfo.finishingTypesList
                }} />
                <React.Suspense fallback={null}>
                    <NewBuildingCardOfferSearchWrapper
                        pageParams={pageParams}
                        offersFilters={offersFilters}
                        handleSortChange={handleSortChange}
                        isPseudoLoading={isPseudoLoading}
                        onPageChange={handlePageChange}
                        sort={pageParams.sort as NewBuildingOffersSortEnum | undefined}
                        onSortChange={handleSortChange}
                        buildings={selectedBuildings}
                        filteredBuildings={filteredBuildings}
                        allBuildings={buildings}
                        buildingById={buildingById}
                        onChange={handleChange}
                        defaultDateString={undefined/*defaultDateString*/}
                        newBuildingName={newBuilding.title.nominative}
                        newBuildingId={String(newBuilding.id)}
                        hasNoOffers={newBuilding.noOffers}
                        renderCallBack={renderCallBack}
                        developerLink={developerLink}
                        developerTitle={developer.title}
                        developerImage={developer.icon ?? undefined}
                        backofficeRegionId={newBuilding.backofficeRegionId ?? undefined}
                        hasApartmentsOnly={hasApartmentsOnly}
                        isMskMOorSPbLo={newBuilding.isMskMO || newBuilding.isSPbLO}
                        callbackData={callbackData}
                        m2Pro={newBuilding.m2Pro}
                    />
                </React.Suspense>
            </div>
            {
                isBuildingPickerVisible ? (
                    <NewBuildingCardBuildingsMap
                        hasApartmentsOnly={hasApartmentsOnly}
                        coordinates={newBuilding.coordinates as number[]}
                        buildingGroups={buildingGroups}
                        notAccreditedBuildingsTotal={notAccreditedBuildingsTotal}
                        hasFrozenBuildings={hasFrozenBuildings}
                        selectBuildings={handleBuildingsSelect}
                        selectedBuildings={selectedBuildings}
                        onBuildingsSelect={handleChange}
                        closeMap={hideBuildingPicker}
                    />
                ) : null
            }
        </>
    );
};

const OfferSearchSectionWithCatcher = errorCatcherWrap(OfferSearchSection);

const NewBuildingCard: React.FunctionComponent<INewBuildingCardProps> = ({
    newBuilding,
    isCardLoading,
    pageParams,
    filters,
    updateFilter,
    updateMultipleFilters,
    removeFilter,
    resetFilters,
    handlePageChange,
    handleSortChange
}) => {
    const {
        instanceConfig: {
            accreditedKey,
            hasDescription,
            hasStats,
            hasDeveloperPromo,
            hasSpecialEvents: shouldShowSpecialEvents,
            hasBreadcrumbs,
            searchIndex,
            hasDeveloperCallBack,
            hasStickyNavbar,
            hasCustomDeveloperBtnSize,
            hasFullDescription,
            hasNews,
            hasQuickSelection,
            calltouchUnitId
        },
        components: {
            Mortgage,
            NewBuildingCardRightBlock: NewBuildingCardRightBlockComponent
        }
    } = useContext(GlobalContext);

    const NewBuildingCardRightBlock = NewBuildingCardRightBlockComponent as React.FunctionComponent<INewBuildingCardRightBlockProps>;
    const NewBuildingCardRightBlockVtb = NewBuildingCardRightBlockComponent as React.FunctionComponent<INewBuildingCardTopStickyProps>;
    const staticPath = useStaticPathConfig();
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    const newBuildingId = pageParams.id;

    const buildingsData = newBuilding.buildings!;
    const breadcrumbs = useBreadcrumbs({
        narrowRegionId: newBuilding.narrowRegionId,
        narrowRegionLocative: newBuilding.narrowRegionLocative,
        routeParams: newBuilding.routeParams as RouteParams,
        title: newBuilding.title.nominative
    });

    const {
        buildingGroups,
        buildings,
        buildingNameById
    } = useBuildings(buildingsData as unknown as Building[], accreditedKey);

    const [ selectedDocumentGroup, selectDocumentGroup ] = useState<DocumentGroup | null>(null);

    const firstDeveloper = newBuilding.developerCards[0]?.developer;
    const developerLink = useMemo(() => onChangeGlobalContext.linkBuilder.developer && firstDeveloper &&
        onChangeGlobalContext.linkBuilder.developer({
            id: firstDeveloper.id,
            name: firstDeveloper.translitTitle
        }), [ firstDeveloper, onChangeGlobalContext.linkBuilder ]);

    const developerVtb = firstDeveloper?.image?.small ? {
        title: firstDeveloper.title,
        imageUrl: firstDeveloper.image.small
    } : undefined;

    const metroList = useMemo(
        () => newBuilding.routes?.filter((route: RouteMetro) => route!.station) ?? [],
        [ newBuilding.routes ]
    );

    const { constructionApprovals, projectDeclarations, actsOfCommissioning } = newBuilding.documents;
    const hasDocs = [ constructionApprovals, projectDeclarations, actsOfCommissioning ].some(docs => docs.length);

    const {
        similarParams: { regionIds, buildingClasses },
        developerRegionIds,
        hasApartments,
        hasApartmentsOnly,
        developerPromoBanner,
        specialEvents,
        constructionProgress,
        ranking
    } = newBuilding;

    const developerPromoGtms = useMemo(() => {
        const gtmClickData = getDeveloperBannerGtmData(developerPromoBanner?.developerName, {
            action: developerPromoBanner?.developerName === 'mortgage' ? 'open' : 'click',
            section: 'banner_left',
            pageType: 'card'
        });

        return {
            gtmClickData,
            gtmShowData: getDeveloperBannerGtmData(developerPromoBanner?.developerName, {
                action: 'view',
                section: 'banner_left',
                pageType: 'card'
            })
        };
    }, [ developerPromoBanner ]);

    const hasSpecialEvents = Boolean(shouldShowSpecialEvents && specialEvents.length > 0);
    const hasConstructionProgress = constructionProgress.length > 0;

    const onStatEventPush = useCallback(() => dataLayerPush({
        event: 'nb_call_like',
        nb_price: newBuilding.priceMin ?? 0,
        nb_class: ranking ?? ''
    }), [ newBuilding.priceMin, ranking ]);

    const phoneButtonState = usePhoneButton({
        newBuildingId: newBuilding.id,
        developerCards: newBuilding.developerCards,
        phoneNum: 0,
        onStatEventPush
    });

    const isMskMOorSpbLO = newBuilding.isMskMO || newBuilding.isSPbLO;

    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const shouldShowCallBack = hasDeveloperCallBack && isMskMOorSpbLO;

    const developerNames = useDeveloperNames(newBuilding.developerCards as DeveloperCard[]);

    // @ts-ignore
    const callbackData: CallbackDataType = useMemo(() => ({
        newBuildingName: newBuilding.title.nominative,
        address: newBuilding.address,
        developerName: developerNames,
        callbackUnitId: calltouchUnitId[newBuilding.isSPbLO ? 'Spb' : 'Msk'],
        emailSender: defineCallbackEmailSender(newBuilding.isMskMO, newBuilding.isSPbLO)
    }), [ newBuilding.title.nominative, newBuilding.address, newBuilding.isSPbLO, newBuilding.isMskMO, developerNames, calltouchUnitId ]);

    const renderCallBack = useCallback((
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => (
        <>
            {shouldShowCallBack ? (
                <CallbackModal
                    btnWrapperClass={cn('callBack')}
                    gtmSendClick={sendDataGTM}
                    onSendEventPush={onStatEventPush}
                    gtmShowModal={dataGTM}
                    // @ts-ignore
                    extra={extra}
                    // @ts-ignore
                    subjectExtra={subjectExtra}
                    {...callbackData}
                />
            ) : null}
            {newBuilding.hasChat ? (
                <ChatButton
                    className={cn('openChat')}
                    size='m'
                    fullWidth
                    hasText
                />
            ) : null}
        </>
    ), [ callbackData, newBuilding.hasChat, shouldShowCallBack, onStatEventPush ]);

    const firstSpecialEventTitle = useMemo(
        () => specialEvents[0]?.title,
        [ specialEvents ]
    );

    const titleSeoTail = useMemo(
        () => newBuilding.seo.seoTitle ? ` (${newBuilding.seo.seoTitle})` : '',
        [ newBuilding.seo.seoTitle ]
    );

    const stickyRef = useRef<HTMLDivElement>(null);

    const hasFlats = buildings.length > 0 || ! newBuilding.noOffers;

    const [ starterModalLocalState, setStarterModalLocalState ] = useState<{ isOpen: boolean; gtmSendData?: DataLayerObject; gtmShowData?: DataLayerObject }>({ isOpen: false });
    const openMortgageStarterModal = useCallback((place: 'right' | 'special_events') => () => setStarterModalLocalState({
        isOpen: true,
        gtmSendData: {
            event: 'nb_ib_form',
            action: 'send',
            page_type: 'card',
            place
        },
        gtmShowData: {
            event: 'nb_ib_form',
            action: 'open',
            page_type: 'card',
            place
        },
    }), []);
    const closeMortgageStarterModal = useCallback(() => setStarterModalLocalState({ isOpen: false }), []);

    const {
        term,
        propertyCost,
        ownAmount,
        purpose,
        regionId
    } = newBuilding.mortgageParams;

    const {
        offersFull
    } = useMinimalBanksMortgageOffersSliced({
        term,
        propertyCost,
        ownAmount,
        purpose: purpose as UseBankMortgageFormProps['purpose'],
        regionId,
        defaultVisibleOffers: 4
    });

    const mortgageDemandLink = useMortgageDemandLink({
        regionId: newBuilding.mortgageParams.regionId,
        propertyCost: newBuilding.mortgageParams.propertyCost,
        purpose: newBuilding.mortgageParams.purposeValue,
        claimType: newBuilding.mortgageParams.claimType,
        ownAmount: newBuilding.mortgageParams.ownAmount,
        newBuildingName: newBuilding.title.nominative
    });

    const mortgageCalculatorRef = useRef<HTMLDivElement>(null);
    const scrollToMortgageCalculator = useCallback(() => {
        mortgageCalculatorRef?.current?.scrollIntoView({ behavior: 'smooth' });
        if (mortgageCalculatorRef?.current) {
            dataLayerPush({
                event: 'nb_ib_form',
                action: 'scroll',
                place: 'right',
                page_type: 'card',
            });
        }
    }, []);

    const renderMortgageButton = useCallback((place: 'right' | 'special_events') => (
        <div className={cn('mortgageButton')}>
            <LinkButton
                fullWidth
                dataTest='mortgage-send-query'
                variant='accent'
                onClick={hasRoleM2Pro ? undefined : openMortgageStarterModal(place)}
                href={hasRoleM2Pro ? mortgageDemandLink : undefined}
            >
                Купить в ипотеку
            </LinkButton>
        </div>
    ), [ hasRoleM2Pro, mortgageDemandLink, openMortgageStarterModal ]);
    const renderMortgageButtonRightBlock = useCallback(() => renderMortgageButton('right'), [ renderMortgageButton ]);
    const renderMortgageButtonSpecialEvents = useCallback(() => renderMortgageButton('special_events'), [ renderMortgageButton ]);

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: newBuilding.gallery?.baseUrl });

    if (isCardLoading) {
        return null;
    }

    return (
        <div className={cn()}>
            {hasBreadcrumbs && breadcrumbs && (
                <div className={cn('breadcrumbs')}>
                    <Breadcrumb
                        sections={breadcrumbs.items.map(({
                            title: content,
                            path: href,
                            active
                        }) => ({
                            content,
                            href,
                            active
                        }))}
                        withoutSeoMarkup
                    />
                </div>
            )}
            <PhoneButtonStateProvider {...phoneButtonState}>
                <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                    <div className={cn('main')}>
                        <div className={cn('left')}>
                            <NewBuildingCardHeading
                                newBuildingTitle={newBuilding.title.nominative}
                                inspectionStatus={newBuilding.inspectionStatus}
                            />
                            <NewBuildingCardLocation
                                address={newBuilding.address}
                                metroList={metroList}
                                regionId={newBuilding.routeParams.regionId}
                            />
                            {
                                hasStickyNavbar ? (
                                    <StickyNavbar
                                        anchor={stickyRef as MutableRefObject<HTMLDivElement>}
                                        navLinks={NAV_LINKS}
                                        hasFlats={hasFlats}
                                        hasSpecialEvents={hasSpecialEvents}
                                        hasConstructionProgress={hasConstructionProgress}
                                        hasApartmentsOnly={hasApartmentsOnly}
                                    />
                                ) : null
                            }
                            {newBuilding.gallery && (
                                <ImageLazySrcSetProvide getUrl={getUrl}>
                                    <NewBuildingCardGallery
                                        title={newBuilding.title}
                                        titleSeoTail={titleSeoTail}
                                        // @ts-ignore
                                        gallery={newBuilding.gallery}
                                        mapAnchor={NAV_LINKS.map.id}
                                        specialEventTitle={firstSpecialEventTitle}
                                        specialEventsAnchor={NAV_LINKS.specialEvents.id}
                                        isSoldOut={newBuilding.isSoldOut}
                                        isSoon={newBuilding.isSoon}
                                        videoId={newBuilding.videoId}
                                    />
                                </ImageLazySrcSetProvide>
                            )}
                            {
                                hasSpecialEvents ? (
                                    <div
                                        id={NAV_LINKS.specialEvents.id}
                                        className={cn('row', { anchor: true })}
                                    >
                                        <NewBuildingCardSectionHeading
                                            title={NAV_LINKS.specialEvents.title}
                                            subtitle={newBuilding.title.locative}
                                            seoTitle={newBuilding.seo.seoTitle}
                                        />
                                        {newBuildingId && (
                                            <NewBuildingCardSpecialEvents
                                                specialEvents={specialEvents}
                                                buildingNameById={buildingNameById}
                                                newBuildingTitle={newBuilding.title.nominative}
                                                developerTitle={firstDeveloper?.title}
                                                dataGTM={DATA_GTM.CARD_PHONE_4}
                                                renderCallBack={renderCallBack}
                                                hasChat={newBuilding.hasChat}
                                                isMskMOorSPbLo={isMskMOorSpbLO}
                                                renderMortgageButton={renderMortgageButtonSpecialEvents}
                                            />
                                        )}
                                    </div>
                                ) : null
                            }
                            {
                                hasFlats && developerLink && firstDeveloper ? (
                                    <OfferSearchSectionWithCatcher
                                        pageParams={pageParams}
                                        filters={filters}
                                        updateFilter={updateFilter}
                                        updateMultipleFilters={updateMultipleFilters}
                                        removeFilter={removeFilter}
                                        resetFilters={resetFilters}
                                        handlePageChange={handlePageChange}
                                        handleSortChange={handleSortChange}
                                        newBuilding={newBuilding}
                                        buildingsData={buildingsData as unknown as Building[]}
                                        renderCallBack={renderCallBack}
                                        developerLink={developerLink}
                                        developer={firstDeveloper}
                                        isCardLoading={false}
                                        callbackData={callbackData}
                                    />
                                ) : null
                            }
                            <Mortgage
                                ref={mortgageCalculatorRef}
                                mortgageParams={newBuilding.mortgageParams}
                                hasMilitaryMortgage={newBuilding.hasMilitaryMortgage}
                                hasApartments={hasApartments}
                                mortgageDemandLink={hasRoleM2Pro ? mortgageDemandLink : undefined}
                                isMskMOorSpbLO={isMskMOorSpbLO}
                            />
                            <div
                                id={NAV_LINKS.description.id}
                                className={cn('row', { anchor: true })}
                            >
                                <NewBuildingCardSectionHeading
                                    title={NAV_LINKS.description.title}
                                    subtitle={newBuilding.title.genitive}
                                    seoTitle={newBuilding.seo.seoTitle}
                                />
                            </div>
                            <NewBuildingInfo
                                info={newBuilding.info as GQLNewBuildingInfo}
                                view='desktop'
                            />
                            {newBuilding?.fullDescription?.html && hasFullDescription ? <div
                                className={cn('desc')}
                            >
                                <NewBuildingDescription text={newBuilding?.fullDescription?.html} />
                            </div> : null}
                            {
                                hasDescription && newBuilding.description ? (
                                    <div className={cn('row', { type: 'description' })}>
                                        <Grid>
                                            <Grid.Cell>
                                                <Ellipsis text={newBuilding.description} />
                                            </Grid.Cell>
                                        </Grid>
                                    </div>
                                ) : null
                            }
                            {hasStats ? (
                                <div className={cn('stats')}>
                                    <NewBuildingStats
                                        buildingClass={newBuilding.buildingClass}
                                        pricePerM2Min={newBuilding.pricePerM2Min}
                                        metro={metroList[0]}
                                        mortgageParams={newBuilding.mortgageParams}
                                        buildingStatus={newBuilding.buildingStatus}
                                    />
                                </div>
                            ) : null}
                            {
                                hasQuickSelection && isMskMOorSpbLO && ! hasRoleM2Pro ? (
                                    <QuickSelectionCardBanner
                                        callbackData={callbackData}
                                        className={cn('quickSelectionCardBanner')}
                                    />
                                ) : null
                            }
                            <NewBuildingCardMap
                                id={NAV_LINKS.map.id}
                                heading={
                                    <NewBuildingCardSectionHeading
                                        title={NAV_LINKS.map.title}
                                        subtitle={newBuilding.title.genitive}
                                        seoTitle={newBuilding.seo.seoTitle}
                                    />
                                }
                                zoom={
                                    newBuilding.position === NewBuildingPositionEnum.Standalone ?
                                        STANDALONE_MAP_ZOOM :
                                        DEFAULT_MAP_ZOOM
                                }
                                center={newBuilding.coordinates as number[]}
                                buildingGroups={buildingGroups}
                                regionId={newBuilding.routeParams.regionId}
                                newBuildingId={newBuilding.id}
                                centeringControlTitle={`Переместить к ${newBuilding.title.dative}`}
                            />
                            {
                                regionIds.length ? (
                                    <Suspense fallback=''>
                                        <NewBuildingCardSimilar
                                            heading={
                                                <NewBuildingCardSectionHeading
                                                    title='Похожие новостройки'
                                                    subtitle={`на\u00A0${newBuilding.title.accusative}`}
                                                    seoTitle={newBuilding.seo.seoTitle}
                                                />
                                            }
                                            newBuildingId={newBuilding.id}
                                            regionIds={regionIds as number[]}
                                            buildingClasses={buildingClasses as NewBuildingClassEnum[]}
                                        />
                                    </Suspense>
                                ) : null
                            }
                            {hasConstructionProgress && (
                                <div
                                    id={NAV_LINKS.constructionProgress.id}
                                    className={cn('row', { anchor: true })}
                                >
                                    <NewBuildingCardSectionHeading
                                        title={NAV_LINKS.constructionProgress.title}
                                        subtitle={newBuilding.title.genitive}
                                        seoTitle={newBuilding.seo.seoTitle}
                                        rightBlock={onChangeGlobalContext.linkBuilder.construction && (
                                            <Link
                                                target='blank'
                                                href={onChangeGlobalContext.linkBuilder.construction(
                                                    pageParams as unknown as INewBuildingCardType
                                                )}
                                            >
                                                Показать все этапы
                                            </Link>
                                        )}
                                    />
                                    <ImageLazySrcSetProvide getUrl={getUrl}>
                                        <NewBuildingCardConstructionProgress
                                            items={constructionProgress as NewBuildingConstructionProgress[]}
                                            title={newBuilding.title}
                                            titleSeoTail={titleSeoTail}
                                        />
                                    </ImageLazySrcSetProvide>
                                </div>
                            )}
                            {
                                hasDocs ? (
                                    <Fragment>
                                        <div className={cn('row', { anchor: true })}>
                                            <NewBuildingCardSectionHeading
                                                title='Документы'
                                                subtitle={newBuilding.title.genitive}
                                                seoTitle={newBuilding.seo.seoTitle}
                                            />
                                        </div>
                                        <div className={cn('row', { type: 'documents' })}>
                                            <Grid cols={2}>
                                                {
                                                    [
                                                        [ constructionApprovals, 'Разрешение на строительство' ],
                                                        [ projectDeclarations, 'Проектная декларация' ],
                                                        [ actsOfCommissioning, 'Разрешение на ввод в эксплуатацию' ]
                                                    ].map(([ docs, title ], i) => docs.length ? (
                                                        <Grid.Cell key={i}>
                                                            <div className={cn('document')}>
                                                                <Document
                                                                    title={title as string}
                                                                    subtitle={`${docs.length} ${declensionByNumber(
                                                                        docs.length, [
                                                                            'документ',
                                                                            'документа',
                                                                            'документов'
                                                                        ]
                                                                    )}`}
                                                                    onClick={() => selectDocumentGroup({
                                                                        title: title as string,
                                                                        documents: docs as DocumentType[]
                                                                    })}
                                                                />
                                                            </div>
                                                        </Grid.Cell>
                                                    ) : null)
                                                }
                                            </Grid>
                                            {
                                                selectedDocumentGroup ? (
                                                    <DocumentsModal
                                                        onClose={() => selectDocumentGroup(null)}
                                                        title={selectedDocumentGroup.title}
                                                        documents={selectedDocumentGroup.documents}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    </Fragment>
                                ) : null
                            }
                            {
                                newBuilding.developerCards.length ? (
                                    <>
                                        <div className={cn('row', { anchor: true })}>
                                            <NewBuildingCardSectionHeading
                                                title={
                                                    newBuilding.developerCards.length > 1 ? 'Застройщики' : 'Застройщик'
                                                }
                                                subtitle={newBuilding.title.genitive}
                                                seoTitle={newBuilding.seo.seoTitle}
                                            />
                                        </div>
                                        <div className={cn('row', { type: 'developers' })}>
                                            <NewBuildingCardDevelopers
                                                developerCards={newBuilding.developerCards as DeveloperCard[]}
                                                developerNames={developerNames}
                                                searchIndex={searchIndex}
                                                newBuildingId={newBuilding.id}
                                                newBuildingName={newBuilding.title.nominative}
                                                newBuildingAddress={newBuilding.address}
                                                hasCustomDeveloperBtnSize={hasCustomDeveloperBtnSize}
                                                isMskMO={newBuilding.isMskMO}
                                                isSPbLO={newBuilding.isSPbLO}
                                                isSpecProjectRegion={newBuilding.isSpecProjectRegion}
                                                hasChat={newBuilding.hasChat}
                                                m2Pro={newBuilding.m2Pro}
                                            />
                                        </div>
                                    </>
                                ) : null
                            }
                            {
                                firstDeveloper?.id && developerRegionIds.length ? (
                                    <Suspense fallback=''>
                                        <NewBuildingCardOthersByDeveloper
                                            heading={
                                                <NewBuildingCardSectionHeading
                                                    title={'Другие ЖК от\u00A0застройщика'}
                                                    subtitle={newBuilding.title.genitive}
                                                    seoTitle={newBuilding.seo.seoTitle}
                                                />
                                            }
                                            developerId={firstDeveloper.id}
                                            newBuildingId={newBuilding.id}
                                            regionIds={developerRegionIds as number[]}
                                        />
                                    </Suspense>
                                ) : null
                            }
                            {
                                hasNews && newBuilding.news?.length ? (
                                    <NewBuildingNews
                                        heading={
                                            <NewBuildingCardSectionHeading
                                                title='Новости'
                                                subtitle={newBuilding.title.genitive}
                                                seoTitle={newBuilding.seo.seoTitle}
                                            />
                                        }
                                        news={newBuilding.news as NewsTinyItem[]}
                                    />
                                ) : null
                            }
                        </div>
                        <div className={cn('right')}>
                            { searchIndex === NewBuildingWhitelabelEnum.Default ? (
                                <NewBuildingCardRightBlock
                                    developerLink={developerLink ?? undefined}
                                    developer={firstDeveloper}
                                    priceMin={newBuilding.priceMin}
                                    priceMax={newBuilding.priceMax}
                                    pricePerM2Min={newBuilding.pricePerM2Min}
                                    pricePerM2Max={newBuilding.pricePerM2Max}
                                    newBuildingId={newBuilding.id}
                                    isMskMO={newBuilding.isMskMO}
                                    isSPbLO={newBuilding.isSPbLO}
                                    isSpecProjectRegion={newBuilding.isSpecProjectRegion}
                                    hasChat={newBuilding.hasChat}
                                    newBuildingName={newBuilding.name}
                                    newBuildingType={newBuilding.type}
                                    newBuildingTitle={newBuilding.title.nominative}
                                    newBuildingAddress={newBuilding.address}
                                    staticPath={staticPath}
                                    buildingClass={newBuilding.buildingClass}
                                    buildingStatus={newBuilding.buildingStatus}
                                    mortgageParams={newBuilding.mortgageParams}
                                    areaRange={newBuilding.formatAreaRange}
                                    developerNames={developerNames}
                                    hasApartments={hasApartments}
                                    hasApartmentsOnly={hasApartmentsOnly}
                                    isSoldOut={newBuilding.isSoldOut}
                                    isSoon={newBuilding.isSoon}
                                    stickyRef={hasStickyNavbar ? stickyRef as MutableRefObject<HTMLDivElement> : undefined}
                                    developerPromoBanner={developerPromoBanner}
                                    isFavorite={Boolean(newBuilding.isFavorite)}
                                    renderMortgageButton={renderMortgageButtonRightBlock}
                                    m2Pro={newBuilding.m2Pro}
                                    scrollToMortgageCalculator={scrollToMortgageCalculator}
                                />
                            ) : (
                                <NewBuildingCardRightBlockVtb
                                    developer={developerVtb}
                                    priceMin={newBuilding.priceMin}
                                    pricePerM2Min={newBuilding.pricePerM2Min}
                                    hasApartments={hasApartments}
                                />
                            )}
                        </div>
                    </div>
                </ChatStateProvider>
            </PhoneButtonStateProvider>
            {hasDeveloperPromo && developerPromoBanner && onChangeGlobalContext.linkBuilder.external && (
                <ImageBanner
                    view='horizontal'
                    targetLink={onChangeGlobalContext.linkBuilder.external(developerPromoBanner.url)}
                    image={developerPromoBanner.long}
                    gtm={DATA_GTM.CARD_BANNER}
                    gtmClickData={developerPromoGtms.gtmClickData}
                    gtmShowData={developerPromoGtms.gtmShowData}
                />
            )}
            {starterModalLocalState.isOpen && (
                <Mortgage2StarterModal
                    withoutAuth
                    isNewVibe
                    isPreferentialNbCondition
                    showMoreBankIcons
                    isDefaultSelectedOffer
                    gtmSendId={DATA_GTM.MORTGAGE_RIGHT_BLOCK_STARTER_MODAL_SEND_ID}
                    gtmShowId={DATA_GTM.MORTGAGE_RIGHT_BLOCK_STARTER_MODAL_SHOW_ID}
                    locationPlacement='плавающая кнопка'
                    onClose={closeMortgageStarterModal}
                    applicationId={ApplicationIdEnum.NewBuildingsDesktop}
                    realtyType='ProfOfferFlat'
                    mortgageRegionId={newBuilding.mortgageParams.regionId}
                    mortgageOffers={offersFull}
                    term={newBuilding.mortgageParams.term}
                    ownAmount={newBuilding.mortgageParams.ownAmount}
                    propertyCost={newBuilding.mortgageParams.propertyCost}
                    purpose={purpose as UseBankMortgageFormProps['purpose']}
                    withoutShowContainer
                    // @ts-expect-error
                    gtmSendData={starterModalLocalState.gtmSendData}
                    // @ts-expect-error
                    gtmShowData={starterModalLocalState.gtmShowData}
                />
            )}
        </div>
    );
};

NewBuildingCard.displayName = 'NewBuildingCard';

export default NewBuildingCard;
