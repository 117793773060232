import React, { useMemo } from 'react';

import type { MetroStation } from '@search/vtbeco-frontend-core/domain/geo/MetroStation';
import type { GeoBaseStore } from '@search/vtbeco-frontend-core/domain/geo/GeoBase';

import { YMapPlacemarkSubwayPin } from '@search/ymap/src/Placemark/Subway/Pin';
import { YMapPlacemarkSubwayPimple } from '@search/ymap/src/Placemark/Subway/Pimple';
import type { YMapBounds } from '@search/ymap/src';

interface MetroStationPlacemark extends MetroStation {
    readonly coordinates: NonNullable<MetroStation['coordinates']>;
}

export const useSubwayOnMap = ({
    geoStore,
    regionId,
    bounds,
    isLoading
}: {
    geoStore?: GeoBaseStore;
    regionId: number;
    bounds: YMapBounds | undefined;
    isLoading?: boolean;
}) => {
    const visibleStations = useMemo(() => {
        if (! bounds || ! geoStore || isLoading) {
            return [];
        }

        return geoStore.stations.noInterchanged.filter(metro => {
            const {
                longitude: metroLongitude,
                latitude: metroLatitude
            } = metro.coordinates ?? {};

            if (! metroLatitude || ! metroLongitude) {
                return false;
            }

            return (
                metroLongitude > bounds.min.x && metroLongitude < bounds.max.x &&
                metroLatitude > bounds.min.y && metroLatitude < bounds.max.y
            );
        });
    }, [ bounds, geoStore, isLoading ]) as MetroStationPlacemark[];

    const subwayPinPlacemarks = useMemo(
        () => visibleStations.map(({ id, coordinates, title, allRoutesColorsList, route }) => (
            <YMapPlacemarkSubwayPin
                key={id}
                id={id}
                coordinates={{ y: coordinates.latitude, x: coordinates.longitude }}
                title={title}
                allRoutesColorsList={allRoutesColorsList}
                routeTitle={route.title}
                regionId={regionId}
            />
        )),
        [ visibleStations, regionId ]
    );

    const subwayPimplePlacemarks = useMemo(
        () => visibleStations.map(({ id, coordinates, allRoutesColorsList }) => (
            <YMapPlacemarkSubwayPimple
                key={id}
                coordinates={{ y: coordinates.latitude, x: coordinates.longitude }}
                color={allRoutesColorsList[0]}
                regionId={regionId}
            />
        )),
        [ visibleStations, regionId ]
    );

    return {
        subwayPinPlacemarks,
        subwayPimplePlacemarks
    };
};
