import React from 'react';

import { graphql, useGqlContext } from '@search/gql-client/src';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { UseAuthError } from '@search/auth/src/useAuth';

import {
    useUpdateFavoriteNewBuildingMutation$data as useUpdateFavoriteNewBuildingMutationResponse,
    useUpdateFavoriteNewBuildingMutation$variables as useUpdateFavoriteNewBuildingMutationVariables
} from './__generated__/useUpdateFavoriteNewBuildingMutation.graphql';

const USE_FAVORITES_MUTATION = graphql`
    mutation useUpdateFavoriteNewBuildingMutation($newBuildingId: ID!, $isFavorite: Boolean!) {
        editFavoriteNewBuildings(isFavorite: $isFavorite, newBuildingId: $newBuildingId) {
            firstError {
                __typename
            }
        }
    }
`;

export function useUpdateFavoriteNewBuilding() {
    const gql = useGqlContext();
    const { waitAuth } = useM2AuthContext();

    return React.useCallback((props: useUpdateFavoriteNewBuildingMutationVariables) => {
        return waitAuth(() => gql.execute<
            useUpdateFavoriteNewBuildingMutationVariables,
            useUpdateFavoriteNewBuildingMutationResponse
        >(USE_FAVORITES_MUTATION, {
            ...props
        })
            .then(response => {
                if (response.data?.editFavoriteNewBuildings.firstError?.__typename === 'AuthUserIdError') {
                    throw new UseAuthError();
                }

                return response;
            }), {
            modalTitle: 'Добавить в избранное',
            modalSubtitle: 'Чтобы добавить в избранное, войдите или зарегистрируйтесь.',
            continueAfterLogin: true
        });
    }, [ gql, waitAuth ]);
}
