import React, { MouseEventHandler, TouchEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Tooltip, useTooltip, Typography } from '@vtblife/uikit';
import { Color, Size, Spinner } from '@vtblife/uikit/legacy';

import type { Image as ImageGQL } from '@search/graphql-typings';

import classname from '@search/classname/src';

import ImageCore from '@search/vtbeco-frontend-core/view/common/components/Image';
import { BadgeBox } from '@search/vtbeco-frontend-core/view/common/components/BadgeBox';
import { GalleryBadge } from '@search/vtbeco-frontend-core/view/common/components/Badge/GalleryBadge';
import { DefaultBadge } from '@search/vtbeco-frontend-core/view/common/components/Badge/DefaultBadge';
import { useSwiper } from '@search/vtbeco-frontend-core/view/common/hooks/useSwiper';
import { NO_PHOTO_SRC, NO_PHOTO_SRC_SET } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { ImageLazySeo } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySeo';

import { makeYoutubeLink, makeYoutubeVideoThumbnail } from '../../libs/makeYoutubeLink';
import { SvgSpriteIcon } from '../SvgSpriteIcon';

import { produceNextIndex, useControl } from './useControl';
import GalleryPreviews from './GalleryPreviews';
import './styles.css';

export type GalleryImage = {
    title: string;
    additionalTitle?: string;
    galleryPhoto: ImageGQL['galleryPhoto'];
    galleryThumbnail: ImageGQL['galleryThumbnail'];
    mediumMobile?: ImageGQL['mediumMobile'];
    medium?: ImageGQL['medium'];
    type?: ImageGQL['type'];
    originPath?: ImageGQL['originPath'];
}

export type GalleryVideo = {
    title: string;
    additionalTitle?: string;
    videoId: string;
    galleryThumbnail: string;
    type: 'VIDEO';
}

export type GalleryElement = GalleryImage | GalleryVideo

type View = 'cover' | 'full'

interface IProps {
    view: View;
    hidePreviews?: boolean;
    elements: GalleryElement[];
    caption?: string;
    mapAnchor?: string;
    categoryBlock?: React.ReactNode;
    title?: string;
    specialEventTitle?: string;
    specialEventsAnchor?: string;
    isSoldOut?: boolean;
    isSoon?: boolean;
    index?: number;
    className?: string;
    topContent?: React.ReactNode;
    bottomContent?: React.ReactNode;
    onClickMain?: (elements: GalleryElement[], index: number) => void;
}

const cn = classname.bind(null, 'CarouselGallery');

const buildTopContent = ({
    title,
    additionalTitle,
    currentIndex,
    elementsLength
}: {
    title?: string;
    additionalTitle?: string;
    currentIndex: number;
    elementsLength: number;
}) => {
    const content = [];

    if (title) {
        content.push(
            <span
                key='title'
                className={cn('top-content-title')}
            >
                {title}
            </span>
        );
    }

    if (additionalTitle) {
        content.push(
            <span
                key='additional-title'
                className={cn('top-content-title-additional')}
            >
                {additionalTitle}
            </span>
        );
    }

    return (
        <div className={cn('top-content')}>
            <div
                key='counter'
                className={cn('top-content-counter')}
            >
                {currentIndex + 1} из {elementsLength}
            </div>
            {content}
        </div>
    );
};

const Arrow = ({ type, handleClick }: {
    type: 'prev' | 'next';
    handleClick: MouseEventHandler;
}) => (
    <div
        className={cn('nav-area', { type })}
        onClick={handleClick}
    >
        <button
            type='button'
            className={cn('btn', { type })}
        >
            <SvgSpriteIcon name='ArrowSlider' />
        </button>
    </div>
);

const VideoElement = ({
    isFull,
    isLoading,
    element,
    finishLoading
}: {
    isFull: boolean;
    isLoading: boolean;
    element: GalleryVideo;
    finishLoading: () => void;
}) => {
    const { videoId, title } = element;

    return (
        isFull ? (
            <iframe
                id='ytplayer'
                width='80%'
                height='100%'
                src={makeYoutubeLink(videoId)}
                onError={finishLoading}
                onLoad={finishLoading}
                className={cn('iframe', { invisible: isLoading })}
                frameBorder='0'
                allowFullScreen
            />
        ) : (
            <div className={cn('videoElement', { invisible: isLoading })}>
                <SvgSpriteIcon
                    name='Play'
                    width={64}
                    height={64}
                    color='#fff'
                    className={cn('videoIcon')}
                />
                <div className={cn('imgContainer')}>
                    <ImageCore
                        onError={finishLoading}
                        onLoad={finishLoading}
                        url={makeYoutubeVideoThumbnail(videoId)}
                        className={cn('hero-img', { video: true })}
                        alt={title}
                        title={title}
                        type={'videoCover'}
                    />
                </div>
            </div>
        )
    );
};

const ElementContainer = ({
    isFull,
    onImageClick,
    element,
    onTouchStart,
    onTouchEnd,
    onTouchMove
}: {
    isFull: boolean;
    onImageClick: () => void;
    element?: GalleryElement;
    onTouchStart?: (e: TouchEvent) => void;
    onTouchEnd?: () => void;
    onTouchMove?: (e: TouchEvent) => void;
}) => {
    const [ isLoading, setLoading ] = useState<boolean>(true);

    const finishLoading = useCallback(() => setLoading(false), []);

    useEffect(() => setLoading(true), [ element ]);

    if (! element) {
        return (<>
            <picture itemType='http://schema.org/ImageObject' className={cn('cap')}>
                <source
                    srcSet={NO_PHOTO_SRC_SET}
                />
                <img
                    className={cn('cap')}
                    crossOrigin='anonymous'
                    src={NO_PHOTO_SRC}
                    loading='lazy'
                />
            </picture>
        </>);
    }

    const { videoId } = element as GalleryVideo;

    return (
        <div
            onClick={onImageClick}
            className={cn('img-container')}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            itemScope
            itemType='http://schema.org/ImageObject'
        >
            {
                isLoading ? (
                    <div className={cn('spinner-container')}>
                        <Spinner
                            size={Size.Medium}
                            color={Color.Gray}
                            velocity='normal'
                        />
                    </div>
                ) : null
            }
            {
                videoId ? (
                    <VideoElement
                        isFull={isFull}
                        isLoading={isLoading}
                        element={element as GalleryVideo}
                        finishLoading={finishLoading}
                    />
                ) : (
                    <ImageLazySeo
                        onError={finishLoading}
                        onLoad={finishLoading}
                        originPath={(element as GalleryImage).originPath}
                        srcWidth={954}
                        srcHeight={533}
                        imgClassName={cn('hero-img', { invisible: isLoading })}
                        className={cn('img-container', { invisible: isLoading })}
                        alt={element.title}
                        title={element.title}
                        metaName={element.title}
                    />
                )
            }
        </div>
    );
};

const SpecialEventBadge = ({
    specialEventsAnchor,
    specialEventTitle
} : {
    specialEventsAnchor: string;
    specialEventTitle: string;
}) => {
    const { to, registerControl } = useTooltip();

    return (
        <>
            <a
                href={`#${specialEventsAnchor}`}
            >
                <GalleryBadge anchorRef={registerControl}>
                    <span dangerouslySetInnerHTML={{ __html: specialEventTitle }} />
                </GalleryBadge>
            </a>
            <Tooltip
                to={to}
                direction='down'
                placement='body'
            >
                Акции и скидки
            </Tooltip>
        </>
    );
};

const MapThumbnail = ({ mapAnchor }: { mapAnchor: string }) => {
    const {
        to,
        registerControl
    } = useTooltip();

    return (
        <>
            <a
                ref={registerControl}
                href={`#${mapAnchor}`}
                className={cn('mapThumbnail')}
            >
                <SvgSpriteIcon
                    name='MapPreviewDesk'
                    width={64}
                    height={64}
                />
            </a>
            <Tooltip
                to={to}
                direction='down'
            >
                Показать карту
            </Tooltip>
        </>
    );
};

const CarouselGalleryTopLayer = ({
    specialEventTitle,
    specialEventsAnchor,
    isSoldOut,
    isSoon,
    mapAnchor,
    caption,
    categoryBlock,
    noPhoto
} : {
    specialEventTitle: string;
    specialEventsAnchor: string;
    isSoldOut?: boolean;
    isSoon?: boolean;
    caption: string;
    categoryBlock: React.ReactNode;
    noPhoto: boolean;
    mapAnchor?: string;
}) => (
    <>
        <BadgeBox className={cn('badge-box')}>
            {
                // eslint-disable-next-line no-nested-ternary
                isSoldOut ? (
                    <DefaultBadge
                        variant='transparent'
                        size='m'
                    >
                        Всё продано
                    </DefaultBadge>
                // eslint-disable-next-line no-nested-ternary
                ) : isSoon ? (
                    <DefaultBadge
                        variant='blue'
                        size='m'
                    >
                        Скоро в продаже
                    </DefaultBadge>
                ) : specialEventTitle ? (
                    <SpecialEventBadge
                        specialEventsAnchor={specialEventsAnchor}
                        specialEventTitle={specialEventTitle}
                    />
                ) : null
            }
        </BadgeBox>
        {mapAnchor ? <MapThumbnail mapAnchor={mapAnchor} /> : null}
        <div className={cn('bottom-panel')}>
            <Typography
                variant='secondary-alone'
                color={noPhoto ? 'secondary' : 'white-500'}
                className={noPhoto ? undefined : cn('caption')}
            >
                {noPhoto ? 'Фотографий нет' : caption}
            </Typography>
            {categoryBlock}
        </div>
    </>
);

const CarouselGallery: React.FunctionComponent<IProps> = props => {
    const isFull = useMemo(() => props.view === 'full', [ props.view ]);
    const initialState = useMemo(() => ({
        isKeyNav: isFull,
        index: props.index ?? 0,
        lastIndex: props.elements.length - 1
    }), [ props.elements.length, props.index, isFull ]);

    const {
        currentIndex,
        handlePrevClick,
        handleNextClick,
        handleClickOnPreview
    } = useControl(initialState);

    const handleClickMain = useCallback(() => {
        props.onClickMain && props.onClickMain(props.elements, currentIndex);
    }, [ props, currentIndex ]);

    const isSwipeable = useMemo(() => props.elements.length > 1, [ props.elements.length ]);

    const {
        handleTouchEnd,
        handleTouchMove,
        handleTouchStart
    } = useSwiper({
        onSwipeLeft: handleNextClick,
        onSwipeRight: handlePrevClick
    });

    useEffect(() => {
        if (! isSwipeable) {
            return;
        }

        const elements = props.elements;
        const lastImgIndex = elements.length - 1;

        const prevImageIndex = produceNextIndex(lastImgIndex, currentIndex - 1);
        const nextImageIndex = produceNextIndex(lastImgIndex, currentIndex + 1);

        if (elements[prevImageIndex].type !== 'VIDEO') {
            const cacheImagePrev = new Image();

            cacheImagePrev.src = (elements[prevImageIndex] as GalleryImage).galleryPhoto;
        }

        if (elements[nextImageIndex].type !== 'VIDEO') {
            const cacheImageNext = new Image();

            cacheImageNext.src = (elements[nextImageIndex] as GalleryImage).galleryPhoto;
        }
    }, [ currentIndex, isSwipeable, props.elements ]);

    const currentElement = props.elements[currentIndex];

    return (
        <div className={cn(null, { view: props.view }, props.className)}>
            <div className={cn('layout')}>
                {
                    isFull ? (
                        buildTopContent({
                            currentIndex,
                            title: props.title,
                            additionalTitle: currentElement.additionalTitle,
                            elementsLength: props.elements.length
                        })
                    ) : null
                }
                <div className={cn('hero')}>
                    {isSwipeable && (
                        <Arrow
                            type='prev'
                            handleClick={handlePrevClick}
                        />
                    )}
                    <ElementContainer
                        onTouchEnd={handleTouchEnd}
                        onTouchMove={handleTouchMove}
                        onTouchStart={handleTouchStart}
                        element={currentElement}
                        onImageClick={handleClickMain}
                        isFull={isFull}
                    />
                    {isSwipeable && (
                        <Arrow
                            type='next'
                            handleClick={handleNextClick}
                        />
                    )}
                    {props.view === 'cover' && (
                        <CarouselGalleryTopLayer
                            specialEventTitle={props.specialEventTitle!}
                            specialEventsAnchor={props.specialEventsAnchor!}
                            isSoldOut={props.isSoldOut}
                            isSoon={props.isSoon}
                            mapAnchor={props.mapAnchor!}
                            caption={props.caption!}
                            noPhoto={props.elements.length === 0}
                            categoryBlock={props.categoryBlock}
                        />
                    )}
                </div>
                {props.bottomContent}
                {
                    props.hidePreviews ? null : (
                        <GalleryPreviews
                            elements={props.elements}
                            currentIndex={currentIndex}
                            onClickItem={handleClickOnPreview}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default CarouselGallery;
