import { useCallback, useState, useEffect } from 'react';

import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { graphql, useGqlContext } from '@search/gql-client/src';
import type { GqlClientOptions } from '@search/gql-client/src';
import { usePrevious } from '@search/hooks/src/usePrevious';
import { GeoBaseStore } from '@search/vtbeco-frontend-core/domain/geo/GeoBase';

// @ts-ignore
import type { useGeoStoreQuery$data as useGeoStoreQueryResponse, useGeoStoreQuery$variables as useGeoStoreQueryVariables } from './__generated__/useGeoStoreQuery.graphql';

function getGeoIds(districtId: number | readonly number[] | undefined) {
    let geoIds: number[] = [];

    if (districtId !== undefined) {
        if (typeof districtId === 'number') {
            geoIds.push(districtId);
        } else {
            geoIds = [ ...districtId ];
        }
    }

    return geoIds.length > 0 ? geoIds : null;
}

const GEO_QUERY = graphql`
    query useGeoStoreQuery(
        $regionId: Int!
        $geoIds: [Int!]
        $metroOnly: Boolean!
    ) {
        geo(regionId: $regionId, geoIds: $geoIds) {
            metro {
                id
                title
                kind
                lines
                interchangedStations
                allRoutesColorsList
                route {
                    id
                }
                coordinates {
                    latitude
                    longitude
                }
            }
            lines {
                id
                title
                kind
                color
            }
            districts @skip(if: $metroOnly) {
                parentId
                displayName
                title
                id
                kind
                isIntracity
                narrowRegion {
                    id
                }
            }
        }
    }
`;

export function useGeoStoreFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: useGeoStoreQueryVariables, opts?: GqlClientOptions) => gql.client<
            useGeoStoreQueryVariables,
            useGeoStoreQueryResponse
            >(GEO_QUERY, vars, opts).then(response => response),
        [ gql ]
    );
}

export const useGeoStore = ({
    regionId,
    districtId,
    isMetroOnly
}: {
    regionId: number;
    districtId?: number | readonly number[];
    isMetroOnly?: boolean;
}) => {
    const prevRegionId = usePrevious<number>(regionId);

    const [ queryVars, setQueryVars ] = useState<useGeoStoreQueryVariables>({
        regionId,
        geoIds: getGeoIds(districtId),
        metroOnly: Boolean(isMetroOnly)
    });

    const geoLoader = useGql2Loader(useGeoStoreFetcher(), {
        cachePrefix: 'newBuildingGeoData',
        tracingTag: 'newBuildingGeoData'
    });

    const { data: { geo } = {} } = geoLoader(queryVars);

    const initGeoStore = geo ? GeoBaseStore.fromGql(geo) : new GeoBaseStore();

    initGeoStore.districts.regionId = regionId;

    const [ geoStore, setGeoStore ] = useState<GeoBaseStore>(initGeoStore);

    useEffect(() => {
        if (regionId !== prevRegionId) {
            setQueryVars({
                regionId,
                geoIds: getGeoIds(districtId),
                metroOnly: Boolean(isMetroOnly)
            });
        }
    }, [ regionId, districtId, isMetroOnly, prevRegionId ]);

    useEffect(() => {
        if (geo) {
            const newGeoStore = GeoBaseStore.fromGql(geo);

            newGeoStore.districts.regionId = regionId;

            setGeoStore(newGeoStore);
        }
    }, [ geo, regionId ]);

    return geoStore;
};
