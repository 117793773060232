import React from 'react';

import { Typography } from '@vtblife/uikit';

import { InspectionStatusEnum } from '@search/graphql-gateway/typings';

import classname from '@search/classname/src';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import type { BuildingGroup } from '../types';

import './styles.css';

interface IProps {
    withoutTitle?: boolean;
    buildingGroups: BuildingGroup[];
    notAccreditedBuildingsTotal: number;
    hasFrozenBuildings: boolean;
}

const cn = classname.bind(null, 'NewBuildingCard');

export const NewBuildingCardCommissioningDates: React.FunctionComponent<IProps> = ({
    withoutTitle: hasNoTitle,
    buildingGroups,
    notAccreditedBuildingsTotal,
    hasFrozenBuildings
}) => (
    <div className={cn('commissioningDates')}>
        {
            hasNoTitle ? '' : (
                <Typography>
                    Сроки сдачи
                </Typography>
            )
        }
        {
            buildingGroups
                .filter(({ buildings }) => buildings.some(building => ! (
                    building.isFrozen || building.inspectionStatus?.value === InspectionStatusEnum.Failed
                )))
                .map(({ date, color }, i) => (
                    <div
                        key={i}
                        className={cn('commissioningDate')}
                    >
                        <div
                            className={cn('commissioningDateMarker')}
                            style={{ backgroundColor: color }}
                        />
                        <Typography tag='span'>
                            {date}
                        </Typography>
                    </div>
                ))
        }
        {(notAccreditedBuildingsTotal || hasFrozenBuildings) && (
            <div className={cn('commissioningDateFooter')}>
                {
                    notAccreditedBuildingsTotal ? (
                        <div className={cn('commissioningDate', { notAccredited: true })}>
                            <div className={cn('commissioningDateMarker')} />
                            <Typography tag='span'>
                                {
                                    notAccreditedBuildingsTotal === 1 ?
                                        'Не аккредитован' :
                                        'Не аккредитованы'
                                }
                            </Typography>
                        </div>
                    ) : null
                }
                {
                    hasFrozenBuildings ? (
                        <div className={cn('commissioningDate', { isFrozen: true })}>
                            <div className={cn('commissioningDateMarker')} />
                            <Typography tag='span'>
                                {'Приостановлено'}
                            </Typography>
                        </div>
                    ) : null
                }
            </div>
        )}
    </div>
);
