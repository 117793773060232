import React, { useContext } from 'react';

import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import type { LinksCloudViewType } from '../NewBuildingSearchLinkCloud';
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions';
import { NewBuildingCardQueryResponse } from '../NewBuildingCard';
import { GlobalContext, IGlobalContext } from '../GlobalContext';

const NewBuildingCardFAQ: React.FunctionComponent<{
    newBuilding: NewBuildingCardQueryResponse['newBuilding'];
    view: LinksCloudViewType;
}> = ({
    newBuilding,
    view,
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        searchIndex
    } = globalContext.instanceConfig;

    if (searchIndex !== NewBuildingWhitelabelEnum.Default) {
        return null;
    }

    const firstDeveloperCard = newBuilding.developerCards[0];
    let developerPostfix = '';

    if (firstDeveloperCard?.developer?.title) {
        developerPostfix = ` от застройщика ${firstDeveloperCard.developer.title}`;
    }

    const qa: {
        q: string;
        a: string;
    }[] = [ {
        q: `Как купить ${
            // eslint-disable-next-line no-nested-ternary
            newBuilding.hasApartmentsOnly ?
                'апартаменты' :
                newBuilding.hasFlats && newBuilding.hasApartments ?
                    'апартаменты или квартиру' :
                    'квартиру'
        } в ${
            newBuilding.title.simplified
        } на сайте m2.ru?`,
        a: `Для покупки ${
            // eslint-disable-next-line no-nested-ternary
            newBuilding.hasApartmentsOnly ?
                'апартаментов' :
                newBuilding.hasFlats && newBuilding.hasApartments ?
                    'апартаментов или квартиры' :
                    'квартиры'
        } в ${
            newBuilding.title.simplified
        }${
            developerPostfix
        } оставьте заявку на странице или позвоните застройщику по указанному номеру телефона.`
    } ];

    const a = [
        newBuilding.buildingClass?.label ? `${
            newBuilding.title.simplified
        }${
            developerPostfix
        } относится к классу ${
            newBuilding.buildingClass.label.toLocaleLowerCase()
        }.` : '',
        // @ts-ignore
        newBuilding.info?.flatsAndApartmentsCount?.value ? `Общее количество квартир в ЖК — ${
            // @ts-ignore
            newBuilding.info.flatsAndApartmentsCount.value
        }.` : '',
        newBuilding.pricePerM2Min ? `Минимальная цена за м² — ${
            formatLocaleNumber(newBuilding.pricePerM2Min)
        } рублей.` : ''
    ]
        .filter(Boolean)
        .join(' ');

    if (a) {
        qa.push({
            q: `Какие типы новостроек представлены у ${
                newBuilding.title.simplified
            }?`,
            a
        });
    }

    qa.push({
        q: 'Почему стоит купить жильё в новостройке у нас?',
        a: 'Все предложения о покупке квартир и апартаментов на m2.ru представлены только официальными застройщиками.'
    });

    return (
        <FrequentlyAskedQuestions
            qa={qa}
            view={view}
        />
    );
};

export default NewBuildingCardFAQ;
