import React from 'react';

import { Router } from '../Router';

export type RouterProviderProps<Context> = {
    children: React.ReactChild;
    router: Router<Context>;
};

export function createRouterProvider<Context>() {
    const RouterContext = React.createContext<{ router: Router<Context>; count: number } | undefined>(
        undefined
    );

    function RouterProvider({
        children,
        router
    }: RouterProviderProps<Context>) {
        const [ count, setCount ] = React.useState(0);

        router.refresh = () => setCount(count + 1);

        return (
            <RouterContext.Provider value={{ router, count }}>
                {children}
            </RouterContext.Provider>
        );
    }

    function useRouter() {
        const rec = React.useContext(RouterContext);

        if (! rec) {
            throw new Error('Wrap your application into <RouterProvider>');
        }
        return rec.router;
    }

    return { RouterProvider, useRouter };
}
