import { Dispatch, RefObject, SetStateAction, useEffect, useState } from 'react';

export default function useLinkToQuizModal(
    containerRef: RefObject<HTMLDivElement>,
    key: string,
    hasQuiz?: boolean
): [ boolean, Dispatch<SetStateAction<boolean>> ] {
    const [ isOpen, setIsOpen ] = useState(false);

    const bannerWrapperHeight = containerRef.current?.getBoundingClientRect().height ?? 0;
    const documentHeight = typeof document !== 'undefined' ? document.documentElement.clientHeight : 0;
    const maxProgressTop = documentHeight / 2 - bannerWrapperHeight / 2;
    const pixelsPerPercentage = (documentHeight - maxProgressTop) / 100;

    useEffect(() => {
        if (! hasQuiz) {
            return;
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    const isShown = Boolean(localStorage.getItem(key));

                    if (! isShown) {
                        setIsOpen(true);
                        localStorage.setItem(key, 'true');
                    }
                }
            });
        }, {
            threshold: [ 0.1 ]
        });

        if (containerRef.current) {
            observer.observe(containerRef.current as HTMLDivElement);
        }

        return () => {
            observer.disconnect();
        };
    }, [ documentHeight, pixelsPerPercentage, containerRef, hasQuiz, key ]);

    return [ isOpen, setIsOpen ];
}
