import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import classname from '@search/classname/src';
import { useYMapDrawArea } from '@search/ymap/src/DrawArea';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { ApplicationIdEnum } from '@search/graphql-typings';
import { OfferYMapDesktop } from '@search/vtbeco-frontend-core/domain/offer/ymap/Desktop/Desktop';
import { YMapPOI } from '@search/vtbeco-frontend-core/view/common/components/YMap/YMapPOI';
import { CustomerFeedback } from '@search/vtbeco-frontend-core/view/common/components/CustomerFeedback';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import type { Shared } from '../../../../types/shared';

import type { IGlobalContext } from '../../../common/components/GlobalContext';
import { GlobalContext } from '../../../common/components/GlobalContext';
import Page from '../../../common/components/Page';
import { useSearchMapPage } from '../../../common/components/NewBuildingSearchMapPage/useSearchMapPage';
import { useYMapUrl } from '../../../common/components/NewBuildingSearchMapPage/useYMapUrl';
import { useSearchFilters } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';
import { useMapSEOTexts } from '../../../common/components/NewBuildingSearchMapPageSeo';
import { useNewBuildingSearchMapPoi } from '../../../common/components/NewBuildingSearchMapPage/useNewBuildingSearchMapPoi';
import { useMapLoading } from '../../../common/hooks/useMapLoading';
import { useSubwayOnMap } from '../../../common/hooks/useSubwayOnMap';

import NewBuildingSearchMapSnippetPanel from '../NewBuildingSearchMapSnippetPanel';
import NewBuildingSearchMapFilters from '../NewBuildingSearchMapFilters';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchMapPage');

const NewBuildingSearchMapPage: React.FunctionComponent<Shared.NewBuildingSearchPage> = ({
    router,
    route,
    params
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        instanceConfig: { searchIndex },
        rootRegions
    } = globalContext;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ showInfrastructure, setShowInfrastructure ] = useState<boolean>(false);

    const {
        filters,
        updateFilter,
        updateFewFilters,
        handleSubmitFilters,
        resetFiltersExceptRegion: resetFilters,
        closeDrawAreaRef,
        customFilterBehavior,
        removeGeo
    } = useSearchFilters({
        route,
        pageParams: params,
        onMap: true
    });
    const {
        data,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        loading,
        geoStore,
        pageParams,
        bounds,
        setBounds,
        polygon,
        setPolygon,
        getPlacemarks,
        queryDisplayValues,
        addQueryDisplayValue,
        tips,
        selectedNewBuilding,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        selectedLoading,
        resetSelected,
        filterPanelState,
        clearCache,
        clearSelectedNewBuildingCache
    } = useSearchMapPage({
        route,
        params,
        whiteLabel: searchIndex,
        rootRegions,
        filters,
        updateFilter,
        limit: 30,
        resetFilters
    });

    const poiList = useNewBuildingSearchMapPoi({
        poiLimit: 30,
        bounds,
        skip: ! showInfrastructure
    });

    const isLoading = useMapLoading(pageParams, loading);

    const newBuildingPlacemarks = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return getPlacemarks();
    }, [ isLoading, getPlacemarks ]);

    const { subwayPimplePlacemarks, subwayPinPlacemarks } = useSubwayOnMap({
        geoStore,
        regionId: pageParams.region,
        bounds,
        isLoading
    });

    const { locative } = rootRegions.getById(Number(pageParams.region)) || {};
    const { title } = useMapSEOTexts({ locative });

    useEffect(() => {
        document.title = title;
    }, [ title ]);

    const { isDrawAreaVisible, toggleDrawArea, closeDrawArea } = useYMapDrawArea({
        handleDrawEnd: setPolygon,
        selectedArea: polygon
    });

    closeDrawAreaRef.current = closeDrawArea;

    const {
        mapUrl,
        listUrl,
        tileUrl,
        handleListClick
    } = useYMapUrl({
        params,
        router
    });

    const submitFilters = useCallback(() => {
        handleSubmitFilters();

        clearCache(true);
    }, [ handleSubmitFilters, clearCache ]);

    const tileLink = useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        return tileUrl;
    }, [ isLoading, tileUrl ]);

    const poiPlacemarks = poiList.length > 0 ? poiList.map(item => (
        <YMapPOI
            key={`${item.longitude}-${item.latitude}`}
            {...item}
        />
    )) : null;

    return (
        <Page
            className={cn()}
            contentClassName={cn('map')}
            wideHeader
            isFullWidth
            hasFooter={false}
            regionId={params.region}
        >
            <OfferYMapDesktop
                altLayout
                bounds={bounds}
                toggleDrawArea={toggleDrawArea}
                isDrawAreaVisible={isDrawAreaVisible}
                selectedArea={polygon}
                setBounds={setBounds}
                setPolygon={setPolygon}
                handleClickList={handleListClick}
                mapUrl={mapUrl}
                listUrl={listUrl}
                tileUrl={tileLink}
                hasSidebar={! filterPanelState.collapsed || selectedNewBuilding !== undefined}
                showInfrastructure={showInfrastructure}
                setShowInfrastructure={setShowInfrastructure}
                renderCustomerFeedback={
                    ! hasM2Pro ? (
                        <CustomerFeedback
                            applicationId={ApplicationIdEnum.NewBuildingsDesktop}
                            modalOpenEvent={NewBuildingAnalytics.CustomerFeedbackClick}
                        />
                    ) : undefined
                }
            >
                <NewBuildingSearchMapFilters
                    geoStore={geoStore}
                    filters={filters}
                    updateFilter={updateFilter}
                    updateFewFilters={updateFewFilters}
                    submitFilters={submitFilters}
                    resetFilters={filterPanelState.reset}
                    collapsed={filterPanelState.collapsed}
                    toggleCollapsed={filterPanelState.toggleCollapsed}
                    tips={tips}
                    removeTips={removeGeo}
                    queryDisplayValues={queryDisplayValues}
                    addQueryDisplayValue={addQueryDisplayValue}
                    customFilterBehavior={customFilterBehavior}
                    count={data?.newBuildingCount?.count ?? undefined}
                    isLoading={isLoading}
                    placeholders={data?.searchMapNewBuildings?.specProjectPlaceholders ?? undefined}
                />
                {
                    pageParams.selectedNewBuildingId ? (
                        <NewBuildingSearchMapSnippetPanel
                            newBuilding={selectedNewBuilding}
                            loading={selectedLoading}
                            onClose={resetSelected}
                            pageParams={pageParams}
                            clearSnippetCache={clearSelectedNewBuildingCache}
                        />
                    ) : null
                }
                {newBuildingPlacemarks}
                {showInfrastructure && poiPlacemarks}
                {subwayPimplePlacemarks}
                {subwayPinPlacemarks}
            </OfferYMapDesktop>
        </Page>
    );
};

NewBuildingSearchMapPage.displayName = 'NewBuildingSearchMapPage';

export default errorCatcherWrap(NewBuildingSearchMapPage);
