import React, { useRef } from 'react';

import classname from '@search/classname/src';

import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import { FilterCollapser } from '@search/vtbeco-frontend-core/domain/newbuilding/components/desktop/FilterCollapser';
import { Filters, IFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import {
    AddQueryDisplayValue,
    QueryDisplayValue,
    QueryDisplayValues
} from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { TipItem } from '@search/vtbeco-frontend-core/view/tips/OffersSearchTips';
import { DeveloperPromoPlaceholders } from '@search/graphql-typings/src';

import FilterPanel from '../../../common/components/FilterPanel';
import type { CustomFilterBehavior } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchMapFilters');

interface INewBuildingSearchMapFilters {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateFewFilters: (filter: IFilter[]) => void;
    submitFilters: () => void;
    resetFilters: () => void;
    tips: QueryDisplayValue[];
    removeTips: (tips: TipItem[]) => void;
    customFilterBehavior: CustomFilterBehavior;
    collapsed: boolean;
    toggleCollapsed: () => void;
    count?: number;
    queryDisplayValues?: QueryDisplayValues;
    addQueryDisplayValue?: AddQueryDisplayValue;
    isLoading?: boolean;
    placeholders?: DeveloperPromoPlaceholders;
}

const NewBuildingSearchMapFilters = ({
    geoStore,
    filters,
    updateFilter,
    updateFewFilters,
    submitFilters,
    resetFilters,
    tips,
    removeTips,
    queryDisplayValues,
    addQueryDisplayValue,
    customFilterBehavior,
    collapsed,
    toggleCollapsed,
    count,
    isLoading,
    placeholders
}: INewBuildingSearchMapFilters) => {
    const countRef = useRef<number | undefined>(undefined);

    if (count !== undefined) {
        countRef.current = count;
    }

    return (
        <div className={cn()}>
            <FilterCollapser
                activeCount={filters.getCountActiveFilters([ Filters.mapBounds, Filters.selectedNewBuilding ])}
                collapsed={collapsed}
                reset={resetFilters}
                toggle={toggleCollapsed}
            />
            {
                collapsed ? null : (
                    <FilterPanel
                        geoStore={geoStore}
                        filters={filters}
                        updateFilter={updateFilter}
                        updateFewFilters={updateFewFilters}
                        submitFilters={submitFilters}
                        tips={tips}
                        removeTips={removeTips}
                        queryDisplayValues={queryDisplayValues}
                        addQueryDisplayValue={addQueryDisplayValue}
                        customFilterBehavior={customFilterBehavior}
                        count={countRef.current}
                        isLoading={isLoading}
                        onMap
                        placeholders={placeholders}
                    />
                )
            }
        </div>
    );
};

export default React.memo(NewBuildingSearchMapFilters);
