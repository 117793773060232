export enum WallsType {
    UNKNOWN = 0,
    MONOLITH = 1,
    BRICK_MONOLITH = 2,
    BRICK = 3,
    PANEL = 4,
    BLOCK = 5,
    WOOD = 6,
}

export enum WallsTypeString {
    MONOLITH = 'MONOLITH',
    BRICK_MONOLITH = 'BRICK_MONOLITH',
    BRICK = 'BRICK',
    PANEL = 'PANEL',
    BLOCK = 'BLOCK',
    WOOD = 'WOOD',
}
