export declare type NoStrictEntityMods = Record<string, string | boolean | number | undefined | object>;

export default (name: string, elem?: string | null, mods?: NoStrictEntityMods, mix?: string) => {
    const baseName = elem ? `${name}__${elem}` : name;
    const buf = [ baseName ];

    mods && Object.keys(mods).forEach(modName => {
        const modVal = mods[modName];

        if (modVal !== false && typeof modVal !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            buf.push(`${baseName}_${modName}${modVal === true ? '' : `_${modVal}`}`);
        }
    });

    mix && buf.push(mix);

    return buf.join(' ');
};
