import React, { Fragment } from 'react';

import { LinkButton } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { ToneEnum, ViewEnum } from '@search/vtbeco-ui/src/types';
import Tooltip from '@search/vtbeco-ui/src/components/controls/Tooltip';

import { VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { formatRange } from '@search/helpers/src/formatNumber';

import Hr from '../../../../../../client/common/components/Hr';
import { SvgSpriteIcon } from '../../../../../../client/common/components/SvgSpriteIcon';
import { vtbMortageDisclaimer } from '../../../../../../dict/mortgage';
import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../../../../../../client/common/constants/mortgage';

import './styles.css';

export interface INewBuildingCardPrice {
    readonly hasApartments: boolean;
    readonly title?: string | null;
    readonly priceMin: number | null;
    readonly pricePerM2Min?: number | null;
    readonly from: string;
}

const cn = classname.bind(null, 'NewBuildingCardPrice');

const NewBuildingCardPrice: React.FunctionComponent<INewBuildingCardPrice> = props => {
    const priceContent = [];

    if (props.priceMin) {
        priceContent.push(
            <CustomTypography
                key='priceMin'
                level='h3'
            >
                {formatRange(props.priceMin, undefined, {
                    billionsAsString: true,
                    thousandsAsNumber: true
                })} ₽
            </CustomTypography>
        );
    }

    if (props.pricePerM2Min) {
        priceContent.push(
            <CustomTypography
                key='pricePerM2Min'
                level='primary'
                color='secondary'
            >
                {formatRange(props.pricePerM2Min, null, {
                    billionsAsString: true
                })} за м²
            </CustomTypography>
        );
    }

    if (! priceContent.length) {
        priceContent.push(
            <CustomTypography key='mortgage'>
                Купить в ипотеку
            </CustomTypography>
        );
    }

    return (
        <div className={cn()}>
            {
                props.title ? (
                    <div className={cn('section', { type: 'site-name' })}>
                        <CustomTypography
                            level='primary-alone'
                            bold
                        >
                            {props.title}
                        </CustomTypography>
                    </div>
                ) : (
                    <Fragment>
                        <div className={cn('section', { type: 'price' })}>
                            {priceContent}
                        </div>
                    </Fragment>
                )
            }
            <div className={cn('section', { type: 'mortgage' })}>
                <CustomTypography level='primary-alone'>
                    Ипотека от {props.hasApartments ? VTB_MORTGAGE_RATE_APARTS : VTB_MORTGAGE_RATE_FLATS} %
                    <Tooltip
                        hasTail
                        tone={ToneEnum.DARK}
                        view={ViewEnum.PRIMARY}
                        renderTrigger={({ triggerRef, handleShow, handleClose, isVisible }) =>
                            <span
                                style={{ color: isVisible ? 'black' : 'grey', cursor: 'pointer' }}
                                ref={triggerRef}
                                onMouseLeave={handleClose}
                                onMouseEnter={handleShow}
                            >
                                &nbsp;
                                <SvgSpriteIcon
                                    name='Info'
                                    width={14}
                                    height={14}
                                />
                            </span>
                        }
                        renderContent={() =>
                            (
                                <CustomTypography
                                    color='white-500'
                                    style={{ maxWidth: '300px' }}
                                    level='secondary-alone'
                                >
                                    {vtbMortageDisclaimer[props.hasApartments ? 'apartments' : 'flats']}
                                </CustomTypography>
                            )
                        }
                    />
                </CustomTypography>
            </div>
            <Hr />
            <LinkButton
                fullWidth
                variant='transparent'
                target='blank'
                href={`${VTB_IPOTEKA_LINK}?from=${props.from}`}
            >
                Оформить заявку
            </LinkButton>
        </div>
    );
};

export default React.memo(NewBuildingCardPrice);
