import React, { useContext, useMemo } from 'react';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings/src';

import { Shared } from '../../../../types/shared';

import { useSearchPage } from '../NewBuildingSearchPage/useSearchPage';
import {
    OnChangeGlobalContext,
    IOnChangeGlobalContext,
    GlobalContext,
    IGlobalContext
} from '../GlobalContext';
import { Meta } from '../Meta';

export const useMapSEOTexts = ({
    locative
}: {
    locative?: string;
}): {
    title: string;
    description: string;
} => {
    const { instanceConfig: { seoHost } } = useContext(GlobalContext);

    return useMemo(() => ({
        title: `Новостройки ${
            locative
        } на карте с ценами. Карта новостроек ${
            locative
        } от застройщика — ${
            seoHost
        }`,
        description: `🏢 Актуальные новостройки ${
            locative
        } на карте с ценами от застройщиков. Все жилые комплексы (ЖК) ${
            locative
        } на карте. 🔎 Удобный поиск. ✔️ Описание и фото.`
    }), [ locative, seoHost ]);
};

const NewBuildingSearchMapPageSeo = ({
    params
}: Shared.NewBuildingSearchMapPageProps) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        hasDeveloperPromo,
        searchIndex,
        hasFavoritesButton
    } = globalContext.instanceConfig;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);
    const showPromo = Boolean(hasDeveloperPromo) && ! hasM2Pro;

    const {
        pageParams
    } = useSearchPage({
        params,
        promo: showPromo,
        promoQuiz: searchIndex === NewBuildingWhitelabelEnum.Vtb || showPromo,
        whiteLabel: searchIndex,
        skipFavorites: ! hasFavoritesButton
    });

    const { locative } = globalContext.rootRegions.getById(Number(pageParams.region)) || {};

    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const canonicalUrl = onChangeGlobalContext
        .linkBuilder
        .searchMap(pageParams).split('?')[0];

    const { title, description } = useMapSEOTexts({ locative });

    return (
        <>
            <Meta
                title={title}
                description={description}
                canonical={canonicalUrl}
            />
        </>
    );
};

NewBuildingSearchMapPageSeo.displayName = 'NewBuildingSearchMapPageSeo';

export default NewBuildingSearchMapPageSeo;
