/* global ymaps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { useYMapPane } from '../Pane';
import { YMapControl } from './Control';
import {
    ControlAttributes,
    Vector,
    IYMapControlManager,
    YMapControlSize,
    defaultControlAttributes,
    defaultGroupAttributes,
    YMapControlManagerOptions,
    IMapControlManagerLayout,
    YMapControlHorizontalAlign,
    YMapControlVerticalAlign
} from './ControlManagerOptions';

export type YMapControlAttributesMap = Partial<
    Record<YMapControlSize, ControlAttributes>
> & { m: ControlAttributes }

export class YMapControlManager implements IYMapControlManager {
    readonly attributes: Readonly<ControlAttributes>
    readonly layout: IMapControlManagerLayout
    readonly mapSize: Vector
    readonly align: {
        x: YMapControlHorizontalAlign;
        y: YMapControlVerticalAlign;
    }
    readonly sizeProp: YMapControlManagerOptions['size']

    protected margin: Vector;

    constructor(
        {
            mapWidth,
            mapHeight,
            size = 'm',
            hAlign = 'left',
            vAlign = 'top',
            layout = 'vertical'
        }: YMapControlManagerOptions,
        defaultAttributesMap: YMapControlAttributesMap = defaultControlAttributes
    ) {
        this.align = { x: hAlign, y: vAlign };
        this.layout = layout;
        this.mapSize = { x: mapWidth, y: mapHeight };
        this.attributes = defaultAttributesMap[size] ?? defaultAttributesMap.m;
        this.margin = (defaultGroupAttributes[size] ?? defaultGroupAttributes.m).margin;
        this.sizeProp = size;
    }

    static use<Instance extends YMapControlManager>(
        this: new (
            options: YMapControlManagerOptions,
            defaultAttributesMap?: YMapControlAttributesMap
        ) => Instance,
        props: Omit<YMapControlManagerOptions, 'mapWidth' | 'mapHeight'>,
        defaultAttributesMap?: YMapControlAttributesMap
    ) {
        const { map } = useYMapPane();
        const [ mapWidth, mapHeight ] = map.container.getSize();
        const controlManager = React.useMemo(
            () =>
                new this(
                    { ...props, mapWidth, mapHeight },
                    defaultAttributesMap
                ),
            [ map ]
        );

        React.useEffect(
            () => controlManager.destructor.bind(controlManager),
            []
        );

        React.useEffect(
            () => controlManager.update({ ...props, mapWidth, mapHeight }),
            [
                props.hAlign,
                props.vAlign,
                props.layout,
                mapWidth,
                mapHeight,
                props.size
            ]
        );

        return controlManager;
    }

    protected controls: (YMapControl | undefined)[] = []

    add(control: YMapControl): () => void {
        const { layout, align, controls } = this;

        if (layout === 'horizontal' && align.x === 'right') controls.unshift(control);
        else if (layout === 'vertical' && align.y === 'bottom') controls.unshift(control);
        else controls.push(control);
        this.scheduleUpdate();
        return this.remove.bind(this, control);
    }

    protected remove(control: YMapControl) {
        this.controls = this.controls.filter(item => item !== control);
        this.scheduleUpdate();
    }

    protected scheduled = false
    protected scheduleUpdate() {
        if (this.scheduled) return;
        this.scheduled = true;
        // eslint-disable-next-line no-undef
        requestAnimationFrame(this.update.bind(this, undefined));
    }

    update(next?: YMapControlManagerOptions) {
        this.scheduled = false;
        if (next) {
            if (next.hAlign) this.align.x = next.hAlign;
            if (next.vAlign) this.align.y = next.vAlign;
        }
        this.updatePositions();
    }

    destructor() {
        const { controls } = this;

        for (const control of controls) if (control) control.destructor();
        this.controls = [];
    }

    protected get center(): Vector {
        const { mapSize, layout, controls } = this;

        const viewport: Vector = { x: 0, y: 0 };

        for (const control of controls) {
            if (! control) continue;
            const { size, margin } = control.attributes();

            if (layout === 'horizontal') viewport.x += margin.x + size.x;
            if (layout === 'vertical') viewport.y += margin.y + size.y;
        }

        const center: Vector = {
            x: Math.floor((mapSize.x - viewport.x) / 2),
            y: Math.floor((mapSize.y - viewport.y) / 2)
        };

        return center;
    }

    // eslint-disable-next-line complexity
    protected updatePositions() {
        if (this.controls.length === 0) return;
        const { layout, align, controls, center, sizeProp } = this;

        const current = { ...this.margin };

        // Avoid yandex logo
        if (layout === 'vertical' && align.y === 'bottom' && align.x === 'right') current.y = +30;
        if (
            layout === 'vertical' &&
            align.y === 'bottom' &&
            (align.x === 'right' || align.x === 'left') &&
            sizeProp === 'mChangedMargin'
        ) {
            current.y = +40;
        }

        for (const control of controls) {
            if (! control) continue;
            const { margin, size } = control.attributes();

            let left: number | undefined;
            let right: number | undefined;
            let top: number | undefined;
            let bottom: number | undefined;

            if (align.x === 'middle') left = current.x + center.x;
            if (align.x === 'left') left = current.x;
            if (align.x === 'right') right = current.x;

            if (align.y === 'middle') top = current.y + center.y;
            if (align.y === 'top') top = current.y;
            if (align.y === 'bottom') bottom = current.y;

            if (layout === 'horizontal') current.x += size.x + margin.x;
            if (layout === 'vertical') current.y += size.y + margin.y;

            const area = {
                width: size.x,
                height: size.y,
                left,
                right,
                top,
                bottom
            } as ymaps.IScreenArea;

            control.position(area);
        }
    }
}
