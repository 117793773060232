import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import type { NewBuildingPoint } from '@search/graphql-typings';
import { MapResultTypeEnum, NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import type { YMapVector } from '@search/common-core/ymap/Vector';
import type { Route } from '@search/router/src';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { convertParamsToGQLFilters } from '@search/nb-routes/src/NewBuildingSearchRoute';

import { yMapVectorFromLatLon } from '@search/ymap/src/Vector';
import type { YMapBounds } from '@search/ymap/src/bounds';
import { yMapBoundsFromVectors } from '@search/ymap/src/bounds';

import type { GqlClientOptions } from '@search/gql-client/src';
import { graphql, useGqlContext } from '@search/gql-client/src';
import type { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import type { IRegion, RootRegions } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/root-regions';
import type { GeoFilter, IFilter, RegionFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { Filters, MapBoundsFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { useGeoTips } from '@search/vtbeco-frontend-core/domain/geo/useGeoTips';
import { useFilterCollapser } from '@search/vtbeco-frontend-core/domain/newbuilding/components/desktop/FilterCollapser';
import { useGql2Loader } from '@search/gql-client/src/useGql2';

import type { Shared } from '../../../../types/shared';

import { useQueryDisplayValues } from '../../hooks/useQueryDisplayValues';
import { useGeoStore } from '../../hooks/useGeoStore';

import { useDrawAreaChip } from './useDrawAreaChip';
import type {
    NewBuildingsInput,
    useSearchMapPageQuery$data as useSearchMapPageQueryResponse,
    useSearchMapPageQuery$variables as useSearchMapPageQueryVariables
} from './__generated__/useSearchMapPageQuery.graphql';
import type {
    useSearchMapPageMapQuery$data as useSearchMapPageMapQueryResponse,
    useSearchMapPageMapQuery$variables as useSearchMapPageMapQueryVariables
} from './__generated__/useSearchMapPageMapQuery.graphql';
import { NewBuildingPlacemark } from './NewBuildingPlacemark';

const SNIPPET_PANEL_WIDTH = 360;

const MAP_QUERY = graphql`
    query useSearchMapPageMapQuery(
        $filters: NewBuildingsInput
        $skipDisplayValues: Boolean!
        $whiteLabel: NewBuildingWhitelabelEnum
        $resultTypes: [MapResultTypeEnum!]
        $limit: Int!
        $showSalesHints: Boolean
    ) {
        searchMapNewBuildings(
            filters: $filters
            whiteLabel: $whiteLabel
            resultTypes: $resultTypes
            limit: $limit
            showSalesHints: $showSalesHints
        ) {
            tips @skip(if: $skipDisplayValues) {
                developers {
                    id
                    type
                    title
                }
                newBuildings {
                    id
                    type
                    title
                }
                addresses {
                    id
                    title
                    type
                }
                metros {
                    __typename
                    id
                    title
                    allRoutesColors
                    type
                }
                districts {
                    id
                    title
                    type
                }
            }
            points {
                title {
                    nominative
                }
                priceMin
                ranking
                buildingStatusList {
                    title
                    isFrozen
                }
                gallery {
                    main {
                        placemarkHint
                    }
                }
                latitude
                longitude
                mangoColor {
                    default
                    active
                }
                routeParams {
                    region
                    regionId
                    subRegion
                    type
                    name
                    id
                }
            }
            taglessPoints {
                latitude
                longitude
            }
            specProjectPlaceholders {
                newBuilding
                developer
                developerName
            }
        }
        newBuildingCount(
            filters: $filters
            whiteLabel: $whiteLabel
        ) {
            count
        }
    }
`;

const SELECTED_PLACEMARK_QUERY = graphql`
    query useSearchMapPageQuery(
        $whiteLabel: NewBuildingWhitelabelEnum
        $paging: PagingInput!
        $skipSearchNewBuildings: Boolean!
        $filters: NewBuildingsInput
        $showDeveloperLinks: Boolean
    ) {
        searchNewBuildings(
            filters: $filters
            whiteLabel: $whiteLabel
            paging: $paging
        ) @skip(if: $skipSearchNewBuildings) {
            items {
                __typename
                ... on NewBuildingSnippet {
                    id
                    title {
                        nominative
                    }
                    priceMin
                    ranking
                    address
                    coordinates
                    mortgageMinPay {
                        minPay
                        variant
                        initialFee
                    }
                    buildingClass {
                        label
                    }
                    isSoldOut
                    isSoon
                    hasEscrow
                    buildingsAccreditedByVtb
                    buildingStatus {
                        title
                        isFrozen
                    }
                    gallery {
                        main {
                            newBuildingSnippet
                            originPath
                        }
                        images {
                            newBuildingSnippet
                            originPath
                        }
                    }
                    description
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                            timeMinutes
                            transportType
                        }
                    }
                    flats {
                        rooms
                        labelShort
                        label(type: SNIPPET)
                        isSoldOut
                        isSoon
                        isTemporaryNotOnSale
                        formatAreaRange
                        formatPriceRange
                        formatPriceRangeShort
                    }
                    developerCards {
                        developer {
                            id
                            title
                        }
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                    hasPreferentialMortgage
                    specialEvents {
                        type
                        title
                        description {
                            short
                        }
                    }
                    inspectionStatus(whiteLabel: $whiteLabel) {
                        value
                        statusText
                    }
                    isFavorite
                    m2Pro {
                        prepayment
                        cooperationTerms {
                            title
                            fileName
                            url
                        }
                        nbPresentation {
                            title
                            fileName
                            url
                        }
                    }
                    mortgageParams {
                        regionId
                        isMainRegion
                        propertyCost
                        purpose
                        ownAmount
                        term
                        purposeValue
                        claimType
                        ownAmount
                    }
                    isMskMO
                    isSPbLO
                    isSpecProjectRegion
                    developerLinks(isEnabled: $showDeveloperLinks)
                }
            }
        }
    }
`;

interface UseSearchMapPageProps {
    params: INewBuildingSearchType;
    whiteLabel: NewBuildingWhitelabelEnum;
    rootRegions: RootRegions;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    limit?: number;
    resetFilters?: () => void;
    route?: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    onNewBuildingSelect?: () => void;
    isMobile?: boolean;
}

function useNewBuildingSearchMapFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: useSearchMapPageMapQueryVariables, opts?: GqlClientOptions) => gql.client<
            useSearchMapPageMapQueryVariables,
            useSearchMapPageMapQueryResponse
            >(MAP_QUERY, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export function useSelectedPlacemarkFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: useSearchMapPageQueryVariables, opts?: GqlClientOptions) => gql.client<
            useSearchMapPageQueryVariables,
            useSearchMapPageQueryResponse
        >(SELECTED_PLACEMARK_QUERY, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export const useSearchMapPage = ({
    route,
    params: pageParams,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    rootRegions,
    filters,
    updateFilter,
    limit = 10,
    resetFilters,
    onNewBuildingSelect,
    isMobile = false
}: UseSearchMapPageProps) => {
    const skipDisplayValues = useRef(false);
    const placemarksRef = useRef<NewBuildingPoint[]>([]);

    const newBuildingsInput = useMemo(
        () => convertParamsToGQLFilters(pageParams) as NewBuildingsInput,
        [ pageParams ]
    );

    const nbSearchMapLoader = useGql2Loader(useNewBuildingSearchMapFetcher(), {
        cachePrefix: 'newBuildingSearchMap',
        tracingTag: 'useNewBuildingSearchMap'
    });

    const { selectedNewBuildingId, showHighlighted } = pageParams;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, loading, clearCache } = nbSearchMapLoader({
        whiteLabel,
        skipDisplayValues: skipDisplayValues.current,
        resultTypes: [ MapResultTypeEnum.NewBuildings, MapResultTypeEnum.Tagless ],
        filters: newBuildingsInput,
        limit,
        showSalesHints: Boolean(showHighlighted),
    });

    const selectedPlacemarkLoader = useGql2Loader(useSelectedPlacemarkFetcher(), {
        cachePrefix: 'nbSearchMapSelectedPlacemark',
        tracingTag: 'nbSearchMapSelectedPlacemark'
    });

    const {
        data: selectedNewBuildingData,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        loading: selectedLoading,
        clearCache: clearSelectedNewBuildingCache
    } = selectedPlacemarkLoader({
        paging: {
            pageNumber: 1,
            pageSize: 1
        },
        skipSearchNewBuildings: ! selectedNewBuildingId,
        filters: {
            regionId: pageParams.region,
            newBuildingId: selectedNewBuildingId,
            notOnlyM2Pro: pageParams.notOnlyM2Pro,
        },
        whiteLabel,
        showDeveloperLinks: Boolean(showHighlighted)
    });

    const { searchMapNewBuildings } = data ?? {};
    const tips = searchMapNewBuildings?.tips;
    const collection = searchMapNewBuildings?.points;
    const taglessCollection = searchMapNewBuildings?.taglessPoints;

    if (collection) {
        placemarksRef.current = collection as NewBuildingPoint[];
    }

    useEffect(() => {
        if (tips) {
            setTimeout(() => {
                skipDisplayValues.current = true;
            }, 100);
        }
    }, [ tips ]);

    const geoFilter = filters.get<GeoFilter>(Filters.geo);
    const regionFilter = filters.get<RegionFilter>(Filters.region);
    const region = rootRegions.getById(regionFilter.region) as IRegion;
    const mapBoundsFilter = filters.get<MapBoundsFilter>(Filters.mapBounds);
    const { polygon, bounds: boundsExternal } = mapBoundsFilter;

    const boundsInternal = useMemo<YMapBounds | undefined>(() => {
        if (! (collection?.length || taglessCollection?.length)) {
            return;
        }

        const points = [
            ...(collection || []).map(point => yMapVectorFromLatLon(point)),
            ...(taglessCollection || []).map(point => yMapVectorFromLatLon(point)),
            ...polygon
        ];

        return yMapBoundsFromVectors(points, 0.001, 3, 13);
    }, [ collection, taglessCollection, polygon ]);

    const boundsRef = React.useRef<YMapBounds | undefined>(region.bounds);

    if (boundsExternal || boundsInternal) {
        boundsRef.current = boundsExternal ?? boundsInternal;
    }

    const setBounds = useCallback((newBounds: YMapBounds) => updateFilter(
        new MapBoundsFilter(newBounds, polygon)
    ), [ updateFilter, polygon ]);

    const setPolygon = useCallback((newPolygon: YMapVector[]) => updateFilter(
        new MapBoundsFilter(
            newPolygon.length === 0 ? boundsRef.current : yMapBoundsFromVectors(newPolygon),
            newPolygon
        )
    ), [ updateFilter ]);

    const setSelected = useCallback((point: NewBuildingPoint, element?: HTMLElement) => {
        if (! route) {
            return;
        }

        const routeParams = route.params();
        const boundsRefValue = boundsRef.current;

        if (! boundsRefValue) {
            return;
        }

        const delta = {
            x: (boundsRefValue.max.x - boundsRefValue.min.x) / 2,
            y: (boundsRefValue.max.y - boundsRefValue.min.y) / 2
        };
        let nextBounds = routeParams.mapBounds?.bounds;

        // В таче сдвигаем карту вверх
        if (isMobile) {
            if (point.latitude < boundsRefValue.min.y + delta.y * 4 / 3) {
                nextBounds = {
                    min: {
                        x: point.longitude - delta.x,
                        y: point.latitude - delta.y - delta.y / 3
                    },
                    max: {
                        x: point.longitude + delta.x,
                        y: point.latitude + delta.y - delta.y / 3
                    }
                };
            }
        // Если выделенный пин попадает под панель, то сдвигаем карту вправо
        } else {
            const rect = element?.getBoundingClientRect();

            if (rect && rect.left < SNIPPET_PANEL_WIDTH) {
                nextBounds = {
                    min: {
                        x: point.longitude - delta.x,
                        y: point.latitude - delta.y
                    },
                    max: {
                        x: point.longitude + delta.x,
                        y: point.latitude + delta.y
                    }
                };
            }
        }

        route.push({
            ...routeParams,
            selectedNewBuildingId: point.routeParams.id,
            mapBounds: {
                ...routeParams.mapBounds,
                bounds: nextBounds
            }
        });
    }, [ route, boundsRef, isMobile ]);

    const resetSelected = useCallback(() => {
        if (! route) {
            return;
        }

        const routeParams = route.params();

        route.push({
            ...routeParams,
            selectedNewBuildingId: undefined
        });
    }, [ route ]);

    const filterPanelState = useFilterCollapser({
        reset: resetFilters,
        clearSelected: resetSelected,
        initCollapsed: Boolean(selectedNewBuildingId)
    });

    const getPlacemarks = useCallback(() => placemarksRef.current.map(item => (
        <NewBuildingPlacemark
            {...item}
            key={item.routeParams.id}
            onClick={(e: MouseEvent, element?: HTMLElement) => {
                onNewBuildingSelect && onNewBuildingSelect();
                setSelected(item, element);
                filterPanelState.close();
            }}
            isActive={selectedNewBuildingId === item.routeParams.id}
            pageParams={pageParams}
        />
    )),
    [ selectedNewBuildingId, pageParams, onNewBuildingSelect, setSelected, filterPanelState ]
    );

    const { drawAreaTitle, removeDrawArea } = useDrawAreaChip({
        filters,
        updateFilter
    });

    const [ queryDisplayValues, addQueryDisplayValue ] = useQueryDisplayValues(tips);

    const geoStore = useGeoStore({
        regionId: newBuildingsInput.regionId,
        districtId: pageParams.districtId
    });

    const geoTips = useGeoTips(queryDisplayValues, filters, geoStore);

    return {
        data,
        geoStore,
        geoFilter,
        pageParams,
        bounds: boundsRef.current,
        setBounds,
        polygon,
        setPolygon,
        getPlacemarks,
        drawAreaTitle,
        removeDrawArea,
        queryDisplayValues,
        addQueryDisplayValue,
        tips: geoTips,
        selectedNewBuilding: selectedNewBuildingData?.searchNewBuildings?.items[0] ?? undefined,
        selectedLoading,
        resetSelected,
        filterPanelState,
        loading,
        clearCache,
        clearSelectedNewBuildingCache,
    };
};
