import React from 'react';

import type { SEOTexts, SEOTextsEntry } from '@search/nb-texts/types';

const SEOTextsContext = React.createContext<{
    seoTexts: SEOTexts;
}>({
    seoTexts: {}
});

export const SEOTextsProvider = ({
    seoTexts,
    children
}: {
    seoTexts: SEOTexts;
    children: React.ReactNode;
}) => (
    <SEOTextsContext.Provider value={{
        seoTexts
    }}>
        {children}
    </SEOTextsContext.Provider>
);

export const useSEOTextsContext = () => React.useContext(SEOTextsContext);

export const useSEOTexts = (
    url: string
): {
    seoTexts?: SEOTextsEntry;
} => {
    const { seoTexts } = useSEOTextsContext();

    try {
        const { pathname, href, host } = new URL(url);

        return {
            seoTexts: seoTexts[pathname] ?? seoTexts[href] ?? seoTexts[`${host}${pathname}`]
        };
    } catch (e) {
        return {
            seoTexts: undefined
        };
    }
};
