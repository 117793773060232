import React from 'react';

import './styles.css';

export type IconSvgSize = keyof typeof sizes

export interface IconSvgProps {
    size?: IconSvgSize;
}

const sizes = {
    m: { x: 24, y: 24 },
    l: { x: 48, y: 48 },
    s: { x: 16, y: 16 }
};

const empty = {};

export const IconSvg = (path: string, { size = 'm' }: IconSvgProps = empty) => {
    const s = sizes[size];

    return (
        <svg
            viewBox={'0 0 24 24'}
            width={s.x}
            height={s.y}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d={path} />
        </svg>
    );
};

export const IconSvgStr = (path: string, { size = 'm' }: IconSvgProps = empty) => {
    const s = sizes[size];

    return `<svg
        viewBox="0 0 24 24"
        width="${s.x}"
        height="${s.y}"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="${path}"/>
    </svg>`;
};
export interface SvgComponent extends React.FC<IconSvgProps> {
    str: (props: IconSvgProps) => string;
}

export function createIcon(name: string, path: string) {
    const Component = IconSvg.bind(null, path) as SvgComponent;

    Component.displayName = name;
    Component.str = IconSvgStr.bind(null, path);
    return Component;
}
