import React from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardSectionHeading');

const NewBuildingCardSectionHeading: React.FunctionComponent<{
    title: string;
    postfix?: string;
    subtitle: string;
    seoTitle?: string | null;
    rightBlock?: React.ReactNode | null;
}> = ({
    title,
    postfix,
    subtitle,
    seoTitle,
    rightBlock
}) => (
    <Typography
        variant='h2'
        className={cn('title')}
    >
        {title}{postfix ? ` ${postfix}` : ''}
        <Typography
            variant='primary-alone'
            color='secondary'
            className={cn('subtitle')}
        >
            <span>
                {subtitle} {seoTitle}
            </span>
            {rightBlock}
        </Typography>
    </Typography>
);

export default NewBuildingCardSectionHeading;
