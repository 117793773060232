import React, { useCallback } from 'react';

import { Modal, Button, Typography } from '@vtblife/uikit';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import classname from '@search/classname/src';
import { SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'LinkToQuizModal');

const LinkToQuizModal = ({
    onClose,
    headText,
    text,
    isMobile,
    pageType
}: {
    onClose: () => void;
    headText: string;
    text: string;
    isMobile?: boolean;
    pageType: 'snippet' | 'card';
}) => {
    const onClick = useCallback(() => {
        dataLayerPush({
            event: 'nb_evt_banner',
            theme: 'pop_up_quize',
            page_type: pageType,
            type: 'go_test'
        });

        window.open('/novostroyki/quiz/', '_blank');
    }, [ pageType ]);

    return (
        <Modal
            opened
            size='s'
            onClose={onClose}
        >
            {! isMobile && (
                <div className={cn('img')}>
                    <SvgSpriteIcon
                        name='LinkToQuiz'
                        width={384}
                        height={160}
                    />
                </div>
            )}
            <Typography variant='h3' className={cn('head')}>
                {headText}
            </Typography>
            <Typography variant='primary' className={cn('text')}>
                {text}
            </Typography>
            <Button
                fullWidth
                variant='primary'
                onClick={onClick}
            >
                Начать тест
            </Button>
        </Modal>
    );
};

export default LinkToQuizModal;
