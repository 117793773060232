import React, { useContext } from 'react';
import { ApplicationIdEnum } from '@search/graphql-typings';

export interface IApplicationState {
    applicationId: ApplicationIdEnum;
}

type Props = {
    children: React.ReactNode;
    applicationId: ApplicationIdEnum;
};

const ApplicationContext = React.createContext<IApplicationState | undefined>(undefined);

export const ApplicationProvider: React.FC<Props> = ({ applicationId, children }: {
    applicationId: ApplicationIdEnum;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactNode;
}) => {
    return <ApplicationContext.Provider value={applicationId}>{children}</ApplicationContext.Provider>;
};

export const useApplicationContext = (): ApplicationIdEnum | undefined => {
    return useContext(ApplicationContext);
};
