/* eslint-disable react-hooks/rules-of-hooks */

import React from 'react';
import type ymaps from '@search/ymap/src/ApiProvider/yandex-maps';
import classname from '@search/classname/src';
import { useYMapApi } from '../ApiProvider';
import {
    yMapBoundsFromArray,
    YMapBoundsZoom,
    YMapBounds,
    yMapBoundsIsEqual,
    yMapBoundsRound,
    yMapCenterIsEqual,
    YMapMargin,
    yMapMarginToTuple
} from '../bounds';
import type { YMapVector } from '../Vector';

import './styles.css';

const cnMap = classname.bind(null, 'YMapPane');

export interface YMapPaneProps {
    /**
     * Показывать это во время загрузки и инициализации карты
     */
    loadingMessage?: React.ReactNode;

    zoom?: number;

    /**
     * Геокоординаты центра карты. Должны быть заданы совместно со state.zoom.
     */
    center?: readonly [number, number];

    /**
     * Максимальный коэффициент масштабирования карты.
     */
    maxZoom?: number;

    /**
     * Минимальный коэффициент масштабирования карты.
     */
    minZoom?: number;

    /**
     * Отступы от краёв карты.
     */
    margin?: YMapMargin | YMapVector | number;

    /**
     * Автоматическое слежение за контейнером карты.
     */
    autoFitToViewport?: 'none' | 'ifNull' | 'always';

    /**
     * true - карта не будет останавливаться на дробных значениях коэффициента масштабирования, false - будет.
     */
    avoidFractionalZoom?: boolean;

    noZoom?: boolean;

    children: React.ReactNode;

    setBounds?: (bounds: YMapBoundsZoom) => void;

    bounds?: YMapBounds;

    withSetBoundsOnInitialize?: boolean;

    isScrollZoomEnabled?: boolean;

    dataTest?: string;

    height?: number;
    width?: number;

    onFullScreen?: (isFullScreen?: boolean) => void;

    onMouseDown?: () => void;

    onMouseUp?: () => void;
}

export type YMapPaneBehaviours =
    'default' |
    'drag' |
    'scrollZoom' |
    'dblClickZoom' |
    'multiTouch' |
    'rightMouseButtonMagnifier' |
    'leftMouseButtonMagnifier' |
    'ruler' |
    'routeEditor'

export function YMapPane({
    zoom = 10,
    center = [ 55.75322, 37.62251 ],
    margin,
    // со значением 'always' карта в сафари начинает бесконечно добавлять высоту (RS-3071)
    autoFitToViewport = 'ifNull',
    minZoom,
    maxZoom,
    avoidFractionalZoom = false,
    noZoom = false,
    children,
    loadingMessage,
    setBounds,
    bounds,
    withSetBoundsOnInitialize,
    isScrollZoomEnabled,
    dataTest = 'ymap-pane',
    width,
    height,
    onFullScreen,
    onMouseUp,
    onMouseDown
}: YMapPaneProps) {
    let ymapsApi: typeof ymaps | undefined;

    try {
        ymapsApi = useYMapApi().api();
    } catch (error) {
        return (<div style={{
            top: 50,
            position: 'relative'
        }}>
            Невозможно загрузить Яндекс карты - попробуйте обновить страницу.
        </div>);
    }

    const boundsInternal = React.useRef(bounds);
    const centerInternal = React.useRef(center);

    const [ node, setNode ] = React.useState<HTMLDivElement>();
    const mapInstance: YMapPaneAttached | undefined = React.useMemo(() => {
        if (! ymapsApi || ! node) return;

        const options = {
            margin: margin ? yMapMarginToTuple(margin) : undefined,
            controls: [],
            center: bounds ? undefined : center as unknown as number[],
            zoom: bounds ? undefined : zoom,
            bounds: bounds ? [
                [ bounds.min.y, bounds.min.x ],
                [ bounds.max.y, bounds.max.x ]
            ] : undefined
        };

        const map = new ymapsApi.Map(
            node,
            options,
            {
                autoFitToViewport,
                minZoom,
                maxZoom,
                avoidFractionalZoom,
                // maxAnimationZoomDifference: 1,
                suppressMapOpenBlock: true,
                yandexMapDisablePoiInteractivity: true
            }
        );

        return {
            api: ymapsApi,
            map
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        node,
        ymapsApi,
        autoFitToViewport,
        minZoom,
        maxZoom,
        avoidFractionalZoom
    ]);

    React.useEffect(() => {
        return () => {
            if (mapInstance) mapInstance.map.destroy();
        };
    }, [ mapInstance ]);

    React.useEffect(() => {
        if (! mapInstance || ! margin) return;

        const { map } = mapInstance;

        map.margin.setDefaultMargin(yMapMarginToTuple(margin));

        if (onMouseUp && onMouseDown) {
            mapInstance.map.events.add([ 'mousedown', 'touchstart' ], onMouseDown);
            mapInstance.map.events.add([ 'mouseup', 'mouseleave', 'touchend' ], onMouseUp);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mapInstance, JSON.stringify(margin) ]);

    const setBoundsCalled = React.useRef(false);

    React.useEffect(() => {
        if (! mapInstance) return;
        const { map } = mapInstance;

        const onBoundsChange = (event: ymaps.IEvent | object) => {
            if (! setBounds) return;

            if (setBoundsCalled.current) {
                setBoundsCalled.current = false;
                return;
            }

            const newCenterRaw = (event as ymaps.IEvent).get('newCenter') as number[];
            const newCenter = { x: newCenterRaw[1], y: newCenterRaw[0] };
            const newZoom = (event as ymaps.IEvent).get('newZoom') as unknown as number;

            const newBounds = yMapBoundsRound(yMapBoundsFromArray(map.getBounds({
                useMapMargin: true
            })));

            if (yMapBoundsIsEqual(newBounds, boundsInternal.current)) return;
            boundsInternal.current = newBounds;

            setBounds({
                ...newBounds,
                center: newCenter,
                zoom: newZoom
            });
        };

        map.events.add('boundschange', onBoundsChange);

        return () => {
            map.events.remove('boundschange', onBoundsChange);
        };
    }, [ mapInstance, setBounds ]);

    React.useEffect(() => {
        if (! mapInstance) return;
        const { map } = mapInstance;

        if (noZoom) map.behaviors.disable('scrollZoom');
        else map.behaviors.enable('scrollZoom');
    }, [ noZoom, mapInstance ]);

    React.useEffect(() => {
        if (withSetBoundsOnInitialize && mapInstance && setBounds) {
            const { map } = mapInstance;
            const newBounds = yMapBoundsRound(yMapBoundsFromArray(map.getBounds({
                useMapMargin: true
            })));
            const [ y, x ] = center;

            setBounds({
                ...newBounds,
                zoom,
                center: { x, y }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ withSetBoundsOnInitialize, mapInstance, setBounds, center[0], center[1], zoom ]);

    React.useEffect(() => {
        if (! mapInstance) return;
        const map = mapInstance.map;

        if (yMapCenterIsEqual(center, centerInternal.current)) return;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        map.setCenter(center as unknown as number[]);
        centerInternal.current = center;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mapInstance, center[0], center[1] ]);

    React.useEffect(() => {
        if (! mapInstance) return;
        const map = mapInstance.map;

        if (! bounds && zoom) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            map.setZoom(zoom);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ zoom, mapInstance ]);

    React.useEffect(() => {
        if (! mapInstance) return;

        if (bounds) {
            if (yMapBoundsIsEqual(bounds, boundsInternal.current)) return;
            boundsInternal.current = bounds;
            const { min, max } = bounds;

            if (setBoundsCalled.current) return;
            setBoundsCalled.current = true;

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            mapInstance.map.setBounds([
                [ min.y, min.x ],
                [ max.y, max.x ]
            ], {
                preciseZoom: true,
                useMapMargin: true
            });

            return;
        }
    }, [ mapInstance, bounds ]);

    const isFullscreen = mapInstance?.map.container.isFullscreen();

    React.useEffect(() => {
        if (! mapInstance) return;

        const { map } = mapInstance;

        if (isFullscreen || isScrollZoomEnabled) {
            map.behaviors.enable('scrollZoom');
        } else {
            map.behaviors.disable('scrollZoom');
        }
    }, [ isFullscreen, isScrollZoomEnabled, mapInstance ]);

    React.useEffect(() => {
        onFullScreen && onFullScreen(isFullscreen);
    }, [ isFullscreen, onFullScreen ]);

    React.useEffect(() => {
        if (! mapInstance) return;

        const { map } = mapInstance;

        map.container.fitToViewport();
    }, [ width, height, mapInstance ]);

    return (
        <div ref={setNode as (el: HTMLDivElement) => void} data-test={dataTest} className={cnMap()}>
            {mapInstance ? (
                <YMapPaneContext.Provider value={mapInstance}>
                    {children}
                </YMapPaneContext.Provider>
            ) : (
                loadingMessage ?? null
            )}
        </div>
    );
}

export interface YMapPaneAttached {
    map: ymaps.Map;
    api: typeof ymaps;
}

const YMapPaneContext = React.createContext<YMapPaneAttached | undefined>(
    undefined
);

export function useYMapPane(): YMapPaneAttached {
    const instance = React.useContext(YMapPaneContext);

    if (! instance) throw new Error('Wrap in <YMapPane>...</YMapPane>');
    return instance;
}
