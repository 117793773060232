/* eslint-disable @typescript-eslint/naming-convention */
/* global window */
import { ErrorNotFound } from '@search/error/src/BadRequest';
import { IRouter, Route, RouteConstructor, RoutePushOptions } from './Route';
import { HistoryLike, LocationLike } from './RouterInterfaces';
import { failHidden, SchemaTypeError, SchemaVariantError } from './schema';

export interface LocationStoreOptions {
    location: LocationLike;
    refresh?: () => void;
    history?: HistoryLike;
    target?: Window;
}

export interface RouterOptions<Context> extends LocationStoreOptions {
    context: Context;
}

export type RouteMatch<Output, Context, Defaults> = {
    route: Route<Output, Context, Defaults>;
    RouteClass: RouteConstructor<Output, Context>;
    params: Output;
};

type RouteBase = { params: () => unknown; paramsSafe: () => unknown };

export class Router<Context = unknown> implements IRouter<Context> {
    location: LocationLike;
    refresh: undefined | (() => void);
    protected history?: HistoryLike;
    protected target?: Window;

    readonly context: Context;

    constructor({
        location,
        refresh,
        history,
        target,
        context
    }: RouterOptions<Context>) {
        this.location = location;
        this.refresh = refresh;
        this.history = history;
        this.target = target;
        this.context = context;
        if (target) {
            target.addEventListener('popstate', this.onPopState);
        }
    }

    toString() {
        return `${this.constructor.name} [ ${this.currentUrl} ]`;
    }

    destructor() {
        if (this.target) {
            this.target.removeEventListener('popstate', this.onPopState);
        }
    }

    private onPopState = () => {
        if (! this.target) return;
        //@ts-expect-error
        this.setLocation(this.target.location);
    };

    private routes = new Map<
        RouteConstructor<unknown, Context>,
        unknown
    >();

    route<Route extends RouteBase>(RouteClass: RouteConstructor<Route, Context>) {
        let route = this.routes.get(RouteClass) as Route;

        if (route) return route;

        route = new RouteClass(this);
        this.routes.set(RouteClass, route);

        return route;
    }

    params<Route extends RouteBase>(RouteClass: RouteConstructor<Route, Context>) {
        return this.route<Route>(RouteClass).params() as ReturnType<Route['params']>;
    }

    paramsSafe<Route extends RouteBase>(
        RouteClass: RouteConstructor<Route, Context>
    ) {
        const route = this.route(RouteClass);

        return route.paramsSafe() as ReturnType<Route['paramsSafe']>;
    }

    /**
     * @throws SchemaVariantError if 404
     * @throws Promise for React.Suspense
     */
    resolve(
        routeClasses: readonly RouteConstructor<RouteBase, Context>[]
    ): RouteMatch<RouteBase, Context, unknown> {
        const errorsSyntax: SchemaTypeError[] = [];
        const errorsNotFound: ErrorNotFound[] = [];

        for (const RouteClass of routeClasses) {
            const route = this.route(RouteClass);

            try {
                return {
                    route,
                    RouteClass,
                    params: route.params()
                } as RouteMatch<RouteBase, Context, unknown>;
            } catch (error) {
                if (error instanceof Error) {
                    error.message = `${route}, url "${this.currentUrl}": ${error.message}`;
                }

                // @ts-expect-error
                if (SchemaTypeError.is(error)) {
                    errorsSyntax.push(error);
                    // @ts-expect-error
                } else if (ErrorNotFound.is(error)) {
                    errorsNotFound.push(error);
                } else {
                    // @ts-expect-error
                    return failHidden(error);
                }
            }
        }

        if (errorsNotFound.length > 0) {
            throw errorsNotFound[0];
        }

        throw new SchemaVariantError(errorsSyntax);
    }

    protected keyCached = '';

    protected setLocation(location: LocationLike) {
        const key = Router.key(location);

        if (key === this.keyCached) return;

        this.keyCached = key;
        this.location = location;

        if (this.refresh) this.refresh();
    }

    get currentUrl(): string {
        return this.location.origin + this.location.pathname + this.location.search;
    }

    update(nextUrl: string = this.currentUrl, options?: RoutePushOptions) {
        if (this.history) {
            if (
                (! nextUrl.startsWith('https://') && ! nextUrl.startsWith('http://')) ||
                nextUrl.startsWith(this.location.origin)
            ) {
                if (options?.replace) this.history.replaceState(this.id, '', nextUrl);
                else this.history.pushState(this.createId(), '', nextUrl);
            } else {
                this.location.assign?.(nextUrl);
                return;
            }
        }
        // @ts-expect-error
        if (! options?.noRefresh && this.refresh && this.target) this.setLocation(this.target.location);
    }

    protected createId() {
        return String(new Date().getTime()) + '-' + Math.floor(Math.random() * 1e8);
    }

    get id() {
        return this.history?.state as undefined | string ?? 'router.id';
    }

    selector<State>(id: string) {
        return new HistoryStateSelector<State>(id, this);
    }

    static key(location: LocationLike) {
        return location.href;
    }
}

class HistoryStateSelector<State> {
    constructor(protected key: string, protected router: { id: string }) {
        this.key = key;
        this.router = router;
    }

    static state = new Map<string, Map<string, unknown>>();

    get stateMap() {
        const id = this.router.id;
        const state = HistoryStateSelector.state;

        let child = state.get(id);

        if (child === undefined) {
            child = new Map();
            state.set(id, child);
        }

        return child;
    }

    get() {
        return this.stateMap.get(this.key) as State | undefined;
    }

    clear() {
        this.stateMap.delete(this.key);
    }

    set(item: State) {
        if (typeof window === 'undefined') return;

        this.stateMap.set(this.key, item);
    }
}
