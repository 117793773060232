import React from 'react';

import { formatLocaleNumber } from '@search/helpers/src/formatNumber';

import {
    minimalBanksMortgageParamsFromOffer,
    useMinimalBanksMortgageOverall
} from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/useMinimalBanksMortageState';
import type { ArrayElement } from '@search/vtbeco-frontend-core/types';

import type { NewBuildingCardQuery$data as NewBuildingCardQueryResponse } from '../NewBuildingCard/__generated__/NewBuildingCardQuery.graphql';

type NewBuilding = NewBuildingCardQueryResponse['newBuilding'];

type Transport = NonNullable<NonNullable<ArrayElement<NewBuilding['routes']>>['transportType']>;

const transportTypeLabel: Record<Transport, string | null> = {
    UNKNOWN: null,
    PUBLIC_TRANSPORT: 'на транспорте',
    FOOT: 'пешком'
};

interface INewBuildingStatsProps {
    buildingClass: NewBuilding['buildingClass'];
    pricePerM2Min: NewBuilding['pricePerM2Min'];
    metro: ArrayElement<NewBuilding['routes']>;
    mortgageParams: NewBuilding['mortgageParams'];
    buildingStatus: NewBuilding['buildingStatus'];
}

const NewBuildingStats: React.FunctionComponent<INewBuildingStatsProps> = ({
    buildingClass,
    pricePerM2Min,
    metro,
    mortgageParams,
    buildingStatus
}) => {
    const {
        allMinimalPaymentFormatted
    } = useMinimalBanksMortgageOverall(
        minimalBanksMortgageParamsFromOffer({
            ...mortgageParams,
            mortgageRegionId: mortgageParams.regionId,
            isNewBuilding: true
        })
    );

    return (
        <>
            {buildingClass ? (
                <p>
                    {`Класс ЖК: ${buildingClass.label.toLocaleLowerCase()}`}
                </p>
            ) : null}
            {pricePerM2Min ? (
                <p>
                    {`Минимальная цена за м²: ${
                        formatLocaleNumber(pricePerM2Min)
                    } ₽`}
                </p>
            ) : null}
            {allMinimalPaymentFormatted ? (
                <p>
                    {`Ипотека: от ${
                        allMinimalPaymentFormatted
                    }`}
                </p>
            ) : null}
            {metro?.station && metro?.transportType && metro.transportType !== 'UNKNOWN' ? (
                <p>
                    {`Время до метро: ${
                        formatLocaleNumber(metro.timeMinutes)
                    } мин. ${
                        transportTypeLabel[metro.transportType]
                    } (${
                        metro.station.name
                    })`}
                </p>
            ) : null}
            {buildingStatus?.length ? (
                <p>
                    {`Срок сдачи: ${
                        buildingStatus
                            .map(({ title }) => title.toLowerCase())
                            .join(', ')
                    }`}
                </p>
            ) : null}
        </>
    );
};

export default NewBuildingStats;
