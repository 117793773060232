/*eslint-env browser*/
import React, { useEffect } from 'react';
import { EventBus } from '@vtblife/event-bus';
import { useHistory, StaticRouter } from 'react-router-dom';

const MicroBundle = React.lazy(() => import('@vtblife/micro-frontend-core').then(d => ({ default: d.MicroBundle })));

function AuthDialogMain() {
    const host = window.location.host;
    const isProduction = host === 'm2.ru';
    const history = useHistory();
    const path = isProduction ?
        'https://cdn.m2.ru/assets/auth' :
        'https://cdn.m2.ru/assets/auth/test';

    return (
        <React.Suspense fallback={<></>}>
            <MicroBundle
                name='auth'
                history={history}
                path={path}
            />
        </React.Suspense>
    );
}

export function AuthDialog() {
    useEffect(() => {
        const eventBus = EventBus.getInstance();
        const event = {
            type: 'app:contextChanged',
            data: {
                config: { features: [ { name: 'auth-is-email-verification-required' } ] }
            },
            category: 'behavior'
        } as const;

        // TODO: need to write typings
        // @ts-ignore
        eventBus.publish(event);
    }, []);

    return (
        <StaticRouter>
            <AuthDialogMain />
        </StaticRouter>
    );
}
