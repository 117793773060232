export enum GeoKind {
    UNKNOWN = 0,
    HOUSE = 1,
    STREET = 2,
    METRO = 3,
    DISTRICT = 4,
    LOCALITY = 5,
    AREA = 6,
    PROVINCE = 7,
    COUNTRY = 8,
    HYDRO = 9,
    VEGETATION = 10,
    AIRPORT = 11,
    OTHER = 12,
    RAILWAY_STATION = 13,
    ROUTE = 14,
    STATION = 17,
    ENTRANCE = 19,
    COMPOSITE_OBJECT = 18,
    STREET_ADDRESS = 15,
    APARTMENT = 16,
}
