import * as React from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import { DeveloperSummaryIconNameType, SvgSpriteIcon } from '../../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardDeveloperSummaryItem');

const NewBuildingCardDeveloperSummaryItem = ({ iconName, label, value, className }: {
    iconName: DeveloperSummaryIconNameType;
    label: string;
    value: string;
    className?: string;
}) => (
    <div className={cn('summary-item', undefined, className)}>
        <div className={cn('summary-item-icon')}>
            <SvgSpriteIcon name={iconName} />
        </div>
        <div>
            <Typography
                variant='secondary-alone'
                color='secondary'
            >
                {label}
            </Typography>
            <Typography>
                {value}
            </Typography>
        </div>
    </div>
);

export default NewBuildingCardDeveloperSummaryItem;
