import React from 'react';
import { Tooltip, Dropdown, Button, ButtonProps, useDropdownHover, useTooltip, Link, Typography } from '@vtblife/uikit';
import Icon, { IconSmallSize } from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingFavoritesButton');

export type FavoritesButtonPropsType = ButtonProps & {
    isFavorite?: boolean;
    inCircle?: boolean;
    className?: string;
    withLink?: boolean;
}

const FavoritesButton = ({
    isFavorite,
    size,
    onClick,
    className,
    inCircle,
    disabled,
    withLink
}: FavoritesButtonPropsType) => {
    const { to, registerControl } = useTooltip();
    const tooltipText = isFavorite ? 'В\u00A0избранном' : 'В\u00A0избранное';
    const { control } = useDropdownHover(isFavorite ? { close: 400 } : undefined);
    const ref = withLink ? control : registerControl;

    return inCircle ? (
        <div
            ref={ref}
            className={cn(null, { inCircle }, className)}
        >
            <Button
                icon='heart'
                size='s'
                dataTest={isFavorite ? 'nb-favorite-yes' : 'nb-favorite-no'}
                variant={isFavorite ? 'danger' : 'secondary'}
                onClick={onClick}
                disabled={disabled}
                circle
            />
            {
                ! withLink ? (
                    <Tooltip
                        direction='down'
                        to={to}
                    >
                        {tooltipText}
                    </Tooltip>
                ) : null
            }
        </div>
    ) : (
        <button
            ref={ref}
            className={cn(null, { isFavorite }, className)}
            data-test={isFavorite ? 'nb-favorite-yes' : 'nb-favorite-no'}
            onClick={onClick}
            disabled={disabled}
        >
            <Icon
                name={'heart' as IconSmallSize}
                size={size}
            />
            {
                ! withLink ? (
                    <Tooltip
                        direction='down'
                        to={to}
                    >
                        {tooltipText}
                    </Tooltip>
                ) : null
            }
        </button>
    );
};

export const FavoritesButtonWithLink = (props: FavoritesButtonPropsType) => {
    const { isFavorite } = props;
    const tooltipText = isFavorite ? 'в\u00A0избранное' : 'Добавить в\u00A0избранное';

    return (
        <Dropdown>
            <FavoritesButton
                withLink
                {...props}
            />
            <Dropdown.Content positioning='end'>
                {isFavorite ? (
                    <div className={cn('dropdown')}>
                        <Typography
                            variant='primary-alone'
                            className={cn('dropdownMainText')}
                        >
                            Добавлено <Link href='/favorites'>{tooltipText}</Link>
                        </Typography>
                        <Typography
                            variant='small-alone'
                            className={cn('dropdownText')}
                        >
                            Перейдите, чтобы скачать презентацию или подписаться на&nbsp;изменение цены
                        </Typography>
                    </div>
                ) : tooltipText}
            </Dropdown.Content>
        </Dropdown>
    );
};

export const FavoritesButtonOffer = ({
    hasFavoritesButton,
    hasRoleM2Pro,
    ...props
} : FavoritesButtonPropsType & {
    hasFavoritesButton?: boolean;
    hasRoleM2Pro: boolean;
}) => {
    if (! hasFavoritesButton) {
        return null;
    }

    return hasRoleM2Pro ? (
        <FavoritesButtonWithLink {...props} />
    ) : (
        <FavoritesButton {...props} />
    );
};

export default FavoritesButton;
