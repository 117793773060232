import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { convertParamsToGQLFilters, INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';

import { graphql, useGql } from '@search/gql-client/src';

import {
    NewBuildingsInput,
    useNewBuildingsSummaryQuery$variables as useNewBuildingsSummaryQueryVariables,
    useNewBuildingsSummaryQuery$data as useNewBuildingsSummaryQueryResponse
} from './__generated__/useNewBuildingsSummaryQuery.graphql';

const NEW_BUILDINGS_SUMMARY_QUERY = graphql`
    query useNewBuildingsSummaryQuery(
        $filters: NewBuildingsInput
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        newBuildingsSummary(
            filters: $filters
            whiteLabel: $whiteLabel
        ) {
            count
            totalPriceMin
            totalPriceMax
            pricePerMeterMin
            pricePerMeterMax
            totalAreaMin
            totalAreaMax
        }
    }
`;

export const useNewBuildingsSummary = ({
    pageParams,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    tracingTag
}: {
    pageParams: INewBuildingSearchType;
    whiteLabel?: NewBuildingWhitelabelEnum;
    tracingTag: string;
}) => {
    const { data } = useGql<
        useNewBuildingsSummaryQueryVariables,
        useNewBuildingsSummaryQueryResponse
    >(NEW_BUILDINGS_SUMMARY_QUERY, {
        whiteLabel,
        filters: convertParamsToGQLFilters(pageParams) as NewBuildingsInput
    }, {
        tracingTag
    });

    return data?.newBuildingsSummary;
};
