/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { addScript } from '../addScript';

declare const window: Window & {
    isCalltouchScriptAdded?: boolean;
};

declare const document: Document;

export const CALLTOUCH_ID = 'i2czl18z';

const CALLTOUCH_SCRIPT = `(function(w,d,n,c){w.CalltouchDataObject=n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)},m=typeof Array.prototype.find === 'function',n=m?"init-min.js":"init.js";s.async=true;s.src="https://mod.calltouch.ru/"+n+"?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","${CALLTOUCH_ID}");`;

export type CalltouchSubpoolType = {
    subPoolId: string;
    subPoolName: string;
    phoneNumber: string;
}

export enum DynamicReplacementErrorEnum {
    SCRIPT = 'script', // нет скрипта
    SERVER_ERROR = 'server_error', // ошибка сервера
    UNKNOWN_ERROR = 'unknown_error', // различные ошибки на клиенте (таймаут ответа сервера, сброс соединения, некорректный json в ответе, ошибочный код ответа сервера)
    CALLTRACKING_BY_CLICK_IS_OFF = 'calltracking_by_click_is_off', // функция подмены по клику выключена
    REPLACEMENT_CONDITIONS_NOT_COMPLETED = 'replacement_conditions_not_completed', // нет ни одного подходящего сабпула для подмены по данной сессии
    SUBPOOLNAME_NOT_FOUND = 'subpoolName_not_found', // одного из переданных значений нет в качестве названий активных сабпулов в проекте
    TIMEOUT = 'timeout_error' // таймаут загрузки скрипта
}

export type DynamicReplacementErrorType = {
    type: DynamicReplacementErrorEnum;
}

export type CalltouchType = (
    callTouchId: string | 'goal',
    method: 'dynamic_replacement' | 'phone_click',
    options?: {
        callback: (success: boolean, result: CalltouchSubpoolType[] | DynamicReplacementErrorType) => void;
        subPoolNames: string[];
    }
) => void;

export const useCalltouch = () => useEffect(() => {
    if (window.isCalltouchScriptAdded) {
        return;
    }

    window.isCalltouchScriptAdded = true;

    const calltouchScript = document.createElement('script');

    calltouchScript.id = 'calltouch-' + CALLTOUCH_ID;
    calltouchScript.type = 'text/javascript';
    calltouchScript.appendChild(document.createTextNode(CALLTOUCH_SCRIPT));

    document.body.appendChild(calltouchScript);
}, []);

export async function addCallTouchScript(callTouchId: string) {
    await addScript({
        content: CALLTOUCH_SCRIPT,
        id: 'calltouch-' + callTouchId,
        check: () => Boolean((window as Window & {ct?: CalltouchType}).ct)
    });

    return (window as Window & {ct: CalltouchType}).ct;
}
