import React from 'react';

import { FavoriteOfferIds } from './useFavoriteOffers';

interface IFavoriteOffersContext {
    favoriteOfferIds: FavoriteOfferIds;
    addFavoriteOfferId: (offerId: string) => void;
    deleteFavoriteOfferId: (offerId: string) => void;
}

const FavoriteOffersContext = React.createContext<IFavoriteOffersContext>({
    favoriteOfferIds: {},
    addFavoriteOfferId: () => {},
    deleteFavoriteOfferId: () => {}
});

export function FavoriteOffersProvider({
    favoriteOfferIds,
    addFavoriteOfferId,
    deleteFavoriteOfferId,
    children
}: IFavoriteOffersContext & {
    children?: React.ReactNode;
}) {
    return (
        <FavoriteOffersContext.Provider value={{ favoriteOfferIds, addFavoriteOfferId, deleteFavoriteOfferId }}>
            {children}
        </FavoriteOffersContext.Provider>
    );
}

export function useFavoriteOffersContext() {
    return React.useContext(FavoriteOffersContext);
}
