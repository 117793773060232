import React, {
    FC,
    Fragment,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import chunk from 'lodash/chunk';
import zip from 'lodash/zip';
import lowerFirst from 'lodash/lowerFirst';
import upperFirst from 'lodash/upperFirst';

import { Button, Grid, Link, Typography } from '@vtblife/uikit';

import type { BuildingInfo, NewBuildingFlat, NewBuildingOffer, NewBuildingOfferMortgageParams } from '@search/graphql-typings';
import {
    ApplicationIdEnum,
    FinishingBuildingEnum,
    MortgagePurpose,
    NewBuildingInfoIconNameEnum
} from '@search/graphql-typings';

import classname from '@search/classname/src';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import {
    minimalBanksMortgageParamsFromOffer,
    useMinimalBanksMortgageOverall
} from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/useMinimalBanksMortageState';
import type { IOfferRouteParams } from '@search/vtbeco-frontend-core/view/common/tools/makeOfferUrl';
import { makeOfferUrl } from '@search/vtbeco-frontend-core/view/common/tools/makeOfferUrl';
import { Mortgage2StarterModal } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/mortgage2/starter/Mortgage2StarterModal';

import { Router } from '@search/router/src/Router';
import { RouterContext } from '@search/offer-search-routes/src';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { FSGallery } from '@search/vtbeco-frontend-core/view/common/components/gallery/GalleryDesktop/FSGallery/FSGallery';
import {
    FigcaptionPosition,
    FigcaptionPositionColor,
    SizeThumbnail
} from '@search/vtbeco-frontend-core/view/common/components/gallery/GalleryDesktop/constants';
import { QRCodePhoneButton } from '@search/vtbeco-frontend-core/view/common/components/QRCodePhoneButton';
import type { CallbackDataType } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import { usePrevious } from '@search/hooks/src/usePrevious';

import useMortgageDemandLink from '../../../hooks/useMortgageDemandLink';
import { GlobalContext } from '../../GlobalContext';
import { IconNameType, OfferSummaryIconNameType, SvgSpriteIcon } from '../../SvgSpriteIcon';
import { renderFlatStatus } from '../../NewBuildingFlats';
import { usePhoneButtonContext } from '../PhoneButtonStateProvider';
import { useFavoriteOfferButton } from '../../FavoritesButton/useFavoriteOfferButton';
import { FavoritesButtonOffer } from '../../FavoritesButton';
import QuickSelectionCardOfferSearchBanner from '../../QuickSelection/QuickSelectionCardOfferSearchBanner';

import type { Building } from '../types';

import { useFavoriteOffersContext } from './FavoriteOffersProvider';
import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardOfferSearch');

type View = 'desktop' | 'mobile';

export const NewBuildingCardOfferSearchBuildingInfo: FC<{
    view: View;
    info: BuildingInfo;
    isInspectionStatusFailed?: boolean;
}> = ({
    view,
    info,
    isInspectionStatusFailed
}) => {
    const {
        buildingStatus,
        hasApartments,
        finishing,
        floor,
        elevatorPassenger,
        elevatorCargo,
        commercialFirstFloor,
        storageRooms
    } = info;
    const content: {
        icon: IconNameType;
        name: string;
        value: string;
    }[] = [];

    if (buildingStatus && ! isInspectionStatusFailed) {
        content.push({
            icon: 'CommissioningDate',
            ...buildingStatus
        });
    }

    if (hasApartments) {
        content.push({
            icon: 'Housing',
            name: 'Тип жилья',
            value: 'апартаменты'
        });
    }

    if (finishing) {
        const { values } = finishing;
        let value;

        if (values.some(item => ([
            FinishingBuildingEnum.Fine,
            FinishingBuildingEnum.PreFinishing,
            FinishingBuildingEnum.WithFurniture
        ].includes(item.value as FinishingBuildingEnum)))) {
            value = values
                .filter(({ label, value: val }) => label && val !== FinishingBuildingEnum.Unknown)
                .map(({ label }) => label)
                .join(', ');
        } else if (values.some(item => item.value === FinishingBuildingEnum.Unknown)) {
            value = 'есть';
        }

        value && content.push({
            icon: 'Finishing',
            name: 'Отделка',
            value
        });
    }

    if (floor) {
        content.push({
            icon: 'Floors',
            name: 'Этажей',
            value: String(floor.formatFloor)
        });
    }

    if (elevatorPassenger?.count) {
        content.push({
            icon: 'Elevator',
            name: declensionByNumber(elevatorPassenger.count, [
                'Пассажирский лифт',
                'Пассажирских лифта',
                'Пассажирских лифтов'
            ]),
            value: String(elevatorPassenger.count)
        });
    }

    if (elevatorCargo?.count) {
        content.push({
            icon: 'Elevator',
            name: declensionByNumber(elevatorCargo.count, [
                'Грузовой лифт',
                'Грузовых лифта',
                'Грузовых лифтов'
            ]),
            value: String(elevatorCargo.count)
        });
    }

    if (commercialFirstFloor) {
        content.push({
            icon: NewBuildingInfoIconNameEnum.CommercialFirstFloor,
            name: commercialFirstFloor.label!,
            value: commercialFirstFloor.value!
        });
    }

    if (storageRooms) {
        content.push({
            icon: NewBuildingInfoIconNameEnum.StorageRooms,
            name: storageRooms.label!,
            value: storageRooms.value!
        });
    }

    if (content.length === 0) {
        return null;
    }

    return (
        <div className={cn('buildingInfo', { view })}>
            {
                content.map(({ icon, name, value }) => (
                    <div
                        key={name}
                        className={cn('buildingInfoItem')}
                    >
                        <div className={cn('buildingInfoItemIcon')}>
                            <SvgSpriteIcon name={icon} />
                        </div>
                        <div className={cn('buildingInfoItemContent')}>
                            <Typography
                                variant='secondary-alone'
                                color='minor'
                            >
                                {name}
                            </Typography>
                            <Typography variant='primary-alone'>
                                {lowerFirst(value)}
                            </Typography>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export const NewBuildingCardOfferSearchBuildingFlats: FC<{
    view: View;
    flats: NewBuildingFlat[] | null;
    selectFlat?: (flat: NewBuildingFlat) => void;
    isSoldOut?: boolean;
    hasNoOffers: boolean;
}> = ({
    view,
    flats: flatsProp,
    selectFlat,
    isSoldOut,
    hasNoOffers
}) => {
    const flats = flatsProp?.filter(flat => Boolean(
        flat && ! (hasNoOffers && isSoldOut && flat.isSoldOut)
    ));

    if (! flats?.length) {
        return null;
    }

    const isDesktop = view === 'desktop';

    const flatGroups = isDesktop ?
        zip(...chunk(flats, Math.ceil(flats.length / 2))) :
        [ flats ];

    return (
        <div className={cn('buildingFlats', { view })}>
            {
                flatGroups.map((flatGroup, i) => (
                    <Fragment key={i}>
                        {
                            flatGroup.map((flat, j) => flat ? (
                                <div
                                    key={j}
                                    className={cn('buildingFlatsItemWrapper', { alone: flats.length === 1 })}
                                >
                                    <div
                                        className={cn('buildingFlatsItem')}
                                        onClick={selectFlat && (() => selectFlat(flat))}
                                    >
                                        <div className={cn('buildingFlatsItemRooms')}>
                                            <Typography variant='secondary-alone'>
                                                {flat.labelShort}
                                            </Typography>
                                            <Typography
                                                variant='secondary-alone'
                                                color='minor'
                                            >
                                                {flat.formatAreaRange}
                                            </Typography>
                                        </div>
                                        <Typography
                                            className={cn('buildingFlatsItemStatus')}
                                            variant='secondary-alone'
                                            color={
                                                flat.isSoldOut ||
                                                flat.isSoon ||
                                                flat.isTemporaryNotOnSale ||
                                                ! flat.formatPriceRangeShort ?
                                                    'minor' :
                                                    'primary'
                                            }
                                        >
                                            {renderFlatStatus(flat, true)}
                                        </Typography>
                                    </div>
                                </div>
                            ) : null)
                        }
                    </Fragment>
                ))
            }
        </div>
    );
};

const OfferMortgage: FC<{
    mortgageRegionId: number;
    isDesktop: boolean;
    offerURL: string | null;
    newBuildingName: string;
    mortgageParams: NewBuildingOfferMortgageParams;
}> = props => {
    const { url, allMinimalPaymentFormatted, isLoading, minimalBanksMortgageOffers } = useMinimalBanksMortgageOverall(
        minimalBanksMortgageParamsFromOffer({
            mortgageRegionId: props.mortgageRegionId,
            propertyCost: props.mortgageParams.propertyCost,
            isNewBuilding: true
        })
    );

    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const handleClickToMortgageForm = useCallback(() => dataLayerPush({
        event: 'nb_ib_form',
        action: 'open',
        page_type: 'card',
        place: 'flat'
    }), []);
    const mortgageDemandLink = useMortgageDemandLink({
        regionId: props.mortgageRegionId,
        propertyCost: props.mortgageParams.propertyCost,
        purpose: props.mortgageParams.purposeValue,
        claimType: props.mortgageParams.claimType,
        ownAmount: props.mortgageParams.ownAmount,
        newBuildingName: props.newBuildingName
    });

    const [ isModalOpen, toggleModal ] = useState<boolean>(false);
    const openModal = useCallback(() => toggleModal(true), []);
    const closeModal = useCallback(() => toggleModal(false), []);

    return isLoading || ! allMinimalPaymentFormatted ? null : (
        <div>
            <Typography
                variant='primary-alone'
                tag='span'
            >
                {`от ${allMinimalPaymentFormatted} `}
            </Typography>
            {
                // eslint-disable-next-line no-nested-ternary
                hasRoleM2Pro ? (
                    <Link
                        target='blank'
                        href={mortgageDemandLink}
                    >
                        <Typography variant='primary-alone'>
                            в ипотеку
                        </Typography>
                    </Link>
                ) : (
                    props.isDesktop && props.offerURL ? (
                        <Typography
                            tag='span'
                            className={cn('link')}
                            variant='primary-alone'
                            onClick={openModal}
                        >
                            в ипотеку
                        </Typography>
                    ) : (
                        <Link
                            target='blank'
                            href={url}
                            gtm={DATA_GTM.MORTGAGE_OFFER}
                            onClick={handleClickToMortgageForm}
                        >
                            <Typography variant='primary-alone'>
                                в ипотеку
                            </Typography>
                        </Link>
                    )
                )
            }
            {
                isModalOpen ? (
                    <Mortgage2StarterModal
                        withoutAuth
                        isNewVibe
                        isPreferentialNbCondition
                        showMoreBankIcons
                        purpose={MortgagePurpose.Primary}
                        locationPlacement='попап оффера'
                        applicationId={props.isDesktop ? ApplicationIdEnum.NewBuildingsDesktop : ApplicationIdEnum.NewBuildingsTouch}
                        gtmSendId={DATA_GTM.MORTGAGE_OFFER_STARTER_MODAL_SEND_ID}
                        gtmShowId={DATA_GTM.MORTGAGE_OFFER_STARTER_MODAL_SHOW_ID}
                        onClose={closeModal}
                        offerUrl={props.offerURL!}
                        propertyCost={props.mortgageParams.propertyCost}
                        ownAmount={props.mortgageParams.ownAmount}
                        term={props.mortgageParams.term}
                        mortgageRegionId={props.mortgageRegionId}
                        realtyType='ProfOfferFlat'
                        isDefaultSelectedOffer
                        mortgageOffers={minimalBanksMortgageOffers}
                        withoutShowContainer
                        gtmSendData={{
                            event: 'nb_ib_form',
                            action: 'send',
                            page_type: 'card',
                            place: 'flat'
                        }}
                        gtmShowData={{
                            event: 'nb_ib_form',
                            action: 'open',
                            page_type: 'card',
                            place: 'flat'
                        }}
                    />
                ) : null
            }
        </div>
    );
};

const OfferModalSnippet: FC<{
    snippetRef?: React.RefObject<HTMLDivElement>;
    view: View;
    offer: NewBuildingOffer;
    buildings: Building[];
    buildingById: Record<string, Building>;
    defaultDateString?: string;
    router: Router<RouterContext>;
    renderCallBack?: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => ReactElement | null;
    developerLink: string;
    developerTitle: string;
    newBuildingName: string;
    developerImage?: {
        origin: string;
    };
    backofficeRegionId?: number;
    callbackData: CallbackDataType;
    isMskMOorSPbLo?: boolean;
    renderM2Pro: (offer: NewBuildingOffer) => React.ReactNode;
    openFavoritesTooltip?: () => void;
}> = ({ // eslint-disable-line
    snippetRef,
    view,
    offer,
    buildings,
    buildingById,
    defaultDateString,
    router,
    renderCallBack,
    developerLink,
    developerTitle,
    developerImage,
    backofficeRegionId,
    callbackData,
    isMskMOorSPbLo,
    renderM2Pro,
    openFavoritesTooltip,
    newBuildingName
}) => {
    const {
        instanceConfig: {
            hasOffers,
            hasFavoritesButton,
            hasQuickSelection
        }
    } = useContext(GlobalContext);
    const { favoriteOfferIds, addFavoriteOfferId, deleteFavoriteOfferId } = useFavoriteOffersContext();
    const isFavorite = useMemo(() => favoriteOfferIds[offer.routeParams.offerId] ?? false, [
        favoriteOfferIds,
        offer.routeParams.offerId
    ]);

    const [ isImageOpened, setImageOpened ] = useState(false);

    const { handleClick, buttonsData, firstDeveloperId } = usePhoneButtonContext();

    const updateFavorite = useFavoriteOfferButton({
        offerId: offer.routeParams.offerId,
        isFavorite,
        addFavoriteOfferId,
        deleteFavoriteOfferId
    });

    const isDesktop = view === 'desktop';
    const building = buildings.length === 1 && buildings[0] ||
        offer.buildingId && buildingById[offer.buildingId] ||
        null;
    const url = makeOfferUrl(router, offer.routeParams as IOfferRouteParams);
    let housing: null | string = null;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const isQuickSelectionPromoVisible = hasQuickSelection && isMskMOorSPbLo && ! hasM2Pro;

    const isFavoritePrev = usePrevious<boolean | undefined>(isFavorite);

    useEffect(() => {
        if (isFavorite && isFavoritePrev === false && hasM2Pro && openFavoritesTooltip) {
            openFavoritesTooltip();
        }
    }, [ isFavoritePrev, isFavorite, hasM2Pro, openFavoritesTooltip ]);

    if (offer.isApartments !== null) {
        housing = offer.isApartments ? 'апартаменты' : 'жилая недвижимость';
    }

    const info = useMemo(() => ([
        {
            name: 'Срок сдачи',
            value: building && lowerFirst(building.dateString) ||
                defaultDateString && lowerFirst(defaultDateString),
            icon: 'OfferCommissioningDate'
        },
        {
            name: 'Общая площадь',
            value: offer.area,
            icon: 'Area'
        },
        {
            name: 'Жилая площадь',
            value: offer.livingArea,
            icon: 'LivingArea'
        },
        {
            name: 'Площадь кухни',
            value: offer.kitchenArea,
            icon: 'KitchenArea'
        },
        {
            name: 'Отделка',
            value: offer.finishing ?? 'уточните у\u00A0застройщика',
            icon: 'OfferFinishing'
        },
        {
            name: offer.balcony && upperFirst(offer.balcony[1]),
            value: offer.balcony && offer.balcony[0],
            icon: 'Balcony'
        },
        {
            name: 'Тип жилья',
            value: housing,
            icon: 'Housing'
        }
    ] as {
        name: string;
        value?: string | null;
        icon: OfferSummaryIconNameType;
    }[]).filter(({ value }) => Boolean(value)), [
        building, defaultDateString, offer, housing
    ]);

    const extra = useMemo(
        () => ([ {
            name: 'Корпус',
            value: building?.address
        }, {
            name: 'Количество комнат',
            value: offer.roomsTitle
        }, {
            name: 'Площадь',
            value: offer.area
        }, {
            name: 'Этаж',
            value: offer.floorTitle
        }, {
            name: 'Цена',
            value: offer.price
        }, {
            name: 'Ссылка на объявление',
            value: url
        } ] as {
            name: string;
            value?: string | null;
        }[])
            .filter(({ value }) => Boolean(value))
            .map(({ name, value }) => `${name}: ${value!}`)
            .join('\n'),
        [ offer, url, building ]
    );

    const renderLayout = () => offer.layout ? (
        <>
            <div
                className={cn('offerModalSnippetLayout')}
                style={{
                    backgroundImage: `url(${offer.layout.origin})`
                }}
                onClick={setImageOpened.bind(null, true)}
            />
            {isImageOpened ? (
                <FSGallery
                    onClose={setImageOpened.bind(null, false)}
                    srcImages={
                        {
                            preview: [ offer.layout.small ],
                            origin: [ offer.layout.origin ]
                        }
                    }

                    initialIndexSrcImage={0}
                    size={SizeThumbnail.L}
                    figcaptionPosition={FigcaptionPosition.TOP}
                    figcaptionPositionColor={FigcaptionPositionColor.WHITE}
                    renderArrow={false}
                    hideThumbnails
                />
            ) : null}
        </>
    ) : (
        <div className={cn('offerModalSnippetLayout', { stub: true })}>
            <SvgSpriteIcon
                name='NoPlan'
                width={42}
                height={42}
            />
        </div>
    );

    const renderPrice = () => (
        <>
            <div className={cn('offerModalSnippetPriceTop')}>
                <div>
                    <Typography
                        variant='primary-alone'
                        color='secondary'
                    >
                        Стоимость
                    </Typography>
                    <Typography variant='h3'>
                        {offer.price}
                    </Typography>
                </div>
                <FavoritesButtonOffer
                    hasFavoritesButton={hasFavoritesButton && isDesktop}
                    hasRoleM2Pro={hasM2Pro}
                    isFavorite={isFavorite}
                    onClick={updateFavorite}
                    inCircle
                />
            </div>
            <Typography variant='primary-alone'>
                {offer.pricePerAreaUnit}
            </Typography>
            {
                hasOffers && backofficeRegionId! <= 4 ? (
                    <OfferMortgage
                        isDesktop={isDesktop}
                        mortgageRegionId={backofficeRegionId!}
                        newBuildingName={newBuildingName}
                        mortgageParams={offer.mortgageParams}
                        offerURL={url}
                    />
                ) : null
            }
            {! isDesktop && hasM2Pro && renderM2Pro(offer)}
            {
                hasFavoritesButton && ! isDesktop ? (
                    <div className={cn('offerModalSnippetFavoriteBtn')}>
                        <Button
                            variant='secondary'
                            icon='heart'
                            iconColor={isFavorite ? 'hsl(5, 90%, 58%)' : undefined}
                            dataTest={isFavorite ? 'nb-favorite-yes' : 'nb-favorite-no'}
                            onClick={updateFavorite}
                            fullWidth
                        >
                            {isFavorite ? 'В избранном' : 'Добавить в избранное'}
                        </Button>
                    </div>
                ) : null
            }
            {isDesktop && hasM2Pro && renderM2Pro(offer)}
            {isDesktop && firstDeveloperId && ! hasM2Pro && (
                <QRCodePhoneButton
                    href={buttonsData[firstDeveloperId].href}
                    onClick={() => handleClick(firstDeveloperId)}
                    qrCodeValue={buttonsData[firstDeveloperId].qrCodeValue}
                    dropdownContainer={snippetRef}
                    gtm={DATA_GTM.CARD_PHONE_OFFER_MODAL}
                    loading={buttonsData[firstDeveloperId].isLoading}
                    className={cn('offerModalSnippetPhoneButton')}
                >
                    {buttonsData[firstDeveloperId].text}
                </QRCodePhoneButton>
            )}
            {renderCallBack && ! hasM2Pro && renderCallBack(
                DATA_GTM.CARD_CALL_BACK_OFFER,
                DATA_GTM.CARD_CALL_BACK_SEND_OFFER,
                extra,
                offer.roomsTitle
            )}
            <Grid.Row
                className={cn('offerModalSnippetDeveloper')}
                gutter={24}
            >
                <Grid.Col xs={18}>
                    <Typography
                        variant='primary-alone'
                        color='secondary'
                    >
                        Застройщик
                    </Typography>
                    <CustomTypography
                        className={cn('offerModalSnippetDeveloperName')}
                        bold
                        tag='a'
                        level='primary-alone'
                        target='_blank'
                        color='primary'
                        href={developerLink}
                    >
                        {developerTitle}
                    </CustomTypography>
                </Grid.Col>
                <Grid.Col
                    className={cn('offerModalSnippetDeveloperLogoContainer')}
                    xs={6}
                >
                    {developerImage?.origin ? (
                        <Image
                            className={cn('offerModalSnippetDeveloperLogo')}
                            type='contain'
                            width={40}
                            height={40}
                            url={developerImage.origin}
                        />
                    ) : (
                        <SvgSpriteIcon
                            className={cn('offerModalSnippetDeveloperLogo')}
                            width={40}
                            height={40}
                            name='CustomDeveloper'
                        />
                    )}
                </Grid.Col>
            </Grid.Row>
        </>
    );

    return (
        <div
            ref={snippetRef}
            className={cn('offerModalSnippet', { view })}
        >
            <Typography
                variant={isDesktop ? 'h2' : 'h4'}
                bold
            >
                {
                    upperFirst([
                        offer.roomsTitle,
                        offer.area,
                        offer.floorTitle
                    ].filter(Boolean).join(', '))
                }
            </Typography>
            {
                building ? (
                    <Typography
                        className={cn('offerModalSnippetBuilding')}
                        variant='primary-alone'
                    >
                        {upperFirst(building.address ?? undefined)}
                    </Typography>
                ) : null
            }
            {
                isDesktop ? (
                    <>
                        <Grid.Row
                            className={cn('offerModalSnippetMain')}
                            gutter={24}
                        >
                            <Grid.Col xs={16}>
                                {renderLayout()}
                            </Grid.Col>
                            <Grid.Col
                                className={cn('offerModalSnippetPrice')}
                                xs={8}
                            >
                                {renderPrice()}
                            </Grid.Col>
                        </Grid.Row>
                        {
                            isQuickSelectionPromoVisible ? (
                                <QuickSelectionCardOfferSearchBanner
                                    callbackData={{
                                        ...callbackData,
                                        extra,
                                        subjectExtra: offer.roomsTitle
                                    }}
                                    className={cn('desktopModalQuickSelection')}
                                />
                            ) : null
                        }
                    </>
                ) : (
                    <>
                        {renderLayout()}
                        <div className={cn('offerModalSnippetPrice')}>
                            {renderPrice()}
                        </div>
                    </>
                )
            }
            {
                info.length ? (
                    <>
                        {
                            isDesktop ? chunk(info, 3).map((infoGroup, i) => (
                                <Grid.Row
                                    key={i}
                                    className={cn('offerModalSnippetInfoGroup')}
                                    gutter={24}
                                >
                                    {infoGroup.reduce((cols, { name, value, icon }) => [
                                        ...cols,
                                        (
                                            <Grid.Col
                                                key={`${name} icon`}
                                                xs={2}
                                            >
                                                <div className={cn('offerModalSnippetInfoIcon')}>
                                                    <SvgSpriteIcon name={icon} />
                                                </div>
                                            </Grid.Col>
                                        ),
                                        (
                                            <Grid.Col
                                                key={`${name} info`}
                                                xs={6}
                                            >
                                                <Typography
                                                    variant='secondary-alone'
                                                    color='secondary'
                                                >
                                                    {name}
                                                </Typography>
                                                <Typography variant='primary-alone'>
                                                    {value}
                                                </Typography>
                                            </Grid.Col>
                                        )
                                    ], [] as Array<ReactElement>)}
                                </Grid.Row>
                            )) : info.map(({ name, value, icon }) => (
                                <div
                                    key={name}
                                    className={cn('offerModalSnippetInfoGroup')}
                                >
                                    <div className={cn('offerModalSnippetInfoIcon')}>
                                        <SvgSpriteIcon name={icon} />
                                    </div>
                                    <div>
                                        <Typography
                                            variant='secondary-alone'
                                            color='secondary'
                                        >
                                            {name}
                                        </Typography>
                                        <Typography variant='primary-alone'>
                                            {value}
                                        </Typography>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                ) : null
            }
            {
                hasOffers && url ? (
                    <CustomTypography
                        className={cn('link', undefined, cn('offerModalSnippetLink'))}
                        level='primary-alone'
                        tag='a'
                        href={url}
                        target='_blank'
                    >
                        {
                            `Объявление о продаже ${
                                offer.isApartments ?
                                    'этих апартаментов' :
                                    'этой квартиры'
                            }`
                        }
                    </CustomTypography>
                ) : null
            }
            {
                ! isDesktop && isQuickSelectionPromoVisible ? (
                    <QuickSelectionCardOfferSearchBanner
                        isMobile
                        callbackData={{
                            ...callbackData,
                            extra,
                            subjectExtra: offer.roomsTitle
                        }}
                        className={cn('mobileModalQuickSelection')}
                    />
                ) : null
            }
        </div>
    );
};

OfferModalSnippet.displayName = 'NewBuildingCardOfferSearch/OfferModalSnippet';

export const NewBuildingCardOfferSearchOfferModalSnippet = errorCatcherWrap(OfferModalSnippet);
