/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */

type Item = {
    name: string;
    args: { id: string };
    isComplete?: boolean;
    error?: Error;
};

type Callback<Args, Result> = (args: Args) => Promise<Result>;

export class DelayedPersistRunner {
    static instance = new DelayedPersistRunner();
    constructor(
        protected win = typeof window === 'undefined' ? undefined : window
    ) {
        this.win = win;
        this.win?.addEventListener('beforeunload', this.beforeUnloadCached);
    }

    get id() {
        return 'DelayedPersistRunner';
    }

    desctructor() {
        this.win?.removeEventListener('beforeunload', this.beforeUnloadCached);
    }

    protected beforeUnloadCached = this.beforeUnload.bind(this)

    beforeUnload() {
        this.flush();
    }

    flush() {
        const tasks = this.tasksCache = this.tasks.filter(item => ! item?.isComplete);

        this.win?.localStorage.setItem(this.id, JSON.stringify(tasks));
    }

    protected tasksCache: Item[] | undefined = undefined;

    protected get tasks() {
        if (this.tasksCache) return this.tasksCache;
        const raw = this.win?.localStorage.getItem(this.id);
        const data: Item[] = raw ? JSON.parse(raw) : [];

        return (this.tasksCache = data);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected callbacks = new Map<string, Callback<any, any>>();

    register<Args, Result>(name: string, cb: Callback<Args, Result>) {
        this.callbacks.set(name, cb);

        return {
            run: this.invoke.bind(this, name) as Callback<Args & { id: string }, Result | undefined>,
            args: this.args.bind(this, name) as () => Args[]
        };
    }

    args<Args>(name: string): Args[] {
        return this.tasks
            .filter(item => item.name === name && ! item.isComplete)
            .map(item => item.args as unknown as Args);
    }

    isPersist(e: Error) {
        return true;
    }

    async invoke<Args extends { id: string }, Result>(name: string, args: Args) {
        const cb = this.callbacks.get(name) as Callback<Args, Result>;

        try {
            const result = await cb(args);

            this.tasksCache = this.tasksCache?.filter(t => t.args.id !== args.id);

            return result;
        } catch (e) {
            if (this.isPersist(e)) {
                const prev = this.tasks.find(t => t.args.id === args.id);
                const task = { name, args, error: undefined, isComplete: undefined };

                if (prev) Object.assign(prev, task);
                else this.tasks.push(task);

                return;
            }
            throw e;
        }
    }

    async run() {
        for (const item of this.tasks) {
            if (item.isComplete) continue;
            const cb = this.callbacks.get(item.name);

            if (! cb) continue;

            try {
                // eslint-disable-next-line no-await-in-loop
                await cb(item.args);
                item.isComplete = true;
            } catch (e) {
                item.error = e;
            }
        }
        this.flush();
    }
}
