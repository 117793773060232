import { useMemo } from 'react';

import { InspectionStatusEnum } from '@search/graphql-gateway/typings';
import type { NewBuildingCard } from '@search/graphql-typings';
import { TipTypeEnum } from '@search/graphql-typings';

import type { PolygonCoordinate } from '@search/ymap/src/Polygon';

import { graphql } from '@search/gql-client/src/useGql';
import { formatDate } from '@search/helpers/src/formatDate';
import { Commissioning, Quarter } from '@search/filter-enums/enums';
import type { QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';

import type { Shared } from '../../../../types/shared';

import { getColor, HEX_COMMISSIONED, HEX_FROZEN } from '../../libs/colors';

import type { Building, BuildingGroup } from './types';

export type {
    MortgagePurpose,
    NewBuildingClassEnum,
    NewBuildingVtbMortgageVariant,
    NewBuildingWhitelabelEnum,
    TransportTypeEnum,
    NewBuildingCardQuery$variables as NewBuildingCardQueryVariables,
    NewBuildingCardQuery$data as NewBuildingCardQueryResponse
} from './__generated__/NewBuildingCardQuery.graphql';
export type {
    NewBuildingCardBuildingsQuery$variables as NewBuildingCardBuildingsQueryVariables,
    NewBuildingCardBuildingsQuery$data as NewBuildingCardBuildingsQueryResponse
} from './__generated__/NewBuildingCardBuildingsQuery.graphql';
export type {
    NewBuildingsInput,
    NewBuildingCardSimilarNewBuildingsQuery$variables as NewBuildingCardSimilarNewBuildingsQueryVariables,
    NewBuildingCardSimilarNewBuildingsQuery$data as NewBuildingCardSimilarNewBuildingsQueryResponse
} from './__generated__/NewBuildingCardSimilarNewBuildingsQuery.graphql';
export type {
    NewBuildingCardOtherNewBuildingsByDeveloperQuery$variables as NewBuildingCardOtherNewBuildingsByDeveloperQueryVariables,
    NewBuildingCardOtherNewBuildingsByDeveloperQuery$data as NewBuildingCardOtherNewBuildingsByDeveloperQueryResponse
} from './__generated__/NewBuildingCardOtherNewBuildingsByDeveloperQuery.graphql';
export * from './NewBuildingCardPolygons';
export * from './NewBuildingCardCommissioningDates';
export * from './types';

export const query = graphql`
    fragment NewBuildingCardInfoItemFields on InfoItem {
        weight
        label
        value
        url
        iconName
    }

    query NewBuildingCardQuery(
        $id: Int!
        $whiteLabel: NewBuildingWhitelabelEnum
        $skipFavorites: Boolean!
    ) {
        newBuilding(
            id: $id
            whiteLabel: $whiteLabel
        ) {
            id
            narrowRegionId(whiteLabel: $whiteLabel)
            narrowRegionLocative(whiteLabel: $whiteLabel)
            backofficeRegionId
            hasFlats
            hasFlatsOnly
            hasApartments
            hasApartmentsOnly
            isSoldOut
            isSoon
            hasMilitaryMortgage
            ranking
            hasChat(whiteLabel: $whiteLabel)
            title {
                genitive
                locative
                accusative
                nominative
                dative
                simplified
            }
            type
            name
            seo(whiteLabel: $whiteLabel) {
                seoTitle
            }
            priceMin
            priceMax
            pricePerM2Min
            pricePerM2Max
            formatAreaRange
            mortgageMinPay {
                term
                rate
                creditAmount
                minPay
                variant
                initialFee
            }
            buildingClass {
                value
                label
                fullLabel
            }
            buildingStatus {
                title
                isFrozen
            }
            isMskMO
            isSPbLO
            isSpecProjectRegion
            coordinates
            polygons
            address
            description
            fullDescription {
                html
            }
            routes(type: [ METRO ]) {
                ...on RouteMetro {
                    station {
                        id
                        name
                        lines
                    }
                    timeMinutes
                    transportType
                }
            }
            description
            position
            developerCards {
                developer {
                    id
                    isSamolet
                    title
                    translitTitle
                    webSite
                    icon {
                        origin
                    }
                    phones {
                        formattedNumber
                    }
                    image {
                        small
                    }
                }
                commissionedCount {
                    value
                    shortLabel
                }
                inProgressCount {
                    value
                    shortLabel
                }
            }
            developerPromoBanner {
                id
                url
                long
                square
                developerName
            }
            gallery {
                main {
                    galleryThumbnail
                    newBuildingSnippet
                    mobileGallery
                    originPath
                }
                images {
                    type
                    galleryThumbnail
                    newBuildingSnippet
                    mobileGallery
                    originPath
                }
                baseUrl
            }
            videoId
            flats {
                rooms
                label(type: CARD)
                isSoldOut
                isSoon
                isTemporaryNotOnSale
                formatAreaRange
                formatPriceRange
            }
            buildings {
                id
                hasApartmentsOnly
                noOffers
                isSoldOut
                isSoon
                name
                address
                accreditedByVtb
                livingFlatsCount
                apartmentsCount
                polygons
                commissioningDate
                isCommissioned
                isFrozen
                priceRange
                flats {
                    label(type: CARD)
                    labelShort
                    rooms
                    isSoldOut
                    isSoon
                    isTemporaryNotOnSale
                    formatAreaRange
                    formatPriceRange
                    formatPriceRangeShort
                }
                infoTitle
                info {
                    legal
                    status
                    buildingStatus {
                        name
                        value
                    }
                    hasApartments
                    buildingClass {
                        label
                    }
                    floor {
                        formatFloor
                    }
                    finishing {
                        label
                        values {
                            value
                            label
                        }
                    }
                    walls {
                        values {
                            label
                        }
                    }
                    ceilingHeight(unit: METER) {
                        text
                    }
                    elevatorPassenger {
                        count
                    }
                    elevatorCargo {
                        count
                    }
                    commercialFirstFloor {
                        label
                        value
                    }
                    storageRooms {
                        label
                        value
                    }
                }
                saleDetails {
                    escrow {
                        label
                    }
                }
                inspectionStatus(whiteLabel: $whiteLabel) {
                    desktopLink
                    mobileLink
                    linkText
                    detailText
                    value
                }
                m2Pro {
                    prepayment
                }
            }
            offersUpdateDate
            offersSummaryInfo {
                finishingTypesList {
                    value
                    label
                }
            }
            info {
                commissioningDate {
                    ...NewBuildingCardInfoItemFields
                }
                buildingClass {
                    ...NewBuildingCardInfoItemFields
                }
                buildingsCount {
                    ...NewBuildingCardInfoItemFields
                }
                flatsAndApartmentsCount {
                    ...NewBuildingCardInfoItemFields
                }
                flatsCount {
                    ...NewBuildingCardInfoItemFields
                }
                apartmentsCount {
                    ...NewBuildingCardInfoItemFields
                }
                apartments {
                    ...NewBuildingCardInfoItemFields
                }
                ceilingHeight(unit: METER) {
                    ...NewBuildingCardInfoItemFields
                }
                finishing {
                    ...NewBuildingCardInfoItemFields
                }
                floorsCount {
                    ...NewBuildingCardInfoItemFields
                }
                walls {
                    ...NewBuildingCardInfoItemFields
                }
                closedArea {
                    ...NewBuildingCardInfoItemFields
                }
                openParking {
                    ...NewBuildingCardInfoItemFields
                }
                closedParking {
                    ...NewBuildingCardInfoItemFields
                }
                undergroundParking {
                    ...NewBuildingCardInfoItemFields
                }
                carFreeCourtyard {
                    ...NewBuildingCardInfoItemFields
                }
                concierge {
                    ...NewBuildingCardInfoItemFields
                }
                guardedArea {
                    ...NewBuildingCardInfoItemFields
                }
                elevatorBrand {
                    ...NewBuildingCardInfoItemFields
                }
                law214 {
                    ...NewBuildingCardInfoItemFields
                }
                escrow {
                    ...NewBuildingCardInfoItemFields
                }
                contractType {
                    ...NewBuildingCardInfoItemFields
                }
                motherCapital {
                    ...NewBuildingCardInfoItemFields
                }
                commercialFirstFloor {
                    ...NewBuildingCardInfoItemFields
                }
                officeOnSite {
                    ...NewBuildingCardInfoItemFields
                }
                showroom {
                    ...NewBuildingCardInfoItemFields
                }
                barrierFreeEnv {
                    ...NewBuildingCardInfoItemFields
                }
                school {
                    ...NewBuildingCardInfoItemFields
                }
                kindergarten {
                    ...NewBuildingCardInfoItemFields
                }
                storageRooms {
                    ...NewBuildingCardInfoItemFields
                }
            }
            constructionProgress {
                image {
                    galleryPhoto
                    medium
                    mediumMobile
                    galleryThumbnail
                    originPath
                }
                year
                quarter
                address
            }
            documents {
                constructionApprovals {
                    title
                    fileName
                    url
                }
                projectDeclarations {
                    title
                    fileName
                    url
                }
                actsOfCommissioning {
                    title
                    fileName
                    url
                }
            }
            mortgageParams {
                regionId
                isMainRegion
                propertyCost
                purpose
                purposeValue
                claimType
                ownAmount
                term
            }
            shortBanksMortgage {
                minimalPaymentText
            }
            noOffers
            similarParams {
                regionIds
                buildingClasses
            }
            developerRegionIds
            specialEvents {
                type
                title
                titleRaw
                description {
                    short
                    full
                }
                duration {
                    short
                    full
                }
                buildingIds
                isHighlighted
            }
            inspectionStatus(whiteLabel: $whiteLabel) {
                desktopLink
                mobileLink
                linkText
                value
                statusText
                detailText
            }
            routeParams {
                region
                regionId
                subRegion
                subRegionDisplayName
                name
                type
                id
            }
            news {
                title
                link
                categories {
                    label
                }
                images
                date {
                    formattedShort
                }
            }
            isFavorite @skip(if: $skipFavorites)
            infoList
            advantagesList
            conditions {
                image {
                    small
                }
                description
            }
            simplifiedCardHeader
            m2Pro {
                prepayment
                cooperationTerms {
                    title
                    fileName
                    url
                }
                nbPresentation {
                    title
                    fileName
                    url
                }
            }
        }
    }
`;

export const buildingsQuery = graphql`
    query NewBuildingCardBuildingsQuery(
        $filters: NewBuildingOffersInput!
        $paging: PagingInput
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        getBuildings(
            filters: $filters
            paging: $paging
            whiteLabel: $whiteLabel
        ) {
            ids
        }
    }
`;

export const similarNewBuildingsQuery = graphql`
    query NewBuildingCardSimilarNewBuildingsQuery(
        $filters: NewBuildingsInput
        $paging: PagingInput!
        $whiteLabel: NewBuildingWhitelabelEnum
        $touch: Boolean!
        $isSimilar: Boolean!
    ) {
        searchNewBuildings(
            paging: $paging
            filters: $filters
            whiteLabel: $whiteLabel
            isSimilar: $isSimilar
        ) {
            items(touch: $touch) {
                ...on NewBuildingSnippet {
                    id
                    name
                    priceMin
                    gallery {
                        main {
                            medium
                            originPath
                        }
                    }
                    description
                    shortAddress
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                            timeMinutes
                            transportType
                        }
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                }
            }
        }
    }
`;

export const otherNewBuildingsByDeveloperQuery = graphql`
    query NewBuildingCardOtherNewBuildingsByDeveloperQuery(
        $filters: NewBuildingsInput
        $paging: PagingInput!
        $whiteLabel: NewBuildingWhitelabelEnum
        $touch: Boolean!
        $isOthersFromDeveloper: Boolean!
    ) {
        searchNewBuildings(
            paging: $paging
            filters: $filters
            whiteLabel: $whiteLabel
            isOthersFromDeveloper: $isOthersFromDeveloper
        ) {
            paging {
                pageNumber
                total
            }
            items(touch: $touch) {
                ...on NewBuildingSnippet {
                    id
                    name
                    priceMin
                    gallery {
                        main {
                            medium
                            originPath
                        }
                    }
                    description
                    shortAddress
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                            timeMinutes
                            transportType
                        }
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                }
            }
        }
    }
`;

const getBuildingIds = (
    buildingIdsByCommissioning: Record<string, number[]>,
    commissioningDates: string | string[]
) => (Array.isArray(commissioningDates) ? commissioningDates : [ commissioningDates ])
    .reduce(
        (buildingIds: number[], commissioningDate) => buildingIds
            .concat(buildingIdsByCommissioning[commissioningDate] || []),
        []
    );

const FROZEN = 'frozen';

export const useBuildings = (
    buildingsData: Building[],
    accreditedKey: Shared.IInstanceConfig['accreditedKey']
) => useMemo(() => {
    let list: Building[] = [];
    let byDate: Record<string, Building[]> = {};
    let buildingGroups: BuildingGroup[] = [];
    let notAccreditedBuildingsTotal = 0;
    let hasFrozenBuildings = false;
    let queryDisplayValues: QueryDisplayValues | undefined;
    const buildings: Building[] = [];
    const buildingById: Record<string, Building> = {};
    const buildingNameById: Record<string, string | null> = {};
    const buildingIdsByCommissioning: Record<string, number[]> = {};
    const commissioningOptions: { value: string; label: string }[] = [];

    if (buildingsData) {
        list = buildingsData
            .filter(building => building.commissioningDate && building.polygons);

        byDate = list
            .reduce((ret: Record<string, Building[]>, building) => {
                if (accreditedKey && ! building[accreditedKey]) {
                    notAccreditedBuildingsTotal++;
                }

                let commissioningDate = building.commissioningDate!;

                if (building.isFrozen || building.inspectionStatus?.value === InspectionStatusEnum.Failed) {
                    commissioningDate = FROZEN;
                } else if (building.isCommissioned || Date.parse(commissioningDate) < Date.now()) {
                    commissioningDate = '';
                } else {
                    const date = new Date(commissioningDate);

                    commissioningDate = `${date.getFullYear()}.${Math.floor(date.getMonth() / 3) + 1}`;
                }

                if (! ret[commissioningDate]) {
                    ret[commissioningDate] = [];
                }

                const buildingCopy = { ...building };

                ret[commissioningDate].push(buildingCopy);
                buildings.push(buildingCopy);

                return ret;
            }, {});

        buildingGroups = Object.keys(byDate)
            .map(date => ({
                date,
                buildings: byDate[date]
            }))
            .sort(({ date: date0 }, { date: date1 }) => (
                date0 === date1 ?
                    0 :
                    Number(date0 > date1) || -1
            ));

        const colorShift = buildingGroups.length && buildingGroups[0].date ? 0 : 1;

        buildingGroups.forEach((buildingGroup, i) => {
            if (buildingGroup.date === FROZEN) {
                buildingGroup.date = 'Приостановлено';
                buildingGroup.color = HEX_FROZEN;
                hasFrozenBuildings = true;
            } else if (buildingGroup.date) {
                const [ year, quarter ] = buildingGroup.date.split('.');

                buildingGroup.date = `${quarter} кв. ${year} г.`;
                buildingGroup.commissioningOption = {
                    value: `${Quarter[parseInt(quarter, 10)]}_${year}`,
                    label: `${quarter} кв. ${year}`
                };

                buildingGroup.color = getColor(i - colorShift);
            } else {
                buildingGroup.color = HEX_COMMISSIONED;
            }

            buildingGroup.buildings.forEach(building => {
                building.dateString = buildingGroup.date || building.info?.buildingStatus?.value;

                building.accredited = accreditedKey ? building[accreditedKey]! : true;
                building.clickable = building.accredited && ! building.isFrozen;
                building.hasAccreditedTick = accreditedKey ? building[accreditedKey]! : false;
                // eslint-disable-next-line no-nested-ternary
                building.accreditedString = accreditedKey && ! building.isFrozen ?
                    building[accreditedKey] ?
                        ', аккредитован' :
                        ', не аккредитован' :
                    '';

                building.soldOutString = building.isSoldOut ? ', всё продано' : '';
                building.soonString = building.isSoon ? ', скоро в продаже' : '';

                building.polygonCoords = building.polygons!.map(
                    polygon => polygon.map(
                        ([ latitude, longitude ]) => ({
                            latitude,
                            longitude
                        }) as PolygonCoordinate
                    )
                );

                const id = String(building.id);

                buildingById[id] = building;
                buildingNameById[id] = building.name ?? null;
            });

            if (! buildingGroup.date) {
                buildingGroup.date = buildingGroup.buildings.length === 1 ? 'Сдан' : 'Сданы';
                buildingGroup.commissioningOption = {
                    value: Commissioning.COMMISSIONED,
                    label: 'Сдан'
                };
            }

            if (buildingGroup.commissioningOption) {
                commissioningOptions.push(buildingGroup.commissioningOption);
                buildingIdsByCommissioning[buildingGroup.commissioningOption.value] = buildingGroup.buildings
                    .map(({ id }) => id) as number[];
            }
        });

        queryDisplayValues = buildingsData.reduce((res, building) => {
            res.buildings = {
                ...res.buildings,
                [building.id!]: {
                    id: building.id!,
                    title: `Корпус ${building.name || building.id!}`,
                    type: TipTypeEnum.Building
                }
            };

            return res;
        }, {} as QueryDisplayValues);
    }

    const defaultDateString = commissioningOptions.length &&
        commissioningOptions[commissioningOptions.length - 1]?.label ||
        undefined;

    return {
        buildingGroups,
        notAccreditedBuildingsTotal,
        hasFrozenBuildings,
        buildings,
        buildingById,
        buildingNameById,
        getBuildingIds: getBuildingIds.bind(null, buildingIdsByCommissioning),
        commissioningOptions,
        defaultDateString,
        queryDisplayValues
    };
}, [ buildingsData, accreditedKey ]);

export const makeOffersUpdateDatePostfix = (offersUpdateDate?: number | null) =>
    // Не показываем даты недельной и более давности
    offersUpdateDate && Date.now() / 1e3 - offersUpdateDate < 7 * 24 * 60 * 60 ?
        `на\xa0${
            formatDate(offersUpdateDate).shortDate
                .replace(/\s/g, '\xa0')
        }` :
        '';

export const useDeveloperNames = (developerCards: NewBuildingCard['developerCards']) => useMemo(
    () => (
        developerCards
            .map(({ developer }) => developer?.title)
            .filter(Boolean)
            .join(', ')
    ),
    [ developerCards ]
);
