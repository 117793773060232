import React from 'react';

import { Modal, Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

import { Document, DocumentType } from '../../../common/components/Document';

import './styles.css';

const cn = classname.bind(null, 'DocumentsModal');

interface IDocumentsModal {
    onClose?: () => void;
    title: string;
    documents: Array<DocumentType & { onClick?: () => void }>;
}

export const DocumentsModal = ({
    onClose,
    title: modalTitle,
    documents
}: IDocumentsModal) => {
    return (
        <Modal
            mobile
            opened
            swipeToClose={false}
            onClose={onClose}
            zIndex={1050}
        >
            <Modal.Header>Документы</Modal.Header>
            <Modal.Content scrollable>
                <Typography
                    variant='h3'
                    className={cn('title')}
                >
                    {modalTitle}
                </Typography>
                <div className={cn('content')}>
                    {documents.map(({ title, fileName, url, onClick }, idx) => (
                        <div
                            key={idx}
                            className={cn('item')}
                        >
                            <Document
                                title={title}
                                subtitle='Скачать'
                                url={url}
                                fileName={fileName}
                                onClick={onClick}
                            />
                        </div>
                    ))}
                </div>
            </Modal.Content>
        </Modal>
    );
};
