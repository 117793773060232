import { GroupedOptions, OptionType } from '@vtblife/uikit/legacy';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

export type GeoRootResponse = {
    readonly geoRootRegionDefault?: {
        id: number;
    } | null;
    readonly geoRootRegions: ReadonlyArray<{
        readonly rootRegion: {
            readonly id: number;
            readonly translit: string | null;
            readonly locative: string | null;
            readonly displayName: string | null;
            readonly bounds: {
                readonly min: {
                    readonly x: number;
                    readonly y: number;
                };
                readonly max: {
                    readonly x: number;
                    readonly y: number;
                };
            } | null;
        } | null;
        readonly regions: ReadonlyArray<{
            readonly id: number;
            narrowRegion?: { id: number } | null;
            readonly translit: string | null;
            readonly locative: string | null;
            readonly displayName: string | null;
            readonly bounds: {
                readonly min: {
                    readonly x: number;
                    readonly y: number;
                };
                readonly max: {
                    readonly x: number;
                    readonly y: number;
                };
            } | null;
        }> | null;
    }> | null;
};

export type IRegion = NonNullable<
    NonNullable<GeoRootResponse['geoRootRegions']>[number]['rootRegion']
> & {
    readonly translit?: string | null;
    parentId?: number;
    mainName?: string;
};

export interface OptionTypeRegionFilter extends OptionType<string> {
    fullName: string;
    isParent?: boolean;
    parentId?: string;
}
export type GroupRegionFilter = GroupedOptions<OptionTypeRegionFilter>;

const regionsDefault: IRegion[] = [
    {
        translit: 'moskva',
        locative: 'в\u00A0Москве',
        mainName: 'Москва',
        displayName: 'Москва',
        id: RegionIdEnum.MSK,
        bounds: {
            min: { x: 37.488248, y: 55.585993 },
            max: { x: 37.74574, y: 55.875427 }
        }
    },
    {
        translit: 'moskovskaya-oblast',
        locative: 'в\u00A0Московской области',
        mainName: 'Московская область',
        displayName: 'Московская область',
        id: RegionIdEnum.MSK_OBL,
        bounds: {
            min: { x: 35.710134, y: 55.124008 },
            max: { x: 39.341116, y: 56.287049 }
        }
    },
    {
        translit: 'moskva-i-oblast',
        locative: 'в\u00A0Москве и Московской области',
        mainName: 'Москва и Московская область',
        displayName: 'Москва и область',
        id: RegionIdEnum.MSK_AND_MSK_OBL,
        bounds: {
            min: { x: 35.710134, y: 55.124008 },
            max: { x: 39.341116, y: 56.287049 }
        }
    },
    {
        translit: 'sankt-peterburg',
        locative: 'в\u00A0Санкт-Петербурге',
        mainName: 'Санкт-Петербург',
        displayName: 'Санкт-Петербург',
        id: RegionIdEnum.SPB,
        bounds: {
            min: { x: 29.812084, y: 59.808254 },
            max: { x: 30.828319, y: 60.059460 }
        }
    },
    {
        translit: 'leningradskaya-oblast',
        locative: 'в\u00A0Ленинградской\u00A0области',
        mainName: 'Ленинградская область',
        displayName: 'Ленинградская область',
        id: RegionIdEnum.LEN_OBL,
        bounds: {
            min: { x: 29.812084, y: 59.808254 },
            max: { x: 30.828319, y: 60.059460 }
        }
    },
    {
        translit: 'sankt-peterburg-i-oblast',
        locative: 'в\u00A0Санкт-Петербурге и области',
        mainName: 'Санкт-Петербург и Ленинградская область',
        displayName: 'Санкт-Петербург и область',
        id: RegionIdEnum.SPB_AND_LEN_OBL,
        bounds: {
            min: { x: 29.812084, y: 59.808254 },
            max: { x: 30.828319, y: 60.059460 }
        }
    }
];
const rootRegionDefault: IRegion = {
    id: 0,
    displayName: '',
    locative: '',
    mainName: '',
    translit: '',
    bounds: null
};

export const geoRootRegionFallback = {
    rootRegion: rootRegionDefault,
    regions: regionsDefault
};
