import { graphql } from '@search/gql-client/src';

export const clGeoRootRegionQuery = graphql`query clGeoRootRegionQuery(
    $suggestedGeoId: ID!,
    $skipGeo: Boolean!,
    $appType: GeoRootRegionAppEnum!
) {
    geoRootRegionDefault(appType: $appType, suggestedGeoId: $suggestedGeoId) @skip(if: $skipGeo) {
        id
    }
    geoRootRegions(appType: $appType) {
        rootRegion {
            id
            translit
            locative
            displayName
            bounds {
                min {
                    x
                    y
                }
                max {
                    x
                    y
                }
            }
        }
        regions {
            id
            narrowRegion {
                id
            }
            translit
            locative
            displayName
            bounds {
                min {
                    x
                    y
                }
                max {
                    x
                    y
                }
            }
        }
    }
}
`;
