/* eslint-disable complexity */
import * as React from 'react';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import { Button, PlainSelect as Select, PlainSelectOption } from '@vtblife/uikit';
import { Paginator } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import Breadcrumb, { BreadcrumbSection } from '@search/vtbeco-ui/src/components/Breadcrumb';
import type { IDeveloperType } from '@search/nb-routes/src/DeveloperRoute';
import { Route } from '@search/router/src/Route';
import { errorCatcherWrap } from '@search/error/src/catcher';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { graphql, useGql } from '@search/gql-client/src/useGql';
import { RegionIdEnum, ROSSIYA } from '@search/filter-enums/enums/Region';

import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';

import { Typography } from '@vtblife/uikit';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import type { Shared } from '../../../../types/shared';
import type { ArrayElement } from '../../../../types';
import { DEVELOPER_ID } from '../../constants/developers';

import { GlobalContext, IGlobalContext } from '../GlobalContext';
import { SvgSpriteIcon } from '../SvgSpriteIcon';

import useBreadcrumbs from './useBreadcrumbs';

import type {
    DeveloperInfoQuery$data as DeveloperInfoQueryResponse,
    DeveloperInfoQuery$variables as DeveloperInfoQueryVariables,
    NewBuildingsInput
} from './__generated__/DeveloperInfoQuery.graphql';

import './styles.css';

const cn = classname.bind(null, 'DeveloperInfo');

const DEVELOPER_INFO_QUERY = graphql`
    fragment DeveloperInfoItemFields on InfoItem {
        weight
        label
        snippetLabel
        value
        snippetValue
        iconName
    }

    query DeveloperInfoQuery(
        $developerId: Int!
        $regionId: Int
        $paging: PagingInput!
        $filters: NewBuildingsInput!
        $whiteLabel: NewBuildingWhitelabelEnum
        $skipDeveloper: Boolean!
        $skipFavorites: Boolean!
    ) {
        newBuildingSeo(whiteLabel: $whiteLabel) {
            developerPage(developerId: $developerId regionId: $regionId) {
                title
                header
                breadcrumb
            }
        }
        developer(developerId: $developerId) @skip(if: $skipDeveloper) {
            developer {
                title
                translitTitle
                icon {
                    origin
                }
            }
            inProgressCount {
                fullLabel
                value
            }
            commissionedCount {
                fullLabel
                value
            }
            regions {
                id
                mainName
                translit
            }
        }
        searchNewBuildings(
            whiteLabel: $whiteLabel
            paging: $paging
            filters: $filters
        ) {
            items {
                __typename
                ... on NewBuildingSnippet {
                    id
                    title {
                        nominative
                    }
                    priceMin
                    isSoldOut
                    ranking
                    address
                    coordinates
                    mortgageMinPay {
                        minPay
                        variant
                        initialFee
                    }
                    mortgageParams {
                        regionId
                        isMainRegion
                        propertyCost
                        purpose
                        purposeValue
                        claimType
                        ownAmount
                        term
                    }
                    buildingClass {
                        label
                    }
                    hasEscrow
                    buildingsAccreditedByVtb
                    buildingStatus {
                        title
                        isFrozen
                    }
                    gallery {
                        main {
                            galleryThumbnail
                            originPath
                        }
                        images {
                            galleryThumbnail
                            originPath
                        }
                    }
                    description
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                            timeMinutes
                            transportType
                        }
                    }
                    flats {
                        rooms
                        labelShort
                        label(type: SNIPPET)
                        isSoldOut
                        isSoon
                        isTemporaryNotOnSale
                        formatAreaRange
                        formatPriceRange
                        formatPriceRangeShort
                    }
                    developerCards {
                        developer {
                            id
                            translitTitle
                            title
                            icon {
                                origin
                            }
                        }
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                    hasPreferentialMortgage
                    specialEvents {
                        type
                        title
                        titleRaw
                        description {
                            short
                        }
                    }
                    info {
                        commissioningDate {
                            ...DeveloperInfoItemFields
                        }
                        buildingClass {
                            ...DeveloperInfoItemFields
                        }
                        totalCount {
                            ...DeveloperInfoItemFields
                        }
                        apartments {
                            ...DeveloperInfoItemFields
                        }
                    }
                    isFavorite @skip(if: $skipFavorites)
                    isMskMO
                    isSPbLO
                    isSpecProjectRegion
                    hasChat
                    noOffers
                    m2Pro {
                        prepayment
                        cooperationTerms {
                            title
                            fileName
                            url
                        }
                        nbPresentation {
                            title
                            fileName
                            url
                        }
                    }
                }
            }
            paging {
                total
                pageNumber
                pageSize
                hasNext
            }
            rating
            reviewsCount
        }
    }
`;

type ExtractSnippet<A> = A extends { __typename: 'NewBuildingSnippet' } ? A : never

type NewBuildingSearchSnippet = ExtractSnippet<NonNullable<
    ArrayElement<NonNullable<NonNullable<DeveloperInfoQueryResponse['searchNewBuildings']>['items']>>
    >>;

type DeveloperPageType = {
    params: IDeveloperType;
    route: Route<IDeveloperType, Shared.IRouterContext, {}>;
    renderSnippet: (item: NewBuildingSearchSnippet, idx: number) => React.ReactNode;
};

const renderBreadcrumps = (breadcrumbs: readonly BreadcrumbSection[]) => (
    <div className={cn('breadcrumbs')}>
        <Breadcrumb sections={breadcrumbs} withoutSeoMarkup />
    </div>
);

const DEFAULT_PAGE_SIZE = 10;

export const useDeveloperInfo = ({
    params,
    filters
}: {
    params: IDeveloperType;
    filters: NewBuildingsInput;
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { instanceConfig: { searchIndex, hasFavoritesButton } } = globalContext;

    const pageSize = useMemo(() => {
        if (searchIndex === NewBuildingWhitelabelEnum.Vtb) {
            return DEFAULT_PAGE_SIZE;
        }

        switch (params.id) {
            case DEVELOPER_ID.PIK:
                if (! params.region) {
                    return 20;
                }

                if (params.region === RegionIdEnum.MSK) {
                    return 30;
                }

                return DEFAULT_PAGE_SIZE;

            case DEVELOPER_ID.SAMOLET:
                if (! params.region) {
                    return 20;
                }

                return DEFAULT_PAGE_SIZE;

            default:
                return DEFAULT_PAGE_SIZE;
        }
    }, [ params.id, params.region, searchIndex ]);

    const res = useGql<DeveloperInfoQueryVariables, DeveloperInfoQueryResponse>(DEVELOPER_INFO_QUERY, {
        skipDeveloper: false,
        skipFavorites: ! hasFavoritesButton,
        whiteLabel: searchIndex,
        regionId: params.region,
        developerId: params.id,
        paging: {
            pageSize,
            pageNumber: params.pageNumber || 1
        },
        filters
    });

    return {
        ...res,
        pageSize
    };
};

export const DeveloperInfo: FunctionComponent<DeveloperPageType> = ({
    params,
    route,
    renderSnippet
}) => {
    const fetchMoreRef = useRef(false);
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        isMobile,
        instanceConfig: { hasBreadcrumbs }
    } = globalContext;
    const filters = useMemo(() => ({
        regionId: params.region ? Number(params.region) : ROSSIYA,
        developerId: params.id,
        closedSales: true
    }), [ params.id, params.region ]);

    const {
        data,
        errors,
        loading,
        fetchMore,
        pageSize
    } = useDeveloperInfo({
        params,
        filters
    });

    if (! data?.developer) {
        throw errors;
    }

    useEffect(() => {
        document.title = data?.newBuildingSeo?.developerPage?.title || document.title;
    }, [ data ]);

    const regionOptions: PlainSelectOption[] = useMemo(
        () => (data?.developer?.regions ?? [])
            .reduce((ret, item) => {
                if (item && item.id && item.mainName) {
                    ret.push({
                        value: String(item.id),
                        text: item.mainName
                    });
                }

                return ret;
            }, [ {
                value: String(ROSSIYA),
                text: 'Любой регион'
            } ] as PlainSelectOption[]),
        [ data ]
    );

    const handleRegionChange = useCallback((value: React.ReactText) => {
        route.push({
            id: params.id,
            region: value === String(ROSSIYA) ? undefined : Number(value),
            name: data!.developer!.developer.translitTitle
        });
    }, [ route, params.id, data ]);

    const developerStat = [];

    if (data?.developer?.commissionedCount) {
        developerStat.push(
            <ListItem key='commissionedCount'>
                <SvgSpriteIcon name='Commissioned' />
                <span className={cn('commissionedCount')}>
                    {`${data.developer.commissionedCount.value} ${data.developer.commissionedCount.fullLabel}`}
                </span>
            </ListItem>
        );
    }

    if (data?.developer?.inProgressCount) {
        developerStat.push(
            <ListItem key='inProgressCount'>
                <SvgSpriteIcon name='InProgress' />
                <span className={cn('inProgressCount')}>
                    {`${data.developer.inProgressCount.value} ${data.developer.inProgressCount.fullLabel}`}
                </span>
            </ListItem>
        );
    }

    const header = data?.newBuildingSeo.developerPage?.header;

    // Затычка для «показать еще»
    const pageNumber = (params && params.pageNumber || 0) >= (data?.searchNewBuildings?.paging?.pageNumber || 0) ?
        params.pageNumber : data?.searchNewBuildings?.paging?.pageNumber ?? 1;
    const hasNextPage = data?.searchNewBuildings?.paging?.total &&
        data?.searchNewBuildings?.paging?.total > (data?.searchNewBuildings?.paging?.pageSize || 0);

    const breadcrumbs = useBreadcrumbs({
        hasBreadcrumbs,
        activeText: data?.newBuildingSeo?.developerPage?.breadcrumb,
        route,
        pageParams: params
    })?.items;

    return (
        <div className={cn(null, {
            isMobile,
            hasBreadcrumbs: Boolean(breadcrumbs)
        })}>
            <div className={cn('section', { developer: true })}>
                {breadcrumbs && ! isMobile ? renderBreadcrumps(breadcrumbs) : null}
                <div className={cn('developerHeader')}>
                    <Typography
                        className={cn('developerName')}
                        variant={isMobile ? 'h3' : 'h1'}
                        tag='h1'
                    >
                        {header}
                    </Typography>
                    {
                        data?.developer?.developer?.icon?.origin ? (
                            <Image
                                className={cn('developerLogo')}
                                url={data.developer.developer.icon.origin}
                            />
                        ) : (
                            <SvgSpriteIcon
                                name='CustomDeveloper'
                                className={cn('developerLogo')}
                                width={40}
                                height={40}
                            />
                        )
                    }
                </div>
            </div>
            <div className={cn('section', { newBuildings: true })}>
                <List className={cn('list')}>
                    <ListItem key='region'>
                        <Select
                            native={isMobile}
                            size={isMobile ? 's' : 'm'}
                            label={isMobile ? 'Регион' : ''}
                            options={regionOptions}
                            block={isMobile}
                            onChange={handleRegionChange}
                            value={String(params.region ?? ROSSIYA)} />
                    </ListItem>
                    {developerStat}
                    <ListItem key='desc'>
                        <span className={cn('desc')}>Объекты застройки включают все регионы</span>
                    </ListItem>
                </List>
                {
                    data?.searchNewBuildings?.items?.map((item, idx) => {
                        switch (item?.__typename) {
                            case 'NewBuildingSnippet':
                                return renderSnippet(item, idx);
                            default:
                                return null;
                        }
                    })
                }
                {
                    hasNextPage &&
                    (
                        <div className={cn('footer')}>
                            {
                                data?.searchNewBuildings?.paging?.hasNext ? (
                                    <Button
                                        disabled={loading}
                                        size='m'
                                        fullWidth={isMobile}
                                        variant='secondary'
                                        onClick={() => {
                                            fetchMoreRef.current = true;

                                            fetchMore({
                                                variables: {
                                                    skipDeveloper: true,
                                                    paging: {
                                                        pageSize,
                                                        pageNumber: (pageNumber || 0) + 1
                                                    }
                                                },
                                                onUpdate: ({ prevData, nextData }) => {
                                                    fetchMoreRef.current = false;

                                                    return {
                                                        ...prevData,
                                                        searchNewBuildings: {
                                                            ...prevData?.searchNewBuildings,
                                                            paging: {
                                                                total: nextData?.searchNewBuildings?.paging?.total ?? null,
                                                                hasNext: nextData?.searchNewBuildings?.paging?.hasNext ?? null,
                                                                pageNumber: nextData?.searchNewBuildings?.paging?.pageNumber ?? null,
                                                                pageSize: nextData?.searchNewBuildings?.paging?.pageSize ?? null
                                                            },
                                                            items: [
                                                                ...(prevData?.searchNewBuildings?.items || []),
                                                                ...(nextData?.searchNewBuildings?.items || [])
                                                            ]
                                                        }
                                                    };
                                                }
                                            });
                                        }}>
                                        Показать еще
                                    </Button>
                                ) : null
                            }
                            {
                                isMobile ? null : (
                                    <Paginator
                                        onChange={(page, event) => {
                                            event?.preventDefault();

                                            window.scrollTo(0, 0);

                                            route.push({ ...params, pageNumber: page });
                                        }}
                                        page={pageNumber ?? undefined}
                                        itemsPerPage={pageSize}
                                        buildPageUrl={page => route.url({ ...params, pageNumber: page })}
                                        totalItems={data?.searchNewBuildings?.paging?.total ?? 0}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <Typography
                    variant='h3'
                    className={cn('developerName')}
                >
                    {header}
                </Typography>
                {breadcrumbs && isMobile ? renderBreadcrumps(breadcrumbs) : null}
            </div>
        </div>
    );
};

DeveloperInfo.displayName = 'DeveloperInfo';

export const DeveloperInfoWithCatcher = errorCatcherWrap(DeveloperInfo);
