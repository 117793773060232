/* eslint-env browser */
import { useEffect, RefObject, PointerEventHandler } from 'react';

export function useOnClickOutside(
    refs: RefObject<HTMLElement | undefined>[],
    handler: PointerEventHandler,
    flag: boolean = true
) {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const listener = (event: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (refs && refs.some(item => ! item.current || item.current.contains(event.target))) {
                return;
            }

            handler(event);
        };

        if (flag) {
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
        } else {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ refs, handler, flag ]);
}
