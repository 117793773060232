import React, { FC, useCallback } from 'react';

import { Tooltip, Button, ButtonProps, useTooltip } from '@vtblife/uikit';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';

import { useChat } from '../../hooks/useChat';

const ChatButton: FC<ButtonProps & {
    hasText?: boolean;
    hasIcon?: boolean;
    className?: string;
    onDarkBackground?: boolean;
}> = ({
    size = 's',
    hasText = false,
    hasIcon = true,
    className,
    onDarkBackground = false,
    ...rest
}) => {
    const { to, registerControl } = useTooltip();

    const { openChat } = useChat();

    const handleClick = useCallback(() => {
        openChat();
        dataLayerPush({ event: 'open_chat' });
    }, [ openChat ]);

    return (
        <div
            className={className}
            ref={hasText ? undefined : registerControl}
        >
            <Button
                size={size}
                variant={onDarkBackground ? 'secondary-alt-bg' : 'secondary'}
                icon={hasIcon ? 'm2-chat' : undefined}
                onClick={handleClick}
                {...rest}
            >
                {hasText ? 'Написать в чат' : ''}
            </Button>
            {hasText ? null : (
                <Tooltip
                    direction='down'
                    to={to}
                >
                    Написать в чат
                </Tooltip>
            )}
        </div>
    );
};

export default ChatButton;
