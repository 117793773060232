import React, { useCallback, useContext, useState } from 'react';
import { Dropdown, LinkButton, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { formatRange } from '@search/helpers/src/formatNumber';
import {
    Developer,
    DeveloperCard,
    NewBuildingSnippet as GQLNewBuildingSnippet,
    NewBuildingWhitelabelEnum
} from '@search/graphql-typings';
import {
    MinimalBanksMortgageSnippetDesktop,
    MortgageModal
} from '@search/vtbeco-frontend-core/view/desktop/components/MinimalBanksMortgageSnippetDesktop';
import { DATA_GTM, VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import Tooltip from '@search/vtbeco-ui/src/components/controls/Tooltip';
import { ToneEnum, ViewEnum } from '@search/vtbeco-ui/src/types';
import { IUseToggleValueReturnType } from '@search/hooks/src/useToggleValue';
import SnippetPhone from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/SnippetPhone';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import { vtbMortageDisclaimer } from '../../../../dict/mortgage';
import { SvgSpriteIcon } from '../../../common/components/SvgSpriteIcon';
import { GlobalContext, IOnChangeGlobalContext, OnChangeGlobalContext } from '../../../common/components/GlobalContext';
import ChatButton from '../../../common/components/ChatButton';
import FavoritesButton from '../../../common/components/FavoritesButton';
import { useFavoriteNewBuildingButton } from '../../../common/components/FavoritesButton/useFavoriteNewBuildingButton';
import { defineCallbackEmailSender } from '../../../common/libs/defineEmailSender';
import { useDeveloperNames } from '../../../common/components/NewBuildingCard';
import { ChatStateProvider } from '../../../common/components/NewBuildingCard/ChatStateProvider';
import RewardAmountIcon from '../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/RewardAmountIcon';
import { M2ProOrderButton } from '../../../common/components/M2ProOrderButton';
import { M2ProAttachedFilesButton } from '../../../common/components/M2ProAttachedFilesButton';
import { useM2ProFiles } from '../../../common/hooks/useM2ProFiles';
import useMortgageDemandLink from '../../../common/hooks/useMortgageDemandLink';
import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../../../common/constants/mortgage';

import M2ProSnippetMortgage from '../M2Pro/M2ProSnippetMortgage';

import './RightBlock.css';

const cn = classname.bind(null, 'NewBuildingSerpSnippetRightBlock');

const renderVtbMortgage = ({
    hasApartments,
    buildingsAccreditedByVtb
}: {
    hasApartments: GQLNewBuildingSnippet['hasApartments'];
    buildingsAccreditedByVtb: GQLNewBuildingSnippet['buildingsAccreditedByVtb'];
}) => (
    <>
        <Typography className={cn('vtb-mortgage-min-pay')}>
            Ипотека от {hasApartments ? VTB_MORTGAGE_RATE_APARTS : VTB_MORTGAGE_RATE_FLATS}%
            <Tooltip
                hasTail
                tone={ToneEnum.DARK}
                view={ViewEnum.PRIMARY}
                renderTrigger={({ triggerRef, handleShow, handleClose, isVisible }) =>
                    <span
                        style={{
                            color: isVisible ? 'black' : 'grey',
                            cursor: 'pointer',
                            position: 'relative'
                        }}
                        ref={triggerRef}
                        onMouseLeave={handleClose}
                        onMouseEnter={handleShow}
                        className={cn('vtbQuestionCircle')}
                    >
                        &nbsp;
                        <SvgSpriteIcon
                            name='VtbQuestionCircle'
                            width={16}
                            height={16}
                        />
                    </span>
                }
                renderContent={() => (
                    <Typography
                        variant='secondary-alone'
                        className={cn('vtb-mortgage-tooltip-content')}
                    >
                        {vtbMortageDisclaimer[hasApartments ? 'apartments' : 'flats']}
                    </Typography>
                )}
            />
        </Typography>
        <LinkButton
            size='s'
            href={`${VTB_IPOTEKA_LINK}?from=serp`}
            target='blank'
            variant='secondary'
            fullWidth
        >
            Оформить заявку
        </LinkButton>

        <div className={cn('paymentsInfoItem')}>
            <Dropdown>
                <Dropdown.Control arrow>
                    <Typography
                        variant='primary-alone'
                    >
                        Аккредитован
                    </Typography>
                </Dropdown.Control>
                <Dropdown.Content>
                    <div className={cn('buildings')}>
                        <Typography
                            color='minor'
                            variant='secondary-alone'
                        >
                            Аккредитованные корпуса
                        </Typography>
                        <Typography variant='secondary-alone'>
                            {buildingsAccreditedByVtb && buildingsAccreditedByVtb.join(', ')}
                        </Typography>
                    </div>
                </Dropdown.Content>
            </Dropdown>
        </div>
    </>
);

const MortgageBlock = ({
    showRegionMortgageButtons,
    isVtb,
    newBuilding,
    url,
    phoneHoverToggle,
    hasM2Pro,
    hasRoleM2Pro
} : {
    showRegionMortgageButtons: boolean;
    isVtb: boolean;
    newBuilding: GQLNewBuildingSnippet;
    url: string;
    phoneHoverToggle: IUseToggleValueReturnType;
    hasM2Pro: boolean;
    hasRoleM2Pro: boolean;
}) => {
    const hasPrice = Boolean(newBuilding.priceMin) || Boolean(newBuilding.hasEscrow);

    if (isVtb) {
        return (
            <div
                className={cn('mortgageText')}
                onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
            >
                {renderVtbMortgage({
                    hasApartments: newBuilding.hasApartments,
                    buildingsAccreditedByVtb: newBuilding.buildingsAccreditedByVtb
                })}
            </div>
        );
    }

    if (showRegionMortgageButtons && ! hasM2Pro) {
        /* Текст "Ипотека — быстро и просто!" */
        return (
            <div className={cn('regionText', { noPrice: ! hasPrice })}>
                <Typography variant='primary-alone' bold>
                    Ипотека — быстро и&nbsp;просто!
                </Typography>
                <Typography variant='secondary-alone'>
                    Получите предложения по&nbsp;ипотеке от&nbsp;банков в&nbsp;течение минуты.
                </Typography>
            </div>
        );
    }

    if (hasRoleM2Pro) {
        /* Ссылка "Оформить ипотеку" */
        return (
            <div
                className={cn('mortgageText')}
                onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
            >
                <M2ProSnippetMortgage
                    regionId={newBuilding.mortgageParams.regionId}
                    propertyCost={newBuilding.mortgageParams.propertyCost}
                    purposeValue={newBuilding.mortgageParams.purposeValue}
                    claimType={newBuilding.mortgageParams.claimType}
                    ownAmount={newBuilding.mortgageParams.ownAmount}
                    newBuildingName={newBuilding.title.nominative}
                />
            </div>
        );
    }

    return (
        /* Текст-кнопка "Рассчитать ипотеку" */
        <div
            className={cn('mortgageText', { noPrice: ! hasPrice })}
            onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
            onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
        >
            <MinimalBanksMortgageSnippetDesktop
                mortgageRegionId={newBuilding.mortgageParams.regionId}
                propertyCost={newBuilding.mortgageParams.propertyCost}
                ownAmount={newBuilding.mortgageParams.ownAmount}
                term={newBuilding.mortgageParams.term}
                gtm={DATA_GTM.MORTGAGE_ON_SNIPPET}
                url={url}
                isNewBuilding
            />
        </div>
    );
};

interface IRightBlock {
    showRegionMortgageButtons: boolean;
    newBuilding: GQLNewBuildingSnippet;
    url: string;
    phoneHoverToggle: IUseToggleValueReturnType;
    hoverToggle: IUseToggleValueReturnType;
    developer?: Developer | null;
    handlePhoneEventPush: () => void;
    clearSerpCache?: (refresh?: boolean) => void;
}

// eslint-disable-next-line complexity
const RightBlock: React.FunctionComponent<IRightBlock> = ({
    showRegionMortgageButtons,
    newBuilding,
    url,
    phoneHoverToggle,
    hoverToggle,
    developer,
    handlePhoneEventPush,
    clearSerpCache
}) => {
    const {
        instanceConfig: {
            searchIndex,
            hasFavoritesButton,
            calltouchUnitId
        },
        calltouchModId
    } = useContext(GlobalContext);

    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const { auth } = useM2AuthContext();

    const rewardAmount = null;
    const { prepayment, cooperationTerms, nbPresentation } = newBuilding.m2Pro ?? {};
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);
    const hasM2Pro = hasRoleM2Pro && Boolean(rewardAmount);

    const m2ProFiles = useM2ProFiles({ cooperationTerms, nbPresentation });

    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const developerLink = developer && onChangeGlobalContext.linkBuilder.developer &&
        onChangeGlobalContext.linkBuilder.developer({
            id: developer.id,
            name: developer.translitTitle
        });

    const favoritesButtonProps = useFavoriteNewBuildingButton({
        newBuildingId: newBuilding.id,
        isFavorite: newBuilding.isFavorite,
        onSuccess: clearSerpCache
    });

    const developerNames = useDeveloperNames(newBuilding.developerCards as DeveloperCard[]);

    const { ranking } = newBuilding;
    const onStatEventPush = useCallback(() => dataLayerPush({
        event: 'nb_call_like',
        nb_price: newBuilding.priceMin ?? 0,
        nb_class: ranking ?? ''
    }), [ newBuilding.priceMin, ranking ]);

    const renderCallBack = useCallback((
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => (
        <CallbackModal
            btnWrapperClass={cn('callBack')}
            newBuildingName={newBuilding.title.nominative}
            address={newBuilding.address}
            emailSender={defineCallbackEmailSender(newBuilding.isMskMO, newBuilding.isSPbLO)}
            developerName={developerNames}
            extra={extra}
            subjectExtra={subjectExtra}
            gtmSendClick={sendDataGTM}
            onSendEventPush={onStatEventPush}
            gtmShowModal={dataGTM}
            callbackUnitId={calltouchUnitId[newBuilding.isSPbLO ? 'Spb' : 'Msk']}
            withoutIconOnButton
            buttonSize='s'
        />
    ), [ newBuilding.title.nominative, newBuilding.address, newBuilding.isMskMO, newBuilding.isSPbLO, developerNames, onStatEventPush, calltouchUnitId ]);

    const [ isModalOpen, toggleModal ] = useState<boolean>(false);
    const openModal = useCallback(() => toggleModal(true), []);
    const closeModal = useCallback(() => toggleModal(false), []);

    const mortgageDemandLink = useMortgageDemandLink({
        regionId: newBuilding.mortgageParams.regionId,
        propertyCost: newBuilding.mortgageParams.propertyCost,
        purpose: newBuilding.mortgageParams.purposeValue,
        claimType: newBuilding.mortgageParams.claimType,
        ownAmount: newBuilding.mortgageParams.ownAmount,
        newBuildingName: newBuilding.title.nominative
    });

    const renderButtons = useCallback(() => {
        if (hasM2Pro) {
            return (
                <div className={cn('m2ProButtons')}>
                    <div
                        onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                        onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                    >
                        <M2ProOrderButton
                            newBuildingId={newBuilding.id}
                            fullWidth
                            size='s'
                            variant={
                                hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                    'primary' :
                                    'secondary'
                            }
                            eventData={{ page_type: 'serp' }}
                        />
                    </div>
                    <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                        <ChatButton
                            hasText
                            fullWidth
                            hasIcon={false}
                        />
                    </ChatStateProvider>
                </div>
            );
        }

        if (showRegionMortgageButtons) {
            return (
                <>
                    <div
                        onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                        onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                        className={cn('regionMortgageButton')}
                    >
                        <LinkButton
                            size='s'
                            variant={
                                hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                    'accent' :
                                    'secondary'
                            }
                            onClick={hasRoleM2Pro ? undefined : openModal}
                            href={hasRoleM2Pro ? mortgageDemandLink : undefined}
                        >
                            Рассчитать ипотеку
                        </LinkButton>
                    </div>
                    {
                        isModalOpen && url ? (
                            <MortgageModal
                                mortgageRegionId={newBuilding.mortgageParams.regionId}
                                propertyCost={newBuilding.mortgageParams.propertyCost}
                                ownAmount={newBuilding.mortgageParams.ownAmount}
                                term={newBuilding.mortgageParams.term}
                                url={url}
                                isNewBuilding
                                onClose={closeModal}
                            />
                        ) : null
                    }
                </>
            );
        }

        return (
            <>
                {developer && (
                    <SnippetPhone
                        phoneParams={{
                            phoneNum: 0,
                            developerId: developer.id,
                            newBuildingId: Number(newBuilding.id),
                            whitelabel: searchIndex
                        }}
                        onPhoneEventPush={handlePhoneEventPush}
                        render={({ phone, handleClick, href, loading }) => (
                            <div
                                className={cn('phone')}
                                onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                                onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                            >
                                <LinkButton
                                    size='s'
                                    onClick={() => handleClick()}
                                    href={href}
                                    variant={
                                        hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                            'primary' :
                                            'secondary'
                                    }
                                    loading={loading}
                                >
                                    {phone}
                                </LinkButton>
                            </div>
                        )}
                        calltouchId={calltouchModId}
                    />
                )}
                {! isVtb && (
                    <div className={cn('callbackAndChat')}>
                        <div className={cn('callback', { hasChat: newBuilding.hasChat })}>
                            {(! newBuilding.isSpecProjectRegion || newBuilding.isMskMO || newBuilding.isSPbLO) && renderCallBack(
                                DATA_GTM.CARD_CALL_BACK_BUILDING,
                                DATA_GTM.CARD_CALL_BACK_SEND_BUILDING
                            )}
                        </div>
                        {newBuilding.hasChat ? (
                            <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                                <ChatButton className={cn('openChat')} />
                            </ChatStateProvider>
                        ) : null}
                    </div>
                )}
            </>
        );
    }, [
        hasM2Pro,
        showRegionMortgageButtons,
        developer,
        newBuilding.id,
        newBuilding.hasChat,
        newBuilding.isSpecProjectRegion,
        newBuilding.title.accusative,
        newBuilding.mortgageParams.regionId,
        newBuilding.mortgageParams.propertyCost,
        newBuilding.mortgageParams.ownAmount,
        newBuilding.mortgageParams.term,
        newBuilding.isMskMO,
        newBuilding.isSPbLO,
        searchIndex,
        handlePhoneEventPush,
        calltouchModId,
        isVtb,
        renderCallBack,
        phoneHoverToggle.handleSetTrueTogVal,
        phoneHoverToggle.handleSetFalseTogVal,
        phoneHoverToggle.isToggleValue,
        hoverToggle.isToggleValue,
        hasRoleM2Pro,
        openModal,
        mortgageDemandLink,
        isModalOpen,
        url,
        closeModal
    ]);

    return (
        <div className={cn()}>
            <div className={cn('content', { vtb: isVtb })}>
                <div className={cn('contentBlock')}>
                    {newBuilding.priceMin && (
                        <Typography variant='h3'>
                            {formatRange(newBuilding.priceMin, null, {
                                billionsAsString: true
                            })} ₽
                        </Typography>
                    )}
                    {hasM2Pro && (
                        <div className={cn('m2Pro', { noPrice: ! newBuilding.priceMin })}>
                            {rewardAmount && (
                                <RewardAmountIcon amount={rewardAmount} />
                            )}
                            {prepayment && (
                                <SvgSpriteIcon
                                    name='M2ProPrepayment'
                                    width={24}
                                    height={24}
                                    className={cn('m2ProPrepayment')}
                                />
                            )}
                        </div>
                    )}
                    {hasM2Pro && m2ProFiles.length > 0 && (
                        <div className={cn('m2ProAttachedFilesButton')}>
                            <M2ProAttachedFilesButton
                                view='text'
                                files={m2ProFiles}
                                eventData={{ page_type: 'serp' }}
                            />
                        </div>
                    )}
                    {
                        ! isVtb && ! hasM2Pro && newBuilding.hasEscrow && (
                            <div className={cn('escrow', { noPrice: ! newBuilding.priceMin })}>
                                <SvgSpriteIcon
                                    name='TickNew'
                                    width={16}
                                    height={16}
                                />
                                <Typography
                                    tag='span'
                                    className={cn('escrowText')}
                                    variant='primary-alone'
                                    color='black-400'
                                >
                                    Эскроу счет
                                </Typography>
                            </div>
                        )
                    }
                    <MortgageBlock
                        showRegionMortgageButtons={showRegionMortgageButtons}
                        isVtb={isVtb}
                        newBuilding={newBuilding}
                        url={url}
                        phoneHoverToggle={phoneHoverToggle}
                        hasM2Pro={hasM2Pro}
                        hasRoleM2Pro={hasRoleM2Pro}
                    />
                    {
                        isVtb && newBuilding.hasEscrow && (
                            <Typography
                                tag='span'
                                variant='primary-alone'
                            >
                                Эскроу счет
                            </Typography>
                        )
                    }
                </div>
                <div className={cn('contentBlock')}>
                    {developer && developerLink && (
                        <a
                            target='_blank'
                            className={cn('developer')}
                            href={developerLink}
                            onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                            onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                        >
                            <Typography
                                tag='div'
                                className={cn('developerName')}
                                variant='primary-alone'
                                color='primary'
                            >
                                {developer.title}
                            </Typography>
                            <div className={cn('developerIcon')}>
                                {
                                    developer.icon?.origin ? (
                                        <Image
                                            type='contain'
                                            width={40}
                                            height={40}
                                            url={developer.icon.origin}
                                        />
                                    ) : (
                                        <SvgSpriteIcon
                                            name='CustomDeveloper'
                                            width={40}
                                            height={40}
                                        />
                                    )
                                }
                            </div>
                        </a>
                    )}
                    {renderButtons()}
                </div>
            </div>
            {
                hasFavoritesButton ? (
                    <FavoritesButton
                        {...favoritesButtonProps}
                        className={cn('favorites')}
                    />
                ) : null
            }
        </div>
    );
};

export default RightBlock;
