import { useMemo } from 'react';

import { regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';
import { NewBuildingSortEnum } from '@search/graphql-typings';

import { SORT_OPTIONS } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';
import { Filters, RegionFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import { MetroStore } from '../../libs/geo-store';

export const useFilterSortOptions = (filters: FilterCollection, metro: MetroStore) => {
    const regionId = (filters.get<RegionFilter>(Filters.region)).region;
    const hasMetro = useMemo(
        () => regionsWithMetroEnumeration.has(regionId) && ! metro.isEmpty(),
        [ regionId, metro ]
    );

    return useMemo(
        () => hasMetro ?
            SORT_OPTIONS :
            SORT_OPTIONS.filter(option => option.value !== NewBuildingSortEnum.TimeToMetroAsc),
        [ hasMetro ]
    );
};
