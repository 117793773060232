import { useMemo } from 'react';
import { Event, Place, PostalAddress, GeoCoordinates, PerformingGroup, Organization, WithContext } from 'schema-dts';

import { NewBuildingCardPageSeoQuery$data as NewBuildingCardPageSeoQueryResponse } from './__generated__/NewBuildingCardPageSeoQuery.graphql';

type NewBuildingType = NewBuildingCardPageSeoQueryResponse['newBuilding'];

interface IUseEventsMeta {
    newBuildingTitle?: string;
    newBuildingAddress?: NewBuildingType['address'];
    newBuildingCoordinates: NewBuildingType['coordinates'];
    newBuildingImgUrl?: string;
    url: string;
    specialEvents: NewBuildingType['specialEvents'];
}

const EVENT_NAME_PREFIX = '🔔 ';

export const useEventsMeta = ({
    newBuildingTitle,
    newBuildingAddress,
    newBuildingCoordinates,
    newBuildingImgUrl,
    url,
    specialEvents
}: IUseEventsMeta): Array<string> => useMemo(() => specialEvents.map(event => {
    const address: PostalAddress = {
        '@type': 'PostalAddress',
        addressLocality: newBuildingAddress
    };

    const geo: GeoCoordinates = {
        '@type': 'GeoCoordinates',
        latitude: newBuildingCoordinates[0],
        longitude: newBuildingCoordinates[1]
    };

    const location: Place = {
        '@type': 'Place',
        name: newBuildingTitle,
        address,
        geo
    };

    const performer: PerformingGroup = {
        '@type': 'PerformingGroup',
        name: newBuildingTitle
    };

    const organizer: Organization = {
        '@type': 'Organization',
        name: 'Метр Квадратный — экосистема недвижимости',
        url: 'https://m2.ru'
    };

    const eventMeta: WithContext<Event> = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: `${EVENT_NAME_PREFIX}${event.title}`,
        description: event.description.short,
        startDate: event.metaStartDate,
        endDate: event.metaEndDate,
        image: newBuildingImgUrl,
        url,
        location,
        performer,
        organizer
    };

    return JSON.stringify(eventMeta);
}), [
    newBuildingTitle,
    newBuildingAddress,
    newBuildingCoordinates,
    newBuildingImgUrl,
    url,
    specialEvents
]);
