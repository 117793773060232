export const roomsDict: Record<string, string> = {
    STUDIO: 'Студии',
    ROOM_1: '1-комнатные',
    ROOM_2: '2-комнатные',
    ROOM_3: '3-комнатные',
    ROOM_4: '4-комнатные',
    ROOM_5_AND_MORE: '5 и более комнат',
    OPEN_PLAN: 'Со свободной планировкой'
};

export const finishingDict: Record<string, string> = {
    AVAILABLE: 'С отделкой',
    NOT_AVAILABLE: 'Без отделки',
    NOT_RELEVANT: ''
};

export const finishingTypeDict: Record<string, string> = {
    FINE: 'С чистовой отделкой',
    WITH_FURNITURE: 'С мебелью',
    WITHOUT_FINISHING: 'Без отделки',
};

export const buildingClassDict: Record<string, string> = {
    ECONOMY: 'Эконом-класса',
    COMFORT: 'Комфорт-класса',
    COMFORT_PLUS: 'Класса комфорт+',
    BUSINESS: 'Бизнес-класса',
    ELITE: 'Элитные'
};

export const commissioningDict: Record<string, string> = {
    COMMISSIONED: 'Срок сдачи: сдан',
    IN_PROGRESS: 'Срок сдачи: строится'
};

export const wallsTypeDict: Record<string, string> = {
    MONOLITH: 'Монолитные',
    BRICK_MONOLITH: 'Кирпично-монолитные',
    BRICK: 'Кирпичные',
    PANEL: 'Панельные',
    BLOCK: 'Блочные'
};

export const floorLastDict: Record<string, string> = {
    ONLY: 'На последнем этаже',
    EXCLUDE: 'Не на последнем этаже'
};

export const commissioningQuarterDict: Record<string, string> = {
    FIRST: '1',
    SECOND: '2',
    THIRD: '3',
    FOURTH: '4'
};

export const parkingDict: Record<string, string> = {
    UNDERGROUND: 'С подземной парковкой',
    AVAILABLE: 'С парковкой'
};

export const sellConditionDict: Record<string, string> = {
    armyMortgage: 'По военной ипотеке',
    escrow: 'Сделка по эскроу-счёту',
    installment: 'В рассрочку',
    law214: 'Сделка по 214-ФЗ'
};

export const securityDict: Record<string, string> = {
    closedArea: 'С закрытой территорией',
    concierge: 'С консьержем'
};

export const apartmentsDict: Record<string, string> = {
    APARTMENTS_ONLY: 'Апартаменты',
    NO_APARTMENTS: ''
};

export const euroPlanningDict: Record<string, string> = {
    ROOM_1: 'Евродвушки',
    ROOM_2: 'Евротрёшки'
};
