export const HEX_COMMISSIONED = '#00AAFF';
export const HEX_FROZEN = '#1F1F2E';

const HEX = [
    '#C224FA',
    '#EC131B',
    '#F9AF1F',
    '#F9E31F',
    '#53E165',
    '#53D8E1',
    '#6030E8',
    '#2644D9',
    '#66717F'
];

const RGB = HEX.map(function(color): [ number, number, number ] {
    const val = parseInt(color.replace('#', ''), 16);

    return [
        val >> 16,
        val >> 8 & 0x00FF,
        val & 0x0000FF
    ];
});

function normalize(value: number) {
    const val = Math.round(value);

    // eslint-disable-next-line no-nested-ternary
    return val < 255 ?
        val < 1 ?
            0 :
            val :
        255;
}

function lighten(color: [ number, number, number ], factor: number) {
    return `#${(
        0x1000000 +
            normalize(color[0] * factor) * 0x10000 +
            normalize(color[1] * factor) * 0x100 +
            normalize(color[2] * factor)
    ).toString(16).slice(1)}`;
}

export function getColor(i: number) {
    const colorsTotal = HEX.length;

    if (i < colorsTotal) {
        return HEX[i];
    }

    return lighten(RGB[i % colorsTotal], 1 + 0.1 * Math.floor(i / colorsTotal));
}
