import React from 'react';

import { Shell } from '@vtblife/layout-components';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { graphql, useGql } from '@search/gql-client/src';

import type { ShellProviderQuery$data as ShellResponse, ShellProviderQuery$variables as ShellVariables } from './__generated__/ShellProviderQuery.graphql';

const DEFAULT_SHELL_CONFIG = { cdnUrl: 'https://cdn.m2.ru' };

const QUERY = graphql`
    query ShellProviderQuery($regionId: Int) {
        shellConfig(regionId: $regionId) {
            cdnUrl
            supportPhone
            copyright
        }
    }
`;

function ShellProvider({
    regionId,
    children
}: {
    children?: React.ReactNode;
    regionId?: number;
}) {
    const { data } = useGql<ShellVariables, ShellResponse>(QUERY, { regionId });
    const { auth: { user } } = useM2AuthContext();

    return (
        <Shell
            user={user ? { ...user, companyId: user.companyId ?? null } : null}
            config={data?.shellConfig ?? DEFAULT_SHELL_CONFIG}
        >
            {children}
        </Shell>
    );
}

export default ShellProvider;
