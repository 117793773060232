import React from 'react';
import Icon from '@vtblife/uikit-icons';
import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'ZoomControl');

const ZoomControl = props => {
    const { zoom, onChange, minZoom, maxZoom, step = 1 } = props;

    const handleZoomUp = () => {
        if (zoom !== maxZoom) {
            onChange(Math.min(zoom + step, maxZoom));
        }
    };

    const handleZoomDown = () => {
        if (zoom !== minZoom) {
            onChange(Math.max(zoom - step, minZoom));
        }
    };

    return (
        <div className={cn()}>
            <div
                className={cn('up', { disabled: zoom === maxZoom })}
                onClick={handleZoomUp}
            >
                <Icon name='plus' size='m' />
            </div>
            <div
                className={cn('down', { disabled: zoom === minZoom })}
                onClick={handleZoomDown}
            >
                <Icon name='minus' size='m' />
            </div>
        </div>
    );
};

export default ZoomControl;
