import React from 'react';

import { graphql, useGqlContext } from '@search/gql-client/src';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { UseAuthError } from '@search/auth/src/useAuth';

import {
    useUpdateFavoriteOfferMutation$data as useUpdateFavoriteOfferMutationResponse,
    useUpdateFavoriteOfferMutation$variables as useUpdateFavoriteOfferMutationVariables
} from './__generated__/useUpdateFavoriteOfferMutation.graphql';

const USE_FAVORITES_MUTATION = graphql`
    mutation useUpdateFavoriteOfferMutation($offerId: ID!, $isFavorite: Boolean!) {
        editFavoritePersonal2(isFavorite: $isFavorite, offerId: $offerId) {
            firstError {
                __typename
            }
        }
    }
`;

export function useUpdateFavoriteOffer() {
    const gql = useGqlContext();
    const { waitAuth } = useM2AuthContext();

    return React.useCallback((props: useUpdateFavoriteOfferMutationVariables) => {
        return waitAuth(() => gql.execute<
            useUpdateFavoriteOfferMutationVariables,
            useUpdateFavoriteOfferMutationResponse
        >(USE_FAVORITES_MUTATION, {
            ...props
        })
            .then(response => {
                if (response.data?.editFavoritePersonal2.firstError?.__typename === 'AuthUserIdError') {
                    throw new UseAuthError();
                }

                return response;
            }), {
            modalTitle: 'Добавить в избранное',
            modalSubtitle: 'Чтобы добавить в избранное, войдите или зарегистрируйтесь.',
            continueAfterLogin: true
        });
    }, [ gql, waitAuth ]);
}
