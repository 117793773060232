import React from 'react';

import { Dropdown, useDropdownHover, Link, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import { NewBuildingCardQueryResponse } from '../../../../common/components/NewBuildingCard';
import InspectionStatusLogo from '../../../../common/components/InspectionStatusLogo';

import './styles.css';

interface IProps {
    inspectionStatus: NewBuildingCardQueryResponse['newBuilding']['inspectionStatus'];
}

const cn = classname.bind(null, 'NewBuildingCardInspectionStatus');
const DROPDOWN_OPENING_DELAY = 250;
const DROPDOWN_CLOSING_DELAY = 150;

const DropdownCustomControl: React.FC<{
    text: string;
    variant: string;
}> = ({ text, variant }) => {
    const { control } = useDropdownHover({
        open: DROPDOWN_OPENING_DELAY,
        close: DROPDOWN_CLOSING_DELAY,
        contentHoverPreserve: true
    });

    return (
        <span ref={control}>
            <Typography
                bold
                tag='span'
                variant='primary-alone'
                className={cn(null, { variant })}
            >
                {text}
            </Typography>
        </span>
    );
};

const NewBuildingCardInspectionStatus = ({ inspectionStatus }: IProps) => {
    if (! inspectionStatus) {
        return null;
    }

    return (
        <Dropdown>
            <DropdownCustomControl
                text={inspectionStatus.statusText}
                variant={inspectionStatus.value}
            />
            <Dropdown.Content>
                <div className={cn('popup')}>
                    <Typography variant='secondary'>
                        {inspectionStatus.detailText}
                    </Typography>
                    {inspectionStatus.desktopLink && (
                        <Link
                            target='blank'
                            href={inspectionStatus.desktopLink}
                        >
                            <InspectionStatusLogo />
                            <Typography variant='secondary'>{inspectionStatus.linkText}</Typography>
                        </Link>
                    )}
                </div>
            </Dropdown.Content>
        </Dropdown>
    );
};

export default React.memo(NewBuildingCardInspectionStatus);
