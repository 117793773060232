import React from 'react';

import { Size, Spinner } from '@vtblife/uikit/legacy';

import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { NewBuildingSnippet as GQLNewBuildingSnippet } from '@search/graphql-typings';
import { errorCatcherWrap } from '@search/error/src/catcher';

import { ModalHeading } from '@search/vtbeco-frontend-core/view/mobile/components/ModalHeading';
import classname from '@search/classname/src';

import NewBuildingSnippet from '../../../common/components/NewBuildingSnippet';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchMapSnippetPanel');

const NewBuildingSnippetWrapCatcher = errorCatcherWrap(NewBuildingSnippet);

interface INewBuildingSearchMapSnippetPanel {
    newBuilding: GQLNewBuildingSnippet;
    onClose: () => void;
    pageParams?: INewBuildingSearchType;
    loading?: boolean;
    clearSnippetCache?: (refresh?: boolean) => void;
}

const NewBuildingSearchMapSnippetPanel = ({
    newBuilding,
    onClose,
    pageParams,
    loading,
    clearSnippetCache
}: INewBuildingSearchMapSnippetPanel) => (
    <div className={cn()}>
        <ModalHeading
            text='Новостройка'
            onClose={onClose}
            className={cn('header')}
        />
        <div className={cn('snippet-container')}>
            {
                loading ? (
                    <div className={cn('spinner-wrapper')}>
                        <Spinner size={Size.Medium} />
                    </div>
                ) : newBuilding && (
                    <NewBuildingSnippetWrapCatcher
                        newBuilding={newBuilding}
                        pageParams={pageParams}
                        clearSnippetCache={clearSnippetCache}
                        view='onMap'
                    />
                )
            }
        </div>
    </div>
);

export default React.memo(NewBuildingSearchMapSnippetPanel);
