import { useMemo } from 'react';
import type { NewBuildingM2Pro } from '@search/graphql-typings';

import type { M2ProAttachedFilesButtonProps } from '../components/M2ProAttachedFilesButton';

export const useM2ProFiles = ({
    cooperationTerms,
    nbPresentation
}: Pick<NewBuildingM2Pro, 'cooperationTerms' | 'nbPresentation'>) => useMemo(() => {
    const result: M2ProAttachedFilesButtonProps['files'] = [];

    if (cooperationTerms) {
        result.push({
            ...cooperationTerms,
            eventData: { name: 'order' }
        });
    }

    if (nbPresentation) {
        result.push({
            ...nbPresentation,
            eventData: { name: 'presentation_zk' }
        });
    }

    return result;
}, [ cooperationTerms, nbPresentation ]);
