import React from 'react';

import { Modal, Button, Typography } from '@vtblife/uikit';

import type { NewBuildingCard } from '@search/graphql-typings';

import classname from '@search/classname/src';

import { VTBCoreYMapDesktop } from '@search/vtbeco-frontend-core/view/common/components/YMap/Desktop';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import {
    NewBuildingCardPolygons,
    NewBuildingCardCommissioningDates,
    BuildingGroup,
    Building
} from '../../../../common/components/NewBuildingCard';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardBuildingsMap');

const NewBuildingCardBuildingsMap = ({
    hasApartmentsOnly,
    coordinates,
    buildingGroups,
    notAccreditedBuildingsTotal,
    hasFrozenBuildings,
    selectBuildings,
    selectedBuildings,
    onBuildingsSelect,
    closeMap
}: {
    hasApartmentsOnly: boolean;
    coordinates: NewBuildingCard['coordinates'];
    buildingGroups: BuildingGroup[];
    notAccreditedBuildingsTotal: number;
    hasFrozenBuildings: boolean;
    selectBuildings: (buildings: Building[]) => void;
    selectedBuildings: Building[];
    onBuildingsSelect: () => void;
    closeMap: () => void;
}) => {
    return (
        <Modal
            opened
            size='xxl'
            onClose={closeMap}
        >
            <Modal.WideContent
                header
                footer
            >
                <div className={cn('modal')}>
                    <NewBuildingCardCommissioningDates
                        buildingGroups={buildingGroups}
                        notAccreditedBuildingsTotal={notAccreditedBuildingsTotal}
                        hasFrozenBuildings={hasFrozenBuildings}
                    />
                    <VTBCoreYMapDesktop
                        noPlacemark
                        isScrollZoomEnabled
                        center={coordinates as [ number, number ]}
                    >
                        <NewBuildingCardPolygons
                            withHints
                            interactive
                            zoomMargin={[ 0, 48 ]}
                            buildingGroups={buildingGroups}
                            selectBuildings={selectBuildings}
                            selectedBuildings={selectedBuildings}
                            onBuildingsSelect={onBuildingsSelect}
                        />
                        <Typography
                            className={cn('selectBuildingHint')}
                            variant='small'
                            color='white-500'
                        >
                            Выберите один или несколько корпусов
                        </Typography>
                        {
                            selectedBuildings.length ? (
                                <div className={cn('selectBuildingShow')}>
                                    <Button
                                        fullWidth
                                        onClick={closeMap}
                                    >
                                        {`Показать ${hasApartmentsOnly ? 'апартаменты' : 'квартиры'}`}
                                    </Button>
                                </div>
                            ) : null
                        }
                    </VTBCoreYMapDesktop>
                </div>
            </Modal.WideContent>
        </Modal>
    );
};

export default React.memo(NewBuildingCardBuildingsMap);
