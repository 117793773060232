import React from 'react';
import type YMaps from '@search/ymap/src/ApiProvider/yandex-maps';
import { yMapLoader } from './loader';

type YMapApiMode = 'debug' | 'release'

type YMapApiCoordorder = 'latlong' | 'longlat'

export type YMapPackage =
    | 'package.standard'
    | 'package.full'
    | 'package.map'
    | 'package.controls'

export interface YMapApiProviderProps {
    apiUrl?: string;
    apiKey?: string;
    suggestApiKey?: string;
    staticApiUrl?: string;
    staticApiKey?: string;
    lang?: string;
    coordorder?: YMapApiCoordorder;
    load?: YMapPackage[];
    mode?: YMapApiMode;
    csp?: boolean;
    children: React.ReactChild;
}

const defaultYModules: YMapPackage[] = [ 'package.full' ];

export function YMapApiProvider({
    apiUrl = 'https://api-maps.yandex.ru/2.1',
    staticApiUrl = 'https://enterprise.static-maps.yandex.ru/1.x',
    apiKey,
    suggestApiKey,
    staticApiKey,
    lang = 'ru_RU',
    coordorder = 'latlong',
    load = defaultYModules,
    mode = 'release',
    csp,
    children
}: YMapApiProviderProps) {
    const params: Record<string, string> = {
        load: load.join(','),
        coordorder,
        lang,
        mode
    };

    if (csp !== undefined) params.csp = csp ? '1' : '0';
    if (apiKey !== undefined) params.apikey = apiKey;
    if (suggestApiKey !== undefined) params.suggest_apikey = suggestApiKey;

    const loadUrl = typeof URLSearchParams === 'undefined' ?
        undefined : `${apiUrl}/?${new URLSearchParams(params).toString()}`;

    const [ ymapApi, setYMapApi ] = React.useState<typeof YMaps>();
    const loadingRef = React.useRef(false);
    const [ error, setError ] = React.useState<Error | null>(null);

    const api = React.useCallback(() => {
        if (ymapApi) return ymapApi;
        if (loadingRef.current) return;
        if (error) throw error;

        const promise = loadUrl ? yMapLoader(loadUrl) : undefined;

        if (! promise) return;

        loadingRef.current = true;

        promise.then(ymapInstance => {
            loadingRef.current = false;
            setYMapApi(ymapInstance);
        })
            .catch((err: Error) => {
                loadingRef.current = false;
                setError(err);
            });
        return;
    }, [ error, loadUrl, ymapApi ]);

    const prev = React.useContext(YMapApiContext);

    if (prev) {
        // throw new Error('Only one <YMapApiProvider> allowed');
        return children;
    }

    return (
        <YMapApiContext.Provider
            value={{
                api,
                staticApiUrl: `${staticApiUrl}/?${
                    staticApiKey ? `key=${staticApiKey}&` : ''
                }`
            }}
        >
            {children}
        </YMapApiContext.Provider>
    );
}

interface IYMapApiContext {
    api: () => typeof YMaps | undefined;
    staticApiUrl: string;
}

const YMapApiContext = React.createContext<undefined | IYMapApiContext>(
    undefined
);

export function useYMapApi(): IYMapApiContext {
    const api = React.useContext(YMapApiContext);

    if (api === undefined) {
        throw new Error(
            'Wrap your app into <YMapApiProvider>...</YMapApiProvider>'
        );
    }

    return api;
}
