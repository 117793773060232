export type FormattedDate = {
    autoDate: string;
    fullDate: string;
    monthAndYear: string;
    shortDate: string;
    shortMonthDate: string;
    shortMonthDateWithTime: string;
    dateAndTime: {
        fullDate: string;
        date: string;
        time: string;
    };
};

type MonthIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

type DateArray = [string, string];

export const MONTHS_NOM: Record<MonthIndex, string> = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
];

const MONTHS: Record<MonthIndex, string> = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря'
];

const MONTHS_SHORT: Record<MonthIndex, string> = [
    'янв',
    'фев',
    'марта',
    'апр',
    'мая',
    'июня',
    'июля',
    'авг',
    'сен',
    'окт',
    'нояб',
    'дек'
];

/**
 * Возвращает количество дней между двумя датами
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export const dateDiffInDays = (date1: Date, date2: Date): number => {
    const d1 = new Date(date1);

    d1.setHours(0);
    d1.setMinutes(0);
    d1.setSeconds(0);

    const d2 = new Date(date2);

    d2.setHours(0);
    d2.setMinutes(0);
    d2.setSeconds(0);

    return Number(((d1.getTime() - d2.getTime()) / (24 * 3600 * 1000)).toFixed(0));
};

export type DateIs = {
    isToday: boolean;
    isYesterday: boolean;
    isTomorrow: boolean;
    isThisYear: boolean;
};

/**
 * Возвращает { Сегодня, Завтра, Вчера, В этом году } для даты
 * @param src Дата
 * @param now Дата для отношения (по умполчанию сейчас)
* getIsDate() -> {
 *     isToday: false,
 *     isYesterday: false,
 *     isTomorrow: false,
 *     isThisYear: false,
 * }
 */
export const getDateIs = (src: Date, now: Date = new Date()): DateIs => {
    const diff = dateDiffInDays(src, now);

    const isToday = diff === 0;
    const isYesterday = diff === -1;
    const isTomorrow = diff === 1;
    const isThisYear = src.getFullYear() === now.getFullYear();

    return {
        isToday,
        isYesterday,
        isTomorrow,
        isThisYear
    };
};

/**
 * Возвращает дату в разных форматах
 * @param {Data} src Дата
 * @param {boolean} isMskTime Использовать московское время
 * @returns {DateArray}
 * @example
 * getDateArray(new Date('2019-02-04T12:45:15'), true) -> [ '2019-02-04', '12:45:15' ]
 * getDateArray(new Date('2019-02-04T12:45:15'), false) -> [ '2019-02-04', '09:45:15' ]
 */
export const transformDateToArray = (src: Date, isMskTime: boolean): DateArray => {
    if (isMskTime) {
        const arrayDate = src
            .toLocaleString('ru', {
                hour12: false,
                timeZone: 'Europe/Moscow',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            })
            .split(', ') as DateArray; // 02/04/2019, 12:45:15

        let day: string | undefined;
        let month: string | undefined;
        let year: string | undefined;

        if (arrayDate[0].indexOf('.') !== -1) {
            [ day, month, year ] = arrayDate[0].split('.');
        } else {
            [ month, day, year ] = arrayDate[0].replace(/\D/g, '/').split('/');
        }

        arrayDate.splice(0, 1, `${year}-${month}-${day}`);

        return arrayDate; // [ '2019-02-04', '12:45:15' ]
    }

    return src
        .toISOString() // 2019-02-04T09:45:15.000Z
        .slice(0, 19) // 2019-02-04T09:45:15
        .split('T') as DateArray; // [ '2019-02-04', '09:45:15' ]
};

/**
 * Возвращает дату в разных форматах
 * @param {number} timestamp Временная метка в секундах (!)
 * @param {number} [currentTimestamp] Текущая временная метка в секундах (!)
 * @param {boolean} [isMskTime] Использовать московское время
 * @returns {FormattedDate}
 * @example
 * formatDate(1549273515) -> {
 *     autoDate: '4 февраля 2019',
 *     fullDate: '4 февраля 2019',
 *     monthAndYear: 'февраль 2019',
 *     shortDate: '4 февраля',
 *     shortMonthDate: '4 фев',
 *     shortMonthDateWithTime: isMskTime ? '4 фев 12:45' : '4 фев 09:45',
 *     dateAndTime: {
 *         fullDate: '04.02.2019',
 *         date: '04.02.19',
 *         time: isMskTime ? '12:45' : '09:45';
 *     }
 * }
 */
export const formatDate = (timestamp: number, currentTimestamp?: number, isMskTime: boolean = false): FormattedDate => {
    const time = timestamp * 1e3;
    const currentTime = currentTimestamp && currentTimestamp * 1e3;
    const src = new Date(time);
    const date = transformDateToArray(src, isMskTime);

    const [ year, month, day ] = date[0].split('-'); // [ '2019', '02', '04' ]
    const [ hour, min ] = date[1].split(':'); // isMskTime ? [ '12', '45', '15' ] : [ '09', '45', '15' ]

    const shortMonthDate = `${Number(day)}\u00A0${MONTHS_SHORT[Number(month) - 1 as MonthIndex]}`; // 4 фев
    const shortMonthDateWithTime = `${shortMonthDate} ${hour}:${min}`; // isMskTime ? 4 фев 12:45 : 4 фев 09:45
    const shortDate = `${Number(day)}\u00A0${MONTHS[Number(month) - 1 as MonthIndex]}`; // 4 февраля
    const fullDate = `${shortDate}\u00A0${year}`; // 4 февраля 2019
    const monthAndYear = `${MONTHS_NOM[Number(month) - 1 as MonthIndex]}\u00A0${year}`; // февраль 2019

    const dateAndTime = {
        fullDate: `${day}.${month}.${year}`, // 4.2.19
        date: `${day}.${month}.${year.slice(-2)}`, // 4.2.19
        time: `${hour}:${min}` // isMskTime ? 12:45 : 09:45
    };

    const now = currentTime ? new Date(currentTime) : new Date();

    let autoDate = fullDate;

    const { isToday, isYesterday, isTomorrow, isThisYear } = getDateIs(src, now);

    if (isTomorrow) {
        autoDate = 'завтра';
    } else if (isYesterday) {
        autoDate = 'вчера';
    } else if (isToday) {
        autoDate = 'сегодня';
    } else if (isThisYear) {
        autoDate = shortDate;
    }

    return {
        autoDate,
        fullDate,
        monthAndYear,
        shortDate,
        shortMonthDate,
        shortMonthDateWithTime,
        dateAndTime
    };
};
