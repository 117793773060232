/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { useGqlContext } from './gqlContext';
import { ExecutionResult, GqlClientOptions, GqlClient } from './GqlClient';

export type Gql2CacheItem<GqlResponse> = {
    responseKey: keyof GqlResponse;
    cacheKey: string | undefined;
};

export type Gql2CacheInfoCallback<
    Skips extends string,
    Variables extends Record<Skips, boolean | undefined | null>,
    GqlResponse
> = (variables: Omit<Variables, Skips>) => Required<Record<Skips, Gql2CacheItem<GqlResponse>>>;

export type Gql2ExtractSkips<Opts> = Opts extends Gql2CacheInfoCallback<infer Skips, any, any> ? Skips : never;

export type Gql2Options<
    Variables extends Record<string, any>,
    GqlResponse extends Record<string, any>,
    CacheInfo extends Gql2CacheInfoCallback<string, Variables, GqlResponse> | undefined
> = {
    /**
     * Отслеживать изменение состояние логина
     */
    authTrack?: boolean;

    /**
     * Префикс глобального кэша, задается, что б уменьшить размер ключа, т.к. по-умолчанию будет взят query
     */
    cachePrefix: string;

    /**
     * Если true, бросит promise как ексепшен
     *
     * По-умолчанию на сервере true,
     * на клиенте - false
     */
    suspense?: boolean;

    /**
     * Автоматизация skip-свойств в переменных.
     * Колбэк, на входе которого переменные, а на выходе ключи кэша, по которым будут храниться части запроса
     * и формироваться skip-свойства.
     *
     * ```ts
     * const cacheInfo: Gql2CacheInfoCallback<
     *     'skipGeo',
     *     SearchOffersQueryVariables,
     *     SearchOffersQueryResponse
     * > = v => ({
     *     skipGeo: { // имя переменной в variables, которую надо выставлять в true или false
     *         responseKey: 'geo', // часть response.data, которую надо кэшировать
     *         cacheKey: String(v.regionId) // ключ кэша, по которому надо кэшировать
     *     }
     * });
     * ```
     */
    cacheInfo?: CacheInfo;

    /**
     * Переданный идентификатор прорастет в трейс jaeger в виде tag'a
     */
    tracingTag?: string;
};

export function gql2ResponsesIsLoading<ResponsePart extends {
    loading?: boolean;
}>(items: readonly ResponsePart[]) {
    return items.some(item => item.loading);
}

// eslint-disable-next-line no-undef
const isSuspense = typeof window === 'undefined' || navigator.userAgent === 'node.js';

type Gql2Fetcher<Variables, GqlResponse> = (
    vars: Variables,
    opts?: GqlClientOptions
) => Promise<ExecutionResult<GqlResponse>>;

/**
 * Создает gql-лоадер для заргузки данных.
 *
 * Данные на каждое query кэшируются глобально и очищаются в момент отмонтирования компонента, в котором был вызван useGql2Loader.
 * Для того, что бы стейт не удалялся между 2мя страницами, используйте поднятие useGql2Loader на уровень выше этих 2х страниц.
 */
export function useGql2Loader<
    Variables extends Record<string, any> = any,
    GqlResponse extends Record<string, any> = any,
    CacheInfo extends Gql2CacheInfoCallback<any, Variables, GqlResponse> | undefined = never,
>(
    fetcher: Gql2Fetcher<Variables, GqlResponse>,
    {
        tracingTag,
        authTrack = false,
        cachePrefix,
        suspense = isSuspense,
        cacheInfo
    }: Gql2Options<Variables, GqlResponse, CacheInfo>
) {
    type Skips = Gql2ExtractSkips<CacheInfo>;

    const gql = useGqlContext();
    const [ , setCounter ] = React.useState(0);
    const abortRef = React.useRef<AbortController>();
    const partsCacheRef = React.useRef(new Map<string, any>());
    const clearsKeysRef = React.useRef(new Set<string>());
    const lastCacheKeyRef = React.useRef(undefined as string | undefined);

    /**
     * Очистить кэши, которые имеют отношение к этому лоадеру
     */
    const clearCache = React.useCallback((refresh: boolean = false) => {
        clearsKeysRef.current.forEach(key => {
            gql.deleteCache(key);
        });
        partsCacheRef.current.clear();
        clearsKeysRef.current.clear();
        if (refresh) setCounter(p => p + 1);
    }, [ gql ]);

    React.useEffect(() => {
        return () => {
            abortRef.current?.abort();
            clearCache();
        };
    }, [ clearCache ]);

    const { auth } = useM2AuthContext();
    const userIdRef = React.useRef<string>();

    userIdRef.current = authTrack && auth.isAuthenticated ? auth.user.userId : undefined;

    /**
     * Каждый следующий вызов - отменит предыдущий.
     * Параметры кэшируются автоматически, не нужно использовать useMemo на объекте параметров.
     */
    const loader = React.useCallback((
        inputVariables: Omit<Variables, Skips>
    ) => {
        const cacheKeyNoAuth = gql.buildCacheKey(cachePrefix, inputVariables);
        const cacheKey = gql.buildCacheKey(cachePrefix + (userIdRef.current ?? ''), inputVariables);

        const cacheItemPrev = cacheKeyNoAuth !== cacheKey ? gql.getCache<GqlResponse>(cacheKeyNoAuth) : undefined;

        clearsKeysRef.current.add(cacheKey);
        let cacheItem = gql.getCache<GqlResponse>(cacheKey);

        const partsCache = partsCacheRef.current;
        const info = cacheInfo?.(inputVariables);
        const infoKeys = info ? Object.keys(info) as Skips[] : [];

        if (! cacheItem) {
            let ctl = abortRef.current;

            if (typeof AbortController !== 'undefined') {
                if (lastCacheKeyRef.current && gql.getCache<GqlResponse>(lastCacheKeyRef.current)?.loading) {
                    gql.deleteCache(lastCacheKeyRef.current);
                }
                abortRef.current?.abort();
                // eslint-disable-next-line no-undef
                ctl = abortRef.current = new AbortController();
            }

            const queryVariables = { ...inputVariables } as Variables;

            if (info) {
                for (const queryKey of infoKeys) {
                    const item = info[queryKey];

                    queryVariables[queryKey] = item.cacheKey !== undefined ?
                        partsCache.has(item.responseKey + '.' + item.cacheKey) as any :
                        true;
                }
            }

            const loading = fetcher(queryVariables, { signal: ctl?.signal, tracingTag })
                .catch(error => gql2OnError<GqlResponse>(error))
                .then((response: ExecutionResult<GqlResponse>) => {
                    if (response.errors?.[0]?.message?.startsWith('AbortError')) {
                        response.errors = undefined;
                    }

                    gql.setCache(cacheKey, response);
                    if (ctl?.signal.aborted) return;
                    setCounter(p => p + 1);
                });

            cacheItem = { ...cacheItemPrev, loading };

            gql.setCache(cacheKey, cacheItem);
        }

        lastCacheKeyRef.current = cacheKey;

        const data = cacheItem.data;

        if (data && info) {
            for (const key of infoKeys) {
                const item = info[key];

                if (item.cacheKey === undefined) continue;

                const itemKey = item.responseKey + '.' + item.cacheKey;

                if (data[item.responseKey]) {
                    partsCache.set(itemKey, data[item.responseKey]);
                } else {
                    data[item.responseKey] = partsCache.get(itemKey);
                }
            }
        }

        const result = {
            errors: cacheItem?.errors ?? undefined,
            data: cacheItem?.data ?? undefined,
            loading: Boolean(cacheItem?.loading),
            cacheKey,
            clearCache
        };

        if (suspense && cacheItem?.loading) throw cacheItem.loading;

        return result;
    }, [
        gql,
        cachePrefix,
        cacheInfo,
        clearCache,
        suspense,
        fetcher,
        tracingTag,
        authTrack ? userIdRef.current : undefined
    ]);

    // @ts-ignore
    loader.clearCache = clearCache;

    return loader as typeof loader & {clearCache(refresh?: boolean): void};
}

function gql2OnError<GqlResponse>(error: Error & { code?: string }) {
    let item = { message: 'Unknown error', code: 'UNKNOWN' };

    if (error) {
        item = {
            code: error.code ?? error.name ?? 'UNKNOWN',
            message: String(error)
        };
    }
    const result: ExecutionResult<GqlResponse> = { errors: [ item ] };

    return result;
}

export function gql2CreateCachedFetcher<Variables, GqlResponse>({ gql, cachePrefix, fetcher }: {
    gql: GqlClient;
    cachePrefix: string;
    fetcher: Gql2Fetcher<Variables, GqlResponse>;
}) {
    const cachedFetcher: Gql2Fetcher<Variables, GqlResponse> = (vars, opts) => {
        const cacheKey = gql.buildCacheKey(cachePrefix, vars);

        const cached = gql.getCache<GqlResponse>(cacheKey);

        if (cached) return Promise.resolve(cached);

        return fetcher(vars, opts)
            .catch(error => gql2OnError<GqlResponse>(error))
            .then(response => {
                if (response.errors?.[0]?.message?.startsWith('AbortError')) {
                    response.errors = undefined;
                }

                gql.setCache(cacheKey, response);
                return response;
            });
    };

    return cachedFetcher;
}
