import React, { useContext, useMemo, useRef } from 'react';
import classname from '@search/classname/src';
import {
    DeveloperPromoPlaceholders,
    NewBuildingWhitelabelEnum,
    RootRegionsApplicationEnum,
    SuggestTypeEnum
} from '@search/graphql-typings';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import {
    Filters,
    FinishingTypeFilter,
    IFilter,
    PriceFilter,
    RegionFilter
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import {
    sellFlatPricesPerAllSquareMsk,
    sellFlatPricesPerAllSquareSpb,
    sellFlatPricesPerMeterMskMo,
    sellFlatPricesPerMeterSpbLo
} from '@search/vtbeco-frontend-core/view/filters/helpers/priceHelper';
import { PredefinedPrice } from '@search/vtbeco-frontend-core/view/filters/types/PredefinedPrice';
import { DesktopRegionFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/region/DesktopRegionFilterView';
import { DesktopRoomsTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/rooms/DesktopRoomsTypeFilterView';
import { DesktopPriceFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/price/DesktopPriceFilterView';
import { DesktopGeoFiltersView2 } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/geo/DesktopGeoFiltersView2';
import { DesktopCommissioningFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/commissioning/DesktopCommissioningFilterView';
import { DesktopFinishingTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/finishing_type/DesktopFinishingTypeFilterView';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { AreaUnit } from '@search/filter-enums/enums';
import { AddQueryDisplayValue } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';

import { GlobalContext, IGlobalContext } from '../../../../common/components/GlobalContext';
import useDeveloperPromoPlaceholder from '../../../../common/hooks/useDeveloperPromoPlaceholder';

import './styles.css';

const cn = classname.bind(null, 'NewNewBuildingSearchFiltersMainView');
const GEO_SUGGEST_RESULT_LIMIT = 30;

interface IProps {
    className?: string;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateFewFilters: (filter: IFilter[]) => void;
    addQueryDisplayValue: AddQueryDisplayValue;
    count: number;
    more: React.ReactNode;
    buttonSubmit: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    placeholders: DeveloperPromoPlaceholders;
}

const priceTypes = [ AreaUnit.UNKNOWN, AreaUnit.METER ];

export const NewNewBuildingSearchFiltersMainView: React.FC<IProps> = ({
    className,
    filters,
    updateFilter,
    updateFewFilters,
    addQueryDisplayValue,
    count,
    more,
    buttonSubmit,
    geoStore,
    placeholders
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const regionFilterValues = useMemo(
        () => globalContext.rootRegions.getRegionsLikeOptions(),
        [ globalContext.rootRegions ]
    );

    const priceFilter = filters.get<PriceFilter>(Filters.price);
    const isPricePerMeter = priceFilter.priceType === AreaUnit.METER;
    const regionFilter = filters.get<RegionFilter>(Filters.region);
    const { region } = regionFilter;

    let pricePresets: PredefinedPrice[];

    switch (region) {
        case RegionIdEnum.MSK:
        case RegionIdEnum.MSK_OBL:
        case RegionIdEnum.MSK_AND_MSK_OBL:
            pricePresets = isPricePerMeter ?
                sellFlatPricesPerMeterMskMo :
                sellFlatPricesPerAllSquareMsk;
            break;
        case RegionIdEnum.SPB:
        case RegionIdEnum.LEN_OBL:
        case RegionIdEnum.SPB_AND_LEN_OBL:
            pricePresets = isPricePerMeter ?
                sellFlatPricesPerMeterSpbLo :
                sellFlatPricesPerAllSquareSpb;
            break;
        default:
            pricePresets = [];
    }

    const rootRegionsApp = globalContext.instanceConfig.searchIndex === NewBuildingWhitelabelEnum.Vtb ?
        RootRegionsApplicationEnum.Vtb : RootRegionsApplicationEnum.NbSerp;

    const finishingTypeFilter = filters.get<FinishingTypeFilter>(Filters.finishingType);

    const newBuildingPlaceholderGtmRef = useRef<HTMLDivElement>(null);

    const { newBuilding: placeholderWithNewBuildings } = useDeveloperPromoPlaceholder({
        placeholders,
        newBuildingPlaceholderGtmRef
    });

    return (
        <div className={cn(null, undefined, className)}>
            <div className={cn('buy-flat')}>
                <div className={cn('top-panel')}>
                    <div className={cn('top-panel-item')}>
                        <DesktopCommissioningFilterView
                            filter={filters.get(Filters.commissioning)}
                            onChange={updateFilter}
                        />
                    </div>
                    <div className={cn('top-panel-item')}>
                        <DesktopFinishingTypeFilterView
                            filter={finishingTypeFilter}
                            onChange={updateFilter}
                        />
                    </div>
                    <div className={cn('top-panel-item')}>
                        <DesktopRegionFilterView
                            options={regionFilterValues}
                            filter={regionFilter}
                            onChange={updateFilter}
                        />
                    </div>
                </div>
                <div className={cn('bottom-panel')}>
                    <div className={cn('rooms')}>
                        <DesktopRoomsTypeFilterView
                            filter={filters.get(Filters.rooms)}
                            onChange={updateFilter}
                        />
                    </div>
                    <div className={cn('price')}>
                        <DesktopPriceFilterView
                            filter={priceFilter}
                            pricePreset={pricePresets}
                            priceTypes={priceTypes}
                            onChange={updateFilter}
                        />
                    </div>
                    <div
                        className={cn('geo')}
                        ref={newBuildingPlaceholderGtmRef}
                    >
                        <DesktopGeoFiltersView2
                            geoStore={geoStore}
                            inputBorderSide='square'
                            rootRegionsApp={rootRegionsApp}
                            suggestTypes={[
                                SuggestTypeEnum.Geo,
                                SuggestTypeEnum.NewBuilding,
                                SuggestTypeEnum.Developer
                            ]}
                            limit={GEO_SUGGEST_RESULT_LIMIT}
                            filters={filters}
                            count={count}
                            showNewBuildingsGroup
                            startWithNewBuildings
                            onChange={updateFewFilters}
                            addQueryDisplayValue={addQueryDisplayValue}
                            placeholder='Адрес'
                            placeholderWithNewBuildings={placeholderWithNewBuildings}
                        />
                    </div>
                    <div className={cn('more')}>
                        {more}
                    </div>
                    <div className={cn('submit')}>
                        {buttonSubmit}
                    </div>
                </div>
            </div>
        </div>
    );
};
