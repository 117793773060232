import React from 'react';
import { useYMapPane } from '../../Pane';
// @ts-ignore
import type { ymaps } from '../ApiProvider';
import { IconPlacemark } from '../../Icon';
import { YMapPlacemarkZIndexes } from '../Tooltip';

export type CoordsPointType = [number, number];

export interface YMapPlacemarkPointProps
    extends Partial<Pick<ymaps.IPlacemarkOptions, 'preset' | 'draggable'>> {
    readonly point?: CoordsPointType;
    onDragEnd?: (coords: CoordsPointType | null) => void;
    placemarkProps?: ymaps.IPlacemarkOptions;
}

export function YMapPlacemarkPoint({
    point,
    draggable = false,
    onDragEnd,
    preset = 'islands#dotIcon',
    placemarkProps = {}
}: YMapPlacemarkPointProps) {
    const {
        map,
        api: {
            Placemark,
            templateLayoutFactory,
            geometry: { Point }
        }
    } = useYMapPane();
    const coordsNormalized = point ?? map.getCenter() as CoordsPointType;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pointInstance = React.useMemo(() => new Point(coordsNormalized), []);

    React.useEffect(() => {
        if (coordsNormalized.length > 0) {
            pointInstance.setCoordinates(coordsNormalized);
        } else {
            pointInstance.setCoordinates(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ coordsNormalized ]);

    React.useEffect(() => {
        const IconLayoutBase = templateLayoutFactory.createClass(
            `<div data-test="ymap-pin">${IconPlacemark.str()}</div>`
        );
        const defaultProps = {
            // @ts-ignore
            iconLayout: 'default#imageWithContent',
            iconImageHref: '',
            // @ts-ignore
            iconContentLayout: IconLayoutBase,
            preset,
            draggable,
            ...YMapPlacemarkZIndexes.Huge
        };

        Object.assign(defaultProps, placemarkProps);

        const placemark = new Placemark(
            pointInstance,
            {},
            defaultProps
        );
        const onDragEndRaw = () => {
            onDragEnd?.(pointInstance.getCoordinates() as CoordsPointType);
        };

        placemark.events.add('dragend', onDragEndRaw);

        map.geoObjects.add(placemark);

        return () => {
            placemark.events.remove('dragend', onDragEndRaw);
            map.geoObjects.remove(placemark);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ map, pointInstance, draggable ]);

    return null;
}
