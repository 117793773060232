import React from 'react';

import {
    YMapControlManagerContext,
    YMapControlManagerOptions
} from '../ControlManagerOptions';
import { YMapControlManager } from '../ControlManager';

interface YMapControlGroupProps
    extends Omit<YMapControlManagerOptions, 'mapWidth' | 'mapHeight'> {
    children: React.ReactNode;
}

export function YMapControlGroup(props: YMapControlGroupProps) {
    const controlManager = YMapControlManager.use(props);

    return (
        <YMapControlManagerContext.Provider value={controlManager}>
            {props.children}
        </YMapControlManagerContext.Provider>
    );
}
