
export function useLkLinks() {
    const savedSearchUrl = '/saved-search';
    const favoritesUrl = '/favorites';

    return {
        savedSearchUrl,
        favoritesUrl
    };
}
