/* eslint-disable max-len */
import React from 'react';

import { createIcon } from './Svg';

export const Minus = createIcon('Minus', 'M5 11.5C5 11.2239 5.22386 11 5.5 11H18.5C18.7761 11 19 11.2239 19 11.5V12.5C19 12.7761 18.7761 13 18.5 13H5.5C5.22386 13 5 12.7761 5 12.5V11.5Z');
export const Plus = createIcon('Plus', 'M11.5 5C11.2239 5 11 5.22386 11 5.5V11H5.5C5.22386 11 5 11.2239 5 11.5V12.5C5 12.7761 5.22386 13 5.5 13H11V18.5C11 18.7761 11.2239 19 11.5 19H12.5C12.7761 19 13 18.7761 13 18.5V13H18.5C18.7761 13 19 12.7761 19 12.5V11.5C19 11.2239 18.7761 11 18.5 11H13V5.5C13 5.22386 12.7761 5 12.5 5H11.5Z');
export const Geo = createIcon('Geo', 'M18.508 6.14916C18.6858 5.7343 18.2663 5.31483 17.8514 5.49263L6.04132 10.5541C5.64203 10.7252 5.6359 11.2891 6.03138 11.4689L9.8176 13.1899C10.2578 13.39 10.6106 13.7428 10.8107 14.183L12.5317 17.9692C12.7115 18.3647 13.2754 18.3586 13.4465 17.9593L18.508 6.14916Z');
export const Ruler = createIcon('Ruler', 'M1.74696 17.3033C1.16117 16.7175 1.16117 15.7678 1.74696 15.182L15.182 1.74696C15.7678 1.16117 16.7175 1.16117 17.3033 1.74696L22.2531 6.6967C22.8388 7.28249 22.8388 8.23224 22.2531 8.81802L8.81802 22.2531C8.23224 22.8388 7.28249 22.8388 6.6967 22.2531L1.74696 17.3033ZM3.51472 16.2426L7.75736 20.4853L20.4853 7.75736L16.2426 3.51472L14.8284 4.92894L15.8891 5.9896C16.0844 6.18486 16.0844 6.50144 15.8891 6.6967L15.182 7.40381C14.9867 7.59907 14.6701 7.59907 14.4749 7.40381L13.4142 6.34315L12 7.75736L14.4749 10.2322C14.6701 10.4275 14.6701 10.7441 14.4749 10.9393L13.7678 11.6465C13.5725 11.8417 13.2559 11.8417 13.0607 11.6465L10.5858 9.17158L9.17158 10.5858L10.2322 11.6465C10.4275 11.8417 10.4275 12.1583 10.2322 12.3536L9.52513 13.0607C9.32987 13.2559 9.01329 13.2559 8.81802 13.0607L7.75736 12L6.34315 13.4142L8.81802 15.8891C9.01329 16.0844 9.01329 16.4009 8.81802 16.5962L8.11092 17.3033C7.91566 17.4986 7.59907 17.4986 7.40381 17.3033L4.92894 14.8284L3.51472 16.2426Z');
export const TypeSelector = createIcon('TypeSelector', 'M12.5226 4.27222C12.0549 4.00762 11.4814 4.01343 11.0191 4.28745L2.72425 9.20457C1.73822 9.78908 1.74559 11.2187 2.73758 11.793L3.93549 12.4866L2.72425 13.2046C1.73822 13.7891 1.74559 15.2187 2.73758 15.793L11.2485 20.7204C11.7134 20.9895 12.2867 20.9895 12.7516 20.7204L21.245 15.8031C22.2486 15.2221 22.2414 13.7705 21.232 13.1995L20.0188 12.5131L21.245 11.8031C22.2487 11.2221 22.2414 9.77051 21.232 9.19945L12.5226 4.27222ZM5.90778 11.3174L12 14.8445L17.9999 11.3709L19.4868 10.51L11.7899 6.15555L4.47827 10.4898L5.90778 11.3174Z');
// export const Traffic = createIcon('Traffic', 'M3.00001 12C3.00003 16.9706 7.02946 21 12 21C16.9706 21 21 16.9706 21 12C21 11.9569 20.9997 11.914 20.9991 11.8711C19.1349 11.3762 17.8389 9.52795 16.4639 7.56698C15.2653 5.85759 14.0066 4.06255 12.2592 3.00366C12.1731 3.00123 12.0867 3 12 3C7.02947 3 3.00004 7.02941 3.00001 11.9999C3.00002 12 3.00002 12 3.00003 12C3.00002 12 3.00002 12 3.00001 12ZM11.5589 1.00868C7.39658 1.01087 4.41553 1.07584 2.00001 3C0.322604 4.3362 -0.565442 9.08231 1.03702 11.0912C1.01251 11.3909 1.00001 11.694 1.00001 12C1.00001 18.0751 5.92488 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C11.8523 1 11.7052 1.00291 11.5589 1.00868Z');
export const Close = createIcon('Close', 'M7.56066 17.8536C7.3654 18.0488 7.04882 18.0488 6.85355 17.8536L6.14645 17.1464C5.95118 16.9512 5.95118 16.6346 6.14645 16.4393L10.5858 12L6.14648 7.56066C5.95121 7.3654 5.95121 7.04882 6.14648 6.85355L6.85358 6.14645C7.04884 5.95118 7.36543 5.95118 7.56069 6.14645L12 10.5858L16.4393 6.14645C16.6346 5.95118 16.9512 5.95118 17.1464 6.14645L17.8536 6.85355C18.0488 7.04882 18.0488 7.3654 17.8536 7.56066L13.4142 12L17.8536 16.4393C18.0488 16.6346 18.0488 16.9512 17.8536 17.1464L17.1465 17.8536C16.9512 18.0488 16.6346 18.0488 16.4394 17.8536L12 13.4142L7.56066 17.8536Z');
export const Check = createIcon('Check', 'M11.7071 25L28.4142 8.29289L27 6.87868L11.7071 22.1716L4.41421 14.8787L3 16.2929L11.7071 25Z');
export const FullscreenOn = createIcon('FullscreenOn', 'M4 7.79289V4.5C4 4.22386 4.22386 4 4.5 4H7.79289C8.23835 4 8.46143 4.53857 8.14645 4.85355L7.20711 5.79289L10.3536 8.93934C10.5488 9.1346 10.5488 9.45118 10.3536 9.64645L9.64646 10.3536C9.4512 10.5488 9.13462 10.5488 8.93935 10.3536L5.7929 7.2071L4.85355 8.14645C4.53857 8.46143 4 8.23835 4 7.79289ZM4 16.2071V19.5C4 19.7761 4.22386 20 4.5 20H7.79289C8.23835 20 8.46143 19.4614 8.14645 19.1464L7.2071 18.2071L10.3535 15.0607C10.5488 14.8654 10.5488 14.5488 10.3535 14.3536L9.64643 13.6464C9.45117 13.4512 9.13459 13.4512 8.93932 13.6464L5.79289 16.7929L4.85355 15.8536C4.53857 15.5386 4 15.7617 4 16.2071ZM20 4.5V7.79289C20 8.23835 19.4614 8.46143 19.1464 8.14645L18.2071 7.2071L15.0606 10.3536C14.8654 10.5488 14.5488 10.5488 14.3535 10.3536L13.6464 9.64645C13.4512 9.45118 13.4512 9.1346 13.6464 8.93934L16.7929 5.79289L15.8536 4.85355C15.5386 4.53857 15.7617 4 16.2071 4H19.5C19.7761 4 20 4.22386 20 4.5ZM20 16.2071V19.5C20 19.7761 19.7761 20 19.5 20H16.2071C15.7617 20 15.5386 19.4614 15.8536 19.1464L16.7929 18.2071L13.6465 15.0607C13.4512 14.8654 13.4512 14.5488 13.6465 14.3536L14.3536 13.6464C14.5488 13.4512 14.8654 13.4512 15.0607 13.6464L18.2071 16.7929L19.1464 15.8536C19.4614 15.5386 20 15.7617 20 16.2071Z');

export function Traffic() {
    return (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M3.00001 12C3.00003 16.9706 7.02946 21 12 21C16.9706 21 21 16.9706 21 12C21 11.9569 20.9997 11.914 20.9991 11.8711C19.1349 11.3762 17.8389 9.52795 16.4639 7.56698C15.2653 5.85759 14.0066 4.06255 12.2592 3.00366C12.1731 3.00123 12.0867 3 12 3C7.02947 3 3.00004 7.02941 3.00001 11.9999C3.00002 12 3.00002 12 3.00003 12C3.00002 12 3.00002 12 3.00001 12ZM11.5589 1.00868C7.39658 1.01087 4.41553 1.07584 2.00001 3C0.322604 4.3362 -0.565442 9.08231 1.03702 11.0912C1.01251 11.3909 1.00001 11.694 1.00001 12C1.00001 18.0751 5.92488 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C11.8523 1 11.7052 1.00291 11.5589 1.00868Z' fill='currentColor' />
    </svg>);
}
Traffic.str = TrafficStr;

export function TrafficStr() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.00001 12C3.00003 16.9706 7.02946 21 12 21C16.9706 21 21 16.9706 21 12C21 11.9569 20.9997 11.914 20.9991 11.8711C19.1349 11.3762 17.8389 9.52795 16.4639 7.56698C15.2653 5.85759 14.0066 4.06255 12.2592 3.00366C12.1731 3.00123 12.0867 3 12 3C7.02947 3 3.00004 7.02941 3.00001 11.9999C3.00002 12 3.00002 12 3.00003 12C3.00002 12 3.00002 12 3.00001 12ZM11.5589 1.00868C7.39658 1.01087 4.41553 1.07584 2.00001 3C0.322604 4.3362 -0.565442 9.08231 1.03702 11.0912C1.01251 11.3909 1.00001 11.694 1.00001 12C1.00001 18.0751 5.92488 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C11.8523 1 11.7052 1.00291 11.5589 1.00868Z" fill="currentColor"/>
</svg>`;
}

export function IconPlacemark() {
    return (<svg style={{ marginLeft: '-15px', marginTop: '-50px' }} width='30' height='48' viewBox='0 0 30 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <ellipse cx='15' cy='47' rx='5' ry='1' fill='#1B2555' fillOpacity='0.32' />
        <path d='M1.14757 12.2295C-0.382524 13.7596 -0.382524 16.2404 1.14757 17.7705L12.2295 28.8524C12.466 29.0889 12.7251 29.2888 13 29.4522L13 47C13 47 13.8431 47.1569 15 47.1569C16.1569 47.1569 17 47 17 47V29.4522C17.2749 29.2888 17.534 29.0889 17.7705 28.8524L28.8524 17.7705C30.3825 16.2404 30.3825 13.7596 28.8524 12.2295L17.7705 1.14757C16.2404 -0.382524 13.7596 -0.382524 12.2295 1.14757L1.14757 12.2295Z' fill='#D60F30' />
        <path d='M10.6066 16.4645C9.7978 15.6557 9.7978 14.3443 10.6066 13.5355L13.5355 10.6066C14.3443 9.7978 15.6557 9.7978 16.4645 10.6066L19.3934 13.5355C20.2022 14.3443 20.2022 15.6557 19.3934 16.4645L16.4645 19.3934C15.6557 20.2022 14.3443 20.2022 13.5355 19.3934L10.6066 16.4645Z' fill='white' />
    </svg>);
}
IconPlacemark.str = IconPlacemarkStr;

function IconPlacemarkStr() {
    return `<svg width="31" height="50" viewBox="0 0 31 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: -15px; margin-top: -50px">
        <ellipse cx='15' cy='47' rx='5' ry='1' fill='#1B2555' fill-opacity='0.32' />
        <path d='M1.14757 12.2295C-0.382524 13.7596 -0.382524 16.2404 1.14757 17.7705L12.2295 28.8524C12.466 29.0889 12.7251 29.2888 13 29.4522L13 47C13 47 13.8431 47.1569 15 47.1569C16.1569 47.1569 17 47 17 47V29.4522C17.2749 29.2888 17.534 29.0889 17.7705 28.8524L28.8524 17.7705C30.3825 16.2404 30.3825 13.7596 28.8524 12.2295L17.7705 1.14757C16.2404 -0.382524 13.7596 -0.382524 12.2295 1.14757L1.14757 12.2295Z' fill='#D60F30' />
        <path d='M10.6066 16.4645C9.7978 15.6557 9.7978 14.3443 10.6066 13.5355L13.5355 10.6066C14.3443 9.7978 15.6557 9.7978 16.4645 10.6066L19.3934 13.5355C20.2022 14.3443 20.2022 15.6557 19.3934 16.4645L16.4645 19.3934C15.6557 20.2022 14.3443 20.2022 13.5355 19.3934L10.6066 16.4645Z' fill='white' />
    </svg>`;
}

export function IconYMap() {
    return (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M3.68377 4.77218L8.68377 3.10552C8.88904 3.03709 9.11096 3.03709 9.31623 3.10552L14.6838 4.8947C14.889 4.96312 15.111 4.96312 15.3162 4.8947L19.6838 3.43885C20.3313 3.22301 21 3.70497 21 4.38753V18.2793C21 18.7098 20.7246 19.0919 20.3162 19.228L15.3162 20.8947C15.111 20.9631 14.889 20.9631 14.6838 20.8947L9.31623 19.1055C9.11096 19.0371 8.88904 19.0371 8.68377 19.1055L4.31623 20.5614C3.6687 20.7772 3 20.2952 3 19.6127V5.72087C3 5.29044 3.27543 4.9083 3.68377 4.77218ZM9 5.00011V17.0001L15 19.0001V7.00011L9 5.00011Z' fill='currentColor' />
    </svg>);
}

export function IconList() {
    return (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M4 3C2.89543 3 2 3.89543 2 5V9C2 10.1046 2.89543 11 4 11H20C21.1046 11 22 10.1046 22 9V5C22 3.89543 21.1046 3 20 3H4ZM4 5H20V9H4V5ZM4 13C2.89543 13 2 13.8954 2 15V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V15C22 13.8954 21.1046 13 20 13H4ZM4 15H20V19H4V15Z' fill='currentColor' />
    </svg>);
}

export function IconDraw() {
    return (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12.1936 3.56791C13.1403 3.51681 14.1134 3.49979 15.4594 3.78571C17.2404 4.16403 18.8167 4.97178 20.1057 6.07702C20.525 6.43651 21.1563 6.38804 21.5158 5.96876C21.8753 5.54949 21.8268 4.91818 21.4075 4.5587C19.8707 3.24106 17.9915 2.27896 15.8749 1.82936C14.2864 1.49192 13.1172 1.51514 12.0857 1.57082C11.5343 1.60059 11.1113 2.07179 11.1411 2.62327C11.1709 3.17475 11.6421 3.59768 12.1936 3.56791ZM7.00006 3.87784C7.00006 3.06007 7.68187 2.39636 8.50006 2.39636C9.31825 2.39636 10.0001 3.06007 10.0001 3.87784V9.30994L16.3684 10.5988C17.3032 10.7746 17.98 11.5912 17.98 12.5413V17.3214C17.98 18.958 16.6529 20.2844 15.0155 20.2844H9.32594C8.53937 20.2844 7.7863 19.9724 7.22997 19.4163L2.44362 14.6324C1.77167 13.9608 1.83939 12.855 2.58643 12.2694C3.07656 11.8852 3.74492 11.82 4.30026 12.1015L7.00006 13.4149V3.87784Z' fill='currentColor' />
    </svg>
    );
}

export function IconTrash() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M16 5.13419V5C16 3.34315 14.6569 2 13 2H11C9.34315 2 8 3.34315 8 5V5.13419C5.51362 5.38305 4 5.88896 4 6.47259V7.47436C4 7.75091 4.39772 8.01 5.09158 8.23257C6.47995 8.67792 9.05402 8.97701 12 8.97701C14.7614 8.97701 17.1961 8.71422 18.6337 8.31451C18.7298 8.28779 18.8214 8.26047 18.9084 8.23257C19.6023 8.01 20 7.7509 20 7.47436V6.47259C20 5.88896 18.4864 5.38305 16 5.13419ZM14 5C14 4.44772 13.5523 4 13 4H11C10.4477 4 10 4.44772 10 5V5.97171H14V5ZM18.0752 10.5015C18.2857 10.462 18.4952 10.4188 18.7014 10.3715L17.8667 18.9947C17.8667 20.6545 15.2401 22 12 22C8.75993 22 6.13333 20.6545 6.13333 18.9947L5.29862 10.3715C5.50475 10.4188 5.7143 10.462 5.92476 10.5015C7.54744 10.8063 9.69422 10.9806 12 10.9806C14.3058 10.9806 16.4526 10.8063 18.0752 10.5015Z' fill='currentColor' />
        </svg>
    );
}

export const IconCenteringStr = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2 -2 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.5C9 0.223857 9.22386 0 9.5 0H10.5C10.7761 0 11 0.223858 11 0.5V3.07089C14.0657 3.5094 16.4906 5.93431 16.9291 9H19.5C19.7761 9 20 9.22386 20 9.5V10.5C20 10.7761 19.7761 11 19.5 11H16.9291C16.4906 14.0657 14.0657 16.4906 11 16.9291V19.5C11 19.7761 10.7761 20 10.5 20H9.5C9.22386 20 9 19.7761 9 19.5V16.9291C5.93431 16.4906 3.5094 14.0657 3.07089 11H0.5C0.223857 11 0 10.7761 0 10.5V9.5C0 9.22386 0.223858 9 0.5 9H3.07089C3.5094 5.93431 5.93431 3.5094 9 3.07089V0.5ZM10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z" fill="#221F2E"/>
    </svg>
`;

export const IconPanoramaStr = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.19 7.21a.74.74 0 01-.18-.2c.02-.05.09-.17.35-.36.39-.27 1-.56 1.86-.81C6.9 5.33 9.3 5 12 5c2.7 0 5.1.33 6.78.84a6.6 6.6 0 011.86.8c.26.2.33.32.35.36a.74.74 0 01-.18.21c-.22.2-.6.43-1.16.65-1.1.45-2.72.81-4.65 1v4.27a33.17 33.17 0 00-6 0V8.86a17.63 17.63 0 01-4.65-1 4.1 4.1 0 01-1.16-.65zM1 17V7c0-.9.6-1.55 1.2-1.98a8.5 8.5 0 012.44-1.1C6.57 3.34 9.17 3 12 3s5.43.34 7.36.92c.96.29 1.8.65 2.43 1.1C22.4 5.45 23 6.1 23 7v10c0 .75-.42 1.33-.9 1.74-.46.4-1.1.74-1.8 1.02-1.37.53-3.22.92-5.3 1.1V15.15a31.02 31.02 0 00-6 0v5.73a20.12 20.12 0 01-5.3-1.11 6.23 6.23 0 01-1.8-1.02c-.48-.41-.9-1-.9-1.74zM21 6.98zm-18 0z" fill="#221F2E"/>
</svg>`;

export const IconNewBuildingStr = `
    <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 4a1 1 0 011-1h12a1 1 0 011 1v17h-6v-4h-2v4H5V4zm2 1h2v2H7V5zm2 4H7v2h2V9zm-2 4h2v2H7v-2zm2 4H7v2h2v-2zm2-12h2v2h-2V5zm2 4h-2v2h2V9zm-2 4h2v2h-2v-2zm4-8h2v2h-2V5zm2 4h-2v2h2V9zm-2 4h2v2h-2v-2zm2 4h-2v2h2v-2z"
            fill="#fff"
        />
    </svg>
`;
