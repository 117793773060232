import { useEffect } from 'react';

declare const document: Document;

export const useCriteoRemarketing = ({
    ids,
    id
}: {
    ids?: (number | string)[];
    id?: number | string;
}) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.src = '//static.criteo.net/js/ld/ld.js';

        document.body.appendChild(script);
    }, []);

    let event = '';
    let idString = '';

    if (ids?.length) {
        event = 'viewList';
        idString = `[ ${ids.map(i => `"${i}"`).join(', ')} ]`;
    } else if (id) {
        event = 'viewItem';
        idString = `"${id}"`;
    }

    useEffect(() => {
        if (! (event && idString)) {
            return;
        }

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.appendChild(document.createTextNode(`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ?
                "t" :
                /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ?
                    "m" :
                    "d";
            window.criteo_q.push(
                { event: "setAccount", account: 87272},
                { event: "setEmail", email: "" },
                { event: "setSiteType", type: deviceType },
                { event: "${event}", item: ${idString} }
            );
        `));

        document.body.appendChild(script);
    }, [ event, idString ]);
};
