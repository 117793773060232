import { Environment } from './environment';

export default (hostname: string): string => {
    if (/(^branch|.|-)(dev|test)[.\-]/.test(hostname)) {
        return Environment.FEATURE;
    } else if (/(^|.|-)dev[.\-]/.test(hostname)) {
        return Environment.DEVELOPMENT;
    } else if (/(^|.|-)test[.\-]/.test(hostname)) {
        return Environment.TESTING;
    } else if (/(^|.|-)stage[.\-]/.test(hostname)) {
        return Environment.STAGING;
    } else if (/(^|.|-)perf[.\-]/.test(hostname)) {
        return Environment.PERF;
    } else if (/(^|\.)local/.test(hostname)) {
        return Environment.LOCAL;
    }

    return Environment.PRODUCTION;
};
