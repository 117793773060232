import React from 'react';

export interface IButtonDataType {
    text: string;
    isLoading: boolean;
    href?: string;
    qrCodeValue?: string;
}

export interface IPhoneButtonStateContext {
    buttonsData: { [developerId: number]: IButtonDataType };
    handleClick: (developerId: number) => void;
    firstDeveloperId?: number;
    handlePhoneEventPush?: () => void;
    handleStatEventPush?: () => void;
}

const PhoneButtonStateContext = React.createContext<IPhoneButtonStateContext>({
    buttonsData: {},
    handleClick: () => {}
});

export function PhoneButtonStateProvider({
    buttonsData,
    handleClick,
    firstDeveloperId,
    handlePhoneEventPush,
    handleStatEventPush,
    children
}: IPhoneButtonStateContext & {
    children?: React.ReactNode;
}) {
    return (
        <PhoneButtonStateContext.Provider value={{
            buttonsData,
            handleClick,
            firstDeveloperId,
            handlePhoneEventPush,
            handleStatEventPush
        }}>
            {children}
        </PhoneButtonStateContext.Provider>
    );
}

export function usePhoneButtonContext() {
    return React.useContext(PhoneButtonStateContext);
}
