import { useEffect } from 'react';

import classname from '@search/classname/src';

import type { IEvent } from '../../ApiProvider/yandex-maps';
import { useYMapPane } from '../../Pane';

import { YMapPlacemarkZIndexes, YMapPlacemarkPriority } from '../Tooltip';
import type { YMapVector } from '../../Vector';

import './styles.css';

const cn = classname.bind(null, 'YMapPlacemarkPOI');

export interface YMapPlacemarkPOIProps {
    coordinates: YMapVector;
    iconName: string;
    hintLayout: string;
    onClick?: () => void;
    isSelected?: boolean;
    layerPriority?: YMapPlacemarkPriority;
}

// point of interest
export function YMapPlacemarkPOI({
    coordinates,
    iconName,
    hintLayout,
    onClick,
    isSelected,
    layerPriority
}: YMapPlacemarkPOIProps) {
    const {
        map,
        api: {
            Placemark,
            templateLayoutFactory,
            geometry: { Point }
        }
    } = useYMapPane();

    useEffect(() => {
        const mods = {
            type: '{{properties.iconName}}',
            view: '{{options.view}}'
        };

        const iconContentLayout = templateLayoutFactory.createClass(`
            <div class='${cn('iconCircle', mods)}'>
                <svg
                    height='24'
                    width='24'
                    class='${cn('icon', mods)}'
                >
                    <use href='#{{properties.iconName}}' />
                </svg>
            </div>
        `);

        const hintContentLayout = templateLayoutFactory.createClass(hintLayout);

        const placemark = new Placemark(
            new Point([ coordinates.y, coordinates.x ]),
            {
                iconName
            },
            {
                // @ts-ignore
                iconLayout: 'default#imageWithContent',
                iconView: isSelected ? 'hovered' : 'default',
                iconImageHref: '',
                iconContentLayout,
                iconImageSize: [ 32, 32 ],
                iconContentSize: [ 32, 32 ],
                iconImageOffset: [ -16, -16 ],
                hintContentLayout,
                hintCloseTimeout: 0,
                hintOpenTimeout: 150,
                cursor: 'arrow',
                ...(layerPriority ? YMapPlacemarkZIndexes[layerPriority] : YMapPlacemarkZIndexes.Default)
            }
        );

        map.geoObjects.add(placemark);

        if (onClick) {
            placemark.events.add('mousedown', () => onClick());
        } else {
            placemark.events
                .add('mouseenter', (e: object | IEvent) => {
                    // @ts-ignore
                    (e as IEvent).get('target').options.set('iconView', 'hovered');
                })
                .add('mouseleave', (e: object | IEvent) => {
                    // @ts-ignore
                    (e as IEvent).get('target').options.set('iconView', 'default');
                });
        }

        return () => {
            map.geoObjects.remove(placemark);
        };
    }, [
        map, coordinates.x, coordinates.y, templateLayoutFactory, Placemark, Point,
        hintLayout, isSelected, iconName, onClick, layerPriority
    ]);

    return null;
}
