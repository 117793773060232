import { useCallback, useRef } from 'react';

import {
    getInitialOfferFilters,
    getInitialFilters,
    getInitialOfferBuildingsFilters
} from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewBuildingFilterInitial';
import { newbuildingFilterSerializer } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterSerializer';
import type { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import type { IFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import type { Route } from '@search/router/src/Route';
import {
    INewBuildingCardType,
    IWlNewBuildingCardType,
    newBuildingCardDefaults,
    wlNewBuildingCardDefaults
} from '@search/nb-routes/src/NewBuildingCardRoute';

import type { Shared } from '../../../../types/shared';

type UseCardFiltersProps = {
    route: Route<IWlNewBuildingCardType, Shared.IRouterContext, typeof wlNewBuildingCardDefaults>;
    pageParams: IWlNewBuildingCardType;
} | {
    route: Route<INewBuildingCardType, Shared.IRouterContext, typeof newBuildingCardDefaults>;
    pageParams: INewBuildingCardType;
};

export const useCardFilters = ({
    pageParams,
    route
}: UseCardFiltersProps) => {
    const filters = useRef<NewbuildingFilterCollection>(getInitialFilters(pageParams));

    const handleFiltersSubmit = useCallback(() => {
        const filterPageParams = newbuildingFilterSerializer.serialize(filters.current.getFilters()) as INewBuildingCardType | IWlNewBuildingCardType;

        route.push({
            ...filterPageParams,
            region: (pageParams as INewBuildingCardType).region,
            type: (pageParams as INewBuildingCardType).type,
            name: (pageParams as INewBuildingCardType).name,
            id: pageParams.id,
            sort: pageParams.sort,
            from: pageParams.from
        } as INewBuildingCardType);
    }, [ pageParams, route ]);

    const handlePageChange = useCallback((pageNumber: number) => {
        route.push({
            ...(pageParams as INewBuildingCardType),
            pageNumber
        });
    }, [ pageParams, route ]);

    const handleSortChange = useCallback((sort: string | string[]) => {
        route.push({
            ...(pageParams as INewBuildingCardType),
            pageNumber: 1,
            sort: Array.isArray(sort) ? sort[0] : sort
        });
    }, [ pageParams, route ]);

    const updateFilter = useCallback((filter: IFilter) => {
        filters.current.update(filter);

        handleFiltersSubmit();
    }, [ handleFiltersSubmit ]);

    const updateMultipleFilters = useCallback((multipleFilters: IFilter[]) => {
        multipleFilters.forEach(filter => filters.current.update(filter));

        handleFiltersSubmit();
    }, [ handleFiltersSubmit ]);

    const removeFilter = useCallback((filter: IFilter) => {
        filters.current.remove(filter);

        handleFiltersSubmit();
    }, [ handleFiltersSubmit ]);

    const resetBuildingsFilter = useCallback(() => {
        filters.current = getInitialOfferBuildingsFilters(filters.current);

        handleFiltersSubmit();
    }, [ handleFiltersSubmit ]);

    const resetFilters = useCallback(() => {
        filters.current = getInitialOfferFilters(filters.current);

        handleFiltersSubmit();
    }, [ handleFiltersSubmit ]);

    return {
        filters: filters.current,
        updateFilter,
        updateMultipleFilters,
        removeFilter,
        resetFilters,
        resetBuildingsFilter,
        handlePageChange,
        handleSortChange
    };
};
