import { Currency } from '@search/filter-enums/enums/Currency';
import { convertCurrencyToGQL } from './gql/convertToGQL';
import {
    formatPrice as formatPriceGQL,
    FormatPriceOptions as FormatPriceOptionsGQL
} from './gql/formatPrice';

export interface FormatPriceOptions extends Omit<FormatPriceOptionsGQL, 'currency'> {
    currency: Currency;
}

/**
 * Возвращает человекопонятную запись цены
 * @param {Object} options
 * @param {number} options.value Цена в копейках/центах (!)
 * @param {Currency} options.currency
 * @param {boolean} [options.noFractional = false]
 * @param {boolean} [options.inWords = false]
 * @param {boolean} [options.displayCurrency]
 * @returns {string}
 * @example
 * formatPrice({
 *     value: 891234567,
 *     currency: Currency.RUB,
 *     noFractional: true,
 *     inWords: true,
 *     displayCurrency: true
 * }) -> '9 млн ₽'
 */
export const formatPrice = ({ currency, ...rest }: FormatPriceOptions) => formatPriceGQL({
    ...rest,
    currency: convertCurrencyToGQL(currency)
});
