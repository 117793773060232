/* eslint-disable dot-notation */
import React, { useContext, useMemo } from 'react';

import { Link, Typography } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';

import { InfoItem, NewBuildingInfo as GQLNewBuildingInfo } from '@search/graphql-typings';

// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import classname from '@search/classname/src';

import { SvgSpriteIcon } from '../SvgSpriteIcon';
import { GlobalContext } from '../GlobalContext';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingInfo');

type ViewType = 'mobile' | 'desktop';

interface INewBuildingInfoProps {
    view: ViewType;
    info: GQLNewBuildingInfo;
}

const getColumnsCount = (view: ViewType) => {
    switch (view) {
        case 'mobile':
            return 1;
        case 'desktop':
            return 3;
        default:
    }
};

export const NewBuildingInfo: React.FunctionComponent<INewBuildingInfoProps> = ({ info: infoProp, view }) => {
    const { instanceConfig: { hasInfoURLs } } = useContext(GlobalContext);
    const info = useMemo(() => {
        const infoCurrent = { ...infoProp };

        if (infoCurrent['apartmentsCount']?.value || infoCurrent['flatsCount']?.value) {
            delete infoCurrent['flatsAndApartmentsCount'];
        }

        return infoCurrent;
    }, [ infoProp ]);

    const infoItems = useMemo(
        () => Object.keys(info)
            .reduce((res, key) => {
                // @ts-ignore
                info[key] && res.push(info[key]);
                return res;
            }, [] as InfoItem[])
            .flat()
            .sort(({ weight: weight0 }, { weight: weight1 }) => weight1 - weight0),
        [ info ]
    );

    const columnsCount = useMemo(() => getColumnsCount(view), [ view ]);

    return (
        infoItems.length ? (
            <div className={cn()}>
                <Grid cols={columnsCount}>
                    {infoItems.map((item, idx) => {
                        const text = (
                            <Typography
                                variant='primary-alone'
                                className={cn('itemText')}
                            >
                                {item.value}
                            </Typography>
                        );
                        const content = hasInfoURLs && item.url ? (
                            <Link
                                href={item.url}
                                target='blank'
                            >
                                {text}
                            </Link>
                        ) : text;

                        return (
                            <Grid.Cell key={idx}>
                                <div className={cn('item', { type: view })}>
                                    <div className={cn('itemIcon')}>
                                        <SvgSpriteIcon name={item.iconName} />
                                    </div>
                                    <div>
                                        <Typography
                                            variant='secondary-alone'
                                            color='secondary'
                                        >
                                            {item.label}
                                        </Typography>
                                        {content}
                                    </div>
                                </div>
                            </Grid.Cell>
                        );
                    })}
                </Grid>
            </div>
        ) : null
    );
};
