import './styles.css';

import React, { FunctionComponent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Badge, Button, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { ApplicationIdEnum, GeoObjectCore } from '@search/graphql-typings';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { offerRegionToMortgage as searchRegionToMortgage } from '@search/vtbeco-frontend-core/domain/offer/regionToBackoffice';
import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { MortgageStupidModal } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/stupid/MortgageStupidModal';
import { MortgageField } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/domain/MortgageField';
import { useGtmOnVisible } from '@search/vtbeco-frontend-core/domain/google/useGtmOnVisible';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';

import { GlobalContext } from '../../../common/components/GlobalContext';

const cn = classname.bind(null, 'MortgagePromoBanner');

const MortgagePromoBanner: FunctionComponent<{
    title: string;
    subtitle: string;
    buttonText: string;
    imageUrl: string;
    badgeText: string;
    regionId: number;
    touch?: boolean;
}> = ({
    title,
    subtitle,
    buttonText,
    imageUrl,
    badgeText,
    regionId,
    touch
}) => {
    const analyticsObj = useMemo(() => (action: 'view' | 'open' | 'send') => ({
        action,
        event: 'nb_evt_banner',
        theme: 'ipoteka_region_special_project',
        section: 'spec_snippet',
        page_type: 'serp'
    }), []);

    const [ isModalOpen, toggleModal ] = useState<boolean>(false);
    const openModal = useCallback(() => {
        dataLayerPush(analyticsObj('open'));
        toggleModal(true);
    }, [ analyticsObj ]);
    const closeModal = useCallback(() => toggleModal(false), []);

    const { rootRegions } = useContext(GlobalContext);
    const mortgageRegionId = useMemo(() => {
        if ([
            RegionIdEnum.MSK,
            RegionIdEnum.SPB,
            RegionIdEnum.MSK_OBL,
            RegionIdEnum.LEN_OBL
        ].includes(regionId)) {
            return searchRegionToMortgage(regionId);
        }

        if (regionId === RegionIdEnum.MSK_AND_MSK_OBL) {
            return searchRegionToMortgage(RegionIdEnum.MSK);
        }

        if (regionId === RegionIdEnum.SPB_AND_LEN_OBL) {
            return searchRegionToMortgage(RegionIdEnum.SPB);
        }

        const { narrowRegion } = regionId && rootRegions.getById(regionId) || {};
        const narrowRegionId = narrowRegion ? (narrowRegion as GeoObjectCore).id : undefined;

        return searchRegionToMortgage(narrowRegionId ?? regionId);
    }, [ regionId, rootRegions ]);

    const containerRef = useRef<HTMLDivElement>(null);
    const onVisible = useCallback(() => {
        dataLayerPush(analyticsObj('view'));
    }, [ analyticsObj ]);

    useGtmOnVisible({ containerRef, onVisible });

    return (<>
        <div
            className={cn()}
            style={{ backgroundImage: `url(${imageUrl}` }}
            ref={containerRef}
            onClick={openModal}
        >
            <div>
                <Badge color='yellow'>{badgeText}</Badge>
            </div>
            <div>
                <Typography
                    className={cn('title')}
                >
                    {title}
                </Typography>
                <Typography
                    className={cn('subtitle')}
                >
                    {subtitle}
                </Typography>
                <div className={cn('button-touch')}>
                    <Button
                        fullWidth
                        variant='primary-alt-bg'
                    >
                        {buttonText}
                    </Button>
                </div>
                <div className={cn('button')}>
                    <Button
                        width={230}
                        variant='primary-alt-bg'
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
        {
            isModalOpen ? (
                <MortgageStupidModal
                    isMobile={touch}
                    // чтобы включить фичу "господдержка" сделай true и удали renderCustomFirstGrid
                    isNewBuilding={false}
                    applicationId={touch ? ApplicationIdEnum.NewBuildingsTouch : ApplicationIdEnum.NewBuildingsDesktop}
                    mortgageRegionId={mortgageRegionId}
                    onClose={closeModal}
                    locationPlacement='промо-баннер'
                    gtmSendId={DATA_GTM.MORTGAGE_SNIPPET_STARTER_MODAL_SEND_ID}
                    gtmShowId={DATA_GTM.MORTGAGE_SNIPPET_STARTER_MODAL_SHOW_ID}
                    realtyType='ProfOfferFlat'
                    gtmSendData={analyticsObj('send')}
                    renderCustomFirstGrid={
                        <MortgageField
                            titleProps={{ accent: true, variant: 'h3' }}
                            title='Скидка 0,6%'
                            children={<>на&nbsp;ставку при оформлении ипотеки через&nbsp;M2</>}
                        />
                    }
                />
            ) : null
        }
    </>);
};

export default MortgagePromoBanner;
