import { ErrorNotFound } from '@search/error/src/BadRequest';

export function strToNum(value: string | undefined | null): number | undefined {
    return value === undefined || value === null ? undefined : Number(value);
}

export function strToNumRequired(value: string): number {
    const result = Number(value);

    if (Number.isNaN(result)) throw new ErrorNotFound(`${value} not a number`);

    return result;
}

export function numToStr(value: number | undefined | null): string | undefined {
    return value === undefined || value === null ? undefined : String(value);
}
