import React from 'react';

import { EventBus } from '@vtblife/event-bus';
import type {
    UserRegionPayload,
    UserRegionChangeEventType
} from '@vtblife/event-bus-events';

import { useRouter } from '@search/offer-search-routes/src';
import { NewBuildingSearchRoute } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { NewBuildingSearchMapRoute } from '@search/nb-routes/src/NewBuildingSearchMapRoute';

import type { Shared } from '../../../types/shared';
import { GlobalContext } from '../components/GlobalContext';

export function useUserRegionUrlSwitch() {
    // @ts-ignore
    const router: Shared.RouterType = useRouter();
    const { rootRegions } = React.useContext(GlobalContext);

    React.useEffect(() => {
        const eventBus = EventBus.getInstance();

        const subscriptionToChange = eventBus.subscribe<UserRegionChangeEventType, UserRegionPayload>(
            'userRegion:change',
            ({ data }) => {
                let supportedRegionId: number | undefined;

                if (data.userRegionId) {
                    supportedRegionId = rootRegions.getById(data.userRegionId)?.id;
                }
                if (! supportedRegionId && data.userRegionIdNarrow) {
                    supportedRegionId = rootRegions.getById(data.userRegionIdNarrow)?.id;
                }
                if (! supportedRegionId) return;

                const newBuildingSearchRoute = router.route(NewBuildingSearchRoute);

                if (newBuildingSearchRoute.paramsSafe()) {
                    newBuildingSearchRoute.push({
                        ...newBuildingSearchRoute.params(),
                        region: supportedRegionId,
                        newBuildingId: undefined
                    });

                    return;
                }

                const newBuildingSearchMapRoute = router.route(NewBuildingSearchMapRoute);

                if (newBuildingSearchMapRoute.paramsSafe()) {
                    newBuildingSearchMapRoute.push({
                        ...newBuildingSearchMapRoute.params(),
                        region: supportedRegionId,
                        newBuildingId: undefined,
                        selectedNewBuildingId: undefined,
                        mapBounds: undefined
                    });
                }
            }
        );

        return () => {
            subscriptionToChange.unsubscribe();
        };
    }, [ rootRegions, router ]);
}
