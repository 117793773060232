import { useCallback, useRef } from 'react';

import { graphql, useGqlContext } from '@search/gql-client/src';
import type { GqlClientOptions } from '@search/gql-client/src';
import { useGql2Loader } from '@search/gql-client/src/useGql2';
import type { YMapBounds } from '@search/ymap/src/bounds';
import type { NewBuildingPoi } from '@search/graphql-typings';
import type {
    useNewBuildingSearchMapPoiQuery$data as useNewBuildingSearchMapPoiQueryResponse,
    useNewBuildingSearchMapPoiQuery$variables as useNewBuildingSearchMapPoiQueryVariables
} from './__generated__/useNewBuildingSearchMapPoiQuery.graphql';

const INFRASTRUCTURE_QUERY = graphql`
    query useNewBuildingSearchMapPoiQuery(
        $bounds: ProfessionalSearchInputBounds!
        $poiLimit: Int!
    ) {
        getInfrastructure(
            bounds: $bounds
            poiLimit: $poiLimit
        ) {
            poiList {
                latitude
                longitude
                name
                kind
                description
                address
                category
                specialization
                noctidial
            }
        }
    }
`;

interface UseNewBuildingSearchMapPoiProps {
    poiLimit?: number;
    bounds?: YMapBounds;
    selectedPOI?: NewBuildingPoi | null;
    skip?: boolean;
}

function useNewBuildingSearchMapPoiFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: useNewBuildingSearchMapPoiQueryVariables, opts?: GqlClientOptions) => gql.client<
            useNewBuildingSearchMapPoiQueryVariables,
            useNewBuildingSearchMapPoiQueryResponse
        >(INFRASTRUCTURE_QUERY, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export const useNewBuildingSearchMapPoi = ({
    poiLimit = 10,
    bounds,
    selectedPOI,
    skip,
}: UseNewBuildingSearchMapPoiProps) => {
    const poiRef = useRef<NewBuildingPoi[]>([]);

    const nbSearchMapLoader = useGql2Loader(useNewBuildingSearchMapPoiFetcher(), {
        cachePrefix: 'newBuildingSearchMapPOI',
        tracingTag: 'useNewBuildingSearchMapPoi'
    });

    if (skip || ! bounds) {
        return poiRef.current;
    }

    const { data } = nbSearchMapLoader({
        bounds: {
            lx: bounds.min.x,
            ly: bounds.min.y,
            rx: bounds.max.x,
            ry: bounds.max.y
        },
        poiLimit,
    });

    if (data?.getInfrastructure?.poiList) {
        const poiList = [ ...data.getInfrastructure.poiList as unknown as NewBuildingPoi[] ];

        if (selectedPOI) {
            const {
                latitude: sLat,
                longitude: sLon
            } = selectedPOI;

            if (! poiList.some(
                ({ latitude: lat, longitude: lon }) => sLat === lat && sLon === lon
            )) {
                poiList.push(selectedPOI);
            }
        }

        poiRef.current = poiList;
    }

    return poiRef.current;
};
