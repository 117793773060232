import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@vtblife/uikit-grid';
import { Link, Typography } from '@vtblife/uikit';

// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchLinkCloud');

export type LinksCloudViewType = 'mobile' | 'desktop';

type CloudLinkType = {
    url: string;
    text: string;
    isCurrent?: boolean;
}

interface INewBuildingLinksCloudProps {
    view: LinksCloudViewType;
    heading?: string;
    links: CloudLinkType[];
    moreLink?: CloudLinkType;
}

const getParams = (view: LinksCloudViewType): {
    columnsTotal: number;
    reducedItemNumber: number;
    headingLevel: 'h3' | 'h4';
    linkTarget: 'blank' | 'self';
} => {
    switch (view) {
        case 'mobile':
            return {
                columnsTotal: 1,
                reducedItemNumber: 4,
                headingLevel: 'h4',
                linkTarget: 'self'
            };
        case 'desktop':
        default:
            return {
                columnsTotal: 4,
                reducedItemNumber: 16,
                headingLevel: 'h3',
                linkTarget: 'blank'
            };
    }
};

export const NewBuildingSearchLinkCloud: React.FunctionComponent<INewBuildingLinksCloudProps> = ({
    heading,
    links,
    moreLink,
    view
}) => {
    const { columnsTotal, reducedItemNumber, headingLevel, linkTarget } = useMemo(
        () => getParams(view),
        [ view ]
    );

    const [ expanded, setExpanded ] = useState<boolean>(false);
    const showMore = useCallback(() => setExpanded(true), [ setExpanded ]);

    useEffect(() => setExpanded(false), [ links ]);

    const { visibleLinks, invisibleLinks } = useMemo(() => {
        if (expanded) {
            return {
                visibleLinks: links,
                invisibleLinks: []
            };
        }

        const border = links.length > reducedItemNumber ? reducedItemNumber - 1 : reducedItemNumber;

        return {
            visibleLinks: links.slice(0, border),
            invisibleLinks: links.slice(border)
        };
    },
    [ expanded, links, reducedItemNumber ]
    );

    return (
        visibleLinks.length ? (
            <div className={cn()}>
                {
                    heading ? (
                        <Typography
                            tag='h3'
                            variant={headingLevel}
                            bold
                            className={cn('heading')}
                        >
                            {heading}
                        </Typography>
                    ) : null
                }
                <Grid cols={columnsTotal}>
                    {
                        visibleLinks.map(({ url, text, isCurrent }, idx) => (
                            <Grid.Cell key={idx}>
                                <div className={cn('item', { disabled: isCurrent })}>
                                    <Link
                                        variant='secondary'
                                        href={isCurrent ? undefined : url}
                                        target={linkTarget}
                                    >
                                        {text}
                                    </Link>
                                </div>
                            </Grid.Cell>
                        ))
                    }
                    {
                        moreLink ? (
                            <Grid.Cell>
                                <div className={cn('item')}>
                                    <Link
                                        href={moreLink.url}
                                        variant='text'
                                        target={linkTarget}
                                    >
                                        {moreLink.text}
                                    </Link>
                                </div>
                            </Grid.Cell>
                        ) : null
                    }
                    {
                        invisibleLinks.length ? (
                            <>
                                {
                                    moreLink ? null : (
                                        <Grid.Cell>
                                            <div className={cn('item')}>
                                                <span
                                                    onClick={showMore}
                                                    className={cn('showMore')}
                                                >
                                                    Показать ещё
                                                </span>
                                            </div>
                                        </Grid.Cell>
                                    )
                                }
                                {
                                    invisibleLinks.map(({ url, text, isCurrent }, idx) => (
                                        <Grid.Cell key={idx}>
                                            <div className={cn('item', { invisible: true, disabled: true })}>
                                                <Link
                                                    variant='secondary'
                                                    href={isCurrent ? undefined : url}
                                                    target={linkTarget}
                                                >
                                                    {text}
                                                </Link>
                                            </div>
                                        </Grid.Cell>
                                    ))
                                }
                            </>
                        ) : null
                    }
                </Grid>
            </div>
        ) : null
    );
};
