import './public-path';
/* global window, document */
import '@search/vtbeco-frontend-core/domain/boot/ssr/script/sentry-fixes';

import 'intersection-observer';

import * as React from 'react';
import { hydrate } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/browser';
import { getDefaultOptions } from '@vtblife/sentry';
import getEnvironmentByHost from '@search/common-core/src/get-environment-by-host';
import { configureUnhandledErrorsNotification } from '@vtblife/layout-components';
import { VTBCoreYMapProvider } from '@search/vtbeco-frontend-core/view/common/components/YMap/Provider';
import { Router } from '@search/router/src/Router';
import { RouterProvider } from '@search/offer-search-routes/src/RouterProvider';
import { GqlClient, GqlContextProvider } from '@search/gql-client/src';
import { gqlBrowserClient } from '@search/gql-client/src/GqlBrowserClient';
import { RootRegions } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/root-regions';
import { MortgageAppSourceProvider, Source } from '@search/vtbeco-frontend-core/view/common/components/MinimalBanksMortgage/MortgageAppSourceContext';
import { authInfoFromLegacy } from '@search/auth/src/AuthInfo';
import { ImageLazyObserverProvider } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazy';
import { M2AuthProvider } from '@search/auth/src/M2AuthProvider';
import { GoogleOptimizeProvider } from '@search/vtbeco-frontend-core/domain/google/useGoogleOptimize';
import { FavoritesModalProvider } from '@search/vtbeco-frontend-core/view/common/components/FavoritesButton/FavoritesModal';
import { BasicErrorProvider } from '@search/vtbeco-frontend-core/view/common/components/FavoritesButton/BasicError';
import { StaticPathProvider } from '@search/common-core/src/StaticPathContext';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { M2ProModalOrderCreation } from '@vtblife/m2-pro';

import type { Shared } from '../../../types/shared';

import App from '../components/App';
import { GlobalContextProvider } from '../components/GlobalContext';
import { SEOTextsProvider } from '../components/SeoTexts/SEOTextsProvider';

// eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
declare let __webpack_public_path__: string;

const GRAPHQL_PATH = '/search/nb/graphql';

if (typeof Sentry !== 'undefined') {
    Sentry.init(getDefaultOptions({
        allowUrls: undefined,
        dsn: process.env.SENTRY_DSN,
        release: process.env.SENTRY_RELEASE,
        environment: getEnvironmentByHost(window.location.host),
        enabled: window.IS_DEBUG !== 'true'
    }));

    configureUnhandledErrorsNotification();
}

const initBrowserApp = ({
    instanceConfig,
    components,
    linkBuilder,
    routes
}: Shared.InitBrowserApp) => {
    const CACHE = window._CACHE || {};

    const gqlClient = new GqlClient({
        client: gqlBrowserClient({
            path: GRAPHQL_PATH,
            headerAppName: instanceConfig.headerAppName
        }),
        cache: CACHE
    });

    const rootRegions = new RootRegions(window.CONFIG.ROOT_REGIONS);

    window.CONFIG.geoSlugData?.forEach(item => {
        rootRegions.addGeoSlug(item.geo, item.region);
    });

    const globalContext = { ...window.CONFIG, instanceConfig, components, rootRegions };
    const { auth, seoTexts, isMobile } = globalContext;
    const googleOptimize = window.CONFIG.googleOptimize;

    const router = new Router<Shared.IRouterContext>({
        context: {
            rootRegions,
            isMobile: false
        },
        target: window,
        location: window.location,
        history: window.history
    });

    const { user } = authInfoFromLegacy(auth);

    return hydrate(
        <GqlContextProvider gql={gqlClient}>
            <CookiesProvider>
                <StaticPathProvider staticPath={window.CONFIG.staticPath}>
                    <GlobalContextProvider
                        router={router}
                        linkBuilder={linkBuilder}
                        globalContext={globalContext}
                    >
                        <VTBCoreYMapProvider>
                            <RouterProvider router={router}>
                                <BasicErrorProvider isMobile={isMobile}>
                                    <ImageLazyObserverProvider>
                                        {
                                            instanceConfig.searchIndex === NewBuildingWhitelabelEnum.Vtb ? (
                                                <App routes={routes} />
                                            ) : (
                                                <M2AuthProvider auth={auth}>
                                                    <GoogleOptimizeProvider value={googleOptimize}>
                                                        <FavoritesModalProvider isMobile={isMobile}>
                                                            <MortgageAppSourceProvider source={Source.NOVOSTROIKY}>
                                                                <SEOTextsProvider seoTexts={seoTexts}>
                                                                    <M2ProModalOrderCreation
                                                                        profileId={user?.userId}
                                                                        isClassified={true}
                                                                    >
                                                                        <App routes={routes} />
                                                                    </M2ProModalOrderCreation>
                                                                </SEOTextsProvider>
                                                            </MortgageAppSourceProvider>
                                                        </FavoritesModalProvider>
                                                    </GoogleOptimizeProvider>
                                                </M2AuthProvider>
                                            )
                                        }
                                    </ImageLazyObserverProvider>
                                </BasicErrorProvider>
                            </RouterProvider>
                        </VTBCoreYMapProvider>
                    </GlobalContextProvider>
                </StaticPathProvider>
            </CookiesProvider>
        </GqlContextProvider>,
        document.getElementById('app')
    );
};

export default initBrowserApp;
