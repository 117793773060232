import React from 'react';

import Button from '@search/vtbeco-ui/src/components/controls/Button';
import { VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'MortgageButton');

const MortgageButton: React.FunctionComponent = () => (
    <Button
        size='l'
        width='max'
        tone='light'
        type='link'
        target='_blank'
        href={`${VTB_IPOTEKA_LINK}?from=card3`}
        className={cn('button')}
    >
        <span className={cn('inner-text-360')}>Ипотека</span>
        <span className={cn('inner-text')}>Купить в ипотеку</span>
    </Button>
);

export default MortgageButton;
