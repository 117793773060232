import React, { useMemo } from 'react';

import classname from '@search/classname/src';

import { FoldingBlock } from '../FoldingBlock';
import type { LinksCloudViewType } from '../NewBuildingSearchLinkCloud';

import './styles.css';

const cn = classname.bind(null, 'FrequentlyAskedQuestions');

const FrequentlyAskedQuestions: React.FunctionComponent<{
    qa: {
        q: string;
        a: string;
    }[];
    view: LinksCloudViewType;
}> = ({
    qa,
    view
}) => useMemo(() => (
    <div className={cn()}>
        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: [
                        qa.map(({ q, a }) => ({
                            '@type': 'Question',
                            name: q,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: a
                            }
                        }))
                    ]
                })
            }}
        />
        {
            qa.map(({ q, a }, i) => (
                <FoldingBlock
                    key={i}
                    title={q}
                    content={a}
                    isMobile={view === 'mobile'}
                />
            ))
        }
    </div>
), [ qa, view ]);

export default FrequentlyAskedQuestions;
