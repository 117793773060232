import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';
import React, { CSSProperties, FC, useContext } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import Icon from '@vtblife/uikit-icons';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { NewBuildingPoint } from '@search/graphql-typings';
import { formatRange } from '@search/helpers/src/formatNumber';
import { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import {
    YMapPlacemarkPriority,
    YMapPlacemarkTooltip
} from '@search/ymap/src/Placemark';
import { IconNewBuildingStr } from '@search/ymap/src/Icon';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';

import { GlobalContext, IGlobalContext } from '../../GlobalContext';
import { useNewBuildingSnippetUrl } from '../../NewBuildingSnippet/useNewBuildingSnippetUrl';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingPlacemarkHint');

const PLACEMARK_TITLE_ATTRS = 'style="display: flex; padding: 2px;"';

const getBuildingStatus = (buildingStatusList: NewBuildingPoint['buildingStatusList']): string => {
    if (! buildingStatusList?.length) {
        return '';
    }

    return upperFirst(buildingStatusList.map(({ title }) => lowerFirst(title)).join(', '));
};

interface IHint {
    title: string;
    priceMin?: string;
    buildingStatus?: string;
    imageUrl?: string;
    stylesForTest?: CSSProperties;
}

export const Hint: FC<IHint> = ({
    title,
    priceMin,
    buildingStatus,
    imageUrl,
    stylesForTest
}) => {
    return (
        <div className={cn()} style={stylesForTest}>
            <div>
                <Typography
                    variant='secondary-alone'
                    className={cn('title')}
                    bold
                >
                    {title}
                </Typography>
                {priceMin && (
                    <Typography
                        variant='secondary-alone'
                        className={cn('priceMin')}
                        bold
                    >
                        {priceMin}
                    </Typography>
                )}
                {buildingStatus && (
                    <Typography
                        variant='small-alone'
                        color='secondary'
                        className={cn('buildingStatus')}
                    >
                        {buildingStatus}
                    </Typography>
                )}
            </div>
            <div className={cn('imageContainer')}>
                {
                    imageUrl ? (
                        <Image
                            type='cover'
                            url={imageUrl}
                            className={cn('photo')}
                        />
                    ) : (
                        <div className={cn('noPhoto')}>
                            <Icon
                                name='camera-crossed'
                                size='m'
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

interface INewBuildingPlacemark extends NewBuildingPoint {
    onClick?: (e: MouseEvent, container?: HTMLElement) => void;
    isActive?: boolean;
    pageParams?: INewBuildingSearchType;
}

export const NewBuildingPlacemark: FC<INewBuildingPlacemark> = ({
    title,
    priceMin,
    gallery,
    latitude,
    longitude,
    routeParams,
    buildingStatusList,
    onClick,
    isActive,
    pageParams,
    mangoColor,
}) => {
    const { isFromInnerNetwork } = useContext<IGlobalContext>(GlobalContext);
    const shouldShowHighlighted = isFromInnerNetwork && Boolean(pageParams?.showHighlighted);

    const url = useNewBuildingSnippetUrl(routeParams, pageParams);

    const layout = (
        <Hint
            title={title.nominative}
            priceMin={priceMin ? `${formatRange(priceMin, null, {
                billionsAsString: true
            })} ₽` : ''}
            buildingStatus={getBuildingStatus(buildingStatusList)}
            imageUrl={gallery?.main?.placemarkHint}
        />
    );

    return (
        <YMapPlacemarkTooltip
            id={String(routeParams.id)}
            url={url}
            title={IconNewBuildingStr}
            onClick={onClick}
            titleAttrs={PLACEMARK_TITLE_ATTRS}
            targetBlank
            coordinates={{ y: latitude, x: longitude }}
            hintLayout={renderToStaticMarkup(layout)}
            isActive={isActive}
            zIndexes={YMapPlacemarkPriority.Middle}
            style={shouldShowHighlighted && mangoColor ? `background-color: ${
                isActive ? mangoColor.active : mangoColor.default
            }` : undefined}
        />
    );
};
