import { useContext, useMemo } from 'react';
import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import { RouteParams } from '@search/graphql-typings';

import { emojiDict } from '../../../../dict/breadcrumbs';
import { IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';

interface BreadcrumbsItem {
    title: string;
    path: string;
    active?: boolean;
}

const CONSTRUCTION_TITLE = 'Ход строительства';

export const useBreadcrumbs = ({
    narrowRegionId,
    narrowRegionLocative,
    routeParams,
    title,
    isConstruction = false
}: {
    narrowRegionId: number;
    narrowRegionLocative?: string | null;
    routeParams: RouteParams;
    title: string;
    isConstruction?: boolean;
}): {
    items: BreadcrumbsItem[];
    rdfMeta: string;
} => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    return useMemo(() => {
        const items: BreadcrumbsItem[] = [
            {
                title: 'Главная',
                path: '/'
            }
        ];

        const itemListElement: ListItem[] = [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Главная',
                item: '/'
            }
        ];

        if (narrowRegionLocative) {
            const label = `новостройки ${narrowRegionLocative}`;
            const path = onChangeGlobalContext.linkBuilder.search({
                region: narrowRegionId
            });

            items.push({
                title: label,
                path
            });

            itemListElement.push({
                '@type': 'ListItem',
                position: 2,
                name: label,
                // TODO: Add full uri to item
                item: path
            });
        }

        if (routeParams.subRegionDisplayName && routeParams.regionId) {
            const path = onChangeGlobalContext.linkBuilder.search({
                region: routeParams.regionId,
                geoSlug: routeParams.subRegion ?? undefined
            });

            items.push({
                title: routeParams.subRegionDisplayName,
                path
            });

            itemListElement.push({
                '@type': 'ListItem',
                position: itemListElement.length + 1,
                name: routeParams.subRegionDisplayName,
                item: path
            });
        }

        const cardPath = onChangeGlobalContext.linkBuilder.card({
            id: routeParams!.id,
            // @ts-ignore
            type: routeParams!.type,
            name: routeParams!.name,
            region: routeParams!.region,
            subRegion: routeParams!.subRegion
        });

        items.push({
            title,
            path: cardPath,
            active: ! isConstruction
        });

        itemListElement.push({
            '@type': 'ListItem',
            position: itemListElement.length + 1,
            name: `${String.fromCodePoint(emojiDict.NEW_BUILDING)} ${title}`,
            item: cardPath
        });

        if (isConstruction) {
            // @ts-ignore
            const constructionPath = onChangeGlobalContext.linkBuilder.construction({
                id: routeParams!.id,
                // @ts-ignore
                type: routeParams!.type,
                name: routeParams!.name,
                region: routeParams!.region,
                subRegion: routeParams!.subRegion
            });

            items.push({
                title: CONSTRUCTION_TITLE,
                path: constructionPath,
                active: true
            });

            itemListElement.push({
                '@type': 'ListItem',
                position: itemListElement.length + 1,
                name: CONSTRUCTION_TITLE,
                item: constructionPath
            });
        }

        const breadcrumbsListMeta: WithContext<BreadcrumbList> = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement
        };

        return {
            items,
            rdfMeta: JSON.stringify(breadcrumbsListMeta)
        };
    }, [
        onChangeGlobalContext,
        narrowRegionId,
        narrowRegionLocative,
        routeParams,
        title,
        isConstruction
    ]);
};
