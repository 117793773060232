import { useState, useCallback } from 'react';

export interface IUseToggleValueReturnType {
    handleSetTogVal: (bool: boolean) => void;
    handleSetTrueTogVal: () => void;
    handleSetFalseTogVal: () => void;
    handleToggleTogVal: () => void;
    isToggleValue: boolean;
}

export const useToggleValue = (initial: boolean = false): IUseToggleValueReturnType => {
    const [ isToggleValue, setToggleValue ] = useState(initial);
    const handleSetTrueTogVal = useCallback(() => {
        setToggleValue(true);
    }, []);
    const handleSetFalseTogVal = useCallback(() => {
        setToggleValue(false);
    }, []);
    const handleToggleTogVal = useCallback(() => {
        setToggleValue(prev => ! prev);
    }, []);
    const handleSetTogVal = useCallback((bool: boolean) => {
        setToggleValue(bool);
    }, []);

    return {
        handleToggleTogVal,
        handleSetTrueTogVal,
        handleSetFalseTogVal,
        handleSetTogVal,
        isToggleValue
    };
};
