import React, { RefObject } from 'react';

import { Dropdown, useDropdownHover, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import type { IMetroRouteProps } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';

import type { NewBuildingCardQueryResponse } from '../../../../common/components/NewBuildingCard';
import { MetroRouteWithSearchLink } from '../../../../common/components/MetroRouteWithSearchLink';

import './styles.css';

interface IProps {
    address: string;
    metroList: NewBuildingCardQueryResponse['newBuilding']['routes'];
    regionId: NewBuildingCardQueryResponse['newBuilding']['routeParams']['regionId'];
}

const cn = classname.bind(null, 'NewBuildingCardLocation');
const DROPDOWN_OPENING_DELAY = 250;

const DropdownCustomControl: React.FC<{ extra: number }> = ({ extra }) => {
    const { control } = useDropdownHover({ open: DROPDOWN_OPENING_DELAY });

    return (
        <div
            ref={control as RefObject<HTMLDivElement>}
            className={cn('more-btn')}
        >
            Ещё {extra}
        </div>
    );
};

const renderMetroRoutes = (metroList: NonNullable<IProps['metroList']>, regionId: IProps['regionId']) => {
    const visibleMetroList = metroList.slice(0, 2);
    const hiddenMetroList = metroList.slice(2);

    return (
        <List className={cn('routes')}>
            {visibleMetroList.map(metro => (
                <ListItem key={metro!.station!.id}>
                    <MetroRouteWithSearchLink
                        metro={metro as IMetroRouteProps['metro']}
                        regionId={regionId}
                        className={cn('route')}
                    />
                </ListItem>
            ))}
            {
                hiddenMetroList?.length ? (
                    <ListItem key='route'>
                        <Dropdown>
                            <DropdownCustomControl extra={hiddenMetroList.length} />
                            <Dropdown.Content>
                                <div className={cn('popup-content')}>
                                    {hiddenMetroList.map(metro =>
                                        <MetroRouteWithSearchLink
                                            key={metro!.station!.id}
                                            regionId={regionId}
                                            metro={metro as IMetroRouteProps['metro']}
                                            className={cn('popup-content-item')}
                                        />
                                    )}
                                </div>
                            </Dropdown.Content>
                        </Dropdown>
                    </ListItem>
                ) : null
            }
        </List>
    );
};

const NewBuildingCardLocation = ({
    address,
    metroList,
    regionId
}: IProps) => (
    <div className={cn()}>
        <Typography
            variant='primary-alone'
            className={cn('address')}
        >
            {address}
        </Typography>
        {metroList?.length ? renderMetroRoutes(metroList, regionId) : null}
    </div>
);

export default React.memo(NewBuildingCardLocation);
