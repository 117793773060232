/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import type ymaps from '@search/ymap/src/ApiProvider/yandex-maps';

import { useYMapPane } from '../Pane';
import {
    useYMapControlManager,
    IYMapControlManager,
    ControlAttributes
} from './ControlManagerOptions';

export interface YMapControlProps {
    index?: number;
    className?: string;
    dataTest?: string;
    title?: string;
    setFullscreen?: (isFullscreen: boolean) => void;
}

export interface IControlOptions {
    // layout?: ymaps.IClassConstructor<ITemplateLayout<any, any, any>>
    position?: ymaps.IScreenAreaPosition;
}

export interface ICustomizableControl<
    Options extends IControlOptions = IControlOptions
> extends ymaps.IControl {
    getMap(): ymaps.Map;
    options: ymaps.ITemplateState<Options>;
}

export abstract class YMapControl<
    Props extends YMapControlProps = YMapControlProps,
    Control extends ICustomizableControl = ICustomizableControl,
    State = ymaps.IControlState<Control>
> {
    protected props: Props

    protected keys: ComponentTokens<Props, State>

    protected ymaps: typeof ymaps
    protected ymap: ymaps.Map

    protected controlManager: IYMapControlManager

    private dispose: (() => void)

    constructor(
        props: Props,
        ymapApi: typeof ymaps,
        ymap: ymaps.Map,
        controlManager: IYMapControlManager
    ) {
        this.props = props;
        this.ymaps = ymapApi;
        this.ymap = ymap;
        this.controlManager = controlManager;

        const dataKeys = {} as Tokens<Props>
;

        if (props) {
            // eslint-disable-next-line guard-for-in
            for (const key in props) {
                dataKeys[key as keyof Props] = new TokenFactory('data', key);
            }
        }

        const stateKeys = {} as Tokens<State>;
        const state = this.state()
;

        if (state) {
            // eslint-disable-next-line guard-for-in
            for (const key in state) {
                stateKeys[key as keyof State] = new TokenFactory('state', key);
            }
        }

        this.keys = {
            data: dataKeys,
            state: stateKeys
        };

        this.index = props.index;
        this.dispose = this.controlManager.add(this);
    }

    state(): State {
        return {} as State;
    }

    static use<
        Props extends YMapControlProps,
        Control extends ICustomizableControl,
        State,
        Instance extends YMapControl<Props, Control, State>
    >(
        this: new (
            props: Props,
            ymapApi: typeof ymaps,
            ymap: ymaps.Map,
            controlManager: IYMapControlManager
        ) => Instance,
        props: Props
    ) {
        const controlManager = useYMapControlManager();
        const { map, api } = useYMapPane();

        const control = React.useMemo(
            () => new this(props, api, map, controlManager),
            []
        );

        React.useEffect(() => {
            return control.destructor.bind(control);
        }, []);

        React.useEffect(() => control.update(props), Object.entries(props));

        return control;
    }

    readonly index: number | undefined
    protected abstract create(): Control
    protected abstract template(): string

    // @ts-ignore-line
    protected layoutClass() {
        const Base = (this.ymaps.templateLayoutFactory.createClass(
            this.template()
        ) as unknown) as ymaps.IClassConstructor<
            ymaps.ITemplateLayout<Props, State, Control>
        >;

        return class YMapBaseLayout extends Base {
            protected dispose = empty
            clear() {
                this.dispose();
                this.dispose = empty;
                super.clear();
            }

            get control(): Control {
                // @ts-ignore-line
                return this.getData().control;
            }

            get map() {
                return this.control.getMap();
            }

            get state() {
                // @ts-ignore-line
                return this.getData().state;
            }
        };
    }

    attributes(): ControlAttributes {
        return this.controlManager.attributes;
    }

    protected _instance: Control | null = null

    get instance(): Control {
        if (this._instance) return this._instance;
        this._instance = this.create();
        return this._instance;
    }

    destructor() {
        const controls = this.ymap.controls;

        controls.remove(this.instance);
        this.dispose();
    }

    update(props: Props) {
        this.props = props;
        // @todo update
    }

    protected attached = false

    position(area: ymaps.IScreenArea) {
        if (! this.attached) {
            this.attached = true;
            this.ymap.controls.add(this.instance);
        }

        const options = this.instance.options;
        const position = areaToPosition(area);

        if (options.set) options.set('position', position);
    }
}

function empty() {}

function areaToPosition(area: ymaps.IScreenArea): ymaps.IScreenAreaPosition {
    if (area.left !== undefined && area.top !== undefined) {
        return {
            left: area.left,
            top: area.top
        };
    }
    if (area.right !== undefined && area.top !== undefined) {
        return {
            right: area.right,
            top: area.top
        };
    }

    if (area.left !== undefined && area.bottom !== undefined) {
        return {
            left: area.left,
            bottom: area.bottom
        }
        ;
    }

    if (area.right !== undefined && area.bottom !== undefined) {
        return {
            right: area.right,
            bottom: area.bottom
        };
    }

    throw new Error(
        `Bad area properties: ${JSON.stringify(area)}, see ScreenPosition type`
    );
}

export class TokenFactory {
    protected prefix: string
    protected key: string
    constructor(prefix: string, key: string) {
        this.prefix = prefix;
        this.key = key;
    }

    protected get name() {
        return `${this.prefix}.${this.key}`;
    }

    ifTrue(value?: string): string {
        return `{% if ${this.name} %}${value || this.key}{% endif %}`;
    }

    ifEqualTo(equal: string, value?: string): string {
        return `{% if "${this.name}" == "${equal}" %}${value || this.key}{% endif %}`;
    }

    value() {
        return `{{ ${this.name} }}`;
    }
}

export type Tokens<Props> = Record<keyof Props, TokenFactory>
export interface ComponentTokens<Props, State> {
    data: Tokens<Props>;
    state: Tokens<State>;
}
