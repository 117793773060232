/* global window */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference path="./susanin.d.ts" />
import Susanin, { RouteConditions, SusaninRouteBase } from 'susanin';

import { ErrorNotFound } from '@search/error/src/BadRequest';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { IRouter, PartialDefaults, Route } from '../Route';
import { Router } from '../Router';
import { LocationLike, Tokens } from '../RouterInterfaces';
import { getTokens, TokenMetadata } from './getTokens';

export class SusaninRoute<
    Input extends {
        origin?: string | null;
    },
    Output,
    Context extends { preferRelativeUrl?: boolean; onlyRelativeUrl?: boolean },
    Defaults = {}
> extends Route<
    Output,
    Context,
    Defaults
> {
    protected metadata?: TokenMetadata;
    protected pattern(p: Tokens<Input>): string {
        throw new Error('implement');
    }
    protected toQuery?(p: Output): Input;
    protected fromQuery?(p: Input): Output;

    defaults(): Defaults {
        throw new Error('implement');
    }

    static defaultLocation: LocationLike = (typeof window !== 'undefined' ? window.location : new URL('https://m2.ru')) as LocationLike;

    constructor(params: IRouter<Context>) {
        super(params instanceof Router ? params : {
            location: SusaninRoute.defaultLocation,
            ...params,
            context: params.context ?? {}
        });
    }

    protected patternHost(): string {
        return '';
        // return `(<origin>)`;
    }

    protected conditions() {
        return {
            // origin: 'https?://[^\/]+'
        };
    }

    protected compiledRoute:
        | SusaninRouteBase<Output, Defaults, string, undefined>
        | undefined = undefined;

    protected get susaninRoute() {
        if (this.compiledRoute) return this.compiledRoute;
        if (! this.metadata) throw new Error('Provide metadata');
        const pattern = this.pattern(getTokens(this.metadata));

        this.compiledRoute = new Susanin.Route({
            postMatch: this.fromQuery ? this.fromQuery.bind(this) : undefined,
            preBuild: this.toQuery ? this.toQuery.bind(this) : undefined,
            data: undefined,
            conditions: this.conditions ? this.conditions() : undefined,
            pattern,
            name: pattern
        });

        return this.compiledRoute;
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected extraPatterns(p: Tokens<Input>): string[] | undefined {
        return undefined;
    }

    protected compiledExtraRoutes: SusaninRouteBase<Output, Defaults, string, undefined>[] | undefined = undefined;

    protected get extraRoutes() {
        if (this.compiledExtraRoutes) return this.compiledExtraRoutes;
        if (! this.metadata) throw new Error('Provide metadata');
        const patterns = this.extraPatterns(getTokens(this.metadata));

        if (! patterns?.length) return [];

        const conditions = this.conditions?.();

        this.compiledExtraRoutes = patterns.map(pattern => new Susanin.Route({
            postMatch: this.fromQuery ? this.fromQuery.bind(this) : undefined,
            preBuild: this.toQuery ? this.toQuery.bind(this) : undefined,
            data: undefined,
            conditions,
            pattern,
            name: pattern
        }));

        return this.compiledExtraRoutes;
    }

    protected mergeDefaults(partial: PartialDefaults<Output, Defaults>): Output {
        if (this.defaults === undefined) return partial as Output;
        const defaults = this.defaults();

        const keys = [ ...Object.keys(partial), ...Object.keys(defaults) ] as (keyof Output & string)[];

        const withDefaults = {} as Output;

        for (const key of keys) {
            // @ts-ignore
            const value = partial[key];
            // @ts-ignore
            const defaultValue = defaults[key];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            withDefaults[key] = value === undefined ? defaultValue : value as any;
        }

        return withDefaults;
    }

    protected regionIdDefault() {
        return RegionIdEnum.MSK;
    }

    onlyRelativeUrl() {
        return this.context?.onlyRelativeUrl ?? false;
    }

    /**
     * При изменении регионов тут, надо поддержать на балансере, дополнив server_name новыми регионами
     * https://gitlab.m2.ru/vtblife/devops/application-lb/-/blob/master/etc/nginx/conf.d/m2.ru.conf
     */
    protected regionIdHost(regionSlug: string) {
        return undefined as undefined | number;
        // if (regionSlug === 'ekb') return 993;
        // if (regionSlug === 'nn') return 1961;
    }

    /**
     * 991 - Свердловская обл, 1959 - нижегородская обл
     */
    protected regionSlugHost(regionId: number): string | undefined {
        return undefined as undefined | string;
        // if (regionId === 993 || regionId === 991) return 'ekb';
        // if (regionId === 1961 || regionId === 1959) return 'nn';
    }

    isDomainOnlySlug(id: number) {
        return undefined;
        // return id === 993 || id === 1961;
    }

    protected regionSlugPath(regionId: number) {
        return undefined as string | undefined;
    }

    hostParts() {
        const loc = this.router.location;

        if (! loc) return undefined;

        let prefix = '';
        let regionId = undefined as number | undefined;
        let suffix = loc.host;
        const parts = loc.host.split('.');

        if (parts.length === 3) {
            // Последняя часть домена, которая передается без изменений
            // test-m2.ru из branch-bla--ekb.test-m2.ru
            suffix = parts[1] + '.' + parts[2];
            // Для хостов с ветками - ветка и регион разделены через --
            // branch-bla и ekb из branch-bla--ekb.test-m2.ru
            const level3 = parts[0].split('--');

            let regionSlug = '';

            if (level3.length < 2) {
                // ekb из ekb.test-m2.ru
                regionSlug = level3[0] ?? '';
            } else {
                // branch-bla и ekb из branch-bla--ekb.test-m2.ru
                prefix = level3[0];
                regionSlug = level3[1];
            }

            const detectedRegionId = regionSlug ? this.regionIdHost(regionSlug) : undefined;

            if (detectedRegionId) regionId = detectedRegionId;

            // Если регион не поддерживается, в хостах вида some.test-m2.ru, то some - это prefix.
            // В хостах branch--some.test-m2.ru, будет редирект на урл без --some.
            // Если в pathname не будет региона, в ClassifiedRoute.regionId вставится дефолт (moskva)
            if (! detectedRegionId && ! prefix) prefix = regionSlug;
        }

        return {
            /**
             * true, если хост только одного уровня, вида localhost
             */
            isSingleDomain: parts.length === 1,
            protocol: loc.protocol ?? 'http:',
            /**
             * Часть хоста перед регионом или доменом второго уровня
             * branch-1231 для хостов вида branch-rs-1231--ekb.test-m2.ru или branch-rs-1231.test-m2.ru
             */
            prefix,
            regionId,
            /**
             * Первый и второй уровни домена из хоста или весь хост, если регион не найден
             */
            suffix
        };
    }

    /**
     * Использутся в toQuery - подготовке параметров для сусанина
     * если на выходе origin пустой - будет относительная ссылка, как раньше.
     */
    regionParams(regionId?: number | null) {
        const isDomainDisabled = this.onlyRelativeUrl();
        const regionSlugPath = regionId && (! this.isDomainOnlySlug(regionId) || isDomainDisabled) ?
            this.regionSlugPath(regionId) :
            undefined;

        const regionSlugHost = regionId && ! isDomainDisabled ?
            this.regionSlugHost(regionId) :
            undefined;

        const parts = this.hostParts();

        // если location не просетили в роутер, parts будет пустой, то фолбэк на регионы-папки
        if (! parts) return { origin: undefined, region: regionSlugPath };

        // const region = regionSlugHost ?? '';
        const separator = parts.prefix && regionSlugHost ? '--' : '';
        const dot = regionSlugHost || parts.prefix ? '.' : '';
        // если был хост вида localhost, а потом выбрали хост-регион, то урл станет вида ekb.m2.localhost
        // Для единообразния и упрощения алгоритма в hostParts
        const m2 = regionSlugHost && parts.isSingleDomain ? 'm2.' : '';

        // Если в контексте preferRelativeUrl = true и регион вычисленный равен региону в урле (из location в конструкторе роута, передается из роутера или вручную)
        // то генерить относительную ссылку, например для снипетов на странице, что бы лишнего не передавать в html
        // По-умолчанию false
        const origin = isDomainDisabled || (this.context?.preferRelativeUrl && parts.regionId === regionId) ?
            undefined :
            `${parts.protocol}//${parts.prefix}${separator}${regionSlugHost ?? ''}${dot}${m2}${parts.suffix}`;

        return {
            origin,
            region: regionSlugPath
        };
    }

    toString() {
        return `${this.constructor.name} ${this.susaninRoute.getName()}`;
    }

    url(params: PartialDefaults<Output, Defaults>): string {
        const url = this.susaninRoute.build(this.mergeDefaults(params));

        return url;
    }

    protected prevKey = ''
    protected prevParams: Output | undefined = undefined

    params(loc?: LocationLike): Output {
        const location = loc ?? this.router.location;
        const paramsTmp = loc ? undefined : this.paramsTmp;

        if (! location) throw new Error('Location not provided in SusaninRoute constructor');
        const url = this.patternHost() ?
            location.origin + location.pathname + location.search :
            location.pathname + location.search;

        const key = paramsTmp ?
            JSON.stringify(this.paramsTmp) :
            url;

        if (this.prevKey === key && this.prevParams) return this.prevParams;

        let params: Output | undefined | null;

        if (paramsTmp) {
            params = this.mergeDefaults(paramsTmp);
        } else {
            params = this.susaninRoute.match(url);

            if (! params) {
                for (const extraRoute of this.extraRoutes) {
                    params = extraRoute.match(url);

                    if (params) break;
                }

                if (! params) {
                    throw new ErrorNotFound('not matched');
                }
            }
        }

        this.prevKey = key;
        this.prevParams = params;

        return params;
    }
}
