import { yMapIsVector, YMapVector, yMapVectorIsEqual, yMapVectorRound } from './Vector';

export type YMapBounds = {
    min: YMapVector;
    max: YMapVector;
}

export type YMapBoundsZoom = YMapBounds & {
    zoom: number;
    center: YMapVector;
};

export type YMapMargin = {
    topLeft: YMapVector;
    bottomRight: YMapVector;
}

export function yMapBoundsFromArray(bounds: number[][]): YMapBounds {
    const [ min, max ] = bounds;

    return {
        min: {
            x: min[1],
            y: min[0]
        },
        max: {
            x: max[1],
            y: max[0]
        }
    };
}

export function yMapBoundsFromVectors(
    coordinates: readonly YMapVector[],
    delta = 0.001,
    deviation = 3,
    precision?: number
): YMapBounds | undefined {
    if (coordinates.length === 0) return undefined;

    const first = coordinates[0];

    const min = { x: first.x - delta, y: first.y - delta };
    const max = { x: first.x + delta, y: first.y + delta };

    for (const coordinate of coordinates) {
        const x = coordinate.x;
        const y = coordinate.y;

        if (Math.abs(min.x - x) > deviation) {
            continue;
        }

        if (Math.abs(min.y - y) > deviation) {
            continue;
        }

        if (x < min.x) min.x = x;
        if (y < min.y) min.y = y;
        if (x > max.x) max.x = x;
        if (y > max.y) max.y = y;
    }

    return yMapBoundsRound({ min, max }, precision);
}

export function yMapBoundsIsEqual(a?: YMapBounds, b?: YMapBounds, precision?: number): boolean {
    if (! a && ! b) return true;
    if ((a && ! b) || (! a && b)) return false;

    return yMapVectorIsEqual(a!.max, b!.max, precision) && yMapVectorIsEqual(a!.min, b!.min, precision);
}

export function yMapCenterIsEqual(a: readonly [number, number], b: readonly [number, number]): boolean {
    if (! a && ! b) return true;
    if ((a && b === undefined) || (a === undefined && b)) return false;

    return a[0] === b[0] && a[1] === b[1];
}

export function yMapBoundsRound(src: YMapBounds, precision?: number): YMapBounds {
    return {
        min: yMapVectorRound(src.min, precision),
        max: yMapVectorRound(src.max, precision)
    };
}

export function yMapIsBounds(some: Object): some is YMapBounds {
    return (some && typeof some === 'object' &&
        (some as YMapBounds).min !== undefined && (some as YMapBounds).max !== undefined);
}

export function yMapIsMargin(some: object): some is YMapMargin {
    return (some && typeof some === 'object' &&
        (some as YMapMargin).bottomRight !== undefined && (some as YMapMargin).topLeft !== undefined);
}

export function yMapMarginToTuple(some: YMapMargin | YMapVector | number): number | number[] {
    if (typeof some === 'number') {
        return some;
    }

    if (yMapIsMargin(some)) {
        return [
            some.topLeft.y,
            some.bottomRight.x,
            some.bottomRight.y,
            some.topLeft.x
        ];
    }

    if (yMapIsVector(some)) {
        return [
            some.y,
            some.x
        ];
    }

    throw new Error('Margin should be YMapMargin, YMapVector or number');
}
