import React, { useCallback } from 'react';

import { Button, Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';
import { CallbackModal, CallbackDataType } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';

import './styles.css';

const cn = classname.bind(null, 'QuickSelectionCardBanner');

const SERVICE_LIST = [
    'Найдём оптимальный вариант под\u00A0ваши требования',
    'Расскажем о\u00A0скидках от\u00A0застройщика',
    'Подберём ипотечную программу под\u00A0любые условия'
];

const QuickSelectionCardBanner: React.FC<{
    callbackData: CallbackDataType;
    className?: string;
}> = ({
    callbackData,
    className
}) => {
    const renderBanner = useCallback((openModalCallback: () => void) => (
        <div
            onClick={openModalCallback}
            className={cn(null, undefined, className)}
        >
            <div>
                <Typography variant='h3'>
                    Покупка&nbsp;квартиры через&nbsp;m2.ru&nbsp;— это&nbsp;просто
                </Typography>
                <div className={cn('serviceList')}>
                    {SERVICE_LIST.map((item, idx, array) => (
                        <Typography
                            key={idx}
                            variant='primary'
                            className={cn('service')}
                        >
                            <span className={cn('iconWrapper')}>
                                <Icon
                                    size='s'
                                    name='check'
                                />
                            </span>
                            {`${item}${idx < array.length - 1 ? ';' : '.'}`}
                        </Typography>
                    ))}
                </div>
                <div className={cn('button')}>
                    <Button variant='promo1'>
                        Подобрать квартиру
                    </Button>
                </div>
            </div>
            <div className={cn('image')} />
        </div>
    ), [ className ]);

    return (
        <CallbackModal
            gtmShowModal='nb_banner_click_selection'
            renderTrigger={renderBanner}
            {...callbackData}
        />
    );
};

export default QuickSelectionCardBanner;
