import * as React from 'react';

import classname from '@search/classname/src';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import { SvgSpriteIcon } from '../../../../../client/common/components/SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'Header');

const Header: React.FunctionComponent<{ isFullWidth: boolean }> = ({ isFullWidth }) => {
    return (
        <div className={cn(null, { wide: isFullWidth })}>
            <div className={cn('primary')}>
                <div className={cn('inner')}>
                    <div className={cn('logo')}>
                        <SvgSpriteIcon
                            name='LogoVTB'
                            width={98}
                            height={36}
                        />
                    </div>
                    <CustomTypography
                        tag='a'
                        href='/'
                        color='primary'
                    >
                        Каталог аккредитованных новостроек
                    </CustomTypography>
                </div>
            </div>
        </div>
    );
};

export default Header;
