/* eslint-disable @typescript-eslint/naming-convention */
/* global ymaps, window */
import { yMapBoundsFromArray } from '../bounds';
import { YMapVector, yMapVectorRound } from '../Vector';

export const defaultUnwantedBehavours = [ 'drag', 'scrollZoom', 'rightMouseButtonMagnifier' ];

export interface YMapDrawAreaManagerParams {
    api: typeof ymaps;
    map: ymaps.Map;
    document: Document;
    eventsPaneZIndex?: number;
    strokeColor?: string;
    strokeWidth?: number;
    strokeOpacity?: number;
    tolerance?: number;

    fillColor?: string;
    fillOpacity?: number;
}

export class YMapDrawAreaManager {
    protected pane: ymaps.pane.EventsPane;
    protected options: Omit<Required<YMapDrawAreaManagerParams>, 'api' | 'map' | 'document' | 'eventsPaneZIndex'>;
    protected api: typeof ymaps;
    protected map: ymaps.Map;
    protected coordinates: YMapVector[] = [];
    protected document: Document;

    constructor(
        {
            api,
            map,
            document,
            eventsPaneZIndex = 500,
            strokeColor = '#0000ff',
            strokeWidth = 1,
            strokeOpacity = 1,
            tolerance = 16,
            fillColor = '#000000',
            fillOpacity = 0.3
        }: YMapDrawAreaManagerParams
    ) {
        this.document = document;
        this.map = map;
        this.api = api;
        this.options = {
            tolerance,
            strokeColor,
            strokeWidth,
            strokeOpacity,
            fillOpacity,
            fillColor
        };

        this.pane = new this.api.pane.EventsPane(this.map, {
            css: { position: 'absolute', width: '100%', height: '100%', cursor: 'pointer' },
            zIndex: eventsPaneZIndex + 50,
            transparent: true
        });
        this.init();
    }

    init() {
        const canvas = this.initCanvas();

        this.pane.getElement().appendChild(canvas);
        this.map.panes.append('ext-paint-on-map', this.pane);
    }

    start() {
        this._canvas?.addEventListener('mousemove', this._onMouseMove);
        this._canvas?.addEventListener('touchmove', this._onMouseMove);

        this.draw();
    }

    destructor() {
        const { map, pane } = this;
        const canvas = this._canvas;

        if (! canvas) return;

        canvas.removeEventListener('mousemove', this._onMouseMove);
        canvas.removeEventListener('touchmove', this._onMouseMove);
        this.pane.getElement().removeChild(canvas);

        this._canvas = undefined;

        map.panes.remove(pane);
    }

    protected _onMouseMove = this.onMouseMove.bind(this);
    private onMouseMove(e: MouseEvent | TouchEvent) {
        if ('touches' in e) {
            const touch = e.touches[0];

            this.addPosition({ x: touch.pageX, y: touch.pageY - this.getYOffset() });
        } else if ('offsetX' in e && 'offsetY' in e) {
            this.addPosition({ x: e.offsetX, y: e.offsetY });
        }
        this.draw();
    }

    protected _canvas: HTMLCanvasElement | undefined = undefined;

    protected getYOffset() {
        return window.innerHeight - this.canvas.height;
    }

    protected initCanvas() {
        const canvas = this._canvas = this.document.createElement('canvas') as HTMLCanvasElement;
        const rect = this.map.container.getParentElement().getBoundingClientRect();

        canvas.width = rect.width;
        canvas.height = rect.height;
        canvas.style.width = '100%';
        canvas.style.height = '100%';

        return this._canvas;
    }

    protected get canvas() {
        if (this._canvas) return this._canvas;

        return this.initCanvas();
    }

    protected _ctx2d: CanvasRenderingContext2D | undefined = undefined;

    get ctx2d() {
        if (this._ctx2d) return this._ctx2d;
        const ctx2d = this.canvas.getContext('2d');

        if (! ctx2d) {
            throw new Error('Cant\'t get 2d context');
        }

        this._ctx2d = ctx2d;

        const options = this.options;

        ctx2d.globalAlpha = options.strokeOpacity;
        ctx2d.strokeStyle = options.strokeColor;
        ctx2d.lineWidth = options.strokeWidth;

        return this._ctx2d;
    }

    protected draw() {
        const { coordinates, canvas, ctx2d } = this;

        if (coordinates.length === 0) return;

        ctx2d.clearRect(0, 0, canvas.width, canvas.height);
        ctx2d.beginPath();

        const firstCoordinate = coordinates[0];

        ctx2d.moveTo(firstCoordinate.x, firstCoordinate.y);

        for (const coordinate of coordinates) {
            ctx2d.lineTo(coordinate.x, coordinate.y);
        }

        ctx2d.stroke();
    }

    addPosition(position: YMapVector) {
        this.coordinates.push(position);
    }

    get geoCoordinates(): YMapVector[] {
        const { map, canvas } = this;
        const bounds = yMapBoundsFromArray(map.getBounds());

        const diff = {
            x: bounds.max.x - bounds.min.x,
            y: bounds.max.y - bounds.min.y
        };

        return this.simplifiedCoodinates.map(coordinate => {
            return yMapVectorRound({
                x: bounds.min.x + (coordinate.x / canvas.width) * diff.x,
                y: bounds.min.y + (1 - coordinate.y / canvas.height) * diff.y
            });
        });
    }

    protected get simplifiedCoodinates(): YMapVector[] {
        const { options, coordinates } = this;

        if (coordinates.length === 0) return [] as YMapVector[];

        let toleranceSquared = options.tolerance * options.tolerance;

        let prev = coordinates[0];
        let simplified = [ prev ];

        do {
            simplified = [ prev ];

            for (let i = 1; i < coordinates.length; i++) {
                const coordinate = coordinates[i];
                const sqr = Math.pow(prev.x - coordinate.x, 2) + Math.pow(prev.y - coordinate.y, 2);

                if (sqr > toleranceSquared) {
                    simplified.push(coordinate);
                    prev = coordinate;
                }
            }

            simplified.push(simplified[0]);
            toleranceSquared *= 2;
        } while (simplified.length > 100);

        return simplified;
    }
}
