import React from 'react';

export function useEnterBlur<E extends HTMLElement>(onKeyPress?: (e: React.KeyboardEvent<E>) => void) {
    return React.useCallback((
        e: React.KeyboardEvent<E> & {
            target: React.KeyboardEvent<E>['target'] & { blur?(): void };
        }
    ) => {
        if (e.key === 'Enter') e.target.blur?.();
        onKeyPress?.(e);
    }, [ onKeyPress ]);
}
