import React from 'react';
import { authInfoDefault, AuthInfoLegacy, authInfoFromLegacy, AuthInfo } from './AuthInfo';
import { useAuth, UseAuthBusInit } from './useAuth';
import { AuthDialog } from './AuthDialog';
import { AuthDelayedPersistRunner } from './AuthDelayedPersistRunner';

const AuthContext = React.createContext({
    auth: authInfoDefault,
    // eslint-disable-next-line
    waitAuth<Result>(cb: (auth?: AuthInfo) => Promise<Result>, opts?: UseAuthBusInit): Promise<Result> { throw new Error('implement'); },
    authScheduler: AuthDelayedPersistRunner.instance,
    isAuthDialogOpened: false,
});

export function M2AuthProvider({
    auth: authInitial,
    withRootAuth = false,
    children
}: {
    auth?: AuthInfoLegacy;
    children?: React.ReactNode;
    withRootAuth?: boolean;
}) {
    const { auth, isAuthDialogOpened, waitAuth, authScheduler } = useAuth({
        authInitial: authInfoFromLegacy(authInitial),
        enableLogging: false,
        withRootAuth
    });

    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <AuthContext.Provider value={{ auth, waitAuth, authScheduler: authScheduler as any, isAuthDialogOpened }}>
            {! withRootAuth && isAuthDialogOpened ? <AuthDialog /> : null}
            {children}
        </AuthContext.Provider>
    );
}

export function useM2AuthContext() {
    return React.useContext(AuthContext);
}
