/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
export enum DealTypeEnum {
    Unknown = 'UNKNOWN',
    Sell = 'SELL',
    Rent = 'RENT'
}

export enum RealtyObjectEnum {
    Unknown = 'UNKNOWN',
    Flat = 'FLAT',
    Room = 'ROOM',
    House = 'HOUSE',
    Land = 'LAND',
    CountryEstate = 'COUNTRY_ESTATE'
}

export enum NewBuildingEnum {
    Unknown = 'UNKNOWN',
    New = 'NEW',
    Second = 'SECOND'
}

export enum RoomsEnum {
    Unknown = 'UNKNOWN',
    Studio = 'STUDIO',
    Room_1 = 'ROOM_1',
    Room_2 = 'ROOM_2',
    Room_3 = 'ROOM_3',
    Room_4 = 'ROOM_4',
    Room_5AndMore = 'ROOM_5_AND_MORE',
    OpenPlan = 'OPEN_PLAN',
    Room_4AndMore = 'ROOM_4_AND_MORE'
}
export enum RoomsOfferedOptionsEnum {
    Unknown = 'UNKNOWN',
    Room_1 = 'ROOM_1',
    Room_2 = 'ROOM_2',
    Room_3 = 'ROOM_3',
    Room_4AndMore = 'ROOM_4_AND_MORE'
}
export enum AreaUnitEnum {
    Unknown = 'UNKNOWN',
    Meter = 'METER',
    Are = 'ARE',
    Hectare = 'HECTARE'
}
export enum TransportTimeLimitEnum {
    Unknown = 'UNKNOWN',
    Max_5Minutes = 'MAX_5_MINUTES',
    Max_10Minutes = 'MAX_10_MINUTES',
    Max_15Minutes = 'MAX_15_MINUTES',
    Max_20Minutes = 'MAX_20_MINUTES',
    Max_25Minutes = 'MAX_25_MINUTES',
    Max_30Minutes = 'MAX_30_MINUTES',
    Max_40Minutes = 'MAX_40_MINUTES',
    Max_50Minutes = 'MAX_50_MINUTES',
    Max_60Minutes = 'MAX_60_MINUTES'
}
export enum TransportTypeEnum {
    Unknown = 'UNKNOWN',
    Foot = 'FOOT',
    PublicTransport = 'PUBLIC_TRANSPORT'
}
export enum FloorConstraintsEnum {
    Unknown = 'UNKNOWN',
    Only = 'ONLY',
    Exclude = 'EXCLUDE'
}
export enum CeilingHeightEnum {
    Unknown = 'UNKNOWN',
    From_2_5 = 'FROM_2_5',
    From_2_7 = 'FROM_2_7',
    From_3 = 'FROM_3',
    From_4 = 'FROM_4'
}
export enum RenovationEnum {
    Unknown = 'UNKNOWN',
    Euro = 'EURO',
    Cosmetic = 'COSMETIC',
    Designed = 'DESIGNED',
    Need = 'NEED',
    None = 'NONE'
}
export enum BathroomEnum {
    Unknown = 'UNKNOWN',
    Combined = 'COMBINED',
    Parted = 'PARTED',
    Several = 'SEVERAL'
}
export enum IsApartmentsOptionsEnum {
    Unknown = 'UNKNOWN',
    NoApartments = 'NO_APARTMENTS',
    ApartmentsOnly = 'APARTMENTS_ONLY'
}
export enum BalconyOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum ParkingEnum {
    Unknown = 'UNKNOWN',
    Closed = 'CLOSED',
    Underground = 'UNDERGROUND',
    Open = 'OPEN'
}
export enum WallsTypeEnum {
    Unknown = 'UNKNOWN',
    Monolith = 'MONOLITH',
    BrickMonolith = 'BRICK_MONOLITH',
    Brick = 'BRICK',
    Panel = 'PANEL',
    Block = 'BLOCK',
    Wood = 'WOOD'
}
export enum LiftOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum InfrastructureOptionsEnum {
    Unknown = 'UNKNOWN',
    ClosedArea = 'CLOSED_AREA',
    Concierge = 'CONCIERGE'
}
export enum AuthorTypeOptionsEnum {
    Unknown = 'UNKNOWN',
    Owner = 'OWNER',
    Agency = 'AGENCY',
    Partner = 'PARTNER',
    Developer = 'DEVELOPER',
    Pit = 'PIT'
}
export enum SellTypeEnum {
    Unknown = 'UNKNOWN',
    Primary = 'PRIMARY',
    PrimaryReassignment = 'PRIMARY_REASSIGNMENT',
    Secondary = 'SECONDARY',
    SecondaryPrimary = 'SECONDARY_PRIMARY',
    Alternative = 'ALTERNATIVE'
}
export enum PhotoOptionsEnum {
    Unknown = 'UNKNOWN',
    WithPhoto = 'WITH_PHOTO'
}
export enum RenovationTypeEnum {
    Unknown = 'UNKNOWN',
    Design = 'DESIGN',
    Euro = 'EURO',
    Need = 'NEED',
    Good = 'GOOD',
    Partial = 'PARTIAL',
    PreFine = 'PRE_FINE',
    Fine = 'FINE',
    Rough = 'ROUGH'
}
export enum LayoutImageOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum LandStatusEnum {
    Unknown = 'UNKNOWN',
    Izhs = 'IZHS',
    Snt = 'SNT',
    Dnp = 'DNP',
    Industrial = 'INDUSTRIAL',
    Agricultural = 'AGRICULTURAL',
    Invest = 'INVEST'
}
export enum HouseCategoryEnum {
    Unknown = 'UNKNOWN',
    Standalone = 'STANDALONE',
    PartOfHouse = 'PART_OF_HOUSE',
    Duplex = 'DUPLEX',
    Townhouse = 'TOWNHOUSE'
}
export enum GasOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum PaymentTypeEnum {
    Unknown = 'UNKNOWN',
    NoPrepayment = 'NO_PREPAYMENT',
    UpTo_1Price = 'UP_TO_1_PRICE',
    UpTo_2Price = 'UP_TO_2_PRICE',
    UpTo_3Price = 'UP_TO_3_PRICE'
}
export enum WaterOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum SewerageOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum HeatingOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum ElectricityOptionsEnum {
    Unknown = 'UNKNOWN',
    HasAny = 'HAS_ANY'
}
export enum BathroomLocationEnum {
    Unknown = 'UNKNOWN',
    Inside = 'INSIDE',
    Outside = 'OUTSIDE'
}
export enum LivingFacilitiesOptionsEnum {
    Unknown = 'UNKNOWN',
    Internet = 'INTERNET',
    WashingMachine = 'WASHING_MACHINE',
    Fridge = 'FRIDGE',
    Dishwasher = 'DISHWASHER',
    AirConditioner = 'AIR_CONDITIONER',
    Tv = 'TV'
}
export enum FurnitureOptionsEnum {
    Unknown = 'UNKNOWN',
    No = 'NO',
    Kitchen = 'KITCHEN',
    All = 'ALL'
}
export enum LivingTenantsOptionsEnum {
    Unknown = 'UNKNOWN',
    ChildrenAllowed = 'CHILDREN_ALLOWED',
    PetsAllowed = 'PETS_ALLOWED'
}
export enum DepositTypeEnum {
    Unknown = 'UNKNOWN',
    NoDeposit = 'NO_DEPOSIT'
}
export enum CommissionTypeEnum {
    Unknown = 'UNKNOWN',
    NoClientCommission = 'NO_CLIENT_COMMISSION'
}

export enum FinishingFlatEnum {
    NotRelevant = 'NOT_RELEVANT',
    Available = 'AVAILABLE',
    NotAvailable = 'NOT_AVAILABLE'
}

export enum FinishingTypeEnum {
    Fine = 'FINE',
    WithFurniture = 'WITH_FURNITURE',
    TurnKey = 'TURN_KEY',
    PreFinishing = 'PRE_FINISHING',
    WithoutFinishing = 'WITHOUT_FINISHING'
}

export enum FinishingEnum {
    NotRelevant = 'NOT_RELEVANT',
    Available = 'AVAILABLE',
    NotAvailable = 'NOT_AVAILABLE'
}
