/* eslint-disable complexity */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Modal, Select, Button, Group, Link, Typography } from '@vtblife/uikit';
import { Paginator } from '@vtblife/uikit/legacy';

import { NewBuildingWhitelabelEnum, NewBuildingsLinkCloud } from '@search/graphql-typings';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import classname from '@search/classname/src';
import Breadcrumb from '@search/vtbeco-ui/src/components/Breadcrumb';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { formatNumber } from '@search/helpers/src/formatNumber';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import NewBuildingQuiz from '@search/nb-quiz/src/components/common/NewBuildingQuiz';
import { useIdle } from '@search/vtbeco-frontend-core/view/common/hooks/useIdle';
import { NotOnlyM2ProFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { M2PRO_OPTIONS, M2ProEnum } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import type { M2ProEvent } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/m2ProEvent';

import { Shared } from '../../../../types/shared';

import Page from '../../../common/components/Page';
import { useSearchPage } from '../../../common/components/NewBuildingSearchPage/useSearchPage';
import { useFilterSortOptions } from '../../../common/components/NewBuildingSearchPage/useFilterSortOptions';
import useSEOTexts from '../../../common/components/NewBuildingSearchPageSeo/useSEOTexts';
import { useSearchFilters } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';
import { NewBuildingSearchSeoSectionErrorWrapped } from '../../../common/components/NewBuildingSearchSeoSection';
import {
    GlobalContext,
    IGlobalContext,
    IOnChangeGlobalContext,
    OnChangeGlobalContext
} from '../../../common/components/GlobalContext';
import useBreadcrumbs from '../../../common/components/NewBuildingSearchPage/useBreadcrumbs';
/* import {
    convertRegionToSearchId,
    useRTBHouseRemarketing
} from '../../../common/hooks/useRTBHouseRemarketing'; */
import { useCriteoRemarketing } from '../../../common/hooks/useCriteoRemarketing';
import { useIsMskMOSPbLO } from '../../../common/hooks/useIsMskMOSPbLO';
import { SvgSpriteIcon } from '../../../common/components/SvgSpriteIcon';
import NewBuildingAdd from '../../../common/components/NewBuildingAdd';
import LinkToQuizModal from '../../../common/components/LinkToQuizModal';
import useLinkToQuizModal from '../../../common/components/LinkToQuizModal/useLinkToQuizModal';

import NewBuildingSearch from '../NewBuildingSearch';
import { NewBuildingFiltersWrapper } from '../NewBuildingFiltersWrapper';
import { NewBuildingEmptySearch } from './NewBuildingEmptySearch';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchPage');

const M2PRO_OPTIONS_DESKTOP = [
    M2PRO_OPTIONS[M2ProEnum.All],
    M2PRO_OPTIONS[M2ProEnum.M2Pro]
];

const NewBuildingSearchPage: React.FunctionComponent<Shared.NewBuildingSearchPage> = ({
    route,
    params
}) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const globalContext = useContext<IGlobalContext>(GlobalContext);

    const {
        hasDeveloperPromo, searchIndex, hasBreadcrumbs, hasFavoritesButton, hasQuiz, hasNewBuildingAdd, hasQuizIdle
    } = globalContext.instanceConfig;
    const { rootRegions } = globalContext;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);
    const showPromo = Boolean(hasDeveloperPromo) && ! hasM2Pro;

    const {
        data,
        loading,
        clearCache,
        geoStore,
        pageParams,
        handlePageChange,
        handleSortChange,
        pageSize
    } = useSearchPage({
        params,
        route,
        promo: showPromo,
        promoQuiz: searchIndex === NewBuildingWhitelabelEnum.Vtb || showPromo,
        whiteLabel: searchIndex,
        skipFavorites: ! hasFavoritesButton,
        hasEventsOnNewData: true
    });
    const {
        filters,
        resetFilters,
        updateFilter,
        updateFewFilters,
        handleSubmitFilters,
        resetFiltersWithGeo,
        resetFiltersExceptRegion,
        removeFilter,
        removeGeo,
        customFilterBehavior
    } = useSearchFilters({
        route,
        pageParams: params
    });

    /* const { region } = params;

    const searchId = convertRegionToSearchId(region);

    useRTBHouseRemarketing({
        type: 'search',
        id: searchId
    }); */

    const ids = useMemo(
        () => data?.searchNewBuildings?.items
            //@ts-expect-error
            ?.map(item => item?.id)
            .filter(Boolean) ?? [],
        [ data ]
    );

    useCriteoRemarketing({ ids });

    const displayValues = data?.searchNewBuildings?.tips;
    const total = data?.searchNewBuildings?.paging?.total;
    const isEmpty = data?.searchNewBuildings?.isEmpty;
    const placeholders = data?.searchNewBuildings?.specProjectPlaceholders;

    const { title, heading, seoInfo } = useSEOTexts({
        route,
        pageParams,
        geoStore,
        total: total ?? undefined
    });

    useEffect(() => {
        document.title = title;
    }, [ title ]);

    const breadcrumbs = useBreadcrumbs({
        route,
        pageParams,
        displayValues,
        geoStore
    });

    const count = total ?? 0;
    const hasNextPage = total && total > (data?.searchNewBuildings?.paging?.pageSize || 0);

    const mapUrl = useMemo(() => onChangeGlobalContext.linkBuilder.searchMap({
        ...params,

        pageNumber: undefined,
        pageSize: undefined,
        sort: undefined
    }), [ onChangeGlobalContext.linkBuilder, params ]);

    // @ts-expect-error
    const sortOptions = useFilterSortOptions(filters, geoStore.stations);
    const selectedSort = sortOptions.find(item => item.value === pageParams.sort);

    const submitFilters = useCallback((withRefresh?: boolean) => {
        handleSubmitFilters(true);

        withRefresh && clearCache(true);
    }, [ handleSubmitFilters, clearCache ]);

    const regionId = params.region;
    const isMskMOSPbLO = useIsMskMOSPbLO(regionId);

    const [ isQuizModalOpen, toggleQuizModal ] = useState<boolean>(false);
    const openQuizModal = useCallback(() => toggleQuizModal(true), []);
    const closeQuizModal = useCallback(() => toggleQuizModal(false), []);

    const containerRef = useRef<HTMLDivElement>(null);
    const [ isOpenLinkToQuizModal, setIsOpenLinkToQuizModal ] = useLinkToQuizModal(
        containerRef,
        'isLinkToQuizModalShownNBSearch',
        hasQuizIdle && ! hasM2Pro && isMskMOSPbLO
    );

    const { isIdle, resetIdle } = useIdle({ idleTimeout: 40 });

    const shouldShowQuizModal = hasQuizIdle && isMskMOSPbLO && ! hasM2Pro && (isIdle || isOpenLinkToQuizModal);

    const m2ProSelectedOption = useMemo(() => M2PRO_OPTIONS_DESKTOP.find(item => item.value === (
        pageParams.notOnlyM2Pro ? M2ProEnum.All : M2ProEnum.M2Pro
    ))!, [ pageParams.notOnlyM2Pro ]);

    const handleM2ProChange = useCallback((m2ProValue: string | number) => {
        updateFilter(
            new NotOnlyM2ProFilter(m2ProValue === M2ProEnum.All)
        );

        dataLayerPush({
            event: 'M2Pro_rieltors',
            name: 'all_zk_choice',
            page_type: 'serp'
        } as M2ProEvent);
    }, [ updateFilter ]);

    const handleM2ProSelectClick = useCallback(() => {
        dataLayerPush({
            event: 'M2Pro_rieltors',
            name: 'all_zk_click',
            page_type: 'serp'
        } as M2ProEvent);
    }, []);

    const sortComponent = (
        <Select onChange={handleSortChange}>
            <Select.Button
                size='s'
                disabled={! data?.searchNewBuildings?.items.length}
            >
                Сортировка{selectedSort ? `: ${selectedSort.label.toLowerCase()}` : ''}
            </Select.Button>
            <Select.Content adaptivePlacement={false}>
                {sortOptions.map((item, key) => (
                    <Select.Option
                        value={item.value}
                        key={key}
                        selected={item.value === params.sort}
                    >
                        {item.label}
                    </Select.Option>
                ))}
            </Select.Content>
        </Select>
    );

    return (
        <Page
            className={cn()}
            contentClassName={cn('content')}
            regionId={regionId}
            withCustomerFeedback
        >
            {/* @ts-expect-error */}
            <NewBuildingFiltersWrapper {...{
                geoStore,
                count: isEmpty ? 0 : count,
                filters,
                resetFilters,
                updateFilter,
                updateFewFilters,
                customFilterBehavior,
                handleSubmitFilters: submitFilters,
                resetFiltersWithGeo,
                removeFilter,
                removeGeo,
                displayValues,
                isLoading: loading,
                placeholders
            }} />
            {
                hasBreadcrumbs ? (
                    <div className={cn('breadcrumbs')}>
                        <Breadcrumb sections={breadcrumbs.items} withoutSeoMarkup />
                    </div>
                ) : null
            }
            <div className={cn('title', { alone: ! hasBreadcrumbs })}>
                <Typography
                    tag='h1'
                    variant='h1'
                >
                    {heading}
                </Typography>
            </div>
            <div className={cn('nav')}>
                <div className={cn('nav-item', {
                    sort: true,
                    hasM2Pro
                })}>
                    {hasM2Pro ? (
                        <Group size='s'>
                            <Select onChange={handleM2ProChange}>
                                <Select.Button
                                    size='s'
                                    onClick={handleM2ProSelectClick}
                                >
                                    {m2ProSelectedOption.buttonLabel}
                                </Select.Button>
                                <Select.Content adaptivePlacement={false}>
                                    {M2PRO_OPTIONS_DESKTOP.map((option, key) => (
                                        <Select.Option
                                            key={key}
                                            value={option.value}
                                            selected={option.value === m2ProSelectedOption.value}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select.Content>
                            </Select>
                            {sortComponent}
                        </Group>
                    ) : sortComponent}
                </div>
                <div className={cn('nav-item', { map: true })}>
                    <Link href={mapUrl} text={false}>
                        <Button variant='secondary' size='s' icon='map' dataTest='ymap-switch-map' block>
                            На карте
                        </Button>
                    </Link>
                </div>
                {count && ! isEmpty ? <div className={cn('nav-item')}>
                    <Typography
                        className={cn('nav-count')}
                        color='black-400'
                    >
                        {`${formatNumber(count)} ЖК`}
                    </Typography>
                </div> : null}
                {hasQuiz && isMskMOSPbLO && ! hasM2Pro && (
                    <div className={cn('nav-item', { quiz: true, isVtb: searchIndex === NewBuildingWhitelabelEnum.Vtb })}>
                        <Button
                            variant='promo1'
                            size='s'
                            block
                            dataTest='quiz-btn-trigger'
                            onClick={openQuizModal}
                        >
                            <SvgSpriteIcon
                                name='QuizFast'
                                width={24}
                                height={24}
                                style={{ position: 'absolute' }}
                            />
                            <span style={{ marginLeft: '32px' }}>
                                Быстрый подбор ЖК
                            </span>
                        </Button>
                    </div>
                )}
            </div>
            {isEmpty && total ? <NewBuildingEmptySearch resetFilters={resetFiltersExceptRegion} showSimilarText /> : null}
            <NewBuildingSearch
                loading={loading}
                searchNewBuildings={data?.searchNewBuildings ?? null}
                pageParams={params}
                clearCache={clearCache}
                resetFilters={resetFiltersExceptRegion}
                openQuizModal={openQuizModal}
            />
            {
                hasNextPage ? (
                    <div className={cn('paginator')} ref={containerRef}>
                        <Paginator
                            onChange={(page, event) => {
                                event?.preventDefault();
                                window.scrollTo(0, 0);
                                handlePageChange(page);
                            }}
                            page={pageParams.pageNumber!}
                            itemsPerPage={pageSize}
                            buildPageUrl={page => route.url({ ...pageParams, pageNumber: page })}
                            totalItems={count}
                        />
                    </div>
                ) : null
            }
            {
                shouldShowQuizModal ? (
                    <LinkToQuizModal
                        onClose={() => {
                            setIsOpenLinkToQuizModal(false);
                            resetIdle();
                        }}
                        headText='Сложно выбрать? Пройдите тест и&nbsp;получите персональную подборку новостроек'
                        text='Результат за несколько минут'
                        pageType='snippet'
                    />
                ) : null
            }
            {hasNewBuildingAdd && (
                <NewBuildingAdd className={cn('add')} />
            )}
            <NewBuildingSearchSeoSectionErrorWrapped
                view='desktop'
                pageParams={params}
                route={route}
                geoStore={geoStore}
                seoInfo={seoInfo}
                whiteLabel={searchIndex}
                linkCloudParams={data?.newBuildingsLinkCloud as unknown as NewBuildingsLinkCloud}
                developers={data?.searchDevelopers?.items ?? []}
                rating={data?.searchNewBuildings?.rating}
            />
            <Modal
                opened={isQuizModalOpen}
                onClose={closeQuizModal}
                size='l'
            >
                <Modal.Content>
                    <NewBuildingQuiz
                        isInModal
                        rootRegions={rootRegions}
                        whiteLabel={searchIndex}
                    />
                </Modal.Content>
            </Modal>
        </Page>
    );
};

NewBuildingSearchPage.displayName = 'NewBuildingSearchPage';

export default errorCatcherWrap(NewBuildingSearchPage);
