import type { RefObject } from 'react';
import { useCallback } from 'react';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { useGtmOnVisible } from '@search/vtbeco-frontend-core/domain/google/useGtmOnVisible';
import { getDeveloperBannerGtmData } from '@search/vtbeco-frontend-core/domain/newbuilding/developer-promo/analytics';
import { DeveloperPromoPlaceholders } from '@search/graphql-typings/src';

const useDeveloperPromoPlaceholder = ({ newBuildingPlaceholderGtmRef, developerPlaceholderGtmRef, placeholders }: {
    newBuildingPlaceholderGtmRef?: RefObject<HTMLDivElement>;
    developerPlaceholderGtmRef?: RefObject<HTMLDivElement>;
    placeholders?: DeveloperPromoPlaceholders;
}) => {
    const onVisible = useCallback((section: 'JK' | 'developer') => {
        if (! placeholders?.developerName) {
            return;
        }

        const placeholderShowData = getDeveloperBannerGtmData(placeholders.developerName, {
            action: 'view',
            section,
            pageType: 'placeholder'
        });

        placeholderShowData && dataLayerPush(placeholderShowData);
    }, [ placeholders ]);

    useGtmOnVisible({
        containerRef: newBuildingPlaceholderGtmRef,
        onVisible: () => onVisible('JK')
    });

    useGtmOnVisible({
        containerRef: developerPlaceholderGtmRef,
        onVisible: () => onVisible('developer')
    });

    if (placeholders) return placeholders;

    return { developer: undefined, newBuilding: undefined };
};

export default useDeveloperPromoPlaceholder;
