import React from 'react';

const StaticPathContext = React.createContext('');

export function useStaticPathConfig() {
    return React.useContext(StaticPathContext);
}

export function StaticPathProvider({
    staticPath,
    children
}: {
    staticPath: string | undefined;
    children: React.ReactChild;
}) {
    return (
        <StaticPathContext.Provider value={staticPath ?? ''}>
            {children}
        </StaticPathContext.Provider>
    );
}
