import React, { useCallback } from 'react';

import type { IFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import type { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import type { AddQueryDisplayValue, QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { DeveloperPromoPlaceholders } from '@search/graphql-typings/src';

import type { CustomFilterBehavior } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';
import { NewNewBuildingSearchFiltersMainView } from './MainView';
import { NewNewBuildingSearchFiltersMoreButton } from './MoreButton';
import { NewNewBuildingSearchFiltersSubmitButton } from './SubmitButton';

const NewNewBuildingSearchFilters = ({
    className,
    filters,
    updateFilter,
    updateFewFilters,
    submitFilters,
    resetFilters,
    areExtraFiltersVisible,
    showExtraFilters,
    hideExtraFilters,
    customFilterBehavior,
    addQueryDisplayValue,
    queryDisplayValues,
    count,
    geoStore,
    isLoading,
    placeholders
}: {
    className?: string;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateFewFilters: (filter: IFilter[]) => void;
    submitFilters: (withRefresh?: boolean) => void;
    resetFilters: () => void;
    areExtraFiltersVisible: boolean;
    showExtraFilters: () => void;
    hideExtraFilters: () => void;
    customFilterBehavior: CustomFilterBehavior;
    addQueryDisplayValue: AddQueryDisplayValue;
    queryDisplayValues: QueryDisplayValues;
    count: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    isLoading?: boolean;
    placeholders: DeveloperPromoPlaceholders;
}) => {
    const submitFromMainFilters = useCallback(() => {
        submitFilters(true);
    }, [ submitFilters ]);

    const submitFromExtraFilters = useCallback(() => {
        submitFilters(false);

        hideExtraFilters();
    }, [ submitFilters, hideExtraFilters ]);

    return (
        <NewNewBuildingSearchFiltersMainView
            className={className}
            geoStore={geoStore}
            filters={filters}
            updateFilter={updateFilter}
            updateFewFilters={updateFewFilters}
            addQueryDisplayValue={addQueryDisplayValue}
            count={count}
            placeholders={placeholders}
            more={
                <NewNewBuildingSearchFiltersMoreButton
                    filters={filters}
                    updateFilter={updateFilter}
                    submitFilters={submitFromExtraFilters}
                    resetFilters={resetFilters}
                    areExtraFiltersVisible={areExtraFiltersVisible}
                    showExtraFilters={showExtraFilters}
                    hideExtraFilters={hideExtraFilters}
                    customFilterBehavior={customFilterBehavior}
                    addQueryDisplayValue={addQueryDisplayValue}
                    queryDisplayValues={queryDisplayValues}
                    count={count}
                    placeholders={placeholders}
                    isLoading={isLoading}
                />
            }
            buttonSubmit={
                <NewNewBuildingSearchFiltersSubmitButton
                    isLoading={isLoading}
                    submitFilters={submitFromMainFilters}
                    count={count}
                />
            }
        />
    );
};

NewNewBuildingSearchFilters.displayName = 'NewNewBuildingSearchFilters';

export default NewNewBuildingSearchFilters;
