import React from 'react';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'Sticky');

const Sticky: React.FunctionComponent<{
    top?: number;
    visible?: boolean;
    className?: string;
}> = ({
    children,
    top,
    visible = true,
    className
}) => {
    return (
        <div
            className={cn(null, { visible }, className)}
            style={{ top }}
        >
            {children}
        </div>
    );
};

export default Sticky;
