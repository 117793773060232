export function regionFromLocation(l: {
    locality?: { id?: number | null } | null;
    narrowRegion?: {id: number } | null;
}) {
    if (l.locality?.id === 1961 || // nn
        l.locality?.id === 993 // ekb
    ) return l.locality.id;

    return l.narrowRegion?.id;
}
