import React, { useReducer, useRef } from 'react';

import { GalleryElement } from './index';

enum ACTIONS {
    OPEN,
    CLOSE
}

interface IGalleryState {
    /**
     * Состояние открытости галереи
     */
    open: boolean;

    /**
     * Индекс элемента, с которого стартует галерея
     */
    index?: number;

    /**
     * Массив элементов галереи (фотографии, видео)
     */
    elements?: Array<GalleryElement>;

    /**
     * Заголовок
     */
    title?: string;
}

type ContextProps = {
    open: (data: { elements: Array<GalleryElement>; index: number; title?: string }) => void;
    close: () => void;
};

type Action = { type: ACTIONS.CLOSE } | { type: ACTIONS.OPEN; payload: IGalleryState }

export const GalleryContext = React.createContext<ContextProps | null>(null);
export const GalleryContextState = React.createContext<IGalleryState>({ open: false });

function reducer(state: IGalleryState, action: Action): IGalleryState {
    switch (action.type) {
        case ACTIONS.OPEN:
            return {
                ...action.payload,
                open: true
            };

        case ACTIONS.CLOSE:
            return {
                open: false
            };

        default:
            return state;
    }
}

export const GalleryContextProvider: React.FunctionComponent<{}> = props => {
    const [ context, dispatchContext ] = useReducer(reducer, { open: false });
    const actions = useRef<ContextProps>({
        open(data) {
            dispatchContext({
                type: ACTIONS.OPEN,
                payload: Object.assign({ open: true }, data)
            });
        },
        close() {
            dispatchContext({ type: ACTIONS.CLOSE });
        }
    });

    return (
        <GalleryContext.Provider value={actions.current}>
            <GalleryContextState.Provider value={context}>
                {props.children}
            </GalleryContextState.Provider>
        </GalleryContext.Provider>
    );
};
