import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { regionFromLocation } from './regionFromLocation';

export type GeoBase = {
    id: number;
    kind?: 'DISTRICT' | 'LOCALITY' | string;
    translit?: string | null;
    title?: string | null;
    locative?: string | null;
}

export class SlugMapper {
    protected slugToId = new Map<string, GeoBase>();
    protected idToSlug = new Map<number, GeoBase>();

    id(slug: string, regionId: number) {
        return this.slugToId.get(slug + regionId);
    }

    slug(id: number) {
        return this.idToSlug.get(id);
    }

    add(geo: GeoBase, regionId: number) {
        if (! geo.translit) return;
        if (geo.kind === 'HOUSE') return;
        if (this.idToSlug.get(geo.id)) return;

        this.slugToId.set(geo.translit + regionId, geo);
        this.idToSlug.set(geo.id, geo);
    }

    addLocation(location: {
        clickableAddress?: readonly GeoBase[] | null;
        narrowRegion?: GeoBase | null;
        wideDistrict?: GeoBase | null;
        narrowDistrict?: GeoBase | null;
        house?: GeoBase | null;
        locality?: GeoBase | null;
        street?: GeoBase | null;
    }) {
        const regionId = regionFromLocation(location) ?? RegionIdEnum.MSK;

        location.wideDistrict && this.add({ ...location.wideDistrict, kind: 'DISTRICT' }, regionId);
        location.narrowDistrict && this.add({ ...location.narrowDistrict, kind: 'DISTRICT' }, regionId);
        [
            ...location.clickableAddress?.filter(geo => geo.kind !== 'HOUSE') ?? [],
            location.house,
            location.locality,
            location.street
        ].forEach(
            geo => geo && this.add(geo, regionId)
        );
    }
}
