import { CurrencyEnum } from '@search/graphql-typings';

import { formatLocaleNumber, abbreviateNumber } from '../formatNumber';

const currencyEnumToPostfix: Record<CurrencyEnum, string> = {
    [CurrencyEnum.Unknown]: '',
    [CurrencyEnum.Rub]: '\u00A0₽',
    [CurrencyEnum.Usd]: '\u00A0$',
    [CurrencyEnum.Eur]: '\u00A0€'
};

export interface FormatPriceOptions {
    value: number;
    currency: CurrencyEnum;
    noFractional?: boolean;
    inWords?: boolean;
    displayCurrency?: boolean;
}

/**
 * Возвращает человекопонятную запись цены
 * @param {Object} options
 * @param {number} options.value Цена в копейках/центах (!)
 * @param {CurrencyEnum} options.currency
 * @param {boolean} [options.noFractional = false]
 * @param {boolean} [options.inWords = false]
 * @param {boolean} [options.displayCurrency]
 * @returns {string}
 * @example
 * formatPrice({
 *     value: 891234567,
 *     currency: Currency.RUB,
 *     noFractional: true,
 *     inWords: true,
 *     displayCurrency: true
 * }) -> '9 млн ₽'
 */
export const formatPrice = ({
    value: val,
    currency,
    noFractional = false,
    inWords = false,
    displayCurrency = false
}: FormatPriceOptions) : string => {
    let value: number | string = val / 1e2;
    let type = '';

    if (inWords) {
        const abbreviatedNumber = abbreviateNumber(value, { thousandsAsNumber: true });

        value = abbreviatedNumber.value as number;
        type = abbreviatedNumber.type;
    }

    value = [
        formatLocaleNumber(value, noFractional ? 0 : 2),
        type
    ]
        .filter(Boolean)
        .join('\u00A0');

    if (displayCurrency) {
        return `${value}${currencyEnumToPostfix[currency]}`;
    }

    return value;
};
