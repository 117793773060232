/* eslint-disable no-console */
/* global sessionStorage */
/* global window */
import React from 'react';

const sessionCache = new Map<string, unknown>();

// Скопировал в common-core

export function useSessionStorage<Value>(namespaceRaw: string) {
    const namespace = namespaceRaw + '.';

    const [ count, setCount ] = React.useState(0);

    const value = React.useCallback((key: string, parsed?: Value | null) => {
        const k = namespace + key;

        if (parsed === null) {
            sessionCache.delete(k);
            sessionStorage.removeItem(k);

            return parsed;
        }

        if (parsed === undefined) {
            if (sessionCache.has(k)) return sessionCache.get(k) as Value;

            const serialized = sessionStorage.getItem(k);

            if (serialized === undefined || serialized === null) return undefined;

            parsed = JSON.parse(serialized) as Value;
        }
        sessionCache.set(k, parsed);
        if (parsed === undefined) return;

        try {
            sessionStorage.setItem(k, JSON.stringify(parsed));
        } catch (e) {
            // limit error
            console.warn(e);
            sessionStorage.removeItem(k);
        }

        return parsed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ namespace, count ]);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const cb = (e: StorageEvent) => {
            sessionCache.clear();
            setCount(c => c + 1);
        };

        window.addEventListener('storage', cb);

        return () => {
            window.removeEventListener('storage', cb);
        };
    }, []);

    return value;
}

export function useOfferSessionVisited() {
    return useSessionStorage<boolean>('OfferVisited');
}

export function useOfferMarkSessionVisited() {
    return useSessionStorage<boolean>('YMapPlacemarkTooltip');
}

