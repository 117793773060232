import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Button, Link, Typography } from '@vtblife/uikit';

import type { DeveloperSnippet as GQLDeveloperSnippet } from '@search/graphql-typings';

import classname from '@search/classname/src';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';

import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { GalleryMobile2 } from '@search/vtbeco-frontend-core/view/common/components/gallery/GalleryMobile2/GalleryMobile2';

import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';

import { IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';
import { SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'DeveloperSnippet');

interface IDeveloperSnippet {
    developer: GQLDeveloperSnippet;
    isMobile?: boolean;
    regionId?: number | null;
}

const DeveloperSnippet: React.FunctionComponent<IDeveloperSnippet> = ({
    developer,
    isMobile = false/*,
    regionId*/
}) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const url = useMemo(() => onChangeGlobalContext.linkBuilder.developer!({
        id: developer.id,
        name: developer.translitName/*,
        region: regionId ?? undefined*/
    }), [ developer/*, regionId*/, onChangeGlobalContext.linkBuilder.developer ]);

    const images = useMemo(() => developer.images?.map((photo, index) => ({
        id: index,
        originPath: photo.originPath
    })) ?? [], [ developer.images ]);

    const [ isHover, setIsHover ] = useState(false);
    const hoverSnippet = () => setIsHover(true);
    const blurSnippet = () => setIsHover(false);

    const handleClick = useCallback(() => {
        window.open(url, '_blank');
    }, [ url ]);

    const { total, commissioned, inProgress } = developer;

    const stats = ([ {
        count: total,
        text: 'Всего ЖК'
    }, {
        count: commissioned,
        text: 'Сдано'
    }, {
        count: inProgress,
        text: 'Строится'
    } ] as {
        count: number;
        text: string;
    }[])
        .filter(({ count }) => count)
        .map(({ count, text }) => (
            <div
                key={text}
                className={cn('section', { stat: true })}
            >
                <Typography variant='primary'>
                    {text}
                </Typography>
                <Typography variant='primary'>
                    {
                        formatLocaleNumber(count)
                    } {
                        declensionByNumber(count, [ 'объект', 'объекта', 'объектов' ])
                    }
                </Typography>
            </div>
        ));

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: developer.baseUrl });

    return (
        <div
            className={cn(null, { isMobile })}
            onMouseEnter={hoverSnippet}
            onMouseLeave={blurSnippet}
        >
            <ImageLazySrcSetProvide getUrl={getUrl}>
                <div
                    onClick={handleClick}
                    className={cn('galleryWrapper')}
                >
                    <GalleryMobile2
                        isNewBuilding
                        withNavButtons={! isMobile}
                        hovered={isHover}
                        pictures={images.map(pic => ({
                            originPath: pic.originPath,
                            description: developer.name
                        }))}
                        maxPicturesCount={8}
                        withSpace={! isMobile}
                    />
                </div>
            </ImageLazySrcSetProvide>
            <div className={cn('section', { top: true })}>
                <Link
                    href={url}
                    target='blank'
                >
                    <Typography variant='h4'>{developer.name}</Typography>
                </Link>
                <div className={cn('icon')}>
                    {
                        developer.icon?.origin ? (
                            <Image
                                type='contain'
                                width={40}
                                height={40}
                                url={developer.icon.origin}
                            />
                        ) : (
                            <SvgSpriteIcon
                                name='CustomDeveloper'
                                width={40}
                                height={40}
                            />
                        )
                    }
                </div>
            </div>
            {stats}
            <div className={cn('buttonWrapper')}>
                <Button
                    fullWidth
                    variant={isHover || isMobile ? 'primary' : 'secondary'}
                    size={isMobile ? 's' : 'm'}
                    onClick={handleClick}
                >
                    Все новостройки
                </Button>
            </div>
        </div>
    );
};

DeveloperSnippet.displayName = 'DeveloperSnippet';

export default DeveloperSnippet;

