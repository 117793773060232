import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import classname from '@search/classname/src';

import { useGql } from '@search/gql-client/src';

import Slider from '@search/vtbeco-frontend-core/view/common/components/Slider';
import { errorCatcherWrap } from '@search/error/src/catcher';

import type { Required } from '../../../../../types';

import { GlobalContext } from '../../../../common/components/GlobalContext';
import type { IGlobalContext } from '../../../../common/components/GlobalContext';
import { NewBuildingTinySnippet, NewBuildingTinySnippetStub } from '../../../../common/components/NewBuildingTinySnippet';
import { otherNewBuildingsByDeveloperQuery } from '../../../../common/components/NewBuildingCard';
import type {
    NewBuildingsInput,
    NewBuildingCardOtherNewBuildingsByDeveloperQueryVariables,
    NewBuildingCardOtherNewBuildingsByDeveloperQueryResponse
} from '../../../../common/components/NewBuildingCard';

import './styles.css';

const NewBuildingTinySnippetWithCatcher = errorCatcherWrap(NewBuildingTinySnippet);

const cn = classname.bind(null, 'NewBuildingCardOthersByDeveloper');

const PAGE_SIZE = 4;
const REQUEST_PAGE_SIZE = 2 * PAGE_SIZE + 1;

type Items = NonNullable<
    NonNullable<
        NewBuildingCardOtherNewBuildingsByDeveloperQueryResponse['searchNewBuildings']
    >['items']
>;

const NewBuildingCardOthersByDeveloper = ({
    heading,
    developerId,
    newBuildingId: newBuildingIdToSkip,
    regionIds: [ regionId, backupRegionId ]
}: {
    heading: React.ReactNode;
    developerId?: number;
    newBuildingId: number;
    regionIds: number[];
}) => {
    const [ pageNumber, setPageNumber ] = useState<number>(1);
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { data } = useGql<
        NewBuildingCardOtherNewBuildingsByDeveloperQueryVariables,
        NewBuildingCardOtherNewBuildingsByDeveloperQueryResponse
    >(otherNewBuildingsByDeveloperQuery, {
        isOthersFromDeveloper: true,
        touch: false,
        whiteLabel: globalContext.instanceConfig.searchIndex,
        filters: {
            newBuildingIdToSkip,
            regionId,
            backupRegionId,
            developerId,
            closedSales: true
        } as unknown as Required<NewBuildingsInput>,
        paging: {
            pageNumber,
            pageSize: REQUEST_PAGE_SIZE
        }
    });
    const newBuildings = data?.searchNewBuildings;

    const newBuildingIdToSkipString = useMemo(() => String(newBuildingIdToSkip), [
        newBuildingIdToSkip
    ]);
    const skipNewBuilding = useCallback(
        (items: Items | null) => items?.filter(item => item?.id && item?.id !== newBuildingIdToSkipString) || [],
        [ newBuildingIdToSkipString ]
    );
    const [ items, setItems ] = useState<Items>(skipNewBuilding(newBuildings?.items ?? null));

    const totalItems = useMemo(() => (newBuildings?.paging?.total ?? 1) - 1, [ newBuildings ]);
    const totalSnippets = useMemo(() => Math.min(
        items.length % PAGE_SIZE !== 0 ?
            PAGE_SIZE * Math.ceil(items.length / PAGE_SIZE) :
            items.length + PAGE_SIZE,
        totalItems
    ), [ items, totalItems ]);
    const [ totalSnippetsShown, setTotalSnippetsShown ] = useState<number>(Math.min(PAGE_SIZE, totalItems));
    const handleIndexChange = useCallback((index: number) => setTotalSnippetsShown(
        Math.min(index + PAGE_SIZE, totalItems)
    ), [ totalItems ]);

    useEffect(() => {
        if (totalSnippetsShown > items.length) {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
    }, [ items, totalSnippetsShown ]);

    useEffect(() => {
        if (newBuildings?.paging?.pageNumber !== 1) {
            setItems(prevItems => [
                ...prevItems,
                ...skipNewBuilding(newBuildings?.items ?? null)
            ]);
        }
    }, [ newBuildings, skipNewBuilding ]);

    if (! items.length) {
        return null;
    }

    return (
        <div className={cn()}>
            {heading}
            <div className={cn('content')}>
                <Slider
                    totalItems={totalSnippets}
                    onIndexChange={handleIndexChange}
                    contentClassName={cn('sliderContent')}
                >
                    {[
                        ...items.map(item => (
                            <NewBuildingTinySnippetWithCatcher
                                key={item?.id}
                                // @ts-ignore
                                newBuilding={item}
                            />
                        )),
                        ...Array.from({ length: totalSnippets - items.length }, (e, i) => (
                            <NewBuildingTinySnippetStub key={`stub-${i}`} />
                        ))
                    ]}
                </Slider>
            </div>
        </div>
    );
};

export default NewBuildingCardOthersByDeveloper;
