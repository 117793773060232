/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';

import { Checkbox } from '@vtblife/uikit';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { PriceFilter, RegionFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import type { UpdateData, UpdateEvent } from '../types';
import { cnNewBuildingQuiz } from '../../index';

export const RegionView = (({
    regionFilter,
    updateData,
    updateEvent
}: {
    regionFilter: RegionFilter;
    updateData: UpdateData;
    updateEvent: UpdateEvent;
    isMobile: boolean;
}) => {
    const update = useCallback(
        (regionId: RegionIdEnum, label: string) => {
            updateData([
                new RegionFilter(regionId),
                new PriceFilter()
            ], {
                region: label,
                price: ''
            });
        },
        [ updateData ]
    );

    useEffect(() => updateEvent({
        event_category: 'region',
        event_action: regionFilter.region === RegionIdEnum.SPB_AND_LEN_OBL ? 'spb' : 'msk'
    }), [ regionFilter, updateEvent ]);

    return (
        <div className={cnNewBuildingQuiz('filter', { region: true })}>
            <Checkbox
                radio
                value={regionFilter.region === RegionIdEnum.MSK_AND_MSK_OBL}
                onChange={() => update(
                    RegionIdEnum.MSK_AND_MSK_OBL,
                    'Москва и область'
                )}
            >
                Москва и&nbsp;область
            </Checkbox>
            <Checkbox
                radio
                value={regionFilter.region === RegionIdEnum.SPB_AND_LEN_OBL}
                onChange={() => update(
                    RegionIdEnum.SPB_AND_LEN_OBL,
                    'Санкт-Петербург и область'
                )}
            >
                Санкт-Петербург и&nbsp;область
            </Checkbox>
        </div>
    );
}) as React.FC;
