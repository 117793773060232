import React from 'react';

import { Button, Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

import { useHover } from '../../../../../common/hooks/useHover';

import './styles.css';

const cn = classname.bind(null, 'QuickSelectionCardOfferSearchDesktopBanner');

const QuickSelectionCardOfferSearchDesktopBanner: React.FC<{
    className?: string;
    onClick?: () => void;
}> = ({
    className,
    onClick
}) => {
    const {
        isHovered,
        handleMouseEnter,
        handleMouseLeave
    } = useHover();

    return (
        <div
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn(null, undefined, className)}
        >
            <div className={cn('inner-wrapper')}>
                <div className={cn('image')} />
                <div className={cn('text-wrapper')}>
                    <Typography
                        variant='primary-alone'
                        bold
                    >
                        Не&nbsp;знаете, что&nbsp;выбрать? Доверьтесь экспертам.
                        Бесплатно&nbsp;найдём то,&nbsp;что&nbsp;нужно&nbsp;именно вам
                    </Typography>
                    <Typography
                        variant='secondary-alone'
                        color='secondary'
                        className={cn('text-secondary')}
                    >
                        Один звонок, без&nbsp;рекламы и&nbsp;спама.
                    </Typography>
                </div>
            </div>
            <div className={cn('button')}>
                <Button
                    size='s'
                    fullWidth
                    variant={isHovered ? 'promo1' : 'secondary'}
                >
                    Подобрать
                </Button>
            </div>
        </div>
    );
};

export default QuickSelectionCardOfferSearchDesktopBanner;
