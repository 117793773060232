/* eslint-disable complexity */
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import { InspectionStatusEnum } from '@search/graphql-gateway/typings';
import {
    NewBuildingFlat,
    NewBuildingSnippet as GQLNewBuildingSnippet,
    NewBuildingWhitelabelEnum
} from '@search/graphql-typings';
import { Collapse, Tooltip, Typography, useTooltip } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';
import classname from '@search/classname/src';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { DATA_GTM, NO_PHOTO_SRC, NO_PHOTO_SRC_SET } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';
import type { IMetroRouteProps } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import { SnippetContext } from '@search/vtbeco-frontend-core/domain/newbuilding/components/desktop/NewBuildingBaseSnippet/snippetContext';
import GallerySnippet from '@search/vtbeco-frontend-core/view/desktop/components/snippet/parts/GallerySnippet';
import { PreferentialMortgageBadge } from '@search/vtbeco-frontend-core/view/common/components/Badge/PreferentialMortgageBadge';
import { BadgeBox } from '@search/vtbeco-frontend-core/view/common/components/BadgeBox';
import { DefaultBadge } from '@search/vtbeco-frontend-core/view/common/components/Badge/DefaultBadge';
import { Badge } from '@search/vtbeco-frontend-core/view/common/components/Badge';
import { IUseToggleValueReturnType, useToggleValue } from '@search/hooks/src/useToggleValue';
import IconTransport, { IconTransportProps } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/IconTransport';
import { VTBCoreYMapDesktop } from '@search/vtbeco-frontend-core/view/common/components/YMap/Desktop';

import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';

import { Shared } from '../../../../types/shared';
import { useNewBuildingSnippet } from '../../../common/components/NewBuildingSnippet/useNewBuildingSnippet';
import {
    getFilterParams,
    useNewBuildingSnippetUrl,
    getUrlParameters
} from '../../../common/components/NewBuildingSnippet/useNewBuildingSnippetUrl';
import { GlobalContext } from '../../../common/components/GlobalContext';
import { COLORS, IconWithColorType, SvgSpriteIcon } from '../../../common/components/SvgSpriteIcon';
import { MetroRouteWithSearchLink } from '../../../common/components/MetroRouteWithSearchLink';
import FavoritesButton from '../../../common/components/FavoritesButton';
import { useFavoriteNewBuildingButton } from '../../../common/components/FavoritesButton/useFavoriteNewBuildingButton';

import NewBuildingFlats from '../NewBuildingFlats';
import RightBlock from './RightBlock';
import BottomBlock from './BottomBlock';

import './styles.css';

interface INewBuildingSnippet {
    newBuilding: GQLNewBuildingSnippet;
    pageParams?: Shared.ISearchParams;
    clearSerpCache?: (refresh?: boolean) => void;
    withLazyLoad?: boolean;
    itemWithOpenedMap?: string;
    setItemWithOpenedMap: (itemId?: string) => void;
}

const cn = classname.bind(null, 'NewBuildingSerpSnippet');

const renderMetroRoutes = (
    routes: GQLNewBuildingSnippet['routes'],
    phoneHoverToggle: IUseToggleValueReturnType,
    regionId?: Shared.ISearchParams['region']
) => {
    if (! (routes && routes?.length)) {
        return null;
    }

    const [ metro ] = routes;

    if (! metro) {
        return null;
    }

    return (
        <div
            key='metro'
            className={cn('metro')}
            onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
        >
            <MetroRouteWithSearchLink
                metro={metro as IMetroRouteProps['metro']}
                regionId={regionId}
                className={cn('metroRoute')}
                withoutTransport
            />
            <Typography
                tag='span'
                color='secondary'
            >
                <IconTransport
                    transportType={metro.transportType as IconTransportProps['transportType']}
                    color='#a4a1b5'
                />
                {`${metro.timeMinutes} мин.`}
            </Typography>
        </div>
    );
};

/* eslint-disable complexity */
const NewBuildingSerpSnippet: React.FunctionComponent<INewBuildingSnippet> = ({
    newBuilding,
    pageParams,
    clearSerpCache,
    withLazyLoad = false,
    itemWithOpenedMap,
    setItemWithOpenedMap
}) => {
    const {
        instanceConfig: {
            searchIndex,
            preferentialMortgageBadgeVariant,
            hasSpecialEvents,
            hasRegionMortgageButton,
            hasFavoritesButton
        }
    } = useContext(GlobalContext);

    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const snippetContextValue = useContext(SnippetContext);

    const favoritesButtonProps = useFavoriteNewBuildingButton({
        newBuildingId: newBuilding.id,
        isFavorite: newBuilding.isFavorite,
        onSuccess: clearSerpCache
    });

    useEffect(() => {
        if (snippetContextValue?.state.isOpenMap) {
            setItemWithOpenedMap(newBuilding.id);
        }
    }, [ snippetContextValue?.state, newBuilding.id, setItemWithOpenedMap ]);

    useEffect(() => {
        if (itemWithOpenedMap && newBuilding.id !== itemWithOpenedMap) {
            snippetContextValue?.closeMap();
            setItemWithOpenedMap(undefined);
        }
    }, [ itemWithOpenedMap, newBuilding.id, snippetContextValue, setItemWithOpenedMap ]);

    const {
        hero,
        routes,
        isFrozen,
        developer,
        pictures
    } = useNewBuildingSnippet(newBuilding);

    const url = useNewBuildingSnippetUrl(newBuilding.routeParams) + getUrlParameters(pageParams);

    const handleGalleryClick = useCallback(() => {
        window.open(url, '_blank');
    }, [ url ]);

    const isImgTransparent = isFrozen || newBuilding.inspectionStatus?.value === InspectionStatusEnum.Failed;

    const hoverToggle = useToggleValue(false);
    const phoneHoverToggle = useToggleValue(false);
    const mapHoverToggle = useToggleValue(false);

    const { ranking, info } = newBuilding;
    const handlePhoneEventPush = useCallback(() => {
        dataLayerPush({
            event: NewBuildingAnalytics.NewBuildingShowPhone,
            'nb-id': newBuilding.id,
            'nb-name': newBuilding.title.nominative,
            'developer-name': developer?.title ?? '',
            ranking: ranking ? upperFirst(ranking.split('_').join(' ').toLowerCase()) : ''
        });

        dataLayerPush({
            event: 'nb_call_like',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            nb_price: newBuilding.priceMin ?? 0,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            nb_class: ranking ?? ''
        });
    }, [ newBuilding, developer, ranking ]);

    const isPhoneRegion = newBuilding.isMskMO || newBuilding.isSPbLO || newBuilding.isSpecProjectRegion;
    const shouldShowRegionMortgageButtons = hasRegionMortgageButton && ! isPhoneRegion;

    const infoItems = useMemo(
        () => [
            info?.commissioningDate,
            info?.apartments,
            info?.buildingClass,
            info?.totalCount
        ]
            .filter(Boolean),
        [ info ]
    );

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: newBuilding.gallery?.baseUrl });

    let isVerified = newBuilding.inspectionStatus?.isVerified;
    let isVerifiedText = newBuilding.inspectionStatus?.statusText;

    if (typeof isVerified !== 'boolean' && newBuilding.buildingStatus?.some(item => item.isFrozen)) {
        isVerified = false;
        isVerifiedText = 'Строительство приостановлено';
    }

    const { to, registerControl, container } = useTooltip();

    const coords = newBuilding.coordinates;

    const refContentCenter = useRef(null);
    const refListDiv = useRef(null);
    const refFlats = useRef(null);

    const getMapHeight = () => {
        if (! refContentCenter.current || ! refListDiv.current || ! refFlats.current) {
            return 0;
        }

        const padding = 8;

        return (
            // @ts-ignore
            refContentCenter.current.clientHeight - refListDiv.current.clientHeight + refFlats.current.clientHeight -
            padding
        );
    };

    return (
        <div
            className={cn()}
            onMouseOver={() => {
                try {
                    const serpPageParamsStr = window.localStorage.getItem('NewBuildingSerpPageParams');
                    const serpPageParams = serpPageParamsStr ? JSON.parse(serpPageParamsStr) : {};
                    const newSerpPageParams = { ...serpPageParams, [newBuilding.id]: getFilterParams(pageParams) };

                    window.localStorage.setItem('NewBuildingSerpPageParams', JSON.stringify(newSerpPageParams));
                } catch (e) {}
            }}
        >
            <a
                href={url}
                target='_blank'
                className={cn('link')}
                onMouseOver={hoverToggle.handleSetTrueTogVal}
                onMouseLeave={hoverToggle.handleSetFalseTogVal}
            >
                {newBuilding.title.nominative}
            </a>
            <Grid cols={24}>
                <Grid.Cell m={11} l={8}>
                    {
                        hero?.src ? (
                            <div
                                className={cn('gallery')}
                                // @ts-ignore
                                ref={container}
                                onMouseOver={() => {
                                    phoneHoverToggle.handleSetTrueTogVal();
                                    hoverToggle.handleSetTrueTogVal();
                                }}
                                onMouseLeave={() => {
                                    phoneHoverToggle.handleSetFalseTogVal();
                                    hoverToggle.handleSetFalseTogVal();
                                }}
                            >
                                <ImageLazySrcSetProvide getUrl={getUrl}>
                                    <GallerySnippet
                                        pictures={pictures}
                                        isFrozen={isImgTransparent}
                                        onClick={handleGalleryClick}
                                        maxPicturesCount={8}
                                        previewSize='below-average'
                                        withLazyLoad={withLazyLoad}
                                    >
                                        <BadgeBox position='top-left'>
                                            {
                                                // eslint-disable-next-line no-nested-ternary
                                                newBuilding.isSoldOut ? (
                                                    <DefaultBadge variant='transparent'>
                                                        Всё продано
                                                    </DefaultBadge>
                                                // eslint-disable-next-line no-nested-ternary
                                                ) : newBuilding.isSoon ? (
                                                    <DefaultBadge variant='blue'>
                                                        Скоро в продаже
                                                    </DefaultBadge>
                                                // eslint-disable-next-line no-nested-ternary
                                                ) : hasSpecialEvents && newBuilding.specialEvents.length ? (
                                                    <Badge
                                                        size='xs'
                                                        backgroundColor={COLORS[upperFirst(camelCase(
                                                            newBuilding.specialEvents[0].type
                                                        )) as IconWithColorType]}
                                                    >
                                                        {newBuilding.specialEvents[0].titleRaw}
                                                    </Badge>
                                                ) : newBuilding.hasPreferentialMortgage ? (
                                                    <PreferentialMortgageBadge
                                                        size='xs'
                                                        variant={preferentialMortgageBadgeVariant}
                                                    />
                                                ) : null
                                            }
                                        </BadgeBox>
                                        <BadgeBox position='top-right-same'>
                                            {typeof isVerified === 'boolean' ? (
                                                <>
                                                    <div
                                                        // @ts-ignore
                                                        ref={registerControl}
                                                        className={cn('icon')}
                                                    >
                                                        <SvgSpriteIcon name={isVerified ? 'Verified' : 'NotVerified'} />
                                                    </div>
                                                    <Tooltip
                                                        direction='left'
                                                        placement='local'
                                                        to={to}
                                                        container={container}
                                                    >
                                                        <div className={cn('verified')}>
                                                            {isVerifiedText}
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            ) : null}
                                        </BadgeBox>
                                    </GallerySnippet>
                                </ImageLazySrcSetProvide>
                            </div>
                        ) : (
                            <picture itemType='http://schema.org/ImageObject' className={cn('galleryCap')}>
                                <source
                                    srcSet={NO_PHOTO_SRC_SET}
                                />
                                <img
                                    className={cn('galleryCap')}
                                    crossOrigin='anonymous'
                                    src={NO_PHOTO_SRC}
                                    loading='lazy'
                                />
                            </picture>
                        )
                    }
                </Grid.Cell>
                <Grid.Cell m={13} l={isVtb ? 10 : 9}>
                    <div
                        className={cn('content', { vtb: isVtb })}
                        ref={refContentCenter}
                    >
                        <div ref={refListDiv}>
                            <List
                                className={cn('list')}
                                layout='vertical'>
                                {[
                                    <div className={cn('titleAndFavorites')} key='nb-title'>
                                        <Typography
                                            variant='h3'
                                            tag='span'
                                            className={cn('title', { active: hoverToggle.isToggleValue })}
                                        >
                                            {newBuilding.title.nominative}
                                        </Typography>
                                        {
                                            hasFavoritesButton ? (
                                                <FavoritesButton
                                                    {...favoritesButtonProps}
                                                    className={cn('favorites-btn')}
                                                />
                                            ) : null
                                        }
                                    </div>,
                                    renderMetroRoutes(routes, phoneHoverToggle, pageParams?.region),
                                    <div key='address' className={cn('address')}>
                                        <Typography
                                            tag='span'
                                            className={cn('addressForUser')}
                                            data-gtm={snippetContextValue!.state.isOpenMap ?
                                                null : DATA_GTM.SNIPPET_MAP
                                            }
                                            variant='primary-alone'
                                        >
                                            {newBuilding.address}
                                        </Typography>
                                        <div
                                            onMouseOver={() => {
                                                phoneHoverToggle.handleSetTrueTogVal();
                                                mapHoverToggle.handleSetTrueTogVal();
                                            }}
                                            onMouseLeave={() => {
                                                phoneHoverToggle.handleSetFalseTogVal();
                                                mapHoverToggle.handleSetFalseTogVal();
                                            }}
                                            // eslint-disable-next-line react/jsx-handler-names
                                            onClick={snippetContextValue!.toggleMap}
                                            className={cn('mapTrigger')}
                                        >
                                            <Typography className={cn('mapTriggerText', {
                                                active: snippetContextValue?.state.isOpenMap,
                                                hover: mapHoverToggle.isToggleValue
                                            })}>
                                                На карте
                                            </Typography>
                                        </div>
                                        {snippetContextValue?.state.isOpenMap && refContentCenter.current && (
                                            <div
                                                className={cn('map')}
                                                style={{
                                                    height: `${getMapHeight()}px`,
                                                    // @ts-ignore
                                                    width: `${refContentCenter.current.clientWidth + 38}px`
                                                }}
                                            >
                                                <VTBCoreYMapDesktop
                                                    // eslint-disable-next-line react/jsx-handler-names
                                                    onClose={snippetContextValue!.toggleMap}
                                                    center={[ coords[0], coords[1] ]}
                                                    small
                                                />
                                            </div>
                                        )}
                                    </div>
                                ].filter(Boolean).map((item, idx) =>
                                    <ListItem className={cn('list-item')} key={idx}>{item}</ListItem>
                                )}
                            </List>
                            {newBuilding.flats && (
                                <div ref={refFlats}>
                                    <NewBuildingFlats
                                        flats={newBuilding.flats as NewBuildingFlat[]}
                                        newBuildingId={newBuilding.id}
                                        url={`${url}#flats`}
                                        hasOffers={! newBuilding.noOffers}
                                        phoneHoverToggle={phoneHoverToggle}
                                        hoverToggle={hoverToggle}
                                    />
                                </div>
                            )}
                            {isVtb && newBuilding.buildingsAccreditedByVtb && (
                                <div
                                    className={cn('accreditedSmallScreen')}
                                    onMouseOver={() => {
                                        phoneHoverToggle.handleSetTrueTogVal();
                                        hoverToggle.handleSetTrueTogVal();
                                    }}
                                    onMouseLeave={() => {
                                        phoneHoverToggle.handleSetFalseTogVal();
                                        hoverToggle.handleSetFalseTogVal();
                                    }}
                                >
                                    <Collapse variant='line' size='s'>
                                        <Collapse.Item title='Аккредитованные корпуса'>
                                            <Typography variant='secondary-alone'>
                                                {newBuilding.buildingsAccreditedByVtb.join(', ')}
                                            </Typography>
                                        </Collapse.Item>
                                    </Collapse>
                                </div>
                            )}
                        </div>
                        <div className={cn('info')}>
                            {infoItems.map((item, idx) => (
                                <div
                                    className={cn('infoItem', { extra: idx > 2 })}
                                    key={idx}
                                >
                                    <Typography
                                        className={cn('infoItemLabel')}
                                        variant='secondary-alone'
                                        color='secondary'
                                    >
                                        <div dangerouslySetInnerHTML={{
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            __html: item!.snippetLabel ?? item!.label
                                        }} />
                                    </Typography>
                                    <Typography
                                        className={cn('infoItemValue')}
                                        variant='primary-alone'
                                    >
                                        <div dangerouslySetInnerHTML={{
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            __html: item!.snippetValue ?? item!.value
                                        }} />
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <BottomBlock
                            showRegionMortgageButtons={shouldShowRegionMortgageButtons}
                            newBuilding={newBuilding}
                            url={url}
                            developer={developer}
                            handlePhoneEventPush={handlePhoneEventPush}
                            phoneHoverToggle={phoneHoverToggle}
                            hoverToggle={hoverToggle}
                        />
                    </div>
                </Grid.Cell>
                {! isVtb && (
                    <Grid.Cell m={0} l={1}>
                        <div className={cn('borderWrapper')}>
                            <div className={cn('border')} />
                        </div>
                    </Grid.Cell>
                )}
                <Grid.Cell m={0} l={6}>
                    <RightBlock
                        showRegionMortgageButtons={shouldShowRegionMortgageButtons}
                        newBuilding={newBuilding}
                        url={url}
                        developer={developer}
                        handlePhoneEventPush={handlePhoneEventPush}
                        phoneHoverToggle={phoneHoverToggle}
                        hoverToggle={hoverToggle}
                        clearSerpCache={clearSerpCache}
                    />
                </Grid.Cell>
            </Grid>
        </div>
    );
};

export default NewBuildingSerpSnippet;
