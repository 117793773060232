import * as React from 'react';

import classname from '@search/classname/src';

import { SvgSpriteIcon, SocialMediaEnum } from '../SvgSpriteIcon';

import './styles.css';

const LINKS = {
    [SocialMediaEnum.Twitter]: 'https://twitter.com/VTB',
    [SocialMediaEnum.Vk]: 'https://vk.com/vtb',
    [SocialMediaEnum.Youtube]: 'https://www.youtube.com/user/vtbgroup',
    [SocialMediaEnum.Ok]: 'https://ok.ru/vtb'
};

interface ISocialMediaLinkProps extends React.HTMLAttributes<HTMLLinkElement> {
    type: SocialMediaEnum;
}

const SocialMediaLink: React.FunctionComponent<ISocialMediaLinkProps> = ({
    className: classNameProp,
    type
}) => {
    const className = React.useMemo(
        () => classname('SocialMediaLink', null, undefined, classNameProp),
        [ classNameProp ]
    );

    return (
        <a
            href={LINKS[type]}
            target='_blank'
            className={className}
        >
            <SvgSpriteIcon
                name={type}
                width={18}
                height={18}
            />
        </a>
    );
};

export default SocialMediaLink;
