import React from 'react';

import { YMapVector } from '../Vector';

export interface YMapDrawAreaProps {
    selectedArea?: readonly YMapVector[];
    handleDrawEnd: (coordinates: YMapVector[]) => void;
}

export function useYMapDrawArea({
    handleDrawEnd,
    selectedArea
}: YMapDrawAreaProps) {
    const [
        isDrawAreaVisible,
        setIsDrawAreaVisible
    ] = React.useState(selectedArea ? selectedArea.length !== 0 : false);

    const closeDrawArea = React.useCallback(() => {
        setIsDrawAreaVisible(false);
    }, [ setIsDrawAreaVisible ]);

    const toggleDrawArea = React.useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const next = ! isDrawAreaVisible;

        setIsDrawAreaVisible(next);

        if (! next) {
            handleDrawEnd([]);
        }
    }, [ setIsDrawAreaVisible, handleDrawEnd, isDrawAreaVisible ]);

    return {
        toggleDrawArea,
        closeDrawArea,
        isDrawAreaVisible
    };
}
