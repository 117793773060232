import * as React from 'react';
import { useContext, useMemo } from 'react';

import type { NewBuildingsInput } from '@search/graphql-typings';

import { ROSSIYA } from '@search/filter-enums/enums/Region';
import { useGql, graphql } from '@search/gql-client/src/useGql';
import { convertParamsToGQLFilters } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';

import { Shared } from '../../../../types/shared';

import { DEVELOPER_ID } from '../../constants/developers';

import { GlobalContext, IGlobalContext, OnChangeGlobalContext } from '../GlobalContext';
import { Meta } from '../Meta';
import { useNewBuildingsSummary } from '../NewBuildingSearchFAQ/useNewBuildingsSummary';
import { useDeveloperInfo } from '../DeveloperInfo';
import useBreadcrumbs from '../DeveloperInfo/useBreadcrumbs';

import type { DeveloperPageSeoQuery$variables as DeveloperPageSeoQueryVariables, DeveloperPageSeoQuery$data as DeveloperPageSeoQueryResponse } from './__generated__/DeveloperPageSeoQuery.graphql';

export const query = graphql`
    query DeveloperPageSeoQuery(
        $developerId: Int!
        $regionId: Int
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        newBuildingSeo(whiteLabel: $whiteLabel) {
           developerPage(regionId: $regionId developerId: $developerId) {
               title
               metaDescription
               breadcrumb
           }
        }
        developer(developerId: $developerId) {
            developer {
                title
                image {
                    large
                }
            }
        }
    }
`;

const DeveloperPageSeo = ({
    params,
    route
}: Shared.DeveloperPageProps) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { searchIndex, hasBreadcrumbs } = globalContext.instanceConfig;
    const { data } = useGql<DeveloperPageSeoQueryVariables, DeveloperPageSeoQueryResponse>(
        query, {
            developerId: params.id,
            regionId: params.region,
            whiteLabel: searchIndex
        }
    );
    const filters = useMemo(() => ({
        region: params.region ?? ROSSIYA,
        developerId: params.id,
        closedSales: true
    }), [ params.id, params.region ]);
    const newBuildingsSummary = useNewBuildingsSummary({
        pageParams: filters,
        whiteLabel: searchIndex,
        tracingTag: 'DeveloperPageSeo'
    });
    const {
        data: developerInfo
    } = useDeveloperInfo({
        params,
        filters: convertParamsToGQLFilters(filters) as NewBuildingsInput
    });

    const { rating, reviewsCount } = developerInfo?.searchNewBuildings ?? {};

    const onChangeGlobalContext = useContext(OnChangeGlobalContext);

    let canonicalUrl = '';

    if (onChangeGlobalContext.linkBuilder.developer) {
        canonicalUrl = onChangeGlobalContext?.linkBuilder?.developer(params).split('?')[0];
    }

    let image: string | undefined;

    // Почти у всех застрощийков нет логотипа в хорошем качестве, поэтому показываем дефолтную картинку
    if (params.id === DEVELOPER_ID.SAMOLET) {
        image = data?.developer.developer?.image?.large;

        if (image && image.startsWith('//')) {
            image = `https:${image}`;
        }
    }

    const title = data?.newBuildingSeo.developerPage?.title ?? '';
    const description = data?.newBuildingSeo.developerPage?.metaDescription;

    const breadcrumbs = useBreadcrumbs({
        hasBreadcrumbs,
        activeText: data?.newBuildingSeo?.developerPage?.breadcrumb,
        route,
        pageParams: params
    });

    const breadcrumbsMeta = breadcrumbs?.rdfMeta;

    return (
        <>
            <Meta
                title={title}
                description={description}
                canonical={canonicalUrl}
                image={image}
            />
            {
                breadcrumbsMeta ? (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{ __html: breadcrumbsMeta }}
                    />
                ) : null
            }
            {newBuildingsSummary ? (
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Product',
                            brand: data?.developer.developer?.title,
                            name: title,
                            description,
                            offers: {
                                '@type': 'AggregateOffer',
                                lowPrice: newBuildingsSummary.totalPriceMin,
                                highPrice: newBuildingsSummary.totalPriceMax,
                                priceCurrency: 'RUB',
                                offerCount: newBuildingsSummary.count
                            },
                            ...(rating && reviewsCount ? {
                                aggregateRating: {
                                    '@type': 'AggregateRating',
                                    ratingValue: `${
                                        formatLocaleNumber(rating)
                                            .replace(',', '.')
                                    }`,
                                    reviewCount: `${reviewsCount}`,
                                    bestRating: '5'
                                }
                            } : {})
                        })
                    }}
                />
            ) : null}
        </>
    );
};

DeveloperPageSeo.displayName = 'DeveloperPageSeo';

export default DeveloperPageSeo;
