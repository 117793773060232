import { useCallback, useState } from 'react';

export const useHover = (isMobile?: boolean) => {
    const [ isHovered, setIsHovered ] = useState<boolean>(false);

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);

    return {
        isHovered,
        handleMouseEnter: isMobile ? undefined : handleMouseEnter,
        handleMouseLeave: isMobile ? undefined : handleMouseLeave
    };
};
