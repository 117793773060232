import React from 'react';

import { Typography } from '@vtblife/uikit';

import {
    Filters
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';

import { cnNewBuildingQuiz } from '../index';

import type { TextKey } from './types';

import { RegionView } from './view/region';
import { RoomsView } from './view/rooms';
import { PriceView } from './view/price';
import { PaymentMethodView } from './view/paymentMethod';
import { PhoneView } from './view/phone';
import { MiniSerpView } from './view/miniSerp';

import './styles.css';

export const STAGES = [
    {
        makeTitle: (isMobile?: boolean) => (
            <>
                Пройдите короткий тест, и&nbsp;мы&nbsp;подберём <Typography
                    className={cnNewBuildingQuiz('boldish')}
                    variant={isMobile ? 'promo-primary-alone' : 'h2'}
                    tag='span'
                    color='secondary'
                >
                    подходящую под ваши условия новостройку
                </Typography>
            </>
        ),
        makeSubtitle: () => 'Где будем искать квартиру?',
        filterProps: [ 'region' ],
        View: RegionView
    },
    {
        makeTitle: () => 'Какую квартиру вы\u00A0ищете?',
        makeSubtitle: () => 'Можно выбрать несколько вариантов:',
        filterProps: [ 'rooms', 'apartments' ],
        View: RoomsView
    },
    {
        makeTitle: () => 'Бюджет. На\u00A0какую цену ориентируетесь?',
        makeSubtitle: () => 'Выберите один вариант:',
        filterProps: [ 'price', 'region', 'rooms' ],
        View: PriceView
    },
    {
        makeTitle: () => 'Способ покупки жилья',
        makeSubtitle: () => 'Выберите один вариант:',
        textProps: [ 'paymentMethod' ],
        View: PaymentMethodView
    },
    {
        makeTitle: (isMobile?: boolean) => (
            <>
                Оставьте свой номер телефона, и&nbsp;мы&nbsp;расскажем об&nbsp;отличных вариантах. <Typography
                    className={cnNewBuildingQuiz('boldish')}
                    variant={isMobile ? 'promo-primary-alone' : 'h2'}
                    tag='span'
                    color='secondary'
                >
                    Бесплатно и&nbsp;без спама
                </Typography>
            </>
        ),
        textProps: [ 'phone', 'hasNotAgreed' ],
        View: PhoneView
    },
    {
        makeTitle: () => 'Заявка на\u00A0консультацию отправлена',
        makeSubtitle: (isVtb?: boolean) => `Скоро мы свяжемся с\u00A0вами. А\u00A0пока ожидаете, ознакомьтесь с\u00A0${
            isVtb ? 'нашими ' : ''}рекомендациями${
            isVtb ? '' : ' от\u00A0m2.ru:'
        }`,
        filterProps: [ 'rooms' ],
        View: MiniSerpView
    }
] as {
    makeTitle: (isMobile?: boolean) => React.ReactNode;
    makeSubtitle?: (isVtb?: boolean) => string;
    filterProps?: Filters[];
    textProps?: TextKey[];
    View: React.FC;
}[];
