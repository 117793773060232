import { useCallback, useMemo } from 'react';

import { SuggestItemDeveloper, TipTypeEnum } from '@search/graphql-typings';
import { DeveloperFilter, Filters } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import {
    AddQueryDisplayValue,
    QueryDisplayValues
} from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';

import { CustomFilterBehavior } from '../../desktop/components/NewNewBuildingSearchFilters';

export const useDeveloperFilterChange = ({
    customFilterBehavior,
    addQueryDisplayValue
}:{
    customFilterBehavior: CustomFilterBehavior;
    addQueryDisplayValue?: AddQueryDisplayValue;
}): (value?: SuggestItemDeveloper) => void => {
    return useCallback((value?: SuggestItemDeveloper) => {
        const { developer } = value ?? {};

        if (developer) {
            const { id, title } = developer;

            addQueryDisplayValue && addQueryDisplayValue('developers', {
                id,
                type: TipTypeEnum.Developer,
                title
            });
        }

        customFilterBehavior.developer.update(value);
    }, [
        addQueryDisplayValue,
        customFilterBehavior
    ]);
};

export const useDeveloperFilterValue = ({
    filters,
    queryDisplayValues
}:{
    filters: NewbuildingFilterCollection;
    queryDisplayValues?: QueryDisplayValues;
}): SuggestItemDeveloper | undefined => {
    const developerFilterId = filters.get<DeveloperFilter>(Filters.developer).developerId;

    return useMemo(() => {
        if (
            developerFilterId &&
            queryDisplayValues?.developers &&
            queryDisplayValues.developers[developerFilterId]
        ) {
            const suggestVal = queryDisplayValues.developers[developerFilterId];

            return {
                label: suggestVal.title,
                data: {
                    id: suggestVal.id
                }
            } as SuggestItemDeveloper;
        }

        return undefined;
    }, [ developerFilterId, queryDisplayValues?.developers ]);
};
