// @ts-ignore
import { ymaps } from '../../ApiProvider';
import { Traffic } from '../../Icon';
import { YMapControlButtonTemplate } from '../Button';
import { YMapControlCheckY, YMapControlCheckProps } from '../Check';

const baseClass = 'YMapControlTraffic';

type YMapControlTrafficProps = YMapControlCheckProps;

export function YMapControlTraffic({
    className,
    index,
    dataTest = 'ymap-traffic',
    title
}: YMapControlTrafficProps) {
    YMapControlTrafficY.use({
        index,
        dataTest,
        className,
        title
    } as YMapControlTrafficProps);

    return null;
}

class YMapControlTrafficY extends YMapControlCheckY<
    YMapControlTrafficProps,
    ymaps.control.ITrafficControl
> {
    protected create() {
        return new this.ymaps.control.TrafficControl({
            options: {
                layout: this.layoutClass()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
        });
    }

    protected template() {
        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            dataTest: this.props.dataTest,
            className: `${baseClass} ${this.checkClass}`,
            children: `${Traffic.str()}`,
            title: this.props.title
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        return class YMapControlTrafficLayout extends Layout {
            protected toggle(next: boolean) {
                if (next) this.control.showTraffic();
                else this.control.hideTraffic();
            }
        };
    }
}
