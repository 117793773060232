import React, { useCallback } from 'react';

import { CallbackDataType, CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';

import QuickSelectionCardOfferSearchDesktopBanner from './QuickSelectionCardOfferSearchDesktopBanner';
import QuickSelectionCardOfferSearchMobileBanner from './QuickSelectionCardOfferSearchMobileBanner';

const QuickSelectionCardOfferSearchBanner: React.FC<{
    callbackData: CallbackDataType;
    isMobile?: boolean;
    className?: string;
}> = ({
    callbackData,
    isMobile,
    className
}) => {
    const renderBanner = useCallback((openModalCallback: () => void) => isMobile ? (
        <QuickSelectionCardOfferSearchMobileBanner
            className={className}
            onClick={openModalCallback}
        />
    ) : (
        <QuickSelectionCardOfferSearchDesktopBanner
            className={className}
            onClick={openModalCallback}
        />
    ), [ className, isMobile ]);

    return (
        <CallbackModal
            mobile={isMobile}
            gtmShowModal={'nb_banner_click_selection'}
            renderTrigger={renderBanner}
            {...callbackData}
        />
    );
};

export default QuickSelectionCardOfferSearchBanner;
