import { useCallback, useContext, useMemo, useState } from 'react';

import { formatPhone } from '@search/helpers/src/formatPhone';
import { useManualGql, graphql } from '@search/gql-client/src/useGql';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import {
    CalltouchError,
    calltouchReplacementFetch,
    ReplaceError,
} from '@search/vtbeco-frontend-core/domain/newbuilding/hooks/useCalltouchReplacement';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { AddScriptError } from '@search/classified-common/src/addScript';

import { GlobalContext } from '../components/GlobalContext';
import type { IButtonDataType, IPhoneButtonStateContext } from '../components/NewBuildingCard/PhoneButtonStateProvider';
// @ts-ignore
import type { NewBuildingCardQuery$data as NewBuildingCardQueryResponse } from '../components/NewBuildingCard/__generated__/NewBuildingCardQuery.graphql';

import type {
    usePhoneButtonQuery$data as usePhoneButtonQueryResponse,
    usePhoneButtonQuery$variables as usePhoneButtonQueryVariables
// @ts-ignore
} from './__generated__/usePhoneButtonQuery.graphql';

declare const window: Window;

interface ICard {
    developer: {
        id?: number;
    };
}

const DEFAULT_BUTTON_TEXT = 'Показать телефон';

const query = graphql`
    query usePhoneButtonQuery(
        $developerId: Int!,
        $newBuildingId: Int!,
        $phoneNum: Int!,
        $whitelabel: NewBuildingWhitelabelEnum
    ) {
        newBuildingPhone(
            developerId: $developerId
            newBuildingId: $newBuildingId
            phoneNum: $phoneNum
            whiteLabel: $whitelabel
        ) {
            formattedNumber
            isReplaceable
        }
    }
`;

interface IUsePhoneButtonProps {
    newBuildingId: number;
    developerCards: NewBuildingCardQueryResponse['newBuilding']['developerCards'];
    phoneNum: number;
    onPhoneEventPush?: () => void;
    onStatEventPush?: () => void;
}

type ButtonsDataStateType = IPhoneButtonStateContext['buttonsData'];

const initialButtonData: IButtonDataType = {
    href: undefined,
    qrCodeValue: undefined,
    text: DEFAULT_BUTTON_TEXT,
    isLoading: false,
};

const formatPhoneNumber = (phone: string) => phone.replace('+7', '').replace(/ /g, '');

const handleReplacePhoneEventPush = ({
    originalPhoneData,
    calltouchPhone,
    replaceError,
}: {
    originalPhoneData: NonNullable<usePhoneButtonQueryResponse['newBuildingPhone']>;
    replaceError: ReplaceError;
    calltouchPhone?: string;
}) => {
    if (calltouchPhone) {
        dataLayerPush({
            event: NewBuildingAnalytics.NewBuildingReplacePhone,
            'phone-origin': originalPhoneData.formattedNumber,
            'phone-replace': calltouchPhone,
            'phone-replace-qr': `${calltouchPhone},0`,
        });
    } else {
        dataLayerPush({
            event: NewBuildingAnalytics.NewBuildingReplacePhoneFail,
            'phone-origin': originalPhoneData.formattedNumber
        });
    }

    dataLayerPush({
        event: NewBuildingAnalytics.NewBuildingReplacePhoneTry,
        'phone-origin': originalPhoneData.formattedNumber,
        replaceable: originalPhoneData.isReplaceable,
        replaced: Boolean(calltouchPhone),
        'replace-error': replaceError,
        ...(calltouchPhone ? {
            'phone-replace': calltouchPhone
        } : {})
    });
};

export function usePhoneButton({
    newBuildingId,
    developerCards,
    phoneNum,
    onPhoneEventPush,
    onStatEventPush
}: IUsePhoneButtonProps, isMobile?: boolean): IPhoneButtonStateContext {
    const { instanceConfig: { searchIndex }, calltouchModId } = useContext(GlobalContext);

    const developerIds = useMemo(
        () => developerCards
            .map((card: ICard) => card.developer?.id)
            .filter(Boolean) as Array<number>,
        [ developerCards ]
    );

    const [ buttonsData, setButtonsData ] = useState<ButtonsDataStateType>(developerIds.reduce<ButtonsDataStateType>((res, id) => ({
        ...res,
        [id]: initialButtonData
    }), {}));

    const makeCall = useCallback((href: string) => {
        window.open(href, '_self');
    }, []);

    const { load } = useManualGql<usePhoneButtonQueryVariables, usePhoneButtonQueryResponse>(query);

    const handleLoadOriginalPhone = useCallback((developerId: number) => load({
        developerId,
        newBuildingId,
        phoneNum,
        whitelabel: searchIndex
    }), [
        newBuildingId,
        phoneNum,
        load,
        searchIndex
    ]);

    const handleClick = useCallback(async (developerId: number) => {
        const tel = buttonsData[developerId].href;

        if (tel) {
            isMobile && makeCall(tel);

            return;
        }

        setButtonsData(prev => ({
            ...prev,
            [developerId]: {
                ...prev[developerId],
                isLoading: true
            }
        }));

        const { newBuildingPhone: originalPhoneData } = await handleLoadOriginalPhone(developerId);

        if (! originalPhoneData) {
            setButtonsData(prev => ({
                ...prev,
                [developerId]: {
                    ...prev[developerId],
                    isLoading: false
                }
            }));

            return;
        }

        if (! calltouchModId) {
            const phone = formatPhoneNumber(originalPhoneData.formattedNumber);
            const href = `tel:+7${phone}`;

            setButtonsData(prev => ({
                ...prev,
                [developerId]: {
                    ...prev[developerId],
                    href,
                    qrCodeValue: href,
                    text: formatPhone(`8${phone}`, { skipSymbols: true, separateLast: true }),
                    isLoading: false
                }
            }));

            isMobile && makeCall(href);

            return;
        }

        let calltouchPhone: string | undefined;
        let replaceError: ReplaceError = 'NONE';

        try {
            calltouchPhone = await calltouchReplacementFetch({
                callTouchId: calltouchModId,
                originalPhone: originalPhoneData.formattedNumber
            });

            if (! calltouchPhone) {
                replaceError = 'UNKNOWN';
            }
        } catch (error) {
            if (error instanceof AddScriptError) {
                replaceError = 'SCRIPT';
            } else if (error instanceof CalltouchError) {
                replaceError = error.code;
            } else {
                replaceError = 'UNKNOWN';
            }
        }

        handleReplacePhoneEventPush({ originalPhoneData, calltouchPhone, replaceError });

        const phone = formatPhoneNumber(calltouchPhone || originalPhoneData.formattedNumber);
        const href = `tel:+7${phone}`;

        setButtonsData(prev => ({
            ...prev,
            [developerId]: {
                ...prev[developerId],
                href,
                qrCodeValue: calltouchPhone ? `${href},0` : href,
                text: formatPhone(`8${phone}`, { skipSymbols: true, separateLast: true }),
                isLoading: false
            }
        }));

        isMobile && makeCall(href);

        onPhoneEventPush && onPhoneEventPush();
        onStatEventPush && onStatEventPush();
    }, [
        buttonsData,
        handleLoadOriginalPhone,
        onPhoneEventPush,
        onStatEventPush,
        isMobile,
        makeCall,
        calltouchModId
    ]);

    return {
        buttonsData,
        handleClick,
        firstDeveloperId: developerIds[0],
        handlePhoneEventPush: onPhoneEventPush,
        handleStatEventPush: onStatEventPush
    };
}
