/* global document, ymaps */
import React from 'react';
import { useYMapPane } from '../Pane';
import { YMapVector, yMapVectorToMapCoordinate } from '../Vector';
import { YMapDrawAreaManager, YMapDrawAreaManagerParams, defaultUnwantedBehavours } from './AreaManager';

export type YMapDrawAreaPaneProps = Omit<YMapDrawAreaManagerParams, 'api' | 'map' | 'document'> & {
    selectedArea?: readonly YMapVector[];
    handleDrawEnd: (coordinates: YMapVector[]) => void;
}

export function YMapDrawAreaPane({
    handleDrawEnd,
    selectedArea,
    ...options
}: YMapDrawAreaPaneProps) {
    const {
        api,
        map
    } = useYMapPane();

    const handleDrawEndRef = React.useRef(handleDrawEnd);

    handleDrawEndRef.current = handleDrawEnd;

    React.useEffect(() => {
        const geometry = selectedArea?.map(yMapVectorToMapCoordinate);

        let polygon: ymaps.Polygon | undefined = geometry && geometry.length > 0 ?
            new api.Polygon([ geometry ], {}, options) :
            undefined;
        // eslint-disable-next-line max-len
        let areaManager: YMapDrawAreaManager | undefined = polygon ? undefined : new YMapDrawAreaManager({ api, map, document, ...options });

        const onMouseDown = () => {
            if (! areaManager) return;
            if (polygon) return;

            map.behaviors.disable(defaultUnwantedBehavours);
            areaManager.start();
        };

        const onMouseUp = () => {
            if (! areaManager) return;
            if (polygon) return;

            // areaManager.addPosition(yMapEventToPosition(e));

            const coordinates = areaManager.geoCoordinates;

            polygon = new api.Polygon([ coordinates.map(yMapVectorToMapCoordinate) ], {}, options);

            map.geoObjects.add(polygon);
            handleDrawEndRef.current(coordinates);

            areaManager?.destructor();
            areaManager = undefined;
        };

        if (polygon) {
            map.geoObjects.add(polygon);
        }

        map.events.add([ 'mousedown', 'touchstart' ], onMouseDown);
        map.events.add([ 'mouseup', 'touchend' ], onMouseUp);

        return () => {
            areaManager?.destructor();
            areaManager = undefined;

            map.behaviors.enable(defaultUnwantedBehavours);
            map.events.remove([ 'mousedown', 'touchstart' ], onMouseDown);
            map.events.remove([ 'mouseup', 'touchend' ], onMouseUp);

            // @ts-expect-error
            if (polygon) map.geoObjects.remove(polygon);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api, map, JSON.stringify(selectedArea) ]);

    return null;
}
