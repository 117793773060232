import React, { Fragment } from 'react';

import Sticky from '../../../../../../client/common/components/Sticky';

import NewBuildingCardPrice, { INewBuildingCardPrice } from '../NewBuildingCardPrice';
import NewBuildingCardDeveloper, { NewBuildingCardDeveloperProps } from '../NewBuildingCardDeveloper';

export interface INewBuildingCardTopStickyProps extends Omit<INewBuildingCardPrice, 'from'> {
    hasApartments: boolean;
    developer?: NewBuildingCardDeveloperProps;
    from?: string;
}

const NewBuildingCardTopSticky: React.FunctionComponent<INewBuildingCardTopStickyProps> = ({
    priceMin,
    pricePerM2Min,
    hasApartments,
    developer,
    from = 'card1'
}) => {
    return (
        <Sticky top={20}>
            <NewBuildingCardPrice
                priceMin={priceMin}
                pricePerM2Min={pricePerM2Min}
                hasApartments={hasApartments}
                from={from}
            />
            {
                developer ? (
                    <Fragment>
                        <br />
                        <NewBuildingCardDeveloper
                            imageUrl={developer.imageUrl}
                            title={developer.title}
                        />
                    </Fragment>
                ) : null
            }
        </Sticky>
    );
};

export default NewBuildingCardTopSticky;
