/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

class PromiseWaiter<Data> {
    promise: Promise<Data> | undefined = undefined;
    resolve = (d: Data) => {};
    reject = (e?: Error) => {};

    create() {
        this.promise = new Promise<Data>((resolve, reject) => {
            this.resolve = d => {
                resolve(d);
                this.promise = undefined;
                this.resolve = () => {};
            };
            this.reject = e => {
                e && reject(e);
                this.promise = undefined;
                this.reject = () => {};
            };
        });

        return this.promise;
    }
}

export function usePromiseWaiter<Data>() {
    return React.useRef(new PromiseWaiter<Data>()).current;
}
