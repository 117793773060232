/* global document window */
import type YMaps from '@search/ymap/src/ApiProvider/yandex-maps';
export type YMapsApi = typeof YMaps;

const ymapsApiInstances = new Map<string, Promise<YMapsApi>>();

export function yMapLoader(
    src = '//api-maps.yandex.ru/2.1/?lang=en_RU'
): Promise<YMapsApi> | undefined {
    if (typeof document === 'undefined') return;
    let ymapsApi = ymapsApiInstances.get(src);

    if (ymapsApi) return ymapsApi;

    ymapsApi = addScript(src).then(() => {
        const ymaps = window.ymaps;

        return new Promise<YMapsApi>((resolve, reject) =>
            ymaps.ready(resolve, reject)
        );
    });

    ymapsApiInstances.set(src, ymapsApi);

    return ymapsApi;
}

function addScript(src: string) {
    return new Promise<HTMLScriptElement>((resolve, reject) => {
        const scriptElement = document.createElement('script');

        scriptElement.onload = () => resolve(scriptElement);
        scriptElement.onerror = (
            // @ts-ignore
            event: Event | string,
            source?: string,
            lineno?: number,
            colno?: number,
            error?: Error
        ) => {
            const message = `Can't insert script into body:${
                source ? `${source}` : ''
            }${lineno ? ` ${lineno}: ${colno ?? 0}` : ''}`;

            if (! error) error = new Error(message);

            else error.message += '\n' + message;

            reject(error);
        };
        scriptElement.type = 'text/javascript';
        scriptElement.src = src;
        document.body.appendChild(scriptElement);
    });
}
