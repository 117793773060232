import { ExecutionResult } from '@search/gql-client/src';
import { legacySlugToNew } from '@search/router/src/legacySlug';
import { SlugMapper } from './SlugMapper';
import { IRegion, GeoRootResponse, geoRootRegionFallback, GroupRegionFilter, OptionTypeRegionFilter } from './geoRootRegionFallback';

export class GeoRootRegionRepository {
    constructor(protected resp: ExecutionResult<GeoRootResponse> = {}) {
        this.resp = resp;
    }

    readonly slugs = new SlugMapper();

    static instance = new GeoRootRegionRepository();

    get loading() {
        return this.resp.loading;
    }

    get error() {
        return this.resp.errors?.[0];
    }

    protected idMap = new Map<number, IRegion>();
    protected slugMap = new Map<string, IRegion>();

    protected rootRegions() {
        return this.resp.data?.geoRootRegions ?? [ geoRootRegionFallback ];
    }

    get isLoaded() {
        return this.resp.data?.geoRootRegions !== undefined;
    }

    get defaultRegionId() {
        return this.resp.data?.geoRootRegionDefault?.id ?? 3;
    }

    add(regionRaw: IRegion, parentId?: number) {
        const region = { ...regionRaw, parentId };

        this.idMap.set(region.id, region);

        if (region.translit) {
            this.slugMap.set(region.translit, region);
        }
    }

    protected get maps() {
        if (this.idMap.size === 0) {
            const rootRegionsResp = this.rootRegions();

            for (const rootRegions of rootRegionsResp) {
                const rootRegion = rootRegions.rootRegion;

                if (rootRegion) this.add(rootRegion);

                for (const regionRaw of rootRegions.regions ?? []) {
                    if (regionRaw) this.add(regionRaw, rootRegion?.id);
                }
            }
        }

        return { idMap: this.idMap, slugMap: this.slugMap };
    }

    get items() {
        return this.maps.idMap;
    }

    byId(id: number) {
        return this.maps.idMap.get(id);
    }

    bySlug(slug: string) {
        const newSlug = legacySlugToNew(slug) ?? slug;

        return this.maps.slugMap.get(slug) ?? this.maps.slugMap.get(newSlug);
    }

    protected groupedOptionsCached: GroupRegionFilter[] | undefined = undefined;

    get options() {
        let result = this.groupedOptionsCached;

        if (result) return result;

        result = [];
        for (const rec of this.rootRegions()) {
            const { rootRegion, regions } = rec;
            const options = [] as OptionTypeRegionFilter[];

            if (rootRegion?.displayName && rootRegion.id) {
                // Группы должны быть кликабельные, новый китовый селект не поддерживает такие, юзается наш старый SlimSelect
                options.push({
                    isParent: true,
                    parentId: undefined,
                    fullName: rootRegion.displayName,
                    label: rootRegion.displayName,
                    value: String(rootRegion.id)
                });
            }

            for (const region of regions ?? []) {
                if (! region?.displayName) continue;

                options.push({
                    isParent: false,
                    parentId: rootRegion?.id ? String(rootRegion.id) : undefined,
                    fullName: region.displayName,
                    label: region.displayName,
                    value: String(region.id)
                });
            }
            result.push({
                options,
                label: ''
            });
        }

        this.groupedOptionsCached = result;

        return result;
    }
}
