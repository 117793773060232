/* global ymaps */
/* eslint-disable max-len */
import './Tooltip.css';

import React from 'react';
import classname from '@search/classname/src';
import {
    useFavoritesButtonState
} from '@search/vtbeco-frontend-core/view/common/components/FavoritesButton/useFavoritesButtonState';
import { useYMapPane } from '../../Pane';
import { YMapVector } from '../../Vector';
import { useOfferMarkSessionVisited, useOfferSessionVisited } from './useSessionStorage';

const cn = classname.bind(null, 'YMapPlacemarkTooltip');

interface PriorityOptions {
    zIndex: number;
    zIndexActive?: number;
    zIndexDrag?: number;
    zIndexHover?: number;
}

export enum YMapPlacemarkPriority {
    Huge = 'Huge',
    High = 'High',
    Middle = 'Middle',
    Default = 'Default',
    Background = 'Background'
}

export const YMapPlacemarkZIndexes: {[key in YMapPlacemarkPriority]: PriorityOptions } = {
    [YMapPlacemarkPriority.Huge]: { zIndex: 690, zIndexHover: 775 }, // marker in offerCard
    [YMapPlacemarkPriority.High]: { zIndex: 675, zIndexHover: 775 },
    [YMapPlacemarkPriority.Middle]: { zIndex: 660, zIndexHover: 775 }, // vas-highlighted pins
    [YMapPlacemarkPriority.Default]: { zIndex: 650, zIndexHover: 775 },
    [YMapPlacemarkPriority.Background]: { zIndex: 640, zIndexHover: 775 }
};

export interface OfferYMapPlacemarkTooltipProps {
    id: string;
    total?: string;
    dataTest?: string;
    url?: string;
    targetBlank?: boolean;
    title: string;
    titleAttrs?: string;
    isActive?: boolean;
    isFavorite?: boolean;
    coordinates: YMapVector;
    hintLayout?: string;
    isVisited?: boolean;
    onClick?: (e: MouseEvent, container?: HTMLElement) => void;
    classes?: {
        active?: string;
        text?: string;
        total?: string;
        visited?: string;
        link?: string;
    };
    zIndexes?: YMapPlacemarkPriority;
    style?: string;
    theme?: 'green';
    isClassifiedMap?: boolean;
}

export function YMapPlacemarkTooltip({
    id,
    url,
    total,
    title,
    titleAttrs = '',
    targetBlank = false,
    coordinates,
    isVisited,
    hintLayout,
    dataTest,
    isActive: isActiveInitial,
    isFavorite = false,
    onClick: handleOnClick,
    classes,
    style = '',
    zIndexes = YMapPlacemarkPriority.Default,
    isClassifiedMap
}: OfferYMapPlacemarkTooltipProps) {
    const {
        map,
        api: {
            Placemark,
            templateLayoutFactory,
            geometry,
            // @ts-ignore
            shape
        }
    } = useYMapPane();

    const { isFavorite: favorite } = useFavoritesButtonState({
        isFavorite,
        id
    });

    const icon = favorite ?
        `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none" class="${cn('icon')}">
            <rect y="2" width="16" height="16" rx="8" fill="#F44535"/>
            <path d="M4.694 12.319C5.674 13.428 6.81 14.393 8 15.25c1.19-.857 2.326-1.822 3.306-2.931C12.209 11.296 13 10.08 13 8.892c0-1.58-1.235-3.142-2.85-3.142-.602 0-1.135.193-1.594.521-.197.14-.4.412-.556.597-.156-.185-.36-.457-.556-.597A2.698 2.698 0 0 0 5.85 5.75C4.235 5.75 3 7.311 3 8.892c0 1.189.79 2.404 1.694 3.427Z" fill="#fff"/>
        </svg>` : '';

    const visitedOfferMarkValue = useOfferMarkSessionVisited();
    const visitedOfferValue = useOfferSessionVisited();

    React.useEffect(() => {
        const PlacemarkLayout = templateLayoutFactory.createClass(url ? `
<a
    href="${url}"
    id="${id}"
    ${dataTest ? ` data-test="${dataTest}"` : ''}
    ${targetBlank ? 'target="_blank"' : ''}
    class="${cn('', undefined, classes?.link)}"
    style="${style}"
>
    ${total === undefined ?
        '' :
        `<div class="${cn('total', undefined, classes?.total)}">${total}</div>`
}
    <div ${titleAttrs} class="${cn('text', undefined, classes?.text)}">${title}</div>
    ${icon}
</a>
` : `
<span
    class="${cn()}"
>
    ${total === undefined ?
        '' :
        `<div class="${cn('total', undefined, classes?.total)}">${total}</div>`
}
    <div ${titleAttrs} class="${cn('text', undefined, classes?.text)}">${title}</div>
    ${icon}
</span>
`);

        const margin = 3;

        class MyPlacemarkLayout extends PlacemarkLayout {
            protected dispose = () => {};
            protected width: number = 0;
            protected height: number = 0;

            clear() {
                this.dispose();
                this.dispose = () => {};
                super.clear();
            }

            isActive?: boolean = isActiveInitial;

            left: number = 0;
            top: number = 0;

            build() {
                super.build();
                const el = this.containerEl;

                const mouseEnter = this.mouseEnter.bind(this);
                const mouseLeave = this.mouseLeave.bind(this);
                const onClick = this.onClick.bind(this);

                if (this.isActive || isActiveInitial) {
                    el.classList.add(cn('active'));
                    classes?.active && el.classList.add(classes.active);
                } else {
                    el.classList.remove(cn('active'));
                    classes?.active && el.classList.remove(classes.active);
                }

                if (this.isVisited) {
                    el.classList.add(cn('visited'));
                    classes?.visited && el.classList.add(classes.visited);
                } else {
                    el.classList.remove(cn('visited'));
                    classes?.visited && el.classList.remove(classes.visited);
                }

                this.width = el.offsetWidth;
                this.height = el.offsetHeight;

                this.left = -this.width / 2 - 1;
                this.top = -this.height + margin;
                el.style.left = this.left.toFixed(0) + 'px';
                el.style.top = this.top.toFixed(0) + 'px';
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const geoObject = (this.getData() as any).geoObject as ymaps.Placemark;
                const events = geoObject.events;

                this.dispose();
                // @ts-ignore
                events.add('click', onClick);
                events.add('mouseenter', mouseEnter);
                events.add('mouseleave', mouseLeave);
                this.dispose = () => {
                    // @ts-ignore
                    events.remove('click', onClick);
                    events.remove('mouseenter', mouseEnter);
                    events.remove('mouseleave', mouseLeave);
                };
            }

            get containerEl() {
                return this.getParentElement()?.getElementsByClassName(cn())[0] as HTMLAnchorElement;
            }

            mouseEnter() {
                this.isActive = true;
                this.rebuild();
            }

            mouseLeave() {
                this.isActive = false;
                this.rebuild();
            }

            // @ts-ignore
            // eslint-disable-next-line no-undef
            onClick(e: globalThis.MouseEvent) {
                if (handleOnClick) {
                    // e.target = this.containerEl;
                    handleOnClick(e, this.containerEl);
                }
                this.isVisited = true;
                if (! handleOnClick) {
                    this.containerEl?.dispatchEvent(new MouseEvent('click'));
                }
            }

            get isVisited() {
                return visitedOfferValue(id) ?? isVisited ?? false;
            }

            set isVisited(next: boolean) {
                visitedOfferMarkValue(id, next);
            }

            getShape() {
                const halfWidth = -this.width / 2;

                // @ts-ignore
                return new shape.Rectangle(new geometry.pixel.Rectangle([
                    [ -halfWidth, margin - this.height ],
                    [ halfWidth, margin + 3 ]
                ]));
            }
        }

        const placemark = new Placemark(
            new geometry.Point([ coordinates.y, coordinates.x ] as unknown as number[]),
            {

            },
            {
                // @ts-ignore
                iconLayout: MyPlacemarkLayout,
                hintContentLayout: hintLayout ? templateLayoutFactory.createClass(hintLayout) : undefined,
                hintCloseTimeout: 0,
                hintOpenTimeout: 150,
                ...YMapPlacemarkZIndexes[zIndexes]
            }
        );

        if (
            ! isClassifiedMap ||
            (isClassifiedMap && map.getZoom() >= 13) ||
            isActiveInitial
        ) {
            map.geoObjects.add(placemark);
        }

        return () => {
            map.geoObjects.remove(placemark);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        icon,
        visitedOfferValue,
        visitedOfferMarkValue,
        map,
        url,
        total,
        title,
        coordinates.x,
        coordinates.y,
        hintLayout,
        isActiveInitial,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        map.getZoom()
    ]);

    return null;
}
