import React, { useState, useCallback } from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'Ellipsis');

const Ellipsis: React.FunctionComponent<{
    text: string;
    length?: number;
}> = ({
    text,
    length = 230
}) => {
    const [ isShow, setShow ] = useState<boolean>(false);
    const isLong = text.length > length;
    let content = text;

    if (isLong && ! isShow) {
        content = text.slice(0, length);
        content = content.slice(0, content.lastIndexOf(' ')) + '...';
    }

    const handleClick = useCallback(() => {
        setShow(current => ! current);
    }, []);

    return (
        <div className={cn()}>
            <Typography tag='p'>
                {content}
            </Typography>
            {
                isLong ? (
                    <Typography
                        tag='span'
                        className={cn('toggle')}
                        onClick={handleClick}
                    >
                        { isShow ? 'Скрыть описание' : 'Развернуть описание' }
                    </Typography>
                ) : null
            }
        </div>
    );
};

export default Ellipsis;
