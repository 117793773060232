import React, { useContext } from 'react';

import { LinkButton, ButtonProps } from '@vtblife/uikit';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import classname from '@search/classname/src';
import { NewBuildingWhitelabelEnum, NewBuildingMortgageParams } from '@search/graphql-typings';

import { GlobalContext } from '../../../../common/components/GlobalContext';
import useMortgageDemandLink from '../../../../common/hooks/useMortgageDemandLink';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardMortgageButton');

export interface IMortgageButton {
    openModal?: () => void;
    mortgageParams?: NewBuildingMortgageParams;
    newBuildingName?: string;
    label?: string;
    size?: ButtonProps['size'];
}

const NewBuildingCardMortgageButton = ({
    label = 'Заявка на ипотеку',
    openModal,
    size,
    mortgageParams,
    newBuildingName
}: IMortgageButton) => {
    const {
        instanceConfig: {
            searchIndex
        },
        components: {
            MortgageButton
        }
    } = useContext(GlobalContext);

    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const mortgageDemandLink = useMortgageDemandLink({
        regionId: mortgageParams?.regionId,
        propertyCost: mortgageParams?.propertyCost,
        purpose: mortgageParams?.purposeValue,
        claimType: mortgageParams?.claimType,
        ownAmount: mortgageParams?.ownAmount,
        newBuildingName
    });

    const VtbMortgageButton = MortgageButton as React.ComponentClass;

    return (
        <div className={cn()}>
            {
                searchIndex === NewBuildingWhitelabelEnum.Vtb ? (
                    <VtbMortgageButton />
                ) : (
                    <LinkButton
                        size={size}
                        fullWidth
                        dataTest='mortgage-send-query'
                        variant='accent'
                        onClick={hasRoleM2Pro ? undefined : openModal}
                        href={hasRoleM2Pro ? mortgageDemandLink : undefined}
                    >
                        <span className={cn('inner-text-360')}>Ипотека</span>
                        <span className={cn('inner-text')}>{label}</span>
                    </LinkButton>
                )
            }
        </div>
    );
};

export default NewBuildingCardMortgageButton;
