import { parse as parseUrl } from 'url';
import { useMemo } from 'react';

import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';

import type { Route } from '@search/router/src/Route';

import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { INewBuildingRouterContext } from '@search/nb-routes/types';

const makeLocation = (url: string) => {
    const urlParsed = parseUrl(url);

    return {
        href: urlParsed.href,
        protocol: urlParsed.protocol!,
        origin: `${urlParsed.protocol}//${urlParsed.host}`,
        host: urlParsed.host!,
        hostname: urlParsed.hostname!,
        port: urlParsed.port!,
        pathname: urlParsed.pathname!,
        search: '',
        hash: urlParsed.hash!
    };
};

type Params = INewBuildingSearchType;
type DefParams = Partial<Params>;

const getDiff = (object0, object1) => transform(object1, (result, value1, key) => {
    const value0 = object0[key];

    if (! isEqual(value0, value1)) {
        result[key] = isObject(value0) && isObject(value1) ?
            getDiff(value0, value1) :
            value1;
    }
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export function makeBaseSegments<Params>({
    route,
    pageParams
}: {
    route: Route<Params, INewBuildingRouterContext, {}>;
    pageParams: Params;
}): {
    urls: string[];
    hasQuery: boolean;
} {
    const currentURL = route.url(pageParams);
    const {
        protocol,
        host,
        pathname,
        query
    } = parseUrl(currentURL);
    const baseURL = `${protocol}//${host}`;
    const segments = pathname!
        .split('/')
        .filter(Boolean);

    const urls = segments
        .reduce((result: string[], segment, i) => {
            result.push(`${result[i - 1] ?? ''}/${segment}`);

            return result;
        }, [])
        .map(url => `${baseURL}${url}/`);

    return {
        urls,
        hasQuery: Boolean(query)
    };
}

export const makeSegments = ({
    route,
    pageParams
}: {
    route: Route<INewBuildingSearchType, INewBuildingRouterContext, {}>;
    pageParams: INewBuildingSearchType;
}): {
    urls: string[];
    definingParams: DefParams[];
    hasQuery: boolean;
} => {
    const {
        urls,
        hasQuery
    } = makeBaseSegments({
        route,
        pageParams
    });

    const definingParams: DefParams[] = urls
        .map(url => {
            try {
                return route.params(makeLocation(url));
            } catch (e) {}
        })
        .map((params: Params, i, paramsList) => {
            if (! params) {
                return undefined;
            }

            if (i === 0 || ! paramsList[i - 1]) {
                return pick(params, 'region');
            }

            return getDiff(paramsList[i - 1], params);
        });

    return {
        urls: urls.filter((url, i) => Boolean(definingParams[i])),
        definingParams: definingParams.filter(Boolean),
        hasQuery
    };
};

export const useSegments = ({
    route,
    pageParams
}: {
    route: Route<INewBuildingSearchType, INewBuildingRouterContext, {}>;
    pageParams: INewBuildingSearchType;
}) => useMemo(
    () => makeSegments({ route, pageParams }),
    [ route, pageParams ]
);
