import React, { useContext } from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import Page from '../../../common/components/Page';
import { GlobalContext } from '../../../common/components/GlobalContext';

import './styles.css';

const cn = classname.bind(null, 'NotFoundPage');

const NotFoundPage: React.FunctionComponent = () => {
    const {
        instanceConfig: {
            productionBaseUrl,
            productionSearchUrl
        }
    } = useContext(GlobalContext);

    return (
        <Page
            className={cn()}
            contentClassName={cn('content')}
        >
            <Typography
                variant='promo-h1'
                bold
            >
                Извините, такой страницы нет.
            </Typography>
            <Typography
                variant='primary-alone'
                className={cn('mt20')}
            >
                {/* eslint-disable-next-line max-len */}
                Посмотрите <a href={productionSearchUrl}>каталог новостроек</a> или начните с <a href={productionBaseUrl}>главной страницы</a>.
            </Typography>
            <img
                // eslint-disable-next-line max-len
                src='//cdn.m2.ru/assets/file-upload-server/468e9de5aba2007993747f2a4f9406aa'
                crossOrigin='anonymous'
                alt=''
                style={{
                    width: '200px',
                    marginTop: '52px'
                }}
            />
        </Page>
    );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
