/* eslint-disable complexity, @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Checkbox, Button, Link, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { STATUS } from '@search/gql-client/src';
import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { NewBuildingsInput, NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import { Router } from '@search/router/src/Router';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { convertParamsToGQLFilters } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { INewBuildingRouterContext } from '@search/nb-routes/types';
import { NewBuildingCardRoute, INewBuildingCardType } from '@search/nb-routes/src/NewBuildingCardRoute';

import { CURRENT_YEAR } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { RootRegions } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/root-regions';
import { Filters, IFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { getInitialFilters } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewBuildingFilterInitial';
import {
    newbuildingFilterSerializer
} from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterSerializer';
import { useInitialPhone } from '@search/vtbeco-frontend-core/view/common/hooks/useInitialPhone';
import type { NewBuildingQuizFieldEvent } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingQuizEvent';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { CoreSvgSpriteIcon } from '@search/vtbeco-frontend-core/view/common/components/CoreSvgSpriteIcon/CoreSvgSpriteIcon';
import { useCallbackEmail } from '@search/vtbeco-frontend-core/domain/emails/useCallbackEmail';

import { useRootRegionsFetcher } from './gql/NewBuildingQuizRootRegionsQuery';
import { useQuizNewBuildingsFetcher } from './gql/NewBuildingQuizQuery';

import { DISCOUNT_ITEMS } from './dict';
import { STAGES } from './stage';
import type { TextKey } from './stage/types';

import './styles.css';

export const cnNewBuildingQuiz = classname.bind(null, 'NewBuildingQuiz');

const TOTAL_STAGES = 4;
const LAST_STAGE = TOTAL_STAGES + 2;

const MAIN_BUTTON_WIDTH = 296;
const SECONDARY_BUTTON_WIDTH = 100;
const MOBILE_SECONDARY_BUTTON_WIDTH = 52;

const ERROR_MESSAGE = 'Что-то пошло не так, попробуйте позже.';

const getIcon = (stage: number) => {
    if (stage === LAST_STAGE) {
        return 'QuizPromo';
    }

    if (stage > TOTAL_STAGES) {
        return 'QuizCall';
    }

    return 'QuizSearch';
};

const makeEmail = (record: Record<TextKey, string>) => ([
    {
        key: 'quizId',
        label: 'ID'
    },
    {
        key: 'region',
        label: 'Регион'
    },
    {
        key: 'rooms',
        label: 'Комнатность'
    },
    {
        key: 'apartments',
        label: ''
    },
    {
        key: 'price',
        label: 'Бюджет'
    },
    {
        key: 'paymentMethod',
        label: 'Способ покупки'
    },
    {
        key: 'phone',
        label: 'Телефон'
    },
    {
        key: 'query',
        label: 'Query'
    }
] as {
    key: TextKey;
    label: string;
}[])
    .map(
        // eslint-disable-next-line no-nested-ternary
        ({ key, label }) => record[key] ?
            label ?
                `${label}: ${record[key]}` :
                record[key] :
            ''
    )
    .filter(Boolean)
    .join('\n');

const PromoGiftItem = ({
    iconName, point, code, isLast
}: {
    iconName: string;
    point: string;
    code: string;
    isLast?: boolean;
}) => {
    return (
        <div className={cnNewBuildingQuiz('promo-item')}>
            <div className={cnNewBuildingQuiz('promo-icon')}>
                <CoreSvgSpriteIcon
                    name={iconName}
                    color='#FF784B'
                    width={24}
                    height={24}
                />
            </div>
            <Typography
                variant='primary'
                bold
            >
                {point} <Typography
                    tag='span'
                    variant='primary'
                    color='secondary'
                >
                    {code}
                </Typography>{isLast ? '.' : ';'}
            </Typography>
        </div>
    );
};

const PromoGifts = ({ isMobile }: { isMobile?: boolean }) => (
    <>
        <div className={cnNewBuildingQuiz('promo-title', { mobile: isMobile })}>
            <Typography variant='primary-alone'>
                Подарки за&nbsp;прохождение теста:
            </Typography>
        </div>
        <div className={cnNewBuildingQuiz('promo')}>
            {DISCOUNT_ITEMS.map(({ iconName, point, code }, idx, array) => (
                <PromoGiftItem
                    key={code}
                    iconName={iconName}
                    point={`cкидка ${point} по\u00A0промокоду`}
                    code={code}
                    isLast={idx === array.length - 1}
                />
            ))}
        </div>
    </>
);

const NewBuildingQuiz = ({
    isMobile = false,
    isInModal = false,
    hasTriedBefore = false,
    setHasTriedBefore,
    isClassified,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    rootRegions: rootRegionsProps
}: {
    isMobile?: boolean;
    isInModal?: boolean;
    hasTriedBefore?: boolean;
    setHasTriedBefore?: () => void;
    isClassified?: boolean;
    whiteLabel?: NewBuildingWhitelabelEnum;
    rootRegions?: RootRegions;
}) => {
    const [ stage, setStage ] = useState<number>(hasTriedBefore ? 0 : 1);
    const goToNextStage = useCallback(() => setStage(s => s + 1), []);
    const stageData = STAGES[stage - 1];
    const { makeTitle, makeSubtitle, filterProps, textProps, View } = stageData || {};

    const filters = useRef(getInitialFilters({
        region: RegionIdEnum.MSK_AND_MSK_OBL
    }));
    const [ params, setParams ] = useState(
        newbuildingFilterSerializer.serialize(filters.current.getFilters()) as INewBuildingSearchType
    );

    const rootRegionsLoad = useGql2Loader(useRootRegionsFetcher(), {
        cachePrefix: 'NewBuildingRootRegions',
        tracingTag: 'NewBuildingQuiz'
    });

    const quizNewBuildingsLoader = useGql2Loader(useQuizNewBuildingsFetcher(), {
        cachePrefix: 'NewBuildingQuizNewBuildings',
        tracingTag: 'NewBuildingQuiz'
    });

    const { data, clearCache } = quizNewBuildingsLoader({
        whiteLabel,
        // @ts-ignore
        filters: convertParamsToGQLFilters(params) as NewBuildingsInput
    });

    const { data: rootRegionsData } = rootRegionsLoad({
        whitelabel: NewBuildingWhitelabelEnum.Default,
        skipQuery: Boolean(rootRegionsProps)
    });

    const router = useMemo(() => {
        const rootRegions = rootRegionsData?.newBuildingRootRegions ?? [];

        // @ts-ignore
        const rootRegionsInstance = rootRegionsProps ?? new RootRegions(rootRegions);
        const win = typeof window === 'undefined' ? undefined : window;

        return new Router<INewBuildingRouterContext>({
            context: {
                rootRegions: rootRegionsInstance,
                isMobile
            },
            target: win,
            // @ts-ignore
            location: win?.location,
            history: win?.history
        });
    }, [ isMobile, rootRegionsData, rootRegionsProps ]);

    const buildCardUrl = useCallback((routeParams: INewBuildingCardType) => {
        const newBuildingCardRoute = router.route(NewBuildingCardRoute);

        return newBuildingCardRoute.url({
            name: routeParams.name,
            id: routeParams.id,
            region: routeParams.region,
            subRegion: routeParams.subRegion,
            type: routeParams.type
        });
    }, [ router ]);

    const dataCount = data?.searchNewBuildings?.paging?.total;
    const newBuildings = data?.searchNewBuildings?.items ?? [];

    const [ count, setCount ] = useState<number | undefined>(
        dataCount ?? undefined
    );

    useEffect(() => {
        if (typeof dataCount === 'number') {
            setCount(dataCount);
        }
    }, [ dataCount ]);

    const initialPhone = useInitialPhone();

    const [ textData, setTextData ] = useState<Record<TextKey, string>>({
        quizId: '',
        region: 'Москва и область',
        rooms: '',
        apartments: '',
        price: 'Пока не знаю',
        paymentMethod: 'Пока не знаю',
        phone: initialPhone,
        hasNotAgreed: 'true',
        query: ''
    });

    useEffect(() => setTextData(prev => ({ ...prev, phone: initialPhone })), [ initialPhone ]);

    useEffect(() => setTextData(value => ({
        ...value,
        quizId: Math.random().toString(36).substring(2),
        query: window.location.href.split('?')[1] ?? ''
    })), []);

    const updateData = useCallback((
        filtersToUpdate: IFilter[] | undefined,
        newTextData: Partial<Record<TextKey, string>> | undefined
    ) => {
        if (filtersToUpdate) {
            filtersToUpdate.forEach(filter => filters.current.update(filter));

            setParams(
                newbuildingFilterSerializer.serialize(filters.current.getFilters()) as INewBuildingSearchType
            );

            clearCache(true);
        }

        if (newTextData) {
            setTextData(value => ({
                ...value,
                ...newTextData
            }));
        }
    }, [ clearCache ]);

    const updateHasAgreed = useCallback(
        (value: boolean) => updateData(undefined, {
            hasNotAgreed: value ? '' : 'true'
        }),
        [ updateData ]
    );

    const isVtb = whiteLabel === NewBuildingWhitelabelEnum.Vtb;

    const [ requestStatus, setRequestStatus ] = useState<STATUS>(STATUS.INIT);
    const sendCallBackQuizRequest = useCallbackEmail();
    const sendRequest = useCallback(() => {
        const makeRequest = () => {
            const sourceMatch = textData.query.match(/quiz_source=([^&]*)/);
            const source = sourceMatch && sourceMatch[1] || '';

            sendCallBackQuizRequest({
                subject: `${
                    isVtb ? 'VTB' : 'Inhouse'}/Квиз${
                    isClassified ? ' из Уютного поиска' : ''}${
                    source ? ` ${source}` : ''
                }`,
                body: makeEmail(textData)
            }, { pathUniqSuffix: '/callback-quiz/' })
                .then(result => setRequestStatus(
                    result.data?.createEmailCallbackSimple.emailMessageId ?
                        STATUS.RESOLVE :
                        STATUS.ERROR
                ))
                .catch(() => setRequestStatus(STATUS.ERROR));
        };

        if (requestStatus !== STATUS.PENDING) {
            makeRequest();
            setRequestStatus(STATUS.PENDING);
        }
    }, [ requestStatus, textData, sendCallBackQuizRequest, isClassified, isVtb ]);

    useEffect(() => {
        switch (requestStatus) {
            case STATUS.ERROR:
                // eslint-disable-next-line no-alert
                window.alert(ERROR_MESSAGE);

                return;
            case STATUS.RESOLVE:
                setStage(s => s + 1);

                setHasTriedBefore?.();

                return;
            default:
        }
    }, [ requestStatus, setHasTriedBefore ]);

    const fieldEvents = useRef<Record<number, NewBuildingQuizFieldEvent>>({});
    const updateFieldEvent = useCallback((
        eventStage: number,
        event: NewBuildingQuizFieldEvent
    ) => {
        fieldEvents.current = {
            ...fieldEvents.current,
            [eventStage]: event
        };
    }, []);

    useEffect(() => {
        const previousStage = stage - 1;

        if (textData.quizId && previousStage >= 1 && previousStage <= TOTAL_STAGES) {
            dataLayerPush({
                quiz_id: textData.quizId,
                event: 'nb_quiz_step_filled',
                event_action: previousStage
            });

            const fieldEvent = fieldEvents.current[previousStage];

            if (fieldEvent) {
                dataLayerPush({
                    quiz_id: textData.quizId,
                    event: 'nb_quiz_field_filled',
                    ...fieldEvent
                });
            }
        }
    }, [ stage, textData.quizId ]);

    useEffect(() => {
        if (textData.quizId && requestStatus === STATUS.RESOLVE) {
            dataLayerPush({
                quiz_id: textData.quizId,
                event: 'nb_quiz_step_filled',
                event_action: 'success'
            });
        }
    }, [ requestStatus, textData.quizId ]);

    const helpBlock = useMemo(() => (
        <>
            <Typography
                className={isMobile ? undefined : cnNewBuildingQuiz('text', { bottom: true })}
                variant='secondary-alone'
                tag={isMobile ? 'span' : 'div'}
            >
                {isMobile ?
                    'Поможем подобрать квартиру и\u00A0оформить документы. ' :
                    'Сложно выбрать? «Метр квадратный» поможет найти подходящее.'
                }
            </Typography>
            <Link onClick={() => setStage(TOTAL_STAGES + 1)}>
                <Typography
                    // eslint-disable-next-line max-len
                    className={isMobile ? undefined : cnNewBuildingQuiz('link', undefined, cnNewBuildingQuiz('boldish'))}
                    variant={isMobile ? 'secondary-alone' : 'primary-alone'}
                    tag={isMobile ? 'span' : 'div'}
                >
                    Получить консультацию
                </Typography>
            </Link>
        </>
    ), [ isMobile ]);

    const countText = useMemo(() => count ? `${
        formatLocaleNumber(count)
    } ${
        declensionByNumber(count, [
            'предложение', 'предложения', 'предложений'
        ])
    }` : '', [ count ]);

    // eslint-disable-next-line complexity
    const buttons = useMemo(() => (
        <>
            {stage === 0 && (
                <Button
                    fullWidth={isMobile}
                    width={isMobile ? undefined : MAIN_BUTTON_WIDTH}
                    onClick={() => setStage(1)}
                >
                    Начать новый поиск
                </Button>
            )}
            {stage >= 2 && stage <= TOTAL_STAGES && (
                <Button
                    variant='secondary'
                    width={
                        isMobile ?
                            MOBILE_SECONDARY_BUTTON_WIDTH :
                            SECONDARY_BUTTON_WIDTH
                    }
                    icon={isMobile ? 'arrow-left' : undefined}
                    onClick={() => setStage(stage - 1)}
                >
                    {isMobile || 'Назад'}
                </Button>
            )}
            {stage >= 1 && stage <= TOTAL_STAGES && (
                <>
                    <Button
                        width={isMobile ? undefined : MAIN_BUTTON_WIDTH}
                        onClick={() => setStage(stage + 1)}
                    >
                        {
                            // eslint-disable-next-line no-nested-ternary
                            stage === 1 ? 'Начать тест' :
                                stage === TOTAL_STAGES ? 'Завершить тест' :
                                    'Следующий вопрос'
                        }
                    </Button>
                    {isInModal && ! isMobile && (
                        <div className={cnNewBuildingQuiz('rightButtonWrapper')}>
                            <Button
                                variant='transparent'
                                onClick={() => setStage(TOTAL_STAGES + 1)}
                            >
                                Получить консультацию
                            </Button>
                        </div>
                    )}
                </>
            )}
            {stage > TOTAL_STAGES && requestStatus !== STATUS.RESOLVE && (
                <Button
                    fullWidth={isMobile && isInModal}
                    width={isMobile ? undefined : MAIN_BUTTON_WIDTH}
                    loading={requestStatus === STATUS.PENDING}
                    disabled={textData.phone.length < 11 || Boolean(textData.hasNotAgreed)}
                    onClick={sendRequest}
                >
                    Получить консультацию
                </Button>
            )}
        </>
    ), [
        isMobile, isInModal,
        stage, textData, sendRequest,
        requestStatus
    ]);

    const subtitle = useMemo(() => makeSubtitle?.(isVtb), [ isVtb, makeSubtitle ]);

    // eslint-disable-next-line complexity
    return useMemo(() => (
        <div className={cnNewBuildingQuiz(null, {
            desktop: ! isMobile,
            mobile: isMobile,
            modal: isInModal
        })}>
            {isInModal || (
                <div className={cnNewBuildingQuiz('header')}>
                    <CoreSvgSpriteIcon
                        name='M2Logo'
                        color='#77738C'
                        width={isMobile ? 36 : 58}
                        height={isMobile ? 32 : 52}
                    />
                </div>
            )}
            <div className={cnNewBuildingQuiz('main')}>
                <div className={cnNewBuildingQuiz('left', { isVtbHeight: isVtb && stage === TOTAL_STAGES + 1 })}>
                    <div className={cnNewBuildingQuiz('leftMain')}>
                        {stage === 0 ? (
                            <>
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant={isMobile ? 'promo-primary-alone' : 'h2'}
                                >
                                    Начать новый поиск новостройки?
                                </Typography>
                                <Typography
                                    className={cnNewBuildingQuiz('subtitle')}
                                    variant='primary'
                                >
                                    {/* eslint-disable-next-line max-len */}
                                    Вам нужно будет заново пройти тест, а&nbsp;мы&nbsp;сбросим условия предыдущего запроса.
                                </Typography>
                            </>
                        ) : (
                            <>
                                {stage >= 1 && stage <= TOTAL_STAGES && (
                                    <Typography
                                        className={cnNewBuildingQuiz('stage', undefined, cnNewBuildingQuiz('boldish'))}
                                        variant='secondary-alone'
                                        color='secondary'
                                    >
                                        {
                                            stage === TOTAL_STAGES ?
                                                'последний вопрос' :
                                                `вопрос ${stage} из ${TOTAL_STAGES}`
                                        }
                                    </Typography>
                                )}
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant={isMobile ? 'promo-primary-alone' : 'h2'}
                                >
                                    {makeTitle(isMobile)}
                                </Typography>
                                {subtitle && (
                                    <Typography
                                        className={cnNewBuildingQuiz('subtitle')}
                                        variant='primary'
                                    >
                                        {subtitle}
                                    </Typography>
                                )}
                                {/* @ts-ignore */}
                                <View {...{
                                    ...(filterProps ?? []).reduce((props, filterProp) => ({
                                        ...props,
                                        [`${filterProp}Filter`]: filters.current.get(filterProp)
                                    }), {} as Record<Filters, IFilter>),
                                    ...(textProps ?? []).reduce((props, textProp) => ({
                                        ...props,
                                        [`${textProp}Value`]: textData[textProp]
                                    }), {} as Record<Filters, IFilter>),
                                    updateData,
                                    updateEvent: updateFieldEvent.bind(null, stage),
                                    requestStatus,
                                    goToNextStage,
                                    newBuildings,
                                    buildCardUrl,
                                    quizId: textData.quizId,
                                    isMobile,
                                    isVtb
                                }} />
                            </>
                        )}
                    </div>
                    {isMobile && stage >= 3 && stage <= TOTAL_STAGES && (
                        <div className={cnNewBuildingQuiz('help')}>
                            {helpBlock}
                        </div>
                    )}
                    {stage === TOTAL_STAGES + 1 && (
                        <div className={cnNewBuildingQuiz('privacy')}>
                            <Checkbox
                                value={! textData.hasNotAgreed}
                                onChange={updateHasAgreed}
                            >
                                <Typography variant='secondary-alone'>
                                    Я&nbsp;ознакомлен с&nbsp;<Link
                                        href='https://m2.ru/support/clients/soglasheniya/privacy-policy/'
                                        target='blank'
                                    >
                                        <Typography variant='secondary-alone'>
                                            Политикой конфиденциальности
                                        </Typography>
                                        {/* eslint-disable-next-line max-len */}
                                    </Link> и&nbsp;согласен на&nbsp;получение консультаций, информационных и/или&nbsp;рекламных рассылок
                                </Typography>
                            </Checkbox>
                        </div>
                    )}
                    {stage === LAST_STAGE && (isInModal || isMobile) && ! isVtb && <PromoGifts isMobile={isMobile} />}
                    {stage === LAST_STAGE && ! isInModal && (
                        <Typography
                            variant='secondary-alone'
                            className={cnNewBuildingQuiz('newSearchText', { mobile: isMobile })}
                        >
                            Чтобы изменить параметры подбора, <Link onClick={() => window.location.reload()}>
                                <Typography variant='secondary-alone'>начните новый поиск</Typography>
                            </Link>
                        </Typography>
                    )}
                    {stage === LAST_STAGE && isMobile ? null : (
                        <div className={cnNewBuildingQuiz('buttons')}>
                            {buttons}
                        </div>
                    )}
                </div>
                {isMobile || isInModal || (
                    <div>
                        <CoreSvgSpriteIcon
                            name={getIcon(stage)}
                            color='#77738C'
                            width={268}
                            height={160}
                        />
                        {stage === 1 && (
                            <div className={cnNewBuildingQuiz('text', { top: true })}>
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant='primary-alone'
                                >
                                    На&nbsp;нашем сервисе более 2&nbsp;500 проверенных новостроек
                                </Typography>
                            </div>
                        )}
                        {stage >= 2 && stage < TOTAL_STAGES && count && (
                            <div className={cnNewBuildingQuiz('text', { top: true })}>
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant='primary-alone'
                                    color='secondary'
                                >
                                    С такими параметрами
                                </Typography>
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant='primary-alone'
                                >
                                    {`${
                                        declensionByNumber(count, [
                                            'было найдено', 'были найдены', 'были найдены'
                                        ])
                                    } ${
                                        countText
                                    }`}
                                </Typography>
                            </div>
                        )}
                        {stage === TOTAL_STAGES && (
                            <div className={cnNewBuildingQuiz('text', { top: true })}>
                                <Typography
                                    className={cnNewBuildingQuiz('boldish')}
                                    variant='primary-alone'
                                >
                                    Ваш список с&nbsp;подходящими предложениями почти готов!
                                </Typography>
                            </div>
                        )}
                        {stage >= 1 && stage <= TOTAL_STAGES && helpBlock}
                        {
                            stage === LAST_STAGE && ! isVtb ? <PromoGifts /> : stage > TOTAL_STAGES && (
                                <>
                                    <div className={cnNewBuildingQuiz('text', { top: true })}>
                                        <Typography
                                            className={cnNewBuildingQuiz('boldish')}
                                            variant='primary-alone'
                                        >
                                            Профессионально и&nbsp;бесплатно
                                        </Typography>
                                    </div>
                                    <Typography
                                        className={cnNewBuildingQuiz('text', { bottom: true })}
                                        variant='secondary-alone'
                                    >
                                        Консультация ни&nbsp;к&nbsp;чему вас не&nbsp;обязывает,
                                        а&nbsp;только информирует об&nbsp;актуальных предложениях.
                                    </Typography>
                                </>
                            )
                        }
                    </div>
                )}
            </div>
            {isMobile || isInModal || (
                <Typography
                    className={cnNewBuildingQuiz('footer')}
                    variant='small'
                    color='minor'
                >
                    {/* eslint-disable-next-line max-len */}
                    &copy;&nbsp;Метр квадратный, {CURRENT_YEAR}. Сервис поиска недвижимости, ипотечные предложения и&nbsp;ремонт в&nbsp;один клик. Общество с&nbsp;ограниченной ответственностью &laquo;Экосистема недвижимости &laquo;Метр квадратный&raquo;, ОГРН 1197746330132, Адрес: 127055, г. Москва, улица Лесная, дом&nbsp;43, под. VI, пом. 4Ч
                </Typography>
            )}
        </div>
    ), [
        isMobile, isInModal,
        stage, goToNextStage,
        makeTitle, subtitle, View,
        filterProps, textProps,
        updateData, updateFieldEvent,
        updateHasAgreed,
        helpBlock, buttons, textData,
        count, countText,
        requestStatus, newBuildings, buildCardUrl, isVtb
    ]);
};

NewBuildingQuiz.displayName = 'NewBuildingQuiz';

export default NewBuildingQuiz;
