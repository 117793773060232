import upperFirst from 'lodash/upperFirst';

import type { Route } from '@search/router/src/Route';

import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { INewBuildingRouterContext } from '@search/nb-routes/types';

import { formatNumber } from '@search/helpers/src/formatNumber';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';

import { spbInnerTowns } from '@search/offer-search-routes/src/mappers/regions/spb/spbCityMapper';
import { mskOblDistrict, mskSpecialDistricts } from '@search/offer-search-routes/src/mappers/regions/msk/mskDistrictMapper';
import { mskCity, mskOblCity } from '@search/offer-search-routes/src/mappers/regions/msk/mskCityMapper';
import { FinishingEnum } from '@search/filter-enums/gql/schema-types-fix';

import { AreaUnitEnum } from '@search/graphql-typings';

import type { SEOTextsEntry } from '../types';
import { seoTextDict } from './seoTextDict';
import { makeSegments } from './makeSegments';

export type SeoInfoType = {
    heading: string;
    description: string;
    nominative: string;
    genitivePlural: string;
    toBuy: string;
    isApartments: boolean;
}

export const makeSEOTexts = ({
    route,
    pageParams,
    geoStore,
    locative,
    total,
    seoHost,
    hasCleanURLs,
    seoTexts
}: {
    route: Route<INewBuildingSearchType, INewBuildingRouterContext, {}>;
    pageParams: INewBuildingSearchType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore?: any;
    locative?: string;
    total?: number;
    seoHost: string;
    hasCleanURLs?: boolean;
    seoTexts?: SEOTextsEntry;
}): {
    title: string;
    description: string;
    heading?: string;
    seoInfo?: SeoInfoType;
} => {
    const {
        definingParams,
        hasQuery
    } = makeSegments({
        route,
        pageParams
    });

    let roomsText = 'квартира';
    let roomsPluralText = 'квартиры';
    let roomsGenitivePluralText = 'квартир';
    let roomsBuyText = 'купить квартиру';

    let newBuildingText = ' в\u00A0новостройке';
    let newBuildingExtraText = ' в\u00A0новостройке (ЖК)';
    let newBuildingPluralText = ' в\u00A0новостройках';

    let priceText = ' c\u00A0ценами';

    const regionText = locative ? ` ${
        locative.replace(/\s+/g, '\u00A0')
    }` : '';
    let geoPriceText = regionText;

    const totalText = total && ! hasQuery ? `${
        formatNumber(total)
    } ${
        declensionByNumber(total, [ 'жилой комплекс', 'жилых комплекса', 'жилых комплексов' ])
    }` : 'жилые комплексы';

    let hasSeoInfo = false;
    let isApartments: boolean = false;

    const headingBase = `${
        upperFirst(roomsPluralText)
    }${
        newBuildingPluralText
    }`;
    let heading = seoTexts?.h1 ?? `${
        headingBase
    }${
        geoPriceText
    }`;

    const makeTexts = () => ({
        title: seoTexts?.title ?? `${
            upperFirst(roomsBuyText)
        }${
            newBuildingExtraText
        }${
            geoPriceText
        }\u00A0— ${
            totalText
        }, цены от\u00A0застройщика, продажа квартир в\u00A0новом доме\u00A0— ${
            seoHost
        }`,
        description: seoTexts?.description ?? `🏢 ${
            upperFirst(roomsBuyText)
        }${
            newBuildingExtraText
        }${
            geoPriceText
        }. ${
            upperFirst(totalText)
            // eslint-disable-next-line max-len
        } от\u00A0застройщиков${
            priceText
        }. ✔\u00A0Описание и\u00A0фото. ✔\u00A0Отзывы покупателей. ✔\u00A0Расположение на\u00A0карте.`,
        heading,
        seoInfo: hasSeoInfo ? {
            heading: `${
                upperFirst(roomsPluralText)
            }${
                newBuildingPluralText
            }${
                geoPriceText
            }`,
            description: `${
                upperFirst(roomsBuyText)
            }${
                newBuildingExtraText
            }${
                geoPriceText
            }\u00A0— лучшие цены на\u00A0сайте ${
                seoHost
                // eslint-disable-next-line max-len
            }. Большой каталог от\u00A0застройщиков. Продажа квартир в\u00A0жилых комплексах по\u00A0цене от\u00A0застройщика.`,
            nominative: `${
                roomsText
            }${
                newBuildingText
            }${
                geoPriceText
            }`,
            genitivePlural: `${
                roomsGenitivePluralText
            }${
                newBuildingPluralText
            }${
                geoPriceText
            }`,
            toBuy: `${
                roomsBuyText
            }${
                newBuildingText
            }${
                geoPriceText
            }`,
            isApartments
        } : undefined
    });

    if (! hasCleanURLs) {
        return makeTexts();
    }

    let commissioning: string = '';
    let buildingClass: string = '';
    let wallsType: string = '';

    const paramTexts = {
        finishing: '',

        armyMortgage: '',
        installment: '',
        motherCapital: '',
        floorLast: '',

        deals: '',

        priceMax: '',
        pricePerMeterMax: '',

        metro: '',
        district: '',
        districtId: 0,

        apartments: '',
        parking: '',
        features: '',
        flatsSpecialEvents: ''
    };

    let rooms: INewBuildingSearchType['rooms'];
    let isCheap: boolean = false;
    let isEuroPlanning: boolean = false;

    const addFeature = (currentFeature: string) => {
        return paramTexts.features ? `${paramTexts.features} и\u00A0${currentFeature}` : ` c\u00A0${currentFeature}`;
    };

    definingParams
        // eslint-disable-next-line complexity
        .forEach(params => {
            const {
                commissioning: commissioningValue,
                rooms: roomsList,
                hasFinishing,
                finishingType: finishingTypeList,
                buildingClass: buildingClassList,
                wallsType: wallsTypeList,
                floorLast,
                law214,
                escrow,
                armyMortgage,
                installment,
                priceMax,
                priceType,
                sort,
                transportType,
                maxMinutes,
                metroId: metroIdList,
                districtId: districtIdList,
                closedArea,
                concierge,
                apartments,
                parking,
                kitchenAreaRangeMin,
                ceilingHeight,
                balcony,
                motherCapital,
                flatsSpecialEvents
            } = params;

            if (roomsList) {
                rooms = roomsList;

                if (kitchenAreaRangeMin === 17) {
                    isEuroPlanning = true;
                }
            } else if (ceilingHeight) {
                paramTexts.features = ` c\u00A0${seoTextDict.makeParam('ceilingHeight')}`;
            } else if (kitchenAreaRangeMin) {
                paramTexts.features = addFeature(
                    seoTextDict.makeParam('bigKitchen')
                );
            } else if (closedArea) {
                paramTexts.features = addFeature(
                    seoTextDict.makeParam('closedArea')
                );
            } else if (concierge) {
                paramTexts.features = addFeature(
                    seoTextDict.makeParam('concierge')
                );
            } else if (balcony) {
                paramTexts.features = addFeature(
                    seoTextDict.makeParam('balcony')
                );
            } else if (commissioningValue) {
                commissioning = commissioningValue;
            } else if (buildingClassList) {
                buildingClass = buildingClassList[0];
            } else if (wallsTypeList) {
                wallsType = wallsTypeList[0];
            } else if (hasFinishing === FinishingEnum.Available) {
                paramTexts.finishing = ` ${seoTextDict.makeFinishing()}`;
            } else if (finishingTypeList) {
                const finishingText = seoTextDict.makeFinishingType(finishingTypeList[0]);

                if (finishingText) {
                    paramTexts.finishing = ` ${finishingText}`;
                }
            } else if (floorLast) {
                const floorLastText = seoTextDict.makeFloorLast(floorLast);

                if (floorLastText) {
                    paramTexts.floorLast = ` ${floorLastText}`;
                }
            } else if (law214) {
                paramTexts.deals = ` ${seoTextDict.makeParam(paramTexts.deals ? 'deals' : 'law214')}`;
            } else if (escrow) {
                paramTexts.deals = ` ${seoTextDict.makeParam(paramTexts.deals ? 'deals' : 'escrow')}`;
            } else if (armyMortgage) {
                paramTexts.armyMortgage = ` ${seoTextDict.makeParam('armyMortgage')}`;
            } else if (installment) {
                paramTexts.installment = ` ${seoTextDict.makeParam('installment')}`;
            } else if (motherCapital) {
                paramTexts.motherCapital = ` ${seoTextDict.makeParam('motherCapital')}`;
            } else if (flatsSpecialEvents) {
                paramTexts.flatsSpecialEvents = ` ${seoTextDict.makeFlatsSpecialEvents(
                    Array.isArray(flatsSpecialEvents) ? flatsSpecialEvents : [ flatsSpecialEvents ]
                )}`;
            } else if (apartments) {
                isApartments = true;
            } else if (parking) {
                const parkingText = seoTextDict.makeParking(Array.isArray(parking) ? parking : [ parking ]);

                if (parkingText) {
                    paramTexts.parking = ` ${parkingText}`;
                }
            } else if (transportType && maxMinutes) {
                paramTexts.metro = ` ${seoTextDict.makeParam('closeToMetro')}`;
            } else if (priceMax) {
                if (sort) {
                    isCheap = true;
                } else if (priceType === AreaUnitEnum.Meter) {
                    paramTexts.priceMax = ` ${seoTextDict.makePricePerMeterMax(priceMax)}`;
                } else {
                    paramTexts.priceMax = ` ${seoTextDict.makePriceMax(priceMax)}`;
                }
            } else if (metroIdList) {
                const [ metroId ] = metroIdList as number[];
                const [ metroGeo ] = geoStore?.stations.findRouteByStations([ metroId ]).stations ?? [];

                if (metroGeo?.title) {
                    paramTexts.metro = ` у\u00A0метро ${metroGeo.title}`;
                }
            } else if (districtIdList) {
                const districtId = Array.isArray(districtIdList) ? districtIdList[0] : districtIdList;
                const districtGeo = geoStore?.districts.get(districtId);
                const districtLocative = districtGeo?.locativeName;

                if (districtLocative && districtLocative !== locative) {
                    paramTexts.district = ` ${districtLocative}`;
                    paramTexts.districtId = districtId;
                }
            }
        });

    const roomsValue: string = rooms ? rooms[0] : '';

    if (isEuroPlanning) {
        roomsText = seoTextDict.makeEuroPlanning(roomsValue, isCheap);
        roomsPluralText = seoTextDict.makeEuroPlanningPlural(roomsValue, isCheap);
        roomsGenitivePluralText = seoTextDict.makeEuroPlanningGenitivePlural(roomsValue, isCheap);
        roomsBuyText = seoTextDict.makeEuroPlanningBuy(roomsValue, isCheap);
    } else {
        roomsText = seoTextDict.makeRooms(roomsValue, isCheap, isApartments);
        roomsPluralText = seoTextDict.makeRoomsPlural(roomsValue, isCheap, isApartments);
        roomsGenitivePluralText = seoTextDict.makeRoomsGenitivePlural(roomsValue, isCheap, isApartments);
        roomsBuyText = seoTextDict.makeRoomsBuy(roomsValue, isCheap, isApartments);
    }

    if (isCheap) {
        priceText = ' по\u00A0низким ценам';
    }

    let extraText = `${
        paramTexts.installment
    }${
        paramTexts.armyMortgage
    }${
        paramTexts.floorLast
    }${
        paramTexts.finishing
    }${
        paramTexts.parking
    }${
        paramTexts.deals
    }${
        paramTexts.features
    }${
        paramTexts.motherCapital
    }${
        paramTexts.flatsSpecialEvents
    }`;

    const commaParamTexts = [].filter(Boolean);

    if (commaParamTexts.length) {
        extraText = `${
            extraText
        }${
            commaParamTexts
                .map(text => `,${text}`)
                .join('')
        },`;
    }

    newBuildingText = ` ${
        seoTextDict.makeNewBuilding(commissioning, buildingClass, wallsType)
    }${
        extraText
    }`;
    newBuildingExtraText = ` ${
        seoTextDict.makeNewBuilding(commissioning, buildingClass, wallsType, false, true)
    }${
        extraText
    }`;
    newBuildingPluralText = ` ${
        seoTextDict.makeNewBuilding(commissioning, buildingClass, wallsType, true)
    }${
        extraText
    }`;

    const geoPriceTextWithoutRegion = `${
        paramTexts.metro
    }${
        paramTexts.district
    }${
        paramTexts.priceMax
    }${
        paramTexts.pricePerMeterMax
    }`;

    const isWithoutRegion: boolean = Object.values({
        ...spbInnerTowns,
        ...mskSpecialDistricts,
        ...mskCity,
        ...mskOblCity,
        ...mskOblDistrict
    }).includes(paramTexts.districtId);

    geoPriceText = isWithoutRegion ?
        geoPriceTextWithoutRegion :
        `${
            geoPriceTextWithoutRegion
        }${
            regionText
        }`;

    heading = seoTexts?.h1 ?? `${
        upperFirst(roomsPluralText)
    }${
        newBuildingPluralText
    }${
        geoPriceText
    }`;

    const simpleHeading = `${
        headingBase
    }${
        isWithoutRegion ?
            paramTexts.district :
            regionText
    }`;

    // https://jira.m2.ru/browse/NB-4032
    if (heading === simpleHeading) {
        heading = `${heading} от\u00A0застройщиков`;
    }

    hasSeoInfo = true;

    return makeTexts();
};
