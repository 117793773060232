import { useRef, useState, MutableRefObject } from 'react';
import useEventListener from './useEventListener';

const useHoverable = (): [boolean, MutableRefObject<HTMLElement | undefined>, (node: HTMLElement) => void] => {
    const elementRef = useRef<HTMLElement>();
    const [ isHovered, setHovered ] = useState(false);

    useEventListener('mouseenter', () => {
        setHovered(true);
    }, elementRef);
    useEventListener('mouseleave', () => {
        setHovered(false);
    }, elementRef);

    return [ isHovered, elementRef, node => {
        elementRef.current = node;
    } ];
};

export default useHoverable;
