import { UseAuthError } from './useAuth';
import { DelayedPersistRunner } from './DelayedPersistRunner';

export class AuthDelayedPersistRunner extends DelayedPersistRunner {
    static instance = new AuthDelayedPersistRunner();

    get id() {
        return 'AuthDelayedPersistRunner';
    }

    isPersist(e: Error) {
        return e instanceof UseAuthError;
    }
}
