/* eslint-disable complexity */
import React, { FunctionComponent, ReactText, useMemo, useContext, useState, useCallback } from 'react';

import { Select, useSelect } from '@vtblife/uikit';

import { errorCatcherWrap } from '@search/error/src/catcher';
import { ROSSIYA } from '@search/filter-enums/enums/Region';
import { useGeoUserRegion } from '@search/geo/src/useGeoUserRegion';

import {
    RenderRegionSelectUikitContent,
    OptionTypeRegionFilter, GroupedOptions,
} from '@search/vtbeco-frontend-core/view/filters/components/filter_types/region/RenderRegionSelectUikitContent';
import {
    getFilteredFlatRegionSelectOptions,
    getSelectedOptionLabel
} from '@search/vtbeco-frontend-core/view/filters/helpers/regionHelper';

import { GlobalContext, IGlobalContext } from '../../GlobalContext';

export const DeveloperSearchRegionFilter: FunctionComponent<{
    regionId?: number | null;
    onChange: (value: ReactText) => void;
}> = ({
    regionId,
    onChange
}) => {
    const { setRegionId } = useGeoUserRegion();

    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { isMobile } = globalContext;
    const allOptions = useMemo(
        () => [
            {
                label: '',
                options: [ {
                    isParent: true,
                    fullName: 'Россия',
                    label: 'Россия',
                    value: String(ROSSIYA)
                } ]
            },
            ...globalContext.rootRegions.getRegionsLikeOptions()
        ] as GroupedOptions<OptionTypeRegionFilter>[],
        [ globalContext.rootRegions ]
    );

    const [ searchInputValue, setSearchInputValue ] = useState('');
    const resetSearchInputValue = useCallback(() => setSearchInputValue(''), []);

    const options = searchInputValue ?
        getFilteredFlatRegionSelectOptions(searchInputValue, allOptions) :
        allOptions;

    const value = String(regionId ?? ROSSIYA);

    const title = useMemo(
        () => getSelectedOptionLabel(value, allOptions),
        [ value, allOptions ]
    );

    const { is, handleChange: handleSelectChange } = useSelect(
        (newValue: string) => {
            setRegionId(Number(newValue));
            onChange(newValue);
        },
        { selected: value }
    );

    return (
        <Select
            onChange={handleSelectChange}
            dataTest='region'
            onToggle={() => {
                resetSearchInputValue();
            }}
        >
            <Select.Button size='s'>{title}</Select.Button>
            <Select.Content
                height={310}
                adaptiveHeight={false}
                adaptivePlacement={false}
                placement='local'
            >
                <RenderRegionSelectUikitContent
                    is={is}
                    isMobile={isMobile}
                    searchInputValue={searchInputValue}
                    emptyText={'Нет похожих регионов'}
                    setSearchInputValue={setSearchInputValue}
                    showInput={allOptions.length >= 10}
                    options={options}
                />
            </Select.Content>
        </Select>
    );
};

DeveloperSearchRegionFilter.displayName = 'DeveloperSearchRegionFilter';

export const DeveloperSearchWithCatcher = errorCatcherWrap(DeveloperSearchRegionFilter);
