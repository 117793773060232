import { useCallback } from 'react';

import { GqlClientOptions, graphql, useGqlContext } from '@search/gql-client/src';

import type {
    NewBuildingQuizQuery$data,
    NewBuildingQuizQuery$variables
} from './__generated__/NewBuildingQuizQuery.graphql';

const quizQuery = graphql`
    query NewBuildingQuizQuery(
        $filters: NewBuildingsInput
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        searchNewBuildings(
            filters: $filters
            whiteLabel: $whiteLabel
            isQuiz: true
        ) {
            items {
                ...on NewBuildingSnippet {
                    id
                    developerCards {
                        developer {
                            id
                        }
                    }
                    title {
                        nominative
                    }
                    housingTypeQuiz
                    buildingStatusQuiz
                    priceMin
                    address
                    buildingStatus {
                        title
                        isFrozen
                    }
                    gallery {
                        baseUrl
                        main {
                            originPath
                        }
                    }
                    description
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                        }
                    }
                    flats {
                        rooms
                        labelFull
                        priceMin
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                    specialEvents {
                        type
                        titleRaw
                    }
                }
            }
            paging {
                total
            }
        }
    }
`;

export function useQuizNewBuildingsFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: NewBuildingQuizQuery$variables, opts?: GqlClientOptions) => gql.client<
            NewBuildingQuizQuery$variables,
            NewBuildingQuizQuery$data
        >(quizQuery, vars, opts).then(response => response),
        [ gql ]
    );
}
