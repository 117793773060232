import React from 'react';

import { LinkButton } from '@vtblife/uikit';

import classname from '@search/classname/src';

import { VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../../../../../client/common/constants/mortgage';

import './styles.css';

const cn = classname.bind(null, 'Mortgage');

const NewBuildingCardMortgage: React.FunctionComponent<{
    id: string;
    hasMilitaryMortgage: boolean;
    hasApartments: boolean;
    isMskMOorSpbLO?: boolean;
}> = ({ id, hasMilitaryMortgage, hasApartments, isMskMOorSpbLO }) => (
    <div
        id={id}
        className={cn()}
    >
        <div className={cn('mortgageTop')}>
            <CustomTypography
                level='h2'
                tag='h2'
            >
                Купить в ипотеку
            </CustomTypography>
            {
                hasMilitaryMortgage ? (
                    <CustomTypography className={cn('mortgageMilitary')}>
                        Есть военная ипотека
                    </CustomTypography>
                ) : ''
            }
        </div>
        <div className={cn('mortgageInfo')}>
            {
                [
                    [ 'Ставка', `от ${hasApartments ? VTB_MORTGAGE_RATE_APARTS : VTB_MORTGAGE_RATE_FLATS} %` ],
                    [ 'Первоначальный взнос', 'от 20 %' ],
                    [ 'Срок кредита', 'до 30 лет' ],
                    // eslint-disable-next-line
                    [ 'Сумма кредита', `до ${isMskMOorSpbLO ? (hasApartments ? '60' : '12') : (hasApartments ? '30' : '6')} млн ₽` ]
                ].map(([ name, value ], i) => (
                    <div
                        key={i}
                        className={cn('mortgageInfoItem')}
                    >
                        <CustomTypography>
                            {name}
                        </CustomTypography>
                        {
                            Array.isArray(value) ? (
                                <div className={cn('mortgageInfoItemValue')}>
                                    {
                                        value.map((val, j) => (
                                            <CustomTypography
                                                key={j}
                                                level='h3'
                                                color={j === 0 ? 'red-500' : 'primary'}
                                                lineThrough={j !== 0}
                                            >
                                                {val}
                                            </CustomTypography>
                                        ))
                                    }
                                </div>
                            ) : (
                                <CustomTypography
                                    className={cn('mortgageInfoItemValue')}
                                    level='h3'
                                >
                                    {value}
                                </CustomTypography>
                            )
                        }
                    </div>
                ))
            }
        </div>
        <div className={cn('mortgageCalculate')}>
            <LinkButton
                fullWidth
                target='blank'
                href={`${VTB_IPOTEKA_LINK}?from=card3`}
            >
                Оформить заявку
            </LinkButton>
        </div>
    </div>
);

export default NewBuildingCardMortgage;
