import React from 'react';

export class ErrorCatcherSoftError extends Error {
    name = 'ErrorCatcherSoftError';
}

export interface ErrorCatcherViewProps {
    error: Error | ErrorCatcherSoftError;
    displayName: string;
}

export interface ErrorCatcher {
    errorView?: React.FC<ErrorCatcherViewProps>;
    logError(options: ErrorCatcherViewProps): void;
}

const errorCatcherDefault: ErrorCatcher = {
    errorView: undefined,
    logError({ error }: ErrorCatcherViewProps) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

const ErrorCatcherContext = React.createContext(errorCatcherDefault);

export function useErrorCatcher() {
    const context = React.useContext(ErrorCatcherContext);

    return context;
}

export function ErrorCatcherProvider({
    options = errorCatcherDefault,
    children
}: {
    options?: ErrorCatcher;
    children: React.ReactNode;
}) {
    const prev = useErrorCatcher();

    return (
        <ErrorCatcherContext.Provider value={{ ...prev, ...options }}>
            {children}
        </ErrorCatcherContext.Provider>
    );
}

export function errorCatcherWrap<Props>(baseComponent: React.FC<Props>): React.FC<Props> {
    const value = baseComponent.displayName || baseComponent.name;

    function safeComponent(props: Props) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const catcher = useErrorCatcher();

        try {
            return baseComponent(props);
        } catch (error) {
            if (error instanceof Promise) {
                // React.Suspense compatible
                throw error;
            }
            if (! (error instanceof Error)) {
                const orig = error;

                // eslint-disable-next-line no-ex-assign
                error = new Error(error);
                error.orig = orig;
            }

            catcher.logError({ error, displayName: value });

            if (catcher.errorView) {
                return catcher.errorView({ error, displayName: error.name });
            }

            return null;
        }
    }

    Object.defineProperty(safeComponent, 'name', { value });
    safeComponent.displayName = `${value}#errorCatcher`;
    if (baseComponent.hasOwnProperty('propTypes')) {
        safeComponent.propTypes = baseComponent.propTypes;
    }
    if (baseComponent.hasOwnProperty('defaultProps')) {
        safeComponent.defaultProps = baseComponent.defaultProps;
    }

    return safeComponent;
}
