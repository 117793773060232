import React, { useCallback, useContext, useMemo, useRef } from 'react';
import {
    SuggestTypeEnum,
    RootRegionsApplicationEnum,
    NewBuildingWhitelabelEnum,
    DeveloperPromoPlaceholders
} from '@search/graphql-typings';

import { Button, Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';

import { regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';

import classname from '@search/classname/src';

import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import {
    Filters,
    IFilter,
    FinishingTypeFilter,
    RegionFilter,
    NotOnlyM2ProFilter,
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { MobileNotOnlyM2ProFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/notOnlyM2Pro/MobileNotOnlyM2ProFilterView';
import { MobileRegionFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/region/MobileRegionFilterView';
import { MobileRoomFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/room/MobileRoomFilterView';
import { MobilePriceFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/price/MobilePriceFilterView';
import { MobileGeoFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/geo/MobileGeoFilterView';
import { MobileTransportLimitFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/transportLimit/MobileTransportLimitFilterView';
import { MobileFloorsTotalFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/floors_total/MobileFloorsTotalFilter';
import { MobileTotalAreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/totalArea/MobileTotalAreaFilterView';
import { MobileLivingAreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/livingArea/MobileLivingAreaFilterView';
import { MobileKitchenAreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/kitchenArea/MobileKitchenAreaFilterView';
import { MobileFloorFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/floor/MobileFloorFilterView';
import { MobileCeilHeightFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/ceilHeight/MobileCeilHeightFilterView';
import { MobileBathroomFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/bathroom/MobileBathroomFilterView';
import { MobileBalconyFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/balcony/MobileBalconyFilterView';
import { MobileApartmentFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/apartments/MobileApartmentsFilterView';
import { MobileParkingFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/parking/MobileParkingFilterView';
import { MobileWallsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/walls/MobileWallsFilterView';
import { IProps as IDeveloperFilterView, MobileDeveloperFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/developer/MobileDeveloperFilterView';
import { MobileCommissioningFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/commissioning/MobileCommissioningFilterView';
import { MobileFinishingTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/finishing_type/MobileFinishingTypeFilterView';
import { MobileClosedSalesFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/closedSales/MobileClosedSalesFilterView';
import { MobileBuildingClassFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/building_class/MobileBuildingClassFilterView';
import { MobilePositionFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/position/MobilePositionFilterView';
import { MobileSafetyFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/safety/MobileSafetyFilterView';
import { MobileAccreditationFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/accreditation/MobileAccreditationFilterView';
import { MobileNewBuildingDealFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/new_building_deal/MobileNewBuildingDealFilterView';
import { MobilePaymentTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/payment_type/MobilePaymentTypeFilterView';
import { MobileFlatsSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/special_events/MobileFlatsSpecialEventsFilterView';
import { MobileOtherSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/special_events/MobileOtherSpecialEventsFilterView';
import { MobileMiscSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/mobile/special_events/MobileMiscSpecialEventsFilterView';
import { DesktopGeoFiltersView, IDesktopGeoFilterViewProps as IGeoFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/geo/DesktopGeoFiltersView';
import { StickyPhoneWrapper } from '@search/vtbeco-frontend-core/view/mobile/components/StickyPhoneWrapper';
import { AddQueryDisplayValue, QueryDisplayValue, QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { TipItem } from '@search/vtbeco-frontend-core/view/tips/OffersSearchTips';
import { FilterTagListItem } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/geo/FilterTagList/FilterTagListItem';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import type { M2ProEvent } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/m2ProEvent';

import { DesktopDeveloperFilterView } from '../../../desktop/components/NewNewBuildingSearchFilters/DesktopDeveloperFilterView';
import { useDeveloperFilterChange, useDeveloperFilterValue } from '../../hooks/useDeveloperFilter';
import { useIsMskMOSPbLO } from '../../hooks/useIsMskMOSPbLO';
import useDeveloperPromoPlaceholder from '../../hooks/useDeveloperPromoPlaceholder';

import type { CustomFilterBehavior } from '../NewNewBuildingSearchFilters/useSearchFilters';
import { GlobalContext } from '../GlobalContext';

import './styles.css';

const cn = classname.bind(null, 'FilterPanel');
const GEO_SUGGEST_RESULT_LIMIT = 30;

const FilterPanel = ({
    geoStore,
    filters,
    updateFilter,
    updateFewFilters,
    submitFilters,
    customFilterBehavior,
    tips,
    removeTips,
    queryDisplayValues,
    addQueryDisplayValue,
    count = 0,
    onMap,
    drawAreaTitle,
    removeDrawArea,
    isLoading,
    placeholders
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    updateFewFilters: (filter: IFilter[]) => void;
    submitFilters?: () => void;
    tips: QueryDisplayValue[];
    removeTips: (tips: TipItem[]) => void;
    queryDisplayValues?: QueryDisplayValues;
    addQueryDisplayValue?: AddQueryDisplayValue;
    customFilterBehavior: CustomFilterBehavior;
    count?: number;
    onMap?: boolean;
    drawAreaTitle?: string;
    removeDrawArea?: () => void;
    isLoading?: boolean;
    placeholders?: DeveloperPromoPlaceholders;
}) => {
    const regionId = (filters.get<RegionFilter>(Filters.region)).region;
    const hasMetro = regionsWithMetroEnumeration.has(regionId) && ! geoStore.stations.isEmpty();
    const hasSpecialEventFilters = useIsMskMOSPbLO(regionId);

    const {
        rootRegions,
        instanceConfig: { accreditedKey, searchIndex }
    } = useContext(GlobalContext);
    const rootRegionsApp = searchIndex === NewBuildingWhitelabelEnum.Vtb ?
        RootRegionsApplicationEnum.Vtb : RootRegionsApplicationEnum.NbSerp;

    const regionFilterValues = useMemo(() => rootRegions.getRegionsLikeOptions(), [ rootRegions ]);

    const developerValue = useDeveloperFilterValue({
        filters,
        queryDisplayValues
    });

    const handleDeveloperFilterChange = useDeveloperFilterChange({
        customFilterBehavior,
        addQueryDisplayValue
    });

    const finishingTypeFilter = filters.get<FinishingTypeFilter>(Filters.finishingType);

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const handleM2ProChange = useCallback((filter: NotOnlyM2ProFilter) => {
        updateFilter(filter);

        dataLayerPush({
            event: 'M2Pro_rieltors',
            name: 'all_zk_choice',
            page_type: onMap ? 'map' : 'serp'
        } as M2ProEvent);
    }, [ updateFilter, onMap ]);

    const newBuildingPlaceholderGtmRef = useRef<HTMLDivElement>(null);
    const developerPlaceholderGtmRef = useRef<HTMLDivElement>(null);

    const { newBuilding: placeholderWithNewBuildings, developer: placeholder } = useDeveloperPromoPlaceholder({
        placeholders,
        newBuildingPlaceholderGtmRef,
        developerPlaceholderGtmRef
    });

    return (
        <div className={cn(null, { onMap })}>
            {
                drawAreaTitle && removeDrawArea ? (
                    <div className={cn('drawAreaContainer')}>
                        <FilterTagListItem
                            id='draw-area'
                            onDelete={removeDrawArea}
                            isActive
                        >
                            {drawAreaTitle}
                        </FilterTagListItem>
                    </div>
                ) : null
            }
            <div className={cn('body', { onMap })}>
                {hasM2Pro && (
                    <div className={cn('filterContainer', { size: 'm' })}>
                        <MobileNotOnlyM2ProFilterView
                            filter={filters.get<NotOnlyM2ProFilter>(Filters.notOnlyM2Pro)}
                            handleChange={handleM2ProChange}
                        />
                    </div>
                )}
                <div className={cn('filterContainer', { size: 's' })}>
                    <MobileRegionFilterView
                        options={regionFilterValues}
                        filter={filters.get(Filters.region)}
                        onChange={updateFilter}
                        onMap={onMap}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileCommissioningFilterView
                        filter={filters.get(Filters.commissioning)}
                        onChange={updateFilter}
                        onMap={onMap}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileRoomFilterView
                        filter={filters.get(Filters.rooms)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobilePriceFilterView
                        filter={filters.get(Filters.price)}
                        handleChange={updateFilter}
                    />
                </div>
                <div
                    className={cn('filterContainer', { size: 'm', onMap })}
                    ref={newBuildingPlaceholderGtmRef}
                >
                    <GeoFiltersView
                        onMap={onMap}
                        showNewBuildingsGroup
                        startWithNewBuildings
                        filters={filters}
                        geoStore={geoStore}
                        onChange={updateFewFilters}
                        rootRegionsApp={rootRegionsApp}
                        suggestTypes={[
                            SuggestTypeEnum.Geo,
                            SuggestTypeEnum.NewBuilding,
                            SuggestTypeEnum.Developer
                        ]}
                        limit={GEO_SUGGEST_RESULT_LIMIT}
                        tips={tips}
                        count={count}
                        removeTips={removeTips}
                        queryDisplayValues={queryDisplayValues}
                        addQueryDisplayValue={addQueryDisplayValue}
                        placeholderWithNewBuildings={placeholderWithNewBuildings}
                    />
                </div>
                {
                    hasMetro && (
                        <div className={cn('filterContainer', { size: 'l', timeToMetro: true })}>
                            <MobileTransportLimitFilterView
                                containerClass={cn('transportLimitFilter')}
                                filter={filters.get(Filters.transportLimit)}
                                onChange={updateFilter}
                                isMobile={! onMap}
                                isTitle
                            />
                        </div>
                    )
                }

                <Typography
                    tag='h3'
                    variant='h3'
                    bold
                    className={cn('sectionHeading')}
                >
                    Квартира
                </Typography>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileTotalAreaFilterView
                        filter={filters.get(Filters.totalArea)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileLivingAreaFilterView
                        filter={filters.get(Filters.livingArea)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileKitchenAreaFilterView
                        filter={filters.get(Filters.kitchenArea)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileFinishingTypeFilterView
                        filter={finishingTypeFilter}
                        onChange={updateFilter}
                    />
                </div>
                <div className={cn('separator')} />
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileFloorFilterView
                        filter={filters.get(Filters.floor)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileFloorsTotalFilterView
                        filter={filters.get(Filters.floorsTotal)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('separator')} />
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileCeilHeightFilterView
                        filter={filters.get(Filters.cellHeight)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileBathroomFilterView
                        filter={filters.get(Filters.bathroom)}
                        handleChange={updateFilter}
                        hasSeveral={false}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'l' })}>
                    <MobileBalconyFilterView
                        filter={filters.get(Filters.balcony)}
                        handleChange={updateFilter}
                    />
                </div>

                <Typography
                    tag='h3'
                    variant='h3'
                    bold
                    className={cn('sectionHeading')}
                >
                    ЖК
                </Typography>
                <div
                    className={cn('filterContainer', { size: 'm', onMap })}
                    ref={developerPlaceholderGtmRef}
                >
                    <DeveloperFilterView
                        onMap={onMap}
                        value={developerValue}
                        onChange={handleDeveloperFilterChange}
                        placeholder={placeholder}
                    />
                </div>
                {
                    searchIndex === NewBuildingWhitelabelEnum.Default && (
                        <div className={cn('filterContainer', { size: 'm' })}>
                            <MobileClosedSalesFilterView
                                filter={filters.get(Filters.closedSales)}
                                handleChange={updateFilter}
                            />
                        </div>
                    )
                }
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileBuildingClassFilterView
                        filter={filters.get(Filters.buildingClass)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileParkingFilterView
                        filter={filters.get(Filters.parkings)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileWallsFilterView
                        withWood={false}
                        filter={filters.get(Filters.walls)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileApartmentFilterView
                        filter={filters.get(Filters.apartments)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobilePositionFilterView
                        filter={filters.get(Filters.position)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'l' })}>
                    <MobileSafetyFilterView
                        filter={filters.get(Filters.safety)}
                        handleChange={updateFilter}
                    />
                </div>
                {
                    hasSpecialEventFilters ? (
                        <>
                            <Typography
                                tag='h3'
                                variant='h3'
                                bold
                                className={cn('sectionHeading')}
                            >
                                Акции и скидки
                            </Typography>
                            <div className={cn('filterContainer', { size: 'm' })}>
                                <MobileFlatsSpecialEventsFilterView
                                    filter={filters.get(Filters.flatsSpecialEvents)}
                                    /* eslint-disable-next-line react/jsx-handler-names */
                                    onChange={customFilterBehavior.flatsSpecialEvents.update}
                                />
                            </div>
                            <div className={cn('filterContainer', { size: 'm' })}>
                                <MobileOtherSpecialEventsFilterView
                                    filter={filters.get(Filters.otherSpecialEvents)}
                                    /* eslint-disable-next-line react/jsx-handler-names */
                                    onChange={customFilterBehavior.otherSpecialEvents.update}
                                />
                            </div>
                            <div className={cn('filterContainer', { size: 'l' })}>
                                <MobileMiscSpecialEventsFilterView
                                    filter={filters.get(Filters.miscSpecialEvents)}
                                    /* eslint-disable-next-line react/jsx-handler-names */
                                    onChange={customFilterBehavior.miscSpecialEvents.update}
                                />
                            </div>
                        </>
                    ) : null
                }
                <Typography
                    tag='h3'
                    variant='h3'
                    bold
                    className={cn('sectionHeading')}
                >
                    Дополнительно
                </Typography>
                {
                    accreditedKey ? null : (
                        <div className={cn('filterContainer', { size: 'm' })}>
                            <MobileAccreditationFilterView
                                filter={filters.get(Filters.accreditation)}
                                handleChange={updateFilter}
                            />
                        </div>
                    )
                }
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobileNewBuildingDealFilterView
                        filter={filters.get(Filters.newBuildingDeal)}
                        handleChange={updateFilter}
                    />
                </div>
                <div className={cn('filterContainer', { size: 'm' })}>
                    <MobilePaymentTypeFilterView
                        filter={filters.get(Filters.paymentType)}
                        handleChange={updateFilter}
                    />
                </div>
            </div>
            {onMap && (
                <StickyPhoneWrapper className={cn('showButtonWrapper')}>
                    <Button
                        fullWidth
                        loading={isLoading}
                        onClick={submitFilters}
                        shadow
                    >
                        {`Показать${(count > 0) ? ` ${count}` : ''}`}
                    </Button>
                </StickyPhoneWrapper>
            )}
        </div>
    );
};

export default FilterPanel;

const DeveloperFilterView = ({
    onMap,
    ...filterProps
}: IDeveloperFilterView & { onMap?: boolean }) => (
    onMap ? (
        <DesktopDeveloperFilterView {...filterProps} />
    ) : (
        <MobileDeveloperFilterView
            {...filterProps}
            uikitModalZIndexEnabled
        />
    )
);

const GeoFiltersView = ({
    onMap,
    queryDisplayValues,
    filters,
    ...otherProps
}: IGeoFilterView & {
    queryDisplayValues?: QueryDisplayValues;
    onMap?: boolean;
}) => (
    <>
        {
            onMap ? (
                <DesktopGeoFiltersView
                    isVertical
                    inputSize={Size.Small}
                    filterTitle='ЖК, город, метро, район, адрес'
                    filters={filters}
                    {...otherProps}
                />
            ) : (
                <MobileGeoFilterView
                    filters={filters}
                    queryDisplayValues={queryDisplayValues}
                    {...otherProps}
                    oldFilters
                    uikitModalZIndexEnabled
                />
            )
        }
    </>
);
