/* global navigator */
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { BaseError } from '@search/error/src/BaseError';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGqlContext } from './gqlContext';
import { ExecutionError, GqlClientOptions } from './GqlClient';

export enum STATUS {
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    RESOLVE = 'RESOLVE',
    INIT = 'INIT'
}

export interface UseGqlState<Response> {
    loading: boolean;
    status: STATUS;
    data?: Response;
    cacheKey?: string;
    errors?: readonly ExecutionError[];
}

interface OnUpdateProps<Response> {
    nextData: Response;
    prevData: Response;
}

export interface FetchMoreProps<Variables extends object, Response> {
    variables: Partial<Variables>;
    onUpdate: (props: OnUpdateProps<Response>) => Partial<Response>;
}

interface Opts {
    suspense?: true;
    tracingTag?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function DEFAULT_ON_UPDATE<Response>({
    nextData
}: OnUpdateProps<Response>) {
    return nextData;
}

export function useGql<Variables extends object, Response>(
    query: string,
    initVariables: Variables,
    opts?: Opts
): UseGqlState<Response>
    & { fetchMore: (props: FetchMoreProps<Variables, Response>) => void}
    & { clearCache: () => void} {
    const gql = useGqlContext();
    const dataRef = useRef<Promise<void | Response> | null>(null);
    const variablesRef = useRef<Variables>(initVariables);

    const cacheKeyRef = useRef<string>(gql.buildCacheKey(query, initVariables));

    const newVariables = useMemo(() => {
        if (isEqual(variablesRef.current, initVariables)) {
            return variablesRef.current;
        }

        variablesRef.current = initVariables;

        return variablesRef.current;
    }, [ initVariables ]);

    const [ state, setState ] = useState<UseGqlState<Response>>(() => {
        const cache = gql.getCache<Response>(cacheKeyRef.current);

        if (cache) {
            return {
                errors: cache.errors ?? undefined,
                data: cache.data ?? undefined,
                loading: false,
                status: STATUS.RESOLVE,
                cacheKey: cacheKeyRef.current
            };
        }

        return {
            loading: true,
            status: STATUS.PENDING
        };
    });

    const load = useCallback(({
        variables = newVariables,
        onUpdate = DEFAULT_ON_UPDATE
    }: { variables: Variables; onUpdate?: ({ nextData }: OnUpdateProps<Response>) => Response }) => {
        if (state.status !== STATUS.PENDING) {
            setState(prevState => ({
                ...prevState,
                loading: true,
                cacheKey: cacheKeyRef.current,
                status: STATUS.PENDING
            }));
        }

        return gql.execute<Variables, Response>(query, variables, { tracingTag: opts?.tracingTag })
            .then(data => {
                dataRef.current = null;

                setState(prevState => ({
                    ...prevState,
                    data: onUpdate({ prevData: prevState.data!, nextData: data.data! }) ?? undefined,
                    loading: false,
                    cacheKey: cacheKeyRef.current,
                    status: STATUS.RESOLVE
                }));
            })
            .catch(error => {
                dataRef.current = null;

                setState(prevState => ({
                    ...prevState,
                    error,
                    loading: false,
                    status: STATUS.ERROR
                }));
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ newVariables ]);

    const fetchMore = useCallback(({ variables, onUpdate }: FetchMoreProps<Variables, Response>) => {
        const params = merge({}, newVariables, variables);

        // variablesRef.current = params;

        dataRef.current = Promise.resolve();

        dataRef.current = load({
            // @ts-ignore
            onUpdate,
            variables: params
        });
    }, [ load, newVariables ]);

    const clearCache = useCallback(() => {
        gql.deleteCache(cacheKeyRef.current);
    }, [ gql ]);

    useEffect(() => {
        cacheKeyRef.current = gql.buildCacheKey(query, newVariables);

        const cache = gql.getCache<Response>(cacheKeyRef.current);

        if (! cache && ! dataRef.current) {
            dataRef.current = load({ variables: newVariables });
        } else if (cache) {
            dataRef.current = null;

            setState(prevState => ({
                ...prevState,
                data: cache.data ? { ...cache.data } : undefined,
                cacheKey: cacheKeyRef.current,
                status: STATUS.RESOLVE,
                loading: false
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ newVariables ]);

    if ([ STATUS.RESOLVE, STATUS.ERROR ].includes(state.status)) {
        return { clearCache, fetchMore, ...state };
    }

    if (! dataRef.current) {
        dataRef.current = load({ variables: newVariables });
    }

    if (
        opts && opts?.suspense ||
        typeof window === 'undefined' || navigator.userAgent === 'node.js'
    ) {
        throw dataRef.current;
    }

    return { clearCache, fetchMore, ...state };
}

interface UseManualGqlReturn<Variables, Response> extends UseGqlState<Response> {
    load: (variables: Variables) => Promise<Response>;
}

export function useManualGqlFetch<Variables extends object, Response>(query: string, options?: GqlClientOptions) {
    const gql = useGqlContext();

    return useCallback(async (variables: Variables) => {
        const response = await gql.execute<Variables, Response>(query, variables, options);
        const error = response.errors?.[0];

        if (error) throw new BaseError(error.message, { cause: error });

        return response.data!;
    }, [ gql, query ]);
}

export function useManualGql<Variables extends object, Response>(query: string):
    UseManualGqlReturn<Variables, Response> {
    const gql = useGqlContext();
    const [ state, setState ] = useState<UseGqlState<Response>>(() => {
        return {
            loading: false,
            status: STATUS.PENDING
        };
    });

    const load = useCallback((variables: Variables) => {
        if (state.status !== STATUS.PENDING) {
            setState(prevState => ({
                ...prevState,
                loading: true,
                status: STATUS.PENDING
            }));
        }

        return gql.execute<Variables, Response>(query, variables)
            .then(data => {
                setState(prevState => ({
                    ...prevState,
                    data: data.data ?? undefined,
                    loading: false,
                    status: STATUS.RESOLVE
                }));

                return data?.data;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                setState(prevState => ({
                    ...prevState,
                    error,
                    loading: false,
                    status: STATUS.ERROR
                }));

                return error;
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ state ]);

    return {
        load,
        ...state
    };
}

export function graphql(query: TemplateStringsArray): string {
    return Array.isArray(query) ? query[0] : query;
}
