import type { User, RoleType } from '@vtblife/event-bus-events';

export type AuthInfoNoAuthorized = {
    isAuthenticated: false;
    user?: undefined;
}

export type AuthInfoAuthorized = {
    isRealtorEmployeeEmpowered?: boolean;
    isAuthenticated: true;
    user: AuthInfoUser;
}

// Пропсы о юзере с root приложения
export type AuthInfo = AuthInfoNoAuthorized | AuthInfoAuthorized;

export type AuthInfoUser = Omit<User, 'companyId'> & { companyId?: User['companyId'] };

export const authInfoDefault: AuthInfo = {
    isAuthenticated: false,
    user: undefined
};

export interface AuthInfoLegacy {
    isAuth: boolean;
    roles: string[];
    permissions: string[];
    currentRole: string;
    currentRoleType: RoleType;
    userId: string;
    username: string;
    companyId: string | null;
}

export function authInfoFromLegacy(auth?: AuthInfoLegacy): AuthInfo {
    return auth?.isAuth ? {
        isAuthenticated: true,
        user: {
            isAuthenticated: true,
            roles: auth.roles,
            userId: auth.userId,
            companyId: auth.companyId,
            currentRole: auth.currentRole,
            currentRoleType: auth.currentRoleType,
            username: auth.username,
            permissions: auth.permissions
        }
    } : authInfoDefault;
}
