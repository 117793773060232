import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';

import type { Route } from '@search/router/src/Route';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { usePrevious } from '@search/hooks/src/usePrevious';

import type { Shared } from '../../../../types/shared';
import { useGeoStore } from '../../hooks/useGeoStore';

import { useNewBuildingSearch } from '../NewBuildingSearch';

interface UseSearchPageProps {
    params: INewBuildingSearchType;
    promo: boolean;
    promoQuiz: boolean;
    whiteLabel: NewBuildingWhitelabelEnum;
    skipFavorites: boolean;
    touch?: boolean;
    route?: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    hasEventsOnNewData?: boolean;
}

export const useSearchPage = ({
    params,
    promo = false,
    promoQuiz = false,
    touch = false,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    route,
    skipFavorites,
    hasEventsOnNewData
}: UseSearchPageProps) => {
    const hasInitialTipsRef = useRef<boolean>(false);
    const prevParams = usePrevious<INewBuildingSearchType>(params);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ skipDisplayValues, setSkipDisplayValues ] = useState(false);

    const {
        data,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        loading,
        clearCache,
        handleLoadNext,
        handleSortChange,
        handlePageChange,
        pageSize
    } = useNewBuildingSearch({
        touch,
        promo,
        promoQuiz,
        pageParams: params,
        route,
        whiteLabel,
        skipDisplayValues,
        skipFavorites,
        hasEventsOnNewData
    });

    const tips = data?.searchNewBuildings?.tips;

    useEffect(() => {
        if (! hasInitialTipsRef.current && tips) {
            hasInitialTipsRef.current = true;
        }
    }, [ tips ]);

    useEffect(() => {
        if (skipDisplayValues) {
            return;
        }

        const hasParamsChanges = Boolean(prevParams && ! isEqual(params, prevParams));

        if (hasParamsChanges) {
            setSkipDisplayValues(true);
        }
    }, [ params, skipDisplayValues, prevParams ]);

    const geoStore = useGeoStore({
        regionId: params.region,
        districtId: params.districtId ?? undefined
    });

    return {
        data,
        loading,
        clearCache,
        geoStore,
        pageParams: params,
        handleLoadNext,
        handleSortChange,
        handlePageChange,
        pageSize
    };
};
