import React, { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import lowerFirst from 'lodash/lowerFirst';

import { Modal, Tooltip, useTooltip, Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';
import { Paginator, Size, Spinner } from '@vtblife/uikit/legacy';
import { InspectionStatusEnum } from '@search/graphql-gateway/typings';
import type { NewBuildingFlat, NewBuildingOffer, NewBuildingOffersInput, Paging } from '@search/graphql-typings';
import { NewBuildingCard, NewBuildingOffersSortEnum } from '@search/graphql-typings';

import classname from '@search/classname/src';

import { DATA_GTM, OFFERS_SORT_OPTIONS } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import type { RouterContext } from '@search/offer-search-routes/src/RouterProvider';
import { useRouter } from '@search/offer-search-routes/src/RouterProvider';
import { Router } from '@search/router/src/Router';
import { SlimSelect } from '@search/vtbeco-frontend-core/view/common/components/SlimSelect/SlimSelect';
import { QRCodePhoneButton } from '@search/vtbeco-frontend-core/view/common/components/QRCodePhoneButton';
import type { CallbackDataType } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import { CSSTransition } from 'react-transition-group';

import RewardAmountIcon, { BuildingsRewardAmountIcon } from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/RewardAmountIcon';
import { GlobalContext } from '../../../../common/components/GlobalContext';
import { SvgSpriteIcon } from '../../../../common/components/SvgSpriteIcon';
import { renderFlatStatus } from '../../../../common/components/NewBuildingFlats';
import type { Building } from '../../../../common/components/NewBuildingCard';
// @ts-ignore
import type { useOffersQuery$variables as useOffersQueryVariables } from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/__generated__/useOffersQuery.graphql';
import {
    NewBuildingCardOfferSearchBuildingFlats,
    NewBuildingCardOfferSearchBuildingInfo,
    NewBuildingCardOfferSearchOfferModalSnippet
} from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch';
import { useFavoriteOffers } from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/useFavoriteOffers';
import { useOffers } from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/useOffers';
import {
    FavoriteOffersProvider,
    useFavoriteOffersContext
} from '../../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/FavoriteOffersProvider';
import InspectionStatusLogo from '../../../../common/components/InspectionStatusLogo';
import { usePhoneButtonContext } from '../../../../common/components/NewBuildingCard/PhoneButtonStateProvider';
import { FavoritesButtonOffer } from '../../../../common/components/FavoritesButton';
import { useFavoriteOfferButton } from '../../../../common/components/FavoritesButton/useFavoriteOfferButton';
import type { Shared } from '../../../../../types/shared';
import ChatButton from '../../../../common/components/ChatButton';
import { ChatStateProvider } from '../../../../common/components/NewBuildingCard/ChatStateProvider';
import { SerpTabs } from '../../../../common/components/SerpTabs/SerpTabs';
import { M2ProOrderButton } from '../../../../common/components/M2ProOrderButton';
import { M2ProAttachedFilesButton } from '../../../../common/components/M2ProAttachedFilesButton';
import { useM2ProFiles } from '../../../../common/hooks/useM2ProFiles';
import QuickSelectionCardOfferSearchBanner
    from '../../../../common/components/QuickSelection/QuickSelectionCardOfferSearchBanner';

import './styles.css';
import { useHover } from '../../../../common/hooks/useHover';

const cn = classname.bind(null, 'NewBuildingCardOfferSearch');

const Stub: FC<{ level: 'primary' | 'secondary' }> = ({ level }) => (
    <div className={cn('stub', { [level]: true })} />
);

const PrimaryStub: FC = () => (
    <Stub level='primary' />
);

const SecondaryStub: FC = () => (
    <Stub level='secondary' />
);

const Top: FC<{
    sort: NewBuildingOffersSortEnum;
    onSortChange: (sort: string) => void;
    total: string;
}> = ({
    sort,
    onSortChange,
    total
}) => (
    <div className={cn('top')}>
        <div className={cn('sort')}>
            <SlimSelect
                size='m'
                options={OFFERS_SORT_OPTIONS}
                value={sort}
                onChange={onSortChange}
            />
            <Typography variant='primary-alone'>
                {total}
            </Typography>
        </div>
    </div>
);

const OfferSerpStub: FC = () => (
    <div className={cn('snippet')}>
        <div className={cn('snippetLayout', { stub: true })} />
        <div className={cn('snippetInfo')}>
            <div className={cn('snippetRooms')}>
                <PrimaryStub />
                <SecondaryStub />
            </div>
            <div className={cn('snippetFloor')}>
                <PrimaryStub />
                <SecondaryStub />
            </div>
            <div className={cn('snippetBathroom')}>
                <PrimaryStub />
                <SecondaryStub />
            </div>
            <div className={cn('snippetBuilding')}>
                <PrimaryStub />
                <SecondaryStub />
            </div>
            <div className={cn('snippetPrice')}>
                <PrimaryStub />
                <SecondaryStub />
            </div>
        </div>
    </div>
);

const BUILDINGS_PER_PAGE = 10;

const BuildingSerp: FC<{
    buildings: Building[];
    pageNumber: number;
    onPageChange: (pageNumber: number) => void;
    newBuildingName: string;
    newBuildingId: string;
    developerTitle?: string;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => ReactElement | null;
    hasNoOffers: boolean;
}> = ({
    buildings,
    pageNumber,
    onPageChange,
    newBuildingName,
    newBuildingId,
    developerTitle,
    renderCallBack,
    hasNoOffers
}) => {
    const [ selectedBuilding, selectBuilding ] = useState<Building | null>(null);
    const [ selectedFlat, selectFlat ] = useState<NewBuildingFlat | null>(null);
    const closeModal = useCallback(() => {
        selectBuilding(null);
        selectFlat(null);
    }, []);
    const { buttonsData, firstDeveloperId, handleClick } = usePhoneButtonContext();

    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    return (
        <>
            {
                buildings
                    .slice((pageNumber - 1) * BUILDINGS_PER_PAGE, pageNumber * BUILDINGS_PER_PAGE)
                    .map((building, i) => {
                        const rewardAmount = null; //building.m2Pro?.rewardAmount;

                        return (
                            <div
                                key={i}
                                className={cn('buildingStats')}
                                onClick={() => selectBuilding(building)}
                            >
                                <div className={cn('serpTitle')}>
                                    {
                                        building.isSoldOut || building.isSoon ? (
                                            <div className={cn('buildingInfoTitle')}>
                                                <Typography variant='h4'>
                                                    {building.infoTitle}
                                                </Typography>
                                                <Typography
                                                    variant='h4'
                                                    tag='div'
                                                >
                                                    {
                                                        building.isSoldOut ?
                                                            'Всё продано' :
                                                            'Скоро в продаже'
                                                    }
                                                </Typography>
                                            </div>
                                        ) : (
                                            <Typography variant='h4'>
                                                {building.infoTitle}
                                            </Typography>
                                        )
                                    }
                                    {
                                        hasRoleM2Pro && rewardAmount && (
                                            <RewardAmountIcon amount={rewardAmount} />
                                        )
                                    }
                                </div>
                                {
                                    building.inspectionStatus ? (
                                        <div className={cn('inspectionStatusBlock')}>
                                            <Typography
                                                bold
                                                tag='span'
                                                className={cn('inspectionStatus', {
                                                    type: building.inspectionStatus.value
                                                })}
                                            >
                                                {building.inspectionStatus.detailText}&nbsp;
                                            </Typography>
                                            <span
                                                className={cn('inspectionStatusLink')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    window.open(
                                                        building.inspectionStatus?.desktopLink ?? undefined,
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                <InspectionStatusLogo />
                                                {building.inspectionStatus.linkText}
                                            </span>
                                        </div>
                                    ) : null
                                }
                                {
                                    building.info?.legal ? (
                                        <Typography
                                            className={cn('developer')}
                                            variant='secondary-alone'
                                            color='minor'
                                        >
                                            Застройщик {building.info.legal}
                                        </Typography>
                                    ) : null
                                }
                                {building.info && (
                                    <NewBuildingCardOfferSearchBuildingInfo
                                        view='desktop'
                                        info={building.info}
                                        isInspectionStatusFailed={
                                            building.inspectionStatus?.value === InspectionStatusEnum.Failed
                                        }
                                    />
                                )}
                                <NewBuildingCardOfferSearchBuildingFlats
                                    view='desktop'
                                    flats={building.flats as NewBuildingFlat[]}
                                    selectFlat={selectFlat}
                                    isSoldOut={building.isSoldOut}
                                    hasNoOffers={hasNoOffers}
                                />
                                {
                                    building.isSoldOut ? null : (
                                        <Typography
                                            className={cn('disclaimer')}
                                            variant='secondary-alone'
                                            color='minor'
                                        >
                                            {`Наличие ${
                                                building.hasApartmentsOnly ? 'апартаментов' : 'квартир'
                                            } и точную стоимость узнавайте в отделе продаж.`}
                                        </Typography>
                                    )
                                }
                            </div>
                        );
                    })
            }
            {
                buildings.length > BUILDINGS_PER_PAGE ? (
                    <div className={cn('paging')}>
                        <Paginator
                            onChange={onPageChange}
                            itemsPerPage={BUILDINGS_PER_PAGE}
                            page={pageNumber}
                            totalItems={buildings.length}
                        />
                    </div>
                ) : null
            }
            {
                selectedBuilding ? (
                    <Modal
                        opened
                        size='l'
                        onClose={closeModal}
                    >
                        <Modal.Title>
                            Корпус {selectedBuilding.name}
                        </Modal.Title>
                        {
                            selectedFlat ? (
                                <div className={cn('buildingFlatsModalFlat')}>
                                    <div className={cn('buildingFlatsModalFlatRooms')}>
                                        <Typography variant='h4'>
                                            {selectedFlat.labelShort}
                                        </Typography>
                                        <Typography
                                            variant='h4'
                                            className={cn('buildingFlatsModalFlatArea')}
                                        >
                                            {selectedFlat.formatAreaRange}
                                        </Typography>
                                    </div>
                                    <Typography
                                        className={cn('buildingFlatsModalFlatStatus')}
                                        variant='h4'
                                        color={
                                            selectedFlat.isSoldOut ||
                                            selectedFlat.isSoon ||
                                            selectedFlat.isTemporaryNotOnSale ||
                                            ! selectedFlat.formatPriceRangeShort ?
                                                'minor' :
                                                'primary'
                                        }
                                    >
                                        {renderFlatStatus(selectedFlat, true)}
                                    </Typography>
                                </div>
                            ) : null
                        }
                        <Typography
                            className={cn('buildingFlatsModalNewBuilding')}
                            variant='h4'
                        >
                            {newBuildingName}
                        </Typography>
                        {
                            selectedBuilding.isSoldOut ? null : (
                                <Typography
                                    className={cn('buildingFlatsModalDisclaimer')}
                                    variant='primary-alone'
                                >
                                    {`Наличие ${
                                        selectedBuilding.hasApartmentsOnly ? 'апартаментов' : 'квартир'
                                    } и точную стоимость узнавайте в отделе продаж.`}
                                </Typography>
                            )
                        }
                        {
                            selectedBuilding.info?.legal ? (
                                <Typography
                                    className={cn('buildingFlatsModalDeveloper')}
                                    color='minor'
                                >
                                    Застройщик {selectedBuilding.info.legal}
                                </Typography>
                            ) : null
                        }
                        <div className={cn('buildingFlatsModalBottom')}>
                            {selectedBuilding?.m2Pro?.rewardAmount ? (
                                <>
                                    <M2ProOrderButton
                                        newBuildingId={newBuildingId}
                                        size='l'
                                        width={344}
                                        eventData={{
                                            page_type: 'card',
                                            modal_type: selectedFlat ? 'building_flat' : 'building'
                                        }}
                                    />
                                    <ChatStateProvider initialClientMessage={`Интересует ${newBuildingName}`}>
                                        <ChatButton
                                            hasText
                                            hasIcon={false}
                                            size='l'
                                            width={344}
                                            variant={developerTitle ? 'secondary' : 'primary'}
                                        />
                                    </ChatStateProvider>
                                </>
                            ) : (
                                <>
                                    {firstDeveloperId && (
                                        <QRCodePhoneButton
                                            href={buttonsData[firstDeveloperId].href}
                                            onClick={() => handleClick(firstDeveloperId)}
                                            qrCodeValue={buttonsData[firstDeveloperId].qrCodeValue}
                                            gtm={DATA_GTM.CARD_PHONE_5}
                                            loading={buttonsData[firstDeveloperId].isLoading}
                                        >
                                            {buttonsData[firstDeveloperId].text}
                                        </QRCodePhoneButton>
                                    )}
                                    {renderCallBack(
                                        DATA_GTM.CARD_CALL_BACK_BUILDING,
                                        DATA_GTM.CARD_CALL_BACK_SEND_BUILDING
                                    )}
                                </>
                            )}
                        </div>
                    </Modal>
                ) : null
            }
        </>
    );
};

const Disclaimer: FC<{
    hasApartmentsOnly: boolean;
}> = ({
    hasApartmentsOnly
}) => (
    <Typography
        className={cn('snippet', { disclaimer: true })}
        color='secondary'
    >
        {`По вашим параметрам нет подходящих ${
            hasApartmentsOnly ? 'апартаментов' : 'квартир'
        }. Попробуйте изменить фильтры`}.
    </Typography>
);

const OfferSnippet: FC<{
    offer: NewBuildingOffer;
    hasBuildings: boolean;
    building: Building | null;
    onClick: () => void;
    defaultDateString?: string;
    renderM2ProRewardAmountIcon: (offer: NewBuildingOffer) => React.ReactNode;
    hasRoleM2Pro: boolean;
}> = ({
    offer,
    hasBuildings,
    building,
    defaultDateString,
    onClick: handleClick,
    renderM2ProRewardAmountIcon,
    hasRoleM2Pro
}) => {
    const { instanceConfig: { hasFavoritesButton } } = useContext(GlobalContext);
    const { favoriteOfferIds, addFavoriteOfferId, deleteFavoriteOfferId } = useFavoriteOffersContext();
    const isFavorite = useMemo(() => favoriteOfferIds[offer.routeParams.offerId] ?? false, [
        favoriteOfferIds,
        offer.routeParams.offerId
    ]);

    const updateFavorite = useFavoriteOfferButton({
        offerId: offer.routeParams.offerId,
        isFavorite,
        addFavoriteOfferId,
        deleteFavoriteOfferId
    });

    const { to, registerControl } = useTooltip();

    const {
        isHovered,
        handleMouseEnter,
        handleMouseLeave
    } = useHover();

    return (
        <div
            key={offer.routeParams.offerId}
            className={cn('snippet')}
        >
            <div
                className={cn('snippetLayout', { stub: ! offer.layout })}
                style={offer.layout ? {
                    backgroundImage: `url(${offer.layout.small})`
                } : {}}
            >
                {
                    offer.layout ? null : (
                        <SvgSpriteIcon
                            name='NoPlan'
                            width={42}
                            height={42}
                            className={cn('snippetNoPlanIcon')}
                        />
                    )
                }
            </div>
            <div className={cn('snippetInfo')}>
                <div className={cn('snippetRooms')}>
                    <div className={cn('snippetRoomsFirstLine')}>
                        {renderM2ProRewardAmountIcon(offer)}
                        <Typography
                            className={cn('link')}
                            tag='a'
                            onClick={handleClick}
                        >
                            {offer.rooms}
                        </Typography>
                    </div>
                    <Typography
                        variant='secondary'
                        color='secondary'
                    >
                        {offer.area}
                    </Typography>
                </div>
                <div className={cn('snippetFloor')}>
                    <Typography>
                        {offer.floor}
                    </Typography>
                    <Typography
                        variant='secondary'
                        color='secondary'
                    >
                        {offer.floorsTotal}
                    </Typography>
                </div>
                <div className={cn('snippetFinishing')}>
                    {offer.finishing ? (
                        <Typography>
                            {offer.finishing}
                        </Typography>
                    ) : (
                        <div
                            ref={registerControl}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className={cn('snippetFinishingIcon', { isHovered })}
                        >
                            <Icon
                                name='question-circle'
                                size='m'
                            />
                        </div>
                    )}
                    <Tooltip
                        to={to}
                        direction='down'
                    >
                        Неизвестно, какая отделка в&nbsp;этой квартире.
                        Уточните информацию у&nbsp;застройщика
                    </Tooltip>
                </div>
                {
                    hasBuildings ? (
                        <div className={cn('snippetBuilding')}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                building ? (
                                    <>
                                        <Typography>
                                            {lowerFirst(building.dateString)}
                                        </Typography>
                                        <Typography
                                            variant='secondary'
                                            color='secondary'
                                        >
                                            {building.address}
                                        </Typography>
                                    </>
                                ) : defaultDateString ? (
                                    <>
                                        <Typography>
                                            {lowerFirst(defaultDateString)}
                                        </Typography>
                                        <CustomTypography
                                            level='secondary'
                                            style={{ opacity: 0 }}
                                        >
                                            —
                                        </CustomTypography>
                                    </>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                <div className={cn('snippetPrice')}>
                    <Typography bold>
                        {offer.price}
                    </Typography>
                    <Typography
                        variant='secondary'
                        color='secondary'
                    >
                        {offer.pricePerAreaUnit}
                    </Typography>
                </div>
                <div>
                    <FavoritesButtonOffer
                        hasFavoritesButton={hasFavoritesButton}
                        hasRoleM2Pro={hasRoleM2Pro}
                        isFavorite={isFavorite}
                        onClick={updateFavorite}
                    />
                </div>
            </div>
        </div>
    );
};

OfferSnippet.displayName = 'NewBuildingCardOfferSearch/OfferSnippet';

const OfferSnippetWrapped = errorCatcherWrap(OfferSnippet);

const OfferModalNav: FC<{
    offerIndex: number;
    totalOffers: number;
    onOfferIndexSelect: (index: number) => void;
}> = ({
    offerIndex,
    totalOffers,
    onOfferIndexSelect: handleOfferIndexSelect
}) => createPortal(
    <div className={cn('offerModalArrowsContainer')}>
        {
            ([ {
                isVisible: offerIndex > 0,
                direction: 'left',
                index: offerIndex - 1
            }, {
                isVisible: offerIndex < totalOffers - 1,
                direction: 'right',
                index: offerIndex + 1
            } ] as {
                    isVisible: boolean;
                    direction: 'left' | 'right';
                    index: number;
                }[]).map(({ isVisible, direction, index }) => (
                isVisible ? (
                    <div
                        key={direction}
                        className={cn('offerModalArrowContainer', { direction })}
                    >
                        <div
                            className={cn('offerModalArrow')}
                            onClick={() => handleOfferIndexSelect(index)}
                        >
                            <div className={cn('offerModalArrowWrapper')}>
                                <SvgSpriteIcon name='Arrow' />
                            </div>
                        </div>
                    </div>
                ) : null
            ))
        }
    </div>, document.body);

const OfferModal: FC<{
    opened: boolean;
    isStub?: boolean;
    offer?: NewBuildingOffer | null;
    offerIndex: number;
    totalOffers: number;
    onOfferIndexSelect: (index: number) => void;
    buildings?: Building[];
    buildingById?: Record<string, Building>;
    defaultDateString?: string;
    router?: Router<RouterContext>;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => ReactElement | null;
    close: () => void;
    developerLink?: string;
    developerTitle?: string;
    newBuildingName: string;
    developerImage?: {
        origin: string;
    };
    backofficeRegionId?: number;
    callbackData: CallbackDataType;
    isMskMOorSPbLo?: boolean;
    renderM2Pro: (offer: NewBuildingOffer) => React.ReactNode;
}> = ({
    opened,
    isStub = false,
    offer,
    offerIndex,
    totalOffers,
    onOfferIndexSelect: handleOfferIndexSelect,
    buildings,
    buildingById,
    defaultDateString,
    router,
    renderCallBack,
    close,
    developerLink,
    developerTitle,
    developerImage,
    backofficeRegionId,
    callbackData,
    isMskMOorSPbLo,
    renderM2Pro,
    newBuildingName
}) => {
    const [ isButtonsVisible, setButtonsVisible ] = useState(false);

    const hideButtons = useCallback(() => setButtonsVisible(false), []);

    useEffect(() => {
        if (opened) {
            setButtonsVisible(true);
        }
    }, [ opened ]);

    const offerModalSnippetRef = useRef(null);

    return (<>
        <CSSTransition
            in={isButtonsVisible}
            apper
            mountOnEnter
            unmountOnExit
            classNames={cn('offerModalArrowsContainer')}
            timeout={400}
        >
            <OfferModalNav
                offerIndex={offerIndex}
                totalOffers={totalOffers}
                onOfferIndexSelect={handleOfferIndexSelect}
            />
        </CSSTransition>

        <Modal
            opened={opened}
            size='xl'
            onBeforeClose={hideButtons}
            onClose={close}
        >
            {
                isStub ? (
                    <div className={cn('offerModalSpinner')}>
                        <Spinner size={Size.Large} />
                    </div>
                ) : (
                    <NewBuildingCardOfferSearchOfferModalSnippet
                        view='desktop'
                        offer={offer!}
                        buildings={buildings!}
                        buildingById={buildingById!}
                        defaultDateString={defaultDateString}
                        router={router!}
                        renderCallBack={renderCallBack}
                        developerLink={developerLink!}
                        developerTitle={developerTitle!}
                        developerImage={developerImage}
                        newBuildingName={newBuildingName}
                        backofficeRegionId={backofficeRegionId}
                        snippetRef={offerModalSnippetRef}
                        callbackData={callbackData}
                        isMskMOorSPbLo={isMskMOorSPbLo}
                        renderM2Pro={renderM2Pro}
                    />
                )
            }
        </Modal>
    </>);
};

export const NewBuildingCardOfferSearchLoading: React.FC<{
    sort?: NewBuildingOffersSortEnum;
    onSortChange: (sort: string | string[]) => void;
    onChange: (skipPseudoLoading?: boolean) => void;
}> = ({ sort = NewBuildingOffersSortEnum.PriceAsc, onChange, onSortChange }) => {
    const handleSortChange = useCallback((value: string | string[]) => {
        onSortChange(value);
        onChange();
    }, [ onSortChange, onChange ]);

    return (<div className={cn()}>
        <Top
            sort={sort}
            onSortChange={handleSortChange}
            total='Ищем предложения...'
        />
        <OfferSerpStub />
    </div>);
};

interface INewBuildingCardOfferSearchProps {
    isLoading: boolean;
    offers: NewBuildingOffer[];
    paging: Paging;
    onPageChange: (pageNumber: number) => void;
    sort?: NewBuildingOffersSortEnum;
    onSortChange: (sort: string | string[]) => void;
    buildings: Building[];
    filteredBuildings: Building[];
    allBuildings: Building[];
    buildingById: Record<string, Building>;
    onChange: (skipPseudoLoading?: boolean) => void;
    defaultDateString?: string;
    newBuildingName: string;
    newBuildingId: string;
    hasNoOffers: boolean;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => ReactElement | null;
    developerLink: string;
    developerTitle: string;
    developerImage?: {
        origin: string;
    };
    backofficeRegionId?: number;
    hasApartmentsOnly: boolean;
    isMskMOorSPbLo?: boolean;
    callbackData: CallbackDataType;
    m2Pro?: NewBuildingCard['m2Pro'];
}

const NewBuildingCardOfferSearch: React.FunctionComponent<INewBuildingCardOfferSearchProps> = ({
    isLoading,
    offers,
    paging,
    onPageChange,
    sort = NewBuildingOffersSortEnum.PriceAsc,
    onSortChange,
    buildings,
    filteredBuildings,
    allBuildings,
    buildingById,
    onChange,
    defaultDateString,
    newBuildingId,
    newBuildingName,
    hasNoOffers,
    renderCallBack,
    developerLink,
    developerTitle,
    developerImage,
    backofficeRegionId,
    hasApartmentsOnly,
    isMskMOorSPbLo,
    callbackData,
    m2Pro
}) => {
    const { instanceConfig: { hasQuickSelection } } = useContext(GlobalContext);

    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const { cooperationTerms, nbPresentation } = m2Pro ?? {};

    const m2ProFiles = useM2ProFiles({ cooperationTerms, nbPresentation });

    const handleSortChange = useCallback((value: string | string[]) => {
        onSortChange(value);
        onChange();
    }, [ onSortChange, onChange ]);

    const [ buildingsPageNumber, setBuildingsPageNumber ] = useState<number>(1);
    const handleBuildingsPageNumberChange = useCallback((pageNumber: number, event?: React.MouseEvent) => {
        event?.preventDefault();

        setBuildingsPageNumber(pageNumber);
        onChange(true);
    }, [ onChange ]);

    useEffect(() => {
        setBuildingsPageNumber(1);
    }, [ buildings.length ]);

    const [ isSerp, setIsSerp ] = useState<boolean>(true);
    const toggleSerp = useCallback((value: boolean) => {
        setIsSerp(value);
        setBuildingsPageNumber(1);
    }, []);

    const pageNumber = paging!.pageNumber;
    const pageSize = paging!.pageSize;
    const offset = (pageNumber - 1) * pageSize;
    const totalOffers = paging!.total!;

    const router = useRouter();

    const handlePageChange = useCallback((pageNum: number, event?: React.MouseEvent) => {
        event?.preventDefault();

        onPageChange(pageNum);
        onChange();
    }, [ onPageChange, onChange ]);

    const [ selectedOfferIndex, selectOfferIndex ] = useState<number | null>(null);
    const resetOfferIndex = useCallback(() => selectOfferIndex(null), []);
    const handleOfferIndexSelect = useCallback((index: number) => {
        if (index < 0 || index >= totalOffers) {
            selectOfferIndex(null);

            return;
        }

        const newPageNumber = Math.floor(index / pageSize) + 1;

        if (newPageNumber !== pageNumber) {
            handlePageChange(newPageNumber);
        }

        selectOfferIndex(index);
    }, [ handlePageChange, pageNumber, pageSize, selectOfferIndex, totalOffers ]);
    const selectedOffer = selectedOfferIndex !== null && offers[selectedOfferIndex - offset] || null;

    const hasBuildings = Boolean(buildings.length) || Boolean(defaultDateString) || offers.some(
        ({ buildingId }) => buildingId && buildingById[buildingId]
    );

    const getBuildings = useCallback(() => {
        let buildingsArr;

        if (! hasNoOffers) {
            buildingsArr = filteredBuildings;
        } else {
            buildingsArr = buildings.length > 0 ? buildings : allBuildings;
        }

        return buildingsArr.map(b => b.m2Pro?.rewardAmount ? b : { ...b, m2Pro });
    },
    [ hasNoOffers, buildings, allBuildings, filteredBuildings, m2Pro ]
    );

    const renderM2ProRewardAmountIcon = useCallback((offer: NewBuildingOffer) => (
        <BuildingsRewardAmountIcon offerBuildingId={offer.buildingId} buildings={getBuildings()} />
    ),
    [ getBuildings ]
    );

    const renderM2Pro = useCallback((offer?: NewBuildingOffer) => {
        if (! offer) {
            return null;
        }

        const offerBuilding = getBuildings().find(building => building.id === offer.buildingId);

        const rewardAmount = null;
        const { prepayment } = offerBuilding?.m2Pro ?? {};

        if (! hasRoleM2Pro || ! rewardAmount) {
            return null;
        }

        return (
            <div className={cn('m2Pro')}>
                <div className={cn('m2ProOffer')}>
                    <RewardAmountIcon amount={rewardAmount} />
                    {prepayment && (
                        <SvgSpriteIcon
                            name='M2ProPrepayment'
                            width={24}
                            height={24}
                            className={cn('prepayment')}
                        />
                    )}
                </div>
                <M2ProOrderButton
                    newBuildingId={newBuildingId}
                    size='m'
                    variant='primary'
                    fullWidth
                    eventData={{
                        page_type: 'card',
                        modal_type: 'flat'
                    }}
                />
                <ChatButton
                    className={cn('openChat')}
                    hasText
                    hasIcon={false}
                    size='m'
                    fullWidth
                    variant='secondary'
                />
                {m2ProFiles.length > 0 && (
                    <div className={cn('m2ProButton')}>
                        <M2ProAttachedFilesButton
                            view='button'
                            variant='transparent'
                            files={m2ProFiles}
                            eventData={{
                                page_type: 'card',
                                modal_type: 'flat'
                            }}
                        />
                    </div>
                )}
            </div>
        );
    },
    [ getBuildings, hasRoleM2Pro, m2ProFiles, newBuildingId ]
    );

    const EmptySerp = () => (
        <>
            <Top
                sort={sort}
                onSortChange={handleSortChange}
                total='Нет предложений'
            />
            <Disclaimer hasApartmentsOnly={hasApartmentsOnly} />
        </>
    );

    if (
        buildings.length > 0 && buildings.every(({ noOffers }) => noOffers) ||
        hasNoOffers
    ) {
        if (! hasNoOffers && ! filteredBuildings.length) {
            return (
                <div className={cn()}>
                    <EmptySerp />
                </div>
            );
        }

        return (
            <div className={cn()}>
                <BuildingSerp
                    buildings={getBuildings()}
                    pageNumber={buildingsPageNumber}
                    onPageChange={handleBuildingsPageNumberChange}
                    newBuildingId={newBuildingId}
                    newBuildingName={newBuildingName}
                    developerTitle={developerTitle}
                    renderCallBack={renderCallBack}
                    hasNoOffers={hasNoOffers}
                />
            </div>
        );
    }

    const renderOfferSerp = () => ! offers.length || paging === null ? (
        <EmptySerp />
    ) : (
        <>
            <Top
                sort={sort}
                onSortChange={handleSortChange}
                total={`${formatLocaleNumber(totalOffers)} ${declensionByNumber(totalOffers, [
                    'предложение', 'предложения', 'предложений'
                ])}`}
            />
            {
                hasQuickSelection && isMskMOorSPbLo && ! hasRoleM2Pro ? (
                    <QuickSelectionCardOfferSearchBanner
                        callbackData={callbackData}
                        className={cn('quickSelectionPromo')}
                    />
                ) : null
            }
            {offers.map((offer, i) => (
                <OfferSnippetWrapped
                    key={offer.routeParams.offerId}
                    offer={offer}
                    hasBuildings={hasBuildings}
                    building={
                        buildings.length === 1 && buildings[0] ||
                        offer.buildingId && buildingById[offer.buildingId] ||
                        null
                    }
                    defaultDateString={defaultDateString}
                    onClick={() => selectOfferIndex(offset + i)}
                    renderM2ProRewardAmountIcon={renderM2ProRewardAmountIcon}
                    hasRoleM2Pro={hasRoleM2Pro}
                />
            ))}
            {
                totalOffers > pageSize ? (
                    <div className={cn('paging')}>
                        <Paginator
                            onChange={handlePageChange}
                            itemsPerPage={pageSize}
                            page={pageNumber}
                            totalItems={totalOffers}
                        />
                    </div>
                ) : null
            }
            <OfferModal
                opened={selectedOfferIndex !== null}
                isStub={isLoading || ! selectedOffer}
                offer={selectedOffer}
                offerIndex={selectedOfferIndex as number}
                totalOffers={totalOffers}
                onOfferIndexSelect={handleOfferIndexSelect}
                buildings={buildings}
                buildingById={buildingById}
                defaultDateString={defaultDateString}
                router={router}
                renderCallBack={renderCallBack}
                close={resetOfferIndex}
                developerLink={developerLink}
                developerTitle={developerTitle}
                developerImage={developerImage}
                backofficeRegionId={backofficeRegionId}
                callbackData={callbackData}
                isMskMOorSPbLo={isMskMOorSPbLo}
                renderM2Pro={renderM2Pro}
                newBuildingName={newBuildingName}
            />
        </>
    );

    return (
        <div className={cn()}>
            {
                filteredBuildings.length === 0 ? renderOfferSerp() : (
                    <>
                        <div className={cn('tabs')}>
                            <SerpTabs
                                isSerp={isSerp}
                                toggleSerp={toggleSerp}
                                hasApartmentsOnly={hasApartmentsOnly}
                            />
                        </div>
                        {
                            isSerp ? renderOfferSerp() : (
                                <BuildingSerp
                                    buildings={filteredBuildings.map(b => b.m2Pro?.rewardAmount ? b : { ...b, m2Pro })}
                                    pageNumber={buildingsPageNumber}
                                    onPageChange={handleBuildingsPageNumberChange}
                                    newBuildingId={newBuildingName}
                                    newBuildingName={newBuildingName}
                                    developerTitle={developerTitle}
                                    renderCallBack={renderCallBack}
                                    hasNoOffers={hasNoOffers}
                                />
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

NewBuildingCardOfferSearch.displayName = 'NewBuildingCardOfferSearch';

type TNewBuildingCardOfferSearchWrapperProps = Omit<
    INewBuildingCardOfferSearchProps, 'offers' | 'paging' | 'isLoading'
> & {
    pageParams: Shared.INewBuildingCardParams;
    offersFilters: NewBuildingOffersInput;
    handleSortChange: (sort: string | string[]) => void;
    isPseudoLoading: boolean;
};

export const NewBuildingCardOfferSearchWrapper: React.FunctionComponent<TNewBuildingCardOfferSearchWrapperProps> = ({
    pageParams,
    offersFilters,
    handleSortChange,
    isPseudoLoading,
    onChange,
    ...props
}) => {
    const { instanceConfig: { hasFavoritesButton } } = useContext(GlobalContext);
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        isLoading: areOffersLoading,
        offers: offersData
    } = useOffers({
        filters: offersFilters,
        paging: {
            pageNumber: pageParams.pageNumber!,
            pageSize: pageParams.pageSize!
        },
        sort: pageParams.sort as useOffersQueryVariables['sort']
    });

    const favoriteOffersData = useFavoriteOffers({ skipFavoriteOffers: ! hasFavoritesButton });

    const offers = offersData?.items;
    const paging = offersData?.paging;

    const isLoading = useMemo(
        () => areOffersLoading || isPseudoLoading,
        [ areOffersLoading, isPseudoLoading ]
    );

    return ((isLoading || ! offers || ! paging) ? (
        <NewBuildingCardOfferSearchLoading
            sort={pageParams.sort as NewBuildingOffersSortEnum | undefined}
            onSortChange={handleSortChange}
            onChange={onChange}
        />
    ) : (
        <FavoriteOffersProvider {...favoriteOffersData}>
            <NewBuildingCardOfferSearch
                isLoading={isLoading}
                offers={offers as NewBuildingOffer[]}
                paging={paging as Paging}
                onChange={onChange}
                {...props}
            />
        </FavoriteOffersProvider>
    ));
};

NewBuildingCardOfferSearchWrapper.displayName = 'NewBuildingCardOfferSearchWrapper';

export default NewBuildingCardOfferSearch;
