export enum Rooms {
    UNKNOWN = 0,
    STUDIO = 1,
    ROOM_1 = 2,
    ROOM_2 = 3,
    ROOM_3 = 4,
    ROOM_4 = 5,
    ROOM_5_AND_MORE = 6,
    OPEN_PLAN = 7
}
