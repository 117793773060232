import React, { FC, useCallback, useState } from 'react';

import { Checkbox } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import type { NewBuildingCard } from '@search/graphql-typings';
import {
    YMapControlGeoLocation,
    YMapControlGroup,
    YMapControlRuler,
    YMapControlTraffic,
    YMapControlCentering,
    YMapControlZoom,
    YMapControlFullscreen
} from '@search/ymap/src/Control';
import type { YMapBoundsZoom } from '@search/ymap/src/bounds';
import type { YMapBounds } from '@search/ymap/src/bounds';
import { YMapPane } from '@search/ymap/src/Pane';

import { useGeoStore } from '../../../../common/hooks/useGeoStore';
import { DEFAULT_MAP_ZOOM } from '../../../../common/constants/map';
import type { BuildingGroup } from '../../../../common/components/NewBuildingCard';
import MapSerp from '../../../../common/components/MapSerp';

import './styles.css';

interface IProps {
    id: string;
    newBuildingId: number;
    heading: React.ReactNode;
    center: NewBuildingCard['coordinates'];
    buildingGroups: BuildingGroup[];
    zoom?: number;
    regionId: NewBuildingCard['narrowRegionId'];
    centeringControlTitle?: string;
}

const cn = classname.bind(null, 'NewBuildingCardMap');

const NewBuildingCardMap: FC<IProps> = ({
    id,
    newBuildingId,
    heading,
    center,
    zoom: initialZoom = DEFAULT_MAP_ZOOM,
    buildingGroups,
    regionId,
    centeringControlTitle
}) => {
    const [ mapData, setMapData ] = useState<{
        bounds: YMapBounds | undefined;
        zoom: YMapBoundsZoom['zoom'];
    }>({
        bounds: undefined,
        zoom: initialZoom
    });
    const handleSetBounds = useCallback(
        (newBounds: YMapBoundsZoom) => setMapData({
            bounds: {
                max: newBounds.max,
                min: newBounds.min
            },
            zoom: newBounds.zoom
        }),
        []
    );
    const [ shouldShowInfrastructure, setShouldShowInfrastructure ] = useState<boolean>(true);
    const [ isFullScreenMap, setFullScreenMap ] = useState<boolean | undefined>(false);

    const geoStore = useGeoStore({
        regionId,
        isMetroOnly: true
    });

    return (
        <div
            id={id}
            className={cn(null, { anchor: true })}
        >
            {heading}
            <div className={cn('body')}>
                <YMapPane
                    center={center as [ number, number ]}
                    zoom={initialZoom}
                    setBounds={handleSetBounds}
                    withSetBoundsOnInitialize
                    onFullScreen={isFullScreen => setFullScreenMap(isFullScreen)}
                >
                    <YMapControlGroup
                        hAlign='right'
                        vAlign='top'
                        layout='vertical'
                    >
                        <YMapControlFullscreen title='Развернуть карту' />
                    </YMapControlGroup>
                    <YMapControlGroup
                        hAlign='right'
                        vAlign='middle'
                        layout='vertical'
                    >
                        <YMapControlZoom />
                        <YMapControlGeoLocation title='Моё местоположение' />
                        <YMapControlTraffic title='Пробки' />
                        <YMapControlRuler title='Линейка' />
                    </YMapControlGroup>
                    <YMapControlGroup
                        hAlign='right'
                        vAlign='bottom'
                        layout='vertical'
                    >
                        <YMapControlCentering title={centeringControlTitle} />
                    </YMapControlGroup>
                    <Checkbox
                        checked={shouldShowInfrastructure}
                        onChange={checked => setShouldShowInfrastructure(checked)}
                        className={cn('infrastructure', { fullscreen: isFullScreenMap })}
                    >
                        Инфраструктура
                    </Checkbox>
                    {
                        mapData.bounds ? (
                            <MapSerp
                                center={center}
                                bounds={mapData.bounds}
                                newBuildingId={newBuildingId}
                                regionId={regionId}
                                buildingGroups={buildingGroups}
                                showInfrastructure={shouldShowInfrastructure}
                                zoom={mapData.zoom}
                                geoStore={geoStore}
                            />
                        ) : null
                    }
                </YMapPane>
            </div>
        </div>
    );
};

export default React.memo(NewBuildingCardMap);
