import React, { useMemo } from 'react';

import { Link, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { STATIC_URL } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';

import { SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

export type DocumentType = {
    title: string;
    fileName?: string;
    url?: string;
};

interface IDocument extends DocumentType {
    subtitle: string;
    onClick?: () => void;
}

const cn = classname.bind(null, 'Document');

export const Document: React.FunctionComponent<IDocument> = ({
    title,
    subtitle,
    fileName,
    url,
    onClick
}) => {
    const href = useMemo(() => url && fileName ? `${
        STATIC_URL
    }/documents${
        url
    }/filename/${
        encodeURIComponent(fileName)
    }` : undefined, [ fileName, url ]);

    return (
        <div className={cn('wrapper')}>
            <div className={cn('content')}>
                <div className={cn('icon')}>
                    <SvgSpriteIcon name='Document' />
                </div>
                <div className={cn('title')}>
                    <Link
                        href={href}
                        onClick={onClick}
                    >
                        {title}
                    </Link>
                    <Typography
                        variant='secondary-alone'
                        color='secondary'
                    >
                        {subtitle}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
