/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* global sessionStorage */
/* global localStorage */
/* global window */
import React from 'react';

const sessionCache = new Map<string, unknown>();

export function useSessionStorage<Value>(namespaceRaw: string) {
    const namespace = namespaceRaw + '.';

    const [ count, setCount ] = React.useState(0);

    const refresh = React.useCallback(() => {
        setCount(c => c + 1);
    }, []);

    const value = React.useCallback((key: string, parsed?: Value | null) => {
        const k = namespace + key;

        if (typeof sessionStorage === 'undefined') return null;

        if (parsed === null) {
            sessionCache.delete(k);
            sessionStorage.removeItem(k);
            refresh();

            return parsed;
        }

        if (parsed === undefined) {
            if (sessionCache.has(k)) return sessionCache.get(k) as Value;

            const serialized = sessionStorage.getItem(k);

            if (serialized === undefined || serialized === null) return undefined;

            parsed = JSON.parse(serialized) as Value;
        }
        sessionCache.set(k, parsed);
        if (parsed === undefined) return;

        try {
            sessionStorage.setItem(k, JSON.stringify(parsed));
        } catch (e) {
            // limit error
            console.warn(e);
            sessionStorage.removeItem(k);
        }
        refresh();

        return parsed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ namespace, count, refresh ]);

    React.useEffect(() => {
        const cb = () => {
            sessionCache.clear();
            refresh();
        };

        window.addEventListener('storage', cb);

        return () => {
            window.removeEventListener('storage', cb);
        };
    }, [ refresh ]);

    return value;
}

export function useOfferSessionVisited() {
    return useSessionStorage<boolean>('OfferVisited');
}

