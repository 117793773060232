/* eslint-disable @typescript-eslint/naming-convention */
import './styles.css';

import { YMapControl, ICustomizableControl, YMapControlProps } from '../Control';

export type YMapControlCheckContent = [ string, string ];

export interface YMapControlCheckProps extends YMapControlProps {
    active?: boolean;
    hasInactiveView?: boolean;
    content?: YMapControlCheckContent;
}

const baseClass = 'YMapControlCheck';

export interface YMapControlCheckState {
    active: boolean;
    content?: YMapControlCheckContent;
}

export abstract class YMapControlCheckY<
    Props extends YMapControlCheckProps,
    Control extends ICustomizableControl,
    State extends YMapControlCheckState = YMapControlCheckState
> extends YMapControl<Props, Control, State> {
    protected get checkClass() {
        const { hasInactiveView, className } = this.props;

        return `${
            baseClass
        }${
            this.keys.state.active.ifTrue(` ${baseClass}_active`)
        }${
            hasInactiveView ? ` ${baseClass}_inactive` : ''
        }${
            className ? ` ${className}` : ''
        }`;
    }

    state(): State {
        const { active, content } = this.props;

        return {
            active: active ?? false,
            content
        } as State;
    }

    protected layoutClass() {
        const { title } = this.props;
        const Layout = super.layoutClass();

        return class YMapControlCheckLayout extends Layout {
            build() {
                super.build();

                const { buttonEl, content, activeTitle } = this;

                if (content) {
                    buttonEl.innerHTML = content;
                    // @ts-ignore
                    buttonEl.title = activeTitle;
                }

                const toggle = this.toggleClick.bind(this);
                const dispose = this.dispose;

                buttonEl.addEventListener('click', toggle);

                this.dispose = () => {
                    dispose();
                    buttonEl.removeEventListener('click', toggle);
                };
            }

            get content(): string | undefined {
                const content = this.state.get('content') as YMapControlCheckContent | undefined;

                return content && content[this.active ? 1 : 0];
            }

            get activeTitle(): string | undefined {
                return this.active ? '' : title;
            }

            get active(): boolean {
                return this.state.get('active') || false;
            }

            set active(next: boolean) {
                this.state.set('active', next);
            }

            protected get buttonEl() {
                const el = this.getElement();
                const buttonEl = el.querySelector(`.${baseClass}`);

                if (! buttonEl) throw new Error(`Not found elements in ${el}`);

                return buttonEl;
            }

            toggleClick() {
                const active = ! this.active;

                this.active = active;
                this.toggle(active);
            }

            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            protected toggle(next: boolean) {}
        };
    }
}
