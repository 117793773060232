import * as React from 'react';

import classname from '@search/classname/src';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { CURRENT_YEAR } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';

import SocialMediaLink from '../../../../../client/common/components/SocialMediaLink';
import { SocialMediaEnum, SvgSpriteIcon } from '../../../../../client/common/components/SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'Footer');

const Footer: React.FunctionComponent<{}> = () => {
    return (
        <div className={cn()}>
            <div className={cn('primary')}>
                <div className={cn('inner')}>
                    <div className={cn('logo')}>
                        <SvgSpriteIcon
                            name='LogoVTB'
                            width={98}
                            height={36}
                        />
                    </div>
                    <div className={cn('right')}>
                        <CustomTypography
                            level='h3'
                            tag='div'
                        >
                            8 800 100-24-24
                        </CustomTypography>
                        <CustomTypography
                            level='small-alone'
                            color='secondary'
                        >
                            Бесплатно для регионов России
                        </CustomTypography>
                    </div>
                </div>
            </div>
            <div className={cn('secondary')}>
                <div className={cn('inner')}>
                    <div className={cn('disclaimer')}>
                        <CustomTypography
                            level='small-alone'
                            color='white-500'
                        >
                            190000, г. Санкт-Петербург, ул. Большая Морская, д. 29
                            <br />
                            Генеральная лицензия Банка России №1000
                            <br />
                            &copy;&nbsp;ВТБ, {CURRENT_YEAR}
                        </CustomTypography>
                        <CustomTypography
                            level='small-alone'
                            color='minor'
                        >
                            Для повышения удобства работы с сайтом Банк ВТБ <a
                                href='https://www.vtb.ru/soglasie-na-obrabotku-dannyh-152-fz/'
                                target='_blank'
                            >
                                использует файлы cookie
                            </a>.
                            В cookie содержатся данные о прошлых посещениях сайта.
                            Если вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера.
                        </CustomTypography>
                    </div>
                    <div className={cn('links')}>
                        <div className={cn('socialMediaLinks')}>
                            {
                                Object.values(SocialMediaEnum).map(type => (
                                    <SocialMediaLink
                                        key={type}
                                        type={type}
                                    />
                                ))
                            }
                        </div>
                        <CustomTypography
                            color='white-500'
                            tag='a'
                            href='https://www.vtb.ru'
                            target='_blank'
                            style={{ textDecoration: 'none' }}
                        >
                            vtb.ru
                        </CustomTypography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
