import React, { FunctionComponent, Suspense } from 'react';

import classname from '@search/classname/src';
import { errorCatcherWrap } from '@search/error/src/catcher';

import { Shared } from '../../../../types/shared';

import Page from '../../../common/components/Page';
import { DeveloperSearchWithCatcher } from '../../../common/components/DeveloperSearch';

import DeveloperSnippet from '../../../common/components/DeveloperSnippet';

import './styles.css';

const cn = classname.bind(null, 'DeveloperSearchPage');

const DeveloperSnippetWithCatcher = errorCatcherWrap(DeveloperSnippet);

export const DeveloperSearchPage: FunctionComponent<Shared.DeveloperSearchPageProps> = ({
    route,
    params
}) => {
    const regionId = params.region && Number(params.region) || undefined;

    return (
        <Page
            className={cn()}
            regionId={regionId}
            withCustomerFeedback
        >
            <Suspense fallback=''>
                <DeveloperSearchWithCatcher
                    route={route}
                    params={params}
                    renderSnippet={snippet => (
                        <DeveloperSnippetWithCatcher
                            developer={snippet}
                            regionId={regionId}
                        />
                    )}
                />
            </Suspense>
        </Page>
    );
};

DeveloperSearchPage.displayName = 'DeveloperSearchPage';
