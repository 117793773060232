import React, { Suspense, useContext, useEffect, useState } from 'react';

import { errorCatcherWrap } from '@search/error/src/catcher';
import classname from '@search/classname/src';
import { useGql } from '@search/gql-client/src/useGql';
import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';

import type { Shared } from '../../../../types/shared';

import { GlobalContext } from '../../../common/components/GlobalContext';
import Page from '../../../common/components/Page';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import { GalleryContextProvider } from '../../../common/components/Gallery/GalleryContext';
import { FSGallery } from '../../../common/components/Gallery/FSGallery';
import { useCardFilters } from '../../../common/components/NewBuildingCardPage/useCardFilters';
import type {
    NewBuildingCardQueryResponse,
    NewBuildingCardQueryVariables
} from '../../../common/components/NewBuildingCard';
import { query } from '../../../common/components/NewBuildingCard';
import NewBuildingCardFAQ from '../../../common/components/NewBuildingCardFAQ';
// import { useRTBHouseRemarketing } from '../../../common/hooks/useRTBHouseRemarketing';
import { useCriteoRemarketing } from '../../../common/hooks/useCriteoRemarketing';

import NewBuildingCard from '../NewBuildingCard';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardPage');

const NewBuildingCardWrapCatcher = errorCatcherWrap(NewBuildingCard);
const NewBuildingCardFAQWrapCatcher = errorCatcherWrap(NewBuildingCardFAQ);

function NewBuildingCardPage({
    route,
    params
}: Shared.NewBuildingCardPageProps) {
    const { instanceConfig: { searchIndex, hasFavoritesButton } } = useContext(GlobalContext);

    const [ pageParams, setPageParams ] = useState(params);
    const [ isFromSerp, setIsFromSerp ] = useState(true);

    const {
        filters,
        updateFilter,
        updateMultipleFilters,
        removeFilter,
        resetBuildingsFilter,
        handlePageChange,
        handleSortChange
    } = useCardFilters({
        pageParams,
        route
    });

    const {
        data: cardData,
        loading: isCardLoading
    } = useGql<NewBuildingCardQueryVariables, NewBuildingCardQueryResponse>(
        query,
        {
            id: Number(pageParams.id),
            whiteLabel: searchIndex,
            skipFavorites: ! hasFavoritesButton
        }
    );
    const { newBuilding } = cardData!;
    const { id } = newBuilding;

    /* useRTBHouseRemarketing({
        type: 'card',
        id
    }); */

    useCriteoRemarketing({ id });

    useEffect(() => {
        document.body.style.overflowX = 'auto';

        if (isFromSerp) {
            try {
                const newBuildingSerpPageParams = window.localStorage.getItem('NewBuildingSerpPageParams');

                setPageParams(newBuildingSerpPageParams ?
                    { ...params, ...JSON.parse(newBuildingSerpPageParams)[newBuilding.id] } : params);
            } catch (e) {}

            setIsFromSerp(false);
        } else {
            try {
                const serpPageParamsStr = window.localStorage.getItem('NewBuildingSerpPageParams');
                const serpPageParams = serpPageParamsStr ? JSON.parse(serpPageParamsStr) : {};
                const newSerpPageParams = { ...serpPageParams, [newBuilding.id]: undefined };

                window.localStorage.setItem('NewBuildingSerpPageParams', JSON.stringify(newSerpPageParams));

                setPageParams(params);
            } catch (e) {}
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ newBuilding.id, params ]);

    const regionId = newBuilding.narrowRegionId;

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: newBuilding.gallery?.baseUrl });

    return (
        <Page
            className={cn()}
            regionId={regionId}
            withCustomerFeedback
        >
            <GalleryContextProvider>
                <ErrorBoundary>
                    <Suspense fallback=''>
                        <NewBuildingCardWrapCatcher {...{
                            newBuilding,
                            isCardLoading,
                            pageParams,
                            filters,
                            updateFilter,
                            updateMultipleFilters,
                            removeFilter,
                            resetFilters: resetBuildingsFilter,
                            handlePageChange,
                            handleSortChange
                        }} />
                        <NewBuildingCardFAQWrapCatcher
                            newBuilding={newBuilding}
                            view='desktop'
                        />
                    </Suspense>
                </ErrorBoundary>
                <ImageLazySrcSetProvide getUrl={getUrl}>
                    <FSGallery />
                </ImageLazySrcSetProvide>
            </GalleryContextProvider>
        </Page>
    );
}

NewBuildingCardPage.displayName = 'NewBuildingCardPage';

export default NewBuildingCardPage;
