import React, { FunctionComponent, Suspense, useState } from 'react';

import classname from '@search/classname/src';

import { errorCatcherWrap } from '@search/error/src/catcher';

import {
    SnippetProviderContext
} from '@search/vtbeco-frontend-core/domain/newbuilding/components/desktop/NewBuildingBaseSnippet/snippetContext';
import type { NewBuildingSnippet } from '@search/graphql-typings';

import type { Shared } from '../../../../types/shared';

import Page from '../../../common/components/Page';
import { DeveloperInfoWithCatcher } from '../../../common/components/DeveloperInfo';

import NewBuildingSerpSnippet from '../NewBuildingSerpSnippet';

import './styles.css';

const cn = classname.bind(null, 'DeveloperPage');

const NewBuildingSerpSnippetWithCatcher = errorCatcherWrap(NewBuildingSerpSnippet);

export const DeveloperPage: FunctionComponent<Shared.DeveloperPageProps> = ({
    route,
    params
}) => {
    const [ itemWithOpenedMap, setItemWithOpenedMap ] = useState<string | undefined>(undefined);

    const regionId = params.region && Number(params.region) || undefined;

    return (
        <Page
            className={cn()}
            regionId={regionId}
            withCustomerFeedback
        >
            <Suspense fallback=''>
                <DeveloperInfoWithCatcher
                    route={route}
                    params={params}
                    renderSnippet={(newBuilding, idx) => (
                        <SnippetProviderContext key={newBuilding.id}>
                            <NewBuildingSerpSnippetWithCatcher
                                newBuilding={newBuilding as NewBuildingSnippet}
                                withLazyLoad={idx !== 0}
                                itemWithOpenedMap={itemWithOpenedMap}
                                setItemWithOpenedMap={setItemWithOpenedMap}
                            />
                        </SnippetProviderContext>
                    )}
                />
            </Suspense>
        </Page>
    );
};

DeveloperPage.displayName = 'DeveloperPage';
