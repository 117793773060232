import React from 'react';

import Grid from '@vtblife/uikit-grid';
import { Button, Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

import { SvgSpriteIcon } from '../../../common/components/SvgSpriteIcon';

import './NewBuildingEmptySearch.css';

const cn = classname.bind(null, 'NewBuildingEmptySearch');

export const NewBuildingEmptySearch: React.FunctionComponent<{
    resetFilters: () => void;
    showSimilarText?: boolean;
}> = ({ resetFilters, showSimilarText }) => {
    return (
        <div className={cn()}>
            <div className={cn('container')}>
                <Grid cols={12}>
                    <Grid.Cell m={4}>
                        <div className={cn('svgContainer')}>
                            <SvgSpriteIcon
                                name='EmptySearch'
                                width={240}
                                height={240}
                            />
                        </div>
                    </Grid.Cell>
                    <Grid.Cell m={8}>
                        <div className={cn('content')}>
                            <div className={cn('text')}>
                                <Typography variant='h3' className={cn('header')}>
                                    По&nbsp;вашему запросу не&nbsp;нашлось предложений
                                </Typography>
                                <Typography variant='primary'>
                                    Попробуйте сбросить фильтры или изменить их&nbsp;параметры. {showSimilarText ? 'Мы\u00A0подобрали предложения, которые отличаются от\u00A0вашего запроса, но\u00A0могут заинтересовать' : ''}
                                </Typography>
                            </div>
                            <Button
                                variant='secondary'
                                width={193}
                                onClick={resetFilters}
                            >
                                Сбросить фильтры
                            </Button>
                        </div>
                    </Grid.Cell>
                </Grid>
            </div>
            {showSimilarText && (
                <Typography
                    tag='h2'
                    variant='h3'
                    className={cn('bottom')}
                >
                    Предложения, которые могут вас заинтересовать
                </Typography>
            )}
        </div>
    );
};

