import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../client/common/constants/mortgage';

/* eslint-disable max-len */
const longFlats = `Полная стоимость кредита 6,115—13,364%. Ставка ${VTB_MORTGAGE_RATE_FLATS}% годовых действует с даты заключения кредитного договора. ` +
    'Заемщики — граждане РФ, имеющие ребенка-гражданина РФ, рожденного с 01.01.2018 г. ' +
    'по 31.12.2023 г., либо имеющие двоих и более детей, которые не достигли 18 лет на дату заключения кредитного ' +
    'договора, либо заемщики с ребенком-инвалидом, рожденным не позднее 31.12.2023 г. Кредит предоставляется на приобретение ' +
    'у юр. лица (в том числе управляющей компании инвестиционного фонда) или ИП — первого собственника готового жилого ' +
    'помещения или на приобретение у юр. лица (за исключением управляющей компании инвестиционного фонда) жилого помещения, ' +
    'находящегося на этапе строительства, или на приобретение на вторичном рынке у физ. лица или юр. лица готового жилого помещения ' +
    '(в том числе с земельным участком), расположенных в сельских населенных пунктах на территории ДФО, или на приобретение готового ' +
    'жилья на вторичном рынке заемщиками, имеющими ребенка инвалида, у любого продавца — в случае расположения квартиры в субъекте РФ, ' +
    'на территории которого отсутствуют строящиеся многоквартирные дома. Сумма кредита для Москвы и области, Санкт-Петербурга и области ' +
    'до 12 млн руб., для остальных регионов — до 6 млн руб. Первый взнос — от 20,1%. ' +
    'Валюта кредита — рубли. Срок кредита — от 1 до 30 лет.';

const longAparts = `Полная стоимость кредита 17,067%—24,769%. Ставка ${VTB_MORTGAGE_RATE_APARTS}% годовых действует для новых зарплатных клиентов при условии комплексного страхования, ` +
    'при сумме кредита от 25 до 60 млн руб. для Москвы, Санкт-Петербурга, Московской и Ленинградской областей и от 10 до 30 млн руб. — ' +
    'для остальных регионов, первом взносе от 20% и при подаче онлайн-заявки или использовании сервисов электронной регистрации и безопасных ' +
    'расчетов или дистанционной сделки. Программа на покупку строящегося и готового жилья. Валюта кредита — рубли. Срок кредита — ' +
    'от 1 до 30 лет.';

const longTerms = 'Условия действительны с 28.02.2024 г. до даты изменения банком условий кредитования. Банк ВТБ (ПАО) оказывает исключительно ' +
    'банковские услуги. Банк вправе отказать в предоставлении кредита без объяснения причин.';

const longBank = 'Банк предоставляет кредит в случае, если заемщик (созаемщик/поручитель, при наличии) соответствует требованиям Банка, госпрограммы и предоставил ' +
    'полный пакет необходимых документов. Банк ВТБ (ПАО). Генеральная лицензия Банка России №1000.';

const flats = `Ставка ${VTB_MORTGAGE_RATE_FLATS}% годовых по программе «Семейная ипотека» действует при первом взносе ` +
    'от 20,1% в соответствии с параметрами программы. Полная стоимость кредита 6,115%—13,364%';

const apartments = `Ставка ${VTB_MORTGAGE_RATE_APARTS}% годовых действует при первом взносе от 20%, онлайн-заявке или использовании ` +
    'цифровых сервисов. Без комплексного страхования ставка увеличивается на 1%. Полная стоимость кредита 17,067—24,769%';

export const vtbMortageDisclaimer = {
    flats,
    apartments,
    long: `${longFlats}<br />${longAparts}<br />${longTerms}<br />${longBank}`
};
