export const PHOTO_TYPE = {
    COMMON: '',
    INNER_YARD: 'Двор',
    ENTRANCE: 'Вход в подъезд',
    HALL: 'Холл',
    ELEVATOR: 'Лифт',
    PARKING: 'Паркинг',
    FINISHING: 'Отделка',
    VIDEO: ''
};
