import React, { useContext } from 'react';

import MetroRoute from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import type { IMetroRouteProps } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';

import { OnChangeGlobalContext } from '../GlobalContext';
import type { IOnChangeGlobalContext } from '../GlobalContext';

export const MetroRouteWithSearchLink: React.FC<IMetroRouteProps & { regionId?: number }> = ({
    metro,
    regionId,
    ...otherProps
}) => {
    const { linkBuilder } = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    const metroId = metro.station?.id;
    const metroSearchUrl = regionId && metroId ? linkBuilder.search({
        region: regionId,
        metroId: [ metroId ]
    }) : undefined;

    return (
        <MetroRoute
            metro={metro}
            searchUrl={metroSearchUrl}
            {...otherProps}
        />
    );
};
