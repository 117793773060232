import { useCallback, useState } from 'react';

export const usePseudoLoading = (): {
    isPseudoLoading: boolean;
    executePseudoLoading: (skipPseudoLoading?: boolean) => void;
} => {
    const [ isPseudoLoading, setPseudoLoading ] = useState<boolean>(false);

    const executePseudoLoading = useCallback((skipPseudoLoading: boolean = false) => {
        if (! skipPseudoLoading) {
            setPseudoLoading(true);

            setTimeout(() => {
                setPseudoLoading(false);
            }, 500);
        }
    }, []);

    return {
        isPseudoLoading,
        executePseudoLoading
    };
};
