import React, { FC } from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { SubwayStation } from '@search/vtbeco-frontend-core/view/common/components/SubwayStation';

import type { YMapPlacemarkSubwayPinProps } from '../index';

import './styles.css';

const cn = classname.bind(null, 'YMapPlacemarkSubwayPin');

export const YMapPlacemarkSubwayPinHint: FC<
    Pick<YMapPlacemarkSubwayPinProps, 'id' | 'title' | 'allRoutesColorsList' | 'routeTitle'> & { iconName: string }
> = ({
    routeTitle,
    iconName,
    ...station
}) => (
    <div className={cn('hint')}>
        <div className={cn('hintIcon')}>
            <svg
                height={52}
                width={52}
            >
                <use xlinkHref={`#${iconName}`} />
            </svg>
        </div>
        <SubwayStation
            station={station}
            textVariant='secondary-alone'
            textColor='primary'
            textBold
            className={cn('hintSubwayStation')}
        />
        <Typography
            variant='small-alone'
            color='secondary'
            className={cn('hintRouteTitle')}
        >
            {routeTitle}
        </Typography>
    </div>
);
