import { useRef, useEffect, MutableRefObject } from 'react';

const useEventListener = (
    eventName: string,
    handler: Function,
    elementRef: MutableRefObject<HTMLElement | undefined>
) => {
    const savedHandler = useRef<Function>();

    useEffect(() => {
        savedHandler.current = handler;
    }, [ handler ]);

    useEffect(
        () => {
            const element = elementRef.current;
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const isSupported = element && element.addEventListener;

            if (! isSupported) {
                return;
            }

            // eslint-disable-next-line
            const eventListener = (event: any) => savedHandler.current!(event);

            element!.addEventListener(eventName, eventListener);

            return () => {
                element!.removeEventListener(eventName, eventListener);
            };
        },
        [ eventName, elementRef.current ]
    );
};

export default useEventListener;
