import React from 'react';
import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingDescription');

export const NewBuildingDescription: React.FC<{ text: string }> = ({ text }) => {
    return (<div
        className={cn()}
        dangerouslySetInnerHTML={{ __html: text }}
    />);
};
