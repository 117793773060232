import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';

import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

import Grid from '@vtblife/uikit-grid';
import { DeveloperPromoPlaceholders } from '@search/graphql-typings/src';

import { GlobalContext } from '../../../../common/components/GlobalContext';
import { useIsMskMOSPbLO } from '../../../../common/hooks/useIsMskMOSPbLO';
import useDeveloperPromoPlaceholder from '../../../../common/hooks/useDeveloperPromoPlaceholder';

import './styles.css';

const cn = classname.bind(null, 'NewNewBuildingSearchFiltersExtraView');

const gridVariables = {
    '--row-gap-xs': '20px',
    '--row-gap-s': '20px',
    '--row-gap-m': '20px',
    '--row-gap-l': '20px',
    '--row-gap-xl': '20px',
    '--column-gap-xs': '20px',
    '--column-gap-s': '20px',
    '--column-gap-m': '20px',
    '--column-gap-l': '20px',
    '--column-gap-xl': '20px'
};

interface IProps {
    regionId: number;

    totalArea: React.ReactNode;
    livingArea: React.ReactNode;
    kitchenArea: React.ReactNode;
    floor: React.ReactNode;
    ceilingHeight: React.ReactNode;
    bathroom: React.ReactNode;
    balcony: React.ReactNode;

    developer: React.ReactNode;
    closedSales: React.ReactNode;
    buildingClass: React.ReactNode;
    parking: React.ReactNode;
    walls: React.ReactNode;
    apartments: React.ReactNode;
    position: React.ReactNode;
    safety: React.ReactNode;

    flatsSpecialEvents: React.ReactNode;
    otherSpecialEvents: React.ReactNode;
    miscSpecialEvents: React.ReactNode;

    accreditation: React.ReactNode;
    deal: React.ReactNode;
    sale: React.ReactNode;

    buttonReset: React.ReactNode;
    buttonSubmit: React.ReactNode;

    placeholders: DeveloperPromoPlaceholders;
}

export const NewNewBuildingSearchFiltersExtraView = ({
    regionId,

    totalArea,
    livingArea,
    kitchenArea,
    floor,
    ceilingHeight,
    bathroom,
    balcony,

    developer,
    closedSales,
    buildingClass,
    parking,
    walls,
    apartments,
    position,
    safety,

    flatsSpecialEvents,
    otherSpecialEvents,
    miscSpecialEvents,

    accreditation,
    deal,
    sale,

    buttonReset,
    buttonSubmit,

    placeholders
}: IProps) => {
    const [ shadow, setShadow ] = useState(false);
    const coverRef = useRef<HTMLElement>();

    const {
        instanceConfig: { accreditedKey }
    } = useContext(GlobalContext);

    const hasSpecialEventFilters = useIsMskMOSPbLO(regionId);

    const developerPlaceholderGtmRef = useRef<HTMLDivElement>(null);

    useDeveloperPromoPlaceholder({
        placeholders,
        developerPlaceholderGtmRef
    });

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    setShadow(false);
                } else {
                    setShadow(true);
                }
            });
        });

        observer.observe(coverRef.current as Element);
    }, []);

    return (
        <div className={cn('extended')}>
            <div className={cn('more-filter')}>
                <div className={cn('filter-section')}>
                    <Typography
                        tag='h3'
                        variant='h3'
                        bold
                        className={cn('section-heading')}
                    >
                        Квартира
                    </Typography>
                </div>
                <div className={cn('extra')}>
                    <div className={cn('filter-section')}>
                        <Grid cols={2} variables={gridVariables}>
                            <Grid.Cell>
                                {totalArea}
                            </Grid.Cell>
                            <Grid.Cell>
                                {livingArea}
                            </Grid.Cell>
                        </Grid>
                    </div>
                    <div className={cn('filter-section')}>
                        <Grid cols={2} variables={gridVariables}>
                            <Grid.Cell>
                                {kitchenArea}
                            </Grid.Cell>
                        </Grid>
                    </div>
                    <div className={cn('filter-section', { floor: true })}>
                        {floor}
                    </div>
                    <div className={cn('filter-section')}>
                        {ceilingHeight}
                    </div>
                    <div className={cn('filter-section', { 'two-one': true })}>
                        {bathroom}
                        {balcony}
                    </div>
                </div>
                <div className={cn('filter-section', { heading: true })}>
                    <Typography
                        tag='h3'
                        variant='h3'
                        bold
                        className={cn('section-heading')}
                    >
                        ЖК
                    </Typography>
                </div>
                <div className={cn('extra')}>
                    <div
                        className={cn('filter-section')}
                        ref={developerPlaceholderGtmRef}
                    >
                        {developer}
                    </div>
                    <div className={cn('filter-section')}>
                        {closedSales}
                    </div>
                    <div className={cn('filter-section')}>
                        {buildingClass}
                    </div>
                    <div className={cn('filter-section')}>
                        {parking}
                    </div>
                    <div className={cn('filter-section')}>
                        {walls}
                    </div>
                    <div className={cn('filter-section', { 'two-one': true })}>
                        {apartments}
                        {position}
                    </div>
                    <div className={cn('filter-section')}>
                        {safety}
                    </div>
                </div>
                {
                    hasSpecialEventFilters ? (
                        <>
                            <div className={cn('filter-section', { heading: true })}>
                                <Typography
                                    tag='h3'
                                    variant='h3'
                                    bold
                                    className={cn('section-heading')}
                                >
                                    Акции и скидки
                                </Typography>
                            </div>
                            <div className={cn('extra')}>
                                <div className={cn('filter-section')}>
                                    {flatsSpecialEvents}
                                </div>
                                <div className={cn('filter-section')}>
                                    {otherSpecialEvents}
                                </div>
                                <div className={cn('filter-section')}>
                                    {miscSpecialEvents}
                                </div>
                            </div>
                        </>
                    ) : null
                }
                <div className={cn('filter-section', { heading: true })}>
                    <Typography
                        tag='h3'
                        variant='h3'
                        bold
                        className={cn('section-heading')}
                    >
                        Дополнительно
                    </Typography>
                </div>
                {
                    accreditedKey ? null : (
                        <div className={cn('filter-section')}>
                            {accreditation}
                        </div>
                    )
                }
                <div className={cn('filter-section')}>
                    {deal}
                </div>
                <div className={cn('filter-section')}>
                    {sale}
                </div>
            </div>
            <div
                ref={coverRef as RefObject<HTMLDivElement>}
                className={cn('cover')}
            >
                <div className={cn('inner-cover')} />
            </div>
            <div className={cn('buttons', { shadow })}>
                <div className={cn('button-reset')}>
                    {buttonReset}
                </div>
                <div className={cn('button-submit')}>
                    {buttonSubmit}
                </div>
            </div>
        </div>
    );
};
