import { NewBuildingFlat } from '@search/graphql-typings';

export const renderFlatStatus = (flat: NewBuildingFlat, isShort: boolean = false) => {
    if (flat.isSoldOut) {
        return 'Проданы';
    }

    if (flat.isSoon) {
        return 'Скоро в продаже';
    }

    if (flat.isTemporaryNotOnSale) {
        return 'Временно не в продаже';
    }

    const formatPriceRange = isShort ?
        flat.formatPriceRangeShort :
        flat.formatPriceRange;

    if (formatPriceRange) {
        return formatPriceRange;
    }

    return 'Уточняйте по телефону';
};
