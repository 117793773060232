/**
 * Склонение слов в зависимости от числительного
 * @param {number} number
 * @param {[ string, string, string ]} titles
 * @returns string
 * @example
 * declOfNum(1, [ 'объявление', 'объявления', 'объявлений' ]) -> объявление
 * declOfNum(3, [ 'объявление', 'объявления', 'объявлений' ]) -> объявления
 * declOfNum(5, [ 'объявление', 'объявления', 'объявлений' ]) -> объявлений
 */
export const declensionByNumber = (number: number, titles: [ string, string, string ]): string => {
    const cases = [ 2, 0, 1, 1, 1, 2 ];

    const numModulus100 = number % 100;
    const numModulus10 = number % 10;

    const index = (numModulus100 > 4 && numModulus100 < 20) ?
        2 :
        cases[(numModulus10 < 5) ? numModulus10 : 5] ?? 2;

    return titles[index];
};
