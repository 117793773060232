/* eslint-disable complexity */
import React, { FunctionComponent, useMemo, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Typography, Button } from '@vtblife/uikit';
import { Paginator } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import Breadcrumb, { BreadcrumbSection } from '@search/vtbeco-ui/src/components/Breadcrumb';
import type { IDeveloperSearchType } from '@search/nb-routes/src/DeveloperSearchRoute';
import { Route } from '@search/router/src/Route';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { GqlClientOptions, useGqlContext } from '@search/gql-client/src';
import { graphql } from '@search/gql-client/src/useGql';
import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { ROSSIYA } from '@search/filter-enums/enums/Region';

import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';

import { Shared } from '../../../../types/shared';
import { ArrayElement } from '../../../../types';

import { GlobalContext, IGlobalContext } from '../GlobalContext';
import { useDeveloperSearchPageSeo } from '../DeveloperSearchPageSeo';

import useBreadcrumbs from './useBreadcrumbs';
import { DeveloperSearchRegionFilter } from './DeveloperSearchRegionFilter';

import type {
    DeveloperSearchQuery$data as DeveloperSearchQueryResponse,
    DeveloperSearchQuery$variables as DeveloperSearchQueryVariables
} from './__generated__/DeveloperSearchQuery.graphql';

import './styles.css';

const cn = classname.bind(null, 'DeveloperSearch');

const DEVELOPER_SEARCH_QUERY = graphql`
    query DeveloperSearchQuery(
        $paging: PagingInput!
        $filters: SearchDevelopersInput!
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        searchDevelopers(
            whiteLabel: $whiteLabel
            paging: $paging
            filters: $filters
        ) {
            items {
                id
                translitName
                name
                images {
                    originPath
                }
                icon {
                    origin
                }
                total
                commissioned
                inProgress
                baseUrl
            }
            paging {
                total
                pageNumber
                pageSize
                hasNext
            }
        }
    }
`;

type DeveloperSnippet = NonNullable<
    ArrayElement<NonNullable<NonNullable<DeveloperSearchQueryResponse['searchDevelopers']>['items']>>
>;

type DeveloperSearchPageType = {
    params: IDeveloperSearchType;
    route: Route<IDeveloperSearchType, Shared.IRouterContext, {}>;
    renderSnippet: (item: DeveloperSnippet) => React.ReactNode;
};

const renderBreadcrumps = (breadcrumbs: readonly BreadcrumbSection[]) => (
    <div className={cn('breadcrumbs')}>
        <Breadcrumb sections={breadcrumbs} withoutSeoMarkup />
    </div>
);

function useDeveloperSearchFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: DeveloperSearchQueryVariables, opts?: GqlClientOptions) => gql.client<
            DeveloperSearchQueryVariables,
            DeveloperSearchQueryResponse
        >(DEVELOPER_SEARCH_QUERY, vars, opts),
        [ gql ]
    );
}

export const DeveloperSearch: FunctionComponent<DeveloperSearchPageType> = ({
    params,
    route,
    renderSnippet
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        isMobile,
        instanceConfig: {
            searchIndex,
            hasBreadcrumbs
        }
    } = globalContext;

    const pageNumberRef = useRef<number>(params.pageNumber ?? 1);

    const developerSearchLoader = useGql2Loader(useDeveloperSearchFetcher(), {
        cachePrefix: 'developerSearch',
        tracingTag: 'DeveloperSearch',
        authTrack: ! isMobile
    });
    const response = developerSearchLoader({
        whiteLabel: searchIndex,
        paging: {
            pageSize: 12,
            pageNumber: isMobile ? pageNumberRef.current : params.pageNumber ?? 1
        },
        filters: {
            regionId: params.region ? Number(params.region) : ROSSIYA
        }
    });

    const prevDataRef = useRef<DeveloperSearchQueryResponse>();

    const data = useMemo(() => {
        const currentData = response.data;

        if (isMobile) {
            const prevData = prevDataRef.current;

            if (! currentData) {
                return prevData;
            }

            if (! prevData) {
                return currentData;
            }

            const nextData: DeveloperSearchQueryResponse = {
                ...prevData,
                searchDevelopers: {
                    ...prevData?.searchDevelopers,
                    paging: currentData?.searchDevelopers?.paging ?? null,
                    items: [
                        ...prevData?.searchDevelopers?.items ?? [],
                        ...currentData?.searchDevelopers?.items ?? []
                    ]
                }
            };

            prevDataRef.current = undefined;

            return nextData;
        }

        if (currentData) {
            prevDataRef.current = currentData;
        }

        return prevDataRef.current;
    }, [ response.data, isMobile ]);

    const [ , setCounter ] = useState(0);

    const handleLoadNext = useCallback(() => {
        prevDataRef.current = data;

        pageNumberRef.current++;

        setCounter(p => p + 1);
    }, [ data ]);

    const { paging, items } = data?.searchDevelopers ?? {};

    const { title, description } = useDeveloperSearchPageSeo(params);

    useEffect(() => {
        document.title = title;
    }, [ title ]);

    const { locative } = params.region &&
        globalContext.rootRegions.getById(Number(params.region)) ||
        {};

    const handleRegionChange = useCallback((value: React.ReactText) => route.push({
        region: value === String(ROSSIYA) ? undefined : Number(value)
    }), [ route ]);

    // Затычка для «показать еще»
    const pageNumber = (params.pageNumber ?? 0) >= (paging?.pageNumber ?? 0) ?
        params.pageNumber :
        paging?.pageNumber ?? 1;
    const hasNextPage = paging?.total && paging?.total > (paging?.pageSize ?? 0);

    const { items: breadcrumbs } = useBreadcrumbs({
        hasBreadcrumbs,
        route,
        pageParams: params
    });

    return (
        <div className={cn(null, {
            isMobile,
            hasBreadcrumbs: Boolean(breadcrumbs)
        })}>
            <div className={cn('section', { header: true })}>
                {breadcrumbs && ! isMobile ? renderBreadcrumps(breadcrumbs) : null}
                <div className={cn('header')}>
                    <Typography
                        tag='h1'
                        variant={isMobile ? 'primary-alone' : 'h1'}
                        bold
                    >
                        Каталог застройщиков {locative}
                    </Typography>
                </div>
            </div>
            <div className={cn('section', { developers: true })}>
                <List className={cn('list')}>
                    <ListItem key='region'>
                        <DeveloperSearchRegionFilter
                            regionId={params.region}
                            onChange={handleRegionChange}
                        />
                    </ListItem>
                    {paging?.total ? (
                        <ListItem key='desc'>
                            <Typography
                                tag='span'
                                variant={isMobile ? 'small-alone' : 'primary-alone'}
                                color='black-400'
                            >
                                {description}
                            </Typography>
                        </ListItem>
                    ) : null}
                </List>
                <div className={cn('serp')}>
                    {items?.map(item => (
                        <div
                            key={item!.id}
                            className={cn('snippet')}
                        >
                            {renderSnippet(item!)}
                        </div>
                    ))}
                </div>
                {hasNextPage && (
                    <div className={cn('footer')}>
                        {isMobile && paging?.hasNext ? (
                            <Button
                                disabled={response?.loading}
                                size='m'
                                fullWidth={isMobile}
                                variant='secondary'
                                onClick={handleLoadNext}
                            >
                                Показать еще
                            </Button>
                        ) : null}
                        {isMobile ? null : (
                            <Paginator
                                onChange={(page, event) => {
                                    event?.preventDefault();

                                    window.scrollTo(0, 0);

                                    route.push({ ...params, pageNumber: page });
                                }}
                                page={pageNumber!}
                                itemsPerPage={12}
                                buildPageUrl={page => route.url({ ...params, pageNumber: page })}
                                totalItems={paging?.total ?? 0}
                            />
                        )}
                    </div>
                )}
                {breadcrumbs && isMobile ? renderBreadcrumps(breadcrumbs) : null}
            </div>
        </div>
    );
};

DeveloperSearch.displayName = 'DeveloperSearch';

export const DeveloperSearchWithCatcher = errorCatcherWrap(DeveloperSearch);
