import { AreaUnit } from '@search/filter-enums/enums/AreaUnit';
import { Currency } from '@search/filter-enums/enums/Currency';

import {
    CurrencyEnum as CurrencyEnumGQL,
    AreaUnitEnum as AreaUnitEnumGQL
} from '@search/graphql-typings';

type Area = {
    value: number;
    unit: AreaUnit;
};

export const mapAreaUnitEnumToGQL = {
    [AreaUnit.UNKNOWN]: AreaUnitEnumGQL.Unknown,
    [AreaUnit.METER]: AreaUnitEnumGQL.Meter,
    [AreaUnit.ARE]: AreaUnitEnumGQL.Are,
    [AreaUnit.HECTARE]: AreaUnitEnumGQL.Hectare
};

export const currencyEnumToGQL = {
    [Currency.UNKNOWN]: CurrencyEnumGQL.Unknown,
    [Currency.RUB]: CurrencyEnumGQL.Rub,
    [Currency.USD]: CurrencyEnumGQL.Usd,
    [Currency.EUR]: CurrencyEnumGQL.Eur
};

export const convertAreaUnitEnumToGQL = (unit: AreaUnit) => {
    return unit ? mapAreaUnitEnumToGQL[unit] : AreaUnitEnumGQL.Unknown;
};

export const convertAreaToGQL = ({ value, unit }: Area) => {
    return {
        value,
        unit: convertAreaUnitEnumToGQL(unit)
    };
};

export const convertCurrencyToGQL = (currency: Currency) => {
    return currency ? currencyEnumToGQL[currency] : CurrencyEnumGQL.Unknown;
};
