import { useCallback, useMemo, useRef, useState } from 'react';

import type { NewBuildingSnippet } from '@search/graphql-typings';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { convertParamsToGQLFilters } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { GqlClientOptions, graphql, useGqlContext } from '@search/gql-client/src';
import type { Route } from '@search/router/src';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';

import type { ArrayElement } from '../../../../types';
import { notEmpty } from '../../../../types';
import type { Shared } from '../../../../types/shared';

import type {
    NewBuildingSearchQuery$data as NewBuildingSearchQueryResponse,
    NewBuildingSearchQuery$variables as NewBuildingSearchQueryVariables,
    NewBuildingsInput
} from './__generated__/NewBuildingSearchQuery.graphql';

// eslint-disable-next-line @typescript-eslint/naming-convention
type ExtractSnippet<A> = A extends { __typename: 'NewBuildingSnippet' } ? A : never

export type {
    AreaUnitEnum,
    BathroomEnum,
    CeilingHeightEnum,
    CommissioningEnum,
    FinishingTypeEnum,
    FloorConstraintsEnum,
    IsApartmentsOptionsEnum,
    NewBuildingClassEnum,
    NewBuildingSortEnum,
    NewBuildingVtbMortgageVariant,
    NewBuildingWhitelabelEnum,
    ParkingEnum,
    RoomsEnum,
    TipTypeEnum,
    TransportTimeLimitEnum,
    TransportTypeEnum,
    WallsTypeEnum,
    NewBuildingsInput,
    NewBuildingSearchQuery$variables as NewBuildingSearchQueryVariables,
    NewBuildingSearchQuery$data as NewBuildingSearchQueryResponse
} from './__generated__/NewBuildingSearchQuery.graphql';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type newBuildingSnippets = NonNullable<
    NonNullable<NewBuildingSearchQueryResponse['searchNewBuildings']>['items']
>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type newBuildingSearchSnippet = NonNullable<
    ArrayElement<NonNullable<NonNullable<NewBuildingSearchQueryResponse['searchNewBuildings']>['items']>>
>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type newBuildingSnippet = ExtractSnippet<newBuildingSearchSnippet>;

const NEW_BUILDING_SEARCH_QUERY = graphql`
    fragment NewBuildingSearchCloudLinkFields on LinkParams {
        region
        metroId
        districtId
        rooms
        hasFinishing
        finishingType
        buildingClass
        commissioning
        wallsType
        floorLast
        law214
        armyMortgage
        installment
        escrow
        isCurrent
        priceMax
        sort
        transportType
        maxMinutes
        priceMax
        priceType
        parking
        closedArea
        concierge
        ceilingHeight
        kitchenAreaRangeMin
        #apartments
    }

    fragment NewBuildingSearchInfoItemFields on InfoItem {
        weight
        label
        snippetLabel
        value
        snippetValue
        iconName
    }

    query NewBuildingSearchQuery(
        $filters: NewBuildingsInput
        $paging: PagingInput!
        $sort: NewBuildingSortEnum
        $skipDisplayValues: Boolean!
        $promo: Boolean!
        $promoQuiz: Boolean!
        $whiteLabel: NewBuildingWhitelabelEnum
        $touch: Boolean!
        $skipLinkCloud: Boolean!
        $developersFilters: SearchDevelopersInput!
        $developersPaging: PagingInput!
        $skipFavorites: Boolean!
    ) {
        searchNewBuildings(
            sort: $sort
            paging: $paging
            filters: $filters
            whiteLabel: $whiteLabel
            promo: $promo
            promoQuiz: $promoQuiz
        ) {
            tips @skip(if: $skipDisplayValues) {
                developers {
                    id
                    type
                    title
                }

                newBuildings {
                    id
                    type
                    title
                }

                addresses {
                    id
                    title
                    type
                }

                metros {
                    __typename
                    id
                    title
                    allRoutesColors
                    type
                }

                districts {
                    id
                    title
                    type
                }

            }
            items(touch: $touch) {
                __typename
                ... on NewBuildingPromoSnippet {
                    isActive
                    id
                    logoWhite
                    disclaimer
                    developerName
                    erid
                }
                ... on NewBuildingAddBanner {
                    isActive
                }
                ... on NewBuildingSearchPromoBanner {
                    isActive
                    id
                    logoColor
                    logoWhite
                    disclaimerList
                    developerName
                    erid
                }
                ... on NewBuildingSearchSamoletPlusBanner {
                    isActive
                    logoColor
                    logoWhite
                    caption
                    subtitle
                    buttonText
                    url
                    imageDesktop
                    imageTouch
                }
                ... on NewBuildingSearchMortgagePromoBanner {
                    isActive
                    caption
                    subtitle
                    buttonText
                    badgeText
                    imageDesktop
                    imageTouch
                }
                ... on NewBuildingSearchQuickSelectionBanner {
                    isActive
                }
                ...on NewBuildingSnippet {
                    id
                    narrowRegionId(whiteLabel: $whiteLabel)
                    title {
                        nominative
                        accusative
                    }
                    ranking
                    hasApartments
                    isSoldOut
                    isSoon
                    priceMin
                    address
                    shortAddress
                    coordinates
                    mortgageMinPay {
                        minPay
                        variant
                        initialFee
                    }
                    buildingClass {
                        label
                    }
                    hasEscrow
                    buildingsAccreditedByVtb
                    buildingStatus {
                        title
                        isFrozen
                    }
                    gallery {
                        main {
                            galleryThumbnail @skip(if: $touch)
                            originPath
                        }
                        images {
                            galleryThumbnail @skip(if: $touch)
                            originPath
                        }
                    }
                    description
                    routes(type: [ METRO ]) {
                        ...on RouteMetro {
                            station {
                                id
                                name
                                lines
                            }
                            timeMinutes
                            transportType
                        }
                    }
                    info {
                        commissioningDate {
                            ...NewBuildingSearchInfoItemFields
                        }
                        buildingClass {
                            ...NewBuildingSearchInfoItemFields
                        }
                        totalCount {
                            ...NewBuildingSearchInfoItemFields
                        }
                        apartments {
                            ...NewBuildingSearchInfoItemFields
                        }
                    }
                    flats {
                        rooms
                        labelShort
                        label(type: SNIPPET)
                        isSoldOut
                        isSoon
                        isTemporaryNotOnSale
                        formatAreaRange
                        formatPriceRange
                        formatPriceRangeShort
                    }
                    developerCards {
                        developer {
                            id
                            title
                            translitTitle
                            icon {
                                origin
                            }
                        }
                    }
                    routeParams {
                        region
                        regionId
                        subRegion
                        name
                        type
                        id
                    }
                    hasPreferentialMortgage
                    mortgageParams {
                        term
                        regionId
                        isMainRegion
                        propertyCost
                        purpose
                        purposeValue
                        claimType
                        ownAmount
                    }
                    specialEvents {
                        type
                        title
                        titleRaw
                        description {
                            short
                        }
                    }
                    inspectionStatus(whiteLabel: $whiteLabel) {
                        isVerified
                        value
                        statusText
                    }
                    isFavorite @skip(if: $skipFavorites)
                    isMskMO
                    isSPbLO
                    isSpecProjectRegion
                    hasChat
                    noOffers
                    m2Pro {
                        prepayment
                        cooperationTerms {
                            title
                            fileName
                            url
                        }
                        nbPresentation {
                            title
                            fileName
                            url
                        }
                    }
                }
            }
            paging {
                total
                hasNext
                pageNumber
                pageSize
            }
            rating
            reviewsCount
            isEmpty
            specProjectPlaceholders {
                newBuilding
                developer
                developerName
            }
        }
        newBuildingsLinkCloud(
            filters: $filters
            sort: $sort
        ) @skip(if: $skipLinkCloud) {
            metro {
                ...NewBuildingSearchCloudLinkFields
            }
            district {
                ...NewBuildingSearchCloudLinkFields
            }
            city {
                ...NewBuildingSearchCloudLinkFields
            }
            seeAlso {
                roomList {
                    ...NewBuildingSearchCloudLinkFields
                }
                finishingList {
                    ...NewBuildingSearchCloudLinkFields
                }
                buildingClassList {
                    ...NewBuildingSearchCloudLinkFields
                }
                dateOfCommissioningList {
                    ...NewBuildingSearchCloudLinkFields
                }
                wallsTypeList {
                    ...NewBuildingSearchCloudLinkFields
                }
                floorLastList {
                    ...NewBuildingSearchCloudLinkFields
                }
                sellConditionsList {
                    ...NewBuildingSearchCloudLinkFields
                }
                cheapList {
                    ...NewBuildingSearchCloudLinkFields
                }
                closeToMetro {
                    ...NewBuildingSearchCloudLinkFields
                }
                priceMaxList {
                    ...NewBuildingSearchCloudLinkFields
                }
                parkingList {
                    ...NewBuildingSearchCloudLinkFields
                }
                securityList {
                    ...NewBuildingSearchCloudLinkFields
                }
                highCeilingsList {
                    ...NewBuildingSearchCloudLinkFields
                }
                bigKitchenList {
                    ...NewBuildingSearchCloudLinkFields
                }
                euroKindList {
                    ...NewBuildingSearchCloudLinkFields
                }
                #apartmentsList {
                #    ...NewBuildingSearchCloudLinkFields
                #}
            }
        }
        searchDevelopers(
            paging: $developersPaging
            filters: $developersFilters
            whiteLabel: $whiteLabel
        ) @skip(if: $skipLinkCloud) {
            items {
                id
                translitName
                name
            }
        }
    }
`;

const PAGE_SIZE_30_PATHNAME = '/moskva/novostroyki/3-komnatnye/';
const PAGE_SIZE_20_PATHNAME = '/moskovskaya-oblast/novostroyki/';

interface UseNewBuildingSearchProps {
    skipDisplayValues: boolean;
    skipFavorites: boolean;
    promo: boolean;
    promoQuiz: boolean;
    touch: boolean;
    pageParams: Shared.ISearchParams;
    route?: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    whiteLabel?: NewBuildingWhitelabelEnum;
    hasEventsOnNewData?: boolean;
}

function useNewBuildingSearchFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: NewBuildingSearchQueryVariables, opts?: GqlClientOptions) => gql.client<
            NewBuildingSearchQueryVariables,
            NewBuildingSearchQueryResponse
        >(NEW_BUILDING_SEARCH_QUERY, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export const useNewBuildingSearch = ({
    pageParams,
    touch = false,
    promo = false,
    promoQuiz = false,
    skipDisplayValues = false,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    route,
    skipFavorites,
    hasEventsOnNewData
}: UseNewBuildingSearchProps) => {
    const newBuildingsInput = useMemo(
        () => convertParamsToGQLFilters(pageParams) as NewBuildingsInput,
        [ pageParams ]
    );

    const pageSize = useMemo(() => {
        const defaultPageSize = pageParams.pageSize ?? 10;

        if (! route || whiteLabel === NewBuildingWhitelabelEnum.Vtb) {
            return defaultPageSize;
        }

        const nbSearchUrl = route.url(pageParams);
        const { pathname, searchParams } = new URL(nbSearchUrl);

        let isQueryParamValid = true;

        searchParams.forEach((value, key) => {
            if (key !== 'pageNumber') {
                isQueryParamValid = false;
            }
        });

        if (pathname === PAGE_SIZE_30_PATHNAME && isQueryParamValid) {
            return 30;
        }

        if (pathname === PAGE_SIZE_20_PATHNAME && isQueryParamValid) {
            return 20;
        }

        return defaultPageSize;
    }, [ pageParams, route, whiteLabel ]);

    const pageNumberRef = useRef<number>(pageParams.pageNumber!);

    const nbSearchLoader = useGql2Loader(useNewBuildingSearchFetcher(), {
        cachePrefix: 'newBuildingSearch',
        tracingTag: 'useNewBuildingSearch',
        authTrack: ! touch
    });

    const response = nbSearchLoader({
        touch,
        promo,
        promoQuiz,
        whiteLabel,
        skipDisplayValues,
        sort: pageParams.sort as NewBuildingSearchQueryVariables['sort'],
        filters: newBuildingsInput,
        paging: {
            pageNumber: touch ? pageNumberRef.current : pageParams.pageNumber!,
            pageSize
        },
        skipLinkCloud: whiteLabel !== NewBuildingWhitelabelEnum.Default,
        developersFilters: {
            regionId: Number(pageParams.region)
        },
        developersPaging: {
            pageNumber: 1,
            pageSize: 15
        },
        skipFavorites
    });

    const prevDataRef = useRef<NewBuildingSearchQueryResponse>();

    const data = useMemo(() => {
        const currentData = response.data;
        const prevData = prevDataRef.current;

        if (! currentData) {
            // для "Показать еще" вернется объект с предыдущими данными, в остальных случаях — undefined
            return prevData;
        }

        if (hasEventsOnNewData) {
            const currentItems = (currentData?.searchNewBuildings?.items ?? [])
                .filter(notEmpty)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                .filter(({ __typename }) => __typename === 'NewBuildingSnippet') as unknown as NewBuildingSnippet[];

            if (currentItems.length) {
                dataLayerPush({
                    event: NewBuildingAnalytics.NewBuildingSerpLoad,
                    'nb-serp-results': JSON.stringify(
                        currentItems.reduce((res, snippet) => ({
                            ...res,
                            [snippet.id]: snippet.ranking
                        }), {})
                    )
                });
            }
        }

        if (! prevData) {
            return currentData;
        }

        const next: NewBuildingSearchQueryResponse = {
            ...prevData,
            searchNewBuildings: {
                ...prevData?.searchNewBuildings,
                paging: currentData?.searchNewBuildings?.paging ?? null,
                items: [
                    ...prevData?.searchNewBuildings?.items ?? [],
                    ...currentData?.searchNewBuildings?.items ?? []
                ],
                isEmpty: currentData?.searchNewBuildings?.isEmpty ?? null,
                rating: currentData?.searchNewBuildings?.rating ?? null,
                reviewsCount: currentData?.searchNewBuildings?.reviewsCount ?? null,
            }
        };

        prevDataRef.current = undefined;

        return next;
    }, [ hasEventsOnNewData, response.data ]);

    const handleSortChange = useCallback((value: string | number) => {
        route?.push({
            ...pageParams,
            sort: value as string,
            pageNumber: 1
        });
    }, [ route, pageParams ]);

    const handlePageChange = useCallback((page: number) => {
        route?.push({
            ...pageParams,
            pageNumber: page
        });
    }, [ route, pageParams ]);

    const [ , setCounter ] = useState(0);

    const handleLoadNext = useCallback(() => {
        prevDataRef.current = data;

        pageNumberRef.current++;

        setCounter(p => p + 1);
    }, [ data ]);

    return {
        data,
        clearCache: response.clearCache,
        loading: response.loading,
        handleLoadNext,
        handleSortChange,
        handlePageChange,
        pageSize
    };
};
