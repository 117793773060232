export type YMapVector = {
    x: number;
    y: number;
};

export function yMapVectorPack(coordinates: readonly YMapVector[]): string | undefined {
    return coordinates.map(coordinate => `${coordinate.x}!${coordinate.y}`).join('~') || undefined;
}

export function yMapVectorUnpack(coordinates: string): YMapVector[] {
    return coordinates.split('~').map(coordinateStr => {
        const [ x, y ] = coordinateStr.split('!');

        return { x: Number(x), y: Number(y) };
    });
}
