import React, { useContext } from 'react';

import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { graphql, useGql } from '@search/gql-client/src';
import { ROSSIYA } from '@search/filter-enums/enums/Region';
import { IDeveloperSearchType } from '@search/nb-routes/src/DeveloperSearchRoute';

import { Shared } from '../../../../types/shared';

import { GlobalContext, IGlobalContext, OnChangeGlobalContext } from '../GlobalContext';
import { Meta } from '../Meta';
import useBreadcrumbs from '../DeveloperSearch/useBreadcrumbs';

import type {
    DeveloperSearchPageSeoQuery$data as DeveloperSearchPageSeoQueryResponse,
    DeveloperSearchPageSeoQuery$variables as DeveloperSearchPageSeoQueryVariables
} from './__generated__/DeveloperSearchPageSeoQuery.graphql';

const DEVELOPER_SEARCH_PAGE_SEO_QUERY = graphql`
    query DeveloperSearchPageSeoQuery(
        $paging: PagingInput!
        $filters: SearchDevelopersInput!
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        searchDevelopers(
            whiteLabel: $whiteLabel
            paging: $paging
            filters: $filters
        ) {
            paging {
                total
            }
        }
    }
`;

export const useDeveloperSearchPageSeo = (params: IDeveloperSearchType) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        instanceConfig: {
            searchIndex
        }
    } = globalContext;
    const onChangeGlobalContext = useContext(OnChangeGlobalContext);
    let canonicalUrl = '';

    if (onChangeGlobalContext.linkBuilder.developerSearch) {
        canonicalUrl = onChangeGlobalContext?.linkBuilder?.developerSearch(params).split('?')[0];
    }

    const regionId = params.region ? Number(params.region) : ROSSIYA;

    const {
        data
    } = useGql<
        DeveloperSearchPageSeoQueryVariables,
        DeveloperSearchPageSeoQueryResponse
    >(DEVELOPER_SEARCH_PAGE_SEO_QUERY, {
        whiteLabel: searchIndex,
        paging: {
            pageSize: 12,
            pageNumber: params.pageNumber || 1
        },
        filters: {
            regionId
        }
    });
    const total = data?.searchDevelopers?.paging?.total ?? 0;
    const { locative } = globalContext.rootRegions.getById(regionId) ?? {};
    const locativePostfix = locative ? ` ${locative}` : '';

    return {
        canonicalUrl,
        title: `Каталог застройщиков${
            locativePostfix
        }\u00A0— полный список застройщиков${
            locativePostfix
        } на\u00A0m2.ru`,
        description: `Все застройщики${
            locativePostfix
        }\u00A0— список девелоперов, подробная информация${
            total ?
                ` по\u00A0${
                    formatLocaleNumber(total)
                }\u00A0${
                    declensionByNumber(total, [
                        'застройщику',
                        'застройщикам',
                        'застройщикам'
                    ])
                }` :
                ''
        }.${
            regionId === ROSSIYA ?
                ' Рейтинг строительных компаний, отзывы покупателей и\u00A0оценки экспертов.' :
                ''
        }`
    };
};

export const DeveloperSearchPageSeo = ({
    params,
    route
}: Shared.DeveloperPageProps) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        instanceConfig: {
            hasBreadcrumbs
        }
    } = globalContext;

    const {
        canonicalUrl,
        description,
        title
    } = useDeveloperSearchPageSeo(params);

    const breadcrumbs = useBreadcrumbs({
        hasBreadcrumbs,
        route,
        pageParams: params
    });

    const breadcrumbsMeta = breadcrumbs.rdfMeta;

    return (
        <>
            <Meta
                title={title}
                description={description}
                canonical={canonicalUrl}
            />
            {
                breadcrumbsMeta ? (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{ __html: breadcrumbsMeta }}
                    />
                ) : null
            }
        </>
    );
};

DeveloperSearchPageSeo.displayName = 'DeveloperSearchPageSeo';

export default DeveloperSearchPageSeo;
