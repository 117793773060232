export type YMapVector = {
    /**
     * Longitude
     */
    x: number;
    /**
     * Latitude
     */
    y: number;
};

export function yMapIsVector(some: Object): some is YMapVector {
    return some && typeof some === 'object' &&
        (some as YMapVector).x !== undefined && (some as YMapVector).y !== undefined;
}

export function yMapVectorIsEqual(a: YMapVector, b: YMapVector, precision?: number): boolean {
    return coordinateRound(a.x, precision) === coordinateRound(b.x, precision) &&
        coordinateRound(a.y, precision) === coordinateRound(b.y, precision);
}

export const yMapConfig = {
    coordinateRound: 7
};

export function yMapVectorRound(coordinate: YMapVector, precision?: number): YMapVector {
    return {
        x: coordinateRound(coordinate.x, precision),
        y: coordinateRound(coordinate.y, precision)
    };
}

function coordinateRound(coordinate: number, precision = yMapConfig.coordinateRound): number {
    return Number(coordinate.toFixed(precision));
}

export function yMapVectorToMapCoordinate(coordinate: YMapVector) {
    return [
        coordinate.y,
        coordinate.x
    ];
}

export function yMapVectorToLatLon(coordinate: YMapVector) {
    return {
        longitude: coordinate.y,
        latitude: coordinate.x
    };
}

export function yMapVectorFromLatLon(coordinate: {latitude: number; longitude: number}): YMapVector {
    return {
        x: coordinate.longitude,
        y: coordinate.latitude
    };
}
