import React, { useCallback, useState } from 'react';

import classname from '@search/classname/src';

import { errorCatcherWrap } from '@search/error/src/catcher';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import { IFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { getTipsCollection, OffersSearchTips, TipItem } from '@search/vtbeco-frontend-core/view/tips/OffersSearchTips';
import { DeveloperPromoPlaceholders } from '@search/graphql-typings/src';

import type { CustomFilterBehavior } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';
import { InputQueryDisplayValues, useQueryDisplayValues } from '../../../common/hooks/useQueryDisplayValues';

import NewNewBuildingSearchFilters from '../NewNewBuildingSearchFilters';

import { useNewBuildingSearchFiltersUi } from './useNewBuildingSearchFiltersUi';
import './styles.css';

const cn = classname.bind(null, 'NewBuildingFiltersWrapper');

const OffersSearchTipsWitchErrorCatcher = errorCatcherWrap(OffersSearchTips);

export const NewBuildingFiltersWrapper: React.FunctionComponent<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    count?: number;
    displayValues: InputQueryDisplayValues;
    filters: NewbuildingFilterCollection;
    resetFilters: (resetGeo?: boolean) => void;
    updateFilter: (filter: IFilter) => void;
    updateFewFilters: (filtersToUpdate: IFilter[]) => void;
    handleSubmitFilters: (withRefresh?: boolean) => void;
    resetFiltersWithGeo: () => void;
    removeFilter: (filter: IFilter) => void;
    removeGeo: (tips: TipItem[]) => void;
    customFilterBehavior: CustomFilterBehavior;
    isLoading: boolean;
    placeholders: DeveloperPromoPlaceholders;
}> = ({
    geoStore,
    count = 0,
    displayValues,
    filters,
    resetFilters,
    updateFilter,
    updateFewFilters,
    handleSubmitFilters,
    resetFiltersWithGeo,
    removeFilter,
    removeGeo,
    customFilterBehavior,
    isLoading,
    placeholders
}) => {
    const {
        areTipsExpanded,
        expandTips,
        areExtraFiltersVisible,
        showExtraFilters,
        hideExtraFilters
    } = useNewBuildingSearchFiltersUi();

    const [ queryDisplayValues, addQueryDisplayValue ] = useQueryDisplayValues(displayValues);

    const tipsVisibleInitial = React.useMemo(
        () => getTipsCollection({
            geoStore,
            filters,
            queryDisplayValues
        }).tipsCollection.length > 0,
        [ geoStore, filters, queryDisplayValues ]
    );

    const [ hasTips, setHasTips ] = useState<boolean>(tipsVisibleInitial);

    const handleTipsLengthUpdate = useCallback((length: number) => {
        setHasTips(length > 0);
    }, []);

    return (
        <>
            <NewNewBuildingSearchFilters {...{
                className: cn('filters'),
                geoStore,
                filters,
                updateFilter,
                updateFewFilters,
                submitFilters: handleSubmitFilters,
                resetFilters,
                areExtraFiltersVisible,
                showExtraFilters,
                hideExtraFilters,
                queryDisplayValues,
                addQueryDisplayValue,
                customFilterBehavior,
                count,
                placeholders,
                isLoading
            }} />
            <div className={cn('tipsContainer', { expanded: hasTips })}>
                <div className={cn('tipsPopup', { expanded: areTipsExpanded })}>
                    <div className={cn('tipsLimiter')}>
                        <OffersSearchTipsWitchErrorCatcher {...{
                            filters,
                            geoStore,
                            removeGeo,
                            updateFilter,
                            removeFilter,
                            queryDisplayValues,
                            customFilterBehavior,
                            expandFilter: expandTips,
                            reset: resetFiltersWithGeo,
                            onUpdateLength: handleTipsLengthUpdate
                        }} />
                    </div>
                </div>
            </div>
        </>
    );
};
