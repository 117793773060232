export type Quarter = {
    quarter: number;
    year: number;
    key: string;
};

const DEFAULT_QUARTERS_COUNT = 13;

/**
 * Возвращает указанное кол-во кварталов в виде объекта { quarter, year, key = quarter_year }
 * @param {number} quartersCount
 * @param {Date} [currentDate = new Date()]
 * @returns {{ quarter: number, year: number, key: string }[]}
 * @example
 * quarters(2, new Date('2020-10-10')) -> [ {
 *     quarter: 4,
 *     year: 2020,
 *     key: '4_2020'
 * }, {
 *     quarter: 1,
 *     year: 2021,
 *     key: '1_2021'
 * } ]
 */
export const quarters = (
    quartersCount: number = DEFAULT_QUARTERS_COUNT,
    currentDate: Date = new Date()
): Quarter[] => {
    const values = [];
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    for (let i = 0, month = currentMonth; i < quartersCount; i++, month += 3) {
        const quarter = Math.floor((month % 12) / 3) + 1;
        const year = currentYear + Math.floor(month / 12);

        values.push({ quarter, year, key: quarter + '_' + year });
    }

    return values;
};
