import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import type { SuggestItemDeveloper } from '@search/graphql-gateway/typings/schema-types';
import { newbuildingFilterSerializer } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterSerializer';
import {
    getInitialFilters,
    getInitialAdditionalFilters
} from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewBuildingFilterInitial';
import type { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import {
    DeveloperFilter,
    Filters,
    GeoFilter,
    IFilter,
    MapBoundsFilter,
    TransportLimitFilter,
    FlatsSpecialEventsFilter,
    OtherSpecialEventsFilter,
    MiscSpecialEventsFilter
} from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import type { TipItemBase } from '@search/vtbeco-frontend-core/view/tips/OffersSearchTips';
import type { Route } from '@search/router/src/Route';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';

import type { Shared } from '../../../../types/shared';

interface UseSearchFiltersProps {
    pageParams: INewBuildingSearchType;
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    onMap?: boolean;
}

export type CustomFilterBehavior = {
    developer: {
        value?: SuggestItemDeveloper;
        update: (value?: SuggestItemDeveloper) => void;
        reset: () => void;
    };
    flatsSpecialEvents: {
        update: (filter: FlatsSpecialEventsFilter | OtherSpecialEventsFilter) => void;
    };
    otherSpecialEvents: {
        update: (filter: FlatsSpecialEventsFilter | OtherSpecialEventsFilter) => void;
    };
    miscSpecialEvents: {
        update: (filter: MiscSpecialEventsFilter) => void;
    };
};

export const useSearchFilters = ({
    pageParams,
    route,
    onMap
}: UseSearchFiltersProps) => {
    const filters = useRef(getInitialFilters(pageParams));
    const closeDrawAreaRef = React.useRef(() => {});

    useEffect(() => {
        filters.current = getInitialFilters(pageParams);
    }, [ pageParams ]);

    const handleSubmitFilters = useCallback((savePaging?: boolean) => {
        const filterPageParams = newbuildingFilterSerializer
            .serialize(filters.current.getFilters()) as INewBuildingSearchType;

        route.push({
            ...filterPageParams,
            pageNumber: savePaging ? pageParams.pageNumber : 1,
            sort: pageParams.sort
        });
    }, [ pageParams.pageNumber, pageParams.sort, route ]);

    const resetFilters = useCallback((resetGeo: boolean = false) => {
        filters.current = getInitialAdditionalFilters(filters.current, resetGeo);

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const resetFiltersWithGeo = useCallback(() => {
        filters.current = getInitialAdditionalFilters(filters.current);

        filters.current.update(new MapBoundsFilter());
        closeDrawAreaRef.current();

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const resetFiltersExceptRegion = useCallback(() => {
        filters.current = getInitialFilters({
            region: pageParams.region,
            ...onMap ? { mapBounds: pageParams.mapBounds } : {},
            showHighlighted: pageParams.showHighlighted || false
        });

        handleSubmitFilters();
    }, [ handleSubmitFilters, onMap, pageParams.mapBounds, pageParams.region ]); // eslint-disable-line

    const updateFewFilters = useCallback((filtersToUpdate: IFilter[]) => {
        filtersToUpdate
            .reduce((acc, filter) => {
                let newFilters = acc.update(filter);

                if (filter.id === Filters.geo) {
                    newFilters = acc.update(new MapBoundsFilter());
                    closeDrawAreaRef.current();
                }

                return newFilters as NewbuildingFilterCollection;
            }, filters.current);

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const updateFilter = useCallback((filter: IFilter) => {
        if (filter.id === Filters.region) {
            filters.current.update(new GeoFilter());
            filters.current.update(new MapBoundsFilter());
            closeDrawAreaRef.current();
        }

        if (filter.id === Filters.geo) {
            filters.current.update(new MapBoundsFilter());
            closeDrawAreaRef.current();
        }

        filters.current.update(filter);

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const removeFilter = useCallback((filter: IFilter) => {
        filters.current.remove(filter);

        if (filter.id === Filters.geo || filter.id === Filters.mapBounds) {
            filters.current.update(new MapBoundsFilter());
            closeDrawAreaRef.current();
        }

        handleSubmitFilters();
    }, [ filters, handleSubmitFilters ]);

    const removeGeo = useCallback((tips: TipItemBase[]) => {
        const filterGeo = filters.current.get<GeoFilter>(Filters.geo);
        const transportLimitFilter = filters.current.get<TransportLimitFilter>(Filters.transportLimit);
        const developerFilter = filters.current.get<DeveloperFilter>(Filters.developer);

        const newGeoIds = new Set(filterGeo.geoIds);
        const metroIds = new Set(filterGeo.metroIds);
        const districtIds = new Set(filterGeo.districtIds);
        const addressIds = new Set(filterGeo.addressIds);
        const newBuildingIds = new Set(filterGeo.newBuildingIds);
        const { developerId } = developerFilter;

        for (const tip of tips) {
            if (tip.__typename === 'TipRouteMetro') {
                filters.current = filters.current.remove(transportLimitFilter);
            }

            newGeoIds.delete(tip.id as unknown as number);
            newGeoIds.delete(tip.geoId as unknown as number);

            if (typeof tip.id === 'number') {
                if (tip.id === developerId) {
                    filters.current = filters.current.remove(developerFilter);
                } else {
                    newGeoIds.delete(tip.id);
                    metroIds.delete(tip.id);
                    districtIds.delete(tip.id);
                    addressIds.delete(tip.id);
                    newBuildingIds.delete(tip.id);
                }
            }

            if (typeof tip.geoId === 'number') {
                newGeoIds.delete(tip.geoId);
                metroIds.delete(tip.geoId);
                districtIds.delete(tip.geoId);
                addressIds.delete(tip.geoId);
                newBuildingIds.delete(tip.geoId);
            }
        }

        filters.current
            .update(new GeoFilter(
                newGeoIds,
                [ ...metroIds ],
                [ ...districtIds ],
                [ ...addressIds ],
                [ ...newBuildingIds ]
            ))
            .update(new MapBoundsFilter());

        closeDrawAreaRef.current();

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateDeveloperFilter = useCallback((value?: any) => {
        updateFilter(new DeveloperFilter(value && value.data.id));
    }, [ updateFilter ]);

    const resetDeveloperFilter = useCallback(() => updateDeveloperFilter(), [ updateDeveloperFilter ]);

    const updateSpecialEventsFilter = useCallback((filter: FlatsSpecialEventsFilter | OtherSpecialEventsFilter) => {
        filters.current
            .update(new MiscSpecialEventsFilter())
            .update(filter);

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const updateMiscSpecialEventsFilter = useCallback((filter: MiscSpecialEventsFilter) => {
        filters.current
            .update(new FlatsSpecialEventsFilter())
            .update(new OtherSpecialEventsFilter())
            .update(filter);

        handleSubmitFilters();
    }, [ handleSubmitFilters ]);

    const customFilterBehavior: CustomFilterBehavior = {
        developer: {
            value: undefined,
            update: updateDeveloperFilter,
            reset: resetDeveloperFilter
        },
        flatsSpecialEvents: {
            update: updateSpecialEventsFilter
        },
        otherSpecialEvents: {
            update: updateSpecialEventsFilter
        },
        miscSpecialEvents: {
            update: updateMiscSpecialEventsFilter
        }
    };

    // eslint-disable-next-line complexity
    const isAdditionalFilterOrGeoSelected = useMemo((): boolean => {
        const {
            /* гео-фильтр */
            metroId, districtId, addressId, newBuildingId,
            /* доп. фильтры */
            /* Квартира */
            totalAreaRangeMin, totalAreaRangeMax,
            livingAreaRangeMin, livingAreaRangeMax,
            kitchenAreaRangeMin,
            floorsTotalMin, floorsTotalMax,
            floorMin, floorMax, floorFirst, floorLast,
            ceilingHeight,
            bathroom,
            balcony,
            /* ЖК */
            developerId,
            closedSales,
            buildingClass,
            parking,
            wallsType,
            apartments,
            standalone,
            concierge,
            closedArea,
            guardedArea,
            /* Акции и скидки */
            flatsSpecialEvents,
            otherSpecialEvents,
            miscSpecialEvents,
            /* Дополнительно */
            accreditedByVTB,
            law214,
            escrow,
            armyMortgage,
            installment,
            motherCapital
        } = newbuildingFilterSerializer
            .serialize(filters.current.getFilters()) as INewBuildingSearchType;

        return Boolean(
            metroId || districtId || addressId || newBuildingId ||
            totalAreaRangeMin || totalAreaRangeMax ||
            livingAreaRangeMin || livingAreaRangeMax ||
            kitchenAreaRangeMin ||
            floorsTotalMin || floorsTotalMax ||
            floorMin || floorMax || floorFirst || floorLast ||
            ceilingHeight ||
            bathroom ||
            balcony ||
            developerId ||
            closedSales ||
            buildingClass ||
            parking ||
            wallsType ||
            apartments ||
            standalone ||
            concierge ||
            closedArea ||
            guardedArea ||
            flatsSpecialEvents || otherSpecialEvents || miscSpecialEvents ||
            accreditedByVTB ||
            law214 ||
            escrow ||
            armyMortgage ||
            installment ||
            motherCapital
        );
    }, []);

    return {
        filters: filters.current,
        handleSubmitFilters,
        resetFilters,
        resetFiltersWithGeo,
        resetFiltersExceptRegion,
        updateFewFilters,
        updateFilter,
        removeFilter,
        removeGeo,
        customFilterBehavior,
        closeDrawAreaRef,
        isAdditionalFilterOrGeoSelected
    };
};
