import { formatRange } from '@search/helpers/src/formatNumber';
import { FlatsSpecialEventString, IsApartmentsOptionsString, ParkingTypeString } from '@search/filter-enums/enums';
import { FinishingEnum } from '@search/filter-enums/gql/schema-types-fix';

const commissioningPrepositionalDict: Record<string, {
    singular: string;
    plural: string;
}> = {
    COMMISSIONED: {
        singular: 'сданном',
        plural: 'сданных'
    },
    IN_PROGRESS: {
        singular: 'строящемся',
        plural: 'строящихся'
    }
};

const commissioningQuarterDict: Record<string, string> = {
    FIRST: '1',
    SECOND: '2',
    THIRD: '3',
    FOURTH: '4'
};

const roomsDict: Record<string, {
    nominative: string;
    accusative: string;
    plural: string;
    genitivePlural: string;
}> = {
    STUDIO: {
        nominative: 'студия',
        accusative: 'студию',
        plural: 'студии',
        genitivePlural: 'студий'
    },
    ROOM_1: {
        nominative: 'однокомнатная квартира',
        accusative: 'однокомнатную квартиру',
        plural: 'однокомнатные квартиры',
        genitivePlural: 'однокомнатных квартир'
    },
    ROOM_2: {
        nominative: 'двухкомнатная квартира',
        accusative: 'двухкомнатную квартиру',
        plural: 'двухкомнатные квартиры',
        genitivePlural: 'двухкомнатных квартир'
    },
    ROOM_3: {
        nominative: 'трёхкомнатная квартира',
        accusative: 'трёхкомнатную квартиру',
        plural: 'трёхкомнатные квартиры',
        genitivePlural: 'трёхкомнатных квартир'
    },
    ROOM_4: {
        nominative: 'четырёхкомнатная квартира',
        accusative: 'четырёхкомнатную квартиру',
        plural: 'четырёхкомнатные квартиры',
        genitivePlural: 'четырёхкомнатных квартир'
    },
    ROOM_5_AND_MORE: {
        nominative: 'квартира с\u00A0пятью и\u00A0более комнатами',
        accusative: 'квартиру с\u00A0пятью и\u00A0более комнатами',
        plural: 'квартиры с\u00A0пятью и\u00A0более комнатами',
        genitivePlural: 'квартир с\u00A0пятью и\u00A0более комнатами'
    },
    OPEN_PLAN: {
        nominative: 'квартира со\u00A0свободной планировкой',
        accusative: 'квартиру со\u00A0свободной планировкой',
        plural: 'квартиры со\u00A0свободной планировкой',
        genitivePlural: 'квартир со\u00A0свободной планировкой'
    }
};

const apartmentsRoomsDict: Record<string, {
    nominative: string;
    accusative: string;
    plural: string;
    genitivePlural: string;
}> = {
    STUDIO: {
        nominative: 'апартаменты',
        accusative: 'апартаменты',
        plural: 'апартаменты',
        genitivePlural: 'апартаментов'
    },
    ROOM_1: {
        nominative: 'однокомнатные апартаменты',
        accusative: 'однокомнатные апартаменты',
        plural: 'однокомнатные апартаменты',
        genitivePlural: 'однокомнатных апартаментов'
    },
    ROOM_2: {
        nominative: 'двухкомнатные апартаменты',
        accusative: 'двухкомнатные апартаменты',
        plural: 'двухкомнатные апартаменты',
        genitivePlural: 'двухкомнатных апартаментов'
    },
    ROOM_3: {
        nominative: 'трёхкомнатные апартаменты',
        accusative: 'трёхкомнатные апартаменты',
        plural: 'трёхкомнатные апартаменты',
        genitivePlural: 'трёхкомнатных апартаментов'
    },
    ROOM_4: {
        nominative: 'четырёхкомнатные апартаменты',
        accusative: 'четырёхкомнатные апартаменты',
        plural: 'четырёхкомнатные апартаменты',
        genitivePlural: 'четырёхкомнатных апартаментов'
    },
    ROOM_5_AND_MORE: {
        nominative: 'апартаменты с\u00A0пятью и\u00A0более комнатами',
        accusative: 'апартаменты с\u00A0пятью и\u00A0более комнатами',
        plural: 'апартаменты с\u00A0пятью и\u00A0более комнатами',
        genitivePlural: 'апартаментов с\u00A0пятью и\u00A0более комнатами'
    },
    OPEN_PLAN: {
        nominative: 'апартаменты со\u00A0свободной планировкой',
        accusative: 'апартаменты со\u00A0свободной планировкой',
        plural: 'апартаменты со\u00A0свободной планировкой',
        genitivePlural: 'апартаментов со\u00A0свободной планировкой'
    }
};

const euroPlanningDict: Record<string, {
    nominative: string;
    accusative: string;
    plural: string;
    genitivePlural: string;
}> = {
    ROOM_1: {
        nominative: 'евродвушка',
        accusative: 'евродвушку',
        plural: 'евродвушки',
        genitivePlural: 'евродвушек'
    },
    ROOM_2: {
        nominative: 'евротрёшка',
        accusative: 'евротрёшку',
        plural: 'евротрёшки',
        genitivePlural: 'евротрёшек'
    }
};

const finishingDict: Record<string, string> = {
    AVAILABLE: 'с\u00A0отделкой',
};

const finishingTypeDict: Record<string, string> = {
    FINE: 'с\u00A0чистовой отделкой',
    WITH_FURNITURE: 'с\u00A0мебелью',
    WITHOUT_FINISHING: 'без\u00A0отделки'
};

const buildingClassDict: Record<string, string> = {
    ECONOMY: 'эконом-класса',
    COMFORT: 'комфорт-класса',
    COMFORT_PLUS: 'класса комфорт+',
    BUSINESS: 'бизнес-класса'
};

const buildingClassPrepositionalDict: Record<string, {
    masculine: string;
    feminine: string;
    plural: string;
}> = {
    ELITE: {
        masculine: 'элитном',
        feminine: 'элитной',
        plural: 'элитных'
    }
};

const wallsTypeDict: Record<string, {
    masculine: string;
    feminine: string;
    plural: string;
}> = {
    MONOLITH: {
        masculine: 'монолитном',
        feminine: 'монолитной',
        plural: 'монолитных'
    },
    BRICK_MONOLITH: {
        masculine: 'кирпично-монолитном',
        feminine: 'кирпично-монолитной',
        plural: 'кирпично-монолитных'
    },
    BRICK: {
        masculine: 'кирпичном',
        feminine: 'кирпичной',
        plural: 'кирпичных'
    },
    PANEL: {
        masculine: 'панельном',
        feminine: 'панельной',
        plural: 'панельных'
    },
    BLOCK: {
        masculine: 'блочном',
        feminine: 'блочной',
        plural: 'блочных'
    }
};

const floorLastDict: Record<string, string> = {
    ONLY: 'на\u00A0последнем этаже',
    EXCLUDE: 'не на\u00A0последнем этаже'
};

const apartmentsDict: Record<string, string> = {
    APARTMENTS_ONLY: 'апартаменты'
};

const parkingDict: Record<string, string> = {
    UNDERGROUND: 'с подземной парковкой',
    AVAILABLE: 'с парковкой'
};

// eslint-disable-next-line max-len
const flatsSpecialEventsDict: Record<FlatsSpecialEventString.FLAT_DISCOUNT | FlatsSpecialEventString.FLAT_SALES_START, string> = {
    [FlatsSpecialEventString.FLAT_DISCOUNT]: 'по\u00A0скидке',
    [FlatsSpecialEventString.FLAT_SALES_START]: 'на\u00A0старте продаж'
};

type ParamName = 'law214' | 'escrow' | 'deals' | 'armyMortgage' | 'installment' | 'closeToMetro' | 'closedArea' |
    'concierge' | 'bigKitchen' | 'ceilingHeight' | 'balcony' | 'motherCapital';

const paramDict: Record<ParamName, string> = {
    law214: 'со\u00A0сделкой по\u00A0214-ФЗ',
    escrow: 'со\u00A0сделкой по\u00A0эскроу-счёту',
    deals: 'со\u00A0сделками по\u00A0эскроу-счёту и\u00A0214-ФЗ',
    armyMortgage: 'по\u00A0военной ипотеке',
    installment: 'в\u00A0рассрочку',
    motherCapital: 'на\u00A0материнский капитал',
    closeToMetro: 'рядом с\u00A0метро',
    closedArea: 'закрытой территорией',
    concierge: 'консьержем',
    bigKitchen: 'большой кухней',
    ceilingHeight: 'высокими потолками',
    balcony: 'балконом'
};

export const seoTextDict = {
    makeRooms: (
        rooms: string,
        isCheap: boolean,
        isApartments: boolean
    ): string => isApartments ? (
        `${isCheap ? 'дешёвые ' : ''}${apartmentsRoomsDict[rooms]?.nominative ?? 'апартаменты'}`
    ) : (
        `${isCheap ? 'дешёвая ' : ''}${roomsDict[rooms]?.nominative ?? 'квартира'}`
    ),
    makeEuroPlanning: (
        rooms: string,
        isCheap: boolean,
    ): string => `${isCheap ? 'дешёвая ' : ''}${euroPlanningDict[rooms]?.nominative ?? 'квартира'}`,
    makeRoomsPlural: (
        rooms: string,
        isCheap: boolean,
        isApartments: boolean
    ): string => isApartments ? (
        `${isCheap ? 'дешёвые ' : ''}${apartmentsRoomsDict[rooms]?.plural ?? 'апартаменты'}`
    ) : (
        `${isCheap ? 'дешёвые ' : ''}${roomsDict[rooms]?.plural ?? 'квартиры'}`
    ),
    makeEuroPlanningPlural: (
        rooms: string,
        isCheap: boolean
    ): string => `${isCheap ? 'дешёвые ' : ''}${euroPlanningDict[rooms]?.plural ?? 'квартиры'}`,
    makeRoomsGenitivePlural: (
        rooms: string,
        isCheap: boolean,
        isApartments: boolean
    ): string => isApartments ? (
        `${isCheap ? 'дешёвых ' : ''}${apartmentsRoomsDict[rooms]?.genitivePlural ?? 'апартаментов'}`
    ) : (
        `${isCheap ? 'дешёвых ' : ''}${roomsDict[rooms]?.genitivePlural ?? 'квартир'}`
    ),
    makeEuroPlanningGenitivePlural: (
        rooms: string,
        isCheap: boolean
    ): string => `${isCheap ? 'дешёвых ' : ''}${euroPlanningDict[rooms]?.genitivePlural ?? 'квартир'}`,
    makeRoomsBuy: (
        rooms: string,
        isCheap: boolean,
        isApartments: boolean
    ): string => isApartments ? (
        `купить ${isCheap ? 'дешёвые ' : ''}${apartmentsRoomsDict[rooms]?.accusative ?? 'апартаменты'}`
    ) : (
        `купить ${isCheap ? 'дешёвую ' : ''}${roomsDict[rooms]?.accusative ?? 'квартиру'}`
    ),
    makeEuroPlanningBuy: (
        rooms: string,
        isCheap: boolean,
    ): string => `купить ${isCheap ? 'дешёвую ' : ''}${euroPlanningDict[rooms]?.accusative ?? 'квартиру'}`,
    makeNewBuilding: (
        commissioning: string,
        buildingClass: string,
        wallsType: string,
        isPlural: boolean = false,
        isExtra: boolean = false
    ): string => {
        const statusTexts = commissioningPrepositionalDict[commissioning];
        let commissioningPreText = '';
        let commissioningPostText = '';

        let isFeminine = true;

        if (statusTexts) {
            commissioningPreText = `${isPlural ? statusTexts.plural : statusTexts.singular} `;

            isFeminine = false;
        } else {
            const quarterYear = commissioning.split('_');

            if (quarterYear.length === 2) {
                const [ quarter, year ] = quarterYear;
                // @ts-ignore
                const quarterText = commissioningQuarterDict[quarter];

                if (quarterText && /^\d+$/.test(year)) {
                    commissioningPostText = `со\u00A0сроком сдачи до\u00A0${quarterText} квартала ${year} `;
                }
            }
        }

        const newBuildingText = `${
            // eslint-disable-next-line no-nested-ternary
            isFeminine ?
                // eslint-disable-next-line no-nested-ternary
                isPlural ?
                    'новостройках' :
                    isExtra ?
                        'новостройке (ЖК)' :
                        'новостройке' :
                isPlural ?
                    'домах' :
                    'доме'
        } `;

        const buildingClassText = buildingClassDict[buildingClass];
        let buildingClassPreText = '';
        let buildingClassPostText = '';

        if (buildingClassText) {
            buildingClassPostText = `${buildingClassText} `;
        } else {
            const buildingClassPrepositional = buildingClassPrepositionalDict[buildingClass];

            if (buildingClassPrepositional) {
                buildingClassPreText = `${
                    // eslint-disable-next-line no-nested-ternary
                    isPlural ? buildingClassPrepositional.plural :
                        isFeminine ? buildingClassPrepositional.feminine : buildingClassPrepositional.masculine
                } `;
            }
        }

        const wallsTypeText = wallsTypeDict[wallsType];
        let wallsTypePreText = '';

        if (wallsTypeText) {
            wallsTypePreText = `${
                // eslint-disable-next-line no-nested-ternary
                isPlural ? wallsTypeText.plural :
                    isFeminine ? wallsTypeText.feminine : wallsTypeText.masculine
            } `;
        }

        return `в\u00A0${
            commissioningPreText
        }${
            buildingClassPreText
        }${
            wallsTypePreText
        }${
            newBuildingText
        }${
            buildingClassPostText
        }${
            commissioningPostText
        }`.trim();
    },
    makeFinishing: (): string => finishingDict[FinishingEnum.Available],
    makeFinishingType: (finishingType: string): string => finishingTypeDict[finishingType] ?? '',
    makeFloorLast: (floorLast: string): string => floorLastDict[floorLast] ?? '',
    makeApartments: (apartments: IsApartmentsOptionsString): string => {
        return apartmentsDict[apartments] ?? '';
    },
    makeParking: (parking: Array<ParkingTypeString>): string => {
        if (parking.length === 3) {
            return parkingDict[ParkingTypeString.AVAILABLE];
        }

        if (parking.length === 1 && parking[0] === ParkingTypeString.UNDERGROUND) {
            return parkingDict[ParkingTypeString.UNDERGROUND];
        }

        return '';
    },
    // eslint-disable-next-line max-len
    makeFlatsSpecialEvents: (flatsSpecialEvents: Array<FlatsSpecialEventString.FLAT_DISCOUNT | FlatsSpecialEventString.FLAT_SALES_START>) => {
        return flatsSpecialEventsDict[flatsSpecialEvents[0]];
    },
    makePriceMax: (to: number): string => `${formatRange(null, to, {
        billionsAsString: true
    })}\u00A0₽`,
    makePricePerMeterMax: (to: number): string => `${formatRange(null, to, {
        billionsAsString: true
    })}\u00A0₽ за\u00A0квадратный\u00A0метр`,
    makeParam: (param: ParamName) => paramDict[param] ?? ''
};
