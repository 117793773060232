import React, { useContext, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import classname from '@search/classname/src';

import { SvgSpriteIcon } from '../SvgSpriteIcon';

import { GalleryContextState, GalleryContext } from './GalleryContext';
import CarouselGallery from './';

import './FSGallery.css';

const cn = classname.bind(null, 'FSGallery');

export const FSGallery: React.FunctionComponent = () => {
    const fsGalleryRef = useRef(null);
    const galleryContext = useContext(GalleryContext);
    const { title, elements = [], index, open } = useContext(GalleryContextState);

    const handleWindowKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            galleryContext!.close();
        }
    }, [ galleryContext ]);

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', handleWindowKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleWindowKeyDown);
        };
    }, [ open, handleWindowKeyDown ]);

    useLayoutEffect(() => {
        if (open) {
            // @ts-ignore
            disableBodyScroll(fsGalleryRef);
        } else {
            // @ts-ignore
            enableBodyScroll(fsGalleryRef);
        }

        return () => {
            // @ts-ignore
            enableBodyScroll(fsGalleryRef);
        };
    }, [ open ]);

    const handleClose = useCallback(galleryContext!.close, []); // eslint-disable-line

    if (! open) {
        return null;
    }

    return (
        <div
            className={cn()}
            ref={fsGalleryRef}
        >
            <CarouselGallery
                view='full'
                index={index}
                title={title}
                elements={elements}
            />
            <button
                className={cn('close')}
                onClick={handleClose}
            >
                <SvgSpriteIcon
                    name='CloseDesktop'
                    color='white'
                />
            </button>
        </div>
    );
};
