export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export enum View {
    mobile = 'mobile',
    desktop = 'desktop'
}

export type viewType = View.mobile | View.desktop;

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

/**
 * Возвращает общие свойства из двух типов.
 * @param T Первый тип
 * @param U Второй тип
 */
export type Shared<T, U> =
    & { [P in Extract<keyof T, keyof U>]?: T[P] extends U[P] ? U[P] : never }
    & { [P in Extract<keyof U, keyof T>]?: U[P] extends T[P] ? T[P] : never };

/**
 * Делаем все поля в объекте обязательынми
 */
export type Required<T> = {
    [P in keyof T]-?: T[P]
};
