/* global document */

export class AddScriptError extends Error {
    name = 'AddScriptError';
}

export function addScript(p: {
    src?: string;
    content?: string;
    id: string;
    timeout?: number;
    check?(v: HTMLScriptElement): boolean;
}) {
    return new Promise<HTMLScriptElement>((resolveRaw, reject) => {
        const timeout = p.timeout ?? 4000;
        let interval: ReturnType<typeof setInterval> | undefined;
        let el = document.getElementById(p.id) as HTMLScriptElement;

        const timer = setTimeout(() => {
            if (interval) clearInterval(interval);
            reject(new AddScriptError(`addScript id=${p.id}, src=${p.src}, timeout ${timeout} error`));
        }, timeout);

        const resolve = () => {
            const check = p.check;

            if (check && ! check(el)) {
                interval = setInterval(() => {
                    if (! check(el)) return;

                    if (interval) clearInterval(interval);
                    clearTimeout(timer);
                    resolveRaw(el);
                }, 500);

                return;
            }

            if (interval) clearInterval(interval);
            clearTimeout(timer);
            resolveRaw(el);
        };

        if (el) return resolve();
        el = document.createElement('script');

        el.id = p.id;
        el.onload = resolve;
        el.onerror = (
            // @ts-ignore
            event: Event | string,
            source?: string,
            lineno?: number,
            colno?: number,
            error?: Error
        ) => {
            const message = `Can't insert script into body:${
                source ? `${source}` : ''
            }${lineno ? ` ${lineno}: ${colno ?? 0}` : ''}`;

            if (! error) error = new AddScriptError(message);

            else error.message += '\n' + message;
            clearTimeout(timer);
            reject(error);
        };
        el.type = 'text/javascript';

        if (p.src) el.src = p.src;
        if (p.content) el.appendChild(document.createTextNode(p.content));

        document.body.appendChild(el);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        if (! p.src) Promise.resolve().then(resolve);
    });
}
