/* eslint-disable @typescript-eslint/naming-convention */
import { Invert, invert } from './invert';
import { SchemaTypeError } from './schema';
import { strToNumRequired } from './strToNum';

export class SeoMapper<
    SeoMap extends Record<string, string | number> = Record<
        string,
        string | number
    >
> {
    readonly seoMap: SeoMap;
    readonly typesMap: Invert<SeoMap>;
    readonly name: string;

    constructor(name: string, seo: SeoMap) {
        this.seoMap = seo;
        this.name = name;
        this.typesMap = invert(seo);
        this.values = Object.values(seo) as unknown as readonly (keyof Invert<SeoMap>)[];
    }

    toString() {
        return this.name;
    }

    get keys(): readonly (keyof SeoMap & string)[] {
        return Object.keys(this.seoMap);
    }

    readonly values: readonly (keyof Invert<SeoMap>)[]

    SeoType = (value: keyof SeoMap) => {
        // eslint-disable-next-line eqeqeq,no-eq-null
        if (this.seoMap[value] == null) throw new SchemaTypeError(`${value} not key of ${this}`);
        return value;
    };

    Type = (value: keyof Invert<SeoMap>) => {
        // eslint-disable-next-line eqeqeq,no-eq-null
        if (this.typesMap[value] == null) {
            throw new SchemaTypeError(`${value} not a value of ${this}`);
        }
        return value;
    };

    toEnums(
        orig: readonly (keyof SeoMap)[] | keyof SeoMap | undefined | null,
        seo?: keyof SeoMap | undefined | null
    ): readonly (keyof Invert<SeoMap>)[] {
        let result: (keyof SeoMap)[] = [];

        if (orig || orig === '') {
            result = Array.isArray(orig) ? [ ...orig ] : [ orig ];
        }

        if (result.length === 0 && seo) {
            result.push(seo);
        }

        return result.map(this.typeFromSeo);
    }

    toIds(
        orig: readonly string[] | string | undefined | null,
        seo?: string | undefined | null
    ): number[] {
        let result: string[] = [];

        if (orig || orig === '') {
            result = Array.isArray(orig) ? [ ...orig ] : [ orig ];
        }

        if (result.length === 0 && seo) {
            result.push(this.seoMap[seo] as string || seo);
        }

        return result.map(strToNumRequired);
    }

    hasType(value: string | number): boolean {
        return this.typesMap.hasOwnProperty(value as keyof Invert<SeoMap>);
    }

    typeFromSeo = (v: keyof SeoMap | string): keyof Invert<SeoMap> => {
        return this.seoMap[this.SeoType(v)];
    };

    typeFromSeoOptional = (v?: keyof SeoMap | string | null): keyof Invert<SeoMap> | undefined => {
        return v ? this.seoMap[v] : undefined;
    };

    hasSeo(value: unknown): value is keyof SeoMap {
        return this.seoMap.hasOwnProperty(value as keyof SeoMap);
    }

    seoFromType = (v: keyof Invert<SeoMap>): keyof SeoMap => {
        return this.typesMap[this.Type(v)];
    };

    seoFromTypeOptional = (v: number): keyof SeoMap | undefined => {
        return this.typesMap[v as keyof Invert<SeoMap>];
    };
}

export function queryParamtoArray<V>(
    orig: readonly V[] | V | undefined | null
): V[] {
    if (orig) return Array.isArray(orig) ? orig : [ orig ];
    return [];
}
