import * as React from 'react';
import { NewBuildingPoi } from '@search/graphql-typings';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { PoiKindToImageName } from '@search/vtbeco-frontend-core/view/common/components/YMap/YMapPOI/dict/poi';
import { PoiImageName, SvgSpriteIcon } from '../../components/SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'POISnippet');

const POISnippet: React.FunctionComponent<NewBuildingPoi> = ({
    name,
    kind,
    description,
    address,
    specialization,
    noctidial,
    category
}) => {
    const iconName = PoiKindToImageName[kind];

    if (! iconName) {
        return null;
    }

    return (
        <div className={cn()}>
            <div className={cn('icon')}>
                <SvgSpriteIcon
                    height={52}
                    width={52}
                    name={iconName as PoiImageName}
                />
            </div>
            {
                name && (
                    <Typography
                        bold
                        variant='secondary-alone'
                        className={cn('name')}
                    >
                        {name}
                    </Typography>
                )
            }
            {
                category && category !== name && (
                    <Typography
                        variant='small-alone'
                        color='secondary'
                        className={cn('category')}
                    >
                        {category}
                    </Typography>
                )
            }
            {
                address || noctidial || specialization || description ? (
                    <div className={cn('separator')} />
                ) : null
            }
            {
                address && (
                    <Typography
                        variant='small-alone'
                        className={cn('address')}
                    >
                        {address}
                    </Typography>
                )
            }
            {
                noctidial && (
                    <Typography
                        variant='small-alone'
                        className={cn('noctidial')}
                    >
                        Круглосуточно
                    </Typography>
                )
            }
            {
                specialization && (
                    <Typography
                        variant='small-alone'
                        color='secondary'
                        className={cn('specialization')}
                    >
                        {specialization}
                    </Typography>
                )
            }
            {
                description && (
                    <Typography
                        variant='small-alone'
                        color='secondary'
                        className={cn('description')}
                    >
                        {description}
                    </Typography>
                )
            }
        </div>
    );
};

POISnippet.displayName = 'POISnippet';

export default POISnippet;

