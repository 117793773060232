export class ErrorRender extends Error {
    name = ErrorRender.displayName

    static displayName = 'ErrorRender'

    static is(obj: Object): obj is ErrorRender {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (obj && (obj as any).name === this.displayName);
    }
}

export class ErrorNotFound extends Error {
    name = ErrorNotFound.displayName

    static displayName = 'ErrorNotFound';

    static is(obj: Object): obj is ErrorNotFound {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (obj && (obj as any).name === this.displayName);
    }
}
