import { useCallback, useEffect } from 'react';

import { useChatStateContext } from '../components/NewBuildingCard/ChatStateProvider';

declare const window: Window & {
    isChatScriptAdded?: boolean;
    usedeskMessenger?: {
        openChat: () => void;
        closeChat: () => void;
        sendMessage: (value: { message: string }) => void;
    };
};

declare const document: Document;

const CHAT_SRC = 'https://support.m2.ru/upload/chats/widget_1_13.js';

export const useChat = (hasChat?: boolean) => {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        wasOpened,
        setWasOpened,
        initialClientMessage
    } = useChatStateContext();

    useEffect(() => {
        if (window.isChatScriptAdded || ! hasChat) {
            return;
        }

        window.isChatScriptAdded = true;

        const chatScript = document.createElement('script');

        chatScript.src = CHAT_SRC;

        document.body.appendChild(chatScript);
    }, [ hasChat ]);

    const openChat = useCallback(() => {
        if (! wasOpened && initialClientMessage) {
            window?.usedeskMessenger?.sendMessage({ message: initialClientMessage });
            setWasOpened(true);
        } else {
            window?.usedeskMessenger?.openChat();
        }
    }, [ initialClientMessage, wasOpened, setWasOpened ]);

    return {
        openChat,
        closeChat: () => window?.usedeskMessenger?.closeChat()
    };
};
