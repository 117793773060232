import { useState, useRef, MutableRefObject } from 'react';
import useEventListener from './useEventListener';

const useFocusable = (): [boolean, MutableRefObject<HTMLElement | undefined>, (node: HTMLElement) => void] => {
    const elementRef = useRef<HTMLElement>();
    const [ isFocused, setFocused ] = useState(false);

    useEventListener('focus', () => {
        setFocused(true);
    }, elementRef);
    useEventListener('blur', () => {
        setFocused(false);
    }, elementRef);

    return [ isFocused, elementRef, node => {
        elementRef.current = node;
    } ];
};

export default useFocusable;
