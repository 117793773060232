export enum FlatsSpecialEvent {
    FLAT_DISCOUNT = 1,
    FLAT_INSTALLMENT_PLAN = 2,
    FLAT_SALES_START = 3,
}

export enum FlatsSpecialEventString {
    FLAT_DISCOUNT = 'FLAT_DISCOUNT',
    FLAT_INSTALLMENT_PLAN = 'FLAT_INSTALLMENT_PLAN',
    FLAT_SALES_START = 'FLAT_SALES_START',
}

export enum OtherSpecialEvent {
    OTHER_DISCOUNT = 1,
    OTHER_INSTALLMENT_PLAN = 2,
    OTHER_SALES_START = 3,
}

export enum OtherSpecialEventString {
    OTHER_DISCOUNT = 'OTHER_DISCOUNT',
    OTHER_INSTALLMENT_PLAN = 'OTHER_INSTALLMENT_PLAN',
    OTHER_SALES_START = 'OTHER_SALES_START',
}

export enum MiscSpecialEvent {
    GIFT = 1,
    // TRADE_IN = 2,
    PROMO = 3,
    // ARBITRARY = 4,
}

export enum MiscSpecialEventString {
    GIFT = 'GIFT',
    // TRADE_IN = 'TRADE_IN',
    PROMO = 'PROMO',
    // ARBITRARY = 'ARBITRARY',
}
