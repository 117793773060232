import React, { useEffect, useRef, useState } from 'react';

import classname from '@search/classname/src';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';

import { SvgSpriteIcon } from '../../SvgSpriteIcon';

import { GalleryElement } from '../index';

import './styles.css';

const cn = classname.bind(null, 'GalleryPreviews');

const GalleryPreviews: React.FunctionComponent<{
    elements: Array<GalleryElement>;
    currentIndex: number;
    onClickItem: (index: number) => void;
}> = ({
    elements,
    currentIndex,
    onClickItem
}) => {
    const [ shift, setShift ] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current && innerRef.current) {
            const diff = containerRef.current.offsetWidth - innerRef.current.offsetWidth;

            setShift(() => diff < 0 ? currentIndex * diff / (elements.length - 1) : 0);
        }
    }, [
        elements,
        currentIndex
    ]);

    return (
        <div
            ref={containerRef}
            className={cn()}
        >
            <div
                ref={innerRef}
                className={cn('container')}
                style={{ transform: `translateX(${shift}px)` }}
            >
                {
                    elements.map(({ galleryThumbnail, videoId }, idx) => (
                        <div
                            key={idx}
                            className={cn('item', { active: idx === currentIndex })}
                            onClick={() => onClickItem(idx)}
                        >
                            {
                                videoId ? (
                                    <div className={cn('videoThumbnail')}>
                                        <SvgSpriteIcon
                                            name='Play'
                                            color='#fff'
                                            className={cn('videoIcon')}
                                        />
                                        <div
                                            className={cn('videoImg')}
                                            style={{
                                                // eslint-disable-next-line max-len
                                                backgroundImage: `linear-gradient(0deg, rgba(116, 109, 147, 0.5), rgba(116, 109, 147, 0.5)), url(${galleryThumbnail})`
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Image
                                        type='cover'
                                        width={100}
                                        height={75}
                                        url={galleryThumbnail}
                                    />
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default GalleryPreviews;
