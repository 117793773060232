import { useEffect, useRef, useState } from 'react';
import isEqualWith from 'lodash/isEqualWith';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { SKIP_MARKERS_REPAINT_KEYS } from '../constants/map';

export function useMapLoading(pageParams: INewBuildingSearchType, loading: boolean) {
    const [ isLoading, setLoading ] = useState(false);
    const prevPageParams = useRef(pageParams);

    useEffect(() => {
        const isFiltersEqual = isEqualWith(pageParams, prevPageParams.current, (_value, _other, key) => {
            if (SKIP_MARKERS_REPAINT_KEYS.includes(key as string)) {
                return true;
            }

            return undefined;
        });

        if (loading && ! isFiltersEqual) {
            setLoading(true);
            prevPageParams.current = pageParams;
        } else if (! loading) {
            setLoading(false);
        }
    }, [ loading, pageParams ]);

    return isLoading;
}
