/* global ymaps */
/* eslint-disable max-len */
import './styles.css';
import { YMapControl } from '../Control';
import { Geo } from '../../Icon';

const baseClass = 'YMapControlButton';

export const YMapControlButtonDisabledClass = `${baseClass}_disabled_yes`;

export interface YMapControlButtonTemplateData {
    title: string;
}

export interface YMapControlButtonProps {
    title?: string;
    children: string;
    className?: string;
    onClick?: () => void;
    dataTest?: string;
    setFullscreen?: (isFullscreen: boolean) => void;
}

export function YMapControlButton({
    className,
    index,
    onClick
}: YMapControlButtonProps) {
    YMapControlButtonY.use({
        index,
        className,
        onClick
    } as YMapControlButtonProps);

    return null;
}

export function YMapControlButtonTemplate({
    children,
    className = '',
    title,
    dataTest = ''
}: YMapControlButtonProps) {
    return `<div${title ? ` title="${title}"` : ''}${dataTest ?
        ` data-test="${dataTest}"` : ''} class="${baseClass} ${className} ${baseClass}_size_m">
        ${children}
    </div>`;
}

export interface YMapControlButtonProps {
    className?: string;
    index?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class YMapControlButtonY<Props extends YMapControlButtonProps, Control> extends YMapControl<Props, ymaps.control.IButton> {
    protected create() {
        return new this.ymaps.control.Button({
            options: {
                layout: this.layoutClass()
            }
        }) as unknown as ymaps.control.IButton;
    }

    protected template() {
        const { props } = this;

        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            className: `${baseClass} ${props.className || ''}`,
            children: `${Geo.str({})}`
        });
    }
}
