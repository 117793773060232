import { WLNewBuildingCardRoute } from '@search/nb-routes/src/NewBuildingCardRoute';
import { WLNewBuildingSearchRoute } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { WLNewBuildingSearchMapRoute } from '@search/nb-routes/src/NewBuildingSearchMapRoute';
import { WLDeveloperRoute } from '@search/nb-routes/src/DeveloperRoute';
import { WLDeveloperSearchRoute } from '@search/nb-routes/src/DeveloperSearchRoute';

import NewBuildingSearchPageSeo from '../../../client/common/components/NewBuildingSearchPageSeo';
import NewBuildingSearchMapPageSeo from '../../../client/common/components/NewBuildingSearchMapPageSeo';
import NewBuildingCardPageSeo from '../../../client/common/components/NewBuildingCardPageSeo';
import DeveloperPageSeo from '../../../client/common/components/DeveloperPageSeo';
import DeveloperSearchPageSeo from '../../../client/common/components/DeveloperSearchPageSeo';

import NewBuildingSearchPage from '../../../client/desktop/components/NewBuildingSearchPage';
import NewBuildingSearchMapPage from '../../../client/desktop/components/NewBuildingSearchMapPage';
import NewBuildingCardPage from '../../../client/desktop/components/NewBuildingCardPage';
import { DeveloperPage } from '../../../client/desktop/components/DeveloperPage';
import { DeveloperSearchPage } from '../../../client/desktop/components/DeveloperSearchPage';

import '../components/common/styles.css';
import '../components/desktop/styles.css';

export const routes = [
    {
        type: 'NewBuildingCardRoute',
        route: WLNewBuildingCardRoute,
        Component: NewBuildingCardPage,
        SeoComponent: NewBuildingCardPageSeo
    },
    {
        type: 'NewBuildingSearchRoute',
        route: WLNewBuildingSearchRoute,
        Component: NewBuildingSearchPage,
        SeoComponent: NewBuildingSearchPageSeo
    },
    {
        type: 'NewBuildingSearchMapRoute',
        route: WLNewBuildingSearchMapRoute,
        Component: NewBuildingSearchMapPage,
        SeoComponent: NewBuildingSearchMapPageSeo
    },
    {
        type: 'DeveloperRoute',
        route: WLDeveloperRoute,
        Component: DeveloperPage,
        SeoComponent: DeveloperPageSeo
    },
    {
        type: 'DeveloperSearchRoute',
        route: WLDeveloperSearchRoute,
        Component: DeveloperSearchPage,
        SeoComponent: DeveloperSearchPageSeo
    }
] as const;
