import React from 'react';
import { BaseError } from '@search/error/src/BaseError';

import {
    BehaviorEvent, BehaviorEventBase, EventBus, SimpleEvent, SimpleEventBase
} from '@vtblife/event-bus';
import {
    AuthFinishedEventData, AuthFinishedEventType, AuthInitialize, AuthLoadedEventType, Subscription
} from '@vtblife/event-bus-events';

import { AuthInfo, authInfoDefault, AuthInfoAuthorized } from './AuthInfo';
import { usePromiseWaiter } from './usePromiseWaiter';
import { AuthDelayedPersistRunner } from './AuthDelayedPersistRunner';

export type UseAuthBusInit = Partial<AuthInitialize['data']> & {
    /**
     * Продолжить выполнять экшен после успешного логина
     */
    continueAfterLogin?: boolean;
};

export class UseAuthError extends BaseError {
    name = 'UseAuthError';
    static is(e: unknown): e is UseAuthError {
        return e instanceof UseAuthError ||
            (e && typeof e === 'object' && (e as Error).name === 'UseAuthError');
    }
}

export class UseAuthClosedError extends BaseError {
    name = 'UseAuthClosedError';
    static is(e: unknown): e is UseAuthClosedError {
        return e instanceof UseAuthClosedError ||
            (e && typeof e === 'object' && (e as Error).name === 'UseAuthClosedError');
    }
}

// const testCreds = {
//     isAuthenticated: true,
//     user: {
//         roles:['3'],
//         permissions:[
//             '/repair/repair/comment:create',
//             '/mortgage/mortgage:client_list',
//             '/repair/repair:read#client',
//             '/repair/repair/comment:create#client',
//             '/repair-document/contract:read#client',
//             '/auth/password:change',
//             '/mortgage/mortgage:read',
//             '/repair/repair/comment:read#client',
//             '/repair-document/contract:read',
//             '/mortgage/mortgage:create','/repair/repair/comment:read','/ui/services:read','/classified/favourites:create','/offer/placement:create',
//             '/mortgage/mortgage:update','/otp/session:verify','/otp/session:send_code'
//         ],
//         userId: '4cefcbfb-5218-4753-b5dc-3ae94639c4fc',
//         currentRole: '3',
//         currentRoleType:'CLIENT',
//         companyId: null
//     }
// };

export function useAuth({
    authInitial = authInfoDefault,
    enableLogging = true,
    withRootAuth = false
}: {
    authInitial?: AuthInfo;
    enableLogging?: boolean;
    withRootAuth?: boolean;
} = {}) {
    const loadedSubRef = React.useRef<Subscription>();
    const waiter = usePromiseWaiter<AuthInfoAuthorized>();
    const stateRef = React.useRef({ auth: authInitial, isAuthDialogOpened: false });
    const [ , setCounter ] = React.useState(0);

    const { eventBus, publish } = React.useMemo(() => {
        const instance = EventBus.getInstance();

        instance.setOptions({ enableLogging });

        const publishers = [] as (SimpleEventBase | BehaviorEventBase)[];

        // eslint-disable-next-line no-shadow
        const publishCb = (e: SimpleEventBase | BehaviorEventBase) => {
            if (withRootAuth) {
                instance.publish(e);

                return;
            }

            publishers.push(e);

            if (loadedSubRef.current) return;

            loadedSubRef.current = instance.subscribe<AuthLoadedEventType, undefined>('auth:loaded', () => {
                let data: typeof publishers[number] | undefined;

                // eslint-disable-next-line no-cond-assign
                while (data = publishers.pop()) {
                    instance.publish(data);
                }
            });
        };

        return { eventBus: instance as Omit<EventBus, 'publish'>, publish: publishCb };
    }, [ withRootAuth, enableLogging ]);

    const authFinished = React.useCallback((
        e: SimpleEvent<AuthFinishedEventType, AuthFinishedEventData>
        | BehaviorEvent<AuthFinishedEventType, AuthFinishedEventData>
    ) => {
        const next: AuthInfo = e.data.user && e.data.isAuthorized ? {
            isAuthenticated: e.data.isAuthorized,
            user: e.data.user
        } : authInfoDefault;

        stateRef.current.auth = next;
        stateRef.current.isAuthDialogOpened = false;
        if (next.isAuthenticated) {
            waiter.resolve(next);
        } else {
            waiter.reject();
        }
        setCounter(p => p + 1);
    }, [ waiter ]);

    React.useEffect(() => {
        const sub = eventBus.subscribe<AuthFinishedEventType, AuthFinishedEventData>('auth:finished', authFinished);

        // eslint-disable-next-line no-undef
        let timer: NodeJS.Timeout | undefined | null;

        const cb = async () => {
            if (! stateRef.current.auth.isAuthenticated) {
                if (timer !== null) timer = setTimeout(cb, 1000);
                return;
            }

            timer = null;
            try {
                await AuthDelayedPersistRunner.instance.run();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
            timer = undefined;
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        cb();

        return () => {
            if (timer) clearTimeout(timer);
            loadedSubRef.current?.unsubscribe();
            sub.unsubscribe();
        };
    }, [ eventBus, waiter ]);

    const waitAuth = React.useCallback(<Result>(cb: (auth?: AuthInfo) => Promise<Result>, {
        flow = 'authorization',
        phone,
        email,
        isProfessional = false,
        isProfessionalDisabled = true,
        isRegistrationPartnerHidden = true,
        isLoginPartnerHidden = true,
        shouldSubmitLogin = true,
        continueAfterLogin = false,
        modalTitle = '',
        modalSubtitle = ''
    }: UseAuthBusInit = {}) => {
        const auth = stateRef.current.auth;

        const onError = (error: Error): Promise<Result> => {
            if (! UseAuthError.is(error)) throw error;

            let promise = waiter.promise;

            if (promise && continueAfterLogin) return promise.then(cb).catch(onError);

            const event: AuthInitialize = {
                type: 'auth:initialize',
                data: {
                    flow,
                    phone,
                    email,
                    isProfessional,
                    isProfessionalHidden: true,
                    isProfessionalDisabled,
                    isRegistrationPartnerHidden,
                    isLoginPartnerHidden,
                    shouldSubmitLogin,
                    modalTitle,
                    modalSubtitle
                },
                category: 'simple'
            };

            publish(event);
            stateRef.current.isAuthDialogOpened = true;
            setCounter(p => p + 1);

            promise = waiter.create();
            if (! continueAfterLogin) throw error;

            return promise.then(cb).catch(onError);
        };

        return cb(auth).catch(onError);
    }, [ publish, waiter ]);

    /**
    // Uncomment to test auth
    React.useEffect(() => {
        setTimeout(() => {
            const event: SimpleEvent<AuthFinishedEventType, AuthFinishedEventData> = {
                type: 'auth:finished',
                data: {
                    isAuthorized: true,
                    user: {
                        companyId: null,
                        currentRole: '3',
                        currentRoleType: 'CLIENT',
                        isAuthenticated: true,
                        permissions: [
                            '/repair/repair/comment:create',
                            '/deal-online/deals:create',
                            '/auth/password:change',
                            '/guarantee-deal/deals:confirm',
                            '/guarantee-deal/deals:read',
                            '/deal-online/rates:read',
                            '/repair/repair/comment:read#client',
                            '/repair-document/contract:read',
                            '/deal-online/deals:update',
                            '/mortgage/mortgage:create',
                            '/repair/repair/comment:read',
                            '/offer/placement:create',
                            '/mortgage/mortgage:client_list',
                            '/repair/repair:read#client',
                            '/repair/repair/comment:create#client',
                            '/repair-document/contract:read#client',
                            '/deal-online/deals:confirm', '/mortgage/mortgage:read',
                            '/guarantee-deal/deals:update',
                            '/deal-online/documents:create-upload-token',
                            '/ui/services:read',
                            '/classified/favourites:create',
                            '/guarantee-deal/deals:create',
                            '/mortgage/mortgage:update',
                            '/deal-online/documents:delete',
                            '/otp/session:verify',
                            '/deal-online/deals:read',
                            '/otp/session:send_code'
                        ],
                        roles: [ '3' ],
                        userId: '4cefcbfb-5218-4753-b5dc-3ae94639c4fc',
                        username: '79111111111'
                    }
                },
                category: 'simple'
            };

            eventBus.publish(event);
            console.log('mock auth');
        }, 7000);
    }, [ eventBus ]);
    /* */

    return {
        waitAuth,
        isAuthDialogOpened: stateRef.current.isAuthDialogOpened,
        auth: {
            ...stateRef.current.auth,
            // Нужно для определения юзера с ролью Realtor Employee Empowered для фильтра "Мои объявления"
            isRealtorEmployeeEmpowered: stateRef.current.auth.user?.currentRole === '37'
        },
        authScheduler: AuthDelayedPersistRunner.instance
    };
}
