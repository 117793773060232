export const DISCOUNT_ITEMS = [
    {
        iconName: 'Design',
        point: '10% на\u00A0дизайн-проект',
        code: 'QUIZ_REMONT'
    },
    {
        iconName: 'Inspection',
        point: '2\u00A0500\u00A0рублей на\u00A0проверку недвижимости',
        code: 'QUIZ_2500'
    }
];
