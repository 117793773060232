import React, { useContext } from 'react';
import { GlobalContext, IGlobalContext } from '../GlobalContext';

interface IMeta {
    title: string;
    canonical: string;
    description?: string;
    image?: string;
    imageWidth?: string;
    imageHeight?: string;
}

const OPEN_GRAPH_LOGO_URL = 'https://cdn.m2.ru/assets/images/open-graph-logo.png';
const NEW_LOGO_URL = 'https://cdn.m2.ru/assets/file-upload-server/e75bd3134d53d8430106d76649a99b77.jpeg'; // NB-5678

export const Meta: React.FC<IMeta> = ({
    title,
    canonical,
    description,
    image = OPEN_GRAPH_LOGO_URL,
    imageWidth,
    imageHeight
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { hasOpenGraph } = globalContext.instanceConfig;

    return (
        <>
            <link rel='canonical' href={canonical} />
            <title>{title}</title>

            {description ? <meta name='description' content={description} /> : null}

            {hasOpenGraph ? <>
                <meta property='og:type' content='website' />
                <meta property='og:locale' content='ru_RU' />
                <meta
                    property='og:site_name'
                    content='Метр Квадратный - поиск, проверка и безопасная сделка с недвижимостью!'
                />
                <meta name='og:title' content={title} />
                {description ? <meta name='og:description' content={description} /> : null}
                <meta name='og:url' content={canonical} />
                <meta property='og:image' content={image === OPEN_GRAPH_LOGO_URL ? NEW_LOGO_URL : image} />

                {imageWidth ? <meta property='og:image:width' content={imageWidth} /> : null}
                {imageHeight ? <meta property='og:image:height' content={imageHeight} /> : null}

                <meta name='twitter:card' content='summary' />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={image === OPEN_GRAPH_LOGO_URL ? NEW_LOGO_URL : image} />
                <meta name='twitter:url' content={canonical} />
            </> : null}
        </>
    );
};
