import { useContext } from 'react';

import { Route } from '@search/router/src';
import type { IDeveloperSearchType } from '@search/nb-routes/src/DeveloperSearchRoute';
import { makeBaseSegments } from '@search/nb-texts/src/makeSegments';

import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import { Shared } from '../../../../types/shared';
import { emojiDict } from '../../../../dict/breadcrumbs';

import { GlobalContext, IGlobalContext } from '../GlobalContext';

type Breadcrumb = {
    content: string;
    href: string;
    active?: boolean;
};

const useBreadcrumbs = ({
    hasBreadcrumbs,
    route,
    pageParams
}: {
    hasBreadcrumbs?: boolean;
    route: Route<IDeveloperSearchType, Shared.IRouterContext, {}>;
    pageParams: IDeveloperSearchType;
}): {
    items: Breadcrumb[];
    rdfMeta: string;
} | undefined => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);

    if (! hasBreadcrumbs) {
        return;
    }

    const { urls } = makeBaseSegments({
        route,
        pageParams
    });

    const breadcrumbs = [
        {
            content: 'Главная',
            href: '/'
        }
    ] as Breadcrumb[];

    const itemListElement: ListItem[] = [
        {
            '@type': 'ListItem',
            position: 1,
            name: 'Главная',
            item: '/'
        }
    ];

    const { locative } = pageParams.region &&
        globalContext.rootRegions.getById(Number(pageParams.region)) ||
        {};

    if (urls.length >= 2 && locative) {
        breadcrumbs.push({
            content: `Новостройки ${locative}`,
            href: urls[urls.length - 2]
        });

        itemListElement.push({
            '@type': 'ListItem',
            position: itemListElement.length + 1,
            name: `Новостройки ${locative}`,
            item: urls[urls.length - 2]
        });
    }

    breadcrumbs.push({
        content: 'Застройщики',
        href: urls[urls.length - 1]
    });

    itemListElement.push({
        '@type': 'ListItem',
        position: itemListElement.length + 1,
        name: `${String.fromCodePoint(emojiDict.DEVELOPER)} Застройщики`,
        item: urls[urls.length - 1]
    });

    breadcrumbs[breadcrumbs.length - 1].active = true;

    const breadcrumbsListMeta: WithContext<BreadcrumbList> = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement
    };

    return {
        items: breadcrumbs,
        rdfMeta: JSON.stringify(breadcrumbsListMeta)
    };
};

export default useBreadcrumbs;
