import React, { useContext } from 'react';
import flow from 'lodash/flow';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import { CommissioningEnum, NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';

import {
    mskOblMetroIds,
    mskMetroIds,
    mskMetroStation
} from '@search/offer-search-routes/src/mappers/regions/msk/mskMetroMapper';
import { mskOblDistrictIds, mskDistrictIds } from '@search/offer-search-routes/src/mappers/regions/msk/mskDistrictMapper';
import { mskOblCityIds, mskCityIds } from '@search/offer-search-routes/src/mappers/regions/msk/mskCityMapper';
import { mskOblRayonIds, mskRayonIds } from '@search/offer-search-routes/src/mappers/regions/msk/mskRayonMapper';

import { lenOblMetroIds, spbMetroIds } from '@search/offer-search-routes/src/mappers/regions/spb/spbMetroMapper';
import { lenOblDistrictIds, spbDistrictIds } from '@search/offer-search-routes/src/mappers/regions/spb/spbDistrictMapper';
import { lenOblCityIds, spbCityIds } from '@search/offer-search-routes/src/mappers/regions/spb/spbCityMapper';

import { commissioningMapper, quarterMapper } from '@search/offer-search-routes/src/mappers/newBuilding/mappers';

import { RegionIdEnum } from '@search/vtbeco-frontend-core/enums';
import { seoLdEventScript } from '@search/vtbeco-frontend-core/view/common/components/Html/HtmlHead/ldEventScript';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';

import type { Shared } from '../../../../types/shared';

import { useSearchPage } from '../NewBuildingSearchPage/useSearchPage';
import {
    OnChangeGlobalContext,
    IOnChangeGlobalContext,
    GlobalContext,
    IGlobalContext
} from '../GlobalContext';
import { Meta } from '../Meta';
import { useNewBuildingsSummary } from '../NewBuildingSearchFAQ/useNewBuildingsSummary';
import useBreadcrumbs from '../NewBuildingSearchPage/useBreadcrumbs';

import useSEOTexts from './useSEOTexts';

const SEARCH_SCHEMA_EVENTS: Parameters<typeof seoLdEventScript>[0][] = [
    {
        actionName: 'Обмен на новую квартиру',
        description: 'Оцените и обменяйте свою квартиру на новую по программе «Трейд‑ин»'
    },
    {
        actionName: 'Ипотека со скидкой до 0,5%',
        actionNamePrefix: '🔥 ',
        description: 'Экономия до 0,5% на ставке по ипотеке'
    },
    {
        actionName: 'Проверенные ЖК',
        actionNamePrefix: '✅ ',
        description: 'Единая база проверенных новостроек'
    }
];

const fixGeo = (params: INewBuildingSearchType): INewBuildingSearchType => {
    const {
        region: regionId,
        metroId,
        districtId
    } = params;

    // eslint-disable-next-line no-nested-ternary
    const metroIds: number[] = metroId ?
        Array.isArray(metroId) ?
            metroId :
            [ metroId ] :
        [];

    // eslint-disable-next-line no-nested-ternary
    const districtIds: number[] = districtId ?
        Array.isArray(districtId) ?
            districtId :
            [ districtId ] :
        [];

    const potentialRegionIds: (number | null)[] = [
        ...metroIds.map(id => {
            if (mskMetroIds.includes(id)) {
                return RegionIdEnum.MSK;
            }

            if (mskOblMetroIds.includes(id)) {
                return RegionIdEnum.MSK_OBL;
            }

            if (spbMetroIds.includes(id)) {
                return RegionIdEnum.SPB;
            }

            if (lenOblMetroIds.includes(id)) {
                return RegionIdEnum.LEN_OBL;
            }

            return null;
        }),
        ...districtIds.map(id => {
            if ([
                ...mskDistrictIds,
                ...mskCityIds,
                ...mskRayonIds
            ].includes(id)) {
                return RegionIdEnum.MSK;
            }

            if ([
                ...mskOblDistrictIds,
                ...mskOblCityIds,
                ...mskOblRayonIds
            ].includes(id)) {
                return RegionIdEnum.MSK_OBL;
            }

            if ([
                ...spbDistrictIds,
                ...spbCityIds
            ].includes(id)) {
                return RegionIdEnum.SPB;
            }

            if ([
                ...lenOblDistrictIds,
                ...lenOblCityIds
            ].includes(id)) {
                return RegionIdEnum.LEN_OBL;
            }

            return null;
        })
    ];

    if (! potentialRegionIds.length) {
        if (regionId === RegionIdEnum.MSK_AND_MSK_OBL) {
            return {
                ...params,
                region: RegionIdEnum.MSK
            };
        }

        if (regionId === RegionIdEnum.SPB_AND_LEN_OBL) {
            return {
                ...params,
                region: RegionIdEnum.SPB
            };
        }

        return params;
    }

    if (potentialRegionIds.every(
        id => id !== null && id === potentialRegionIds[0]
    )) {
        return {
            ...params,
            region: potentialRegionIds[0]!
        };
    }

    return params;
};

const fixNumberInMetroName = (params: INewBuildingSearchType): INewBuildingSearchType => {
    const metroId = Array.isArray(params.metroId) ? params.metroId[0] : params.metroId;

    if (params.region !== RegionIdEnum.MSK ||
        ! metroId ||
        Array.isArray(metroId) && metroId.length > 1) {
        return params;
    }

    const metro = Object.entries(mskMetroStation)
        .find(([ , value ]) => value === metroId);

    if (! metro) {
        return params;
    }

    const newParams = { ...params };

    if (metro[0].match(/\d$/)) {
        let metroNameWithoutNumber = metro[0].slice(0, -1);

        if (metroNameWithoutNumber.match(/-$/)) {
            metroNameWithoutNumber = metroNameWithoutNumber.slice(0, -1);
        }

        const metroWithoutNumber = Object.entries(mskMetroStation)
            .find(([ key ]) => key === metroNameWithoutNumber);

        if (! metroWithoutNumber) {
            return params;
        }

        newParams.metroId = [ metroWithoutNumber[1] ];
    }

    return newParams;
};

const fixCommissioning = (params: INewBuildingSearchType): INewBuildingSearchType => {
    const { commissioning } = params;

    if (! commissioning || commissioningMapper.hasType(commissioning)) {
        return params;
    }

    const [ , year ] = commissioning.split('_');
    const fixedCommissioning = `${quarterMapper.seoFromType(4)}_${year}`;
    const validCommissioning = Object.values(CommissioningEnum);

    if (
        commissioning !== fixedCommissioning &&
        validCommissioning.includes(fixedCommissioning as unknown as CommissioningEnum)
    ) {
        return {
            ...params,
            commissioning: fixedCommissioning
        };
    }

    return params;
};

export const makeCanonicalUrlParams = (params: INewBuildingSearchType): INewBuildingSearchType => {
    return flow([ fixGeo, fixNumberInMetroName, fixCommissioning ])(params);
};

const NewBuildingSearchPageSeo = ({
    params,
    route
}: Shared.NewBuildingSearchPage) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const {
        hasDeveloperPromo,
        searchIndex,
        hasFavoritesButton
    } = globalContext.instanceConfig;

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);
    const showPromo = Boolean(hasDeveloperPromo) && ! hasM2Pro;

    const {
        data,
        geoStore,
        pageParams
    } = useSearchPage({
        params,
        promo: showPromo,
        promoQuiz: searchIndex === NewBuildingWhitelabelEnum.Vtb || showPromo,
        whiteLabel: searchIndex,
        skipFavorites: ! hasFavoritesButton
    });

    const newBuildingsSummary = useNewBuildingsSummary({
        pageParams,
        whiteLabel: searchIndex,
        tracingTag: 'NewBuildingSearchPageSeo'
    });

    const canonicalUrlParams = makeCanonicalUrlParams(pageParams);

    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const canonicalUrl = onChangeGlobalContext
        .linkBuilder
        .search(canonicalUrlParams).split('?')[0];

    const {
        title,
        heading,
        description
    } = useSEOTexts({
        route,
        pageParams,
        geoStore,
        total: data?.searchNewBuildings?.paging?.total ?? undefined
    });

    const {
        rating,
        reviewsCount
    } = data?.searchNewBuildings ?? {};

    const breadcrumbs = useBreadcrumbs({
        route,
        pageParams,
        displayValues: data?.searchNewBuildings?.tips,
        geoStore
    });

    const breadcrumbsMeta = breadcrumbs.rdfMeta;
    const { displayName } = globalContext.rootRegions.getById(pageParams.region) || {};

    const schemaEventScriptTagAttributes = SEARCH_SCHEMA_EVENTS.map(item => seoLdEventScript({
        ...item,
        url: canonicalUrl,
        location: {
            region: displayName ?? '',
            mainName: heading ?? '',
            latitude: undefined,
            longitude: undefined
        }
    }));

    return (
        <>
            <Meta
                title={title}
                description={description}
                canonical={canonicalUrl}
            />
            {breadcrumbsMeta ? (
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{ __html: breadcrumbsMeta }}
                />
            ) : null}
            {newBuildingsSummary ? (
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Product',
                            name: title,
                            description,
                            offers: {
                                '@type': 'AggregateOffer',
                                lowPrice: newBuildingsSummary.totalPriceMin,
                                highPrice: newBuildingsSummary.totalPriceMax,
                                priceCurrency: 'RUB',
                                offerCount: newBuildingsSummary.count,
                                image: 'https://cdn.m2.ru/assets/file-upload-server/e75bd3134d53d8430106d76649a99b77.jpeg' // NB-5678
                            },
                            ...(rating && reviewsCount ? {
                                aggregateRating: {
                                    '@type': 'AggregateRating',
                                    ratingValue: `${
                                        formatLocaleNumber(rating)
                                            .replace(',', '.')
                                    }`,
                                    reviewCount: `${reviewsCount}`,
                                    bestRating: '5'
                                }
                            } : {})
                        })
                    }}
                />
            ) : null}
            {schemaEventScriptTagAttributes.map(({ content, type }, idx) => content ? (
                <script
                    key={idx}
                    type={type}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            ) : null)}
        </>
    );
};

NewBuildingSearchPageSeo.displayName = 'NewBuildingSearchPageSeo';

export default NewBuildingSearchPageSeo;
