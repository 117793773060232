import React, { useCallback } from 'react';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { useFavoritesModal } from '@search/vtbeco-frontend-core/view/common/components/FavoritesButton/FavoritesModal';

import { useUpdateFavoriteOffer } from './useUpdateFavoriteOffer';
import { useUpdateFavoriteOfferMutation$variables as useUpdateFavoriteOfferMutationVariables } from './__generated__/useUpdateFavoriteOfferMutation.graphql';

export const useFavoriteOfferButton = ({
    isFavorite,
    offerId,
    addFavoriteOfferId,
    deleteFavoriteOfferId
}: useUpdateFavoriteOfferMutationVariables & {
    addFavoriteOfferId: (offerId: string) => void;
    deleteFavoriteOfferId: (offerId: string) => void;
}): (e: React.MouseEvent<Element, MouseEvent>) => void => {
    const favoritesMutation = useUpdateFavoriteOffer();
    const { setError } = useFavoritesModal();

    const updateFavorite = useCallback((next: Pick<useUpdateFavoriteOfferMutationVariables, 'isFavorite'>) => {
        setError(undefined);

        if (next.isFavorite) {
            dataLayerPush({
                event: NewBuildingAnalytics.OfferAddToFavorites,
                offerId
            });
        }

        favoritesMutation({
            isFavorite: next.isFavorite,
            offerId
        })
            .then(({ data }) => {
                const error = data?.editFavoritePersonal2.firstError;

                setError(error);

                if (! error) {
                    if (next.isFavorite) {
                        addFavoriteOfferId(offerId);
                    } else {
                        deleteFavoriteOfferId(offerId);
                    }
                }
            })
            .catch(e => {
                setError(e);
            });
    }, [
        offerId,
        addFavoriteOfferId,
        deleteFavoriteOfferId,
        favoritesMutation,
        setError
    ]);

    return useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();

        updateFavorite({
            isFavorite: ! isFavorite
        });
    }, [ updateFavorite, isFavorite ]);
};
