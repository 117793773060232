import { Close } from '../../Icon';
import { YMapControlButtonTemplate } from '../Button';
import { YMapControl, YMapControlProps } from '../Control';
// @ts-ignore
import { ymaps } from '../../ApiProvider';

interface YMapControlCloseProps extends YMapControlProps {
    onClose?: () => void;
}

const baseClass = 'YMapControlClose';

export function YMapControlClose({
    className,
    index,
    dataTest = 'ymap-close',
    onClose
}: YMapControlCloseProps) {
    YMapControlCloseY.use({
        index,
        dataTest,
        className,
        onClose
    } as YMapControlCloseProps);

    return null;
}

class YMapControlCloseY extends YMapControl<
    YMapControlCloseProps,
    ymaps.control.IButton
> {
    protected create() {
        return (new this.ymaps.control.Button({
            data: this.props,
            options: {
                layout: this.layoutClass()
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any) as unknown) as ymaps.control.IButton;
    }

    protected template() {
        const { props } = this;

        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            dataTest: props.dataTest,
            className: `${baseClass} ${props.className ?? ''}`,
            children: `${Close.str({})}`
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        return class YMapControlCloseLayout extends Layout {
            build() {
                super.build();
                const el = this.getElement().querySelector(`.${baseClass}`);

                if (! el) throw new Error(`element not found in ${el}`);
                const close = this.close.bind(this);

                el.addEventListener('click', close);
                const dispose = this.dispose;

                this.dispose = () => {
                    dispose();
                    el.removeEventListener('click', close);
                };
            }

            close() {
                const onClose = this.getData().data.get('onClose');

                if (onClose) onClose();
            }
        };
    }
}
