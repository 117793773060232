import React from 'react';

import { EventBus } from '@vtblife/event-bus';

import { GeoUserRegion } from './GeoUserRegion';

type EventBusUserRegionData = {
    userRegionId?: number;
    userRegionIdNarrow?: number;
    userRegionName?: string;
};

export function useGeoUserRegion() {
    const regionRef = React.useRef(new GeoUserRegion());

    const [ , setCount ] = React.useState(0);

    React.useEffect(() => {
        const eventBus = EventBus.getInstance();
        const subscription = eventBus.subscribe<'userRegion:change', EventBusUserRegionData>(
            'userRegion:change',
            event => {
                if (regionRef.current.userRegionId === event.data.userRegionId) return;
                const regionId = event.data.userRegionId ?? 3;

                regionRef.current.setUserRegionId(regionId);
                setCount(p => p + 1);
            }
        );

        return () => {
            subscription.unsubscribe();
        };
    }, [ ]);

    const setRegionId = React.useCallback((userRegionId: number) => {
        regionRef.current.setUserRegionId(userRegionId);

        // событие перезагрузит страницу из-за gatsby
        // const userRegionName = rootRegions.byId(userRegionId)?.displayName ?? 'Ваш регион';
        // const eventBus = EventBus.getInstance();
        // eventBus.publish({
        //     type: 'userRegion:change',
        //     data: {
        //         userRegionId,
        //         userRegionIdNarrow: undefined,
        //         userRegionName
        //     }
        // } as SimpleEvent<'userRegion:change', EventBusUserRegionData>);
    }, [ ]);

    return {
        setRegionId
    };
}

