export enum IsApartmentsOptions {
    UNKNOWN = 0,
    NO_APARTMENTS = 1,
    APARTMENTS_ONLY = 2,
}

export enum IsApartmentsOptionsString {
    UNKNOWN = 'UNKNOWN',
    NO_APARTMENTS = 'NO_APARTMENTS',
    APARTMENTS_ONLY = 'APARTMENTS_ONLY',
}
