import React, { useContext } from 'react';

import { RouteParams } from '@search/graphql-typings';
import { useGql, graphql } from '@search/gql-client/src/useGql';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';

import { Shared } from '../../../../types/shared';

import { OnChangeGlobalContext, IOnChangeGlobalContext, GlobalContext, IGlobalContext } from '../GlobalContext';
import { useSEOTexts } from '../SeoTexts/SEOTextsProvider';
import { useBreadcrumbs } from '../NewBuildingCardPage/useBreadcrumbs';
import { Meta } from '../Meta';

import { useEventsMeta } from './useEventsMeta';
import type {
    NewBuildingCardPageSeoQuery$variables as NewBuildingCardPageSeoQueryVariables, NewBuildingCardPageSeoQuery$data as NewBuildingCardPageSeoQueryResponse
} from './__generated__/NewBuildingCardPageSeoQuery.graphql';
import type {
    NewBuildingCardPageSeoOffersSummaryQuery$variables as NewBuildingCardPageSeoOffersSummaryQueryVariables, NewBuildingCardPageSeoOffersSummaryQuery$data as NewBuildingCardPageSeoOffersSummaryQueryResponse
} from './__generated__/NewBuildingCardPageSeoOffersSummaryQuery.graphql';

const seoExperimentIdsMsk = [ 433, 801, 241 ];
const seoExperimentIdsMO = [ 597 ];
const seoWithoutEventsIds = [ 6584 ];

export const cardQuery = graphql`
    query NewBuildingCardPageSeoQuery(
        $id: Int!
        $whiteLabel: NewBuildingWhitelabelEnum
    ) {
        newBuilding(
            id: $id
            whiteLabel: $whiteLabel
        ) {
            narrowRegionId(whiteLabel: $whiteLabel)
            narrowRegionLocative(whiteLabel: $whiteLabel)
            title {
                nominative
            }
            address
            coordinates
            gallery {
                main {
                    large
                }
            }
            seo(whiteLabel: $whiteLabel) {
                title,
                metaDescription
            }
            routeParams {
                id
                name
                type
                regionId
                region
                regionId
                subRegion
                subRegionDisplayName
            }
            rating
            reviewsCount
            specialEvents {
                title
                description {
                    short
                }
                metaStartDate
                metaEndDate
            }
        }
    }
`;

export const offersSummaryQuery = graphql`
    query NewBuildingCardPageSeoOffersSummaryQuery(
        $filters: ProfessionalSearchInput
    ) {
        searchOffersSummary(filters: $filters) {
            count
            priceRange {
                from
                to
            }
        }
    }
`;

const NewBuildingCardPageSeo = (
    props: Shared.NewBuildingCardPageProps
) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { instanceConfig: { searchIndex } } = globalContext;
    const id = Number(props.params.id);
    const { data } = useGql<NewBuildingCardPageSeoQueryVariables, NewBuildingCardPageSeoQueryResponse>(
        cardQuery, {
            id,
            whiteLabel: searchIndex
        }
    );

    const { data: offersSummaryData } = useGql<
        NewBuildingCardPageSeoOffersSummaryQueryVariables,
        NewBuildingCardPageSeoOffersSummaryQueryResponse
    >(
        offersSummaryQuery, {
            filters: {
                regionId: Number(data?.newBuilding?.routeParams.regionId),
                realtyObject: 'FLAT',
                dealType: 'SELL',
                newBuilding: [ 'NEW' ],
                newBuildingIds: [ id ]
            }
        }
    );

    const params = Object.assign({}, data!.newBuilding?.routeParams);

    const breadcrumbs = useBreadcrumbs({
        narrowRegionId: data!.newBuilding!.narrowRegionId,
        narrowRegionLocative: data!.newBuilding!.narrowRegionLocative,
        routeParams: data!.newBuilding!.routeParams as RouteParams,
        title: data!.newBuilding!.title.nominative
    });

    const breadcrumbsMeta = breadcrumbs.rdfMeta;
    const searchOffersSummary = offersSummaryData?.searchOffersSummary;

    const subRegion = seoExperimentIdsMO.includes(params.id) ? undefined : params.subRegion;

    let canonicalUrl = onChangeGlobalContext.linkBuilder.card({
        id: params.id,
        // @ts-ignore
        type: params.type,
        name: params.name,
        region: params.region,
        subRegion
    }).split('?')[0];

    if ([ ...seoExperimentIdsMsk, ...seoExperimentIdsMO ].includes(params.id)) {
        canonicalUrl = canonicalUrl.replace('novostroyki', 'nedvizhimost/kupit-kvartiru');
    }

    const {
        seo: { title, metaDescription },
        rating,
        reviewsCount
    } = data!.newBuilding!;
    const newBuildingTitle = data?.newBuilding?.title.nominative;

    let image = data?.newBuilding?.gallery?.main?.large;

    if (image && image.startsWith('//')) {
        image = `https:${image}`;
    }

    const { seoTexts } = useSEOTexts(canonicalUrl);

    const specialEventsMetaList = useEventsMeta({
        newBuildingTitle,
        newBuildingAddress: data?.newBuilding.address,
        newBuildingCoordinates: data?.newBuilding.coordinates ?? [],
        newBuildingImgUrl: image,
        url: canonicalUrl,
        specialEvents: data?.newBuilding.specialEvents ?? []
    });

    const isWithoutEvents = seoWithoutEventsIds.includes(id);

    return (
        <>
            <Meta
                title={seoTexts?.title ?? title}
                description={seoTexts?.description ?? metaDescription}
                canonical={canonicalUrl}
                image={image}
            />
            {
                breadcrumbsMeta ? (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{ __html: breadcrumbsMeta }}
                    />
                ) : null
            }
            {searchOffersSummary ? (
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Product',
                            brand: newBuildingTitle,
                            name: title,
                            description: metaDescription,
                            offers: {
                                '@type': 'AggregateOffer',
                                lowPrice: Number(searchOffersSummary.priceRange?.from),
                                highPrice: Number(searchOffersSummary.priceRange?.to),
                                priceCurrency: 'RUB',
                                offerCount: searchOffersSummary.count,
                                image
                            },
                            ...(rating && reviewsCount ? {
                                aggregateRating: {
                                    '@type': 'AggregateRating',
                                    ratingValue: `${
                                        formatLocaleNumber(rating)
                                            .replace(',', '.')
                                    }`,
                                    reviewCount: `${reviewsCount}`,
                                    bestRating: '5'
                                }
                            } : {})
                        })
                    }}
                />
            ) : null}
            {
                ! isWithoutEvents && specialEventsMetaList.map((eventMeta, idx) => (
                    <script
                        key={idx}
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{ __html: eventMeta }}
                    />
                ))
            }
        </>
    );
};

NewBuildingCardPageSeo.displayName = 'NewBuildingCardPageSeo';

export default NewBuildingCardPageSeo;
