import { useCallback, useState } from 'react';

export const useNewBuildingSearchFiltersUi = () => {
    const [ areTipsExpanded, expandTips ] = useState(false);

    const [ areExtraFiltersVisible, toggleExtraFilters ] = useState<boolean>(false);
    const showExtraFilters = useCallback(() => {
        toggleExtraFilters(true);
    }, []);
    const hideExtraFilters = useCallback(() => {
        toggleExtraFilters(false);
    }, []);

    return {
        areTipsExpanded,
        expandTips,
        areExtraFiltersVisible,
        showExtraFilters,
        hideExtraFilters
    };
};
