import React, { useCallback, useMemo, useState } from 'react';

import { Button, ButtonProps, Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import type { M2ProEvent } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/m2ProEvent';

import { DocumentsModal as DocumentsModalDesktop } from '../../../desktop/components/DocumentsModal';
import { DocumentsModal as DocumentsModalMobile } from '../../../mobile/components/DocumentsModal';
import type { DocumentType } from '../Document';

import './styles.css';

const cn = classname.bind(null, 'M2ProAttachedFilesButton');

const TITLE = 'Регламент и презентации';

const ModalTrigger = ({
    view,
    openModal,
    variant,
    size
}: {
    openModal: () => void;
    view: 'text' |'button';
} & Pick<ButtonProps, 'variant' | 'size'>) => {
    switch (view) {
        case 'button':
            return (
                <Button
                    size={size}
                    variant={variant}
                    onClick={openModal}
                    fullWidth
                >
                    {TITLE}
                </Button>
            );

        case 'text':
        default:
            return (
                <Typography
                    bold
                    variant='primary-alone'
                    onClick={openModal}
                    className={cn('link')}
                >
                    {TITLE}
                </Typography>
            );
    }
};

export type M2ProAttachedFilesButtonProps = {
    files: Array<DocumentType & { eventData: Pick<M2ProEvent, 'name'> }>;
    view: 'text' |'button';
    isMobile?: boolean;
    eventData?: Pick<M2ProEvent, 'page_type' | 'position' | 'modal_type'>;
} & Pick<ButtonProps, 'variant' | 'size'>;

export const M2ProAttachedFilesButton = ({
    files,
    isMobile,
    view,
    variant,
    size,
    eventData
}: M2ProAttachedFilesButtonProps) => {
    const [ isModalOpened, setModalOpened ] = useState<boolean>(false);

    const openModal = useCallback(() => setModalOpened(true), []);
    const closeModal = useCallback(() => setModalOpened(false), []);

    const handleOpenModal = useCallback(() => {
        openModal();

        dataLayerPush({
            event: 'M2Pro_rieltors',
            name: 'order_presentation',
            ...eventData
        } as M2ProEvent);
    }, [ openModal, eventData ]);

    const fileListWithClickEvent = useMemo(() => files.map(({ eventData: documentEventData, ...file }) => ({
        ...file,
        onClick: () => dataLayerPush({
            event: 'M2Pro_rieltors',
            ...documentEventData,
            ...eventData
        } as M2ProEvent)
    })), [ eventData, files ]);

    const DocumentsModal = isMobile ? DocumentsModalMobile : DocumentsModalDesktop;

    return (
        <>
            <ModalTrigger
                view={view}
                openModal={handleOpenModal}
                variant={variant}
                size={size}
            />
            {
                isModalOpened ? (
                    <DocumentsModal
                        title={TITLE}
                        documents={fileListWithClickEvent}
                        onClose={closeModal}
                    />
                ) : null
            }
        </>
    );
};
