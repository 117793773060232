/* eslint-disable complexity */
import React, { useCallback, useContext, useState } from 'react';
import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';

import { InspectionStatusEnum } from '@search/graphql-gateway/typings';
import {
    NewBuildingMortgageParams,
    NewBuildingSnippet as GQLNewBuildingSnippet,
    NewBuildingWhitelabelEnum,
    RouteMetro
} from '@search/graphql-typings';

import { Badge as DSBadge, Link, Typography } from '@vtblife/uikit';

import ButtonEco from '@search/vtbeco-ui/src/components/controls/Button/Button';
import { ToneEnum, ViewEnum } from '@search/vtbeco-ui/src/types';

import classname from '@search/classname/src';

import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { NewBuildingAnalytics } from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingAnalytics';
import { DATA_GTM, VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';
import SnippetPhone from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/SnippetPhone';
// import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { GalleryMobile2 } from '@search/vtbeco-frontend-core/view/common/components/gallery/GalleryMobile2/GalleryMobile2';
import { formatRange } from '@search/helpers/src/formatNumber';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';

import { Shared } from '../../../../types/shared';

import NewBuildingCardMortgageButton from '../../../mobile/components/NewBuildingCard/NewBuildingCardMortgageButton';
import NewBuildingCardMortgageModal from '../../../mobile/components/NewBuildingCard/NewBuildingCardMortgageModal';

import { useM2ProFiles } from '../../hooks/useM2ProFiles';

import Tab from '../Tab';
import { renderFlatStatus } from '../NewBuildingFlats';
import { NewBuildingSnippetSpecialEvents } from '../NewBuildingSnippetSpecialEvents';
import { GlobalContext } from '../GlobalContext';
import { useFavoriteNewBuildingButton } from '../FavoritesButton/useFavoriteNewBuildingButton';
import { MetroRouteWithSearchLink } from '../MetroRouteWithSearchLink';
import ChatButton from '../ChatButton';
import { M2ProOrderButton } from '../M2ProOrderButton';
import { M2ProAttachedFilesButton } from '../M2ProAttachedFilesButton';
import { SvgSpriteIcon } from '../SvgSpriteIcon';
import RewardAmountIcon from '../NewBuildingCard/NewBuildingCardOfferSearch/RewardAmountIcon';
import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../../constants/mortgage';

import { useNewBuildingSnippet } from './useNewBuildingSnippet';
import { useNewBuildingSnippetUrl } from './useNewBuildingSnippetUrl';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSnippet');

interface INewBuildingSnippet {
    newBuilding: GQLNewBuildingSnippet;
    view?: 'mobile' | 'onMap' | 'onMobileMap';
    pageParams?: Shared.ISearchParams;
    clearSnippetCache?: (refresh?: boolean) => void;
}

const NewBuildingSnippet: React.FunctionComponent<INewBuildingSnippet> = ({
    newBuilding,
    view = 'mobile',
    pageParams,
    clearSnippetCache
}) => {
    const isMobile = [ 'mobile', 'onMobileMap' ].includes(view);
    const isOnMap = [ 'onMap', 'onMobileMap' ].includes(view);
    const isOnMobileMap = view === 'onMobileMap';
    const {
        instanceConfig: {
            searchIndex,
            hasSpecialEvents,
            hasFavoritesButton
        },
        calltouchModId
    } = useContext(GlobalContext);
    const {
        isFrozen,
        routes,
        developer,
        pictures
    } = useNewBuildingSnippet(newBuilding);

    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const metro = routes?.length ? (routes[0] as RouteMetro) : undefined;

    const url = useNewBuildingSnippetUrl(newBuilding.routeParams, pageParams);

    const { auth } = useM2AuthContext();

    const rewardAmount = null;
    const { prepayment, cooperationTerms, nbPresentation } = newBuilding.m2Pro ?? {};
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType) && Boolean(rewardAmount);

    const m2ProFiles = useM2ProFiles({ cooperationTerms, nbPresentation });

    const priceInfo = [];

    if (newBuilding.priceMin) {
        priceInfo.push(
            <div
                key='priceMin'
                className={cn('section-item', { type: 'info' })}
            >
                <Typography
                    variant='secondary-alone'
                    bold
                >
                    {formatRange(newBuilding.priceMin, null, {
                        billionsAsString: true
                    })} ₽
                </Typography>
            </div>
        );
    }

    if (isVtb && isMobile) {
        priceInfo.push(
            <div
                key='vtb-mortgage'
                className={cn('section-item', { type: 'info' })}
            >
                <Typography variant='secondary-alone'>
                    Ипотека от {newBuilding.hasApartments ? VTB_MORTGAGE_RATE_APARTS : VTB_MORTGAGE_RATE_FLATS} % *
                </Typography>
            </div>
        );
    }

    const isImgTransparent = isFrozen || newBuilding.inspectionStatus?.value === InspectionStatusEnum.Failed;

    const { ranking } = newBuilding;
    const handlePhoneEventPush = useCallback(() => {
        dataLayerPush({
            event: NewBuildingAnalytics.NewBuildingShowPhone,
            'nb-id': newBuilding.id,
            'nb-name': newBuilding.title.nominative,
            'developer-name': developer?.title ?? '',
            ranking: ranking ? upperFirst(ranking.split('_').join(' ').toLowerCase()) : ''
        });
        dataLayerPush({
            event: 'nb_call_like',
            nb_price: newBuilding.priceMin ?? 0,
            nb_class: ranking ?? ''
        });
    }, [ newBuilding, developer, ranking ]);

    const isInspectionFailed = newBuilding.inspectionStatus?.value === InspectionStatusEnum.Failed;

    const buildingStatus = newBuilding.buildingStatus
        ?.filter(item => ! (isInspectionFailed && item.isFrozen)) ?? [];

    const [ isHover, setIsHover ] = useState(false);
    const hoverSnippet = () => setIsHover(true);
    const blurSnippet = () => setIsHover(false);

    const onFavoritesRequestSuccess = useCallback(() => {
        if (isOnMap && clearSnippetCache) {
            clearSnippetCache();
        }
    }, [ isOnMap, clearSnippetCache ]);

    const favoritesButtonState = useFavoriteNewBuildingButton({
        newBuildingId: newBuilding.id,
        isFavorite: newBuilding.isFavorite,
        onSuccess: onFavoritesRequestSuccess
    });

    const handleClick = useCallback(() => {
        window.open(url, '_blank');
    }, [ url ]);

    const getUrl = useClImageLazySrcSetUrl({ baseUrl: newBuilding.gallery?.baseUrl });

    const isPhoneRegion = newBuilding.isMskMO || newBuilding.isSPbLO || newBuilding.isSpecProjectRegion;

    const [ isOpenContactsModal, setIsOpenContactsModal ] = useState(false);
    const openMortgageModal = useCallback(() => setIsOpenContactsModal(true), []);
    const closeMortgageModal = useCallback(() => setIsOpenContactsModal(false), []);

    const renderButtons = () => {
        if (isPhoneRegion) {
            return developer ? (
                <div className={cn('section-item', { type: 'phone' })}>
                    <SnippetPhone
                        phoneParams={{
                            phoneNum: 0,
                            developerId: developer.id,
                            newBuildingId: Number(newBuilding.id),
                            whitelabel: searchIndex
                        }}
                        onPhoneEventPush={handlePhoneEventPush}
                        calltouchId={calltouchModId}
                        isMobile={isMobile}
                        onMap={view === 'onMap'}
                        fullWidth
                    />
                </div>
            ) : null;
        }

        return (
            <NewBuildingCardMortgageButton
                mortgageParams={newBuilding.mortgageParams}
                newBuildingName={newBuilding.title.nominative}
                size='s'
                openModal={openMortgageModal}
            />
        );
    };

    return (
        <div
            className={cn(null, { view })}
            onMouseEnter={hoverSnippet}
            onMouseLeave={blurSnippet}
        >
            <div
                onClick={handleClick}
                className={cn('galleryWrapper', {
                    frozen: isImgTransparent,
                    narrow: isOnMobileMap
                })}
            >
                <ImageLazySrcSetProvide getUrl={getUrl}>
                    <GalleryMobile2
                        isNewBuilding
                        key={newBuilding.id}
                        withNavButtons={! isMobile}
                        hovered={isHover}
                        pictures={pictures.map(pic => ({
                            originPath: pic.originPath,
                            description: pic.description ?? ''
                        }))}
                        renderBadges={isOnMobileMap ? undefined : <>
                            {newBuilding.buildingClass ? (
                                <DSBadge color='black'>
                                    {newBuilding.buildingClass.label}
                                </DSBadge>
                            ) : null}
                            {newBuilding.isSoldOut ? (
                                <DSBadge color='black'>
                                    Всё продано
                                </DSBadge>
                            ) : null}
                            {newBuilding.isSoon ? (
                                <DSBadge color='blue'>
                                    Скоро в продаже
                                </DSBadge>
                            ) : null}
                        </>}
                        renderSpecialEvents={
                            // eslint-disable-next-line no-nested-ternary
                            isOnMobileMap ? undefined : hasSpecialEvents ? (
                                <NewBuildingSnippetSpecialEvents
                                    specialEvents={newBuilding.specialEvents}
                                    hasPreferentialMortgage={newBuilding.hasPreferentialMortgage}
                                    isMobile={isMobile}
                                    withShortList
                                    inGallery
                                />
                            ) : newBuilding.hasPreferentialMortgage ? (
                                <DSBadge color='red'>
                                    Ипотека 6,5%
                                </DSBadge>
                            ) : null
                        }
                        onFavoriteClick={hasFavoritesButton ? favoritesButtonState.onClick : undefined}
                        isFavoriteActive={favoritesButtonState.isFavorite}
                        {
                            ...hasFavoritesButton ? {
                                showFavoritesButton: true,
                                favoritesButtonState
                            } : {}
                        }
                        {
                            ...isOnMobileMap ? {
                                noSlider: true,
                                withDots: false,
                                relativeHeight: true
                            } : {
                                maxPicturesCount: 8
                            }
                        }
                    />
                </ImageLazySrcSetProvide>
            </div>
            <div className={cn('section', { top: true })}>
                <div className={cn('header', { m2Pro: hasM2Pro })}>
                    <Link
                        href={url}
                        target='blank'
                    >
                        <Typography variant='h4'>{newBuilding.title.nominative}</Typography>
                    </Link>
                    {hasM2Pro && ! newBuilding.priceMin && (
                        <div>
                            {rewardAmount && (
                                <RewardAmountIcon amount={rewardAmount} />
                            )}
                            {prepayment && (
                                <SvgSpriteIcon
                                    name='M2ProPrepayment'
                                    width={24}
                                    height={24}
                                    className={cn('prepayment')}
                                />
                            )}
                        </div>
                    )}
                </div>
                {newBuilding.priceMin && (
                    <div className={cn('price')}>
                        {priceInfo}
                        {hasM2Pro && (
                            <div>
                                {rewardAmount && (
                                    <RewardAmountIcon amount={rewardAmount} />
                                )}
                                {prepayment && (
                                    <SvgSpriteIcon
                                        name='M2ProPrepayment'
                                        width={24}
                                        height={24}
                                        className={cn('prepayment')}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
                {newBuilding.developerLinks?.map((link, idx) => (
                    <div
                        key={idx}
                        className={cn('developerLink')}
                    >
                        <Link
                            href={link}
                            target='blank'
                        >
                            <Typography variant='secondary-alone'>
                                {`Перейти на сайт ${idx > 0 ? idx + 1 : ''}`}
                            </Typography>
                        </Link>
                    </div>
                ))}
                {hasM2Pro && m2ProFiles.length > 0 && (
                    <div className={cn('m2ProAttachedFilesButton')}>
                        <M2ProAttachedFilesButton
                            size='s'
                            variant='secondary'
                            view='button'
                            files={m2ProFiles}
                            isMobile={isMobile}
                            eventData={{ page_type: isOnMap ? 'map' : 'serp' }}
                        />
                    </div>
                )}
                {
                    ! isOnMobileMap && isVtb ? (
                        <div className={cn('section-item', { type: 'info-footer' })}>
                            <ButtonEco
                                type='link'
                                target='_blank'
                                href={`${VTB_IPOTEKA_LINK}?from=serp`}
                                tone={ToneEnum.PRIMARY}
                                view={ViewEnum.OUTLINED}
                                width='max'
                            >
                                Оформить заявку
                            </ButtonEco>
                        </div>
                    ) : null
                }
            </div>
            {
                // eslint-disable-next-line max-len
                ! isOnMobileMap && isVtb && newBuilding.buildingsAccreditedByVtb ? (
                    <div className={cn('section')}>
                        <Tab
                            className={cn('trigger')}
                            renderTitle={isOpen => (
                                <div data-gtm={isOpen ? null : DATA_GTM.SNIPPET_ACCREDITED}>
                                    <Typography variant='secondary-alone'>
                                        Аккредитован
                                    </Typography>
                                </div>
                            )}
                        >
                            <Typography
                                className={cn('section', { inlaid: true })}
                                variant='secondary-alone'
                                color='secondary'
                            >
                                {`Аккредитованные корпуса: ${
                                    newBuilding.buildingsAccreditedByVtb.join(', ')
                                }`}
                            </Typography>
                        </Tab>
                    </div>
                ) : null
            }
            {
                ! isOnMobileMap && Array.isArray(newBuilding.flats) && newBuilding.flats.length ? (
                    <div className={cn('section')}>
                        <Tab
                            className={cn('trigger')}
                            renderTitle={isOpen => (
                                <Typography
                                    variant='secondary-alone'
                                    data-gtm={isOpen ? null : DATA_GTM.SNIPPET_FLATS}
                                >
                                    Все квартиры
                                </Typography>
                            )}
                        >
                            {
                                newBuilding.flats
                                    .map(flat => (
                                        <div
                                            key={flat.rooms}
                                            className={cn('section', { inlaid: true }, cn('row'))}
                                        >
                                            <div className={cn('col')}>
                                                <Typography variant='secondary-alone'>
                                                    {flat.labelShort}
                                                </Typography>
                                                <Typography
                                                    variant='secondary-alone'
                                                    color='secondary'
                                                >
                                                    {flat.formatAreaRange}
                                                </Typography>
                                            </div>
                                            <div className={cn('col')}>
                                                <Typography
                                                    {...(flat.formatPriceRange ? {
                                                        className: cn('flatStatus')
                                                    } : {
                                                        level: 'secondary-alone'
                                                    })}
                                                    variant='secondary-alone'
                                                >
                                                    {renderFlatStatus(flat, true)}
                                                </Typography>
                                            </div>
                                        </div>
                                    ))
                            }
                        </Tab>
                    </div>
                ) : null
            }
            <div className={cn('section', { bottom: true })}>
                <div className={cn('section-item', { type: 'info' })}>
                    <List separator=' '>
                        {
                            buildingStatus.length ? (
                                <ListItem>
                                    {
                                        buildingStatus
                                            .map((item, i, statuses) => {
                                                let { title: text } = item;

                                                if (i !== 0) {
                                                    text = lowerFirst(text);
                                                }

                                                if (i !== statuses.length - 1) {
                                                    text = `${text}, `;
                                                }

                                                return (
                                                    <Typography
                                                        key={i}
                                                        variant='secondary-alone'
                                                        tag='span'
                                                        color={item.isFrozen ? 'red-500' : 'primary'}
                                                    >
                                                        {text}
                                                    </Typography>
                                                );
                                            })
                                    }
                                </ListItem>
                            ) : null
                        }
                        {
                            ! isOnMobileMap && newBuilding.inspectionStatus ? (
                                <ListItem key='inspectionStatus'>
                                    <Typography
                                        variant='secondary-alone'
                                        tag='span'
                                        className={cn('inspectionStatus', {
                                            type: newBuilding.inspectionStatus.value
                                        })}
                                    >
                                        {newBuilding.inspectionStatus.statusText}
                                    </Typography>
                                </ListItem>
                            ) : null
                        }
                    </List>
                </div>
                {
                    isOnMobileMap ? null : (
                        <div className={cn('section-item', { type: 'info' })}>
                            <Typography
                                variant='secondary-alone'
                                color='secondary'
                            >
                                {newBuilding.address}
                            </Typography>
                        </div>
                    )
                }
                {
                    metro ? (
                        <div className={cn('section-item', { type: 'info' })}>
                            <MetroRouteWithSearchLink
                                view='mobile'
                                metro={metro}
                                regionId={pageParams?.region}
                            />
                        </div>
                    ) : null
                }
                {
                    ! hasM2Pro ? renderButtons() : null
                }
                {
                    hasM2Pro && (
                        <div className={cn('buttons')}>
                            <M2ProOrderButton
                                newBuildingId={newBuilding.id}
                                size='s'
                                fullWidth
                                eventData={{ page_type: isOnMap ? 'map' : 'serp' }}
                            />
                            <ChatButton
                                variant='secondary'
                            />
                        </div>

                    )
                }
                {isOpenContactsModal && (
                    <NewBuildingCardMortgageModal
                        isSerp={view === 'mobile'}
                        isSerpMap={view !== 'mobile'}
                        onClose={closeMortgageModal}
                        mortgageParams={newBuilding.mortgageParams as NewBuildingMortgageParams}
                    />
                )}
            </div>
        </div>
    );
};

NewBuildingSnippet.displayName = 'NewBuildingSnippet';

export default NewBuildingSnippet;

