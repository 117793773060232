import React, { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { useYMapPane } from '../../../Pane';
import type { YMapVector } from '../../../Vector';

import { YMapPlacemarkZIndexes } from '../../Tooltip';

import { pickStartShowZoom as pickPinStartShowZoom } from '../Pin';

export interface YMapPlacemarkSubwayPimpleProps {
    coordinates: YMapVector;
    color: string;
    regionId: number;
}

const pickStartShowZoom = (regionId: number) => {
    switch (regionId) {
        case RegionIdEnum.MSK:
        case RegionIdEnum.MSK_OBL:
        case RegionIdEnum.MSK_AND_MSK_OBL:
            return 12;

        default:
            return 11;
    }
};

export function YMapPlacemarkSubwayPimple({
    coordinates,
    color,
    regionId
}: YMapPlacemarkSubwayPimpleProps) {
    const {
        map,
        api: {
            Placemark,
            templateLayoutFactory,
            geometry: { Point }
        }
    } = useYMapPane();

    const pimpleStartShowZoom = pickStartShowZoom(regionId);
    const pinStartShowZoom = pickPinStartShowZoom(regionId);

    const icon = (
        <svg
            height={8}
            width={8}
            color={color}
        >
            <use xlinkHref='#SubwayPimple' />
        </svg>
    );

    const iconTemplate = renderToStaticMarkup(icon);
    const iconContentLayout = templateLayoutFactory.createClass(iconTemplate);

    useEffect(() => {
        const pimple = new Placemark(
            new Point([ coordinates.y, coordinates.x ]),
            {},
            {
                // @ts-ignore
                iconLayout: 'default#imageWithContent',
                iconImageHref: '',
                iconContentLayout,
                iconImageSize: [ 8, 8 ],
                iconContentSize: [ 8, 8 ],
                iconImageOffset: [ -4, -4 ],
                cursor: 'arrow',
                ...YMapPlacemarkZIndexes.Background
            }
        );

        const zoom = Math.round(map.getZoom());

        if (zoom >= pimpleStartShowZoom && zoom < pinStartShowZoom) {
            map.geoObjects.add(pimple);
        }

        return () => map.geoObjects.remove(pimple);
    }, [
        map, coordinates.x, coordinates.y, templateLayoutFactory, Placemark, Point,
        iconContentLayout, pimpleStartShowZoom, pinStartShowZoom
    ]);

    return null;
}
