import React, { useContext } from 'react';
import { useRouter } from '@search/offer-search-routes/src/RouterProvider';

import type { Shared } from '../../../../types/shared';
import { useUserRegionUrlSwitch } from '../../hooks/useUserRegionUrlSwitch';
import { GlobalContext } from '../GlobalContext';

import './styles.css';

// nb release sp 117
const App = ({
    routes
}: {
    routes: Shared.IRoutes;
}) => {
    const {
        components: {
            NotFoundPage
        }
    } = useContext(GlobalContext);

    useUserRegionUrlSwitch();

    try {
        // @todo  Перестать использовать useRouter из offer-search-routes и сделать свой с правильным контекстом для новостроек
        // @ts-ignore
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const router: Shared.RouterType = useRouter();
        const { RouteClass } = router.resolve(routes.map(r => r.route));
        const matchedRoute = routes.find(({ route }) => route === RouteClass);

        switch (matchedRoute?.type) {
            case 'NewBuildingCardRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'NewBuildingConstructionRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'NewBuildingQuizRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute}
                    />
                );
            }

            case 'NewBuildingSearchRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'NewBuildingSearchMapRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'WLNewBuildingSearchMapRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'DeveloperRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'WLDeveloperRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'DeveloperSearchRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'WLDeveloperSearchRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'DeveloperAppLandingRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            case 'DeveloperLandingRoute': {
                const { Component, route } = matchedRoute;
                const currentRoute = router.route(route);
                const params = currentRoute.params();

                return (
                    <Component
                        router={router}
                        params={params}
                        route={currentRoute} />
                );
            }

            default:
                return <NotFoundPage />;
        }
    } catch (e) {
        if (e.name === 'SchemaVariantError') {
            return <NotFoundPage />;
        }

        throw e;
    }
};

export default App;
