import React from 'react';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { SeoInfoType } from '@search/nb-texts/src/makeSEOTexts';

import type { LinksCloudViewType } from '../NewBuildingSearchLinkCloud';
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions';

import { useNewBuildingsSummary } from './useNewBuildingsSummary';

export const NewBuildingSearchFAQ: React.FunctionComponent<{
    pageParams: INewBuildingSearchType;
    view: LinksCloudViewType;
    rating: number;
    seoInfo?: SeoInfoType;
    whiteLabel?: NewBuildingWhitelabelEnum;
}> = ({
    pageParams,
    view,
    seoInfo,
    whiteLabel = NewBuildingWhitelabelEnum.Default,
    rating
}) => {
    const newBuildingSummary = useNewBuildingsSummary({
        pageParams,
        whiteLabel,
        tracingTag: 'NewBuildingSearchFAQ'
    });

    if (
        [
            RegionIdEnum.MSK_AND_MSK_OBL,
            RegionIdEnum.SPB_AND_LEN_OBL
        ].includes(pageParams.region) || ! (
            pageParams.pageNumber === 1 &&
            newBuildingSummary &&
            newBuildingSummary.count >= 2 &&
            seoInfo &&
            whiteLabel === NewBuildingWhitelabelEnum.Default
        )
    ) {
        return null;
    }

    const qa: {
        q: string;
        a: string;
    }[] = [ {
        q: `Как ${
            seoInfo.toBuy
        } на\u00A0сайте m2.ru?`,
        a: `Ищете объявления о\u00A0продаже ${
            seoInfo.genitivePlural
        }? Воспользуйтесь фильтрами или поиском в\u00A0разделе.`
    }, {
        q: `Сколько ${
            seoInfo.isApartments ? 'стоят' : 'стоит'
        } ${
            seoInfo.nominative
        }?`,
        // eslint-disable-next-line max-len
        a: `Самый большой выбор объектов недвижимости с\u00A0разной стоимостью\u00A0— цены в\u00A0данной подборке от\u00A0${
            formatLocaleNumber(newBuildingSummary.totalPriceMin)
        } до\u00A0${
            formatLocaleNumber(newBuildingSummary.totalPriceMax)
        }\u00A0руб. Площадь составляет от\u00A0${
            formatLocaleNumber(newBuildingSummary.totalAreaMin)
        } до\u00A0${
            formatLocaleNumber(newBuildingSummary.totalAreaMax)
        }\u00A0кв.\u00A0м., цена квадратного метра\u00A0— от\u00A0${
            formatLocaleNumber(newBuildingSummary.pricePerMeterMin)
        } до\u00A0${
            formatLocaleNumber(newBuildingSummary.pricePerMeterMax)
        }\u00A0руб.`
    }, {
        q: 'Почему стоит искать недвижимость у\u00A0нас?',
        // eslint-disable-next-line max-len
        a: `Предложения на\u00A0m2.ru\u00A0— только от\u00A0официальных застройщиков. У\u00A0нас самый большой выбор ${
            seoInfo.genitivePlural
        }: в\u00A0разделе ${
            declensionByNumber(newBuildingSummary.count, [ 'размещён', 'размещено', 'размещено' ])
        } ${
            formatLocaleNumber(newBuildingSummary.count)
        }\u00A0ЖК${
            rating ? ` со\u00A0средним рейтингом ${formatLocaleNumber(rating)}` : ''
        }. Гарантия сделки: вернём полную стоимость недвижимости, если что-то пойдёт не\u00A0так.`
    } ];

    return (
        <FrequentlyAskedQuestions
            qa={qa}
            view={view}
        />
    );
};
