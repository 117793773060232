import React from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import { Typography } from '@vtblife/uikit';

import { ViewEnum, ToneEnum } from '@search/vtbeco-ui/src/types';
import Tooltip from '@search/vtbeco-ui/src/components/controls/Tooltip';
import classname from '@search/classname/src';

import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import { ArrayElement } from '../../../../types';
import { newBuildingSnippet } from '../NewBuildingSearch';
import { SpecialEventIconName, SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingSnippetSpecialEvents');

type SpecialEvents = newBuildingSnippet['specialEvents'];
type SpecialEvent = ArrayElement<SpecialEvents>;

const Item = ({
    specialEvent,
    isMobile = false
}: {
    specialEvent?: SpecialEvent;
    isMobile?: boolean;
}) => (
    <Tooltip
        tone={ToneEnum.DARK}
        view={ViewEnum.PRIMARY}
        directions={[ 'bottom-left', 'top-left' ]}
        renderTrigger={({ triggerRef, handleShow, handleClose }) => (
            <div
                className={cn('item', { preferentialMortgage: ! specialEvent })}
                ref={triggerRef}
                onClick={e => {
                    if (isMobile) {
                        return;
                    }

                    e.stopPropagation();
                    e.preventDefault();
                }}
                onMouseEnter={isMobile ? undefined : handleShow}
                onMouseLeave={isMobile ? undefined : handleClose}
            >
                {
                    specialEvent ? (
                        <SvgSpriteIcon name={upperFirst(camelCase(specialEvent.type)) as SpecialEventIconName} />
                    ) : (
                        <Typography
                            variant='secondary-alone'
                            color='white-500'
                        >
                            6,5%
                        </Typography>
                    )
                }
            </div>
        )}
        renderContent={() =>
            <div className={cn('itemTooltip')}>
                {
                    specialEvent ? (
                        <>
                            <CustomTypography
                                level='secondary-alone'
                                color='white-500'
                                bold
                                dangerouslySetInnerHTML={{
                                    __html: specialEvent.title
                                }}
                            />
                            <CustomTypography
                                className={cn('itemDesc')}
                                level='small-alone'
                                color='white-500'
                                dangerouslySetInnerHTML={{
                                    __html: specialEvent.description.short
                                }}
                            />
                        </>
                    ) : (
                        <Typography
                            variant='secondary-alone'
                            color='white-500'
                            bold
                        >
                            Ипотека от 6,5%
                        </Typography>
                    )
                }
            </div>
        }
    />
);

export const NewBuildingSnippetSpecialEvents = ({
    specialEvents,
    hasPreferentialMortgage,
    isMobile = false,
    withShortList = false,
    inGallery = false
}: {
    specialEvents: SpecialEvents;
    hasPreferentialMortgage: boolean;
    isMobile?: boolean;
    withShortList?: boolean;
    inGallery?: boolean;
}) => {
    if (! hasPreferentialMortgage && specialEvents.length === 0) {
        return null;
    }

    const content = (
        <>
            {
                hasPreferentialMortgage ? (
                    <Item isMobile={isMobile} />
                ) : null
            }
            {
                specialEvents
                    .slice(0,
                        // eslint-disable-next-line no-nested-ternary
                        isMobile || withShortList ?
                            hasPreferentialMortgage ? 3 : 4 :
                            hasPreferentialMortgage ? 5 : 6
                    )
                    .map((specialEvent, i) => (
                        <Item
                            key={i}
                            specialEvent={specialEvent}
                            isMobile={isMobile}
                        />
                    ))
            }
        </>
    );

    return inGallery ? content : (
        <div className={cn()}>
            {content}
        </div>
    );
};
