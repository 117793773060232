export enum CeilingHeight {
    UNKNOWN = 0,
    FROM_2_5 = 1,
    FROM_2_7 = 2,
    FROM_3 = 3,
    FROM_4 = 4,
}

export enum CeilingHeightString {
    FROM_2_5 = 'FROM_2_5',
    FROM_2_7 = 'FROM_2_7',
    FROM_3 = 'FROM_3',
    FROM_4 = 'FROM_4',
}
