import React, { useContext, useCallback } from 'react';
import chunk from 'lodash/chunk';

import { NewBuildingFlat, NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import classname from '@search/classname/src';
import { IUseToggleValueReturnType } from '@search/hooks/src/useToggleValue';

import Grid from '@vtblife/uikit-grid';
import { Collapse, Typography } from '@vtblife/uikit';

import { getFilterParams } from '../../../common/components/NewBuildingSnippet/useNewBuildingSnippetUrl';
import { renderFlatStatus } from '../../../common/components/NewBuildingFlats';
import { GlobalContext } from '../../../common/components/GlobalContext';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingFlats');

const convertRoomsNumber = (rooms: string) => {
    switch (rooms) {
        case 'STUDIO':
            return 'STUDIO';
        case 'FREE':
            return 'OPEN_PLAN';
        case '1':
        case '2':
        case '3':
        case '4':
            return `ROOM_${rooms}`;
        default:
            return 'ROOM_5_AND_MORE';
    }
};

const renderFlatCell = (
    flat: NewBuildingFlat,
    newBuildingId: string,
    url: string,
    hasOffers: boolean,
    forPromoSnippet: boolean,
    searchIndex: NewBuildingWhitelabelEnum,
    hoverToggle?: IUseToggleValueReturnType,
    phoneHoverToggle?: IUseToggleValueReturnType
) => {
    const hasNoPrice = flat.isSoldOut || flat.isSoon || flat.isTemporaryNotOnSale ||
        ! flat.formatPriceRangeShort && ! flat.formatPriceRange;
    const hasLink = hasOffers && ! hasNoPrice;
    const flatStatusTextColor = hasNoPrice ? 'black-400' : undefined;

    return (
        <div className={cn('cell')}>
            {hasLink ? (
                <a
                    href={url}
                    target='_blank'
                    className={cn('link', { vtb: searchIndex === NewBuildingWhitelabelEnum.Vtb })}
                    onMouseOver={event => {
                        try {
                            const serpPageParamsStr = window.localStorage.getItem('NewBuildingSerpPageParams');
                            const serpPageParams = serpPageParamsStr ? JSON.parse(serpPageParamsStr) : {};
                            const newSerpPageParams = {
                                ...serpPageParams,
                                // @ts-ignore
                                [newBuildingId]: getFilterParams({ rooms: [ convertRoomsNumber(flat.rooms) ] })
                            };

                            window.localStorage.setItem('NewBuildingSerpPageParams', JSON.stringify(newSerpPageParams));
                            event.stopPropagation();

                            hoverToggle?.handleSetFalseTogVal();
                            phoneHoverToggle?.handleSetTrueTogVal();
                        } catch (e) {
                        }
                    }}
                    onMouseLeave={hoverToggle?.handleSetTrueTogVal}
                >
                    <Typography
                        variant='primary-alone'
                        color={forPromoSnippet ? 'white-500' : 'link'}
                        tag='span'
                    >
                        {flat.labelShort}
                    </Typography>
                    <Typography
                        variant='primary-alone'
                        color={forPromoSnippet ? 'white-500' : 'link'}
                        tag='span'
                        className={cn('area')}
                    >
                        {flat.formatAreaRange}
                    </Typography>
                </a>
            ) : (
                <>
                    <Typography
                        variant='primary-alone'
                        color={forPromoSnippet ? 'white-500' : undefined}
                        tag='span'
                    >
                        {flat.labelShort}
                    </Typography>
                    <Typography
                        variant='primary-alone'
                        color={forPromoSnippet ? 'white-500' : undefined}
                        tag='span'
                        className={cn('area')}
                    >
                        {flat.formatAreaRange}
                    </Typography>
                </>
            )}
            <Typography
                variant='primary-alone'
                color={forPromoSnippet ? 'white-500' : flatStatusTextColor}
                className={cn('status')}
            >
                {renderFlatStatus(flat, true)}
            </Typography>
        </div>
    );
};

const NewBuildingFlats: React.FunctionComponent<{
    flats: NewBuildingFlat[];
    forPromoSnippet?: boolean;
    newBuildingId: string;
    url: string;
    hasOffers: boolean;
    phoneHoverToggle?: IUseToggleValueReturnType;
    hoverToggle?: IUseToggleValueReturnType;
}> = ({
    flats: flatsProp,
    forPromoSnippet = false,
    newBuildingId,
    url,
    hasOffers,
    phoneHoverToggle,
    hoverToggle
}) => {
    const {
        instanceConfig: {
            searchIndex
        }
    } = useContext(GlobalContext);

    const flats = flatsProp!.filter(Boolean);
    const chunkFlats = chunk(flats, 2);

    const renderFlats = useCallback(() => {
        return chunkFlats.map((chFlats, index) => (
            <div className={cn('row')} key={index}>
                <Grid cols={2} key={index}>
                    <Grid.Cell m={1}>
                        {renderFlatCell(
                            chFlats[0],
                            newBuildingId,
                            url,
                            hasOffers,
                            forPromoSnippet,
                            searchIndex,
                            hoverToggle,
                            phoneHoverToggle
                        )}
                    </Grid.Cell>
                    {chFlats[1] && (
                        <Grid.Cell m={1}>
                            {renderFlatCell(
                                chFlats[1],
                                newBuildingId,
                                url,
                                hasOffers,
                                forPromoSnippet,
                                searchIndex,
                                hoverToggle,
                                phoneHoverToggle
                            )}
                        </Grid.Cell>
                    )}
                </Grid>
            </div>
        ));
    }, [ chunkFlats, forPromoSnippet, hasOffers, hoverToggle, newBuildingId, phoneHoverToggle, url, searchIndex ]);

    if (! flats?.length) {
        return null;
    }

    return (
        <div
            className={cn('')}
            onMouseOver={() => {
                phoneHoverToggle?.handleSetTrueTogVal();
                hoverToggle?.handleSetTrueTogVal();
            }}
            onMouseLeave={() => {
                phoneHoverToggle?.handleSetFalseTogVal();
                hoverToggle?.handleSetFalseTogVal();
            }}
        >
            <a
                href={url}
                target='_blank'
                className={cn('link')}
            />
            {forPromoSnippet ? renderFlats() : (
                <>
                    <div className={cn('flats')}>
                        {renderFlats()}
                    </div>
                    <div className={cn('flatsSmallScreen')}>
                        <Collapse variant='line' size='s'>
                            <Collapse.Item title='Все квартиры'>
                                {renderFlats()}
                            </Collapse.Item>
                        </Collapse>
                    </div>
                </>
            )}
        </div>
    );
};

export default NewBuildingFlats;
