import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Button, Link, Typography } from '@vtblife/uikit';
import { Input, PhoneInput, Size } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import { STATUS } from '@search/gql-client/src';
import { useCallbackEmail } from '@search/vtbeco-frontend-core/domain/emails/useCallbackEmail';

import { SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingAdd');

const ERROR_MESSAGE = 'Что-то пошло не так, попробуйте позже.';

type State = 'add' | 'callBack';
type TextKey = 'developer' | 'newBuilding' | 'person' | 'phone' | 'email';

const LABELS: Record<TextKey, string> = {
    developer: 'Застройщик',
    newBuilding: 'Название ЖК',
    person: 'Контактное лицо',
    phone: 'Номер телефона',
    email: 'Email'
};

const INITIAL_TEXT_DATA = {
    developer: '',
    newBuilding: '',
    person: '',
    phone: '',
    email: ''
};

const makeEmail = (record: Record<TextKey, string>) => ([
    'developer', 'newBuilding', 'person', 'phone', 'email'
] as TextKey[])
    .map(key => record[key] ? `${LABELS[key]}: ${record[key]}` : '')
    .filter(Boolean)
    .join('\n');

const NewBuildingAdd = ({
    isMobile = false,
    className
}: {
    isMobile?: boolean;
    className?: string;
}) => {
    const [ textData, setTextData ] = useState<Record<TextKey, string>>(INITIAL_TEXT_DATA);
    const setTextField = useCallback((key: TextKey, value: string) => setTextData(data => ({ ...data, [key]: value })), []);

    const [ hasTried, setHasTried ] = useState<boolean>(false);

    const [ requestStatus, setRequestStatus ] = useState<STATUS>(STATUS.INIT);
    const sendCallBackQuizRequest = useCallbackEmail();
    const sendRequest = useCallback(() => {
        if (requestStatus !== STATUS.PENDING) {
            setHasTried(true);

            if (Object.values(textData).every(v => v)) {
                sendCallBackQuizRequest({
                    subject: 'Заявка на подключение ЖК',
                    body: makeEmail(textData)
                }, { pathUniqSuffix: '/callback-nb-add/' })
                    .then(result => setRequestStatus(
                        result.data?.createEmailCallbackSimple.emailMessageId ?
                            STATUS.RESOLVE :
                            STATUS.ERROR
                    ))
                    .catch(() => setRequestStatus(STATUS.ERROR));

                setRequestStatus(STATUS.PENDING);
            }
        }
    }, [ requestStatus, textData, sendCallBackQuizRequest ]);

    const [ state, setState ] = useState<State | null>(null);
    const openAddModal = useCallback(() => setState('add'), []);
    const openCallBackModal = useCallback(() => setState('callBack'), []);
    const closeModal = useCallback(() => {
        setState(null);
        setTextData(INITIAL_TEXT_DATA);
        setRequestStatus(STATUS.INIT);
        setHasTried(false);
    }, []);

    useEffect(() => {
        switch (requestStatus) {
            case STATUS.ERROR:
                // eslint-disable-next-line no-alert
                window.alert(ERROR_MESSAGE);

                return;
            case STATUS.RESOLVE:
                closeModal();

                return;
            default:
        }
    }, [ requestStatus, closeModal ]);

    const ModalTitleComponent = isMobile ? Modal.Header : Modal.Title;

    return (
        <div className={cn(null, {
            desktop: ! isMobile,
            mobile: isMobile
        }, className)}>
            {isMobile || (
                <div className={cn('icon')}>
                    <SvgSpriteIcon
                        name='NewBuildingAdd'
                        width={86}
                        height={88}
                    />
                </div>
            )}
            <div className={cn('main')}>
                <div className={cn('text')}>
                    <Typography
                        variant='h4'
                        className={cn('h4')}
                    >
                        Добавьте свой&nbsp;ЖК в&nbsp;каталог новостроек
                    </Typography>
                    <Typography
                        variant='primary-alone'
                        color='secondary'
                    >
                        Бесплатно разместим объявления от&nbsp;застройщиков и&nbsp;их&nbsp;официальных представителей
                    </Typography>
                </div>
                <div>
                    <Button
                        fullWidth={isMobile}
                        variant='secondary'
                        size='s'
                        onClick={openAddModal}
                    >
                        Связаться с&nbsp;менеджером
                    </Button>
                </div>
            </div>
            <Modal
                mobile={isMobile}
                opened={state !== null}
                onClose={closeModal}
                size='s'
            >
                <ModalTitleComponent>
                    {state === 'add' ? 'Добавление нового\u00A0ЖК' : 'Обратный звонок'}
                </ModalTitleComponent>
                <Modal.Content>
                    <div className={cn('modal', {
                        desktop: ! isMobile,
                        mobile: isMobile
                    })}>
                        {state === 'add' ? (
                            <>
                                <Typography>
                                    Чтобы добавить свой&nbsp;ЖК, напишите на&nbsp;почту: <Link href='mailto:sales@m2.ru'>sales@m2.ru</Link>.
                                </Typography>
                                <Typography>
                                    Для публикации вам необходимо предоставить рекламную информацию, фиды, контактные данные.
                                </Typography>
                                <Button
                                    fullWidth
                                    size='m'
                                    variant='secondary'
                                    icon='phone-incoming'
                                    onClick={openCallBackModal}
                                >
                                    Перезвоните мне
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography>
                                    Оставьте контактные данные, и&nbsp;мы&nbsp;свяжемся с&nbsp;вами в&nbsp;течение нескольких рабочих дней.
                                </Typography>
                                <div className={cn('inputs')}>
                                    {([
                                        'developer', 'newBuilding', 'person', 'phone', 'email'
                                    ] as TextKey[]).map(key => {
                                        const Component = key === 'phone' ? PhoneInput : Input;
                                        const value = textData[key];

                                        return (
                                            <Component
                                                isError={hasTried && ! value}
                                                key={key}
                                                fullWidth
                                                value={value}
                                                onChange={v => setTextField(key, v)}
                                                hasClear
                                                label={LABELS[key]}
                                                size={Size.Large}
                                            />
                                        );
                                    })}
                                </div>
                                <Button
                                    fullWidth
                                    size='m'
                                    variant='primary'
                                    onClick={sendRequest}
                                    loading={requestStatus === STATUS.PENDING}
                                >
                                    Отправить
                                </Button>
                            </>
                        )}
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default NewBuildingAdd;
