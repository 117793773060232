import { WLNewBuildingCardRoute } from '@search/nb-routes/src/NewBuildingCardRoute';
import { WLNewBuildingSearchRoute } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { WLNewBuildingSearchMapRoute } from '@search/nb-routes/src/NewBuildingSearchMapRoute';
import { NewBuildingSearchMapTileRoute } from '@search/nb-routes/src/NewBuildingSearchMapTileRoute';
import { WLDeveloperRoute } from '@search/nb-routes/src/DeveloperRoute';
import { WLDeveloperSearchRoute } from '@search/nb-routes/src/DeveloperSearchRoute';

import { Shared } from '../../../types/shared';

export const linkBuilder: Shared.LinkBuilderType = (
    rootRegions,
    router
) => ({
    search(params) {
        const searchRoute = router.route(WLNewBuildingSearchRoute);

        return searchRoute.url(params);
    },
    searchMap(params) {
        const searchMapRoute = router.route(WLNewBuildingSearchMapRoute);

        return searchMapRoute.url(params);
    },
    tile(params) {
        const searchMapTileRoute = router.route(NewBuildingSearchMapTileRoute);

        return searchMapTileRoute.url(params);
    },
    card(params) {
        const newBuildingCardRoute = router.route(WLNewBuildingCardRoute);

        return newBuildingCardRoute.url(params);
    },
    developer(params) {
        try {
            const developerRoute = router.route(WLDeveloperRoute);

            return developerRoute.url(params);
        } catch {
            return '';
        }
    },
    developerSearch(params) {
        try {
            const developerSearchRoute = router.route(WLDeveloperSearchRoute);

            return developerSearchRoute.url(params);
        } catch {
            return '';
        }
    }
});
