import React, { useMemo } from 'react';

import classname from '@search/classname/src';

import Slider from '@search/vtbeco-frontend-core/view/common/components/Slider';

import {
    ConstructionProgressItem,
    ConstructionProps,
    groupImages,
    Image,
    useConstructionGallery
} from '../../../../common/components/ConstructionProgress';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardConstructionProgress');

const NewBuildingCardConstructionProgress = (props: ConstructionProps) => {
    const imageGroups = useMemo<Image[][]>(
        () => groupImages(props).reduce((allGroups, groups) => [
            ...allGroups,
            ...groups
        ], [] as Image[][]),
        [ props ]
    );

    const { handleGallery } = useConstructionGallery({
        title: `Ход строительства ${props.title.genitive}`
    });

    return (
        <div className={cn()}>
            <Slider
                contentClassName={cn('sliderContent')}
                isNavHidden
            >
                {imageGroups.map((imageGroup, index) => (
                    <ConstructionProgressItem
                        key={index}
                        originPath={imageGroup[0].originPath}
                        title={imageGroup[0].title}
                        date={imageGroup[0].date}
                        address={imageGroup[0].address}
                        onImageClick={() => handleGallery(imageGroup, 0)}
                        newBuildingName={props.title.nominative}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default React.memo(NewBuildingCardConstructionProgress);
