/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Выделяет из строки только цифры
 * @param {string} string
 * @returns {string}
 * @example
 * parseNumber('от 1020304050 ₽') -> '1020304050'
 */
export const parseNumber = (string: number | string): string => string
    .toString()
    .replace(/\D/g, '');

/**
 * Форматирует число, разделяя тысячи пробелами
 * @param {number | string | null} [number]
 * @param {string} [defaultString = '0']
 * @param {(n: number | string) => string} [stringify = a => a.toString()]
 * @returns {string}
 * @example
 * formatNumber(null) -> '0'
 * formatNumber(null, '') -> ''
 * formatNumber(1000000) -> '1 000 000'
 * formatNumber(10203040) -> '10 203 040'
 * formatNumber(1020304050) -> '1 020 304 050'
 * formatNumber('от 1020304050 ₽') -> 'от 1 020 304 050 ₽'
 */
export const formatNumber = (
    number?: number | string | null,
    defaultString: string = '0',
    stringify: (n: number | string) => string = a => a.toString()
): string => number ?
    stringify(number)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1\u00A0') :
    defaultString;

/**
 * Форматирует число, разделяя тысячи пробелами
 * @param {number | string | null} [number]
 * @returns {string}
 * @example
 * parseAndFormatNumber(null) -> ''
 * parseAndFormatNumber(1000000) -> '1 000 000'
 * parseAndFormatNumber(10203040) -> '10 203 040'
 * parseAndFormatNumber(1020304050) -> '1 020 304 050'
 * parseAndFormatNumber('от 1020304050 ₽') -> '1 020 304 050'
 */
export const parseAndFormatNumber = (number?: number | string | null): string => formatNumber(
    number, '', parseNumber
);

export const LOCALE = 'ru-RU';
export const HAS_LOCALE_SUPPORT = (1e3).toLocaleString(LOCALE).includes(' ');

/**
 * Форматирование числа в русской локали
 * @param {number | null} [number]
 * @param {number} [maximumFractionDigits = 2]
 * @returns {string}
 * @example
 * formatLocaleNumber(12.3) -> '12,3'
 * formatLocaleNumber(1234) -> '1 234'
 * formatLocaleNumber(1234.5) -> '1 234,5'
 * formatLocaleNumber(1234.56, 1) -> '1 234,6'
 * formatLocaleNumber(1234.567) -> '1 234,57'
 */
export const formatLocaleNumber = (
    number?: number | null,
    maximumFractionDigits: number = 2,
    minimumFractionDigits: number = 0
): string => {
    if (typeof number !== 'number') {
        return '';
    }

    return formatLocaleNumberWithOptions(number, { maximumFractionDigits, minimumFractionDigits });
};

export const formatLocaleNumberWithOptions = (
    number: number,
    options: Intl.NumberFormatOptions = {}
): string => {
    const result = number.toLocaleString(LOCALE, options);

    return HAS_LOCALE_SUPPORT ? result : result
        .replace(/,/g, ' ') // неразрывный пробел
        .replace(/\./g, ',');
};

enum ABBREVIATIONS {
    THOUSAND = 'тыс',
    MILLION = 'млн',
    BILLION = 'млрд'
}

/**
 * Сокращает запись числа до тысяч и миллионов
 * @param {number | null} [number]
 * @param {{ billionsAsString?: boolean, thousandsAsNumber?: boolean, roundUp?: boolean }} [options]
 * @returns {{ value?: number | null, type: string }}
 * @example
 * abbreviateNumber() -> { value: undefined, type: '' }
 * abbreviateNumber(123) -> { value: 123, type: '' }
 * abbreviateNumber(1234) -> { value: 1, type: 'тыс' }
 * abbreviateNumber(1234, { thousandsAsNumber: true }) -> { value: 1234, type: '' }
 * abbreviateNumber(1294567) -> { value: 1.2, type: 'млн' }
 * abbreviateNumber(9432100) -> { value: 9.4, type: 'млн' }
 * abbreviateNumber(9432100, { roundUp: true }) -> { value: 9.5, type: 'млн' }
 */
export const abbreviateNumber = (
    number?: number | null,
    options?: {
        billionsAsString?: boolean;
        thousandsAsNumber?: boolean;
        roundUp?: boolean;
    }
): {
    value?: number | null;
    type: string;
} => {
    if (
        ! number ||
        number < 1e3 ||
        // RS-1361 Logic for price preset
        options && options.thousandsAsNumber && number < 1e6
    ) {
        return {
            value: number,
            type: ''
        };
    }

    const round = options?.roundUp ? Math.ceil : Math.round;

    if (number < 1e6) {
        return {
            value: round(number / 1e3),
            type: ABBREVIATIONS.THOUSAND
        };
    }

    if (number >= 1e9 && options?.billionsAsString) {
        return {
            value: round(number / 1e8) / 10,
            type: ABBREVIATIONS.BILLION
        };
    }

    return {
        value: round(number / 1e5) / 10,
        type: ABBREVIATIONS.MILLION
    };
};

/**
 * Возвращает человекопонятную запись диапазона чисел
 * @param {number | null} [rangeMin]
 * @param {number | null} [rangeMax]
 * @param {{ billionsAsString?: boolean, thousandsAsNumber?: boolean, roundUp?: boolean }} [options]
 * @returns {string}
 * @example
 * formatRange(500000, 1200000) -> '500 тыс – 1,2 млн'
 * formatRange(500000) -> 'от 500 тыс'
 * formatRange(null, 1200000) -> 'до 1,2 млн'
 * formatRange(1000000, 1200000) -> '1 – 1,2 млн'
 * formatRange(100000, 1200000, { thousandsAsNumber: true }) -> '100 000 – 1,2 млн'
 */
export const formatRange = (
    rangeMin?: number | null,
    rangeMax?: number | null,
    options?: {
        billionsAsString?: boolean;
        thousandsAsNumber?: boolean;
        roundUp?: boolean;
        withPrepositions?: boolean;
    }
): string => {
    if (
        (rangeMin === null || typeof rangeMin === 'undefined') &&
        (rangeMax === null || typeof rangeMax === 'undefined')
    ) {
        return '';
    }

    const min = abbreviateNumber(Number(rangeMin || 0), options);
    const max = abbreviateNumber(Number(rangeMax || 0), options);

    if (rangeMin && ! rangeMax) {
        return [
            'от',
            formatLocaleNumber(min.value),
            min.type
        ].filter(Boolean).join(' ');
    }

    if (rangeMax && ! rangeMin) {
        return [
            'до',
            formatLocaleNumber(max.value),
            max.type
        ].filter(Boolean).join(' ');
    }

    if (min.type === max.type) {
        if (min.value === max.value) {
            return `${formatLocaleNumber(max.value)} ${max.type}`;
        }

        return [
            options?.withPrepositions ? 'от' : '',
            formatLocaleNumber(min.value),
            options?.withPrepositions ? 'до' : '–',
            formatLocaleNumber(max.value),
            max.type
        ].filter(Boolean).join(' ');
    }

    return [
        options?.withPrepositions ? 'от' : '',
        formatLocaleNumber(min.value),
        min.type,
        options?.withPrepositions ? 'до' : '–',
        formatLocaleNumber(max.value),
        max.type
    ].filter(Boolean).join(' ');
};
