import React, { FC } from 'react';

import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';

import Logo from './logo.url.png';

import './styles.css';

const InspectionStatusLogo: FC<{}> = () => {
    const staticPath = useStaticPathConfig();

    return (
        <Image
            className='InspectionStatusLogo'
            url={`${staticPath}${Logo}`}
        />
    );
};

export default InspectionStatusLogo;
