/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useState, useRef, useEffect, useMemo, MouseEvent } from 'react';
import debounce from 'lodash/debounce';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import MetroMap from './MetroMap';
import ZoomControl from './ZoomControl';

import './styles.css';

const cn = classname.bind(null, 'MetroMapSelector');

const INITIAL_ZOOM = 1;
const STEP_ZOOM = 0.5;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 5;

const MOVING_CLASS_NAME = '_moving';

const getTopLeft = node => {
    const { style } = node;

    return [ parseFloat(style.top) || 0, parseFloat(style.left) || 0 ];
};

const setTopLeft = (node, top, left) => {
    const { style } = node;

    style.top = `${top}px`;
    style.left = `${left}px`;
};

const getScale = node => {
    return Number(node.dataset.scale) || 1;
};

const setScale = (node, scale) => {
    node.style.transform = 'scale(' + scale + ')';
    node.dataset.scale = scale;
};

const setMapNodeZoom = ({ mapNode, mapContainerNodeRect, deltaZoom, zoom, zoomOrigin, onChange }) => {
    const currentZoom = getScale(mapNode);

    if (deltaZoom) {
        zoom = currentZoom + deltaZoom;
    }

    zoom = Math.min(Math.max(zoom, MIN_ZOOM), MAX_ZOOM);

    if (currentZoom === zoom) {
        return;
    }

    const [ currentTop, currentLeft ] = getTopLeft(mapNode);
    const zoomRatio = zoom / currentZoom;

    const halfContainerWidth = mapContainerNodeRect.width / 2;
    const halfContainerHeight = mapContainerNodeRect.height / 2;

    if (! zoomOrigin) {
        zoomOrigin = {
            pageX: mapContainerNodeRect.x + halfContainerWidth,
            pageY: mapContainerNodeRect.y + halfContainerHeight,
        };
    }

    const x = zoomOrigin.pageX - mapContainerNodeRect.x;
    const y = zoomOrigin.pageY - mapContainerNodeRect.y;

    const top = zoomRatio * (halfContainerHeight + currentTop - y) + y - halfContainerHeight;
    const left = zoomRatio * (halfContainerWidth + currentLeft - x) + x - halfContainerWidth;

    setTopLeft(mapNode, top, left);
    setScale(mapNode, zoom);

    onChange && onChange(zoom, [ top, left ]);
};

const getMinMaxTopLeft = (mapNode, mapContainerNodeRect) => {
    const padding = 100;
    const mapNodeRect = mapNode.getBoundingClientRect();
    const halfWidth = mapNodeRect.width / 2 + mapContainerNodeRect.width / 2 - padding;
    const halfHeight = mapNodeRect.height / 2 + mapContainerNodeRect.height / 2 - padding;

    return [
        [ -halfHeight, halfHeight ],
        [ -halfWidth, halfWidth ]
    ];
};

const actualizeTopLeft = (minMaxTopLeft, top, left) => {
    return [
        Math.min(Math.max(top, minMaxTopLeft[0][0]), minMaxTopLeft[0][1]),
        Math.min(Math.max(left, minMaxTopLeft[1][0]), minMaxTopLeft[1][1])
    ];
};

const MetroMapSelector = props => {
    const [ zoom, setZoom ] = useState(INITIAL_ZOOM);
    const [ [ top, left ], setPosition ] = useState([ 0, 0 ]);
    const debounceSetZoom = useMemo(() => {
        return debounce(setZoom, 100);
    }, [ setZoom ]);
    const debounceSetPosition = useMemo(() => {
        return debounce(setPosition, 100);
    }, [ setPosition ]);

    const debouncedSetZoomPosition = useMemo(() => {
        return debounce((zoom, position) => {
            setZoom(zoom);
            setPosition(position);
        }, 100);
    }, []);
    const mapNodeRef = useRef<HTMLDivElement>();
    const mapContainerNodeRef = useRef<HTMLDivElement>();
    const mapContainerNodeRectRef = useRef<ClientRect | DOMRect>();

    useEffect(() => {
        const mapNode = mapNodeRef.current;

        if (mapNode) {
            setMapNodeZoom({
                mapNode,
                mapContainerNodeRect: mapContainerNodeRectRef.current,
                zoom
            });
        }
    }, [ zoom ]);

    useEffect(() => {
        const mapNode = mapNodeRef.current;

        if (mapNode) {
            setTopLeft(mapNode, top, left);
        }
    }, [ top, left ]);

    const handleMapContainerMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
        const mapNode = mapNodeRef.current;
        const mapContainerNode = mapContainerNodeRef.current;
        const minMaxTopLeft = getMinMaxTopLeft(mapNode, mapContainerNodeRectRef.current);
        let [ top, left ] = getTopLeft(mapNode);
        const startPoint = {
            pageX: e.pageX,
            pageY: e.pageY,
            top,
            left
        };

        mapContainerNode.classList.add(MOVING_CLASS_NAME);

        const handleDocumentMouseMove = (e: MouseEvent<HTMLDivElement>) => {
            [ top, left ] = actualizeTopLeft(
                minMaxTopLeft,
                startPoint.top + e.pageY - startPoint.pageY,
                startPoint.left + e.pageX - startPoint.pageX);

            setTopLeft(mapNode, top, left);
        };
        const handleDocumentMouseUp = () => {
            document.removeEventListener('mousemove', handleDocumentMouseMove);
            document.removeEventListener('mouseup', handleDocumentMouseUp);

            mapContainerNode.classList.remove(MOVING_CLASS_NAME);
            setPosition([ top, left ]);
        };

        document.addEventListener('mousemove', handleDocumentMouseMove, false);
        document.addEventListener('mouseup', handleDocumentMouseUp, false);
    }, []);

    useEffect(() => {
        const mapContainerNode = mapContainerNodeRef.current;

        mapContainerNode.addEventListener('wheel', handleMapContainerWheel, { passive: false });

        return () => {
            mapContainerNode.removeEventListener('wheel', handleMapContainerWheel);
        };
    });

    const handleMapContainerWheel = useCallback((e: WheelEvent) => {
        const mapNode = mapNodeRef.current;
        const mapContainerNodeRect = mapContainerNodeRectRef.current;

        if (e.ctrlKey || e.metaKey) {
            e.preventDefault();

            setMapNodeZoom({
                mapNode,
                mapContainerNodeRect,
                deltaZoom: -e.deltaY / 100,
                zoomOrigin: e,
                onChange: debouncedSetZoomPosition
            });
        } else {
            const [ top, left ] = getTopLeft(mapNode);
            const [ newTop, newLeft ] = actualizeTopLeft(
                getMinMaxTopLeft(mapNode, mapContainerNodeRect),
                top - e.deltaY,
                left - e.deltaX);

            setTopLeft(mapNode, newTop, newLeft);
            debounceSetPosition([ newTop, newLeft ]);
        }
    }, [ debounceSetZoom, debounceSetPosition ]);

    const handleMapContainerDoubleClick = useCallback((e: MouseEvent) => {
        setMapNodeZoom({
            mapNode: mapNodeRef.current,
            mapContainerNodeRect: mapContainerNodeRectRef.current,
            deltaZoom: (e.ctrlKey || e.metaKey) ? -STEP_ZOOM : STEP_ZOOM,
            zoomOrigin: e,
            onChange: debouncedSetZoomPosition
        });
    }, [ debounceSetZoom, debounceSetPosition ]);

    const handleCenterControlClick = useCallback(() => {
        setPosition([ 0, 0 ]);
        setZoom(INITIAL_ZOOM);
    }, []);

    const handleMapNodeRef = useCallback(node => {
        if (node) {
            mapNodeRef.current = node;
            mapContainerNodeRef.current = node.parentNode;
            mapContainerNodeRectRef.current = mapContainerNodeRef.current.getBoundingClientRect();
        }
    }, []);

    return (
        <div className={cn()}>
            <div
                className={cn('map-container')}
                onDoubleClick={handleMapContainerDoubleClick}
                onMouseDown={handleMapContainerMouseDown}
            >
                <div className={cn('map')} ref={handleMapNodeRef}>
                    <MetroMap {...props} />
                </div>
            </div>
            <div className={cn('controls')}>
                {top === 0 && left === 0 && zoom === INITIAL_ZOOM ? null : (
                    <div
                        onClick={handleCenterControlClick}
                        className={cn('center-control')}
                        title='Переместить карту к центру'
                    >
                        <Icon name='target' />
                    </div>
                )}
                <ZoomControl
                    zoom={zoom}
                    step={STEP_ZOOM}
                    minZoom={props.vertical ? INITIAL_ZOOM : MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    onChange={setZoom}
                />
            </div>
        </div>
    );
};

export default MetroMapSelector;
