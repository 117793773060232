import { RegionIdEnum } from '@search/filter-enums/enums/Region';

/**
* Проверяет является ли регион СПб/СПб и ЛО/ЛО
*/
export function isSpbWide(regionId?: number | string) {
    let region = regionId;

    if (typeof region === 'string') region = Number(regionId);

    switch (region) {
        case RegionIdEnum.SPB:
        case RegionIdEnum.SPB_AND_LEN_OBL:
        case RegionIdEnum.LEN_OBL:
            return true;
        default: return false;
    }
}

/**
* Проверяет является ли регион Мск/Мск и МО/МО
*/
export function isMskWide(regionId?: number | string) {
    let region = regionId;

    if (typeof region === 'string') region = Number(regionId);

    switch (region) {
        case RegionIdEnum.MSK:
        case RegionIdEnum.MSK_OBL:
        case RegionIdEnum.MSK_AND_MSK_OBL:
            return true;
        default: return false;
    }
}
