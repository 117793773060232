import { EmailSenderEnum } from '@search/graphql-typings';

export const defineCallbackEmailSender = (
    isMskMO?: boolean,
    isSPbLO?: boolean
): EmailSenderEnum => {
    if (isMskMO) {
        return EmailSenderEnum.NewBuildingsCallbackMsk;
    }

    if (isSPbLO) {
        return EmailSenderEnum.NewBuildingsCallbackSpb;
    }

    return EmailSenderEnum.NewBuildingsCallbackDefault;
};

export const defineOnlineBookingEmailSender = (
    isMskMO?: boolean,
    isSPbLO?: boolean
): EmailSenderEnum => {
    if (isMskMO) {
        return EmailSenderEnum.NewBuildingsOnlineBookingMsk;
    }

    if (isSPbLO) {
        return EmailSenderEnum.NewBuildingsOnlineBookingSpb;
    }

    return EmailSenderEnum.NewBuildingsCallbackDefault;
};
