import React from 'react';

import { NewBuildingSnippet, RouteMetro } from '@search/graphql-typings';

import { Badge as DSBadge } from '@vtblife/uikit';
import { formatRange } from '@search/helpers/src/formatNumber';

import classname from '@search/classname/src';

import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import MetroRoute from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import { ImageLazySeo } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySeo';
import { NO_PHOTO_SRC, NO_PHOTO_SRC_SET } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';

import {
    ImageLazySrcSetProvide,
    useClImageLazySrcSetUrl
} from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySrcSet';

import { useNewBuildingSnippet } from '../NewBuildingSnippet/useNewBuildingSnippet';
import { useNewBuildingSnippetUrl } from '../NewBuildingSnippet/useNewBuildingSnippetUrl';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingTinySnippet');

export const NewBuildingTinySnippet = ({
    newBuilding,
    isMobile
}: {
    newBuilding: NewBuildingSnippet;
    isMobile?: boolean;
}) => {
    const { routes } = useNewBuildingSnippet(newBuilding);

    const {
        name: newBuildingName,
        shortAddress,
        gallery,
        routeParams
    } = newBuilding;

    const url = useNewBuildingSnippetUrl(routeParams);
    const getUrl = useClImageLazySrcSetUrl({ baseUrl: gallery?.baseUrl });

    const src = gallery?.main?.medium ?? NO_PHOTO_SRC;
    const srcSet = gallery?.main?.originPath || gallery?.main?.medium ? undefined : NO_PHOTO_SRC_SET;

    return (
        <a
            href={url}
            target='_blank'
            className={cn(null, { mobile: isMobile })}
        >
            <div className={cn('imageWrapper')}>
                <ImageLazySrcSetProvide getUrl={getUrl}>
                    <ImageLazySeo
                        loadingLazy='native'
                        src={src}
                        srcSet={srcSet}
                        className={cn('image')}
                        alt={newBuildingName}
                        originPath={gallery?.main?.originPath}
                        srcWidth={400}
                        srcHeight={400}
                    />
                </ImageLazySrcSetProvide>
                {
                    newBuilding.priceMin ? (
                        <div className={cn('priceWrapper')}>
                            <DSBadge color='black'>{formatRange(newBuilding.priceMin, null, {
                                billionsAsString: true
                            })}</DSBadge>
                        </div>
                    ) : null
                }
            </div>
            <div className={cn('name')}>
                <CustomTypography
                    tag='span'
                    level='primary-alone'
                    title={newBuildingName}
                    color='primary'
                    bold
                >
                    {newBuildingName}
                </CustomTypography>
            </div>
            {
                routes?.length ? (
                    <MetroRoute
                        metro={routes[0] as RouteMetro}
                        withoutTransport
                    />
                ) : (
                    shortAddress && (
                        <div className={cn('address')}>
                            <CustomTypography
                                tag='span'
                                level='primary-alone'
                                color='primary'
                                title={shortAddress}
                            >
                                {shortAddress}
                            </CustomTypography>
                        </div>
                    )
                )
            }
        </a>
    );
};

export const NewBuildingTinySnippetStub = ({ isMobile }: { isMobile?: boolean }) => (
    <div className={cn(null, { mobile: isMobile })}>
        <div className={cn('stub', { image: true })} />
        <div className={cn('stub', { name: true })} />
        <div className={cn('stub', { address: true })} />
    </div>
);
