export enum ParkingType {
    UNKNOWN = 0,
    CLOSED = 1,
    UNDERGROUND = 2,
    OPEN = 3
}

export enum ParkingTypeString {
    UNKNOWN = 'UNKNOWN',
    CLOSED = 'CLOSED',
    UNDERGROUND = 'UNDERGROUND',
    AVAILABLE = 'AVAILABLE',
    OPEN = 'OPEN'
}
