import React, { useCallback, useState } from 'react';

import { Input, Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';

import {
    SuggestTypeEnum,
    SuggestItemDeveloper
} from '@search/graphql-typings';
import { graphql, useGqlContext } from '@search/gql-client/src';
import { Filter2 } from '@search/vtbeco-frontend-core/view/filters/components/new_filter/Filter2';

import Suggest, { ISuggestProps } from '@search/vtbeco-ui/src/components/controls/Suggest';
import { Highlight } from '@search/vtbeco-ui/src/components/controls/Highlight/Highlight';
import useDebouncedCallback from '@search/vtbeco-frontend-core/view/common/hooks/useDebouncedCallback';
import classname from '@search/classname/src';

import {
    NewDesktopDeveloperFilterViewQuery$data as NewDesktopDeveloperFilterViewQueryResponse,
    NewDesktopDeveloperFilterViewQuery$variables as NewDesktopDeveloperFilterViewQueryVariables
} from './__generated__/NewDesktopDeveloperFilterViewQuery.graphql';

import './index.css';

interface IProps {
    value?: SuggestItemDeveloper;
    onChange: (value?: SuggestItemDeveloper) => void;
    placeholder?: string;
}

const cn = classname.bind(null, 'DesktopDeveloperFilterView');

const QUERY = graphql`
    query NewDesktopDeveloperFilterViewQuery($text: String! $suggestTypes: [SuggestTypeEnum!]) {
        suggest(text: $text suggestTypes: $suggestTypes) {
            ...on SuggestItemDeveloper {
                label
                data {
                    id
                    scope
                }
                developer {
                    id
                    title
                }
            }
        }
    }
`;

const renderInput: ISuggestProps['renderInput'] = (suggestProps, state, handlers) => {
    const onFocus = suggestProps.onInputToggleFocus ? () => {
        suggestProps.onInputToggleFocus?.(true);
    } : handlers.handleFocus;

    const onBlur = suggestProps.onInputToggleFocus ? () => {
        suggestProps.onInputToggleFocus?.(false);
    } : handlers.handleBlur;

    return (
        <Input
            autoFocus={suggestProps.autoFocus}
            // @ts-ignore
            size={suggestProps.inputSize} // eslint-disable-line
            value={state.label}
            onBlur={onBlur}
            onFocus={onFocus}
            name='suggest-input'
            onChange={handlers.handleInputChange}
            onKeyDown={handlers.handleInputKeyDown}
            placeholder={suggestProps.placeholder}
            label={suggestProps.label}
        />
    );
};

export const DesktopDeveloperFilterView: React.FC<IProps> = ({
    value,
    onChange,
    placeholder = 'Название компании'
}) => {
    const gql = useGqlContext();

    const handleSelect = useCallback((val?: SuggestItemDeveloper) => {
        onChange(val);
    }, [ onChange ]);

    const handleClear = useCallback(() => {
        onChange();
    }, [ onChange ]);

    const [ suggestions, setSuggestions ] = useState<SuggestItemDeveloper[]>([]);

    const [ debouncedSuggest ] = useDebouncedCallback(
        (text: string) => {
            gql.execute<NewDesktopDeveloperFilterViewQueryVariables, NewDesktopDeveloperFilterViewQueryResponse>(
                QUERY, {
                    text,
                    suggestTypes: [ SuggestTypeEnum.Developer ]
                })
                .then(data => {
                    if (data.data) {
                        setSuggestions(data?.data?.suggest || []);
                    } else {
                        setSuggestions([]);
                    }
                }).catch(() => setSuggestions([]));
        },
        300
    );

    const fetchSuggestions = (text: string) => {
        debouncedSuggest(text);
    };

    return (
        <Filter2 filterTitle='Застройщик' className={cn('select')}>
            <Suggest
                width='max'
                // @ts-ignore
                size='xxl'
                inputSize={Size.Small}
                borderOut={false}
                placeholder={placeholder}
                suggestion={value}
                suggestions={suggestions}
                onClear={handleClear}
                fetchSuggestions={fetchSuggestions}
                renderList={(props, { focusedIndex, label }, handlers) => (
                    <div className={cn('suggest')}>
                        {
                            suggestions.map((suggestion, i) => (
                                <div
                                    key={i}
                                    className={cn('suggestion', {
                                        highlighted: i === focusedIndex
                                    })}
                                    onMouseDown={e => handlers.handleItemMouseDown(e, i)}
                                >
                                    <div>
                                        <Highlight
                                            text={suggestion.label || ''}
                                            highlight={label || ''}
                                        />
                                        {
                                            suggestion?.data?.scope ? (
                                                <Typography
                                                    variant='small-alone'
                                                    color='secondary'
                                                >
                                                    {suggestion.data.scope}
                                                </Typography>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
                renderEmptyList={({ emptyText }) => (
                    <div className={cn('empty')}>
                        {emptyText}
                    </div>
                )}
                onSelect={handleSelect}
                clearOnSelect={false}
                renderInput={renderInput}
            />
        </Filter2>
    );
};
