import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import {
    QueryDisplayValueKey,
    QueryDisplayValue,
    QueryDisplayValues
} from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { GeoBase } from '@search/geo/src/SlugMapper';

// @ts-ignore
import { NewBuildingSearchQueryResponse } from '../components/NewBuildingSearch/__generated__/NewBuildingSearchQuery.graphql';
import { GlobalContext, IGlobalContext } from '../components/GlobalContext';

export type InputQueryDisplayValues = NonNullable<NewBuildingSearchQueryResponse['searchNewBuildings']>['tips'];

const inputToQueryDisplayValues = (displayValues: InputQueryDisplayValues): QueryDisplayValues => {
    return Object.entries(displayValues ?? {})
        .reduce<QueryDisplayValues>((acc, tip) => {
            const [ key, value ] = tip as [ QueryDisplayValueKey, QueryDisplayValue[] ];

            if (value) {
                acc[key] = value.reduce<Record<number, QueryDisplayValue>>((ret, item) => {
                    if (item) {
                        ret[item.id] = item;
                    }

                    return ret;
                }, {});
            }

            return acc;
        }, {} as QueryDisplayValues);
};

export const useQueryDisplayValues = (displayValues: InputQueryDisplayValues) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const initialState = useMemo(() => inputToQueryDisplayValues(displayValues), [ displayValues ]);

    const [
        queryDisplayValues,
        setQueryDisplayValues
    ] = React.useState<QueryDisplayValues>(initialState);

    const addDisplayValue = useCallback((
        key: QueryDisplayValueKey,
        value: QueryDisplayValue & { region?: number; translit?: string; locative?: string }
    ) => setQueryDisplayValues(
        prev => {
            if ((key === 'addresses' || key === 'districts') && value && value.region) {
                const geo: GeoBase = {
                    id: value.id,
                    kind: value.type === 'ADDRESS' ? 'LOCALITY' : 'DISTRICT',
                    translit: value.translit,
                    title: value.title,
                    locative: value.locative
                };

                globalContext.rootRegions?.addGeoSlug(geo, value.region);
            }

            const prevValues = prev[key];
            const { id } = value;

            if (prevValues && prevValues[id]) {
                return prev;
            }

            const next = { ...prev };

            if (! prevValues) {
                next[key] = {};
            }

            next[key]![id] = value;

            return next;
        }
    ), [ globalContext.rootRegions ]);

    useEffect(() => {
        if (displayValues) {
            const newQueryDisplayValues = inputToQueryDisplayValues(displayValues);

            Object.entries(newQueryDisplayValues).forEach(keyValues => {
                const [ key, values ] = keyValues as [ QueryDisplayValueKey, Record<number, QueryDisplayValue> ];

                Object.entries(values).forEach(idValue => {
                    const [ , value ] = idValue as [ string, QueryDisplayValue & { region?: number; translit?: string; locative?: string } ];

                    addDisplayValue(key, value);
                });
            });
        }
    }, [ displayValues, addDisplayValue ]);

    return [
        queryDisplayValues,
        addDisplayValue
    ] as const;
};
