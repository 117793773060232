import React from 'react';

import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { GqlClientOptions, graphql, useGqlContext } from '@search/gql-client/src';

import { useOffersQuery$data as useOffersQueryResponse, useOffersQuery$variables as useOffersQueryVariables } from './__generated__/useOffersQuery.graphql';

export const offersQuery = graphql`
    query useOffersQuery(
        $filters: NewBuildingOffersInput!
        $paging: PagingInput!
        $sort: NewBuildingOffersSortEnum
    ) {
        searchNewBuildingOffers(
            filters: $filters
            paging: $paging
            sort: $sort
        ) {
            items {
                isApartments
                layout {
                    origin
                    small
                }
                rooms
                roomsTitle
                area
                livingArea
                kitchenArea
                floor
                floorsTotal
                floorTitle
                finishing
                balcony
                buildingId
                priceValue
                price
                pricePerAreaUnit
                routeParams {
                    offerId
                    dealType
                    categoryType
                    newBuilding
                    room
                    region
                }
                mortgageParams {
                    propertyCost
                    purposeValue
                    claimType
                    ownAmount
                    term
                }
            }
            paging {
                pageNumber
                pageSize
                total
            }
        }
    }
`;

type UseOffersResponse = {
    offers: useOffersQueryResponse['searchNewBuildingOffers'];
    clearCache: () => void;
    isLoading: boolean;
}

function useOffersFetcher() {
    const gql = useGqlContext();

    return React.useCallback(
        (vars: useOffersQueryVariables, opts?: GqlClientOptions) => gql.client<
            useOffersQueryVariables,
            useOffersQueryResponse
        >(offersQuery, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export const useOffers = (vars: useOffersQueryVariables): UseOffersResponse => {
    const offersLoader = useGql2Loader(useOffersFetcher(), { cachePrefix: 'newBuildingOffers' });
    const { data, clearCache, loading: isLoading } = offersLoader(vars);

    return {
        offers: data?.searchNewBuildingOffers ?? null,
        clearCache,
        isLoading
    };
};
