import 'intersection-observer';

import initBrowserApp from '../../../../client/common/libs/init-browser-app';

import instanceConfig from '../../config';

import Header from '../../components/desktop/Header';
import Footer from '../../components/desktop/Footer';

import NotFoundPage from '../../../../client/desktop/components/NotFoundPage';
import Mortgage from '../../components/desktop/Mortgage';
import NewBuildingCardTopSticky from '../../components/desktop/NewBuildingCard/NewBuildingCardTopSticky';
import MortgageButton from '../../components/mobile/MortgageButton';

import { routes } from '../../routes/desktop';
import { linkBuilder } from '../../routes/routes';

initBrowserApp({
    routes,
    linkBuilder,
    instanceConfig,
    components: {
        Header,
        Footer,
        Mortgage,
        NotFoundPage,
        MortgageButton,
        NewBuildingCardRightBlock: NewBuildingCardTopSticky
    }
});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,@typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
// @ts-ignore
if (typeof module.hot === 'object') {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,@typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    module.hot.accept();
}
