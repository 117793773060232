import { strToNumRequired } from './strToNum';

export function arrayStrToNum(orig: readonly string[] | string | undefined | null): number[] {
    let result: string[] = [];

    if (orig) {
        result = Array.isArray(orig) ? orig : [ orig ];
    }

    return result.map(strToNumRequired);
}

export function arrayNumToStr(orig: number | readonly number[]): string[] {
    let result: number[] = [];

    if (orig) {
        result = Array.isArray(orig) ? orig : [ orig ];
    }

    return result.map(String);
}
