import React, { useCallback, useContext, useMemo } from 'react';

import type { Image, NewBuildingCard } from '@search/graphql-typings';

import { Tooltip, Button, ButtonProps, useTooltip } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';

import classname from '@search/classname/src';

import { PHOTO_TYPE } from '../../../../../dict/photoType';
import CarouselGallery from '../../../../common/components/Gallery';
import type { GalleryElement, GalleryVideo } from '../../../../common/components/Gallery';
import { GalleryContext } from '../../../../common/components/Gallery/GalleryContext';
import { makeYoutubeVideoThumbnail } from '../../../../common/libs/makeYoutubeLink';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingCardGallery');

type GalleryImg = {
    galleryThumbnail: Image['galleryThumbnail'];
    mediumMobile: Image['mediumMobile'];
    galleryPhoto: Image['galleryPhoto'];
}

interface IProps {
    gallery?: {
        main?: GalleryImg;
        images?: GalleryImg & { type: Image['type'] };
    };
    videoId: string;
    mapAnchor?: string;
    title?: NewBuildingCard['title'];
    titleSeoTail?: string;
    specialEventTitle?: string;
    specialEventsAnchor?: string;
    isSoldOut?: boolean;
    isSoon?: boolean;
    onlyVideo?: boolean;
}

const NewBuildingCardGallery: React.FunctionComponent<IProps> = ({
    gallery,
    videoId,
    mapAnchor,
    title,
    titleSeoTail,
    specialEventTitle,
    specialEventsAnchor,
    isSoldOut,
    isSoon,
    onlyVideo
}) => {
    const galleryElements: GalleryElement[] = useMemo(() => {
        let elements = [];

        if (gallery?.main) {
            elements.push({
                ...gallery?.main,
                type: 'COMMON'
            });
        }

        if (gallery?.images) {
            elements = elements.concat(gallery.images);
        }

        if (videoId) {
            elements.push({
                // @ts-ignore
                videoId,
                galleryThumbnail: makeYoutubeVideoThumbnail(videoId),
                type: 'VIDEO'
            });
        }

        return elements
            .map(item => title && titleSeoTail ? ({
                ...item,
                title: item.type === 'COMMON' ?
                    `${title.nominative}${titleSeoTail}` :
                    `${PHOTO_TYPE[item.type as keyof typeof PHOTO_TYPE]} ${title.locative}${titleSeoTail}`
            }) : item);
    }, [ gallery, title, titleSeoTail, videoId ]);

    const imagesByType = useMemo(() => galleryElements.reduce<{
        [ index: string ]: GalleryElement[];
    }>((ret, image) => {
        const type = image?.type ?? '';

        if (ret[type]) {
            ret[type].push(image);
        } else {
            ret[type] = [ image ];
        }

        return ret;
    }, {}), [ galleryElements ]);

    const galleryContext = useContext(GalleryContext);
    const handleFullGallery = useCallback((elements: GalleryElement[], currentIndex: number) => {
        galleryContext?.open({
            elements,
            index: currentIndex,
            title: title?.nominative
        });
    }, [ galleryContext, title ]);

    return (
        <Grid>
            <Grid.Cell>
                <div className={cn()}>
                    <div className={cn('main', { onlyVideo })}>
                        <CarouselGallery
                            view='cover'
                            hidePreviews
                            onClickMain={handleFullGallery}
                            elements={galleryElements}
                            caption={title && titleSeoTail ? `Фотографии ${title.genitive}${titleSeoTail}` : undefined}
                            mapAnchor={mapAnchor}
                            categoryBlock={(
                                <GalleryCategoryBlock
                                    imagesByType={imagesByType}
                                    handleFullGallery={handleFullGallery}
                                    galleryElements={galleryElements}
                                />
                            )}
                            specialEventTitle={specialEventTitle}
                            specialEventsAnchor={specialEventsAnchor}
                            isSoldOut={isSoldOut}
                            isSoon={isSoon}
                        />
                    </div>
                </div>
            </Grid.Cell>
        </Grid>
    );
};

const GalleryCategoryBlock: React.FunctionComponent<{
    imagesByType: {
        [ index: string ]: GalleryElement[];
    };
    galleryElements: GalleryElement[];
    handleFullGallery: (elements: GalleryElement[], currentIndex: number) => void;
}> = ({
    imagesByType,
    galleryElements,
    handleFullGallery
}) => {
    const openFSGallery = useCallback(
        () => handleFullGallery(galleryElements, 0),
        [ handleFullGallery, galleryElements ]
    );

    const openFSGalleryCategory = useCallback((type: string) => {
        const imgIdx = galleryElements.findIndex(img => img.type === type);

        handleFullGallery(galleryElements, imgIdx);
    }, [ galleryElements, handleFullGallery ]);

    const hasOneOrLessGalleryElements = useMemo(() => galleryElements.length <= 1, [ galleryElements.length ]);

    return hasOneOrLessGalleryElements ? null : ( // пока нет панорамы и видеоролика, завязываемся на кол-во фоток
        <ul className={cn('category')}>
            {/*TODO: Раскомментить, когда появится панорама*/}
            {/*<GalleryCategoryButton
                icon='panorama'
                tooltipText='Панормама улицы'
                handleButtonClick={() => {}}
            />*/}
            {
                galleryElements
                    .filter(({ type }) => type === 'VIDEO')
                    .map(item => (
                        <GalleryCategoryButton
                            key={(item as GalleryVideo).videoId}
                            icon='video'
                            tooltipText='Видеоролик о ЖК'
                            handleButtonClick={() => openFSGalleryCategory((item as GalleryVideo).type)}
                        />
                    ))
            }
            {
                hasOneOrLessGalleryElements ? null : (
                    <GalleryCategoryButton
                        icon='camera'
                        text={String(galleryElements.length)}
                        tooltipText='Открыть галерею'
                        handleButtonClick={openFSGallery}
                    />
                )
            }
            {
                Object
                    .entries(imagesByType)
                    .filter(([ type ]) => type !== 'COMMON' && type !== 'VIDEO')
                    .map(([ type ]) => (
                        <GalleryCategoryButton
                            key={type}
                            text={PHOTO_TYPE[type as keyof typeof PHOTO_TYPE]}
                            tooltipText='Открыть галерею'
                            handleButtonClick={() => openFSGalleryCategory(type)}
                        />
                    ))
            }
        </ul>
    );
};

const GalleryCategoryButton = ({
    text,
    tooltipText,
    handleButtonClick,
    icon
}: {
    tooltipText: string;
    handleButtonClick: () => void;
    text?: string;
    icon?: ButtonProps['icon'];
}) => {
    const {
        to,
        registerControl
    } = useTooltip();

    return (
        <li
            ref={registerControl}
            className={cn('category-item')}
        >
            <Button
                icon={icon}
                size='s'
                variant='primary-alt-bg'
                onClick={handleButtonClick}
            >
                {text}
            </Button>
            <Tooltip to={to} direction='down'>{tooltipText}</Tooltip>
        </li>
    );
};

export default React.memo(NewBuildingCardGallery);
