import React, { useCallback, useState } from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import { SvgSpriteIcon } from '../SvgSpriteIcon';

import './styles.css';

const cn = classname.bind(null, 'FoldingBlock');

export const FoldingBlock: React.FunctionComponent<{
    title: string;
    content: string;
    isMobile?: boolean;
}> = ({
    title,
    content,
    isMobile = false
}) => {
    const [ isFolded, toggleFold ] = useState<Boolean>(true);
    const handleClick = useCallback(() => toggleFold(v => ! v), []);

    return (
        <div
            className={cn(null, { folded: isFolded })}
            onClick={handleClick}
        >
            <Typography
                className={cn('title')}
                variant={isMobile ? 'primary' : 'h4'}
                tag='h2'
                bold
            >
                {title}
                <div className={cn('chevron')}>
                    <SvgSpriteIcon name='Chevron' />
                </div>
            </Typography>
            <Typography className={cn('content')}>
                {content}
            </Typography>
        </div>
    );
};
