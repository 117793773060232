import React, { useCallback, useContext, useState } from 'react';

import classname from '@search/classname/src';
import { formatRange } from '@search/helpers/src/formatNumber';
import {
    Developer,
    DeveloperCard,
    NewBuildingSnippet as GQLNewBuildingSnippet,
    NewBuildingWhitelabelEnum
} from '@search/graphql-typings';
import { MortgageModal } from '@search/vtbeco-frontend-core/view/desktop/components/MinimalBanksMortgageSnippetDesktop';
import { DATA_GTM, VTB_IPOTEKA_LINK } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import { LinkButton, Typography } from '@vtblife/uikit';
import { IUseToggleValueReturnType } from '@search/hooks/src/useToggleValue';
import SnippetPhone from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/SnippetPhone';
import { CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import Tooltip from '@search/vtbeco-ui/src/components/controls/Tooltip';
import { ToneEnum, ViewEnum } from '@search/vtbeco-ui/src/types';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import { vtbMortageDisclaimer } from '../../../../dict/mortgage';
import { SvgSpriteIcon } from '../../../common/components/SvgSpriteIcon';
import { GlobalContext } from '../../../common/components/GlobalContext';
import ChatButton from '../../../common/components/ChatButton';
import { defineCallbackEmailSender } from '../../../common/libs/defineEmailSender';
import { useDeveloperNames } from '../../../common/components/NewBuildingCard';
import { ChatStateProvider } from '../../../common/components/NewBuildingCard/ChatStateProvider';
import RewardAmountIcon from '../../../common/components/NewBuildingCard/NewBuildingCardOfferSearch/RewardAmountIcon';
import useMortgageDemandLink from '../../../common/hooks/useMortgageDemandLink';
import { M2ProOrderButton } from '../../../common/components/M2ProOrderButton';
import { VTB_MORTGAGE_RATE_APARTS, VTB_MORTGAGE_RATE_FLATS } from '../../../common/constants/mortgage';

import './BottomBlock.css';

const cn = classname.bind(null, 'NewBuildingSerpSnippetBottomBlock');

interface IBottomBlock {
    showRegionMortgageButtons: boolean;
    newBuilding: GQLNewBuildingSnippet;
    url: string;
    phoneHoverToggle: IUseToggleValueReturnType;
    hoverToggle: IUseToggleValueReturnType;
    developer?: Developer | null;
    handlePhoneEventPush: () => void;
}

const BottomBlock: React.FunctionComponent<IBottomBlock> = ({
    showRegionMortgageButtons,
    newBuilding,
    url,
    phoneHoverToggle,
    hoverToggle,
    developer,
    handlePhoneEventPush
}) => {
    const {
        instanceConfig: {
            searchIndex,
            calltouchUnitId
        },
        calltouchModId
    } = useContext(GlobalContext);

    const isVtb = searchIndex === NewBuildingWhitelabelEnum.Vtb;

    const { auth } = useM2AuthContext();

    const rewardAmount = null;
    const { prepayment } = newBuilding.m2Pro || {};
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);
    const hasM2Pro = hasRoleM2Pro && Boolean(rewardAmount);

    const developerNames = useDeveloperNames(newBuilding.developerCards as DeveloperCard[]);

    const { ranking } = newBuilding;
    const onStatEventPush = useCallback(() => dataLayerPush({
        event: 'nb_call_like',
        nb_price: newBuilding.priceMin ?? 0,
        nb_class: ranking ?? ''
    }), [ newBuilding.priceMin, ranking ]);

    const renderCallBack = useCallback((
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string,
        subjectExtra?: string
    ) => (
        <CallbackModal
            btnWrapperClass={cn('callBack')}
            newBuildingName={newBuilding.title.nominative}
            address={newBuilding.address}
            emailSender={defineCallbackEmailSender(newBuilding.isMskMO, newBuilding.isSPbLO)}
            developerName={developerNames}
            extra={extra}
            subjectExtra={subjectExtra}
            gtmSendClick={sendDataGTM}
            onSendEventPush={onStatEventPush}
            gtmShowModal={dataGTM}
            callbackUnitId={calltouchUnitId[newBuilding.isSPbLO ? 'Spb' : 'Msk']}
            withoutIconOnButton
            buttonSize='s'
        />
    ), [ newBuilding.title.nominative, newBuilding.address, newBuilding.isMskMO, newBuilding.isSPbLO, developerNames, onStatEventPush, calltouchUnitId ]);

    const [ isModalOpen, toggleModal ] = useState<boolean>(false);
    const openModal = useCallback(() => toggleModal(true), []);
    const closeModal = useCallback(() => toggleModal(false), []);

    const mortgageDemandLink = useMortgageDemandLink({
        regionId: newBuilding.mortgageParams.regionId,
        propertyCost: newBuilding.mortgageParams.propertyCost,
        purpose: newBuilding.mortgageParams.purposeValue,
        claimType: newBuilding.mortgageParams.claimType,
        ownAmount: newBuilding.mortgageParams.ownAmount,
        newBuildingName: newBuilding.title.nominative
    });

    const renderButtons = useCallback(() => {
        if (hasM2Pro) {
            return (
                <div className={cn('m2ProButtons')}>
                    <div
                        onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                        onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                    >
                        <M2ProOrderButton
                            newBuildingId={newBuilding.id}
                            size='s'
                            variant={
                                hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                    'primary' :
                                    'secondary'
                            }
                            fullWidth
                            eventData={{ page_type: 'serp' }}
                        />
                    </div>
                    <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                        <ChatButton
                            hasText
                            fullWidth
                            hasIcon={false}
                        />
                    </ChatStateProvider>
                </div>
            );
        }

        if (showRegionMortgageButtons) {
            return (
                <div className={cn('phoneButtons')}>
                    <div
                        onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                        onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                        className={cn('regionMortgageButton')}
                    >
                        <LinkButton
                            size='s'
                            variant={
                                hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                    'accent' :
                                    'secondary'
                            }
                            onClick={hasRoleM2Pro ? undefined : openModal}
                            href={hasRoleM2Pro ? mortgageDemandLink : undefined}
                        >
                            Рассчитать ипотеку
                        </LinkButton>
                    </div>
                    {
                        isModalOpen && url ? (
                            <MortgageModal
                                mortgageRegionId={newBuilding.mortgageParams.regionId}
                                propertyCost={newBuilding.mortgageParams.propertyCost}
                                ownAmount={newBuilding.mortgageParams.ownAmount}
                                term={newBuilding.mortgageParams.term}
                                url={url}
                                isNewBuilding
                                onClose={closeModal}
                            />
                        ) : null
                    }
                </div>
            );
        }

        return (
            <div className={cn('phoneButtons')}>
                {isVtb && (
                    <LinkButton
                        size='s'
                        href={`${VTB_IPOTEKA_LINK}?from=serp`}
                        target='blank'
                        variant='secondary'
                    >
                        Оформить заявку
                    </LinkButton>
                )}
                {developer && (
                    <SnippetPhone
                        phoneParams={{
                            phoneNum: 0,
                            developerId: developer.id,
                            newBuildingId: Number(newBuilding.id),
                            whitelabel: searchIndex
                        }}
                        onPhoneEventPush={handlePhoneEventPush}
                        render={({ phone, handleClick, href, loading }) => (
                            <div
                                className={cn('phone')}
                                onMouseOver={phoneHoverToggle.handleSetTrueTogVal}
                                onMouseLeave={phoneHoverToggle.handleSetFalseTogVal}
                            >
                                <LinkButton
                                    size='s'
                                    onClick={() => handleClick()}
                                    href={href}
                                    variant={
                                        hoverToggle.isToggleValue || phoneHoverToggle.isToggleValue ?
                                            'primary' :
                                            'secondary'
                                    }
                                    loading={loading}
                                    fullWidth
                                >
                                    {phone}
                                </LinkButton>
                            </div>
                        )}
                        calltouchId={calltouchModId}
                    />
                )}
                {! isVtb && (
                    <>
                        {(! newBuilding.isSpecProjectRegion || newBuilding.isMskMO || newBuilding.isSPbLO) && renderCallBack(
                            DATA_GTM.CARD_CALL_BACK_BUILDING,
                            DATA_GTM.CARD_CALL_BACK_SEND_BUILDING
                        )}
                        {newBuilding.hasChat ? (
                            <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                                <ChatButton className={cn('openChat')} />
                            </ChatStateProvider>
                        ) : null}
                    </>
                )}
            </div>
        );
    }, [
        hasM2Pro,
        showRegionMortgageButtons,
        isVtb,
        developer,
        newBuilding.id,
        newBuilding.isSpecProjectRegion,
        newBuilding.hasChat,
        newBuilding.title.accusative,
        newBuilding.mortgageParams.regionId,
        newBuilding.mortgageParams.propertyCost,
        newBuilding.mortgageParams.ownAmount,
        newBuilding.mortgageParams.term,
        newBuilding.isMskMO,
        newBuilding.isSPbLO,
        searchIndex,
        handlePhoneEventPush,
        calltouchModId,
        renderCallBack,
        phoneHoverToggle.handleSetTrueTogVal,
        phoneHoverToggle.handleSetFalseTogVal,
        phoneHoverToggle.isToggleValue,
        hoverToggle.isToggleValue,
        hasRoleM2Pro,
        openModal,
        mortgageDemandLink,
        isModalOpen,
        url,
        closeModal
    ]);

    return (
        <div className={cn()}>
            <div className={cn('content')}>
                <div className={cn('contentBlock', { m2Pro: hasM2Pro })}>
                    {newBuilding.priceMin && (
                        <Typography variant='h3'>
                            {formatRange(newBuilding.priceMin, null, {
                                billionsAsString: true
                            })} ₽
                        </Typography>
                    )}
                    {hasM2Pro && (
                        <div className={cn('m2pro')}>
                            {rewardAmount && (
                                <RewardAmountIcon amount={rewardAmount} />
                            )}
                            {prepayment && (
                                <SvgSpriteIcon
                                    name='M2ProPrepayment'
                                    width={18}
                                    height={22}
                                    className={cn('prepayment')}
                                />
                            )}
                        </div>
                    )}
                    {
                        newBuilding.hasEscrow && ! isVtb && ! hasM2Pro && (
                            <div className={cn('escrow')}>
                                <SvgSpriteIcon
                                    name='TickNew'
                                    width={16}
                                    height={16}
                                />
                                <Typography
                                    tag='span'
                                    className={cn('escrowText')}
                                    variant='primary-alone'
                                    color='black-400'
                                >
                                    Эскроу счет
                                </Typography>
                            </div>
                        )
                    }
                    {
                        isVtb && (
                            <Typography className={cn('vtb-mortgage-min-pay')}>
                                Ипотека от {newBuilding.hasApartments ? VTB_MORTGAGE_RATE_APARTS : VTB_MORTGAGE_RATE_FLATS} %
                                <Tooltip
                                    hasTail
                                    tone={ToneEnum.DARK}
                                    view={ViewEnum.PRIMARY}
                                    renderTrigger={({ triggerRef, handleShow, handleClose, isVisible }) =>
                                        <span
                                            style={{
                                                color: isVisible ? 'black' : 'grey',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}
                                            ref={triggerRef}
                                            onMouseLeave={handleClose}
                                            onMouseEnter={handleShow}
                                            className={cn('vtbQuestionCircle')}
                                        >
                                                &nbsp;
                                            <SvgSpriteIcon
                                                name='VtbQuestionCircle'
                                                width={16}
                                                height={16}
                                            />
                                        </span>
                                    }
                                    renderContent={() => (
                                        <Typography
                                            variant='secondary-alone'
                                            className={cn('vtb-mortgage-tooltip-content')}
                                        >
                                            {vtbMortageDisclaimer[newBuilding.hasApartments ? 'apartments' : 'flats']}
                                        </Typography>
                                    )}
                                />
                            </Typography>
                        )
                    }
                </div>
                {renderButtons()}
            </div>
        </div>
    );
};

export default BottomBlock;
