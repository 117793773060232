import React from 'react';

import { SvgSpriteIcon } from '@search/whitelabel-search-www/client/common/components/SvgSpriteIcon';
import { NewBuildingOffer } from '@search/graphql-typings';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';
import { Building } from '@search/whitelabel-search-www/client/common/components/NewBuildingCard';

export default function RewardAmountIcon({ amount }: { amount: number }) {
    if (amount < 1) {
        return (
            <SvgSpriteIcon width={6} name='M2ProLow' />
        );
    }

    if (amount < 2) {
        return (
            <SvgSpriteIcon width={16} name='M2ProMiddle' />
        );
    }

    return (
        <SvgSpriteIcon width={24} name='M2ProHigh' />
    );
}

export function BuildingsRewardAmountIcon({
    offerBuildingId, // eslint-disable-line
    buildings // eslint-disable-line
}:{
    offerBuildingId: NewBuildingOffer['buildingId'];
    buildings: Building[];
}) {
    const { auth } = useM2AuthContext();
    const hasRoleM2Pro = isM2ProUser(auth.user?.currentRoleType);

    // const offerBuilding = buildings.find(building => building.id === offerBuildingId);

    const rewardAmount = null; //offerBuilding?.m2Pro?.rewardAmount;

    if (! hasRoleM2Pro || ! rewardAmount) {
        return null;
    }

    return (
        <RewardAmountIcon amount={rewardAmount} />
    );
}
