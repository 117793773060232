/* global ymaps */
import { IconCenteringStr } from '../../Icon';
import { YMapControlButtonTemplate, YMapControlButtonDisabledClass } from '../Button';
import { YMapControl, YMapControlProps } from '../Control';

type YMapControlCenteringProps = YMapControlProps;

const baseClass = 'YMapControlCentering';
const PRECISION = 1e-5;

export function YMapControlCentering({
    className,
    index,
    dataTest,
    title
}: YMapControlCenteringProps) {
    YMapControlCenteringY.use({
        index,
        dataTest,
        className,
        title
    } as YMapControlCenteringProps);

    return null;
}

class YMapControlCenteringY extends YMapControl<YMapControlCenteringProps, ymaps.control.IButton> {
    protected create() {
        return (new this.ymaps.control.Button({
            data: this.props,
            options: {
                layout: this.layoutClass()
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any) as unknown) as ymaps.control.IButton;
    }

    protected template() {
        const { props } = this;

        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            className: `${baseClass} ${props.className ?? ''}`,
            dataTest: props.dataTest,
            children: IconCenteringStr,
            title: props.title
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        return class YMapCenteringLayout extends Layout {
            center: [number, number] = [ 0, 0 ];

            build() {
                super.build();
                const el = this.buttonsEl;
                const map = this.map;
                const update = this.update.bind(this);
                const centering = this.centering.bind(this);

                el.addEventListener('click', centering);
                map.events.add('boundschange', update, this);

                this.center = map.getCenter() as [number, number];

                el.classList.add(YMapControlButtonDisabledClass);

                const dispose = this.dispose;

                this.dispose = () => {
                    dispose();
                    map.events.remove('boundschange', update, this);
                    el.removeEventListener('click', centering);
                };
            }

            protected get buttonsEl() {
                const el = this.getElement().querySelector(`.${baseClass}`);

                if (! el) throw new Error(`element not found in ${el}`);

                return el;
            }

            protected centering() {
                const map = this.map;
                const el = this.buttonsEl;

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                map.setCenter(this.center);

                el.classList.add(YMapControlButtonDisabledClass);
            }

            protected update() {
                const el = this.buttonsEl;
                const map = this.map;
                const center = map.getCenter();
                const latitudeDiff = Math.abs(this.center[0] - center[0]);
                const longitudeDiff = Math.abs(this.center[1] - center[1]);

                if (latitudeDiff > PRECISION || longitudeDiff > PRECISION) {
                    el.classList.remove(YMapControlButtonDisabledClass);
                }
            }

            protected destroy() {
                this.dispose();
            }
        };
    }
}
