import React, { useContext } from 'react';
import { Modal, Button } from '@vtblife/uikit';
import { DeveloperPromoPlaceholders, NewBuildingWhitelabelEnum } from '@search/graphql-typings';

import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';
import { Filters } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import type { IFilter, RegionFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { DesktopWallsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/walls/DesktopWallsFilterView';
import { DesktopParkingFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/parking/DesktopParkingFilterView';
import { DesktopBalconyFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/balcony/DesktopBalconyFilterView';
import { DesktopApartmentFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/apartments/DesktopApartmentFilterView';
import { DesktopClosedSalesFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_closedSales/DesktopClosedSalesFilterView';
import { DesktopBuildingClassFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_building_class/DesktopBuildingClassFilterView';
import { DesktopPositionFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_position/DesktopPositionFilterView';
import { DesktopSafetyFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_safety/DesktopSafetyFilterView';
import { DesktopFlatsSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/special_events/DesktopFlatsSpecialEventsFilterView';
import { DesktopOtherSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/special_events/DesktopOtherSpecialEventsFilterView';
import { DesktopMiscSpecialEventsFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/special_events/DesktopMiscSpecialEventsFilterView';
import { DesktopAccreditationFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_accreditation/DesktopAccreditationFilterView';
import { DesktopNewBuildingDealFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_new_building_deal/DesktopNewBuildingDealFilterView';
import { DesktopPaymentTypeFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/desktop/new_payment_type/DesktopPaymentTypeFilterView';
import { LivingAreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/living_area/LivingAreaFilter';
import { KitchenAreaFromFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/kitchen_area/KitchenAreaFilter';
import { FloorFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/floor/FloorFilter';
import { FloorsTotalFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/floors_total/FloorsTotalFilter';
import { CeilHeightFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/ceil_height/CeilHeightFilter';
import { BathroomFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/bathroom/BathroomFilter';
import { AreaFilterView } from '@search/vtbeco-frontend-core/view/filters/components/filter_types/area/AreaFilter';

import { AddQueryDisplayValue, QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';

import { formatNumber } from '@search/helpers/src/formatNumber';

import type { CustomFilterBehavior } from '../../../common/components/NewNewBuildingSearchFilters/useSearchFilters';
import { GlobalContext, IGlobalContext } from '../../../common/components/GlobalContext';
import { useDeveloperFilterChange, useDeveloperFilterValue } from '../../../common/hooks/useDeveloperFilter';
import useDeveloperPromoPlaceholder from '../../../common/hooks/useDeveloperPromoPlaceholder';
import { DesktopDeveloperFilterView } from './NewDesktopDeveloperFilterView';
import { NewNewBuildingSearchFiltersExtraView } from './ExtraView';

interface IProps {
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
    submitFilters: () => void;
    resetFilters: () => void;
    areExtraFiltersVisible: boolean;
    showExtraFilters: () => void;
    hideExtraFilters: () => void;
    customFilterBehavior: CustomFilterBehavior;
    queryDisplayValues: QueryDisplayValues;
    addQueryDisplayValue: AddQueryDisplayValue;
    count: number;
    isLoading?: boolean;
    placeholders: DeveloperPromoPlaceholders;
}

export const NewNewBuildingSearchFiltersMoreButton: React.FC<IProps> = ({
    filters,
    updateFilter,
    submitFilters,
    resetFilters,
    areExtraFiltersVisible,
    showExtraFilters,
    hideExtraFilters,
    customFilterBehavior,
    addQueryDisplayValue,
    queryDisplayValues,
    count,
    isLoading,
    placeholders
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const { searchIndex } = globalContext.instanceConfig;

    const regionId = (filters.get<RegionFilter>(Filters.region)).region;

    const submitedText = (count > 0) ?
        `Показать ${formatNumber(count)}` :
        'Нет результатов';

    const developerValue = useDeveloperFilterValue({
        filters,
        queryDisplayValues
    });

    const handleDeveloperFilterChange = useDeveloperFilterChange({
        customFilterBehavior,
        addQueryDisplayValue
    });

    const { developer: placeholder } = useDeveloperPromoPlaceholder({ placeholders });

    return (
        <div>
            {
                areExtraFiltersVisible && (
                    <Modal
                        size={'l'}
                        onClose={hideExtraFilters}
                        opened
                        overflow={false}
                    >
                        <Modal.Content>
                            <NewNewBuildingSearchFiltersExtraView
                                placeholders={placeholders}
                                regionId={regionId}
                                totalArea={
                                    <AreaFilterView
                                        filter={filters.get(Filters.totalArea)}
                                        handleChange={updateFilter}
                                    />
                                }
                                livingArea={
                                    <LivingAreaFilterView
                                        filter={filters.get(Filters.livingArea)}
                                        handleChange={updateFilter}
                                    />
                                }
                                kitchenArea={
                                    <KitchenAreaFromFilterView
                                        filter={filters.get(Filters.kitchenArea)}
                                        handleChange={updateFilter}
                                    />
                                }
                                floor={
                                    <FloorFilterView
                                        filter={filters.get(Filters.floor)}
                                        handleChange={updateFilter}
                                        renderFloorTotalFilter={
                                            <FloorsTotalFilterView
                                                filter={filters.get(Filters.floorsTotal)}
                                                handleChange={updateFilter}
                                            />
                                        }
                                    />
                                }
                                ceilingHeight={
                                    <CeilHeightFilterView
                                        filter={filters.get(Filters.cellHeight)}
                                        handleChange={updateFilter}
                                    />
                                }
                                bathroom={
                                    <BathroomFilterView
                                        filter={filters.get(Filters.bathroom)}
                                        handleChange={updateFilter}
                                    />
                                }
                                balcony={
                                    <DesktopBalconyFilterView
                                        filter={filters.get(Filters.balcony)}
                                        handleChange={updateFilter}
                                    />
                                }
                                developer={
                                    <DesktopDeveloperFilterView
                                        value={developerValue}
                                        onChange={handleDeveloperFilterChange}
                                        placeholder={placeholder}
                                    />
                                }
                                closedSales={
                                    searchIndex === NewBuildingWhitelabelEnum.Default && (
                                        <DesktopClosedSalesFilterView
                                            filter={filters.get(Filters.closedSales)}
                                            handleChange={updateFilter}
                                        />
                                    )
                                }
                                buildingClass={
                                    <DesktopBuildingClassFilterView
                                        filter={filters.get(Filters.buildingClass)}
                                        handleChange={updateFilter}
                                    />
                                }
                                parking={
                                    <DesktopParkingFilterView
                                        filter={filters.get(Filters.parkings)}
                                        handleChange={updateFilter}
                                    />
                                }
                                walls={
                                    <DesktopWallsFilterView
                                        withWood={false}
                                        filter={filters.get(Filters.walls)}
                                        handleChange={updateFilter}
                                    />
                                }
                                apartments={
                                    <DesktopApartmentFilterView
                                        filter={filters.get(Filters.apartments)}
                                        handleChange={updateFilter}
                                    />
                                }
                                position={
                                    <DesktopPositionFilterView
                                        filter={filters.get(Filters.position)}
                                        handleChange={updateFilter}
                                    />
                                }
                                safety={
                                    <DesktopSafetyFilterView
                                        filter={filters.get(Filters.safety)}
                                        handleChange={updateFilter}
                                    />
                                }
                                flatsSpecialEvents={
                                    <DesktopFlatsSpecialEventsFilterView
                                        filter={filters.get(Filters.flatsSpecialEvents)}
                                        handleChange={customFilterBehavior.flatsSpecialEvents.update}
                                    />
                                }
                                otherSpecialEvents={
                                    <DesktopOtherSpecialEventsFilterView
                                        filter={filters.get(Filters.otherSpecialEvents)}
                                        handleChange={customFilterBehavior.otherSpecialEvents.update}
                                    />
                                }
                                miscSpecialEvents={
                                    <DesktopMiscSpecialEventsFilterView
                                        filter={filters.get(Filters.miscSpecialEvents)}
                                        handleChange={customFilterBehavior.miscSpecialEvents.update}
                                    />
                                }
                                accreditation={
                                    <DesktopAccreditationFilterView
                                        filter={filters.get(Filters.accreditation)}
                                        handleChange={updateFilter}
                                    />
                                }
                                deal={
                                    <DesktopNewBuildingDealFilterView
                                        filter={filters.get(Filters.newBuildingDeal)}
                                        handleChange={updateFilter}
                                    />
                                }
                                sale={
                                    <DesktopPaymentTypeFilterView
                                        filter={filters.get(Filters.paymentType)}
                                        handleChange={updateFilter}
                                    />
                                }
                                buttonReset={
                                    <Button
                                        fullWidth
                                        variant='secondary'
                                        onClick={resetFilters}
                                        dataTest='filter-more-reset'
                                    >
                                        Сбросить
                                    </Button>
                                }
                                buttonSubmit={
                                    <Button
                                        fullWidth
                                        onClick={submitFilters}
                                        dataTest='filter-more-submit'
                                        loading={isLoading}
                                    >
                                        {submitedText}
                                    </Button>
                                }
                            />
                        </Modal.Content>
                        <Modal.WideContent footer>
                            <div style={{ height: '8px' }} />
                        </Modal.WideContent>
                    </Modal>
                )
            }
            <Button
                fullWidth
                rounded='none'
                dataTest='filter-more-show'
                variant='secondary'
                onClick={showExtraFilters}
            >
                Ещё
            </Button>
        </div>
    );
};
