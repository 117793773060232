import React, { useContext, useRef } from 'react';

import classname from '@search/classname/src';

import { useGql } from '@search/gql-client/src';

import Slider from '@search/vtbeco-frontend-core/view/common/components/Slider';
import type { NewBuildingSnippet, PagingInput } from '@search/graphql-typings';
import { errorCatcherWrap } from '@search/error/src/catcher';
import { useIdle } from '@search/vtbeco-frontend-core/view/common/hooks/useIdle';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { isM2ProUser } from '@search/auth/src/helpers/isM2ProUser';

import type { Required } from '../../../../../types';
import type { Shared } from '../../../../../types/shared';

import { GlobalContext } from '../../../../common/components/GlobalContext';
import { NewBuildingTinySnippet } from '../../../../common/components/NewBuildingTinySnippet';
import type {
    NewBuildingCardSimilarNewBuildingsQueryResponse,
    NewBuildingCardSimilarNewBuildingsQueryVariables,
    NewBuildingClassEnum,
    NewBuildingsInput
} from '../../../../common/components/NewBuildingCard';
import { similarNewBuildingsQuery } from '../../../../common/components/NewBuildingCard';
import { useIsMskMOSPbLO } from '../../../../common/hooks/useIsMskMOSPbLO';
import LinkToQuizModal from '../../../../common/components/LinkToQuizModal';
import useLinkToQuizModal from '../../../../common/components/LinkToQuizModal/useLinkToQuizModal';

import './styles.css';

const NewBuildingTinySnippetWithCatcher = errorCatcherWrap(NewBuildingTinySnippet);

const cn = classname.bind(null, 'NewBuildingCardSimilar');

const NewBuildingCardSimilar = ({
    heading,
    newBuildingId: newBuildingIdToSkip,
    regionIds: [ regionId, backupRegionId ],
    buildingClasses: buildingClass
}: {
    heading: React.ReactNode;
    newBuildingId: number;
    regionIds: number[];
    buildingClasses: NewBuildingClassEnum[];
    pageParams?: Shared.INewBuildingCardParams;
}) => {
    const {
        instanceConfig: {
            searchIndex,
            hasQuizIdle
        }
    } = useContext(GlobalContext);
    const { data } = useGql<
        NewBuildingCardSimilarNewBuildingsQueryVariables,
        NewBuildingCardSimilarNewBuildingsQueryResponse
    >(similarNewBuildingsQuery, {
        isSimilar: true,
        touch: false,
        whiteLabel: searchIndex,
        filters: {
            newBuildingIdToSkip,
            regionId,
            backupRegionId,
            buildingClass
        } as unknown as Required<NewBuildingsInput>,
        paging: {
            pageSize: 13
        } as Required<PagingInput>
    });

    const { auth } = useM2AuthContext();
    const hasM2Pro = isM2ProUser(auth.user?.currentRoleType);

    const isMskMOSPbLO = useIsMskMOSPbLO(regionId);
    const containerRef = useRef<HTMLDivElement>(null);
    const [ isOpenLinkToQuizModal, setIsOpenLinkToQuizModal ] = useLinkToQuizModal(
        containerRef,
        'isLinkToQuizModalShownNBCard',
        hasQuizIdle && ! hasM2Pro && isMskMOSPbLO
    );
    const { isIdle, resetIdle } = useIdle({ idleTimeout: 40 });
    const shouldShowQuizModal = hasQuizIdle && isMskMOSPbLO && ! hasM2Pro && (isIdle || isOpenLinkToQuizModal);

    const items = data?.searchNewBuildings?.items || [];

    if (! items.length) {
        return null;
    }

    return (
        <div className={cn()}>
            {heading}
            <div className={cn('content')} ref={containerRef}>
                <Slider contentClassName={cn('sliderContent')}>
                    {items.map((item: NewBuildingSnippet) => (
                        <NewBuildingTinySnippetWithCatcher
                            key={item?.id}
                            newBuilding={item}
                        />
                    ))}
                </Slider>
            </div>
            {
                shouldShowQuizModal ? (
                    <LinkToQuizModal
                        onClose={() => {
                            setIsOpenLinkToQuizModal(false);
                            resetIdle();
                        }}
                        headText='Не уверены, подойдет ли вам это предложение? Найдём варианты интереснее'
                        text='Пройдите тест и&nbsp;получите персональную подборку новостроек'
                        pageType='card'
                    />
                ) : null
            }
        </div>
    );
};

export default NewBuildingCardSimilar;
