type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never
type MergeUnion<U> = UnionToIntersection<U> extends infer O ? { [K in keyof O]: O[K] } : never;
type ReadonlyItem<T> = T extends (infer Item)[] ? readonly Item[] : T;
type NullablePartial<T> = { [P in keyof T]?: ReadonlyItem<T[P]> | null; };
type ObjectValuesMerge<Obj> = NullablePartial<MergeUnion<NonNullable<Obj[keyof Obj]>>>

type MapperParams<Mapper extends (ctx: any) => object> = ObjectValuesMerge<ReturnType<Mapper>>
type MapperContext<Mapper> = Mapper extends (ctx: infer Context) => unknown ? Context : never;
type MapperResult<Mapper> = Mapper extends (ctx: unknown) => infer Result ? Result : never;

export class SeoChpuMapper<
    Mapper extends (ctx: {}) => Record<string, object | undefined | null>
> {
    constructor(protected context: MapperContext<Mapper>) {
        this.context = context;
    }

    // eslint-disable-next-line max-len
    tagsIntersection = (this.constructor as unknown as { tagsIntersection: Map<keyof ReturnType<Mapper>, string[]> }).tagsIntersection;
    extraTagWeight = (this.constructor as unknown as { extraTagWeight: Map<string, number> }).extraTagWeight;
    chpuMap() { return (this.constructor as unknown as { chpuMap: Mapper }).chpuMap(this.context); }

    fromChpu(chpuKeys = [] as readonly (string | null | undefined)[]) {
        const chpuMap = this.chpuMap() as MapperResult<Mapper>;

        type TagMap = MapperResult<Mapper>;
        type Params = MapperParams<Mapper>;
        const params = {} as Params;
        const chpuUsed = [] as (keyof TagMap)[];

        for (let i = 0; i < chpuKeys.length; i++) {
            const chpuKey = chpuKeys[i] as (keyof TagMap | undefined);

            if (! chpuKey) continue;
            const chpuValues = chpuMap[chpuKey] as Params;

            if (! chpuValues) continue;
            const paramKeys = Object.keys(chpuValues) as (keyof Params)[];

            chpuUsed.push(chpuKey);

            for (let j = 0; j < paramKeys.length; j++) {
                const paramKey = paramKeys[j];
                const val = params[paramKey];
                let nextVal = chpuValues[paramKey];

                if (Array.isArray(nextVal) && Array.isArray(val)) {
                    nextVal = [ ...val, ...nextVal ] as unknown as Params[keyof Params];
                }

                params[paramKey] = nextVal as Params[keyof Params];
            }
        }

        // @ts-ignore
        const chpuUsedTagsCount = this.calcTagCount(chpuUsed);

        return { params, chpuUsed, chpuUsedTagsCount };
    }

    maxChpu() {
        return 2;
    }

    calcTagCount(keys: string[]) {
        return keys.reduce((acc, key) => {
            acc += this.extraTagWeight.get(key) ?? 1;

            return acc;
        }, 0);
    }

    resolveChpuInsersectionConflicts(chpuSelectedKeysArr: (keyof ReturnType<Mapper>)[]) {
        if (chpuSelectedKeysArr.length === 0) return [];

        const chpuSelectedKeys = new Set(chpuSelectedKeysArr);

        chpuSelectedKeysArr.forEach(selectedKey => {
            if (this.tagsIntersection.has(selectedKey)) {
                this.tagsIntersection.get(selectedKey)?.forEach(containedKey => chpuSelectedKeys.delete(containedKey));
            }
        });

        return Array.from(chpuSelectedKeys);
    }

    toChpu(params: MapperParams<Mapper>, currentTagCount = 0) {
        type Params = MapperParams<Mapper>;

        const chpuMap = this.chpuMap();
        let chpuKeys = [] as (keyof ReturnType<Mapper>)[];
        let chpuParams = {} as Params;

        if (currentTagCount > this.maxChpu()) {
            return {
                chpuKeys,
                chpuParams,
                chpuKeysTagsCount: currentTagCount
            };
        }

        const keys = Object.keys(chpuMap) as (keyof typeof chpuMap)[];
        let chpuKeysTagsCount = currentTagCount;

        for (let i = 0; i < keys.length; i++) {
            const chpuKey = keys[i];

            const chpuParamsTemplate = chpuMap[chpuKey] as Params;

            if (! chpuParamsTemplate) continue;

            const paramKeys = Object.keys(chpuParamsTemplate) as (keyof Params)[];
            const isAllEqual = checkEqualAllParams({
                chpuReferenceStandartMap: chpuParamsTemplate,
                chpuMap: params
            });

            if (! isAllEqual) continue;

            chpuKeys.push(chpuKey);
            // resolveChpuInsersectionConflicts({
            //     // @ts-ignore
            //     chpuSelectedKeys: chpuKeys,
            //     chpuSelected: (chpuKeys as string[]).map(c => chpuMap[c]),
            //     chpuNominee: chpuMap[chpuKey],
            //     chpuNomineeKey: chpuKey
            // });

            for (let j = 0; j < paramKeys.length; j++) {
                // Добавляем свойства, которые сконвертились в chpuKeys
                chpuParams[paramKeys[j]] = params[paramKeys[j]];
            }
        }

        chpuKeys = this.resolveChpuInsersectionConflicts(chpuKeys);

        // @ts-ignore
        chpuKeysTagsCount += this.calcTagCount(chpuKeys);
        if (chpuKeysTagsCount > this.maxChpu()) {
            // Тегов > 2, не переносим ничего в теги
            chpuParams = {};
            chpuKeys = [];
        }

        return { chpuKeys, chpuParams, chpuKeysTagsCount };
    }
}

function checkEqualAllParams({
    chpuReferenceStandartMap,
    chpuMap
}: {
    // Какие значения будут стандартом для эквивалентости
    chpuReferenceStandartMap: Record<string, any> | null;
    chpuMap: Record<string, any> | null;
}) {
    if (! chpuMap || ! chpuReferenceStandartMap) {
        return;
    }
    const chpuReferenceStandartParams = Object.keys(chpuReferenceStandartMap);
    let isAllEqual = true;

    for (let j = 0; j < chpuReferenceStandartParams.length; j++) {
        const chpuReferenceParamKey = chpuReferenceStandartParams[j];
        const chpuReferenceParamValue = chpuReferenceStandartMap[chpuReferenceParamKey];
        const chpuParamValue = chpuMap[chpuReferenceParamKey];

        if (chpuReferenceParamValue === chpuParamValue) continue;

        if (
            Array.isArray(chpuReferenceParamValue) &&
            Array.isArray(chpuParamValue) &&
            chpuReferenceParamValue.length === chpuParamValue.length
        ) {
            // длина равна, поэтому бежим по любой
            for (let k = 0; k < chpuReferenceParamValue.length; k++) {
                if (chpuReferenceParamValue[k] === chpuParamValue[k]) continue;
                isAllEqual = false;
                break;
            }
            if (isAllEqual) continue;
        }
        isAllEqual = false;
        break;
    }

    return isAllEqual;
}

// function resolveChpuInsersectionConflicts({
//     chpuSelectedKeys,
//     chpuSelected,
//     chpuNominee,
//     chpuNomineeKey
// }: {
//     chpuSelectedKeys: string[];
//     chpuSelected: (object | undefined | null)[];
//     chpuNominee: object | undefined | null;
//     chpuNomineeKey: string;
// }) {
//     if (! chpuNominee) return;
//     const chpuNomineeParams = Object.keys(chpuNominee);

//     // for splice next
//     const removeFromChpuSelectedIndexes: number[] = [];
//     let isPushNominee = true;

//     if (chpuSelected.length === 0) {
//         chpuSelectedKeys.push(chpuNomineeKey);
//         return;
//     }

//     for (let i = 0; i < chpuSelected.length; i++) {
//         const chpu = chpuSelected[i];

//         if (! chpu) {
//             continue;
//         }

//         const chpuParams = Object.keys(chpu);

//         // если нет пересекающихся ключей - не идём дальше
//         if (new Set([ ...chpuParams, ...chpuNomineeParams ]).size === (chpuNomineeParams.length + chpuParams.length)) {
//             // push
//             break;
//         }

//         if (chpuParams.length <= chpuNomineeParams.length) {
//             const isContained = ! checkEqualAllParams({
//                 chpuReferenceStandartMap: chpuNominee,
//                 chpuMap: chpu
//             });

//             if (isContained) {
//                 removeFromChpuSelectedIndexes.push(i);
//             }
//             continue;
//         }

//         if (chpuParams.length > chpuNomineeParams.length) {
//             const isContained = ! checkEqualAllParams({
//                 chpuReferenceStandartMap: chpu,
//                 chpuMap: chpuNominee
//             });

//             if (isContained) {
//                 isPushNominee = false;
//             }
//             continue;
//         }
//     }

//     removeFromChpuSelectedIndexes.forEach((idxRemove, idx) => {
//         // если элементов несколько, они при каждом шаге сдвигаются влево
//         chpuSelectedKeys.splice(idxRemove - idx, 1);
//     });
//     if (isPushNominee) {
//         chpuSelectedKeys.push(chpuNomineeKey);
//     }
// }
