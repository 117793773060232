export const ROSSIYA = 1;

export enum RegionIdEnum {
    UNKNOWN = 0,
    MSK = 3,
    MSK_AND_MSK_OBL = 9513,
    SPB_AND_LEN_OBL = 9514,
    SPB = 444,
    MSK_OBL = 108,
    LEN_OBL = 886
}

export enum AdditionalRegionWithMetroIdEnum {
    KZN = 2165,
    EKB = 993,
    NSK = 1310,
    NNV = 1961,
    SMR = 3339
}

/* for subway pin on map */
export enum AdditionalRegionOblWithMetroIdEnum {
    EKB_OBL = 991,
    NSK_OBL = 1308,
    NNV_OBL = 1959,
    SMR_OBL = 3337
}

export const regionsEnumeration: ReadonlySet<RegionIdEnum> = new Set<RegionIdEnum>([
    RegionIdEnum.MSK,
    RegionIdEnum.MSK_AND_MSK_OBL,
    RegionIdEnum.SPB_AND_LEN_OBL,
    RegionIdEnum.SPB,
    RegionIdEnum.MSK_OBL,
    RegionIdEnum.LEN_OBL
]);

export const regionsWithMetroEnumeration: ReadonlySet<
    RegionIdEnum |
    AdditionalRegionWithMetroIdEnum
> = new Set<
    RegionIdEnum |
    AdditionalRegionWithMetroIdEnum
>([
    RegionIdEnum.MSK,
    RegionIdEnum.MSK_AND_MSK_OBL,
    RegionIdEnum.SPB_AND_LEN_OBL,
    RegionIdEnum.SPB,
    RegionIdEnum.MSK_OBL,
    RegionIdEnum.LEN_OBL,
    AdditionalRegionWithMetroIdEnum.KZN,
    AdditionalRegionWithMetroIdEnum.EKB,
    AdditionalRegionWithMetroIdEnum.NSK,
    AdditionalRegionWithMetroIdEnum.NNV,
    AdditionalRegionWithMetroIdEnum.SMR
]);

export enum AdditionalRegionWithMetroIdEnumObl {
    SVERDLOVSKAYA_OBLAST = 991,
    NIZHEGORODSKAYA_OBLAST = 1959,
    TATARSTAN = 1042,
    NOVOSIBIRSKAYA_OBLAST = 1308,
}
