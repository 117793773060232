import React from 'react';
import { NewBuildingInfoIconNameEnum } from '@search/graphql-typings';

export enum SocialMediaEnum {
    Twitter = 'Twitter',
    Vk = 'VK',
    Youtube = 'YouTube',
    Ok = 'OK'
}
export type DeveloperSummaryIconNameType = 'Commissioned' | 'InProgress';
export type BuildingSummaryIconNameType = 'CommissioningDate' | 'Elevator' | 'Finishing' | 'Floors' | 'Housing';
// eslint-disable-next-line max-len
export type OfferSummaryIconNameType = 'OfferCommissioningDate' | 'Area' | 'LivingArea' | 'KitchenArea' | 'OfferFinishing' | 'Balcony' | 'Housing';
// eslint-disable-next-line max-len
export type SpecialEventIconName = 'Promo' | 'Discount' | 'InstallmentPlan' | 'SalesStart' | 'Gift' | 'TradeIn' | 'Arbitrary';
export type GalleryIconName = 'Play' | 'Photo' | 'Panorama';
export type PoiImageName = 'SchoolPoiImg' | 'UniversityPoiImg' | 'KindergartenPoiImg' | 'HospitalPoiImg' |
'PharmacyPoiImg' | 'ShopPoiImg' | 'FoodServicePoiImg' | 'SportCentrePoiImg';
export type PreviewNameType = 'MapPreviewImg' | 'VideoPreviewImg' | 'ConstructionProgressPreviewImg';

export type IconWithColorType = SpecialEventIconName | GalleryIconName | SocialMediaEnum | PoiImageName |
    'Design' | 'Inspection' |
    'Chevron' | 'Arrow' | 'ArrowSlider' | 'CloseDesktop' | 'CloseMobile' | 'CheckMark' | 'MiniArrow';

export type IconNameType = IconWithColorType |
    DeveloperSummaryIconNameType |
    BuildingSummaryIconNameType |
    OfferSummaryIconNameType |
    NewBuildingInfoIconNameEnum |
    PreviewNameType |
    'QuestionMark' | 'NoPlan' | 'CustomDeveloper' | 'Pin' | 'Info' | 'Tick' | 'TickNew' | 'TickNewBlue' | 'Document' | 'Map' | 'Filters' |
    'Verified' | 'NotVerified' |
    'CallCircle' | 'VtbQuestionCircle' |
    'M2Logo' |
    'QuizSearch' | 'QuizCall' | 'QuizPromo' | 'QuizFast' |
    'NewBuildingAdd' |
    'EmptySearch' | 'LinkToQuiz' |
    'M2ProPrepayment' | 'M2ProHigh' | 'M2ProMiddle' | 'M2ProLow';

const DEFAULT_COLOR = '#77738C';

export const COLORS: Record<IconWithColorType, string> = {
    Promo: '#28B9BE',
    Discount: '#E91F0C',
    InstallmentPlan: '#9173FA',
    SalesStart: '#3A86F7',
    Gift: '#FF71CF',
    TradeIn: DEFAULT_COLOR,
    Arbitrary: '#28B9BE',

    Arrow: '#221F2E',
    ArrowSlider: '#FFF',
    MiniArrow: '#2F3441',
    Chevron: '#221F2E',

    CloseDesktop: '#B6B7C9',
    CloseMobile: '#221F2E',

    Play: '#221F2E',
    Photo: '#221F2E',
    Panorama: '#221F2E',

    CheckMark: '#1F212E',

    Design: '#FF784B',
    Inspection: '#FF784B',

    SchoolPoiImg: 'transparent',
    UniversityPoiImg: 'transparent',
    KindergartenPoiImg: 'transparent',
    HospitalPoiImg: 'transparent',
    PharmacyPoiImg: 'transparent',
    ShopPoiImg: 'transparent',
    FoodServicePoiImg: 'transparent',
    SportCentrePoiImg: 'transparent',

    [SocialMediaEnum.Twitter]: '#FFF',
    [SocialMediaEnum.Vk]: '#FFF',
    [SocialMediaEnum.Youtube]: '#FFF',
    [SocialMediaEnum.Ok]: '#FFF'
};

export const SvgSpriteIcon = ({
    name,
    width = 24,
    height = 24,
    color = COLORS[name as IconWithColorType] ?? DEFAULT_COLOR,
    ...otherProps
}: React.SVGProps<SVGSVGElement>) => (
    <svg
        height={height}
        width={width}
        fill={color}
        {...otherProps}
    >
        <use xlinkHref={`#${name}`} />
    </svg>
);
