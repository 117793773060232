import { useCallback, useMemo } from 'react';

import { Filters, IFilter, MapBoundsFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { NewbuildingFilterCollection } from '@search/vtbeco-frontend-core/domain/newbuilding/filters/NewbuildingFilterCollection';

export const useDrawAreaChip = ({
    filters,
    updateFilter
}: {
    filters: NewbuildingFilterCollection;
    updateFilter: (filter: IFilter) => void;
}) => {
    const drawAreaTitle = useMemo(
        () => {
            const mapBoundsFilter = filters.get<MapBoundsFilter>(Filters.mapBounds);

            return (
                // eslint-disable-next-line no-nested-ternary
                mapBoundsFilter?.hasPolygon() ? 'Область на карте' :
                    mapBoundsFilter?.bounds ? 'Область карты' : undefined
            );
        },
        [ filters ]
    );

    const removeDrawArea = useCallback(
        () => updateFilter(new MapBoundsFilter()),
        [ updateFilter ]
    );

    return {
        drawAreaTitle,
        removeDrawArea
    };
};
