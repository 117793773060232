import { useCallback, useContext, useMemo } from 'react';

import { Router } from '@search/router/src';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';

import { OfferYMapTilePoint } from '@search/vtbeco-frontend-core/domain/offer/ymap/tile/Point';
import { Shared } from '../../../../types/shared';
import { IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';

export const useYMapUrl = ({
    params,
    router,
    excludeCoords
}: {
    params: INewBuildingSearchType;
    router: Router<Shared.IRouterContext>;
    excludeCoords?: OfferYMapTilePoint;
}) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    const mapUrl = useMemo(
        () => onChangeGlobalContext.linkBuilder.searchMap(params),
        [ params, onChangeGlobalContext.linkBuilder ]
    );

    const listUrl = useMemo(() => {
        const { selectedNewBuildingId, ...searchPageParams } = params;

        return onChangeGlobalContext.linkBuilder.search(searchPageParams);
    }, [ params, onChangeGlobalContext.linkBuilder ]);

    const excludeParams: { excludeLong?: number; excludeLat?: number } = useMemo(() => {
        return {};
    }, []);

    if (excludeCoords) {
        excludeParams.excludeLat = excludeCoords.latitude;
        excludeParams.excludeLong = excludeCoords.longitude;
    }

    const tileUrl = useMemo(() => {
        const { selectedNewBuildingId, ...searchPageParams } = params;

        return onChangeGlobalContext.linkBuilder.tile({
            ...searchPageParams,
            ...excludeParams,
            mapBounds: {
                ...params.mapBounds,
                bounds: undefined
            }
        });
    }, [ onChangeGlobalContext.linkBuilder, params, excludeParams ]);

    const handleListClick = useCallback(() => router.update(listUrl), [ router, listUrl ]);

    return {
        mapUrl,
        listUrl,
        tileUrl,
        handleListClick
    };
};
