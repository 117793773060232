/* eslint-disable complexity */
import { useContext, useMemo } from 'react';

import { Route } from '@search/router/src/Route';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { AreaUnitEnum } from '@search/graphql-typings';
import { IsApartmentsOptionsString } from '@search/filter-enums/enums';
import { useSegments } from '@search/nb-texts/src/makeSegments';

import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';
import { Shared } from '../../../../types/shared';
import breadcrumbsDict, { emojiDict } from '../../../../dict/breadcrumbs';

import { InputQueryDisplayValues, useQueryDisplayValues } from '../../hooks/useQueryDisplayValues';

import { GlobalContext } from '../GlobalContext';

type Breadcrumb = {
    content: string;
    href: string;
    active?: boolean;
};

const useBreadcrumbs = ({
    route,
    pageParams,
    displayValues,
    geoStore
}: {
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    pageParams: INewBuildingSearchType;
    displayValues: InputQueryDisplayValues;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore?: any;
}): {
    items: Breadcrumb[];
    rdfMeta: string;
} => {
    const {
        urls,
        definingParams
    } = useSegments({
        route,
        pageParams
    });
    const [ queryDisplayValues ] = useQueryDisplayValues(displayValues);

    const { rootRegions } = useContext(GlobalContext);

    const { locative } = rootRegions.getById(Number(pageParams.region)) || {};

    return useMemo(() => {
        const itemListElement: ListItem[] = [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Главная',
                item: '/'
            }
        ];

        const breadcrumbs = [
            {
                content: 'Главная',
                href: '/'
            },
            ...definingParams
                .map((params, i) => {
                    const {
                        region,
                        commissioning,
                        rooms: roomsList,
                        hasFinishing,
                        finishingType: finishingTypeList,
                        buildingClass: buildingClassList,
                        wallsType: wallsTypeList,
                        floorLast,
                        law214,
                        escrow,
                        armyMortgage,
                        installment,
                        priceMax,
                        priceType,
                        sort,
                        transportType,
                        maxMinutes,
                        metroId: metroIdList,
                        districtId,
                        closedArea,
                        concierge,
                        apartments,
                        parking,
                        kitchenAreaRangeMin,
                        ceilingHeight,
                        balcony,
                        motherCapital,
                        flatsSpecialEvents,
                        addressId
                    } = params;
                    const {
                        metros,
                        districts
                    } = queryDisplayValues;
                    let content = '';

                    if (region) {
                        content = `Новостройки${locative ? ` ${locative}` : ''}`;
                    } else if (commissioning) {
                        content = breadcrumbsDict.makeCommissioning(commissioning);
                    } else if (roomsList) {
                        const roomsValue = roomsList[0];

                        content = kitchenAreaRangeMin === 17 ?
                            breadcrumbsDict.makeEuroPlanning(roomsValue) :
                            breadcrumbsDict.makeRooms(roomsValue);
                    } else if (ceilingHeight) {
                        content = breadcrumbsDict.makeParam('ceilingHeight');
                    } else if (kitchenAreaRangeMin) {
                        content = breadcrumbsDict.makeParam('bigKitchen');
                    } else if (hasFinishing && ! finishingTypeList) {
                        content = breadcrumbsDict.makeFinishing(hasFinishing);
                    } else if (finishingTypeList) {
                        content = breadcrumbsDict.makeFinishingType(finishingTypeList[0]);
                    } else if (buildingClassList) {
                        content = breadcrumbsDict.makeBuildingClass(buildingClassList[0]);
                    } else if (wallsTypeList) {
                        content = breadcrumbsDict.makeWallsType(wallsTypeList[0]);
                    } else if (floorLast) {
                        content = breadcrumbsDict.makeFloorLast(floorLast);
                    } else if (law214) {
                        content = breadcrumbsDict.makeParam('law214');
                    } else if (escrow) {
                        content = breadcrumbsDict.makeParam('escrow');
                    } else if (armyMortgage) {
                        content = breadcrumbsDict.makeParam('armyMortgage');
                    } else if (installment) {
                        content = breadcrumbsDict.makeParam('installment');
                    } else if (motherCapital) {
                        content = breadcrumbsDict.makeParam('motherCapital');
                    } else if (closedArea) {
                        content = breadcrumbsDict.makeParam('closedArea');
                    } else if (concierge) {
                        content = breadcrumbsDict.makeParam('concierge');
                    } else if (balcony) {
                        content = breadcrumbsDict.makeParam('balcony');
                    } else if (apartments) {
                        const apartmentsText = breadcrumbsDict.makeApartments(apartments as IsApartmentsOptionsString);

                        if (apartmentsText) {
                            content = ` ${apartmentsText}`;
                        }
                    } else if (parking) {
                        const parkingText = breadcrumbsDict.makeParking(Array.isArray(parking) ? parking : [ parking ]);

                        if (parkingText) {
                            content = ` ${parkingText}`;
                        }
                    } else if (flatsSpecialEvents) {
                        content = breadcrumbsDict.makeFlatsSpecialEvents(
                            Array.isArray(flatsSpecialEvents) ? flatsSpecialEvents : [ flatsSpecialEvents ]
                        );
                    } else if (priceMax) {
                        if (sort) {
                            content = breadcrumbsDict.makeParam('cheap');
                        } else if (priceType === AreaUnitEnum.Meter) {
                            content = breadcrumbsDict.makePricePerMeterMax(priceMax);
                        } else {
                            content = breadcrumbsDict.makePriceMax(priceMax);
                        }
                    } else if (transportType && maxMinutes) {
                        content = breadcrumbsDict.makeParam('closeToMetro');
                    } else if (metroIdList) {
                        const metroId = (metroIdList as number[])[0];
                        const metro = metros && metros[metroId]?.title;

                        if (metro) {
                            content = breadcrumbsDict.makeMetro(metro);
                        } else {
                            const [ metroGeo ] = geoStore?.stations.findRouteByStations([ metroId ]).stations ?? [];

                            if (metroGeo) {
                                content = breadcrumbsDict.makeMetro(metroGeo.title);
                            }
                        }
                    } else if (districtId) {
                        const districtIdValue = Array.isArray(districtId) ? districtId[0] : districtId;
                        const district = districts && districts[districtIdValue]?.title;

                        const { title } = rootRegions?.getGeoSlug(districtIdValue) ?? {};

                        if (district) {
                            content = district;
                        } else if (title) {
                            content = title;
                        } else {
                            const districtGeo = geoStore?.districts.get(districtId);

                            if (districtGeo) {
                                content = districtGeo.title
                                    .replace('городской округ', '')
                                    .trim();
                            }
                        }
                    } else if (addressId) {
                        const addressIdValue = Array.isArray(addressId) ? addressId[0] : addressId;
                        const { title } = rootRegions?.getGeoSlug(addressIdValue) ?? {};

                        if (title) {
                            content = title;
                        }
                    }

                    content && itemListElement.push({
                        '@type': 'ListItem',
                        position: i + 2,
                        name: region ? `${String.fromCodePoint(emojiDict.NEW_BUILDING)} ${content}` : content,
                        item: urls[i]
                    });

                    return {
                        content,
                        href: urls[i]
                    } as Breadcrumb;
                })
                .filter(({ content }) => Boolean(content))
        ];

        breadcrumbs[breadcrumbs.length - 1].active = true;

        const breadcrumbsListMeta: WithContext<BreadcrumbList> = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement
        };

        return {
            items: breadcrumbs,
            rdfMeta: JSON.stringify(breadcrumbsListMeta)
        };
    }, [ definingParams, queryDisplayValues, urls, locative, geoStore?.stations, geoStore?.districts, rootRegions ]);
};

export default useBreadcrumbs;
