/* eslint-disable @typescript-eslint/no-floating-promises */
import './styles.css';

// @ts-ignore
import { ymaps } from '../../ApiProvider';

import { Plus, Minus } from '../../Icon';

import {
    YMapControlButtonDisabledClass,
    YMapControlButtonTemplate
} from '../Button';
import { YMapControl } from '../Control';

export interface YMapControlZoomProps
    extends Omit<ymaps.IMapZoomOptions, 'duration'> {
    zoomDuration?: number;
    zoomStep?: number;
    className?: string;
    zoomInTitle?: string;
    zoomOutTitle?: string;
    index?: number;
    dataTest?: string;
}

const disabledClass = YMapControlButtonDisabledClass;
const baseClass = 'YMapControlZoom';
const itemClass = `${baseClass}__item`;

const zoomInClass = `${itemClass}__in`;
const zoomOutClass = `${itemClass}__out`;

export function YMapControlZoom({
    className,
    zoomOutTitle = 'Zoom out',
    zoomInTitle = 'Zoom in',
    zoomDuration = 200,
    zoomStep,
    index,
    dataTest = 'ymap-zoom',
    useMapMargin = true,
    checkZoomRange
}: YMapControlZoomProps) {
    YMapControlZoomY.use({
        index,
        dataTest,
        className,
        zoomDuration,
        zoomStep,
        zoomInTitle,
        zoomOutTitle,
        useMapMargin,
        checkZoomRange
    } as YMapControlZoomProps);

    return null;
}

class YMapControlZoomY extends YMapControl<YMapControlZoomProps, ymaps.control.IZoomControl> {
    attributes() {
        const { attributes, layout } = this.controlManager
;

        if (layout === 'horizontal') {
            return {
                margin: attributes.margin,
                size: {
                    x: attributes.size.x * 2,
                    y: attributes.size.y
                }
            };
        }

        return {
            margin: attributes.margin,
            size: {
                x: attributes.size.x,
                y: attributes.size.y * 2
            }
        };
    }

    protected create() {
        return new this.ymaps.control.ZoomControl({
            data: this.props,
            options: {
                zoomStep: this.props.zoomStep,
                zoomDuration: this.props.zoomDuration,
                layout: this.layoutClass()
            }
        });
    }

    protected template() {
        const { props } = this;

        // eslint-disable-next-line new-cap
        return YMapControlButtonTemplate({
            className: `${baseClass} ${baseClass}_${this.controlManager.layout} ${props.className || ''}`,
            children: `
                <div class="${itemClass} ${zoomInClass}" data-test="${this.props.dataTest}-in">
                    ${Plus.str({})}
                </div>
                <div class="${itemClass} ${zoomOutClass}" data-test="${this.props.dataTest}-out">
                    ${Minus.str({})}
                </div>
            `
        });
    }

    protected layoutClass() {
        const Layout = super.layoutClass();

        return class YMapControlZoomLayout extends Layout {
            build() {
                super.build();
                const { zoomInEl, zoomOutEl } = this.buttonsEl;
                const zoomIn = this.zoomChange.bind(this, 1);
                const zoomOut = this.zoomChange.bind(this, -1);

                zoomInEl.addEventListener('click', zoomIn);
                zoomOutEl.addEventListener('click', zoomOut);

                const map = this.map;

                if (! map) return;
                const update = this.update.bind(this);

                map.zoomRange.events.add('change', update);
                map.events.add('boundschange', update, this);
                const dispose = this.dispose
;

                this.dispose = () => {
                    dispose();
                    map.zoomRange.events.remove('change', update);
                    map.events.remove('boundschange', update);
                    zoomInEl.removeEventListener('click', zoomIn);
                    zoomOutEl.removeEventListener('click', zoomOut);
                };
            }

            protected get buttonsEl() {
                const el = this.getElement();
                const zoomInEl = el.querySelector(`.${zoomInClass}`);
                const zoomOutEl = el.querySelector(`.${zoomOutClass}`);

                if (! zoomInEl || ! zoomOutEl) {
                    throw new Error(`Not found elements in ${el}`);
                }

                return { zoomInEl, zoomOutEl };
            }

            protected update() {
                const map = this.map;

                if (! map) return;
                const [ zoomMin, zoomMax ] = map.zoomRange.getCurrent();
                const zoom = map.getZoom();
                const { zoomInEl, zoomOutEl } = this.buttonsEl;
                const zInCl = zoomInEl.classList;
                const zOutCl = zoomOutEl.classList;

                if (zoom + 1 > zoomMax) zInCl.add(disabledClass);
                else zInCl.remove(disabledClass);

                if (zoom - 1 < zoomMin) zOutCl.add(disabledClass);
                else zOutCl.remove(disabledClass);
            }

            protected zoomChange(delta: number) {
                const map = this.map;

                if (! map) return;
                const [ zoomMin, zoomMax ] = map.zoomRange.getCurrent();
                const newZoom = map.getZoom() + delta;

                if (newZoom >= zoomMin && newZoom <= zoomMax) {
                    const data = this.getData().data;

                    map.setZoom(newZoom, {
                        useMapMargin: data.get('useMapMargin'),
                        checkZoomRange: data.get('checkZoomRange'),
                        duration: data.get('zoomDuration')
                    });
                    this.update();
                }
            }
        };
    }
}
