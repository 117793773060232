/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import type {
    NewBuildingsLinkCloud,
    LinkParams
} from '@search/graphql-typings';
import { FinishingEnum, FinishingTypeEnum } from '@search/graphql-typings';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import { Route } from '@search/router/src/Route';
import { BuildingClass } from '@search/filter-enums/enums/BuildingClass';
import { WallsTypeString } from '@search/filter-enums/enums/WallsType';
import { ParkingTypeString } from '@search/filter-enums/enums/ParkingType';
//import { IsApartmentsOptionsString } from '@search/filter-enums/enums/IsApartmentsOptions';
import { FloorConstraintsString } from '@search/filter-enums/enums/FloorConstraints';
import { formatRange } from '@search/helpers/src/formatNumber';

import {
    roomsDict,
    finishingDict,
    finishingTypeDict,
    buildingClassDict,
    commissioningDict,
    wallsTypeDict,
    floorLastDict,
    commissioningQuarterDict,
    parkingDict,
    sellConditionDict,
    securityDict,
    euroPlanningDict,
    //apartmentsDict
} from '../../../../dict/cloudLink';
import type { Shared } from '../../../../types/shared';

export type CloudLinkType = {
    url: string;
    text: string;
    isCurrent?: boolean;
    isMore?: boolean;
}

type LinksType = {
    metro: CloudLinkType[];
    city: CloudLinkType[];
    district: CloudLinkType[];
    seeAlso: CloudLinkType[];
};

export const useCloudLinks = ({
    route,
    geoStore,
    linkCloudParams,
    pageParams
}: {
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    pageParams: INewBuildingSearchType;
    linkCloudParams?: NewBuildingsLinkCloud;
}): LinksType => {
    const {
        metro: metroLinkParams,
        city: cityLinkParams,
        district: districtLinkParams,
        seeAlso: seeAlsoLinkParams
    } = linkCloudParams ?? {};

    const {
        roomList,
        finishingList,
        buildingClassList,
        dateOfCommissioningList,
        wallsTypeList,
        floorLastList,
        sellConditionsList,
        cheapList,
        closeToMetro,
        priceMaxList,
        parkingList,
        securityList,
        highCeilingsList,
        bigKitchenList,
        euroKindList,
        // apartmentsList
    } = seeAlsoLinkParams ?? {};

    const metro = useMemo(
        () => makeMetroLinks(route, metroLinkParams, geoStore).filter(({ text }) => text),
        [ route, geoStore, metroLinkParams ]
    );

    const city = useMemo(
        () => makeDistrictLinks(route, cityLinkParams, geoStore).filter(({ text }) => text),
        [ route, geoStore, cityLinkParams ]
    );

    const district = useMemo(
        () => makeDistrictLinks(route, districtLinkParams, geoStore).filter(({ text }) => text),
        [ route, geoStore, districtLinkParams ]
    );

    const roomLinks = useMemo(
        () => makeRoomLinks(route, roomList),
        [ route, roomList ]
    );

    const finishingLinks = useMemo(
        () => makeFinishingLinks(route, finishingList),
        [ route, finishingList ]
    );

    const buildingClassLinks = useMemo(
        () => makeBuildingClassLinks(route, buildingClassList),
        [ route, buildingClassList ]
    );

    const cheapLinks = useMemo(
        () => makeCheapLinks(route, cheapList),
        [ route, cheapList ]
    );

    const closeToMetroLinks = useMemo(
        () => closeToMetro ? makeCloseToMetroLinks(route, [ closeToMetro ]) : [],
        [ route, closeToMetro ]
    );

    const priceMaxLinks = useMemo(
        () => makePriceMaxLinks(route, priceMaxList),
        [ route, priceMaxList ]
    );

    const commissioningLinks = useMemo(
        () => makeCommissioningLinks(route, dateOfCommissioningList),
        [ route, dateOfCommissioningList ]
    );

    const wallsTypeLinks = useMemo(
        () => makeWallsTypeLinks(route, wallsTypeList),
        [ route, wallsTypeList ]
    );

    const floorLastLinks = useMemo(
        () => makeFloorLastLinks(route, floorLastList),
        [ route, floorLastList ]
    );

    const sellConditionsLinks = useMemo(
        () => makeSellConditionsLinks(route, sellConditionsList, pageParams),
        [ route, sellConditionsList, pageParams ]
    );

    const parkingLinks = useMemo(
        () => makeParkingLinks(route, parkingList),
        [ route, parkingList ]
    );

    const securityLinks = useMemo(
        () => makeSecurityLinks(route, securityList, pageParams),
        [ route, securityList, pageParams ]
    );

    const highCeilingsLinks = useMemo(
        () => makeHighCeilingsLinks(route, highCeilingsList),
        [ route, highCeilingsList ]
    );

    const bigKitchenLinks = useMemo(
        () => makeBigKitchenLinks(route, bigKitchenList),
        [ route, bigKitchenList ]
    );

    const euroKindLinks = useMemo(
        () => makeEuroKindLinks(route, euroKindList),
        [ route, euroKindList ]
    );

    /*const apartmentsLinks = useMemo(
        () => makeApartmentsLinks(route, apartmentsList),
        [ route, apartmentsList ]
    );*/

    const seeAlso = [
        ...roomLinks,
        ...euroKindLinks,
        ...highCeilingsLinks,
        ...bigKitchenLinks,
        ...finishingLinks,
        ...closeToMetroLinks,
        ...cheapLinks,
        ...priceMaxLinks,
        ...buildingClassLinks,
        ...parkingLinks,
        ...securityLinks,
        // ...apartmentsLinks,
        ...commissioningLinks,
        ...wallsTypeLinks,
        ...floorLastLinks,
        ...sellConditionsLinks
    ].filter(({ text }) => text);

    return {
        metro,
        city,
        district,
        seeAlso
    };
};

/* ФОРМИРОВАНИЕ ССЫЛОК ПО РАЗДЕЛАМ */

/* Ссылки для раздела `Метро` */
const makeMetroLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined,
    geoStore: any
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getMetroStationName(geoStore, params.metroId),
            isCurrent: params.isCurrent
        }))
        .filter(({ url }) => url && ! /[0-9]+\/$/.test(url))
        .sort((a, b) => a.text.localeCompare(b.text)) ?? []
);

/* Ссылки для разделов `Район`/`Округ и район` и `Город` */
const makeDistrictLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined,
    geoStore: any
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getDistrictName(geoStore, params.districtId),
            isCurrent: params.isCurrent
        }))
        .map(item => ({
            ...item,
            text: formatDistrictLinkText(item.text)
        }))
        .filter(({ url }) => url)
        .sort((a, b) => a.text.localeCompare(b.text)) ?? []
);

/* Ссылки для раздела `Посмотрите также` */
const makeRoomLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.rooms ? roomsDict[params.rooms] : ''
        }))
        .filter(({ url }) => url) ?? []
);

const makeFinishingLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getFinishingName(params.hasFinishing, params.finishingType)
        }))
        .filter(({ url }) => url) ?? []
);

const makeBuildingClassLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.buildingClass ? buildingClassDict[params.buildingClass] : ''
        }))
        .filter(({ url }) => url) ?? []
);

const makeCheapLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: 'Дешёвые'
        }))
        .filter(({ url }) => url) ?? []
);

const makeCloseToMetroLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: 'Рядом с метро'
        }))
        .filter(({ url }) => url) ?? []
);

const makePriceMaxLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: `${formatRange(null, params.priceMax, {
                billionsAsString: true
            })}\u00A0₽${params.priceType === 'METER' ? ' за\u00A0кв.\u00A0м' : ''}`
        }))
        .filter(({ url }) => url) ?? []
);

const makeCommissioningLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getCommissioningName(params.commissioning)
        }))
        .filter(({ url }) => url) ?? []
);

const makeWallsTypeLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.wallsType ? wallsTypeDict[params.wallsType] : ''
        }))
        .filter(({ url }) => url) ?? []
);

const makeFloorLastLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.floorLast ? floorLastDict[params.floorLast] : ''
        }))
        .filter(({ url }) => url) ?? []
);

const makeSellConditionsLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined,
    currentPageParams: INewBuildingSearchType
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getSellConditionName(params, currentPageParams)
        }))
        .filter(({ url }) => url) ?? []
);

const makeParkingLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getParkingName(params.parking)
        }))
        .filter(({ url }) => url) ?? []
);

const makeSecurityLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined,
    currentPageParams: INewBuildingSearchType
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: getSecurityName(params, currentPageParams)
        }))
        .filter(({ url }) => url) ?? []
);

const makeBigKitchenLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: 'С большой кухней'
        }))
        .filter(({ url }) => url) ?? []
);

const makeHighCeilingsLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: 'С высокими потолками'
        }))
        .filter(({ url }) => url) ?? []
);

const makeEuroKindLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.rooms ? euroPlanningDict[params.rooms] : ''
        }))
        .filter(({ url }) => url) ?? []
);

/*const makeApartmentsLinks = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    paramList: LinkParams[] | undefined
): CloudLinkType[] => (
    paramList
        ?.map(params => ({
            url: makeUrl(route, params),
            text: params.apartments ? apartmentsDict[params.apartments] : ''
        }))
        .filter(({ url }) => url) ?? []
);*/

/* ХЕЛПЕРЫ */
function formatDistrictLinkText(linkText: string) {
    let text = linkText;

    const districtSubstr = 'район ';

    if (text.startsWith(districtSubstr)) {
        text = text.replace(districtSubstr, '');
    }

    return text.replace(/(административный округ|городской округ)/, '').trim();
}

const getMetroStationName = (geoStore: any, stationId?: number | null): string => {
    if (! stationId) {
        return '';
    }

    const [ metroGeo ] = geoStore.stations.findRouteByStations([ stationId ]).stations ?? [];

    return metroGeo?.title ?? '';
};

const getDistrictName = (geoStore: any, districtId?: number | null): string => {
    if (! districtId) {
        return '';
    }

    const districtGeo = geoStore.districts.get(districtId);

    return districtGeo?.title ?? '';
};

const getFinishingName = (hasFinishing: string, finishingType?: string | null): string => {
    if (finishingType) {
        return finishingTypeDict[finishingType];
    }

    return finishingDict[hasFinishing];
};

const getCommissioningName = (commissioning?: string | null): string => {
    if (! commissioning) {
        return '';
    }

    const commissioningName = commissioningDict[commissioning];

    if (commissioningName) {
        return commissioningName;
    }

    const [ quarter, year ] = commissioning.split('_');

    return `Срок сдачи: до ${commissioningQuarterDict[quarter]} кв. ${year}`;
};

const getSellConditionName = (params: LinkParams, currentPageParams: INewBuildingSearchType): string => {
    if (params.armyMortgage && ! currentPageParams.armyMortgage) {
        return sellConditionDict.armyMortgage;
    }

    if (params.escrow && ! currentPageParams.escrow) {
        return sellConditionDict.escrow;
    }

    if (params.installment && ! currentPageParams.installment) {
        return sellConditionDict.installment;
    }

    if (params.law214 && ! currentPageParams.law214) {
        return sellConditionDict.law214;
    }

    return '';
};

const getParkingName = (parking: string[]): string => {
    if (parking.length === 3) {
        return parkingDict[ParkingTypeString.AVAILABLE];
    }

    if (parking.length === 1 && parking[0] === ParkingTypeString.UNDERGROUND) {
        return parkingDict[ParkingTypeString.UNDERGROUND];
    }

    return '';
};

const getSecurityName = (params: LinkParams, currentPageParams: INewBuildingSearchType): string => {
    if (params.closedArea && ! currentPageParams.closedArea) {
        return securityDict.closedArea;
    }

    if (params.concierge && ! currentPageParams.concierge) {
        return securityDict.concierge;
    }

    return '';
};

const makeUrl = (
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>,
    params: LinkParams
) => {
    const {
        region,
        metroId,
        districtId,
        rooms,
        hasFinishing,
        finishingType,
        buildingClass,
        commissioning,
        wallsType,
        floorLast,
        /* eslint-disable @typescript-eslint/naming-convention */
        law214,
        armyMortgage,
        installment,
        escrow,
        closedArea,
        concierge,
        /* eslint-enable @typescript-eslint/naming-convention */
        transportType,
        maxMinutes,
        sort,
        priceMax,
        priceType,
        parking,
        ceilingHeight,
        kitchenAreaRangeMin,
        // apartments
    } = params;

    const url = route.url({
        region,
        metroId: metroId ? [ metroId ] : [],
        districtId: districtId ? [ districtId ] : [],
        // @ts-ignore
        rooms: rooms ? [ rooms ] : [],
        hasFinishing: hasFinishing as FinishingEnum,
        // @ts-ignore
        finishingType: finishingType ? [ finishingType as FinishingTypeEnum ] : [],
        buildingClass: buildingClass ? [ buildingClass as BuildingClass ] : [],
        commissioning,
        wallsType: wallsType ? [ wallsType as WallsTypeString ] : [],
        floorLast: floorLast as FloorConstraintsString,
        law214,
        escrow,
        armyMortgage,
        installment,
        sort,
        priceMax,
        priceType,
        transportType,
        maxMinutes,
        parking: parking as ParkingTypeString[],
        closedArea,
        concierge,
        ceilingHeight,
        kitchenAreaRangeMin,
        // apartments: apartments as IsApartmentsOptionsString,
    });

    return url.includes('?') ? '' : url;
};
