/* eslint-disable max-len */
/*eslint-env browser*/
import * as Sentry from '@sentry/browser';
import type { GqlClientOptions, GqlClientType, ExecutionResult } from './GqlClient';

type GqlBrowserClientProps = {
    path: string;
    credentials?: RequestCredentials;
    headerAppName?: string;
    mode?: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
}

export const gqlBrowserClient = ({ path, headerAppName = '', credentials = 'same-origin', mode } : GqlBrowserClientProps): GqlClientType => {
    return <Variables, Response>(query: string, variables: Variables, options?: GqlClientOptions): Promise<ExecutionResult<Response>> => {
        return fetch(`${path}${options?.pathUniqSuffix ?? ''}`, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-Gql-Tracing-Tag': options?.tracingTag ?? '',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-Retpath-Y': document.location.href,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-Requested-With': 'XMLHttpRequest',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-App-Name': headerAppName,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-Type': 'application/json',
                ...options?.extraHeaders
            },
            body: JSON.stringify({
                query,
                variables
            }),
            signal: options?.signal,
            method: 'post',
            credentials,
            mode
        })
            .then(response => Promise.all([ response.json(), response.headers ]))
            .then(([ result, headers ]) => {
                if (result.errors && typeof Sentry !== 'undefined') {
                    Sentry.withScope(scope => {
                        scope.setExtra('query', query);
                        scope.setExtra('errors', result.errors);

                        Sentry.captureException(new Error('GQL error'), {
                            tags: {
                                section: 'GQL',
                                traceId: headers.get('x-trace-id')
                            }
                        });
                    });
                }

                return result;
            })
            .catch(e => {
                if (typeof Sentry !== 'undefined') {
                    Sentry.withScope(scope => {
                        scope.setExtra('query', query);
                        Sentry.captureException(e, {
                            tags: {
                                section: 'GQL'
                            },
                        });
                    });
                }
                return Promise.reject(e);
            });
    };
};
