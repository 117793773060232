import React, { FunctionComponent, useCallback, useContext } from 'react';

import { Badge, Link, Typography } from '@vtblife/uikit';
import type { LinkButtonProps } from '@vtblife/uikit/dist/components/link/types';
import Grid from '@vtblife/uikit-grid';

import classname from '@search/classname/src';
import Image from '@search/vtbeco-frontend-core/view/common/components/Image';
import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';
import SnippetPhone from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/SnippetPhone';
import { dataLayerPush } from '@search/vtbeco-frontend-core/domain/google/dataLayerPush';
import { useGtmOnVisible } from '@search/vtbeco-frontend-core/domain/google/useGtmOnVisible';
import { CallbackModal } from '@search/vtbeco-frontend-core/view/common/components/CallbackModal';
import type { NewBuildingFlat, NewBuildingSnippet } from '@search/graphql-typings';
import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import type { IMetroRouteProps } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/MetroRoute';
import type { DeveloperPromoEventResult } from '@search/vtbeco-frontend-core/domain/newbuilding/developer-promo/analytics';
import { graphql, useGql } from '@search/gql-client/src';

import { GlobalContext, IOnChangeGlobalContext, OnChangeGlobalContext } from '../../../common/components/GlobalContext';
import { MetroRouteWithSearchLink } from '../../../common/components/MetroRouteWithSearchLink';
import { useNewBuildingSnippet } from '../../../common/components/NewBuildingSnippet/useNewBuildingSnippet';
import ChatButton from '../../../common/components/ChatButton';
import { defineCallbackEmailSender } from '../../../common/libs/defineEmailSender';
import { useNewBuildingSnippetUrl } from '../../../common/components/NewBuildingSnippet/useNewBuildingSnippetUrl';
import { ChatStateProvider } from '../../../common/components/NewBuildingCard/ChatStateProvider';

import NewBuildingFlats from '../NewBuildingFlats';

import type {
    NewBuildingPromoSnippetDesktopQuery$variables as NewBuildingPromoSnippetDesktopQueryVariables,
    NewBuildingPromoSnippetDesktopQuery$data as NewBuildingPromoSnippetDesktopQueryResponse
} from './__generated__/NewBuildingPromoSnippetDesktopQuery.graphql';

import './styles.css';

const cn = classname.bind(null, 'NewBuildingPromoSnippet');

const query = graphql`
    query NewBuildingPromoSnippetDesktopQuery($id: Int!) {
        newBuilding(id: $id) {
            gallery {
                main {
                    promoSnippet
                }
            }
            title {
                nominative
                accusative
            }
            buildingStatus {
                title
            }
            address
            flats {
                rooms
                label
                labelShort
                isSoldOut
                isSoon
                formatAreaRange
                formatPriceRange
                formatPriceRangeShort
            }
            developerCards {
                developer {
                    id
                }
            }
            id
            routeParams {
                id
                name
                type
                region
                regionId
                subRegion
            }
            priceMin
            ranking
            routes(type: [METRO]) {
                __typename
                ... on RouteMetro {
                    station {
                        id
                        name
                        lines
                    }
                    timeMinutes
                    transportType
                }
            }
            isSPbLO
            isMskMO
            hasChat
            noOffers
        }
    }
`;

function renderBuildingStatus(title: string, index: number, buildingStatusLength: number) {
    if (index === 0) {
        return `${title}, `;
    }

    if (index === buildingStatusLength - 1) {
        return `${title.charAt(0).toLowerCase()}${title.slice(1)}`;
    }

    return `${title.charAt(0).toLowerCase()}${title.slice(1)}, `;
}

const NewBuildingPromoSnippetMetro = ({
    newBuilding
} : {
    newBuilding: NewBuildingSnippet;
}) => {
    const { routes } = useNewBuildingSnippet(newBuilding);

    if (! routes || routes.length === 0) {
        return null;
    }

    return (
        <MetroRouteWithSearchLink
            metro={routes[0] as IMetroRouteProps['metro']}
            regionId={newBuilding.routeParams!.regionId}
            className={cn('metroRoute')}
            onDarkBackground
        />
    );
};

const NewBuildingPromoSnippetFlats = ({
    newBuilding
} : {
    newBuilding: NewBuildingSnippet;
}) => {
    const url = useNewBuildingSnippetUrl(newBuilding.routeParams as NewBuildingSnippet['routeParams']);
    const { flats } = newBuilding;

    return (Array.isArray(flats) && flats.length ? (
        <div className={cn('flats')}>
            <NewBuildingFlats
                flats={flats as NewBuildingFlat[]}
                forPromoSnippet
                newBuildingId={String(newBuilding.id)}
                hasOffers={! newBuilding.noOffers}
                url={url}
            />
        </div>
    ) : null);
};

const NewBuildingPromoSnippetButtons = ({
    newBuilding,
    onStatEventPush,
    gtm
} : {
    newBuilding: NewBuildingSnippet;
    onStatEventPush: () => void;
    gtm?: LinkButtonProps['gtm'];
}) => {
    const { instanceConfig: { calltouchUnitId }, calltouchModId } = useContext(GlobalContext);

    const developerId = newBuilding.developerCards[0]?.developer?.id!;

    return (
        <div className={cn('buttons')}>
            {
                developerId ? (
                    <div className={cn('snippetPhone')}>
                        <SnippetPhone
                            phoneParams={{
                                newBuildingId: Number(newBuilding.id),
                                developerId,
                                phoneNum: 0
                            }}
                            size='m'
                            variant='primary-alt-bg'
                            gtm={gtm}
                            fullWidth
                            calltouchId={calltouchModId}
                            onPhoneEventPush={onStatEventPush}
                        />
                    </div>
                ) : null
            }
            <CallbackModal
                newBuildingName={newBuilding.title.nominative}
                address={newBuilding.address}
                emailSender={defineCallbackEmailSender(newBuilding.isMskMO, newBuilding.isSPbLO)}
                gtmSendClick={DATA_GTM.CARD_CALL_BACK_SEND_BUILDING}
                onSendEventPush={onStatEventPush}
                gtmShowModal={DATA_GTM.CARD_CALL_BACK_BUILDING}
                callbackUnitId={calltouchUnitId[newBuilding.isSPbLO ? 'Spb' : 'Msk']}
                withoutIconOnButton
                buttonSize='m'
                onDarkBackground
                btnWrapperClass={cn('callBackButton')}
            />
            {newBuilding.hasChat ? (
                <ChatStateProvider initialClientMessage={`Интересует ${newBuilding.title.accusative}`}>
                    <ChatButton
                        className={cn('openChat')}
                        size='m'
                        onDarkBackground
                    />
                </ChatStateProvider>
            ) : null}
        </div>
    );
};

const NewBuildingPromoSnippet: FunctionComponent<{
    id: number;
    disclaimer: string;
    developerName: string;
    developerLogo?: string;
    dataGTM?: {
        click?: string;
        phoneClick?: string;
    };
    gtmClickData?: { event: string } | DeveloperPromoEventResult;
    gtmShowData?: { event: string } | DeveloperPromoEventResult;
    erid: string;
}> = ({
    id,
    disclaimer,
    developerName,
    developerLogo,
    dataGTM = {},
    gtmClickData,
    gtmShowData,
    erid
}) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, loading } = useGql<
        NewBuildingPromoSnippetDesktopQueryVariables,
        NewBuildingPromoSnippetDesktopQueryResponse
    >(
        query,
        { id }
    );
    const newBuilding = data && data.newBuilding;

    const onStatEventPush = useCallback(() => dataLayerPush({
        event: 'nb_call_like',
        nb_price: newBuilding?.priceMin ?? 0,
        nb_class: newBuilding?.ranking ?? ''
    }), [ newBuilding ]);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const onVisible = useCallback(() => {
        gtmShowData && dataLayerPush(gtmShowData);
    }, [ gtmShowData ]);

    useGtmOnVisible({ containerRef, onVisible });

    if (loading || ! newBuilding) {
        return null;
    }

    const { buildingStatus } = newBuilding;
    const routeParams = { ...newBuilding.routeParams } as typeof newBuilding.routeParams;

    const link = onChangeGlobalContext.linkBuilder.card({
        id: routeParams!.id,
        // @ts-ignore
        type: routeParams!.type,
        name: routeParams!.name,
        region: routeParams!.region,
        subRegion: routeParams!.subRegion,
        erid
    }).split('?')[0];

    return (
        <div
            className={cn()}
            style={{
                background: `linear-gradient(180deg, rgba(31, 31, 46, 0.1) 0%, rgba(31, 31, 46, 0.8) 100%), url(${
                    newBuilding.gallery?.main?.promoSnippet
                }) no-repeat`,
                backgroundSize: 'cover'
            }}
            onClick={() => gtmClickData && dataLayerPush(gtmClickData)}
            ref={containerRef}
        >
            <Grid cols={15} gap={false}>
                <Grid.Cell m={8}>
                    <div className={cn('leftBlock')}>
                        <Image
                            className={cn('developerLogo', {
                                [developerName]: true
                            })}
                            url={developerLogo}
                        />
                        <div>
                            <div className={cn('badgeWrapper')}>
                                <Badge color='white'>Реклама</Badge>
                            </div>
                            <Typography
                                variant='promo-h1'
                                color='white-500'
                                bold
                            >
                                {newBuilding.title.nominative}
                            </Typography>
                            {
                                buildingStatus?.length ? (
                                    <List
                                        className={cn('buildingStatus')}
                                    >
                                        {
                                            buildingStatus.map(({ title }, index) =>
                                                <ListItem key={title}>
                                                    <Typography
                                                        variant='h4'
                                                        color='white-500'
                                                        tag='span'
                                                    >
                                                        {renderBuildingStatus(title, index, buildingStatus.length)}
                                                    </Typography>
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                ) : null
                            }
                            <NewBuildingPromoSnippetMetro
                                // @ts-ignore
                                newBuilding={newBuilding}
                            />
                            <Typography
                                className={cn('address')}
                                color='white-500'
                            >
                                {newBuilding.address}
                            </Typography>
                            <NewBuildingPromoSnippetButtons
                                // @ts-ignore
                                newBuilding={newBuilding}
                                onStatEventPush={onStatEventPush}
                                gtm={dataGTM.phoneClick}
                            />
                            <Typography
                                color='secondary-alt-bg'
                                variant='small-alone'
                                className={cn('disclaimer')}
                            >
                                {disclaimer}
                            </Typography>
                        </div>
                    </div>
                </Grid.Cell>
                <Grid.Cell m={7}>
                    <div className={cn('rightBlock')}>
                        <NewBuildingPromoSnippetFlats
                            // @ts-ignore
                            newBuilding={newBuilding}
                        />
                    </div>
                </Grid.Cell>
            </Grid>
            <Link
                href={`${link}?erid=${erid}`}
                target='blank'
                gtm={dataGTM.click}
            />
        </div>
    );
};

export default NewBuildingPromoSnippet;
