/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, FunctionComponent } from 'react';

import classname from '@search/classname/src';

import MetroMapView from './lib/MetroMapView';
import './lib/styles.css';

import './styles.css';

const cn = classname.bind(null, 'MapMetroMapSelector');

interface IProps {
    svg: string;
    vertical?: boolean;
    selected: Set<number>;
    onChange: (data: any) => void;
}

const MetroMap: FunctionComponent<IProps> = props => {
    const { svg, vertical, selected, onChange } = props;

    const svgNode = useMemo(() => {
        const node = document.createElement('div');

        node.innerHTML = svg;

        return node.firstChild as HTMLElement;
    }, [ svg ]);

    const metroMap = useMemo(() => {
        return new MetroMapView({
            node: svgNode,
            selected,
            onChange
        });
    }, [ svgNode ]); // NOTE `selected` и `onChange` отсутствует в списке нарочно

    useEffect(() => {
        metroMap.setOnChange(onChange);
        metroMap.model.setStations(selected);
    }, [ metroMap, onChange, selected ]);

    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current.appendChild(svgNode);
        }
    }, [ svgNode, ref.current ]);

    return (
        <div ref={ref} className={cn(null, { vertical })} />
    );
};

export default MetroMap;
