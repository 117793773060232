import React, { useCallback } from 'react';

import { Typography } from '@vtblife/uikit';
import { PhoneInput, Size } from '@vtblife/uikit/legacy';

import { CoreSvgSpriteIcon } from '@search/vtbeco-frontend-core/view/common/components/CoreSvgSpriteIcon/CoreSvgSpriteIcon';

import { cnNewBuildingQuiz } from '../../index';
import { DISCOUNT_ITEMS } from '../../dict';
import type { UpdateData, UpdateEvent } from '../types';

export const PhoneView = (({
    phoneValue,
    updateData,
    isMobile,
    isVtb
}: {
    phoneValue: string;
    hasNotAgreedValue: string;
    updateData: UpdateData;
    updateEvent: UpdateEvent;
    isMobile: boolean;
    isVtb?: boolean;
}) => {
    const updatePhone = useCallback(
        (value: string) => updateData(undefined, {
            phone: value
        }),
        [ updateData ]
    );

    return (
        <div className={cnNewBuildingQuiz('filter', { phone: true })}>
            <PhoneInput
                fullWidth={isMobile}
                width={isMobile ? 540 : undefined}
                value={phoneValue}
                onChange={updatePhone}
                hasClear
                label='Номер телефона'
                size={Size.Large}
                className={cnNewBuildingQuiz('phone')}
            />
            {! isVtb && (<>
                <Typography variant='primary'>
                    В подарок дадим промокоды на наши сервисы:
                </Typography>
                <div className={cnNewBuildingQuiz('advantages')}>
                    {DISCOUNT_ITEMS.map(({ iconName, point }, idx, array) => (
                        <div
                            key={iconName}
                            className={cnNewBuildingQuiz('point')}
                        >
                            <div>
                                <CoreSvgSpriteIcon
                                    color='#FF784B'
                                    name={iconName}
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <Typography
                                variant='primary'
                                bold
                            >
                                {`скидку ${point}${idx < array.length - 1 ? ';' : '.'}`}
                            </Typography>
                        </div>
                    ))}
                </div>
            </>)}
        </div>
    );
}) as React.FC;
