import React, { useContext, useMemo } from 'react';

import {
    NewBuildingWhitelabelEnum,
    NewBuildingsLinkCloud
} from '@search/graphql-typings';
import { Route } from '@search/router/src';
import { errorCatcherWrap } from '@search/error/src/catcher';
import classname from '@search/classname/src';

import { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { SeoInfoType } from '@search/nb-texts/src/makeSEOTexts';

import { RegionIdEnum } from '@search/vtbeco-frontend-core/enums';

import type { Shared } from '../../../../types/shared';
import { NewBuildingSearchLinkCloud, LinksCloudViewType } from '../NewBuildingSearchLinkCloud';
import { CloudLinkType, useCloudLinks } from '../NewBuildingSearchLinkCloud/useCloudLinks';
import { NewBuildingSearchFAQ } from '../NewBuildingSearchFAQ';
import type { NewBuildingSearchQuery$data as NewBuildingSearchQueryResponse } from '../NewBuildingSearch/__generated__/NewBuildingSearchQuery.graphql';
import { GlobalContext, IGlobalContext, IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';
import './styles.css';

const cn = classname.bind(null, 'NewBuildingSearchSeoSection');

export const NewBuildingSearchSeoSection: React.FunctionComponent<{
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    pageParams: INewBuildingSearchType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore: any;
    view: LinksCloudViewType;
    seoInfo?: SeoInfoType;
    whiteLabel?: NewBuildingWhitelabelEnum;
    linkCloudParams?: NewBuildingsLinkCloud;
    developers?: NonNullable<NewBuildingSearchQueryResponse['searchDevelopers']>['items'];
    rating?: NonNullable<NewBuildingSearchQueryResponse['searchNewBuildings']>['rating'];
}> = ({
    route,
    pageParams,
    geoStore,
    view,
    seoInfo,
    whiteLabel,
    linkCloudParams,
    developers = [],
    rating
}) => {
    const cloudLinks = useCloudLinks({
        route,
        geoStore,
        linkCloudParams,
        pageParams
    });

    const districtCloudHeading = useMemo(() => pageParams.region === RegionIdEnum.MSK ||
        pageParams.region === RegionIdEnum.MSK_OBL ? 'Округ и район' : 'Район',
    [ pageParams.region ]
    );

    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    const region = Number(pageParams.region);
    const { locative } = region &&
        globalContext.rootRegions.getById(region) ||
        {};

    const developersCloudLinks = useMemo(() => [
        ...developers.map(({
            id,
            translitName,
            name
        }) => ({
            text: name,
            url: onChangeGlobalContext.linkBuilder.developer!({
                region,
                id,
                name: translitName
            })
        }))
    ] as CloudLinkType[], [
        region,
        developers,
        onChangeGlobalContext.linkBuilder
    ]);

    const hasCloudLink = developersCloudLinks.length || cloudLinks.seeAlso.length ||
        cloudLinks.metro.length || cloudLinks.city.length || cloudLinks.district.length;

    return (
        <>
            {
                hasCloudLink ? (
                    <div className={cn('linkCloudWrapper')}>
                        {
                            developersCloudLinks.length ? (
                                <NewBuildingSearchLinkCloud
                                    view={view}
                                    heading={`Застройщики${locative ? ` ${locative}` : ''}`}
                                    links={developersCloudLinks}
                                    moreLink={{
                                        text: 'Все застройщики',
                                        url: onChangeGlobalContext.linkBuilder.developerSearch!({
                                            region
                                        })
                                    }}
                                />
                            ) : null
                        }
                        {
                            cloudLinks.seeAlso.length ? (
                                <NewBuildingSearchLinkCloud
                                    view={view}
                                    heading='Посмотрите также'
                                    links={cloudLinks.seeAlso}
                                />
                            ) : null
                        }
                        {
                            cloudLinks.metro.length ? (
                                <NewBuildingSearchLinkCloud
                                    view={view}
                                    heading='Метро'
                                    links={cloudLinks.metro}
                                />
                            ) : null
                        }
                        {
                            cloudLinks.city.length ? (
                                <NewBuildingSearchLinkCloud
                                    view={view}
                                    heading='Город'
                                    links={cloudLinks.city}
                                />
                            ) : null
                        }
                        {
                            cloudLinks.district.length ? (
                                <NewBuildingSearchLinkCloud
                                    view={view}
                                    heading={districtCloudHeading}
                                    links={cloudLinks.district}
                                />
                            ) : null
                        }
                    </div>
                ) : null
            }
            <NewBuildingSearchFAQ
                pageParams={pageParams}
                view={view}
                seoInfo={seoInfo}
                whiteLabel={whiteLabel}
                rating={rating ?? 0}
            />
        </>
    );
};

export const NewBuildingSearchSeoSectionErrorWrapped = errorCatcherWrap(NewBuildingSearchSeoSection);
