import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { Shared } from '../../types/shared';

const config: Shared.IInstanceConfig = {
    searchIndex: NewBuildingWhitelabelEnum.Vtb,
    accreditedKey: 'accreditedByVtb',
    hasDescription: true,
    productionBaseUrl: '//vtb.ru/',
    productionSearchUrl: '/',
    host: 'https://vitrina.vtb.ru',
    seoHost: 'vitrina.vtb.ru',
    hasCleanURLs: false,
    rootRegionsType: NewBuildingWhitelabelEnum.Vtb,
    preferentialMortgageBadgeVariant: 'vtb',
    hasDeveloperPromo: false,
    hasRepairRequest: false,
    hasOffers: false,
    hasSpecialEvents: true,
    hasBreadcrumbs: false,
    hasCustomOfferFilterSize: true,
    hasCustomDeveloperBtnSize: true,
    hasFavoritesButton: false,
    hasOpenGraph: false,
    showAppleHomeScreenIcon: false,
    hasFullDescription: false,
    hasNews: false,
    hasChat: false,
    hasRegionMortgageButton: false,
    hasTradeIn: false,
    hasQuickSelection: false,
    hasCustomerFeedback: false,
    hasInfoURLs: false,
    GTM_ID: {
        default: 'GTM-N7S7DNR',
        production: 'GTM-NNC8ZQP'
    },
    headerAppName: 'wl-nb-search',
    hasQuiz: true,
    hasQuizIdle: false,
    hasNewBuildingAdd: false,
    brandColor: '#1e4bd2',
    CT_ID: {
        testing: 'lmb48t8x',
        production: 'i2czl18z'
    },
    calltouchUnitId: {
        Msk: 34362,
        Spb: 34363
    }
};

export default config;
