/**
 * Форматирует неполный номер телефона в вид +7 999 999 •• ••
 * @param {number} code
 * @param {number | string} number
 * @returns {string}
 */
export const formatIncompletePhone = (code: number | string, number: number | string): string => {
    const n = number.toString();

    // eslint-disable-next-line max-len
    return `${String(code) === '8' ? code : `+${code}`} ${n[0] || '•'}${n[1] || '•'}${n[2] || '•'} ${n[3] || '•'}${n[4] || '•'}${n[5] || '•'} ${n[6] || '•'}${n[7] || '•'} ${n[8] || '•'}${n[9] || '•'}`;
};

const PHONE = /(\d)(\d{0,3})(\d{0,3})(\d{0,4})/;
const PHONE_SEPARATED = /(\d)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/;

/**
 * Форматирует номер телефона в вид +7 (999) 999-9999
 * @param {string} phone
 * @param {boolean} skipSymbols
 * @param {boolean} separateLast
 * @param {boolean} skipParenthesis
 * @returns {string}
 */
export const formatPhone = (
    phone: string,
    params?:
        { skipSymbols?: boolean; separateLast?: boolean; skipParenthesis?: boolean; unbreakable?: boolean }
): string => {
    let phoneClean = phone.toString().replace(/\D/g, '');
    const lb = params?.skipSymbols || params?.skipParenthesis ? '' : '(';
    const rb = params?.skipSymbols || params?.skipParenthesis ? '' : ')';
    // eslint-disable-next-line
    const h = params?.skipSymbols ? params?.unbreakable ? '\u00A0' : ' ' : '-';
    const s = params?.unbreakable ? '\u00A0' : ' '; // space

    /**
     * Автоматическое добавление +7 при начале ввода номера без кода
     */
    if (phoneClean.length === 1 && phoneClean !== '7' && phoneClean !== '8') {
        phoneClean = `7${phoneClean}`;
    }

    const x = phoneClean.match(params?.separateLast ? PHONE_SEPARATED : PHONE);

    if (! x) {
        return phoneClean;
    }

    if (x[5]) {
        return `+7${s}${lb}${x[2]}${rb}${s}${x[3]}${h}${x[4]}${h}${x[5]}`; // +7 (999) 999-99-99
    }

    if (x[4]) {
        return `+7${s}${lb}${x[2]}${rb}${s}${x[3]}${h}${x[4]}`; // +7 (999) 999-9999
    }

    if (x[3]) {
        return `+7${s}${lb}${x[2]}${rb}${s}${x[3]}`; // +7 (999) 9999
    }

    if (x[2]) {
        return `+7${s}${lb}${x[2]}${rb}`; // +7 (999)
    }

    return '+7';
};
