import React, { FunctionComponent, MutableRefObject, useCallback, useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

import classname from '@search/classname/src';

import Sticky from '../../../../../common/components/Sticky';

import './styles.css';
import NewBuildingCardNavbar, { INewBuildingCardNavbarProps } from '../index';

const cn = classname.bind(null, 'StickyNavbar');

export const StickyNavbar: FunctionComponent<INewBuildingCardNavbarProps & {
    anchor: MutableRefObject<HTMLDivElement>;
}> = ({
    anchor,
    ...navbarProps
}) => {
    const [ isNavbarVisible, showNavbar ] = useState<boolean>(false);

    const handleScroll = useCallback(() => {
        const { bottom = 1 } = anchor.current?.getBoundingClientRect() ?? {};

        showNavbar(bottom <= 0);
    }, [ anchor, showNavbar ]);

    useEffect(() => {
        const throttledHandleScroll = throttle(handleScroll, 100);

        document.addEventListener('scroll', throttledHandleScroll);

        return () => {
            document.removeEventListener('scroll', throttledHandleScroll);
        };
    }, [ handleScroll ]);

    return (
        <Sticky
            top={20}
            visible={isNavbarVisible}
            className={cn()}
        >
            <NewBuildingCardNavbar {...navbarProps} />
        </Sticky>
    );
};
