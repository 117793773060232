/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';

import { Checkbox } from '@vtblife/uikit';

import type {
    NewBuildingQuizPaymentMethodEventAction
} from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingQuizEvent';

import { cnNewBuildingQuiz } from '../../index';
import type { UpdateData, UpdateEvent } from '../types';

const paymentMethodValues = [ {
    label: 'Собственные средства',
    eventAction: 'cash'
}, {
    label: 'Ипотека',
    eventAction: 'mortgage'
}, {
    label: 'Рассрочка',
    eventAction: 'installments'
}, {
    label: 'Пока не знаю',
    eventAction: 'undecided'
} ] as {
    label: string;
    eventAction: NewBuildingQuizPaymentMethodEventAction;
}[];

export const PaymentMethodView = (({
    paymentMethodValue,
    updateData,
    updateEvent
}: {
    paymentMethodValue: string;
    updateData: UpdateData;
    updateEvent: UpdateEvent;
    isMobile: boolean;
}) => {
    const update = useCallback(
        (value: string) => updateData(undefined, {
            paymentMethod: value
        }),
        [ updateData ]
    );

    useEffect(() => {
        const eventAction = paymentMethodValues
            .find(({ label }) => label === paymentMethodValue)
            ?.eventAction;

        eventAction && updateEvent({
            event_category: 'payment_method',
            event_action: eventAction
        });
    }, [ paymentMethodValue, updateEvent ]);

    const paymentMethodRadioButtons = paymentMethodValues
        .map(({ label }) => (
            <Checkbox
                key={label}
                radio
                value={paymentMethodValue === label}
                onChange={() => update(label)}
            >
                {label}
            </Checkbox>
        ));

    return (
        <div className={cnNewBuildingQuiz('filter', { paymentMethod: true })}>
            {paymentMethodRadioButtons}
        </div>
    );
}) as React.FC;
