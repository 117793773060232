import React, { ReactElement } from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import { Button, Typography } from '@vtblife/uikit';

import classname, { NoStrictEntityMods } from '@search/classname/src';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';
import { QRCodePhoneButton } from '@search/vtbeco-frontend-core/view/common/components/QRCodePhoneButton';

import type { ArrayElement } from '../../../../../types';
import { SvgSpriteIcon, SpecialEventIconName } from '../../SvgSpriteIcon';

import { usePhoneButtonContext } from '../PhoneButtonStateProvider';
import type { NewBuildingCardQueryResponse } from '..';

import './styles.css';

export const cn = (
    elem: string,
    mods?: NoStrictEntityMods
) => classname(
    'NewBuildingCard',
    `specialEvents${elem}`,
    mods
);

export type SpecialEvents = NewBuildingCardQueryResponse['newBuilding']['specialEvents'];
export type SpecialEvent = ArrayElement<SpecialEvents>;

const enforceFullStop = (sentence: string) => sentence.replace(/([^.])$/, '$1.');

export const NewBuildingSelectedSpecialEvent = ({
    isMobile = false,
    selectedEvent,
    buildingNameById,
    newBuildingTitle,
    developerTitle,
    dataGTM,
    renderCallBack,
    renderMortgageButton,
    isMskMOorSPbLo,
    hasChat
}: {
    isMobile?: boolean;
    selectedEvent: SpecialEvent;
    buildingNameById: Record<string, string | null>;
    newBuildingTitle: string;
    developerTitle?: string;
    dataGTM: DATA_GTM;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string
    ) => ReactElement | null;
    renderMortgageButton: (label?: string) => React.ReactNode;
    isMskMOorSPbLo: boolean;
    hasChat?: boolean;
}) => {
    const {
        title,
        titleRaw,
        description: {
            short: shortDesc,
            full: fullDesc
        },
        duration: { full: duration },
        buildingIds
    } = selectedEvent;

    const description = fullDesc ||
        shortDesc && enforceFullStop(shortDesc);

    const buildingNames = buildingIds
        .map(id => buildingNameById[String(id)])
        .filter(Boolean);
    const buildingsTotal = buildingNames.length;
    const hasOneBuilding = buildingsTotal === 1;
    const hasMultipleBuildings = buildingsTotal > 1;

    const infoTexts = [
        enforceFullStop(upperFirst([
            duration,
            hasOneBuilding && `для корпуса ${buildingNames[0]}`
        ].filter(Boolean).join(', '))),
        hasMultipleBuildings && `Для корпусов:<br/>${
            buildingNames.map(name => `— ${name}`).join(';<br/>')
        }.`
    ].filter(Boolean) as string[];

    const callBack = renderCallBack(
        DATA_GTM.CARD_CALL_BACK_SPECIAL_EVENT,
        DATA_GTM.CARD_CALL_BACK_SEND_SPECIAL_EVENT,
        `Акция: ${titleRaw}`
    );

    const { buttonsData, handleClick, firstDeveloperId } = usePhoneButtonContext();

    return (
        <div className={cn('SelectedItem', { mobile: isMobile })}>
            <CustomTypography
                level={isMobile ? 'primary' : 'h4'}
                bold
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: title
                }}
            />
            <div className={cn('SelectedItemInfo', { mobile: isMobile })}>
                {
                    description && (
                        <CustomTypography dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: description
                        }} />
                    )
                }
                {
                    infoTexts.map((text, i) => (
                        <CustomTypography
                            key={i}
                            color='secondary'
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __html: text
                            }}
                        />
                    ))
                }
            </div>
            <Typography
                className={cn('NewBuildingTitle')}
                variant='h4'
            >
                {newBuildingTitle}
            </Typography>
            {
                developerTitle ? (
                    <Typography className={cn('DeveloperTitle')}>
                        {`Застройщик ${
                            developerTitle
                        }. Позвоните${
                            hasChat ? ' или напишите' : ''
                        } в отдел продаж и узнайте подробнее.`}
                    </Typography>
                ) : null
            }
            {
                isMobile ? (
                    <div className={cn('Bottom')}>
                        {! isMskMOorSPbLo && (
                            <div className={cn('mortgageButton')}>
                                {renderMortgageButton()}
                            </div>
                        )}
                        {firstDeveloperId && (
                            <Button
                                fullWidth
                                gtm={DATA_GTM.CARD_PHONE_3}
                                onClick={() => handleClick(firstDeveloperId)}
                                variant={isMskMOorSPbLo ? 'primary' : 'secondary'}
                                loading={buttonsData[firstDeveloperId].isLoading}
                            >
                                Позвонить
                            </Button>
                        )}
                        {callBack && (
                            <div className={cn('CallBackWrapper')}>
                                {callBack}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={cn('Bottom')}>
                        {firstDeveloperId && (
                            <div className={cn('PhoneWrapper')}>
                                <QRCodePhoneButton
                                    href={buttonsData[firstDeveloperId].href}
                                    onClick={() => handleClick(firstDeveloperId)}
                                    qrCodeValue={buttonsData[firstDeveloperId].qrCodeValue}
                                    gtm={dataGTM}
                                    loading={buttonsData[firstDeveloperId].isLoading}
                                >
                                    {buttonsData[firstDeveloperId].text}
                                </QRCodePhoneButton>
                            </div>
                        )}
                        {callBack && (
                            <div className={cn('CallBackWrapper')}>
                                {callBack}
                            </div>
                        )}
                    </div>
                )
            }
        </div>
    );
};

export const NewBuildingSpecialEvent = ({
    specialEvent,
    onClick: handleClick
}: {
    specialEvent: SpecialEvent;
    onClick: () => void;
}) => {
    const {
        isHighlighted,
        type,
        title,
        description: { short: description },
        duration: { short: duration }
    } = specialEvent;

    return (
        <div
            className={cn('Item', {
                highlighted: isHighlighted
            })}
            onClick={handleClick}
        >
            <SvgSpriteIcon
                name={upperFirst(camelCase(type)) as SpecialEventIconName}
                {...(isHighlighted ? {
                    color: '#6DAC25'
                } : {})}
            />
            <SvgSpriteIcon
                name='QuestionMark'
                width={16}
                height={16}
            />
            <CustomTypography
                className={cn('ItemTitle')}
                level='primary-alone'
                bold
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: title
                }}
            />
            {
                description && (
                    <CustomTypography
                        className={cn('ItemDescription')}
                        level='secondary-alone'
                        color='secondary'
                        dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: description
                        }}
                    />
                )
            }
            {
                duration && (
                    <CustomTypography
                        className={cn('ItemDuration')}
                        level='secondary-alone'
                        color='secondary'
                        dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: duration
                        }}
                    />
                )
            }
        </div>
    );
};
