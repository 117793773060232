import React from 'react';

import { YMapControl } from './Control';

export interface ControlAttributes {
    size: Vector;
    margin: Vector;
}

export interface Vector {
    x: number;
    y: number;
}

export type YMapControlSize = keyof typeof defaultControlAttributes

export type IMapControlManagerLayout = 'horizontal' | 'vertical'
export type YMapControlHorizontalAlign = 'left' | 'middle' | 'right'
export type YMapControlVerticalAlign = 'top' | 'middle' | 'bottom'
export interface YMapControlManagerOptions {
    mapWidth: number;
    mapHeight: number;
    size?: YMapControlSize;
    hAlign?: YMapControlHorizontalAlign;
    vAlign?: YMapControlVerticalAlign;
    layout?: IMapControlManagerLayout;
}

export interface IYMapControlManager {
    readonly attributes: Readonly<ControlAttributes>;
    readonly layout: IMapControlManagerLayout;
    readonly mapSize: Vector;
    readonly align: Readonly<{
        x: YMapControlHorizontalAlign;
        y: YMapControlVerticalAlign;
    }>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    add(control: YMapControl<{}, any, {}>): () => void;
}

export const YMapControlManagerContext = React.createContext<
    IYMapControlManager | undefined
>(undefined);

export function useYMapControlManager() {
    const instance = React.useContext(YMapControlManagerContext);

    if (! instance) {
        throw new Error(
            'Wrap YMapControl component into <YMapControlGroup>...</YMapControlGroup>'
        );
    }
    return instance;
}

export const defaultControlAttributes = {
    m: {
        size: { x: 32, y: 32 },
        margin: { x: 12, y: 12 }
    },
    mChangedMargin: {
        size: { x: 32, y: 32 },
        margin: { x: 12, y: 12 }
    },
    l: {
        size: { x: 44, y: 44 },
        margin: { x: 12, y: 12 }
    }
};

export const defaultGroupAttributes = {
    m: {
        margin: { x: 16, y: 16 }
    },
    mChangedMargin: {
        margin: { x: 24, y: 24 }
    },
    l: {
        margin: { x: 16, y: 16 }
    }
};
