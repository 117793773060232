import React, { useEffect, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import {
    AdditionalRegionWithMetroIdEnum,
    AdditionalRegionOblWithMetroIdEnum,
    RegionIdEnum
} from '@search/filter-enums/enums/Region';
import type { MetroStationBase } from '@search/vtbeco-frontend-core/domain/geo/MetroStation';

import type { IEvent } from '../../../ApiProvider/yandex-maps';
import { useYMapPane } from '../../../Pane';
import type { YMapVector } from '../../../Vector';

import { YMapPlacemarkZIndexes } from '../../Tooltip';

import { YMapPlacemarkSubwayPinHint as SubwayHint } from './Hint';
import { YMapPlacemarkSubwayPinIcon as SubwayIcon } from './Icon';

const pickIconName = (regionId: number) => {
    switch (regionId) {
        case RegionIdEnum.MSK:
        case RegionIdEnum.MSK_OBL:
        case RegionIdEnum.MSK_AND_MSK_OBL:
            return 'MskMetroLogo';

        case RegionIdEnum.SPB:
        case RegionIdEnum.LEN_OBL:
        case RegionIdEnum.SPB_AND_LEN_OBL:
            return 'SpbMetroLogo';

        case AdditionalRegionWithMetroIdEnum.KZN:
            return 'KznMetroLogo';

        case AdditionalRegionWithMetroIdEnum.EKB:
        case AdditionalRegionOblWithMetroIdEnum.EKB_OBL:
            return 'EkbMetroLogo';

        case AdditionalRegionWithMetroIdEnum.NSK:
        case AdditionalRegionOblWithMetroIdEnum.NSK_OBL:
            return 'NskMetroLogo';

        case AdditionalRegionWithMetroIdEnum.NNV:
        case AdditionalRegionOblWithMetroIdEnum.NNV_OBL:
            return 'NnvMetroLogo';

        case AdditionalRegionWithMetroIdEnum.SMR:
        case AdditionalRegionOblWithMetroIdEnum.SMR_OBL:
            return 'SmrMetroLogo';

        default:
            return 'MskMetroLogo';
    }
};

export const pickStartShowZoom = (regionId: number) => {
    switch (regionId) {
        case RegionIdEnum.MSK:
        case RegionIdEnum.MSK_OBL:
        case RegionIdEnum.MSK_AND_MSK_OBL:
            return 14;

        default:
            return 13;
    }
};

export interface YMapPlacemarkSubwayPinProps {
    coordinates: YMapVector;
    id: MetroStationBase['id'];
    title: MetroStationBase['title'];
    allRoutesColorsList: MetroStationBase['allRoutesColorsList'];
    routeTitle: string;
    regionId: number;
}

export function YMapPlacemarkSubwayPin({
    coordinates,
    title,
    allRoutesColorsList,
    id,
    routeTitle,
    regionId
}: YMapPlacemarkSubwayPinProps) {
    const {
        map,
        api: {
            Placemark,
            templateLayoutFactory,
            geometry: { Point }
        }
    } = useYMapPane();

    const iconName = pickIconName(regionId);
    const startShowZoom = pickStartShowZoom(regionId);

    const hint = (
        <SubwayHint
            id={id}
            title={title}
            iconName={iconName}
            allRoutesColorsList={allRoutesColorsList}
            routeTitle={routeTitle}
        />
    );
    const hintTemplate = renderToStaticMarkup(hint);
    const hintContentLayout = templateLayoutFactory.createClass(hintTemplate);

    const iconColorList = useMemo(() => allRoutesColorsList.slice(0).reverse(), [ allRoutesColorsList ]);

    const iconColors = useMemo(
        () => iconColorList.map((color, idx) => `{{options.color${idx}}}`),
        [ iconColorList ]
    );

    const icon = (
        <SubwayIcon
            iconColors={iconColors}
            iconName={iconName}
            iconBgColor='{{options.bgColor}}'
        />
    );
    const iconTemplate = renderToStaticMarkup(icon);
    const iconContentLayout = templateLayoutFactory.createClass(iconTemplate);

    const iconColorsDefault = useMemo(
        () => iconColorList.reduce<Record<string, string>>((result, color, idx) => {
            result[`iconColor${idx}`] = color;
            return result;
        }, {}),
        [ iconColorList ]
    );

    const iconColorsHovered = useMemo(
        () => iconColorList.reduce<Record<string, string>>((result, color, idx) => {
            result[`iconColor${idx}`] = 'white';
            return result;
        }, {}),
        [ iconColorList ]
    );

    const iconBgColorHovered = useMemo(() => allRoutesColorsList[0], [ allRoutesColorsList ]);

    useEffect(() => {
        const placemark = new Placemark(
            new Point([ coordinates.y, coordinates.x ]),
            {},
            {
                // @ts-ignore
                iconLayout: 'default#imageWithContent',
                iconImageHref: '',
                iconBgColor: 'white',
                ...iconColorsDefault,
                iconContentLayout,
                iconImageSize: [ 32, 32 ],
                iconContentSize: [ 32, 32 ],
                iconImageOffset: [ -16, -16 ],
                hintContentLayout,
                hintCloseTimeout: 0,
                hintOpenTimeout: 150,
                cursor: 'arrow',
                ...YMapPlacemarkZIndexes.Default
            }
        );

        Math.round(map.getZoom()) >= startShowZoom && map.geoObjects.add(placemark);

        placemark.events
            .add('mouseenter', (e: object | IEvent) => {
                // @ts-ignore
                (e as IEvent).get('target').options.set({
                    iconBgColor: iconBgColorHovered,
                    ...iconColorsHovered
                });
            })
            .add('mouseleave', (e: object | IEvent) => {
                // @ts-ignore
                (e as IEvent).get('target').options.set({
                    iconBgColor: 'white',
                    ...iconColorsDefault
                });
            });

        return () => {
            map.geoObjects.remove(placemark);
        };
    }, [
        map, coordinates.x, coordinates.y, templateLayoutFactory, Placemark, Point,
        iconContentLayout, hintContentLayout, iconColorsDefault, iconColorsHovered, iconBgColorHovered, startShowZoom
    ]);

    return null;
}
