import React from 'react';
import { GqlClient } from './GqlClient';

export const GqlContext = React.createContext<GqlClient | undefined>(undefined);

export interface GQLContextProviderProps {
    children: React.ReactChild;
    gql: GqlClient;
}

export function GqlContextProvider({
    gql,
    children
}: GQLContextProviderProps) {
    return (
        <GqlContext.Provider value={gql}>
            {children}
        </GqlContext.Provider>
    );
}

export function useGqlContext(): GqlClient {
    const context = React.useContext(GqlContext);

    if (! context) {
        throw new Error('Wrap your app into GqlContextProvider');
    }

    return context;
}
