import * as React from 'react';
import { Grid } from '@vtblife/uikit';
import classname from '@search/classname/src';
import './styles.css';

const cn = classname.bind(null, 'MockSnippet');

const MockSnippet: React.FC<{}> = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mixin = (selector: string, ...rest: any) => [ cn('mock'), cn(selector, ...rest) ].join(' ');

    return (
        <div className={cn()}>
            <Grid.Row>
                <Grid.Col xs={8}>
                    <div className={mixin('gallery')} />
                </Grid.Col>
                <Grid.Col xs={16}>
                    <Grid.Row>
                        <Grid.Col xs={15}>
                            <div className={cn('body')}>
                                <div className={mixin('title')} />
                                <div className={mixin('description', { full: true })} />
                                <div className={mixin('description', { full: true })} />
                                <div className={mixin('description', { full: false })} />
                            </div>
                        </Grid.Col>
                        <Grid.Col xs={9}>
                            <div className={mixin('price')} />
                            <div className={mixin('priceDetail')} />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col xs={8} />
                <Grid.Col xs={16}>
                    <div className={cn('footer')}>
                        <div className={mixin('phone')} />
                        <div className={mixin('avatar')} />
                        <div className={mixin('name')} />
                        <div className={mixin('map')} />
                    </div>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
};

export default MockSnippet;
