import { useContext, useMemo } from 'react';

import { NewBuildingSnippet } from '@search/graphql-typings';
import { INewBuildingCardType } from '@search/nb-routes/src/NewBuildingCardRoute';

import { Shared } from '../../../../types/shared';

import { IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';

export function getFilterParams(pageParams?: Shared.ISearchParams) {
    return pageParams ? {
        commissioningDate: pageParams.commissioning ? [ pageParams.commissioning ] : undefined,

        finishingFlat: pageParams.hasFinishing,

        floorMin: pageParams.floorMin,
        floorMax: pageParams.floorMax,
        floorFirst: pageParams.floorFirst,
        floorLast: pageParams.floorLast,

        rooms: pageParams.rooms,

        priceMin: pageParams.priceMin,
        priceMax: pageParams.priceMax,
        priceType: pageParams.priceType,

        totalAreaRangeMin: pageParams.totalAreaRangeMin,
        totalAreaRangeMax: pageParams.totalAreaRangeMax,
        livingAreaRangeMin: pageParams.livingAreaRangeMin,
        livingAreaRangeMax: pageParams.livingAreaRangeMax,
        kitchenAreaRangeMin: pageParams.kitchenAreaRangeMin
    } : {};
}

export const useNewBuildingSnippetUrl = (
    routeParams: NewBuildingSnippet['routeParams'],
    pageParams?: Shared.ISearchParams
) => {
    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);

    const filterParams = useMemo(
        () => getFilterParams(pageParams),
        [ pageParams ]
    );

    return useMemo(
        () => onChangeGlobalContext.linkBuilder.card({
            region: routeParams.region,
            subRegion: routeParams.subRegion,
            id: routeParams.id,
            type: routeParams.type as INewBuildingCardType['type'],
            name: routeParams.name,
            ...filterParams
        }),
        [ onChangeGlobalContext, routeParams, filterParams ]
    );
};

const getCommissioningDateParameter = (pageParams?: Shared.ISearchParams) => {
    return pageParams?.commissioning ? `commissioningDate=${pageParams?.commissioning}` : '';
};

const getFinishingFlatParameter = (pageParams?: Shared.ISearchParams) => {
    return pageParams?.finishingType ?
        pageParams?.finishingType
            .filter(item => item)
            .map(item => item.toUpperCase())
            .filter(item => [ 'FINE', 'WITHOUT_FINISHING' ].includes(item))
            .map(item => `finishingFlat=${item}`) // finishingType (list page) -> finishingFlat (card page)
            .join('&') :
        '';
};

const getRoomsParameter = (pageParams?: Shared.ISearchParams) => {
    return [ ...pageParams?.rooms || [] ]
        .map(item => `rooms=${item}`)
        .join('&');
};

const getPriceMinParameter = (pageParams?: Shared.ISearchParams) => {
    return pageParams?.priceMin ? `priceMin=${pageParams?.priceMin}` : '';
};

const getPriceMaxParameter = (pageParams?: Shared.ISearchParams) => {
    return pageParams?.priceMax ? `priceMax=${pageParams?.priceMax}` : '';
};

export const getUrlParameters = (pageParams?: Shared.ISearchParams) => {
    const params: Array<string> = [];

    params.push(getCommissioningDateParameter(pageParams));
    params.push(getFinishingFlatParameter(pageParams));
    params.push(getRoomsParameter(pageParams));
    params.push(getPriceMinParameter(pageParams));
    params.push(getPriceMaxParameter(pageParams));

    const result = params
        .filter(item => item)
        .join('&');

    return result ? `?${result}` : '';
};
