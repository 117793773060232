import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Modal } from '@vtblife/uikit';

import { DATA_GTM } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/constants';

import Slider from '@search/vtbeco-frontend-core/view/common/components/Slider';
import {
    cn,
    NewBuildingSpecialEvent,
    NewBuildingSelectedSpecialEvent
} from '../../../../common/components/NewBuildingCard/NewBuildingCardSpecialEvents';
import type {
    SpecialEvents,
    SpecialEvent
} from '../../../../common/components/NewBuildingCard/NewBuildingCardSpecialEvents';

import './styles.css';

const NewBuildingSelectedSpecialEventModal = ({
    selectedEvent,
    buildingNameById,
    onClose: handleClose,
    newBuildingTitle,
    developerTitle,
    dataGTM,
    renderCallBack,
    hasChat,
    isMskMOorSPbLo,
    renderMortgageButton
}: {
    selectedEvent: SpecialEvent;
    buildingNameById: Record<string, string | null>;
    onClose: () => void;
    newBuildingTitle: string;
    developerTitle?: string;
    dataGTM: DATA_GTM;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string
    ) => ReactElement | null;
    hasChat?: boolean;
    isMskMOorSPbLo: boolean;
    renderMortgageButton: (label?: string) => React.ReactNode;
}) => (
    <Modal
        opened
        size='s'
        onClose={handleClose}
    >
        <NewBuildingSelectedSpecialEvent
            selectedEvent={selectedEvent}
            buildingNameById={buildingNameById}
            newBuildingTitle={newBuildingTitle}
            developerTitle={developerTitle}
            dataGTM={dataGTM}
            renderCallBack={renderCallBack}
            hasChat={hasChat}
            isMskMOorSPbLo={isMskMOorSPbLo}
            renderMortgageButton={renderMortgageButton}
        />
    </Modal>
);

const NewBuildingSpecialEvents = ({
    specialEvents,
    buildingNameById,
    newBuildingTitle,
    developerTitle,
    dataGTM,
    renderCallBack,
    hasChat,
    isMskMOorSPbLo,
    renderMortgageButton
}: {
    specialEvents: SpecialEvents;
    buildingNameById: Record<string, string | null>;
    newBuildingTitle: string;
    developerTitle?: string;
    dataGTM: DATA_GTM;
    renderCallBack: (
        dataGTM?: DATA_GTM,
        sendDataGTM?: DATA_GTM,
        extra?: string
    ) => ReactElement | null;
    hasChat?: boolean;
    isMskMOorSPbLo: boolean;
    renderMortgageButton: () => React.ReactNode;
}) => {
    const [ selectedEvent, selectEvent ] = useState<SpecialEvent | null>(null);
    const [ maxItems, setMaxItems ] = useState<number>(3);

    const closeModal = useCallback(() => selectEvent(null), []);
    const handleSetMaxItems = useCallback((value: number) => setMaxItems(value), []);

    const eventsTotal = useMemo(() => specialEvents.length, [ specialEvents ]);

    return (
        <div className={cn('')}>
            <Slider
                contentClassName={cn('SliderContent')}
                onSetMaxItems={handleSetMaxItems}
            >
                {
                    specialEvents.map((specialEvent, i) => (
                        <div
                            key={i}
                            className={cn('ItemWrapper')}
                            style={{
                                minWidth: `${100 / Math.min(eventsTotal, maxItems)}%`
                            }}
                        >
                            <NewBuildingSpecialEvent
                                specialEvent={specialEvent}
                                onClick={() => selectEvent(specialEvent)}
                            />
                        </div>
                    ))
                }
            </Slider>
            {
                selectedEvent && (
                    <NewBuildingSelectedSpecialEventModal
                        selectedEvent={selectedEvent}
                        onClose={closeModal}
                        buildingNameById={buildingNameById}
                        newBuildingTitle={newBuildingTitle}
                        developerTitle={developerTitle}
                        dataGTM={dataGTM}
                        renderCallBack={renderCallBack}
                        hasChat={hasChat}
                        isMskMOorSPbLo={isMskMOorSPbLo}
                        renderMortgageButton={renderMortgageButton}
                    />
                )
            }
        </div>
    );
};

export default NewBuildingSpecialEvents;
