import { useContext, useMemo } from 'react';

import { Route } from '@search/router/src/Route';
import type { INewBuildingSearchType } from '@search/nb-routes/src/NewBuildingSearchRoute';
import type { SeoInfoType } from '@search/nb-texts/src/makeSEOTexts';
import { makeSEOTexts } from '@search/nb-texts/src/makeSEOTexts';
import { RootRegions } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/root-regions';

import { Shared } from '../../../../types/shared';

import { GlobalContext, IOnChangeGlobalContext, OnChangeGlobalContext } from '../GlobalContext';
import { useSEOTexts as useSEOTextsJSON } from '../SeoTexts/SEOTextsProvider';

function getGeoNumber(geo: number | readonly number[] | null | undefined) {
    if (Array.isArray(geo)) {
        return geo.length;
    }

    return geo ? 1 : 0;
}

function getGeoObj(pageParams: INewBuildingSearchType, rootRegions: RootRegions) {
    let result;
    const slugNumber = getGeoNumber(pageParams.addressId) + getGeoNumber(pageParams.districtId);

    if (pageParams.addressId && slugNumber < 2) {
        const addressId = Array.isArray(pageParams.addressId) ? pageParams.addressId[0] : pageParams.addressId;

        result = rootRegions.getGeoSlug(addressId)?.locative;
    }

    if (pageParams.districtId && slugNumber < 2) {
        const districtId = Array.isArray(pageParams.districtId) ? pageParams.districtId[0] : pageParams.districtId;

        result = rootRegions.getGeoSlug(districtId)?.locative;
    }

    return result ?? rootRegions.getById(Number(pageParams.region))?.locative;
}

const useSEOTexts = ({
    route,
    pageParams,
    geoStore,
    total
}: {
    route: Route<INewBuildingSearchType, Shared.IRouterContext, {}>;
    pageParams: INewBuildingSearchType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoStore?: any;
    total?: number;
}): {
    title: string;
    description: string;
    heading?: string;
    seoInfo?: SeoInfoType;
} => {
    const {
        instanceConfig: {
            seoHost,
            hasCleanURLs
        },
        rootRegions
    } = useContext(GlobalContext);
    const locative = getGeoObj(pageParams, rootRegions);

    const onChangeGlobalContext = useContext<IOnChangeGlobalContext>(OnChangeGlobalContext);
    const path = onChangeGlobalContext
        .linkBuilder
        .search(pageParams).split('?')[0];
    const { seoTexts } = useSEOTextsJSON(path);

    return useMemo(() => makeSEOTexts({
        route,
        pageParams,
        geoStore,
        locative,
        total,
        seoHost,
        hasCleanURLs,
        seoTexts
    }), [
        route,
        pageParams,
        geoStore,
        locative,
        total,
        seoHost,
        hasCleanURLs,
        seoTexts
    ]);
};

export default useSEOTexts;
