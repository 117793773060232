import React, { Fragment, useCallback, useEffect, useMemo } from 'react';

import { useYMapPane } from '@search/ymap/src/Pane';
import { YMapPolygon } from '@search/ymap/src/Polygon';
import type ymaps from '@search/ymap/src/ApiProvider/yandex-maps';
import classname from '@search/classname/src';

import { cnCustomTypography } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import type { Building, BuildingGroup } from '../types';

import './styles.css';

// eslint-disable-next-line max-len
const hatchingSVG = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMy4wNiAwSC45NEwwIC45NHYyLjEyTDMuMDYgMHptMCA2SC45NEw2IC45NHYyLjEyTDMuMDYgNnoiIGZpbGw9IiMwMDAiLz48L3N2Zz4=';

const MAX_ZOOM = 19;

interface IProps {
    interactive?: boolean;
    withHints?: boolean;
    buildingGroups: BuildingGroup[];
    selectBuildings?: (buildings: Building[]) => void;
    selectedBuildings?: Building[];
    zoomMargin?: number[];
    onBuildingsSelect?: () => void;
    shouldSetBoundsFromPolygons?: boolean;
    shouldBeRemovedOnUnmount?: boolean;
}

const cn = classname.bind(null, 'NewBuildingCard');

export const NewBuildingCardPolygons: React.FunctionComponent<IProps> = ({
    interactive: isInteractive,
    withHints: hasHints,
    buildingGroups,
    selectBuildings,
    selectedBuildings,
    zoomMargin,
    onBuildingsSelect,
    shouldSetBoundsFromPolygons = true,
    shouldBeRemovedOnUnmount
}) => {
    const { api, map } = useYMapPane();

    // @ts-ignore
    const getCenterAndZoom = useCallback((bounds: number[][]) => api.util.bounds.getCenterAndZoom(
        bounds,
        map.container.getSize(),
        // @ts-ignore
        map.options.get('projection'),
        { margin: zoomMargin }
    ), [ api, map, zoomMargin ]);

    useEffect(() => {
        if (shouldSetBoundsFromPolygons) {
            setTimeout(() => {
                const bounds = map.geoObjects.getBounds()!;
                const { center, zoom } = getCenterAndZoom(map.geoObjects.getBounds()!);

                /* eslint-disable @typescript-eslint/no-floating-promises */
                if (zoom > MAX_ZOOM) {
                    map.setCenter(center);
                    map.setZoom(MAX_ZOOM);
                } else {
                    map.setBounds(bounds, { zoomMargin });
                }
                /* eslint-enable @typescript-eslint/no-floating-promises */
            }, 0);
        }
    }, [ shouldSetBoundsFromPolygons, map, zoomMargin, getCenterAndZoom ]);

    const handleSelectBuilding = useCallback((e: object | ymaps.IEvent, building?: Building) => {
        if (! building) {
            return;
        }

        selectBuildings!([ ...selectedBuildings!, building ]);

        onBuildingsSelect && onBuildingsSelect();
    }, [ selectBuildings, onBuildingsSelect, selectedBuildings ]);

    const handleDeselectBuilding = useCallback((e: object | ymaps.IEvent, building?: Building) => {
        if (! building) {
            return;
        }

        selectBuildings!(selectedBuildings!.filter(b => b !== building));

        onBuildingsSelect && onBuildingsSelect();
    }, [ selectBuildings, onBuildingsSelect, selectedBuildings ]);

    const HintLayout = useMemo(() => (
        api.templateLayoutFactory.createClass(`
            <div class="${cn('polygonHint')}">
                <div class="${cn('polygonHintContent')}">
                    <div class="
                        ${cnCustomTypography(null, { level: 'disclaimer-alone', color: 'white-500' })}
                        ${cn('polygonHintTop')}
                        ${cn('polygonHintTop')}_withTick_{{properties.hasAccreditedTick}}
                    ">
                        {{
                            properties.dateString
                        }}{{
                            properties.accreditedString
                        }}{{
                            properties.soldOutString
                        }}{{
                            properties.soonString
                        }}
                    </div>
                    <div class="${cnCustomTypography(null, { level: 'disclaimer-alone', color: 'white-400-alpha' })}">
                        {{properties.address}}
                    </div>
                </div>
            </div>
        `)
    ), [ api ]);

    return (
        <Fragment>
            {
                buildingGroups.map(
                    (buildingGroup, i) => (
                        <Fragment key={i}>
                            {
                                buildingGroup.buildings.map(
                                    (building, j) => {
                                        const isClickable = isInteractive && building.clickable;
                                        const isSelected = selectedBuildings && selectedBuildings.includes(building);

                                        return (
                                            <YMapPolygon
                                                key={j}
                                                polygons={building.polygonCoords!}
                                                fillColor={isSelected ? '#FFFFFF' : buildingGroup.color}
                                                fillOpacity={building.accredited ? 0.4 : 0.2}
                                                strokeColor={buildingGroup.color}
                                                strokeWidth={isSelected ? 2 : 0}
                                                fillImageHref={
                                                    building.accredited ?
                                                        undefined :
                                                        hatchingSVG
                                                }
                                                onClick={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    isClickable ?
                                                        isSelected ? handleDeselectBuilding : handleSelectBuilding :
                                                        undefined
                                                }
                                                cursor={isClickable ? 'pointer' : 'default'}
                                                hintLayout={hasHints ? HintLayout : undefined}
                                                properties={building}
                                                shouldBeRemovedOnUnmount={shouldBeRemovedOnUnmount}
                                            />
                                        );
                                    }
                                )
                            }
                        </Fragment>
                    )
                )
            }
        </Fragment>
    );
};
