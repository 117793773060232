import React from 'react';

import { Button, Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'QuickSelectionCardOfferSearchMobileBanner');

const QuickSelectionCardOfferSearchMobileBanner: React.FC<{
    className?: string;
    onClick?: () => void;
}> = ({
    className,
    onClick
}) => {
    return (
        <div
            onClick={onClick}
            className={cn(null, undefined, className)}
        >
            <div>
                <Typography
                    variant='primary-alone'
                    tag='span'
                    color='purple-500'
                    bold
                >
                    {'Не\u00A0знаете, что\u00A0выбрать? '}
                </Typography>
                <Typography
                    variant='primary-alone'
                    tag='span'
                    bold
                >
                    Доверьтесь экспертам. Бесплатно&nbsp;найдём то,&nbsp;что&nbsp;нужно&nbsp;именно вам
                </Typography>
            </div>
            <Typography
                variant='secondary-alone'
                color='secondary'
                className={cn('text-secondary')}
            >
                Один звонок, без&nbsp;рекламы и&nbsp;спама.
            </Typography>
            <Button
                size='s'
                fullWidth
                variant='promo1'
            >
                Подробнее
            </Button>
        </div>
    );
};

export default QuickSelectionCardOfferSearchMobileBanner;
