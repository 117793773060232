/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useCallback, useEffect } from 'react';

import { Checkbox, Typography } from '@vtblife/uikit';
import Grid from '@vtblife/uikit-grid';

import { IsApartmentsOptions, Rooms } from '@search/filter-enums/enums';

import { ApartmentFilter, PriceFilter, RoomFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import type {
    NewBuildingQuizRoomsEventAction
} from '@search/vtbeco-frontend-core/domain/newbuilding/analytics/NewBuildingQuizEvent';

import { cnNewBuildingQuiz } from '../../index';
import type { UpdateData, UpdateEvent } from '../types';

export type RoomQuizFilterType = Rooms.STUDIO | Rooms.ROOM_1 | Rooms.ROOM_2 | Rooms.ROOM_3 | Rooms.ROOM_4 |
    Rooms.ROOM_5_AND_MORE;

const roomValues = ([
    {
        value: [ Rooms.STUDIO ],
        label: 'Квартира студия',
        eventAction: 'studio'
    },
    {
        value: [ Rooms.ROOM_1 ],
        label: 'Однокомнатная',
        eventAction: '1'
    },
    {
        value: [ Rooms.ROOM_2 ],
        label: 'Двухкомнатная',
        eventAction: '2'
    },
    {
        value: [ Rooms.ROOM_3 ],
        label: 'Трёхкомнатная',
        eventAction: '3'
    },
    {
        value: [ Rooms.ROOM_4, Rooms.ROOM_5_AND_MORE ],
        label: 'Четыре и более комнат',
        eventAction: '4_or_more'
    }
] as {
    value: Rooms[];
    label: string;
    eventAction: NewBuildingQuizRoomsEventAction;
}[]);

export const RoomsView = (({
    roomsFilter,
    apartmentsFilter,
    updateData,
    updateEvent,
    isMobile
}: {
    roomsFilter: RoomFilter;
    apartmentsFilter: ApartmentFilter;
    updateData: UpdateData;
    updateEvent: UpdateEvent;
    isMobile: boolean;
}) => {
    const updateRooms = useCallback(
        (rooms: Rooms[], label: string) => {
            updateData([
                new RoomFilter(rooms),
                new PriceFilter()
            ], {
                rooms: label,
                price: ''
            });
        },
        [ updateData ]
    );

    const updateApartments = useCallback(
        (isApartmentsOptions: IsApartmentsOptions) => updateData([
            new ApartmentFilter(isApartmentsOptions)
        ], {
            apartments: isApartmentsOptions === IsApartmentsOptions.NO_APARTMENTS ?
                'Не предлагать апартаменты' :
                ''
        }),
        [ updateData ]
    );

    useEffect(() => {
        const eventActions: NewBuildingQuizRoomsEventAction[] = roomValues
            .filter(({ value }) => value.every(v => roomsFilter.rooms.includes(v)))
            .map(({ eventAction }) => eventAction);

        if (apartmentsFilter.isApartment === IsApartmentsOptions.NO_APARTMENTS) {
            eventActions.push('no_apartments');
        }

        updateEvent({
            event_category: 'rooms',
            event_action: eventActions
        });
    }, [ roomsFilter, apartmentsFilter, updateEvent ]);

    const roomCheckboxes = roomValues
        .map(({ value, label }) => (
            <Checkbox
                key={value[0]}
                value={value.every(v => roomsFilter.rooms.includes(v))}
                onChange={isOn => {
                    const rooms = isOn ?
                        [ ...roomsFilter.rooms, ...value ] :
                        roomsFilter.rooms.filter(v => ! value.includes(v));

                    updateRooms(
                        rooms,
                        rooms
                            .map(
                                roomValue => roomValues
                                    .find(({ value: v }) => v.includes(roomValue))
                                    ?.label
                            )
                            .filter(Boolean)
                            .filter((lbl, i, lbls) => lbls.indexOf(lbl) === i)
                            .join(', ')
                    );
                }}
            >
                {label}
            </Checkbox>
        ));

    return (
        <>
            <div className={cnNewBuildingQuiz('filter', { rooms: true })}>
                {isMobile ? (
                    roomCheckboxes.map((roomCheckbox, i) => (
                        <Fragment key={i}>
                            {roomCheckbox}
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid cols={12}>
                            {roomCheckboxes.slice(0, 3).map((roomCheckbox, i) => (
                                <Grid.Cell
                                    key={i}
                                    m={4}
                                >
                                    {roomCheckbox}
                                </Grid.Cell>
                            ))}
                        </Grid>
                        <Grid cols={12}>
                            {roomCheckboxes.slice(3, 6).map((roomCheckbox, i) => (
                                <Grid.Cell
                                    key={i}
                                    m={4}
                                >
                                    {roomCheckbox}
                                </Grid.Cell>
                            ))}
                        </Grid>
                    </>
                )}
            </div>
            <div className={cnNewBuildingQuiz('filter', { apartments: true })}>
                <Typography
                    className={cnNewBuildingQuiz('boldish')}
                    variant='secondary-alone'
                >
                    Дополнительные условия
                </Typography>
                <Checkbox
                    value={apartmentsFilter.isApartment === IsApartmentsOptions.NO_APARTMENTS}
                    onChange={isOn => updateApartments(
                        isOn ?
                            IsApartmentsOptions.NO_APARTMENTS :
                            IsApartmentsOptions.UNKNOWN
                    )}
                >
                    Не предлагать апартаменты
                </Checkbox>
            </div>
        </>
    );
}) as React.FC;
