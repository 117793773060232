import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

import { MapResultTypeEnum } from '@search/graphql-typings';
import type { NewBuildingCard } from '@search/graphql-typings';
import { useRouter } from '@search/offer-search-routes/src/RouterProvider';
import { YMapPlacemarkPoint } from '@search/ymap/src/Placemark';
import { YMapLayer } from '@search/ymap/src/Layer';
import type { YMapBounds } from '@search/ymap/src';
import type { OfferYMapTilePoint } from '@search/vtbeco-frontend-core/domain/offer/ymap/tile/Point';
import { YMapClickPane } from '@search/vtbeco-frontend-core/domain/offer/ymap/Mobile/YMapClickPane';
import { YMapPOI } from '@search/vtbeco-frontend-core/view/common/components/YMap/YMapPOI';
import { graphql, useGql } from '@search/gql-client/src';
import { useGql2Loader } from '@search/gql-client/src/useGql2';

import { ImageSlider } from '@search/vtbeco-frontend-core/domain/image/slider/ImageSlider';
import type { GeoBaseStore } from '@search/vtbeco-frontend-core/domain/geo/GeoBase';
import classname from '@search/classname/src';

import { useSubwayOnMap } from '../../hooks/useSubwayOnMap';
import { GlobalContext } from '../GlobalContext';
import type { IGlobalContext } from '../GlobalContext';
import { NewBuildingPlacemark } from '../NewBuildingSearchMapPage/NewBuildingPlacemark';
import { useSelectedPlacemarkFetcher } from '../NewBuildingSearchMapPage/useSearchMapPage';
import { useYMapUrl } from '../NewBuildingSearchMapPage/useYMapUrl';
import { NewBuildingCardPolygons } from '../NewBuildingCard';
import type { Building, BuildingGroup } from '../NewBuildingCard';
import NewBuildingSnippet from '../NewBuildingSnippet';
import POISnippet from '../POISnippet';

import type {
    MapSerpQuery$data as MapSerpQueryResponse,
    MapSerpQuery$variables as MapSerpQueryVariables
} from './__generated__/MapSerpQuery.graphql';

import './styles.css';

const cn = classname.bind(null, 'MapSerp');

type NewBuildingPOI = NonNullable<NonNullable<MapSerpQueryResponse['getInfrastructure']>['poiList']>[0];
type NewBuildingPoint = NonNullable<NonNullable<MapSerpQueryResponse['searchMapNewBuildings']>['points']>[0];

const QUERY = graphql`
    query MapSerpQuery(
        $filters: NewBuildingsInput
        $whiteLabel: NewBuildingWhitelabelEnum
        $resultTypes: [MapResultTypeEnum!]
        $limit: Int!
        $withInfrastracture: Boolean!
        $poiLimit: Int!
        $bounds: ProfessionalSearchInputBounds!
    ) {
        searchMapNewBuildings(
            filters: $filters
            whiteLabel: $whiteLabel
            resultTypes: $resultTypes
            limit: $limit
        ) {
            points {
                title {
                    nominative
                }
                priceMin
                buildingStatusList {
                    title
                    isFrozen
                }
                gallery {
                    main {
                        placemarkHint
                    }
                }
                latitude
                longitude
                routeParams {
                    region
                    regionId
                    subRegion
                    type
                    name
                    id
                }
            }
        }
        getInfrastructure(
            bounds: $bounds
            poiLimit: $poiLimit
        ) @include(if: $withInfrastracture) {
            poiList  {
                latitude
                longitude
                name
                kind
                description
                address
                category
                specialization
                noctidial
            }
        }
    }
`;

const MapSerp: FC<{
    isMobile?: boolean;
    center: NewBuildingCard['coordinates'];
    bounds: YMapBounds;
    setBounds?: (newBounds: YMapBounds) => void;
    geoStore?: GeoBaseStore;
    newBuildingId: number;
    regionId: NewBuildingCard['narrowRegionId'];
    buildingGroups: BuildingGroup[];
    withInteractiveMap?: boolean;
    selectBuildings?: (buildings: Building[]) => void;
    selectedBuildings?: Building[];
    handleBuildingsSelect?: () => void;
    showInfrastructure: boolean;
    zoom: number;
    areControlsHidden?: boolean;
    toggleControls?: (areHidden: boolean) => void;
}> = ({
    isMobile = false,
    center,
    bounds,
    setBounds,
    geoStore,
    newBuildingId,
    regionId,
    buildingGroups,
    withInteractiveMap,
    selectBuildings,
    selectedBuildings,
    handleBuildingsSelect,
    showInfrastructure,
    zoom,
    areControlsHidden,
    toggleControls
}) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const router = useRouter();

    const { instanceConfig: { searchIndex } } = globalContext;

    const { data } = useGql<
        MapSerpQueryVariables,
        MapSerpQueryResponse
    >(QUERY, {
        whiteLabel: searchIndex,
        resultTypes: [ MapResultTypeEnum.NewBuildings ],
        limit: 20,
        filters: {
            regionId,
            mapBounds: {
                bounds: {
                    min: bounds!.min,
                    max: bounds!.max
                }
            }
        },
        withInfrastracture: showInfrastructure,
        poiLimit: 20,
        bounds: {
            lx: bounds.min.x,
            ly: bounds.min.y,
            rx: bounds.max.x,
            ry: bounds.max.y
        },
    });

    const { points = [] } = data?.searchMapNewBuildings ?? {};
    const { poiList = [] } = data?.getInfrastructure ?? {};

    const excludeCoords: OfferYMapTilePoint = {
        latitude: center[0],
        longitude: center[1]
    };

    const [ selectedPOI, setSelectedPOI ] = useState<NewBuildingPOI | null>(null);
    const resetPOI = useCallback(() => {
        setSelectedPOI(null);
        toggleControls && toggleControls(false);
    }, [ toggleControls ]);

    const selectPOI = useCallback((poi: NewBuildingPOI) => {
        if (! (bounds && isMobile)) {
            return;
        }

        const { latitude, longitude } = poi;

        const delta = {
            x: (bounds.max.x - bounds.min.x) / 2,
            y: (bounds.max.y - bounds.min.y) / 2
        };

        if (latitude < bounds.min.y + delta.y) {
            setTimeout(() => setBounds && setBounds({
                min: {
                    x: longitude - delta.x,
                    y: latitude - delta.y
                },
                max: {
                    x: longitude + delta.x,
                    y: latitude + delta.y
                }
            }), 300);
        }

        setSelectedPOI(poi);
        toggleControls && toggleControls(true);
    }, [ bounds, isMobile, setBounds, toggleControls ]);

    const [ selectedNewBuildingId, setSelectedNewBuildingId ] = useState<number | null>(null);
    const resetNewBuilding = useCallback(() => {
        setSelectedNewBuildingId(null);
        toggleControls && toggleControls(false);
    }, [ toggleControls ]);

    const selectedPlacemarkLoader = useGql2Loader(useSelectedPlacemarkFetcher(), {
        cachePrefix: 'nbMapSerpSelectedPlacemark',
        tracingTag: 'nbMapSerpSelectedPlacemark'
    });
    const {
        data: selectedNewBuildingData,
        clearCache: clearSelectedNewBuildingCache
    } = selectedPlacemarkLoader({
        paging: {
            pageNumber: 1,
            pageSize: 1
        },
        skipSearchNewBuildings: ! selectedNewBuildingId,
        filters: {
            regionId,
            newBuildingId: selectedNewBuildingId
        },
        whiteLabel: globalContext.instanceConfig.searchIndex
    });
    const selectedNewBuilding = selectedNewBuildingData?.searchNewBuildings?.items[0] ?? undefined;

    const selectNewBuilding = useCallback((point: NewBuildingPoint) => {
        if (! (bounds && isMobile)) {
            return;
        }

        const { latitude, longitude } = point;

        const delta = {
            x: (bounds.max.x - bounds.min.x) / 2,
            y: (bounds.max.y - bounds.min.y) / 2
        };

        if (latitude < bounds.min.y + delta.y * 4 / 3) {
            setTimeout(() => setBounds && setBounds({
                min: {
                    x: longitude - delta.x,
                    y: latitude - delta.y - delta.y / 3
                },
                max: {
                    x: longitude + delta.x,
                    y: latitude + delta.y - delta.y / 3
                }
            }), 300);
        }

        setSelectedNewBuildingId(point.routeParams.id);
        toggleControls && toggleControls(true);
    }, [ bounds, isMobile, setBounds, toggleControls ]);

    const resetSelected = useCallback(() => {
        resetNewBuilding();
        resetPOI();
    }, [ resetNewBuilding, resetPOI ]);

    const handlePOIClick = useCallback((poi: NewBuildingPOI) => {
        resetNewBuilding();
        selectPOI(poi);
    }, [ resetNewBuilding, selectPOI ]);

    const poi = poiList.map(item => (
        <YMapPOI
            key={`${item.longitude}-${item.latitude}`}
            {...item}
            onClick={isMobile ? () => handlePOIClick(item) : undefined}
            isSelected={Boolean(
                selectedPOI &&
                item.latitude === selectedPOI.latitude &&
                item.longitude === selectedPOI.longitude
            )}
        />
    ));

    const handlePlacemarkClick = useCallback((placemark: NewBuildingPoint) => {
        resetPOI();
        selectNewBuilding(placemark);
    }, [ resetPOI, selectNewBuilding ]);

    const otherNewBuildings = useMemo(
        () => points!
            .filter(item => item.routeParams.id !== newBuildingId)
            .map(item => (
                <NewBuildingPlacemark
                    {...item}
                    key={item.routeParams.id}
                    onClick={isMobile ? () => handlePlacemarkClick(item) : undefined}
                    isActive={Boolean(
                        selectedNewBuilding &&
                        selectedNewBuilding.routeParams.id === item.routeParams.id
                    )}
                />
            )),
        [ points, newBuildingId, isMobile, selectedNewBuilding, handlePlacemarkClick ]
    );

    const {
        tileUrl
    } = useYMapUrl({
        params: {
            region: regionId,
            selectedNewBuildingId: newBuildingId
        },
        // @ts-ignore
        router,
        excludeCoords
    });

    const { subwayPimplePlacemarks, subwayPinPlacemarks } = useSubwayOnMap({
        geoStore,
        regionId,
        bounds
    });

    return (
        <>
            {zoom > 15 ? (
                <NewBuildingCardPolygons
                    withHints
                    interactive={withInteractiveMap}
                    buildingGroups={buildingGroups}
                    onBuildingsSelect={handleBuildingsSelect}
                    selectBuildings={selectBuildings}
                    selectedBuildings={selectedBuildings}
                    shouldSetBoundsFromPolygons={false}
                    shouldBeRemovedOnUnmount
                />
            ) : (
                <YMapPlacemarkPoint point={center as [ number, number ]} />
            )}
            {tileUrl && <YMapLayer tileUrl={tileUrl} />}
            {otherNewBuildings}
            {poi}
            {subwayPimplePlacemarks}
            {subwayPinPlacemarks}
            {selectedNewBuilding ? (
                <div className={cn('panel')}>
                    <ImageSlider
                        containerCrossSwipeEnabled
                        onCrossSwipeFwd={resetNewBuilding}
                        noOverflow
                        items={[
                            <NewBuildingSnippet
                                key='NewBuildingSnippet'
                                view='onMobileMap'
                                newBuilding={selectedNewBuilding}
                                clearSnippetCache={clearSelectedNewBuildingCache}
                            />
                        ]}
                    />
                </div>
            ) : null}
            {selectedPOI ? (
                <div className={cn('panel')}>
                    <ImageSlider
                        containerCrossSwipeEnabled
                        onCrossSwipeFwd={resetPOI}
                        noOverflow
                        items={[
                            <POISnippet
                                key='POISnippet'
                                {...selectedPOI}
                            />
                        ]}
                    />
                </div>
            ) : null}
            {areControlsHidden ? (
                <YMapClickPane resetSelected={resetSelected} />
            ) : null}
        </>
    );
};

export default React.memo(MapSerp);
