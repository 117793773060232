/* eslint-disable new-cap */
import React, { useReducer, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { RootRegions } from '@search/vtbeco-frontend-core/domain/newbuilding/libs/root-regions';
import type { SEOTexts } from '@search/nb-texts/types';
import type { UserInfo } from '@vtblife/bff-utils/dist/auth';
import type { NewBuildingQuiz } from '@search/graphql-typings';
import { GeoBase } from '@search/geo/src/SlugMapper';

import { COOKIES } from '../../../../enums/cookies';
import type { Shared } from '../../../../types/shared';

export interface IOnChangeGlobalContext {
    setRgid: (rgid: number) => void;
    linkBuilder: Shared.ILinkBuilder;
}

export interface IGlobalContext {
    rgid: string;
    auth?: UserInfo;
    rootRegions: RootRegions;
    env: string;
    calltouchModId?: string; // идентификатор скрипта Calltouch, зависящий от env
    baseURL?: string;
    isMobile: boolean;
    isFromInnerNetwork?: boolean;
    seoTexts: SEOTexts;
    instanceConfig: Shared.IInstanceConfig;
    components: Shared.IInstanceComponents;
    isSimplifiedCard: boolean;
    quiz?: NewBuildingQuiz;
    geoSlugData?: Array<{
        geo: GeoBase;
        region: number;
    }>;
}

const ACTIONS = {
    SET_RGID: 'SET_RGID'
};

const defaultGlobalContext = {
    env: '',
    rgid: '1',
    baseURL: '',
    isMobile: false,
    rootRegions: new RootRegions([]),
    isFromInnerNetwork: false,
    instanceConfig: {},
    components: {},
    seoTexts: {},
    isSimplifiedCard: false
};

const DEFAULT_LINK_BUILDER = (): Shared.ILinkBuilder => ({
    search() {
        return '/';
    },
    searchMap() {
        return '/';
    },
    card() {
        return '/';
    },
    tile() {
        return '/';
    }
});

export const GlobalContext = React.createContext<IGlobalContext>(defaultGlobalContext);
export function useGlobalContext() {
    return React.useContext<IGlobalContext>(GlobalContext);
}

export const OnChangeGlobalContext = React.createContext<IOnChangeGlobalContext>({
    linkBuilder: DEFAULT_LINK_BUILDER(),
    setRgid: () => {}
});

function reducer(state: IGlobalContext, action) {
    switch (action.type) {
        case ACTIONS.SET_RGID:
            return {
                ...state,
                rgid: action.payload.rgid
            };
        default:
            throw new Error();
    }
}

export const GlobalContextProvider: React.FunctionComponent<{
    globalContext: IGlobalContext;
    linkBuilder: Shared.LinkBuilderType;
    router: Shared.RouterType;
}> = ({
    children,
    globalContext,
    linkBuilder = DEFAULT_LINK_BUILDER,
    router
}) => {
    const [ context, dispatchContext ] = useReducer(reducer, globalContext);
    const [ , setCookie ] = useCookies([ COOKIES.RGID ]);
    const actions = useRef<IOnChangeGlobalContext>({
        setRgid: rgid => {
            const d = new Date();

            d.setFullYear(d.getFullYear() + 1);

            setCookie(COOKIES.RGID, String(rgid), { expires: d, path: '/' });

            dispatchContext({ type: ACTIONS.SET_RGID, payload: { rgid } });
        },
        linkBuilder: linkBuilder(globalContext.rootRegions, router)
    });

    return (
        <OnChangeGlobalContext.Provider value={actions.current}>
            <GlobalContext.Provider value={context}>
                {children}
            </GlobalContext.Provider>
        </OnChangeGlobalContext.Provider>
    );
};
